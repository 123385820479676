define("printpost/models/request", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    channel: _emberData.default.attr('string'),
    withdraw: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    document: _emberData.default.attr('string'),
    originalDocumentName: _emberData.default.attr('string'),
    fileDb: _emberData.default.attr(),
    shippingMethod: _emberData.default.attr('string'),
    emailSubject: _emberData.default.attr('string'),
    emailSenderName: _emberData.default.attr('string'),
    emailSender: _emberData.default.attr('string'),
    emailReturn: _emberData.default.attr('string'),
    devolucao: _emberData.default.attr('string'),
    number: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    sent: _emberData.default.attr('number'),
    processing: _emberData.default.attr('number'),
    error: _emberData.default.attr('number'),
    totalCost: _emberData.default.attr('number'),
    templateId: _emberData.default.attr(),
    walletId: _emberData.default.attr(),
    letterService: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    smsService: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    emailService: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    billingRelated: _emberData.default.attr('boolean'),
    phoneContact: _emberData.default.attr('string'),
    emailContact: _emberData.default.attr('string'),
    paymentMethod: _emberData.default.attr('string'),
    paymentCode: _emberData.default.attr('string'),
    databaseId: _emberData.default.attr(),
    printingOptions: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    address: _emberData.default.attr(),
    logoId: _emberData.default.attr(),
    letterTemplateId: _emberData.default.attr(),
    smsTemplateId: _emberData.default.attr(),
    emailTemplateId: _emberData.default.attr(),
    userId: _emberData.default.attr(),
    customer: _emberData.default.attr(),
    sentStatuses: _emberData.default.hasMany('request-status'),
    wallet: _emberData.default.belongsTo('wallet'),
    columnsSelectSMS: _emberData.default.attr(),
    columnsSelectEmail: _emberData.default.attr()
  });

  _exports.default = _default;
});