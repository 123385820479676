define("printpost/utils/globalFunctions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.apiLinkQuery = apiLinkQuery;
  _exports.sleep = sleep;
  _exports.getUrlParameters = getUrlParameters;
  _exports.scorePassword = scorePassword;
  _exports.checkPassStrength = checkPassStrength;
  _exports.removeItemArrayObject = removeItemArrayObject;
  _exports.scroll_to_class = scroll_to_class;
  _exports.bar_progress = bar_progress;
  _exports.removeSpecialChar = removeSpecialChar;
  _exports.removeItemArray = removeItemArray;
  _exports.capitalize = capitalize;
  _exports.firstUpper = firstUpper;
  _exports.validaCpfCnpj = validaCpfCnpj;
  _exports.decodeHTML = decodeHTML;

  function apiLinkQuery(obj) {
    let string_retun = "";

    for (var k in obj) {
      if (typeof obj[k] != 'object') {
        string_retun += string_retun.length > 1 ? '&' + k + '=' + obj[k] : k + '=' + obj[k];
      } else {
        let obj2 = obj[k];

        for (var k2 in obj2) {
          if (typeof obj2[k2] != 'object') {
            string_retun += string_retun.length > 1 ? '&' + k + '[' + k2 + ']' + '=' + obj2[k2] : k + '[' + k2 + ']' + '=' + obj2[k2];
          } else {
            let obj3 = obj2[k2];

            if (obj3.length) {
              obj3.forEach(function (item, index) {
                if (typeof item == 'object') {
                  let __key = Object.keys(item);

                  let s = apiLinkQuery(item);
                  s = s.split(__key[0]).join(k + '[' + k2 + ']' + '[' + index + ']' + '[' + __key[0] + ']');
                  string_retun += string_retun.length > 1 ? '&' + s : s;
                } else {
                  string_retun += string_retun.length > 1 ? '&' + k + '[' + k2 + ']' + '=' + item : k + '[' + k2 + ']' + '=' + item;
                }
              });
            } else {
              let __t = JSON.parse('{"' + k2 + '":' + JSON.stringify(obj3) + '}');

              let s = apiLinkQuery(__t);
              s = s.split(k2).join(k + '[' + k2 + ']');
              string_retun += string_retun.length > 1 ? '&' + s : s;
            }
          }
        }
      }
    }

    return string_retun;
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function getUrlParameters(parameter, staticURL, decode) {
    var currLocation = staticURL.length ? staticURL : window.location.search;

    if (currLocation.indexOf('?') == -1) {
      return false;
    }

    var parArr = currLocation.split("?")[1].split("&");
    var returnBool = true;

    for (var i = 0; i < parArr.length; i++) {
      var parr = parArr[i].split("=");

      if (parr[0] == parameter) {
        return decode ? decodeURIComponent(parr[1]) : parr[1];
        returnBool = true;
      } else {
        returnBool = false;
      }
    }

    if (!returnBool) return false;
  }

  function scorePassword(pass) {
    var score = 0;
    if (!pass) return score; // award every unique letter until 5 repetitions

    var letters = new Object();

    for (var i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    } // bonus points for mixing it up


    var variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
    };
    var variationCount = 0;

    for (var check in variations) {
      variationCount += variations[check] == true ? 1 : 0;
    }

    score += (variationCount - 1) * 10;
    return parseInt(score);
  }

  function checkPassStrength(pass) {
    var score = scorePassword(pass);
    if (score > 80) return "strong";
    if (score > 60) return "good";
    if (score >= 30) return "weak";
    return "";
  }

  function removeItemArrayObject(dataArray, dataKey, dataValue) {
    for (var i = 0; i < dataArray.length; i++) {
      if (dataArray[i][dataKey] == dataValue) {
        dataArray.splice(i, 1);
      }
    }

    return dataArray;
  }

  function scroll_to_class(element_class, removed_height) {
    var scroll_to = $(element_class).offset().top - removed_height;

    if ($(window).scrollTop() != scroll_to) {
      $('html, body').stop().animate({
        scrollTop: scroll_to
      }, 0);
    }
  }

  function bar_progress(progress_line_object, direction) {
    var number_of_steps = progress_line_object.data('number-of-steps');
    var now_value = progress_line_object.data('now-value');
    var new_value = 0;

    if (direction == 'right') {
      new_value = now_value + 100 / number_of_steps;
    } else if (direction == 'left') {
      new_value = now_value - 100 / number_of_steps;
    }

    progress_line_object.attr('style', 'width: ' + new_value + '%;').data('now-value', new_value);
  }

  function removeSpecialChar(str, remove_spaces = true, unsensitive = true) {
    var especial_char_a = unsensitive ? [/á/gi, /à/gi, /â/gi, /ã/gi] : [/á/g, /à/g, /â/g, /ã/g];
    var especial_char_e = unsensitive ? [/é/gi, /è/gi, /ê/gi, /ẽ/gi] : [/é/g, /è/g, /ê/g, /ẽ/g];
    var especial_char_i = unsensitive ? [/í/gi, /ì/gi, /î/gi, /ĩ/gi] : [/í/g, /ì/g, /î/g, /ĩ/g];
    var especial_char_o = unsensitive ? [/ó/gi, /ò/gi, /ô/gi, /õ/gi] : [/ó/g, /ò/g, /ô/g, /õ/g];
    var especial_char_u = unsensitive ? [/ú/gi, /ù/gi, /û/gi, /ũ/gi] : [/ú/g, /ù/g, /û/g, /ũ/g];
    var especial_char_cedil = unsensitive ? [/ç/gi] : [/ç/g];
    var especial_upper_char_a = [/Á/g, /À/g, /Â/g, /Ã/g];
    var especial_upper_char_e = [/É/g, /È/g, /Ê/g, /Ẽ/g];
    var especial_upper_char_i = [/Í/g, /Ì/g, /Î/g, /Ĩ/g];
    var especial_upper_char_o = [/Ó/g, /Ò/g, /Ô/g, /Õ/g];
    var especial_upper_char_u = [/Ú/g, /Ù/g, /Û/g, /Ũ/g];
    var especial_upper_char_cedil = [/Ç/g];

    function replaceArray(str, strReplace, arrayData) {
      arrayData.forEach(item => {
        str = str.replace(item, strReplace);
      });
      return str;
    }

    str = replaceArray(str, "a", especial_char_a);
    str = replaceArray(str, "e", especial_char_e);
    str = replaceArray(str, "i", especial_char_i);
    str = replaceArray(str, "o", especial_char_o);
    str = replaceArray(str, "u", especial_char_u);
    str = replaceArray(str, "c", especial_char_cedil);

    if (!unsensitive) {
      str = replaceArray(str, "A", especial_upper_char_a);
      str = replaceArray(str, "E", especial_upper_char_e);
      str = replaceArray(str, "I", especial_upper_char_i);
      str = replaceArray(str, "O", especial_upper_char_o);
      str = replaceArray(str, "U", especial_upper_char_u);
      str = replaceArray(str, "C", especial_upper_char_cedil);
    }

    if (remove_spaces) {
      str = str.replace(/ /gi, "_");
    }

    return str;
  }

  function removeItemArray(item, arrayData) {
    var val = $.inArray(item, arrayData);
    var _newArrayData = [];
    delete arrayData[val];
    $.each(arrayData, function (index, valor) {
      //log(valor);
      if (valor != null) {
        _newArrayData.push(valor);
      }
    });
    return _newArrayData;
  }

  function capitalize(string) {
    let str = string.split(' ');

    if (str.length > 0) {
      let strReturn = "";
      str.forEach(item => {
        strReturn += item.replace(/^./, item[0].toUpperCase()) + " ";
      });
      return strReturn.trim();
    }
  }

  function firstUpper(string) {
    if (string.length > 0) {
      let strReturn = string.toLowerCase();
      strReturn = strReturn.replace(/^./, strReturn[0].toUpperCase());
      return strReturn.trim();
    }
  }

  function validaCpfCnpj(val) {
    if (val.length == 14) {
      var cpf = val.trim();
      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');
      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = v1 * 10 % 11;

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = v2 * 10 % 11;

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    } else if (val.length == 18) {
      var cnpj = val.trim();
      cnpj = cnpj.replace(/\./g, '');
      cnpj = cnpj.replace('-', '');
      cnpj = cnpj.replace('/', '');
      cnpj = cnpj.split('');
      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cnpj.length > i; i++) {
        if (cnpj[i - 1] != cnpj[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v1 += cnpj[i] * p1;
        } else {
          v1 += cnpj[i] * p2;
        }
      }

      v1 = v1 % 11;

      if (v1 < 2) {
        v1 = 0;
      } else {
        v1 = 11 - v1;
      }

      if (v1 != cnpj[12]) {
        return false;
      }

      for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v2 += cnpj[i] * p1;
        } else {
          v2 += cnpj[i] * p2;
        }
      }

      v2 = v2 % 11;

      if (v2 < 2) {
        v2 = 0;
      } else {
        v2 = 11 - v2;
      }

      if (v2 != cnpj[13]) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  function decodeHTML(str) {
    var htmlEntities = [{
      entity: '&',
      regex: '&amp;'
    }, {
      entity: '>',
      regex: '&gt;'
    }, {
      entity: '<',
      regex: '&lt;'
    }, {
      entity: '"',
      regex: '&quot;'
    }, {
      entity: ' ',
      regex: '&nbsp;'
    }, {
      entity: 'á',
      regex: '&aacute;'
    }, {
      entity: 'à',
      regex: '&agrave;'
    }, {
      entity: 'â',
      regex: '&acirc;'
    }, {
      entity: 'ã',
      regex: '&atilde;'
    }, {
      entity: 'ä',
      regex: '&auml;'
    }, {
      entity: 'Á',
      regex: '&Aacute;'
    }, {
      entity: 'À',
      regex: '&Agrave;'
    }, {
      entity: 'Â',
      regex: '&Acirc;'
    }, {
      entity: 'Ã',
      regex: '&Atilde;'
    }, {
      entity: 'Ä',
      regex: '&Auml;'
    }, {
      entity: 'é',
      regex: '&eacute;'
    }, {
      entity: 'è',
      regex: '&egrave;'
    }, {
      entity: 'ê',
      regex: '&ecirc;'
    }, {
      entity: 'ẽ',
      regex: '&etilde;'
    }, {
      entity: 'ë',
      regex: '&euml;'
    }, {
      entity: 'É',
      regex: '&Eacute;'
    }, {
      entity: 'È',
      regex: '&Egrave;'
    }, {
      entity: 'Ê',
      regex: '&Ecirc;'
    }, {
      entity: 'Ẽ',
      regex: '&Etilde;'
    }, {
      entity: 'Ë',
      regex: '&Euml;'
    }, {
      entity: 'í',
      regex: '&iacute;'
    }, {
      entity: 'ì',
      regex: '&igrave;'
    }, {
      entity: 'î',
      regex: '&icirc;'
    }, {
      entity: 'ĩ',
      regex: '&itilde;'
    }, {
      entity: 'ï',
      regex: '&iuml;'
    }, {
      entity: 'Í',
      regex: '&Iacute;'
    }, {
      entity: 'Ì',
      regex: '&Igrave;'
    }, {
      entity: 'Î',
      regex: '&Icirc;'
    }, {
      entity: 'Ĩ',
      regex: '&Itilde;'
    }, {
      entity: 'Ï',
      regex: '&Iuml;'
    }, {
      entity: 'ó',
      regex: '&oacute;'
    }, {
      entity: 'ò',
      regex: '&ograve;'
    }, {
      entity: 'ô',
      regex: '&ocirc;'
    }, {
      entity: 'õ',
      regex: '&otilde;'
    }, {
      entity: 'ö',
      regex: '&ouml;'
    }, {
      entity: 'Ó',
      regex: '&Oacute;'
    }, {
      entity: 'Ò',
      regex: '&Ograve;'
    }, {
      entity: 'Ô',
      regex: '&Ocirc;'
    }, {
      entity: 'Õ',
      regex: '&Otilde;'
    }, {
      entity: 'Ö',
      regex: '&Ouml;'
    }, {
      entity: 'ú',
      regex: '&uacute;'
    }, {
      entity: 'ù',
      regex: '&ugrave;'
    }, {
      entity: 'û',
      regex: '&ucirc;'
    }, {
      entity: 'ũ',
      regex: '&utilde;'
    }, {
      entity: 'ü',
      regex: '&uuml;'
    }, {
      entity: 'Ú',
      regex: '&Uacute;'
    }, {
      entity: 'Ù',
      regex: '&Ugrave;'
    }, {
      entity: 'Û',
      regex: '&Ucirc;'
    }, {
      entity: 'Ũ',
      regex: '&Utilde;'
    }, {
      entity: 'Ü',
      regex: '&Uuml;'
    }, {
      entity: 'ç',
      regex: '&ccedil;'
    }, {
      entity: 'Ç',
      regex: '&Ccedil;'
    }];

    for (let i = 0; i < htmlEntities.length; i++) {
      const v = htmlEntities[i];
      str = str.replace(v.regex, v.entity);
    }

    let text = removeSpecialChar(str, false, false);
    return text.trim();
  }
});