define("printpost/routes/prices/addlinkcustomer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      // let id_param = getUrlParameters("id", "", true);
      var url_accounts = this.baseapi.apiLink('Accounts/list');
      var url_pricingtables = this.baseapi.apiLink('PricingTables/list?filter[where][pricingTableType]=customersprice_table&filter[include]=channel');
      var url = this.baseapi.apiLink('LinkCustomers/list?filter[where][id]=' + queryParams.id);
      let item = [{
        "name": "",
        "active": true,
        "pricingTableId": "",
        "userId": ""
      }];
      const obj_accounts = await this.baseapi.getHttp(url_accounts).then(m => m.data);
      const obj_pricingTables = await this.baseapi.getHttp(url_pricingtables).then(m => m.data);

      if (queryParams && queryParams.id) {
        const obj = await this.baseapi.getHttp(url).then(r => r.data);
        item = obj;
      }

      return Ember.RSVP.hash({
        item: item,
        pricingtables: obj_pricingTables,
        accounts: obj_accounts,
        id: queryParams.id
      });
    }
  });

  _exports.default = _default;
});