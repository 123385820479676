define("printpost/controllers/invoice/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return $('#subpreloader');
    }).property().volatile(),
    actions: {
      downloadMapXLS() {
        let monthRef = this.get('model.monthRef');
        let url = this.baseapi.apiLink('Invoices/map-production-xls');
        this.preloader.toggleClass('d-none');
        this.baseapi.getDownload(`${url}?filter[where][monthReference]=${monthRef}`, 'file_download.xlsx', true);
        this.preloader.toggleClass('d-none');
      }

    }
  });

  _exports.default = _default;
});