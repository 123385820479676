define("printpost/components/topol-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      saveTemplate() {
        if (this.saveEvent) {
          console.log('save event in topol editor');
          this.saveEvent();
        }
      }

    }
  });

  _exports.default = _default;
});