define("printpost/controllers/cloud/images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'size'],
    page: 1,
    size: 10,
    columns: Ember.computed(function () {
      return [{
        name: `NAME`,
        valuePath: `tag_name`
      }, {
        name: `ID`,
        valuePath: `image_id`
      }, {
        name: `STATUS`,
        valuePath: `status`
      }];
    }),
    rows: Ember.computed('model.[]', function () {
      let grows = this.get('model');
      return grows.filterBy('is_horizon', true).toArray();
    })
  });

  _exports.default = _default;
});