define("printpost/routes/unsubscribe", ["exports", "printpost/mixins/open-route-mixin"], function (_exports, _openRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default Route.extend(UnauthenticatedRouteMixin, {
  var _default = Ember.Route.extend(_openRouteMixin.default, {
    hasLayout: false,
    baseapi: Ember.inject.service(),
    actions: {
      unsub() {
        let url = this.baseapi.apiLink('app');
        var id = this.baseapi.queryParam('id');
        this.baseapi.getHttp(`${url}/unsubscribe?id=${id}`, false).then(x => {
          window.location.href = '/unsubsuccess';
        }, () => {
          window.location.href = '/unsubsuccess';
        });
      }

    }
  });

  _exports.default = _default;
});