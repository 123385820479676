define("printpost/controllers/cart", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    continueEdit: (0, _emberConcurrency.task)(function* (idvalue) {
      if (idvalue) {
        let _request = null;
        let query = {
          filter: {
            where: {
              id: idvalue
            },
            fields: {
              id: true,
              title: true,
              paymentCode: true,
              channel: true,
              devolucao: true,
              withdraw: true,
              shippingMethod: true,
              emailSubject: true,
              paymentMethod: true,
              paymentStatus: true,
              status: true,
              quantity: true,
              sent: true,
              processing: true,
              error: true,
              totalCost: true,
              letterService: true,
              smsService: true,
              emailService: true,
              emailContact: true,
              phoneContact: true,
              document: true,
              originalDocumentName: true,
              columnsSelectSMS: true,
              columnsSelectEmail: true,
              userId: true,
              clientIdentifier: true,
              invoiceId: true,
              walletId: true,
              fileDb: true,
              templateId: true,
              databaseId: true,
              printingOptions: true,
              billingRelated: false,
              organizationId: true,
              observation: true,
              notarysOffice: true,
              pendingApprove: true,
              address: true,
              logoId: true,
              letterTemplateId: true,
              smsTemplateId: true,
              emailTemplateId: true
            }
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        let url = this.baseapi.apiLink('Requests');
        _request = yield this.baseapi.getHttp(url + "/list?" + strquery).then(r => r.data);
        _request = _request[0];
        delete _request.createdAt;

        if (_request != null) {
          localStorage.removeItem('storage:request');
          localStorage.removeItem('newServiceRequestId');
          localStorage.removeItem('storage:wallet-columns');
          localStorage.removeItem('storage:excededSms');
          localStorage.removeItem('storage:template-selected');
          localStorage.removeItem('storage:request-options');
          localStorage.setItem('newServiceRequestId', idvalue);
          localStorage.setItem('storage:request', JSON.stringify(_request));
          window.location.href = "services/new";
        }
      }
    }).keepLatest(),
    actions: {
      continueEditing: function (idvalue) {
        Ember.get(this, 'continueEdit').perform(idvalue);
      }
    }
  });

  _exports.default = _default;
});