define("printpost/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "printpost/mixins/secure-default-route-factory"], function (_exports, _applicationRouteMixin, _secureDefaultRouteFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Ember.Route.reopenClass(_secureDefaultRouteFactory.default);

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    // Inject the notifications service
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    api: Ember.inject.service('baseapi'),

    beforeModel() {
      return this._loadAllModels();
    },

    sessionAuthenticated() {
      this._super(...arguments);

      this._loadAllModels();
    },

    sessionInvalidated() {
      this._super(...arguments);

      this._loadCurrentUser();
    },

    _loadAllModels() {
      this.get('currentUser').load().catch(() => this.get('session'));
    },

    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});