define("printpost/templates/services/completed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Iy3vHldD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content-bg au-boxshadow mt-0\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"container-fluid mt-5 p-5\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"completed-mark\"],[9],[0,\"\\n                \"],[7,\"span\"],[11,\"class\",\"pt-5 drip-icon drip-checkmark\"],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"completed-text\"],[9],[0,\"\\n                \"],[7,\"p\"],[9],[0,\"É isso aí!\"],[10],[0,\"\\n                \"],[7,\"h4\"],[9],[0,\"Seu pedido foi enviado para análise, avisaremos quando entrar em execução.\"],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"text-center mt-5 proceed-button  row mx-auto col-md-12\"],[9],[0,\"\\n                \"],[7,\"button\"],[11,\"class\",\"btn btn-lg btn-block btn-success col-md-8 mr-20\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"goRouter\",\"requests\"],null]],[9],[7,\"span\"],[9],[0,\"ACOMPANHAR PEDIDO\"],[10],[10],[0,\"\\n\\n                \"],[7,\"li\"],[11,\"class\",\"parent submenu dropdown col-md-3\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"services.new2\",[27,\"query-params\",null,[[\"edit\"],[false]]]],[[\"tagName\",\"class\"],[\"button\",\"btn btn-lg btn-block btn-success\"]],{\"statements\":[[0,\"                      \"],[7,\"span\"],[11,\"class\",\"\"],[9],[0,\"NOVO PEDIDO\"],[10],[0,\" \\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[0,\"                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/services/completed.hbs"
    }
  });

  _exports.default = _default;
});