define("printpost/templates/components/whitelabel-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q6OBWDHt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"head\"],[9],[0,\"\\n\"],[7,\"link\"],[11,\"rel\",\"icon\"],[12,\"href\",[28,[[21,\"favicon\"]]]],[11,\"sizes\",\"32x32\"],[11,\"type\",\"image/png\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"page-call\"],[11,\"id\",\"login-call\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"style\",[28,[\"background-image: url(\",[21,\"image\"],\");\\n  display: table;\\n  position: fixed !important;\\n  top: 0;\\n  left: 30%;\\n  bottom: 0;\\n  width: 70%;\\n  height: 100%;\\n  position: relative;\\n  background-size: cover;\\n  background-position: center center;\\n  background-repeat: no-repeat;\\n  z-index: -1;\\n  \"]]],[9],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"call-to-action\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"h1\"],[9],[0,\"\\n      \"],[1,[21,\"title\"],true],[0,\"\\n    \"],[10],[0,\"\\n    \\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[21,\"message\"],true],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/whitelabel-banner.hbs"
    }
  });

  _exports.default = _default;
});