define("printpost/controllers/services/completed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      transitionToDashboard: function () {
        this.transitionToRoute('dashboard').then(() => {
          document.location.reload(true);
        });
      },

      goRouter(url) {
        localStorage.removeItem('storage:request');
        localStorage.removeItem('newServiceRequestId');
        localStorage.removeItem('storage:wallet-columns');
        localStorage.removeItem('storage:excededSms');
        localStorage.removeItem('storage:template-selected');
        localStorage.removeItem('storage:request-options');
        this.transitionToRoute(url);
      }

    }
  });

  _exports.default = _default;
});