define("printpost/controllers/produtos/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    data: Ember.computed('model', function () {
      var model = this.get('model.data');
      return model[0];
    }),
    actions: {
      goToLink: function (item) {
        this.transitionToRoute(item);
      }
    }
  });

  _exports.default = _default;
});