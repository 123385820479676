define("printpost/controllers/prices/list", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    data: Ember.computed('model', function () {
      var model = this.get('model.data');
      return model;
    }),
    pricingTableOrganizations: Ember.computed('pricingTableOrganizations', function () {
      return [];
    }),
    organizationForVincule: Ember.computed('organizationForVincule', function () {
      return [];
    }),
    pricingTableAccounts: Ember.computed('pricingTableAccounts', function () {
      return [];
    }),
    accountsForVincule: Ember.computed('accountsForVincule', function () {
      return [];
    }),
    showAddUser: Ember.computed('showAddUser', function () {
      return false;
    }),
    currentIdTablePricing: Ember.computed('currentIdTablePricing', function () {
      return '';
    }),
    listLinkCustomer: function (idTablePricing) {
      let query = {
        filter: {
          where: {
            pricingTableId: idTablePricing
          },
          include: ['account', 'organization']
        }
      };
      let strquery = (0, _globalFunctions.apiLinkQuery)(query);
      let url = this.baseapi.apiLink('LinkCustomers/users');
      this.preloader.toggleClass('d-none');
      this.baseapi.getHttp(url + '?' + strquery).then(resp => {
        Ember.set(this, 'pricingTableAccounts', resp.data);
        this.preloader.toggleClass('d-none');

        if (resp.data) {
          let ids_accounts = resp.data.map(x => {
            return x.account.id;
          });
          let query02 = {
            filter: {
              where: {
                id: {
                  nin: ids_accounts
                }
              }
            }
          };

          if (ids_accounts.length == 1) {
            query02.filter.where.id = {
              neq: resp.data[0].account.id
            };
          }

          let strquery02 = ids_accounts.length > 0 ? (0, _globalFunctions.apiLinkQuery)(query02) : '';
          let url_account = this.baseapi.apiLink('Accounts/list');
          this.preloader.toggleClass('d-none');
          this.baseapi.getHttp(url_account + '?' + strquery02).then(resp02 => {
            this.preloader.toggleClass('d-none');
            Ember.set(this, 'accountsForVincule', resp02.data);
          }); //organization

          let ids_organizations = resp.data.map(x => {
            return x.organization.id;
          });
          let query03 = {
            filter: {
              where: {
                id: {
                  nin: ids_organizations
                }
              }
            }
          };

          if (ids_organizations.length == 1) {
            query03.filter.where.id = {
              neq: resp.data[0].organization.id
            };
          }

          let strquery03 = ids_organizations.length > 0 ? (0, _globalFunctions.apiLinkQuery)(query03) : '';
          let url_organization = this.baseapi.apiLink('Organizations/list');
          this.preloader.toggleClass('d-none');
          this.baseapi.getHttp(url_organization + '?' + strquery03).then(resp03 => {
            this.preloader.toggleClass('d-none');
            Ember.set(this, 'organizationForVincule', resp03.data);
          });
        }
      });
    },
    searchAccountTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Accounts/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    searchOrganizationTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Organizations/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    actions: {
      goToLink: function (item) {
        this.transitionToRoute(item);
      },
      ativarItem: function (id, val) {
        let priceId = id == undefined ? 0 : id;
        let list = Ember.get(this, 'data');
        let item = list.find(x => x.id == id);

        if (item) {
          Ember.set(item, 'active', !val);
          let __price = {
            data: {
              type: "PricingTables",
              id: priceId,
              attributes: item
            }
          };
          delete __price.data.attributes.id;
          let url = this.baseapi.apiLink('PricingTables/' + priceId);
          let call = this.baseapi.patch(url, __price);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none'); //not to do

            Ember.set(item, 'id', priceId);
            this.toast.success('Atualizado!', 'Preço atualizado com sucesso!', {
              positionClass: 'toast-bottom-right'
            });
          }, error => {
            this.preloader.toggleClass('d-none');
            console.log(error);
          });
        }
      },
      setShowAddUser: function () {
        let val = !Ember.get(this, 'showAddUser');
        Ember.set(this, 'showAddUser', val);
      },
      getLinkCustomer: function (idTablePricing) {
        Ember.set(this, 'currentIdTablePricing', idTablePricing);
        Ember.set(this, 'dataClient', null);
        Ember.set(this, 'dataOrganization', null);
        this.listLinkCustomer(idTablePricing);
      },
      addLinkUser: function () {
        let usuario = Ember.get(this, 'dataClient');
        let idUser = usuario.id;
        let idTablePricing = Ember.get(this, 'currentIdTablePricing');
        let pt = this.get('model.data').find(x => x.id == idTablePricing);

        if (!pt) {
          this.toast.error('Item Não cadastrado!', 'Erro ao vincular tabela', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let item = {
          "name": '',
          "userId": idUser,
          "active": true,
          "pricingTableId": idTablePricing,
          "channelId": pt.channelId
        };
        let _object = {
          item: item
        };

        if (item.userId.length > 0 && item.pricingTableId.length > 0) {
          let url = this.baseapi.apiLink('LinkCustomers');
          let call = this.baseapi.post(url + "/cadastrar", _object);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');

            if (resp.data.errors) {
              this.toast.error('Item Não cadastrado!', resp.data.errors[0].detail, {
                positionClass: 'toast-bottom-right'
              });
              return;
            }

            ;
            this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.listLinkCustomer(idTablePricing);
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não cadastrado!', 'Erro ao cadastrar dados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },
      addLinkOrganization: function () {
        let organization = Ember.get(this, 'dataOrganization');
        let idOrganization = organization.id;
        let idTablePricing = Ember.get(this, 'currentIdTablePricing');
        let pt = this.get('model.data').find(x => x.id == idTablePricing);

        if (!pt) {
          this.toast.error('Item Não cadastrado!', 'Erro ao vincular tabela', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let item = {
          "name": '',
          "organizationId": idOrganization,
          "active": true,
          "pricingTableId": idTablePricing,
          "channelId": pt.channelId
        };
        let _object = {
          item: item
        };

        if (item.organizationId && item.pricingTableId) {
          let url = this.baseapi.apiLink('LinkCustomers');
          let call = this.baseapi.post(url + "/cadastrar", _object);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');

            if (resp.data.errors) {
              this.toast.error('Item Não cadastrado!', resp.data.errors[0].detail, {
                positionClass: 'toast-bottom-right'
              });
              return;
            }

            ;
            this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.listLinkCustomer(idTablePricing);
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não cadastrado!', 'Erro ao cadastrar dados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },

      removeLinkUser(id) {
        let idTablePricing = Ember.get(this, 'currentIdTablePricing');
        console.log(id);

        if (id) {
          let url = this.baseapi.apiLink('LinkCustomers');
          let call = this.baseapi.delete(url + '/' + id);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.listLinkCustomer(idTablePricing);
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },

      setCliente(item) {
        Ember.set(this, 'dataClient', item);
      },

      setOrganization(item) {
        Ember.set(this, 'dataOrganization', item);
      }

    }
  });

  _exports.default = _default;
});