define("printpost/routes/organization/edit", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      // let id_param = getUrlParameters("id", "", true);
      let date = new Date();
      let monthStr = this.baseapi.padStr(date.getMonth() + 1, 2);
      let date1 = date.getFullYear() + "-" + monthStr + "-01T00:00";
      let date2 = date.getFullYear() + "-" + monthStr + "-30T00:00";
      var url = this.baseapi.apiLink('Organizations/list');
      var dataInvoiceConfig = {
        creditLimit: '0,00',
        dueDate: '',
        period: [date1, date2]
      };
      var dataFtpReturn = {
        ftpHost: '',
        ftpPort: '',
        ftpUser: '',
        ftpPass: '',
        active: false,
        beginHour: [],
        dayWeeks: {
          sun: false,
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false
        }
      };
      var clickMassaOptions = {
        active: 0,
        url: '',
        token: ''
      };
      var dataOptions = {
        emailAddress: [],
        emailReplyAddress: [],
        viewOrganizationData: 'unify',
        invoiceOrganizationData: 'unify',
        smsServiceId: '1062',
        validateWhatsapp: 0,
        clickmassa: clickMassaOptions,
        sendFtpReturn: dataFtpReturn
      };
      let item = {
        username: "",
        cnpj: "",
        email: "",
        phone: "",
        razaoSocial: "",
        inscricaoEstadual: "",
        options: dataOptions,
        invoiceConfig: dataInvoiceConfig
      }; //menus organization

      var url_menus = this.baseapi.apiLink(`MenuRoles/list?id=${queryParams.id}&organization=1`);
      var list_menus = [];
      const obj_menus = await this.baseapi.getHttp(url_menus).then(m => m.data);

      if (obj_menus.length > 0) {
        obj_menus.forEach(function (item) {
          if (!item.menuRole) {
            item.menuRole = {
              order: ""
            };
          }
        });
        list_menus = obj_menus;
      } //address vars


      var organizationAddress = {
        id: '',
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        complemento: '',
        numero: '',
        cidadecep: '',
        userId: '',
        type: 'ORGANIZATION_ADDRESS'
      };
      var obj_address = [];
      let url_address = this.baseapi.apiLink('Addresses/list');

      if (queryParams && queryParams.id) {
        let query = {
          filter: {
            where: {
              id: queryParams.id
            }
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        url += "?" + strquery;
        const obj = await this.baseapi.getHttp(url).then(r => r.data);
        item = obj.find(x => x.id == queryParams.id);
        if (!item.invoiceConfig) item.invoiceConfig = dataInvoiceConfig;
        if (!item.options) item.options = dataOptions;

        if (item.options && item.options.clickmassa) {
          clickMassaOptions = item.options.clickmassa;
        }

        if (item.options && !item.options.sendFtpReturn) {
          item.options.sendFtpReturn = dataOptions.sendFtpReturn;
        }

        if (!item.options.emailReplyAddress) item.options.emailReplyAddress = []; //address

        url_address += "?filter[where][type]=ORGANIZATION_ADDRESS&filter[where][organizationId]=" + queryParams.id;
        obj_address = await this.baseapi.getHttp(url_address).then(resp => resp.data);
        let addressOrg = obj_address.find(x => x.type.toUpperCase() == 'ORGANIZATION_ADDRESS');
        organizationAddress = addressOrg ? addressOrg : organizationAddress;
      }

      return Ember.RSVP.hash({
        item: item,
        clickMassaOptions: clickMassaOptions,
        address: obj_address,
        menus: list_menus,
        organizationAddress: organizationAddress
      });
    }
  });

  _exports.default = _default;
});