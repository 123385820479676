define("printpost/components/app-footer", ["exports", "ember-data", "printpost/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    localVersion: Ember.computed(function () {
      var version = _environment.default.APP.appVersion ? _environment.default.APP.appVersion : '0.0.0';
      return version;
    }),
    appVersion: Ember.computed(function () {
      let url = this.baseapi.apiLink('app/version');
      return _emberData.default.PromiseObject.create({
        promise: this.baseapi.getHttp(url).then(x => x.data.version)
      });
    }).property().volatile(),

    didRender() {
      this._super(...arguments);

      let url = this.baseapi.apiLink('app/version');

      _emberData.default.PromiseObject.create({
        promise: this.baseapi.getHttp(url).then(x => x.data)
      }).then(resp => {
        let version = resp.version;
        let local = this.get('localVersion');
        if (version != local) this.send('toggleToast');
      });
    },

    actions: {
      reload() {
        let url = this.baseapi.apiLink('app/version');

        _emberData.default.PromiseObject.create({
          promise: this.baseapi.getHttp(url).then(x => x.data)
        }).then(resp => {
          let version = resp.version;
          let local = this.get('localVersion');
          let keyAuthStore = 'ember_simple_auth-session';

          if (version != local) {
            var sessionStorage = JSON.parse(localStorage.getItem(keyAuthStore));
            sessionStorage.authenticated.appVersion = version;
            localStorage.setItem(keyAuthStore, JSON.stringify(sessionStorage));
          }

          window.location.reload(true);
        });
      },

      toggleToast() {
        document.getElementById('supertoast').classList.toggle('d-none');
      }

    }
  });

  _exports.default = _default;
});