define("printpost/components/radio-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    displayName: Ember.computed('randnumber', function () {
      return (Math.random() * 99999).toString();
    }),
    actions: {
      doAction(value) {
        this.dataEvent(value);
      }

    }
  });

  _exports.default = _default;
});