define("printpost/validations/user", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    username: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 3,
      max: 40
    })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 3,
      max: 40
    }), (0, _validators.validateFormat)({
      type: 'email'
    })] // terms: [
    //   validateInclusion({
    //     list: [true],
    //     message: "Please accept the terms and conditions!"
    //   })
    // ],

  };
  _exports.default = _default;
});