define("printpost/components/labeled-radio-button", ["exports", "ember-radio-button/components/labeled-radio-button"], function (_exports, _labeledRadioButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _labeledRadioButton.default;
    }
  });
});