define("printpost/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function (attr) {
      return Ember.String.camelize(attr);
    },
    keyForRelationship: function (rawKey
    /*, relationship*/
    ) {
      //console.log('-------> relationship', relationship);
      return Ember.String.camelize(rawKey);
    },

    normalizeQueryResponse(store, clazz, payload) {
      const result = this._super(...arguments);

      result.meta = result.meta || {};

      if (payload.links) {
        result.meta.pagination = this.createPageMeta(payload.links);
      }

      return result;
    },

    createPageMeta(data) {
      let meta = {};
      Object.keys(data).forEach(type => {
        const link = data[type];
        meta[type] = {};
        let a = document.createElement('a');
        a.href = link;
        a.search.slice(1).split('&').forEach(pairs => {
          const [param, value] = pairs.split('=');

          if (param == 'page%5Bnumber%5D') {
            meta[type].number = parseInt(value);
          }

          if (param == 'page%5Bsize%5D') {
            meta[type].size = parseInt(value);
          }
        });
        a = null;
      });
      return meta;
    }

  });

  _exports.default = _default;
});