define("printpost/controllers/menus/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataItem: Ember.computed('model', function () {
      var model = this.get('model.item');
      return model;
    }),
    dataListIcon: Ember.computed('model.listIcons', function () {
      var model = this.get('model.listIcons');
      return model;
    }),
    dataList: Ember.computed('model.list', function () {
      let list = this.get('model.data');
      return list;
    }),
    actions: {
      salvar: function () {
        let item_data = Ember.get(this, 'dataItem');
        let item = {
          "id": item_data.id,
          "name": item_data.name,
          "icon": item_data.icon,
          "order": item_data.order,
          "url": item_data.url,
          "active": item_data.active,
          "submenuId": item_data.submenuId,
          "description": item_data.description
        };
        let _object = {
          data: {
            type: "Menu",
            attributes: item
          }
        };

        if (item.name.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink('Menus');
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.transitionToRoute('menus.list');
            }, error => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
            });
          } else {
            this.preloader.toggleClass('d-none');
            let url = this.baseapi.apiLink('Menus/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
            }, error => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
            });
          }
        }
      },
      setSubmenuidSelect: function () {
        let submenuId = this.baseapi.getFieldSelectValue("menupai_selection");

        if (submenuId != "0") {
          Ember.set(this, 'dataItem.submenuId', submenuId);
        }
      },
      apagar: function () {
        let item_data = Ember.get(this, 'dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink('Menus');
          let call = this.baseapi.delete(url + '/' + item_data.id);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('menus.list');
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },
      setIconValue: function (value) {
        Ember.set(this, 'dataItem.icon', value);
      },

      setFatherMenu(item) {
        this.set('model.fatherMenu', item);
        if (item.id != 'ITEM_PAI') this.set('dataItem.submenuId', item.id);else if (item.id == 'ITEM_PAI') this.set('dataItem.submenuId', '');
      }

    }
  });

  _exports.default = _default;
});