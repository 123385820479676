define("printpost/routes/manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      tags: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});