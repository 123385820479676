define("printpost/routes/services/new", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    accountSubaccount: Ember.inject.service(),
    model: async function () {
      let user = this.baseapi.getCurrentUser();
      let optionPrinted = false;
      let url_prices = this.baseapi.apiLink('LinkCustomers/list');
      var obj_prices = [];
      const obj_prices_customer = await this.baseapi.getHttp(url_prices).then(resp => resp.data); //account subaccount

      const obj_subaccount = await this.accountSubaccount.listAccountSubaccount();
      var query_general = {
        filter: {
          where: {
            pricingTableType: 'general_table',
            active: true
          },
          include: ['channel']
        }
      };
      var strquery_general = (0, _globalFunctions.apiLinkQuery)(query_general);
      let url_price_table = this.baseapi.apiLink('PricingTables/find');
      const obj_prices_general = await this.baseapi.getHttp(url_price_table + '?' + strquery_general).then(resp => resp.data);

      if (obj_prices_customer.length > 0) {
        obj_prices = obj_prices_customer;
      } else {
        obj_prices = obj_prices_general;
      }

      if (user.type == this.baseapi.getAdmValue()) {
        obj_prices = obj_prices_general;
      }

      optionPrinted = obj_prices.find(x => x.channelValue == "carta") ? true : false;
      var channelSimulator = [];
      obj_prices.map(item => {
        var rsi = {
          channel: item.channel,
          quantity: 0,
          channelId: item.channelId,
          channelValue: item.channelValue,
          options: item.options
        };
        channelSimulator.push(rsi);
      }); //papper weight

      let url_papper_weight = this.baseapi.apiLink('PapperWeights/list');
      const obj_papper_weight = await this.baseapi.getHttp(url_papper_weight).then(resp => resp.data); //account

      let url_account = this.baseapi.apiLink('Accounts/list');
      const obj_account = await this.baseapi.getHttp(url_account).then(resp => resp.data); //cost center

      var url_costcenter = this.baseapi.apiLink('CostCenters/list');
      const obj_costcenter = await this.baseapi.getHttp(url_costcenter).then(r => r.data);
      var computedItens = {
        dataPrintOptions: {
          format: '',
          grammage: '',
          papper: '',
          formatValue: '',
          sendValue: '',
          colored: false,
          blackWhite: false
        }
      };
      return Ember.RSVP.hash({
        prices: obj_prices,
        totalValue: "0,00",
        optionPrinted: optionPrinted,
        papperWeight: obj_papper_weight,
        accounts: obj_account,
        obj_subaccount: obj_subaccount,
        obj_prices_general: obj_prices_general,
        obj_costcenter: obj_costcenter,
        request_tags: [],
        channelSimulator: channelSimulator,
        computedItens: computedItens
      });
    },
    actions: {
      routerReload: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});