define("printpost/components/drag-headerbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({// classNames: ['drag-headerbox'],
    // attributeBindings: ['draggable'],
    // draggable: true,
    // x_header: 0,
    // y_header: 0,
    // positionChanged: Ember.observer('x_header', 'y_header', function(){
    //   this.$().css({
    //     left: `${this.get('x_header')}px`,
    //     top: `${this.get('y_header')}px`,
    //   });
    // }),
    // dragStart(e_header) {
    //   var x_header = parseInt(this.$().css('left')) - e_header.originalEvent.clientX;
    //   var y_header = parseInt(this.$().css('top')) -  e_header.originalEvent.clientY;
    //   e_header.originalEvent.dataTransfer.setData("position", `${x_header},${y_header}`);
    // },
    // windowDrop(e_header) {
    //   // origin
    //   // var xy = e.dataTransfer.getData("position").split(',');
    //   // this.setProperties({
    //   //   x: `${e.originalEvent.clientX + parseInt(xy[0])}`,
    //   //   y: `${e.originalEvent.clientY + parseInt(xy[1])}`,
    //   // });
    //   console.log(e_header);
    //   var xy_header = e_header.dataTransfer.getData("position").split(',');
    //   var area_header = document.getElementById('cont_area').getBoundingClientRect();
    //   //console.log(area); 
    //   var target_x_header = (area_header.right -  area_header.left) - 77;
    //   var target_y_header = (area_header.bottom - area_header.top) - 5;
    //   //perimetro x
    //   var new_x_header = (e_header.originalEvent.clientX + parseInt(xy_header[0])) >= target_x_header  
    //   ? target_x_header 
    //   : (e_header.originalEvent.clientX + parseInt(xy_header[0]));
    //   new_x_header = new_x_header < area_header.left ? (area_header.left + 5) : new_x_header;
    //   //console.log(new_x); 
    //   //perimetro y
    //   var new_y_header = (e_header.originalEvent.clientY + parseInt(xy_header[1])) >= target_y_header  
    //   ? target_y_header 
    //   : (e_header.originalEvent.clientY + parseInt(xy_header[1]));
    //   new_y_header = new_y_header < area_header.top ? (area_header.top + 5) : new_y_header;
    //   console.log(new_y_header + " | " + new_x_header);
    //   this.setProperties({
    //     x_header: `${new_x_header}`,
    //     y_header: `${new_y_header}`,
    //   });
    // },
    // windowDragOver(e_header) {
    //   e_header.originalEvent.preventDefault();
    // },
    // didRender() {
    //   var self = this;
    //   $(document)
    //     .on('drop', $.proxy(self.windowDrop, self))
    //     .on('dragover', $.proxy(self.windowDragOver, self));
    // },
    // didDestroyElement() {
    //   $(document)
    //     .off('drop')
    //     .off('dragover');
    // }
  });

  _exports.default = _default;
});