define("printpost/routes/carteiras/multicanal", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: async function () {
      //wallets
      var url_wallet = this.baseapi.apiLink('Wallets/list');
      var query_wallet = {
        filter: {
          fields: {
            name: true,
            description: true,
            userId: true,
            id: true
          },
          limit: 18
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query_wallet);
      const listWallets = await this.baseapi.getHttp(url_wallet + '?' + strquery).then(r => r.data); //data return

      let objDate = new Date();
      let strDateBegin = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-01';
      var query_reqreturn = {
        filter: {
          where: {
            and: [{
              createdAt: {
                gt: new Date(strDateBegin)
              }
            }, {
              createdAt: {
                lt: new Date()
              }
            }]
          }
        }
      };
      var strquery_reqreturn = (0, _globalFunctions.apiLinkQuery)(query_reqreturn);
      var url_typereturn = this.baseapi.apiLink('RequestReturns/data-dashboard');
      var objDataReturn = {
        carta: [],
        cartaNotReceive: 0,
        cartaReceive: 0,
        cartaAll: 0,
        heightCarta: 230,
        email: [],
        emailNotReceive: 0,
        emailReceive: 0,
        emailAll: 0,
        heightEmail: 230,
        sms: [],
        smsNotReceive: 0,
        smsReceive: 0,
        smsAll: 0,
        heightSms: 230
      };
      await this.baseapi.getHttp(url_typereturn + "?" + strquery_reqreturn).then(r => {
        var obj = r.data;

        if (obj) {
          obj.forEach((element, index) => {
            var par = index % 2 == 0 ? true : false;

            if (element['carta']) {
              objDataReturn.carta = element['carta'];
              let sumHeigh = 40 * objDataReturn.carta.length;
              objDataReturn.heightCarta += sumHeigh;
              objDataReturn.cartaAll += element['cartaAll'];
              objDataReturn.cartaNotReceive += element['cartaNotReceive'];
              objDataReturn.cartaReceive += element['cartaReceive'];
              objDataReturn.carta.forEach((odr_c, index_c) => {
                var par = index_c % 2 == 0 ? true : false;
                odr_c.isPar = par;
              });
            } else if (element['sms']) {
              objDataReturn.sms = element['sms'];
              let sumHeigh = 40 * objDataReturn.sms.length;
              objDataReturn.heightSms += sumHeigh;
              objDataReturn.smsAll += element['smsAll'];
              objDataReturn.smsNotReceive += element['smsNotReceive'];
              objDataReturn.smsReceive += element['smsReceive'];
              objDataReturn.sms.forEach((odr_c, index_c) => {
                var par = index_c % 2 == 0 ? true : false;
                odr_c.isPar = par;
              });
            } else if (element['email']) {
              objDataReturn.email = element['email'];
              let sumHeigh = 40 * objDataReturn.email.length;
              objDataReturn.heightEmail += sumHeigh;
              objDataReturn.emailAll += element['emailAll'];
              objDataReturn.emailNotReceive += element['emailNotReceive'];
              objDataReturn.emailReceive += element['emailReceive'];
              objDataReturn.email.forEach((odr_c, index_c) => {
                var par = index_c % 2 == 0 ? true : false;
                odr_c.isPar = par;
              });
            }
          });
          objDataReturn.heightCarta = String(objDataReturn.heightCarta) + 'px';
          objDataReturn.heightEmail = String(objDataReturn.heightEmail) + 'px';
          objDataReturn.heightSms = String(objDataReturn.heightSms) + 'px';
        }
      });
      return Ember.RSVP.hash({
        dataWallets: listWallets,
        dataReturn: objDataReturn
      });
    }
  });

  _exports.default = _default;
});