define("printpost/routes/upload", ["exports", "ember-concurrency", "printpost/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    debugger: Ember.inject.service(),
    session: Ember.inject.service(),
    // model: function (){
    //   return {
    //     file: this.get('fileTask').perform()
    //   };
    // },
    // fileTask: task(function *(){
    //   let file = yield this.get('store').query('file', {});
    //   return file;
    // }).keepLatest(),
    uploadFile: (0, _emberConcurrency.task)(function* (file) {
      let post_data = {
        name: Ember.get(file, 'name'),
        size: Ember.get(file, 'size')
      };

      try {
        /*
        file.readAsDataURL().then(function (url) {
          //
        });
        //*/
        let token = this.get('session.data.authenticated.access');
        let opts = {
          'accepts': 'application/json,application/vnd.api+json',
          'headers': {
            'Authorization': 'Bearer ' + token
          },
          'data': post_data
        };
        let urlbase = [_environment.default.APP.API.host, _environment.default.APP.API.namespace].join('/');
        let response = yield file.upload(urlbase + '/files/upload', opts);

        if (response) {// print(response.body.data.attributes.file);
        }
      } catch (e) {// fileRecord.rollback();
      } finally {
        this.refresh();
      }
    }).maxConcurrency(3).enqueue(),
    actions: {
      uploadImage(file) {
        Ember.get(this, 'uploadFile').perform(file);
      },

      deleteFile(file) {
        file.destroyRecord();
      },

      filesReload() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});