define("printpost/controllers/services/system", ["exports", "printpost/controllers/services", "printpost/resources/highcharts-theme"], function (_exports, _services, _highchartsTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _services.default.extend({
    chartOptions: Ember.computed('model.{chart,title,xAxis,yAxis}', function () {
      return {
        chart: this.get('model.chart'),
        title: this.get('model.title'),
        xAxis: this.get('model.xAxis'),
        yAxis: this.get('model.yAxis')
      };
    }),
    lineOptions: Ember.computed(function () {
      return {
        tooltips: {
          backgroundColor: 'rgba(0,0,0,0.9)',
          titleFontColor: 'rgb(255,255,255)',
          bodyFontColor: 'rgba(255,255,255,0.8)',
          yPadding: 6
        },
        mode: 'index',
        intersect: false
      };
    }),
    chartData: Ember.computed(function () {
      return {
        labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
        datasets: [{
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          borderColor: '#6a1095',
          backgroundColor: '#db8bff',
          fill: 'start',
          spanGaps: false
        }]
      };
    }),
    loadAverage: Ember.computed(function () {
      return {
        labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
        datasets: [{
          label: '',
          data: [20, 10, 3, 5, 13, 12],
          borderColor: 'rgba(38, 115, 199, 1)',
          backgroundColor: 'rgba(38, 115, 199, 0.45)',
          fill: 'start',
          spanGaps: false
        }]
      };
    }),
    wordcloud: Ember.computed('model.{wordcloud}', function () {
      return this.get('model.wordcloud');
    }),
    publishers: Ember.computed(function () {
      return {
        top: [{
          name: '',
          description: '',
          image_url: ''
        }]
      };
    }),
    counters: Ember.computed('model.{counters}', function () {
      let json_data = this.get('model.counters');
      let mode = 'nearest';
      let intersect = true;
      json_data.twitter.hours.chartjs = {
        options: {
          responsive: true,
          tooltips: {
            backgroundColor: 'rgba(0,0,0,0.8)',
            titleFontColor: 'rgb(255,255,255)',
            titleFontSize: 11,
            caretSize: 4,
            callbacks: {
              label: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                return label;
              }
            },
            yPadding: 2
          },
          hover: {
            mode: mode,
            intersect: intersect
          },
          legend: {
            display: false
          },
          animation: false,
          scales: {
            xAxes: [{
              display: false,
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              display: false,
              gridLines: {
                display: false
              }
            }]
          }
        },
        data: {
          labels: json_data.twitter.hours.series.x,
          datasets: [{
            label: '',
            data: json_data.twitter.hours.series.y,
            borderColor: 'rgba(101, 73, 154, 1)',
            fill: 'start',
            backgroundColor: 'rgba(101, 73, 154, 0.15)',
            spanGaps: false,
            radius: 1
          }]
        }
      };
      return json_data;
    }),
    theme: _highchartsTheme.default
  });

  _exports.default = _default;
});