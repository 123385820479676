define("printpost/routes/retornos", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      type: {
        refreshModel: true,
        replace: true,
        showTitle: undefined
      }
    },

    model(queryParams) {
      var params = queryParams;
      var today = new Date();
      return Ember.RSVP.hash({
        'wallet': this.get('findWalletTask').perform(params),
        'letterReturns': this.get('findLetterReturnsTask').perform(params, today),
        'smsReturns': this.get('findSmsReturnsTask').perform(params, today),
        'emailReturns': this.get('findEmailReturnsTask').perform(params, today),
        'execucaoRequests': this.get('execucaoRequestsTask').perform(params),
        'allReturns': this.get('findAllReturnsTask').perform(params, today)
      });
    },

    execucaoRequestsTask: (0, _emberConcurrency.task)(function* (params) {
      if (params.type) {
        const USER = this.get('session.data.authenticated');
        let request = yield this.store.query('request', {
          filter: {
            where: {
              'walletId': params.type,
              'userId': USER.userId,
              'status': 'Expedição'
            }
          }
        }).then(request => {
          return request;
        });
        return request;
      } else {
        const USER = this.get('session.data.authenticated');
        let request = yield this.store.query('request', {
          filter: {
            where: {
              'userId': USER.userId,
              'status': 'Expedição'
            }
          }
        }).then(request => {
          return request;
        });
        return request;
      }
    }),
    findAllReturnsTask: (0, _emberConcurrency.task)(function* (params, date) {
      var formatedMax = (0, _moment.default)(date).format();
      var days = 7;
      var res = date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000);
      var minDate = new Date(res);
      var formatedMin = (0, _moment.default)(minDate).format();

      if (params.type) {
        let request = yield this.get('store').query('request-return', {
          filter: {
            where: {
              'walletId': params.type,
              'createdAt': {
                "gt": formatedMin,
                "lt": formatedMax
              }
            }
          }
        });
        return request;
      } else {
        let request = yield this.get('store').query('request-return', {
          filter: {
            where: {
              'createdAt': {
                "gt": formatedMin,
                "lt": formatedMax
              }
            }
          }
        }).then(request => {
          return request;
        });
        return request;
      }
    }),
    findWalletTask: (0, _emberConcurrency.task)(function* () {
      const USER = this.get('session.data.authenticated');
      return yield this.get('store').query('wallet', {
        filter: {
          where: {
            'userId': USER.userId
          }
        }
      });
    }),
    findLetterReturnsTask: (0, _emberConcurrency.task)(function* (params, date) {
      var formatedMax = (0, _moment.default)(date).format();
      var days = 7;
      var res = date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000);
      var minDate = new Date(res);
      var formatedMin = (0, _moment.default)(minDate).format();

      if (params.type) {
        let request = yield this.get('store').query('request-return', {
          filter: {
            where: {
              'channel': 'letter',
              'walletId': params.type,
              'createdAt': {
                "gt": formatedMin,
                "lt": formatedMax
              }
            }
          }
        }).then(request => {
          return request;
        });
        return request;
      } else {
        let request = yield this.get('store').query('request-return', {
          filter: {
            where: {
              'channel': 'letter',
              'createdAt': {
                "gt": formatedMin,
                "lt": formatedMax
              }
            }
          }
        }).then(request => {
          return request;
        });
        return request;
      }
    }),
    findSmsReturnsTask: (0, _emberConcurrency.task)(function* (params, date) {
      var formatedMax = (0, _moment.default)(date).format();
      var days = 7;
      var res = date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000);
      var minDate = new Date(res);
      var formatedMin = (0, _moment.default)(minDate).format();

      if (params.type) {
        let request = yield this.get('store').query('request-return', {
          filter: {
            where: {
              'channel': 'sms',
              'walletId': params.type,
              'createdAt': {
                "gt": formatedMin,
                "lt": formatedMax
              }
            }
          }
        }).then(request => {
          return request;
        });
        return request;
      } else {
        let request = yield this.get('store').query('request-return', {
          filter: {
            where: {
              'channel': 'sms',
              'createdAt': {
                "gt": formatedMin,
                "lt": formatedMax
              }
            }
          }
        }).then(request => {
          return request;
        });
        return request;
      }
    }),
    findEmailReturnsTask: (0, _emberConcurrency.task)(function* (params, date) {
      var formatedMax = (0, _moment.default)(date).format();
      var days = 7;
      var res = date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000);
      var minDate = new Date(res);
      var formatedMin = (0, _moment.default)(minDate).format();

      if (params.type) {
        let request = yield this.get('store').query('request-return', {
          filter: {
            where: {
              'channel': 'email',
              'walletId': params.type,
              'createdAt': {
                "gt": formatedMin,
                "lt": formatedMax
              }
            }
          }
        }).then(request => {
          return request;
        });
        return request;
      } else {
        let request = yield this.get('store').query('request-return', {
          filter: {
            where: {
              'channel': 'email',
              'createdAt': {
                "gt": formatedMin,
                "lt": formatedMax
              }
            }
          }
        }).then(request => {
          return request;
        });
        return request;
      }
    })
  });

  _exports.default = _default;
});