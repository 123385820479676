define("printpost/storages/template-selected", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Storage = _object.default.extend();

  Storage.reopenClass({
    initialState() {
      return {
        editor_mode: '',
        tags: [],
        email: {
          html: '',
          json: {}
        },
        sms: {
          html: '',
          json: {}
        },
        carta: {
          html: '',
          json: {}
        },
        carta_verso: {
          html: '',
          json: {}
        },
        carta_pre_impresso: {
          html: '',
          json: {}
        }
      };
    }

  });
  var _default = Storage;
  _exports.default = _default;
});