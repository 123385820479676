define("printpost/templates/sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bWiEpT8D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-signing\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"signin-box visible no-border\"],[11,\"id\",\"login-box\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"signin-brand\"],[9],[0,\"\\n      \"],[4,\"url-logo\",null,null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"widget-body\"],[9],[0,\"\\n\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"space-8\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[4,\"whitelabel-banner\",null,null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\\n\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/sign.hbs"
    }
  });

  _exports.default = _default;
});