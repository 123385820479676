define("printpost/services/debugger", ["exports", "printpost/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isLoggerActive = _environment.default.environment === 'development';

  var _default = Ember.Service.extend({
    info: function (message) {
      if (isLoggerActive) Ember.Logger.info(message);
    },
    debug: function (message) {
      if (isLoggerActive) Ember.Logger.warn('DEBUG: ', message);
    },
    error: function (message) {
      if (isLoggerActive) Ember.Logger.error(message);
    }
  });

  _exports.default = _default;
});