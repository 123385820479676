define("printpost/routes/templates/new", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //
  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),

    model(params) {
      return Ember.RSVP.hash({
        'base': this.get('baseTemplateTask').perform(params.id),
        'wallet': this.get('walletTask').perform(),
        'images': this.get('getUserImages').perform()
      });
    },

    baseTemplateTask: (0, _emberConcurrency.task)(function* (id) {
      let request = yield this.get('store').findRecord('baseTemplate', id, {
        include: 'channel'
      });
      return request;
    }),
    walletTask: (0, _emberConcurrency.task)(function* () {
      const USER = this.get('session.data.authenticated');
      let request = yield this.get('store').query('wallet', {
        filter: {
          where: {
            'userId': USER.userId
          }
        }
      }).then(request => {
        return request;
      });
      return request;
    }),
    getUserImages: (0, _emberConcurrency.task)(function* () {
      let userId = this.get('session.data.authenticated.userId');
      let request = yield this.get('store').query('user-image', {
        where: {
          'userId': userId
        }
      }).then(request => {
        return request;
      });
      return request;
    }).keepLatest()
  });

  _exports.default = _default;
});