define("printpost/controllers/services/payment", ["exports", "printpost/controllers/services", "ember-uuid", "printpost/utils/globalFunctions", "ember-concurrency", "printpost/config/environment"], function (_exports, _services, _emberUuid, _globalFunctions, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = Ember.Object.extend({
    printingOptions: null,
    emailContact: null,
    emailSubject: null,
    walletId: null,
    empty_resp: null
  });

  var _default = _services.default.extend({
    variables: variables.create({
      printingOptions: null
    }),
    session: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    notification: Ember.inject.service('toast'),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),

    init() {
      this._super(...arguments);

      const USER = this.get('session.data.authenticated');
      Ember.set(this.newRequest, 'paymentMethod', USER.account.payment);

      if (USER.account.payment != "faturado") {
        Ember.set(this.newRequest, 'paymentMethod', 'antecipado');
      }

      Ember.set(this, 'whatsapp', Ember.get(this.newRequest, 'whatsappService'));
      Ember.set(this, 'sms', Ember.get(this.newRequest, 'smsService'));
      Ember.set(this, 'email', Ember.get(this.newRequest, 'emailService'));
      Ember.set(this, 'letter', Ember.get(this.newRequest, 'letterService'));
    },

    loadInit: Ember.computed(function () {
      setTimeout(() => {
        this.set('newRequest', this.get('model.request'));
      }, 300);
      return 123;
    }).property().volatile(),
    unmasked: Ember.computed('unmasked', function () {
      return '';
    }),
    masked: Ember.computed('masked', function () {
      return '';
    }),
    cardType: Ember.computed('cardType', function () {
      return '';
    }),
    creditCardInfo: Ember.computed('creditCardInfo', function () {
      return {
        cardHolderName: '',
        cardNumber: '',
        cardNumberFormat: '',
        cardMounthExpire: '',
        cardYearExpire: '',
        cardCVV: '',
        cardBrand: 'visa',
        cardInstallments: 1
      };
    }),
    dataPrices: Ember.computed('model.prices', function () {
      return this.get('model.prices');
    }),
    dataWallet: Ember.computed('model.wallet', function () {
      return this.get('model.wallet');
    }),
    dataPrintOptions: Ember.computed('model.printingOptions', function () {
      return this.get('model.printingOptions');
    }).property().volatile(),
    dataSimulatorCEP: Ember.computed('dataSimulatorCEP', function () {
      return '';
    }),
    dataSimulatorCEPFreight: Ember.computed('dataSimulatorCEPFreight', function () {
      return [];
    }),
    dataAddresses: Ember.computed('model.address', function () {
      return this.get('model.address');
    }).property().volatile(),
    dataEndereco: Ember.computed('dataEndereco', function () {
      return {
        id: '',
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        complemento: '',
        numero: '',
        cidadecep: '',
        type: 'ADDRESS_FREIGHT'
      };
    }),
    dataEnderecoPayment: Ember.computed('dataEnderecoPayment', function () {
      return {
        id: '',
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        complemento: '',
        numero: '',
        cidadecep: ''
      };
    }),
    dataEnderecoFrete: Ember.computed('dataEnderecoFrete', function () {
      return {
        id: '',
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        complemento: '',
        numero: '',
        cidadecep: '',
        type: 'ADDRESS_FREIGHT'
      };
    }),
    modelWallet: Ember.computed('model.wallet', function () {
      return this.get('model.wallet');
    }),
    totalValue: Ember.computed('totalValue', function () {
      return "0,00";
    }),
    totalItems: Ember.computed(function () {
      let totalItem = 0;

      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      let quantity = _newReq.quantity;
      if (_newReq.letterService) totalItem += quantity.carta;
      if (_newReq.emailService) totalItem += quantity.email;
      if (_newReq.smsService) totalItem += quantity.sms;
      if (_newReq.whatsappService) totalItem += quantity.whatsapp;
      if (_newReq.registryOfficeService) totalItem += quantity.registry_office;
      return totalItem;
    }).property().volatile(),
    testeValores: Ember.computed('model.smsValueCalculate', function () {
      let r = this.baseapi.numberToReal(0);
      return r;
    }),
    qtdSMSAllComputed: Ember.computed('model.qtdSMSAll', function () {
      return this.get('model.qtdSMSAll');
    }).property().volatile(),
    excededSMSComputed: Ember.computed('model.excededSMS', function () {
      return this.get('model.excededSMS');
    }).property().volatile(),
    praticatePricesComputed: Ember.computed('model.praticatePrices', function () {
      return this.get('model.praticatePrices');
    }).property().volatile(),
    unitValueFloat: [],
    parcelas: Ember.computed('parcelas', function () {
      let saida = [];
      let total = this.baseapi.realStringConvertDecimal(Ember.get(this, 'totalValue'));
      let strtotal = Ember.get(this, 'totalValue');

      for (let i = 1; i <= 6; i++) {
        let v = total / i;
        let r = v.toLocaleString('pt-BR', {
          maximumFractionDigits: 2
        });
        let element = {
          "selectText": i + "x de R$ " + r + " - Total de R$ " + strtotal,
          "selectId": i,
          "selectValue": i
        };
        saida.push(element);
      }

      return saida;
    }).property().volatile(),
    paymentMethod: Ember.computed('paymentMethod', function () {
      return 'credit-card';
    }),
    reloadAddress: function () {
      this.preloader.toggleClass('d-none');
      let url_address = this.baseapi.apiLink('Addresses/list');
      this.baseapi.getHttp(url_address).then(resp => {
        Ember.set(this, 'dataAddresses', resp.data);
        this.preloader.toggleClass('d-none');
      });
    },
    dataUser: Ember.computed('dataUser', function () {
      const d = this.get('session.data.authenticated');
      return d.account;
    }).property().volatile(),
    requestAttachs: Ember.computed('itemRequestAttachs', function () {
      let r = Ember.get(this, 'model.rawFiles');
      r = r == null ? [] : r;
      return r;
    }),
    sendRawFile: (0, _emberConcurrency.task)(function (file, reqId) {
      Ember.set(this, 'isCreateLoading', true);
      Ember.set(this, 'isChangeRawFile', true);
      let form = new FormData();
      let userId = this.get('newRequest.userId');
      form.set("type", "request_attach_observation");
      form.set("requestId", reqId);
      form.set("userId", userId);
      form.append("file", file.blob);
      let organizationId = Ember.get(this, 'newRequest.organizationId');
      var dataUser = this.get('dataUser');

      if (organizationId) {
        form.set("organizationId", organizationId);
      } else if (dataUser.organizationId) {
        form.set("organizationId", dataUser.organizationId);
      }

      let url = this.baseapi.apiLink("rawFiles");
      url += "/upload-file";
      this.baseapi.postFormData(url, form).then(rawObj => {
        let p = Ember.get(this, 'requestAttachs');
        let u = [rawObj.data];
        p.forEach(item => {
          u.push(item);
        });
        Ember.set(this, 'requestAttachs', u);
        Ember.set(this, 'isCreateLoading', false);
        Ember.set(this, 'isChangeRawFiles', false);
      }, error => {
        this.variables.set('empty_resp', error);
        this.toast.error('', 'Erro ao enviar o arquivo, tente novamente.', {
          positionClass: 'toast-bottom-right'
        });
      });
    }).keepLatest(),
    removeFileTask: (0, _emberConcurrency.task)(function (file) {
      let url = this.baseapi.apiLink('rawFiles');
      url += '/remove/' + file.id;
      Ember.set(this, 'isChangeRawFiles', true);
      this.baseapi.delete(url).then(resp => {
        if (resp.data.undefined.success) {
          let data = Ember.get(this, 'requestAttachs');
          let dataResult = [];

          for (var i = 0; i < data.length; i++) {
            if (data[i].id != file.id) {
              dataResult.push(data[i]);
            }
          }

          Ember.set(this, 'requestAttachs', dataResult);
          Ember.set(this, 'isChangeRawFiles', false);
        } else {
          this.toast.error('', 'Erro ao remover o arquivo', {
            positionClass: 'toast-bottom-right'
          });
          Ember.set(this, 'isChangeRawFiles', false);
        }
      }, error => {
        this.variables.set('empty_resp', error);
        this.toast.error('', 'Erro ao remover o arquivo', {
          positionClass: 'toast-bottom-right'
        });
        Ember.set(this, 'isChangeRawFiles', false);
      });
    }).keepLatest(),
    contactsSend: Ember.computed('contactSend', function () {
      return {
        emailContact: '',
        smsContact: '',
        whatsappContact: ''
      };
    }),
    myFlatpickrRef: Ember.computed(function () {
      return {
        carta: null,
        email: null,
        sms: null,
        whatsapp: null
      };
    }),
    dateSendRequest: Ember.computed(function () {
      var model = {
        carta: '',
        email: '',
        sms: '',
        whatsapp: ''
      };
      var printOption = Ember.get(this, 'model.request.printingOptions');
      var imediate = {
        carta: printOption.templateLetter.disparoImediato,
        email: printOption.templateEmail.disparoImediato,
        sms: printOption.templateSMS.disparoImediato,
        whatsapp: printOption.templateWhatsapp.disparoImediato
      };
      let data_carta = !printOption.templateLetter.disparoImediato ? printOption.templateLetter.dataDisparo : this.baseapi.returnDate(3, true);
      let data_email = !printOption.templateEmail.disparoImediato ? printOption.templateEmail.dataDisparo : this.baseapi.returnDate(0, true);
      let data_sms = !printOption.templateSMS.disparoImediato ? printOption.templateSMS.dataDisparo : this.baseapi.returnDate(0, true);
      let data_whatsapp = !printOption.templateWhatsapp.disparoImediato ? printOption.templateWhatsapp.dataDisparo : this.baseapi.returnDate(0, true);
      model.carta = data_carta;
      model.email = data_email;
      model.sms = data_sms;
      model.whatsapp = data_whatsapp;
      Ember.set(this, 'changeDateSendMod.originValues', model);
      Ember.set(this, 'changeDateSendMod.imediateValues', imediate);
      return model;
    }).property().volatile(),
    flatMinDate: Ember.computed('flatMinDate', function () {
      var model = {
        carta: '',
        email: '',
        sms: '',
        whatsapp: ''
      };
      model.carta = new Date().setDate(new Date().getDate() + 2);
      model.email = new Date().setMinutes(new Date().getMinutes() + 30);
      model.sms = new Date().setMinutes(new Date().getMinutes() + 20);
      model.whatsapp = new Date().setMinutes(new Date().getMinutes() + 20);
      return model;
    }).property().volatile(),
    flatMaxDate: Ember.computed('flatMaxDate', function () {
      var model = {
        carta: '',
        email: '',
        sms: '',
        whatsapp: ''
      };
      model.carta = new Date().setMonth(new Date().getMonth() + 2, 1);
      model.email = new Date().setMonth(new Date().getMonth() + 2, 1);
      model.sms = new Date().setMonth(new Date().getMonth() + 2, 1);
      model.whatsapp = new Date().setMonth(new Date().getMonth() + 2, 1);
      return model;
    }).property().volatile(),
    changeDateSendMod: Ember.computed(function () {
      let m = {
        mode: '',
        originValues: {
          carta: '',
          email: '',
          sms: '',
          whatsapp: ''
        },
        imediateValues: {
          carta: '',
          email: '',
          sms: '',
          whatsapp: ''
        },
        newValues: {
          carta: '',
          email: '',
          sms: '',
          whatsapp: ''
        },
        dataFlat: {
          carta: '',
          email: '',
          sms: '',
          whatsapp: ''
        }
      };
      return m;
    }),
    changeStorageRequestOptionsTask: function (chave, valor, template) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions[template][chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      let str = `newRequest.printingOptions.${template}.${chave}`;
      Ember.set(this, str, valor);
    },
    changeStorageRequestTask: (0, _emberConcurrency.task)(function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      let str = `newRequest.${chave}`;
      Ember.set(this, str, valor);
    }).keepLatest(),
    savePendentRequest: (0, _emberConcurrency.task)(function* () {
      yield this.baseapi.savePendentRequest().then(x => x);
      this.send('routerReload');
    }),
    whatsAppSuccess: Ember.computed('whatsAppSuccess', function () {
      return null;
    }).property().volatile(),
    whatsAppError: Ember.computed('whatsAppError', function () {
      return null;
    }).property().volatile(),
    walletValidate: Ember.computed('walletValidate', function () {
      return null;
    }).property().volatile(),
    qtdDisponivel: Ember.computed('qtdDisponivel', function () {
      return {
        email: 0,
        sms: 0
      };
    }).property().volatile(),
    economyPercent: Ember.computed('economyPercent', function () {
      return '';
    }).property().volatile(),
    socketValidation: (0, _emberConcurrency.task)(function* () {
      let validateWhatsapp = '';

      if (this.get('newRequest.validateWhatsapp') === true) {
        validateWhatsapp = '?validateWhatsapp=1';
      }

      this.set('model.runningValidation', true);
      var newRequest = JSON.parse(localStorage.getItem('storage:request'));

      if (!newRequest.emailService && !newRequest.smsService) {
        return;
      }

      let urlValidation = this.baseapi.apiLink(`Wallets/validation/${newRequest.walletId}${validateWhatsapp}`);
      this.set('newRequest', newRequest);
      yield this.baseapi.getHttp(urlValidation).then(resp => {
        resp.data;
        this.get('socketGetData').perform();
      }).catch(error => {
        this.toast.error('', 'Erro, informe ao suporte', {
          positionClass: 'toast-bottom-right'
        });
        this.set('model.runningValidation', false);
      });
      return '';
    }).keepLatest(),
    socketGetData: (0, _emberConcurrency.task)(function* () {
      var newRequest = JSON.parse(localStorage.getItem('storage:request'));
      let urlSocket = this.baseapi.apiLink(`sockets/sms-validation?filter[where][id]=${newRequest.walletId}`);
      var tmp_wppSuccess = this.get('whatsAppSuccess');
      var tmp_wppError = this.get('whatsAppError');
      var total = 0;
      var finishedValidation = false;

      while (newRequest.smsService && total < newRequest.quantity.sms || newRequest.emailService && !finishedValidation) {
        tmp_wppError = this.get('whatsAppError');
        tmp_wppSuccess = this.get('whatsAppSuccess');
        yield this.baseapi.getHttp(urlSocket).then(resp => {
          const msg = resp.data;

          if (!msg) {
            return;
          }

          let wppSuccess = {
            label: msg.qtdWhatss.having,
            style: Ember.String.htmlSafe(`width: ${msg.qtdWhatss.pixelsSuccess}px`)
          };
          let wppError = {
            label: msg.qtdWhatss.notHaving,
            style: Ember.String.htmlSafe(`width: ${msg.qtdWhatss.pixelsError}px`)
          };
          this.set('whatsAppSuccess', wppSuccess);
          this.set('whatsAppError', wppError);
          this.set('walletValidate', msg);
          total = msg.qtdWhatss.having + msg.qtdWhatss.notHaving;
          var percentageEconomy = (msg.qtdPhones - msg.qtdTotalValids) * 100 / msg.qtdPhones;
          if (percentageEconomy > 0) this.set('economyPercent', `Você economizou ${this.baseapi.numberToReal(percentageEconomy)}%`);

          if (newRequest.emailService && newRequest.smsService) {
            if (msg.finished && msg.finished.blacklistEmail && msg.finished && msg.finished.blacklistSms && msg.finished && msg.finished.invalidEmail && msg.finished && msg.finished.invalidSms && msg.finished && msg.finished.smsSize) {
              finishedValidation = true;
            }
          } else if (newRequest.emailService) {
            if (msg.finished && msg.finished.blacklistEmail && msg.finished && msg.finished.invalidEmail) {
              finishedValidation = true;
            }
          } else if (newRequest.smsService) {
            if (msg.finished && msg.finished.blacklistSms && msg.finished && msg.finished.invalidSms && msg.finished && msg.finished.smsSize) {
              finishedValidation = true;
            }
          }
        }).catch(error => {
          console.log('stop item');
          this.set('model.runningValidation', false);
          finishedValidation = true;
          this.toast.error('', 'Erro, informe ao suporte', {
            positionClass: 'toast-bottom-right'
          });
        });
        yield this.baseapi.sleep(500);
      }

      if (total == newRequest.quantity.sms || newRequest.multiChannel && finishedValidation || finishedValidation) {
        this.set('model.runningValidation', false);

        if (this.get('newRequest.praticatePrices.sms.quantityExceded') > 0) {
          this.toast.warning('Atenção!', 'Existem mensagens com mais de 160 caracteres que serão cobradas adicionalmente', this.baseapi.toastDontClear());
        }

        let walletValidate = this.get('walletValidate'); //let qtdSms = walletValidate.qtdWhatss.total ?  walletValidate.qtdWhatss.total : newRequest.quantity.sms;

        let qtdDisponivel = {
          sms: newRequest.quantity.sms - walletValidate.qtdInvalid.sms - walletValidate.qtdBlacklist.sms,
          email: newRequest.quantity.email - walletValidate.qtdInvalid.email - walletValidate.qtdBlacklist.email
        };
        this.set('qtdDisponivel', qtdDisponivel);
        this.toast.success('', 'Validação concluida com sucesso!', this.baseapi.toastDontClear());
      }

      return '';
    }).keepLatest(),
    changeValidationsTask: function (chave, valor, template) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.validations[template][chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      let str = `newRequest.validations.${template}.${chave}`;
      Ember.set(this, str, valor);
    },
    actions: {
      updateCreditCard(unmasked, masked, cardType) {
        Ember.set(this, 'unmasked', unmasked);
        Ember.set(this, 'creditCardInfo.cardNumber', unmasked);
        let formatNumber = masked.replace('-', ' ').replace('-', ' ').replace('-', ' ');
        Ember.set(this, 'creditCardInfo.cardNumberFormat', formatNumber);
        Ember.set(this, 'masked', masked);
        Ember.set(this, 'cardType', cardType.toLowerCase());
        Ember.set(this, 'creditCardInfo.cardBrand', cardType.toLowerCase());
      },

      updateCardMounth(unmasked, masked) {
        Ember.set(this, 'creditCardInfo.cardMounthExpire', masked);
      },

      updateCardYear(unmasked, masked) {
        Ember.set(this, 'creditCardInfo.cardYearExpire', masked);
      },

      updateCardCVV(unmasked, masked) {
        Ember.$('.flip').addClass('card-flip-in');
        Ember.set(this, 'creditCardInfo.cardCVV', masked);
      },

      exitBackCard() {
        Ember.$('.flip').removeClass('card-flip-in');
      },

      activeArea(item) {
        if (item == 'boleto-area') {
          Ember.set(this, 'newRequest.paymentMethod', 'boleto');
          Ember.$('#boleto-area').addClass('active');
          Ember.$('#creditcard-area').removeClass('active');
        } else if (item == 'creditcard-area') {
          Ember.set(this, 'newRequest.paymentMethod', 'credit-card');
          Ember.$('#boleto-area').removeClass('active');
          Ember.$('#creditcard-area').addClass('active');
        }
      },

      createRequest: function () {
        this.preloader.toggleClass('d-none');
        let notification = this.get('notification');
        const USER = this.get('session.data.authenticated');
        Ember.set(this.newRequest, 'paymentCode', (0, _emberUuid.v4)());
        Ember.set(this.newRequest, 'customer', Ember.get(USER, 'customer.id'));
        Ember.set(this.newRequest, 'status', 'Análise');
        let sNewRequest = JSON.parse(localStorage.getItem('storage:request'));
        let requestId = localStorage.getItem('newServiceRequestId');
        requestId = requestId == undefined ? 0 : requestId;
        let obj_save = {
          "data": {
            "attributes": sNewRequest,
            "type": "Request"
          }
        };
        let url = this.baseapi.apiLink('Requests');
        let call = null;

        if (sNewRequest.paymentMethod == "antecipado" && sNewRequest.fileDb != "impressao") {
          /** to do */
        } else if (sNewRequest.paymentMethod == "faturado" || sNewRequest.fileDb == "impressao" || sNewRequest.fileDb == "layout") {
          try {
            if (requestId == 0) {
              call = this.baseapi.post(url, obj_save);
              call.then(resp => {
                this.variables.set('empty_resp', resp);
                this.send('clearDataRequest');
                notification.success('', 'Pedido criado com sucesso!', {
                  positionClass: 'toast-bottom-right'
                });
                this.preloader.toggleClass('d-none');
                this.transitionToRoute('services.completed');
              }, error => {
                this.variables.set('empty_resp', error);
                notification.error('', 'O pedido não pode ser criado!', {
                  positionClass: 'toast-bottom-right'
                });
                this.preloader.toggleClass('d-none');
              });
            } else {
              url += "/" + requestId;
              let __rp = obj_save;
              __rp.data.id = requestId;
              __rp.data.attributes.status = "Análise";
              call = this.baseapi.patch(url, __rp);
              call.then(resp => {
                this.variables.set('empty_resp', resp);
                this.send('clearDataRequest');
                notification.success('', 'Pedido criado com sucesso!', {
                  positionClass: 'toast-bottom-right'
                });
                this.preloader.toggleClass('d-none');
                this.transitionToRoute('services.completed');
              }, error => {
                this.variables.set('empty_resp', error);
                notification.error('', 'O pedido não pode ser criado!', {
                  positionClass: 'toast-bottom-right'
                });
                this.preloader.toggleClass('d-none');
              });
            }
          } catch (error) {
            notification.error('', 'O pedido não pode ser criado!', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
          }
        }
      },
      setShippingMethod: function (value) {
        if (value != 'endereco') {
          this.send('setFreightCost', '0,00');
          this.send('clearDataEndereco');
        }

        this.set('newRequest.shippingMethod', value);
        this.set('model.printingOptions.sendValue', value);
      },
      updateSimulatorCEP: function (itemValue, itemFormat) {
        Ember.set(this, 'dataSimulatorCEP', itemValue);
      },
      calculateFrete: function () {
        let cep = Ember.get(this, 'dataSimulatorCEP');
        let options_format = Ember.get(this, 'dataPrintOptions');
        let wallet = Ember.get(this, 'dataWallet');
        let quantity = wallet.addressCount;
        let peso = 1;

        if (w && cep.length > 3) {
          let wd = this.baseapi.realStringConvertDecimal(w.weight);
          peso = quantity * wd / 1000;
          peso = Math.ceil(peso);

          if (peso > 0 && quantity > 0) {
            let url = this.baseapi.apiLink("PricingTables/frete?peso=" + peso + "&cep=" + cep);
            this.baseapi.getHttp(url).then(resp => {
              let v = resp.data;
              Ember.set(this, 'dataSimulatorCEPFreight', v);
            });
          } else {
            this.toast.error('', 'Informe uma quantidade de cartas válidas para calcular o frete.', {
              positionClass: 'toast-bottom-right'
            });
          }
        } else {
          this.toast.error('', 'Informe um CEP para calcular o frete.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      setFreightCost: function (valor) {
        let r = Ember.get(this, 'newRequest.totalCost');
        let fdec = this.baseapi.realStringConvertDecimal(valor);
        let rdec = this.baseapi.realStringConvertDecimal(r);
        rdec += fdec;
        Ember.set(this, 'totalValue', this.baseapi.numberToReal(rdec));
        Ember.set(this, 'newRequest.totalFreight', this.baseapi.numberToReal(fdec));
        this.send('updateParcelas');
      },
      updateParcelas: function () {
        let saida = [];
        let total = this.baseapi.realStringConvertDecimal(Ember.get(this, 'totalValue'));
        let strtotal = Ember.get(this, 'totalValue');

        for (let i = 1; i <= 6; i++) {
          let v = total / i;
          let r = v.toLocaleString('pt-BR', {
            maximumFractionDigits: 2
          });
          let element = {
            "selectText": i + "x de R$ " + r + " - Total de R$ " + strtotal,
            "selectId": i,
            "selectValue": i
          };
          saida.push(element);
        }

        Ember.set(this, 'parcelas', saida);
      },

      buscaCEP() {
        let t = Ember.$('#cep_addres').val();
        t = (0, _globalFunctions.removeSpecialChar)(t).replace("-", "").replace(".", "");
        Ember.$('#cep_addres').val(t);

        if (t.length < 8) {
          this.toast.error('CEP Inválido!', 'Informe CEP válido.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let url = "//viacep.com.br/ws/" + t + "/json";
        let r = this.baseapi.getHttp(url, false);
        r.then(response => {
          let data = response.data;
          Ember.set(this, 'dataEndereco.cep', t);
          Ember.set(this, 'dataEndereco.endereco', data.logradouro);
          Ember.set(this, 'dataEndereco.bairro', data.bairro);
          Ember.set(this, 'dataEndereco.complemento', data.complemento);
          Ember.set(this, 'dataEndereco.cidade', data.localidade);
          Ember.set(this, 'dataEndereco.uf', data.uf);
          Ember.set(this, 'dataEndereco.cidadecep', data.localidade + ' - ' + data.uf);
        });
      },

      setAddress: function (address) {
        let d = {
          id: address.id,
          nome: address.nome,
          endereco: address.endereco,
          bairro: address.bairro,
          cidade: address.cidade,
          uf: address.uf,
          cep: address.cep,
          complemento: address.complemento,
          numero: address.numero,
          cidadecep: address.cidadecep,
          type: 'ADDRESS_FREIGHT'
        };
        Ember.set(this, 'dataEndereco', d);
      },
      setAddressPayment: function (address) {
        let d = {
          id: address.id,
          nome: address.nome,
          endereco: address.endereco,
          bairro: address.bairro,
          cidade: address.cidade,
          uf: address.uf,
          cep: address.cep,
          complemento: address.complemento,
          numero: address.numero,
          cidadecep: address.cidadecep
        };
        Ember.set(this, 'dataEnderecoPayment', d);
      },
      selectAddress: function (address) {
        let g = Ember.get(this, 'newRequest.address');
        Ember.set(this, 'dataSimulatorCEP', address.cep);
        Ember.set(this, 'dataEnderecoFrete', address);
        Ember.set(this, 'dataSimulatorCEPFreight', []);
        let nd = [address];
        g.forEach(item => {
          if (item.type != "ADDRESS_FREIGHT") {
            nd.push(item);
          }
        });
        Ember.set(this, 'newRequest.address', nd);
        this.send('calculateFrete');
      },
      clearDataEndereco: function () {
        let d = {
          id: '',
          nome: '',
          endereco: '',
          bairro: '',
          cidade: '',
          uf: '',
          cep: '',
          complemento: '',
          numero: '',
          cidadecep: '',
          type: 'ADDRESS_FREIGHT'
        };
        Ember.set(this, 'dataEndereco', d);
      },
      salvarEndereco: function () {
        let modelName = 'Address';
        let modelPluralName = 'Addresses';
        this.preloader.toggleClass('d-none');
        let item = Ember.get(this, 'dataEndereco');
        let _object = {
          data: {
            type: modelName,
            attributes: item
          }
        };

        if (item.nome.length > 0 && item.numero > 0 && item.endereco.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink(modelPluralName);
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            call.then(resp => {
              this.variables.set('empty_resp', resp);
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
              this.reloadAddress();
            }, error => {
              this.variables.set('empty_resp', error);
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
            });
          } else {
            let url = this.baseapi.apiLink(modelPluralName + '/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            call.then(resp => {
              this.variables.set('empty_resp', resp);
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              item.id = __rp.data.id;
              this.preloader.toggleClass('d-none');
              this.reloadAddress();
            }, error => {
              this.variables.set('empty_resp', error);
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
            });
          }
        } else {
          this.toast.error('Item Não cadastrado!', 'Verifique os campos obrigatórios!', {
            positionClass: 'toast-bottom-right'
          });
          this.preloader.toggleClass('d-none');
        }
      },
      apagarEndereco: function () {
        let modelPluralName = 'Addresses';
        this.preloader.toggleClass('d-none');
        let item_data = Ember.get(this, 'dataEndereco');

        if (item_data.id) {
          let url = this.baseapi.apiLink(modelPluralName);
          let call = this.baseapi.delete(url + '/' + item_data.id);
          call.then(resp => {
            this.variables.set('empty_resp', resp);
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
          }, error => {
            this.variables.set('empty_resp', error);
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
          });
          this.reloadAddress();
        }
      },
      payment: function () {
        this.preloader.toggleClass('d-none');
        let notification = this.get('notification');
        const USER = this.get('session.data.authenticated');
        Ember.set(this.newRequest, 'paymentCode', (0, _emberUuid.v4)());
        Ember.set(this.newRequest, 'customer', Ember.get(USER, 'customer.id'));
        let sNewRequest = JSON.parse(localStorage.getItem('storage:request'));
        let requestId = localStorage.getItem('newServiceRequestId');
        requestId = requestId == undefined ? 0 : requestId;
        let dataAddressBoleto = Ember.get(this, 'dataEnderecoPayment');
        let dataBoleto = {
          Address: dataAddressBoleto
        };
        let cuser = this.baseapi.getCurrentUser();
        dataBoleto.Address.cpf = cuser.cpf;

        if (requestId) {
          sNewRequest.id = requestId;
        }

        let creditCard = Ember.get(this, 'creditCardInfo');
        let objPost = {
          Request: sNewRequest,
          Payment: creditCard
        };

        if (sNewRequest.paymentMethod == "boleto") {
          objPost.Payment = dataBoleto;
        }

        let url = this.baseapi.apiLink('Requests/pay');
        this.baseapi.post(url, objPost).then(resp => {
          if (resp.data.success) {
            this.preloader.toggleClass('d-none');
            let data = resp.data.success[0];

            if (data.status == 200) {
              let data = resp.data.success[0];
              let dataMsg = data && data.detail ? data.detail : "Pedido criado com sucesso!";
              this.send('clearDataRequest');
              notification.success('', dataMsg, {
                positionClass: 'toast-bottom-right'
              });
              this.transitionToRoute('services.completed');
            } else if (data.status == 205) {
              if (sNewRequest.paymentMethod == "boleto") {
                let data = resp.data.success[0];
                let dataMsg = data && data.detail ? data.detail : "Pedido criado com sucesso!";
                this.send('clearDataRequest');
                notification.success('', dataMsg, {
                  positionClass: 'toast-bottom-right'
                });
                window.open(data.source.Payment.Url, '_blank');
                setTimeout(() => {
                  this.transitionToRoute('services.completed');
                }, 1500);
              } else {
                localStorage.setItem('newServiceRequestId', data.source.RequestId);
                notification.error('', data.message, {
                  positionClass: 'toast-bottom-right'
                });
              }
            }
          } else {
            notification.error('', 'O pedido não pode ser criado! [#1]', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
          }
        }, error => {
          this.variables.set('empty_resp', error);
          notification.error('', 'O pedido não pode ser criado! [#2]', {
            positionClass: 'toast-bottom-right'
          });
          this.preloader.toggleClass('d-none');
        });
      },
      bill: function () {
        let notification = this.get('notification');
        const USER = this.get('session.data.authenticated');
        let sNewRequest = JSON.parse(localStorage.getItem('storage:request'));
        let requestId = localStorage.getItem('newServiceRequestId');
        requestId = requestId == undefined ? 0 : requestId;

        if (sNewRequest.emailService && (!sNewRequest.emailSubject || !sNewRequest.emailContact)) {
          this.toast.error('', 'Não foi informado o ASSUNTO do e-mail ou o email de resposta.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (requestId) {
          sNewRequest.id = requestId;
        }

        if (!sNewRequest.walletId && sNewRequest.typeConfig == "pdf_fechado") {
          sNewRequest.walletId = 'static';
        }

        let objPost = {
          Request: sNewRequest
        };
        this.preloader.toggleClass('d-none');
        let url = this.baseapi.apiLink('Requests/bill');
        this.baseapi.post(url, objPost).then(resp => {
          if (resp.data.success) {
            this.preloader.toggleClass('d-none');
            let data = resp.data.success[0];
            let dataMsg = data && data.detail ? data.detail : "Pedido criado com sucesso!";
            this.send('clearDataRequest');
            notification.success('', dataMsg, {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('services.completed');
          } else {
            notification.error('', resp.data.error.message, {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
          }
        }, error => {
          this.variables.set('empty_resp', error);
          notification.error('', 'O pedido não pode ser criado!', {
            positionClass: 'toast-bottom-right'
          });
          this.preloader.toggleClass('d-none');
        });
      },

      uploadFile(file) {
        let r = localStorage.getItem('newServiceRequestId');

        if (!r) {
          Ember.set(this, 'isChangeRawFiles', true);
          this.baseapi.savePendentRequest().then(data => {
            Ember.get(this, 'sendRawFile').perform(file, data);
            Ember.set(this, 'isChangeRawFiles', false);
          }, error => {
            this.variables.set('empty_resp', error);
            Ember.set(this, 'isChangeRawFiles', false);
          });
        } else {
          Ember.get(this, 'sendRawFile').perform(file, r);
        }
      },

      removeFile(file) {
        let url = this.baseapi.apiLink('rawFiles');
        url += '/remove/' + file.id;
        Ember.set(this, 'isChangeRawFiles', true);
        this.baseapi.delete(url).then(resp => {
          if (resp.data.undefined.success) {
            let data = Ember.get(this, 'requestAttachs');
            let dataResult = [];

            for (var i = 0; i < data.length; i++) {
              if (data[i].id != file.id) {
                dataResult.push(data[i]);
              }
            }

            Ember.set(this, 'requestAttachs', dataResult);
            Ember.set(this, 'isChangeRawFiles', false);
          } else {
            this.toast.error('', 'Erro ao remover o arquivo', {
              positionClass: 'toast-bottom-right'
            });
            Ember.set(this, 'isChangeRawFiles', false);
          }
        }, error => {
          this.variables.set('empty_resp', error);
          this.toast.error('', 'Erro ao remover o arquivo', {
            positionClass: 'toast-bottom-right'
          });
          Ember.set(this, 'isChangeRawFiles', false);
        });
      },

      setPendingApprove() {
        let v = Ember.get(this, 'newRequest.pendingApprove');
        v = v == 1 ? 0 : 1;
        Ember.set(this, 'newRequest.pendingApprove', v);
      },

      sendSMSTest: function (monitoring = null) {
        try {
          var request = JSON.parse(localStorage.getItem("storage:request"));
          var requestOptions = "templateSMS";
          var printingOptionsStorage = request.printingOptions[requestOptions];
          let walletId = Ember.get(this, 'newRequest.walletId');
          let _sms = printingOptionsStorage.frontHTML;
          let form = {};

          if (walletId && _sms && (monitoring === true || Ember.get(this, 'contactsSend.smsContact'))) {
            form.send = Ember.get(this, 'contactsSend.smsContact');
          } else {
            this.toast.error('Erro!', 'Alguns campos não foram identificados.', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          form.walletId = walletId;
          form.monitoring = monitoring;
          form.requestId = localStorage.getItem("newServiceRequestId");
          let url = this.baseapi.apiLink('Sms/test');
          let r = this.baseapi.post(url, form);
          this.preloader.toggleClass('d-none');
          r.then(response => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Sucesso!', 'SMS enviado, verifique seu celular em instantes.', {
              positionClass: 'toast-bottom-right'
            });
          }).catch(error => {
            this.variables.set('empty_resp', error);
            this.preloader.toggleClass('d-none');
            this.toast.error('Erro!', 'Problemas ao enviar os testes.', {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (e) {
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },

      sendEmailTest(monitoring = null) {
        try {
          var request = JSON.parse(localStorage.getItem("storage:request"));
          var requestOptions = "templateEmail";
          var printingOptionsStorage = request.printingOptions[requestOptions];
          let reply_to = Ember.get(this, 'newRequest.emailContact');
          let subject = Ember.get(this, 'newRequest.emailSubject'); // let printOptions = get(this, 'newRequest.printingOptions');

          let walletId = Ember.get(this, 'newRequest.walletId');
          let _html = printingOptionsStorage.frontHTML; // let form = new FormData();

          let form = {};

          if (walletId && _html && reply_to && subject && (monitoring === true || Ember.get(this, 'contactsSend.emailContact'))) {
            let email_send = Ember.get(this, 'contactsSend.emailContact');
            form.send = email_send;
          } else {
            this.toast.error('Erro!', 'Alguns campos não foram identificados.', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          form.monitoring = monitoring;
          form.walletId = walletId;
          form.requestId = localStorage.getItem("newServiceRequestId");
          let url = this.baseapi.apiLink('Emails/test');
          let r = this.baseapi.post(url, form);
          this.preloader.toggleClass('d-none');
          r.then(response => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Sucesso!', 'Email enviado, verifique na sua caixa de entrada em instantes.', {
              positionClass: 'toast-bottom-right'
            });
          }).catch(error => {
            this.variables.set('empty_resp', error);
            this.preloader.toggleClass('d-none');
            this.toast.error('Erro!', 'Problemas ao enviar os testes.', {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (e) {
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },

      changeFlatPicker: function (mod) {
        Ember.set(this, 'changeDateSendMod.mode', mod);
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f[mod].selectedDates.forEach(function (date) {
          dateFlat = date;
        });
        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = day + "/" + month + "/" + year + " - " + hour + ":" + minute;
        let data = Ember.get(this, 'changeDateSendMod.newValues');
        let dataFlat = Ember.get(this, 'changeDateSendMod.dataFlat');
        data[mod] = strDate;
        dataFlat[mod] = f[mod];
        let template = ''; // mod == 'sms' ? 'newRequest.printingOptions.templateSMS.dataDisparo' : mod == 'email'? 'newRequest.printingOptions.templateEmail.dataDisparo' : 'newRequest.printingOptions.templateLetter.dataDisparo';

        let template2 = ''; // mod == 'sms' ? 'templateSMS' : mod == 'email'? 'templateEmail' : 'templateLetter';

        let immediate = ''; // mod == 'sms' ? 'newRequest.printingOptions.templateSMS.disparoImediato' : mod == 'email'? 'newRequest.printingOptions.templateEmail.disparoImediato' : 'newRequest.printingOptions.templateLetter.disparoImediato';

        if (mod == 'sms') {
          template = 'newRequest.printingOptions.templateSMS.dataDisparo';
          template2 = 'templateSMS';
          immediate = 'newRequest.printingOptions.templateSMS.disparoImediato';
        } else if (mod == 'whatsapp') {
          template = 'newRequest.printingOptions.templateWhatsapp.dataDisparo';
          template2 = 'templateWhatsapp';
          immediate = 'newRequest.printingOptions.templateWhatsapp.disparoImediato';
        } else if (mod == 'email') {
          template = 'newRequest.printingOptions.templateEmail.dataDisparo';
          template2 = 'templateEmail';
          immediate = 'newRequest.printingOptions.templateEmail.disparoImediato';
        }

        let updateValue = '';
        Ember.set(this, template, strDate);
        Ember.set(this, immediate, false);
        this.changeStorageRequestOptionsTask('dataDisparo', strDate, template2);
        this.changeStorageRequestOptionsTask('disparoImediato', false, template2);
        Ember.set(this, 'changeDateSendMod.newValues', data);
        Ember.set(this, 'changeDateSendMod.dataFlat', dataFlat);
      },

      saveEmailSubject() {
        var v = this.get('emailSubject');
        this.set('model.request.emailSubject', v);
        this.get('changeStorageRequestTask').perform('emailSubject', v);
      },

      saveDateShipping() {
        this.get('savePendentRequest').perform();
      },

      backPage() {
        localStorage.setItem('serviceLoad', true);
        this.transitionToRoute('/services/new');
      },

      clearDataRequest() {
        try {
          Ember.get(this, 'newRequest').clear();
        } catch (error) {}

        localStorage.removeItem('storage:request');
        localStorage.removeItem('newServiceRequestId');
        localStorage.removeItem('storage:wallet-columns');
        localStorage.removeItem('storage:excededSms');
        localStorage.removeItem('storage:template-selected');
        localStorage.removeItem('storage:request-options');
        localStorage.removeItem('storage:force-wallet');
        localStorage.removeItem('serviceLoad');
      },

      smsValidation(key) {
        let field = `newRequest.validations.sms.${key}`;
        let currentValue = this.get(field);
        let newValue = currentValue == 0 ? 1 : 0;
        this.changeValidationsTask(key, newValue, 'sms');
      },

      emailValidation(key) {
        let field = `newRequest.validations.email.${key}`;
        let currentValue = this.get(field);
        let newValue = currentValue == 0 ? 1 : 0;
        this.changeValidationsTask(key, newValue, 'email');
      },

      newConnect() {
        if (this.get('newRequest.emailService') || this.get('newRequest.smsService')) {
          this.get('socketValidation').perform();
        }
      },

      sendWhatsappTest: function () {
        try {
          var request = JSON.parse(localStorage.getItem("storage:request"));
          var requestOptions = "templateWhatsapp";
          var printingOptionsStorage = request.printingOptions[requestOptions];
          let walletId = Ember.get(this, 'newRequest.walletId');
          let _sms = printingOptionsStorage.frontHTML;
          let form = {};

          if (walletId && _sms && Ember.get(this, 'model.messageTest.number')) {
            form.send = Ember.get(this, 'model.messageTest.number');
          } else {
            this.toast.error('Erro!', 'Alguns campos não foram identificados.', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          form.walletId = walletId;
          form.requestId = localStorage.getItem("newServiceRequestId");
          let url = this.baseapi.apiLink('Whatsapp/test');
          let r = this.baseapi.post(url, form);
          this.preloader.toggleClass('d-none');
          r.then(response => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Sucesso!', 'Whatsapp enviado, verifique seu celular em instantes.', {
              positionClass: 'toast-bottom-right'
            });
          }).catch(error => {
            let msg = 'Problemas ao enviar os testes.';

            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length) {
              msg += `: ${error.response.data.errors[0].detail}`;
            }

            this.variables.set('empty_resp', error);
            this.preloader.toggleClass('d-none');
            this.toast.error('Erro!', msg, {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (e) {
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        }
      }
    }
  });

  _exports.default = _default;
});