define("printpost/controllers/dashboard", ["exports", "ember-concurrency", "ember-local-storage", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _emberLocalStorage, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    newRequest: (0, _emberLocalStorage.storageFor)('request'),
    dataUser: Ember.computed('dataUser', function () {
      // let d = this.baseapi.getCurrentUser();
      const d = this.get('session.data.authenticated');
      return d.account;
    }).property().volatile(),
    menusFilter: Ember.computed('menusFilter', function () {
      var itens = ['filter_mes', 'filter_ano', 'filter_inicio'];
      return itens;
    }),
    data: Ember.computed('model', function () {
      var model = this.get('model.dataDashboard');
      return model[0];
    }),
    dataWallets: Ember.computed('model.dataWallets', function () {
      var model = this.get('model.dataWallets');
      return model;
    }),
    dataReturn: Ember.computed('model.dataReturn', function () {
      var model = this.get('model.dataReturn');
      return model;
    }),
    dataDateFilter: Ember.computed('model.dataDateFilter', function () {
      var model = this.get('model.dataDateFilter');
      return model;
    }),
    dataDateFilterStr: Ember.computed('model.dataDateFilterStr', function () {
      var model = this.get('model.dataDateFilterStr');
      return model;
    }),
    listRequestsDuplicate: Ember.computed('listRequestsDuplicate', function () {
      return [];
    }),
    itemDuplicate: Ember.computed('itemDuplicate', function () {
      return [];
    }),
    duplicateRequestQuestion: (0, _emberConcurrency.task)(function* (item) {
      let r = item.id;
      let dataRequest = null;
      yield this.store.findRecord('request', r).then(function (data_obj) {
        dataRequest = item; //problema para setar o wallet Id
        // _request.walletId = item.walletId;

        dataRequest.printingOptions = data_obj.data.printingOptions;
        let printingCheck = dataRequest.printingOptions;
        let isImediatoEmail = printingCheck.templateEmail ? printingCheck.templateEmail.disparoImediato : true;
        ;
        let isImediatoCarta = printingCheck.templateLetter ? printingCheck.templateLetter.disparoImediato : true;
        let isImediatoSMS = printingCheck.templateSMS ? printingCheck.templateSMS.disparoImediato : true;
        let isImediato = isImediatoCarta * isImediatoEmail * isImediatoSMS ? true : false;

        if (isImediato) {
          // question edit any information
          Ember.$('#btn-duplicate-req-1').click();
        } else {
          // direct edit any information
          Ember.$('#btn-duplicate-req-2').click();
        }
      });
      Ember.set(this, 'itemDuplicate', dataRequest);
    }).keepLatest(),
    taskFilterReturn: (0, _emberConcurrency.task)(function* () {
      this.preloader.toggleClass('d-none');
      const requestTagId = Ember.get(this, 'cStrWalletId');
      var costCenterId = Ember.get(this, 'cStrCostCenterId');
      const date = {
        start: Ember.get(this, 'cStrDateBegin'),
        end: Ember.get(this, 'cStrDateEnd')
      };
      const objDataReturn = yield this.dashboard.loadDashboard(requestTagId, date, costCenterId);
      this.set('model.dataReturn', objDataReturn);
      this.preloader.toggleClass('d-none'); // ;(async () => {
      //   // const listCampaign = await this.dashboard.listCampaign();
      //   // const listIdsRequest = await this.dashboard.listIdsRequest(byCampaign);
      // })();
    }).keepLatest(),
    datePeriodo: [],
    cStrDateBegin: Ember.computed('strDateBegin', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-01T00:00:00-03:00';
      return strDate;
    }),
    cStrDateEnd: Ember.computed('strDateEnd', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + 'T00:00:00-03:00';
      return strDate;
    }),
    cStrWalletId: Ember.computed('strWalletId', function () {
      return '';
    }),
    dataGrafPie: Ember.computed('model.graphOne.data', function () {
      let d = {
        "datasets": [],
        "labels": []
      };
      return d;
    }),
    dataGrafPieTwo: Ember.computed('model.graphOne.data', function () {
      let d = {
        "datasets": [],
        "labels": []
      };
      return d;
    }),
    options: Ember.computed('model.graphOne.options', function () {
      return {
        "responsive": true,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#ffffff"
          }
        },
        "scales": {
          "borderColor": "#ffffff",
          "tickColor": "#FFFFFF",
          "yAxes": [{
            "display": true,
            "ticks": {
              "beginAtZero": true,
              "zeroLineColor": "#FFFFFF",
              "fontColor": "#FFFFFF"
            },
            "gridLines": {
              "display": true
            }
          }],
          "xAxes": [{
            "display": true,
            "gridLines": {
              "display": false,
              "borderColor": "#ffffff",
              "color": "#ffffff"
            },
            "ticks": {
              "beginAtZero": true,
              "zeroLineColor": "#FFFFFF",
              "fontColor": "#FFFFFF"
            }
          }]
        }
      };
    }).property().volatile(),
    taskGetReport: (0, _emberConcurrency.task)(function* (channel, typeReport, monthYear = '') {
      Ember.set(this, 'model.dataReport.channel', channel.toUpperCase());
      Ember.set(this, 'model.dataReport.type', typeReport);
      Ember.set(this, 'model.dataReport.show', true);
      var url = typeReport == 3 ? this.baseapi.apiLink(`RequestDatasets/dashboard-messages/${channel}?filter[limit]=20`) : this.baseapi.apiLink(`RequestDatasets/dashboard-report/${channel}?typeReport=${typeReport}`);
      monthYear = monthYear.replace('/', ':');
      var strDateMonthYear = typeReport == 3 ? this.get('model.dataReport.monthYear') : '';
      strDateMonthYear = typeReport == 3 ? `${monthYear.split(':')[0]}:${strDateMonthYear}` : '';
      url = typeReport != 1 ? typeReport == 2 ? `${url}&monthYear=${monthYear}` : typeReport == 3 ? `${url}&dateMonthYear=${strDateMonthYear}` : url : url;
      this.preloader.toggleClass('d-none');

      try {
        var dados = yield this.baseapi.getHttp(url).then(resp => resp.data);
      } catch (error) {
        this.preloader.toggleClass('d-none');
        Ember.set(this, 'model.dataReport.show', false);
        this.toast.error('', 'Erro ao consultar dados, tente novamente.', {
          positionClass: 'toast-bottom-right'
        });
      }

      this.preloader.toggleClass('d-none');
      var chartObject = {
        datasets: [],
        labels: []
      };

      if (typeReport == 1) {
        if (dados.length > 0) {
          var labels = ["Enviado", "Entregue sem confirmação", "Entregue com confirmação", "Não Recebido"];
          var colors = ["#b9f7b9", "#f7e499", "#a7b1cf", "#ec9ea8"];
          var fields = ["totalSend", "receivedNoConfirm", "receivedConfirm", "notReceived"];

          for (let i = 0; i < 5; i++) {
            var data = [];
            var f = fields[i];
            dados.map(d => data.push(d[f]));
            var b = {
              label: labels[i],
              backgroundColor: colors[i],
              borderColor: colors[i],
              data: data,
              borderWidth: 0
            };
            chartObject.datasets.push(b);
          }
        }

        ;
        var lineDataset = {
          type: 'line',
          label: 'Line Dataset',
          order: 1,
          data: [],
          borderColor: '#50e3c2',
          tension: 0,
          pointBorderColor: '#FFF',
          pointBackgroundColor: '#FFF'
        };
        var l = [];
        dados.map(d => {
          l.push(d.monthYear);
          lineDataset.data.push(d.totalSend);
        });
        chartObject.labels = l;
        chartObject.datasets.push(lineDataset);
        Ember.set(this, 'dataGrafPie', chartObject);
        Ember.set(this, 'model.dataReport.reportOne', dados);
      }

      Ember.set(this, 'model.dataReport.channel', channel);

      if (typeReport == 2) {
        var lineDataset = {
          type: 'line',
          label: 'Line',
          order: 1,
          data: [],
          borderColor: '#50e3c2',
          tension: 0,
          pointBorderColor: '#FFF',
          pointBackgroundColor: '#FFF'
        };
        var l = [];
        dados.map(d => {
          l.push(d.dateMonth.split('/')[0]);
          lineDataset.data.push(d.totalSend);
        });
        chartObject.labels = l;
        chartObject.datasets = [lineDataset];
        Ember.set(this, 'dataGrafPieTwo', chartObject);
        Ember.set(this, 'model.dataReport.reportTwo', dados);
        Ember.set(this, 'model.dataReport.monthYear', monthYear);
      }

      if (typeReport == 3) {
        Ember.set(this, 'strDateMonthYear', strDateMonthYear.replace(/:/g, '/'));
        Ember.set(this, 'model.dataReport.reportThird', dados.data);
        Ember.set(this, 'model.dataReport.pages', dados.totalPages);
        Ember.set(this, 'model.dataReport.dateMonthYear', strDateMonthYear);
      }
    }).keepLatest(),
    dataCostCenter: Ember.computed('model.obj_costcenter', function () {
      var model = this.get('model.obj_costcenter');
      return model;
    }),
    actions: {
      newService() {
        localStorage.removeItem('storage:request');
        localStorage.removeItem('newServiceRequestId');
        localStorage.removeItem('storage:wallet-columns');
        localStorage.removeItem('storage:excededSms');
        localStorage.removeItem('storage:template-selected');
        localStorage.removeItem('storage:request-options'); // window.location.href = "services/new";

        this.transitionToRoute('services.new');
      },

      findRequests: function () {
        let url = this.baseapi.apiLink('Requests');
        let filter = 'filter[where][or][0][status]=An%C3%A1lise' + '&' + 'filter[where][or][1][status]=Execu%C3%A7%C3%A3o' + '&' + 'filter[where][or][2][status]=Finalizados' + '&' + 'filter[include]=wallet' + '&' + 'filter[order]=id%20DESC';
        let r = this.baseapi.getHttp(url + '/list' + '?' + filter);
        r.then(response => {
          let objReturn = response.data;
          Ember.set(this, 'listRequestsDuplicate', objReturn);
        });
      },
      duplicateRequestQuestion: function (item) {
        Ember.get(this, 'duplicateRequestQuestion').perform(item);
      },
      reloadPage: function () {// window.location.reload(true);
      },
      duplicateRequest: function (status) {
        let _request = Ember.get(this, 'itemDuplicate');

        if (_request) {
          status = Number(status.toString());
          let r = _request.id;
          const user = this.get('session.data.authenticated').account;

          if (user.type == "cliente_comum") {
            return;
          }

          let url = this.baseapi.apiLink('Requests/replicate');
          url += "/" + r;

          if (status == 1) {
            url += "/2";
          } else {
            url += "/1";
          }

          let call = this.baseapi.post(url, {});
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');

            if (resp.data.success) {
              this.toast.success('', 'Pedido replicado com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              Ember.$('#btn-duplicate-success').click();
            } else {
              this.toast.error('', 'Problemas identificados ao replicar pedido', {
                positionClass: 'toast-bottom-right'
              });
            }
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('', 'Erro ao replicar pedido, tente novamente.', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },
      actionFilterReturn: function (strfilter) {
        let listFilterMenus = Ember.get(this, 'menusFilter');
        let objDate = new Date();
        let strDateBegin = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-01T00:00:00-03:00';
        let strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
        listFilterMenus.forEach(item => {
          if (strfilter != item) {
            Ember.$('#' + item).addClass('text-lightgray');
          } else {
            Ember.$('#' + item).removeClass('text-lightgray');

            if (item == 'filter_ano') {
              strDateBegin = objDate.getFullYear() + '-01-01T00:00:00-03:00';
              strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
            } else if (item == 'filter_inicio') {
              strDateBegin = '2019-01-01T00:00:00-03:00';
              strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
            }
          }
        });
        Ember.set(this, 'cStrDateBegin', strDateBegin);
        Ember.set(this, 'cStrDateEnd', strDateEnd);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes_setas').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
        }, 500);
        Ember.get(this, 'taskFilterReturn').perform();
      },
      actionFilterWallet: function (name) {
        Ember.$('#list_wallets li').each((index, item) => {
          var nameCampaign = Ember.$(item).attr('data-id');

          if (name == nameCampaign) {
            Ember.$(item).removeClass('text-lightgray');
            name != 'filter_all' ? Ember.set(this, 'cStrWalletId', name) : Ember.set(this, 'cStrWalletId', '');
            Ember.get(this, 'taskFilterReturn').perform();
          } else {
            Ember.$(item).addClass('text-lightgray');
          }

          if (name == "filter_all") Ember.$(item).removeClass('text-lightgray');
        });
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });

        if (!dateFlat) {
          return;
        }

        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":00-03:00";
        let d = this.datePeriodo;

        if (d.length == 0) {
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        } else if (d.length == 1) {
          let st = year + "-" + month + "-" + day + "T23:59:00-03:00";
          d.push(st);
          Ember.set(this, 'cStrDateEnd', st);
          Ember.get(this, 'taskFilterReturn').perform();
        } else if (d.length == 2) {
          d = [];
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        }

        this.datePeriodo = d;
        setTimeout(() => {
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').addClass('text-lightgray');
          Ember.$('#filter_periodo').removeClass('text-lightgray');
        }, 500);
      },

      beforeMonth() {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() - 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth(), 0);
        let d1Str = d1.getFullYear() + '-' + this.baseapi.padStr(d1.getMonth() + 1) + '-01T00:00:00-03:00';
        let d2Str = d2.getFullYear() + '-' + this.baseapi.padStr(d2.getMonth() + 1) + '-' + this.baseapi.padStr(d2.getDate()) + 'T23:59:00-03:00';
        let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        Ember.set(this, 'cStrDateBegin', d1Str);
        Ember.set(this, 'cStrDateEnd', d2Str);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').removeClass('text-lightgray');
        }, 500);
        Ember.get(this, 'taskFilterReturn').perform(true);
      },

      afterMonth() {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() + 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth() + 2, 0);
        let d1Str = d1.getFullYear() + '-' + this.baseapi.padStr(d1.getMonth() + 1) + '-01T00:00:00-03:00';
        let d2Str = d2.getFullYear() + '-' + this.baseapi.padStr(d2.getMonth() + 1) + '-' + this.baseapi.padStr(d2.getDate()) + 'T23:59:00-03:00';
        let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        Ember.set(this, 'cStrDateBegin', d1Str);
        Ember.set(this, 'cStrDateEnd', d2Str);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').removeClass('text-lightgray');
        }, 500);
        Ember.get(this, 'taskFilterReturn').perform(true);
      },

      filterCampaign() {
        let input, filter, li;
        input = document.getElementById('filterCampaign');
        filter = input.value.toUpperCase();
        li = this.get('model.dataWallets');
        var new_list = li.filter(x => x.name.toUpperCase().indexOf(filter) > -1);
        Ember.set(this, 'dataWallets', new_list);
      },

      getReturnReport(channel, typeReport, monthYear = '') {
        this.get('taskGetReport').perform(channel, typeReport, monthYear);
      },

      setReturnReport(type) {
        if (type == 0) {
          Ember.set(this, 'model.dataReport.show', false);
        }

        Ember.set(this, 'model.dataReport.type', type);
      },

      setViewMessage(id) {
        Ember.set(this, 'viewMessage', id);

        if (id) {
          let url = this.baseapi.apiLink(`RequestDatasets/dashboard-message-detail/${id}`);
          let call = this.baseapi.getHttp(url);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');

            if (resp.data.success && resp.data.success[0] && resp.data.success[0].status == 200) {
              this.set('model.templateView', resp.data.success[0].detail.message);
            } else {
              this.toast.error('', 'Problemas identificados ao replicar pedido', {
                positionClass: 'toast-bottom-right'
              });
            }
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('', 'Erro ao consultar mensagem, tente novamente.', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },

      goRouter(url) {
        // localStorage.removeItem('storage:request');
        localStorage.removeItem('newServiceRequestId'); // localStorage.removeItem('storage:wallet-columns');
        // localStorage.removeItem('storage:excededSms');
        // localStorage.removeItem('storage:template-selected');
        // localStorage.removeItem('storage:request-options');

        this.transitionToRoute(url);
      },

      paginationRequest: async function (filterSkip) {
        var query = {
          filter: {
            order: 'id DESC',
            skip: filterSkip.skip,
            limit: filterSkip.limit
          }
        };
        this.preloader.toggleClass('d-none');

        try {
          let channel = Ember.get(this, 'model.dataReport.channel');
          let dateMonthYear = Ember.get(this, 'model.dataReport.dateMonthYear');
          const base_url = this.baseapi.apiLink(`RequestDatasets/dashboard-messages/${channel}?dateMonthYear=${dateMonthYear}`);
          const strquery = (0, _globalFunctions.apiLinkQuery)(query);
          var url = `${base_url}&${strquery}`;
          const itensList = await this.baseapi.getHttp(url).then(resp => resp.data);
          Ember.set(this, 'model.dataReport.pages', itensList.totalPages);
          Ember.set(this, 'model.dataReport.reportThird', itensList.data);
        } catch (error) {
          this.toast.error('', 'Erro na consulta dos dados', {
            positionClass: 'toast-bottom-right'
          });
        }

        this.preloader.toggleClass('d-none');
      },
      actionFilterCost: function (name) {
        Ember.$('#list_costcenter li').each((index, item) => {
          var nameCampaign = Ember.$(item).attr('data-name');
          var valueText = Ember.$(item).attr('data-id');

          if (name == nameCampaign) {
            Ember.$(item).removeClass('text-lightgray');
            name != 'filter_all' ? Ember.set(this, 'cStrCostCenterId', valueText) : Ember.set(this, 'cStrCostCenterId', '');
            Ember.get(this, 'taskFilterReturn').perform();
          } else {
            Ember.$(item).addClass('text-lightgray');
          }

          if (name == "filter_all") Ember.$(item).removeClass('text-lightgray');
        });
      },

      filterCostcenter() {
        let input, filter, li;
        input = document.getElementById('filterCostcenter');
        filter = input.value.toUpperCase();
        li = this.get('model.obj_costcenter');
        var new_list = li.filter(x => x.description.toUpperCase().indexOf(filter) > -1);
        Ember.set(this, 'dataCostCenter', new_list);
      }

    }
  });

  _exports.default = _default;
});