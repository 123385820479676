define("printpost/controllers/static", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      const x1 = [-3, -2, -1, 0, 1, 2, 3]; // const angles = new Array(60).fill(0).map((z,i) => -Math.PI + i*2*Math.PI/60);

      this.setProperties({
        chartData: [{
          x: x1,
          y: x1.map(x => Math.pow(x, 2)),
          type: 'bar',
          bar: {
            color: '#7267ff'
          }
        }],
        chartLayout: {
          xaxis: {//range: [-3.5, 3.5]
          },
          yaxis: {//range: [-15, 15]
          }
        },
        chartOptions: {}
      });
    }

  });

  _exports.default = _default;
});