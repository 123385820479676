define("printpost/controllers/services/info", ["exports", "printpost/controllers/services"], function (_exports, _services) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _services.default.extend({
    baseapi: Ember.inject.service(),
    test: Ember.inject.service('test-service'),
    actions: {
      nextRouter: function () {
        // let t = (JSON.parse(localStorage.getItem('ember_simple_auth-session'))).authenticated.id;
        // let replace_token = '?access_token=' + t;
        var order = this.test.verifySendOrder();
        let walletId = Ember.get(this, 'newRequest.walletId');
        let url_wallet = this.baseapi.apiLink('Wallets/list');
        let r_2 = this.baseapi.getHttp(url_wallet + '?filter[where][id]=' + walletId);
        r_2.then(response => {
          let objReturn = response.data[0];

          if (objReturn) {
            Ember.set(this, 'walletColumns.data', objReturn.columns);
            Ember.set(this, 'walletColumns.dataSMS', objReturn.columns_sms);
            Ember.set(this, 'walletColumns.dataEmail', objReturn.columns_email);
          }

          Ember.set(this, 'newRequest.step', 2);
          this.transitionToRoute('services.data');
        });
      }
    }
  });

  _exports.default = _default;
});