define("printpost/routes/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dashboard: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    model: async function () {
      const exit = await this.dashboard.dashboardQuantity();
      const listCampaign = await this.dashboard.listCampaign(); // const listIdsRequest = await this.dashboard.listIdsRequest(listCampaign);

      const objDataReturn = await this.dashboard.loadDashboard();
      const objDate = new Date();
      const strDate = this.baseapi.getMonthStr(objDate.getMonth()) + " - " + objDate.getFullYear();
      const date = new Date();
      date.setMonth(date.getMonth() - 5);
      var url_costcenter = this.baseapi.apiLink('CostCenters/list');
      const obj_costcenter = await this.baseapi.getHttp(url_costcenter).then(r => r.data);
      return Ember.RSVP.hash({
        dataWallets: listCampaign,
        dataReturn: objDataReturn,
        dataDateFilterStr: strDate,
        dataDateFilter: objDate,
        dataDashboard: exit,
        templateView: '',
        dataReport: {
          channel: '',
          show: false,
          monthYear: '',
          type: 0,
          reportOne: [],
          reportTwo: [],
          reportThird: [],
          pages: 0,
          dateMonthYear: ''
        },
        obj_costcenter: obj_costcenter,
        maxFlatpickerDate: `${date.getFullYear()}-${this.baseapi.padStr(date.getMonth() + 1, 2)}`
      });
    }
  });

  _exports.default = _default;
});