define("printpost/controllers/services/email", ["exports", "printpost/controllers/services", "ember-concurrency", "printpost/config/environment", "printpost/utils/globalFunctions"], function (_exports, _services, _emberConcurrency, _environment, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DOMTinymce = [];
  var logos_positions = ['none', '#img_tl', '#img_tr', '#img_bl', '#img_br', '#img_marcadagua'];

  var _default = _services.default.extend({
    loadPlugin: function () {
      // Use run loop if you need to setup the DOM first
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$.getScript('https://d5aoblv5p04cg.cloudfront.net/editor-2/loader/build.js');
      });
    }.on('init'),
    test: Ember.inject.service('test-service'),
    session: Ember.inject.service(),
    dataUser: Ember.computed('dataUser', function () {
      // let d = this.baseapi.getCurrentUser();
      const d = this.get('session.data.authenticated');
      return d.account;
    }).property().volatile(),
    queryParams: Ember.computed(function () {
      return ['product', 'wallet'];
    }),
    product: Ember.computed(function () {
      return null;
    }),
    baseapi: Ember.inject.service(),
    newFile: Ember.computed(function () {
      return null;
    }),
    dataFilter: Ember.computed('filter', function () {
      return [];
    }),
    dataProducts: Ember.computed('model.products', function () {
      var model = this.get('model.products');
      return model;
    }),
    dataTemplates: Ember.computed('model.templates', function () {
      var model = this.get('model.templates');
      return model;
    }),
    dataChannel: Ember.computed('model.channel', function () {
      var model = this.get('model.channel');
      return model;
    }),
    dataHtml: Ember.computed('dataHtml', function () {
      return {
        frontHTML: '',
        backHTML: ''
      };
    }),
    dataTemplate: Ember.computed('dataTemplate', function () {
      return {};
    }),
    reloadTemplate: Ember.computed('reloadTemplate', function () {
      return true;
    }),
    showModal: Ember.computed(function () {
      return false;
    }),
    templateEmail: Ember.computed(function () {
      let t = Ember.get(this, 'newRequest.printingOptions.templateEmail');
      return t;
    }),
    templates: Ember.computed(function () {
      return [];
    }),
    templateSelected: Ember.computed(function () {
      return null;
    }),
    columns_wallet: Ember.computed(function () {
      return [];
    }),
    columns_email: Ember.computed(function () {
      let data = Ember.get(this, 'walletColumns.dataEmail');
      Ember.set(this.newRequest, 'columnsSelectEmail', data);
      return data;
    }).property().volatile(),
    columns_selected: Ember.computed(function () {
      let data = Ember.get(this, 'walletColumns.dataEmail');
      return data;
    }),
    filterModels: Ember.computed('model.product', function () {
      return this.get('model.product');
    }),
    isShowingTranslucent: Ember.computed(function () {
      return false;
    }),
    requestPrintingOption: Ember.computed(function () {
      let npo = Ember.get(this, 'newRequest.printingOptions');
      return npo;
    }),
    selectTemplate: (0, _emberConcurrency.task)(function* () {
      // yield set(this.newRequest,'emailTemplateId', this.templateEmail.templateId);
      // let npo = get(this, 'newRequest.printingOptions');
      yield Ember.set(this.newPrintingOptions, 'templateEmail', this.templateEmail);
      let nextWallet = this.get('newRequest.walletId');

      if (nextWallet != 'static') {
        let reqId = localStorage.getItem('newServiceRequestId');
        let url_wallet = this.baseapi.apiLink('Wallets/find-tags');
        const objReturn = yield this.baseapi.getHttp(`${url_wallet}/${nextWallet}?requestId=${reqId}`).then(r => r.data);
        var tags = [];

        for (let j = 0; j < objReturn.length; j++) {
          const column = objReturn[j];
          var obj = {
            value: `[${column.title}]`,
            text: column.title,
            label: column.title
          };
          tags.push(obj);
        }

        Ember.set(this, 'storageTemplateSelected.tags', tags);
      } // this.send('salvarPendente');
      // yield set(npo, 'templateEmail', this.templateEmail);
      // set(this.newRequest,'printingOptions', npo);

    }).keepLatest(),
    flatMinDate: Ember.computed('flatMinDate', function () {
      return new Date().setMinutes(new Date().getMinutes() + 30);
    }),
    flatMaxDate: Ember.computed('flatMaxDate', function () {
      return new Date().setMonth(new Date().getMonth() + 2, 1);
    }),
    myFlatpickrRef: Ember.computed(function () {
      return null;
    }),
    changeStorageRequestFunc: function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions.templateEmail[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.printingOptions.templateEmail.' + chave, valor);
    },
    changeStorageRequestTask: (0, _emberConcurrency.task)(function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions.templateEmail[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.printingOptions.templateEmail.' + chave, valor);
    }),
    getStorageRequestTask: (0, _emberConcurrency.task)(function (chave) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      return _newReq.printingOptions.templateEmail[chave];
    }),
    actions: {
      selectTemplateStep2: function (json_data) {
        var order = this.test.verifySendOrder();
        let dataRequest = JSON.parse(localStorage.getItem('storage:request'));
        let nr = dataRequest.typeConfig;
        Ember.set(this, 'newRequest.printingOptions', dataRequest.printingOptions);
        Ember.get(this, 'selectTemplate').perform(json_data).then(() => {
          TopolPlugin.destroy(); // this.send('salvarPendente');

          this.toast.success('', 'Template selecionada com sucesso, aguarde até ser direcionado.', {
            positionClass: 'toast-bottom-right'
          });

          if (!Ember.get(this, 'newRequest.printingOptions.templateEmail.responseEmail')) {
            Ember.set(this, 'newRequest.emailContact', 'suporte@printpost.com.br');
          } // if(nr != "file_layout"){
          //   this.transitionToRoute('services.test');
          // }else {
          //   this.transitionToRoute('services.payment');
          // }


          this.transitionToRoute('services.payment');
        });
      },
      doRefresh: function () {
        window.location.reload(true);
      },
      setTemplate: function (id_template) {
        if (id_template.length > 1) {
          Ember.$("#next-template").removeAttr('disabled');
        } else {
          Ember.$("#next-template").attr('disabled', 'disabled');
        }

        Ember.get(this, 'changeStorageRequestTask').perform('templateId', id_template);
      },
      setColumnEmail: function (value) {
        let cols = Ember.get(this, 'newRequest.columnsSelectEmail');

        if (cols.find(r => r == value) == undefined) {
          cols.push(value);
        } else {
          let new_cols = [];
          cols.forEach(elem => {
            if (elem != value) {
              new_cols.push(elem);
            }
          });
          cols = new_cols;
        }

        Ember.set(this, 'columns_selected', cols);
        Ember.set(this.newRequest, 'columnsSelectEmail', cols);
      },

      nextClick(itemid) {
        document.getElementById(itemid).click();
      },

      changeStorageRequest(chave, valor) {
        Ember.get(this, 'changeStorageRequestTask').perform(chave, valor);
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });
        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = day + "/" + month + "/" + year + " - " + hour + ":" + minute;
        Ember.get(this, 'changeStorageRequestTask').perform('dataDisparo', strDate);
      },

      changeCheckbox(item, tipo, val) {
        let df = Ember.get(this, 'dataFilter');

        if (val) {
          let o = {};
          o[tipo] = item;
          df.push(o);
        } else {
          for (let i = 0; i < df.length; i++) {
            const element = df[i];

            if (element[tipo] == item) {
              df.splice(i, 1);
            }
          }
        }

        Ember.set(this, 'dataFilter', df);
      },

      filterTemplate: async function () {
        let r = Ember.get(this, 'dataFilter');
        let channel = Ember.get(this, 'dataChannel');
        let query = {
          filter: {
            where: {
              "channelId": channel.id
            },
            include: 'channel'
          }
        };

        if (r.length >= 1) {
          query.filter.where.or = [];
          r.forEach(item => {
            if (item.productId) {
              query.filter.where.or.push({
                "productId": item.productId
              });
            }
          });
        }

        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        var url_templates = this.baseapi.apiLink('Templates/list');
        const obj_templates = await this.baseapi.getHttp(url_templates + "?" + strquery).then(r => r.data);
        Ember.set(this, 'dataTemplates', obj_templates);
      },
      selectTemplate: async function (id) {
        let pto = this.get('newRequest.printingOptions');
        Ember.set(pto, 'templateLetter.editorMode', 'email');
        this.set('newRequest.printingOptions', pto);

        if (id != "construir_do_zero") {
          Ember.set(this, 'reloadTemplate', false);
          let query = {
            filter: {
              where: {
                "id": id
              },
              fields: {
                userId: false
              }
            }
          };
          let strquery = (0, _globalFunctions.apiLinkQuery)(query);
          var url_templates = this.baseapi.apiLink('Templates/list');
          const obj = await this.baseapi.getHttp(url_templates + "?" + strquery).then(r => r.data[0]);
          Ember.set(this, 'dataTemplate', obj);
          Ember.set(this, 'dataHtml.frontHTML', obj.internal);
          Ember.set(this, 'dataHtml.backHTML', obj.externalHtml);
          var tmplSelect = {
            html: '',
            json: obj.json
          };
          Ember.set(this, 'storageTemplateSelected.editor_mode', 'email');
          Ember.set(this, 'storageTemplateSelected.email', tmplSelect);
          Ember.set(this, 'reloadTemplate', true);
        } else if (id == "construir_do_zero") {
          var data = Ember.get(this, 'templateZero.email');
          var obj = {
            html: '',
            json: data
          };
          Ember.set(this, 'storageTemplateSelected.editor_mode', 'email');
          Ember.set(this, 'storageTemplateSelected.email', obj);
          this.changeStorageRequestFunc('templateId', 'construir_do_zero');
          this.send('nextRouter');
        }
      },
      defineTemplate: function () {
        let t = Ember.get(this, 'dataTemplate');
        this.changeStorageRequestFunc('frontHTML', t.internal);
        this.changeStorageRequestFunc('backHTML', t.externalHtml);
        this.changeStorageRequestFunc('disparoImediato', true);
        this.changeStorageRequestFunc('productId', t.productId);
        this.changeStorageRequestFunc('templateId', t.id);
        this.send('nextRouter');
      },
      nextRouter: function () {
        let nr = Ember.get(this, 'newRequest.typeConfig');
        let nextWallet = this.get('newRequest.walletId');
        TopolPlugin.destroy();

        if (nr == 'pdf_fechado') {
          this.transitionToRoute('services.data');
        } else if (nr == 'models_templates' || nr == 'file_layout') {
          //route models #2
          let npo = Ember.get(this, 'newRequest.printingOptions');
          let step = Ember.get(this, 'newRequest.step');
          let sms = npo.templateSMS.templateId == null && Ember.get(this, 'newRequest.smsService') ? true : false;
          let letter = npo.templateLetter.templateId == null && Ember.get(this, 'newRequest.letterService') ? true : false; // if(!sms && !letter) {
          //   this.transitionToRoute('services.info');
          // }else 

          if (step == 1) Ember.set(this, 'newRequest.step', 2);

          if (Ember.get(this, 'newRequest.walletId') == "static") {
            this.transitionToRoute('services.data');
          } else if (sms || step == 1) {
            this.transitionToRoute('services.sms', {
              queryParams: {
                wallet: nextWallet
              }
            });
          } else if (letter || step == 1) {
            this.transitionToRoute('services.letter', {
              queryParams: {
                wallet: nextWallet
              }
            });
          } else {
            this.transitionToRoute('services.payment');
          }
        } else if (nr == 'creation') {
          // model creation
          this.transitionToRoute('services.data');
        }
      },

      backSelectTemplate() {
        Ember.set(this, 'newRequest.step', 1);
        TopolPlugin.destroy();
        this.transitionToRoute('services.config');
      },

      setViewTutorial(link) {
        Ember.set(this, 'urlVideoTutorial', link);
      },

      viewTemplate(template) {
        Ember.set(this, 'dataHtml.frontHTML', template.internal);
        Ember.set(this, 'dataHtml.backHTML', template.externalHtml);
      },

      selectAndDefineTemplate: async function (template) {
        if (template) {
          if (template == "construir_do_zero") {
            Ember.set(this, 'dataHtml.frontHTML', '');
            Ember.set(this, 'dataHtml.backHTML', '');
            this.changeStorageRequestFunc('templateId', 'construir_do_zero');
            this.send('nextRouter');
            return;
          }

          this.changeStorageRequestFunc('frontHTML', template.internal);
          this.changeStorageRequestFunc('backHTML', template.externalHtml);
          this.changeStorageRequestFunc('disparoImediato', true);
          this.changeStorageRequestFunc('productId', template.productId);
          this.changeStorageRequestFunc('templateId', template.id);
          this.changeStorageRequestFunc('editorMode', 'email');
          this.send('nextRouter'); // console.log('depois after route');
        }
      },

      saveTemplate() {
        var request = JSON.parse(localStorage.getItem("storage:request"));
        this.set('newRequest', request);
      }

    }
  });

  _exports.default = _default;
});