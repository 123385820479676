define("printpost/routes/peoples/omnichannel", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    omnichannelService: Ember.inject.service(),
    queryParams: {
      id: null
    },
    model: async function (queryParams) {
      let id = queryParams.id; // const listData = await this.omnichannelService.list({ filter:{ limit: 20, order: 'id DESC', skip: 0}});
      // filtrar por data do dia

      let query = {
        filter: {
          order: 'id DESC',
          skip: 0,
          limit: 10
        }
      };
      let dateFlat = new Date();
      let year = dateFlat.getFullYear();
      let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
      let day = this.baseapi.padStr(dateFlat.getDate(), 2);
      let dtIni = year + "-" + month + "-" + day + "T00:00:00-03:00";
      let dtEnd = year + "-" + month + "-" + day + "T23:59:59-03:00";
      let dateFilter = {
        dateIni: dtIni,
        dateEnd: dtEnd,
        service: null
      };
      const listData = await this.omnichannelService.filter(1, dateFilter, query);
      var dataTotalAddress = 0;
      var dataTotalDig = 0;
      var dataNegativeRet = 0;
      var dataPositiveRet = 0;
      var peopleView = {
        history: null,
        request: null,
        return: null,
        data: null,
        columns: null
      };
      var dataAnalitics = {
        dataTotalAddress: dataTotalAddress,
        dataTotalDig: dataTotalDig,
        dataNotRet: dataNegativeRet,
        dataPosRet: dataPositiveRet
      };
      const objDate = new Date();
      const strDate = this.baseapi.getMonthStr(objDate.getMonth()) + " - " + objDate.getFullYear();
      const date = new Date();
      date.setMonth(date.getMonth() - 5);
      return Ember.RSVP.hash({
        people: null,
        dataAnalitics: dataAnalitics,
        peopleViewDetail: peopleView,
        renderDetail: false,
        dataDateFilterStr: strDate,
        dataDateFilter: objDate,
        peopleHistories: listData.data,
        pages: listData.totalPages,
        dataLinhaTempo: [],
        dataLinhaTempoNoArquivada: [],
        dataLinhaTempoArquivada: [],
        dataGrafPie1: null,
        dataGrafBar1: null,
        linhaTempoMode: 'normal',
        maxFlatpickerDate: `${date.getFullYear()}-${this.baseapi.padStr(date.getMonth() + 1, 2)}`
      });
    }
  });

  _exports.default = _default;
});