define("printpost/controllers/services/config", ["exports", "printpost/controllers/services"], function (_exports, _services) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _services.default.extend({
    test: Ember.inject.service('test-service'),
    dataConfigTypes: Ember.computed('dataConfigTypes', function () {
      return [{
        id: 1,
        name: 'Enviar PDF fechado',
        image: '/assets/icons/pdf.png',
        description: 'Faça upload de uma arquivo finalizado para a impressão no formato PDF. (gabarito)',
        value: "pdf_fechado"
      }, {
        id: 2,
        name: 'Acesse modelos prontos de acordo com o seu segmento',
        image: '/assets/icons/models.png',
        description: 'Escolha um design e inclua suas informações e imagens na nossa ferramenta de edição. Sem custo adicional.',
        value: "models_templates"
      }, {
        id: 3,
        name: 'Arquivo com Posicionamento',
        image: '/assets/icons/creation.png',
        description: 'Faça upload de um arquivo com um layout pré definido que não seja um CSV ou Excel',
        value: "file_layout"
      }];
    }),
    actions: {
      setTypeConfig: function (value) {
        Ember.set(this, 'newRequest.typeConfig', value);

        if (value == 'pdf_fechado') {
          Ember.set(this, 'newRequest.fileDb', 'impressao');
          Ember.set(this, 'newRequest.walletId', 'static');
          this.send('setMultichannel', false);
        } else if (value == 'models_templates') {
          Ember.set(this, 'newRequest.fileDb', 'printpost');
        } else if (value == 'creation') {
          Ember.set(this, 'newRequest.fileDb', 'impressao');
          Ember.set(this, 'newRequest.walletId', 'static');
        }
      },
      setMultichannel: function (value) {
        let imediate = !value;
        Ember.set(this, 'newRequest.printingOptions.templateLetter.disparoImediato', imediate);
        Ember.set(this, 'newRequest.printingOptions.templateSMS.disparoImediato', imediate);
        Ember.set(this, 'newRequest.printingOptions.templateEmail.disparoImediato', imediate);
        Ember.set(this, 'newRequest.multiChannel', value);
      },
      nextRouter: function () {
        let nr = Ember.get(this, 'newRequest.typeConfig');
        let nextWallet = this.get('newRequest.walletId');
        var order = this.test.verifySendOrder();

        if (nr == 'pdf_fechado') {
          this.transitionToRoute('services.data');
        } else if (nr == 'models_templates' || nr == 'file_layout') {
          //route models #2
          let letter = Ember.get(this, 'newRequest.letterService');
          let sms = Ember.get(this, 'newRequest.smsService');
          let email = Ember.get(this, 'newRequest.emailService');

          if (nr == "file_layout") {
            Ember.set(this, 'newRequest.fileDb', 'layout');
          }

          if (letter) {
            this.transitionToRoute('services.letter', {
              queryParams: {
                wallet: nextWallet
              }
            });
          } else if (sms) {
            this.transitionToRoute('services.sms', {
              queryParams: {
                wallet: nextWallet
              }
            });
          } else if (email) {
            this.transitionToRoute('services.email', {
              queryParams: {
                wallet: nextWallet
              }
            });
          } else {
            this.transitionToRoute('services.info');
          }
        } else if (nr == 'creation') {
          // model creation
          this.transitionToRoute('services.data');
        }
      }
    }
  });

  _exports.default = _default;
});