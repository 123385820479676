define("printpost/authenticators/loopback", ["exports", "ember-simple-auth-loopback/authenticators/loopback", "printpost/config/environment"], function (_exports, _loopback, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const api = _environment.default.APP.API.host + '/' + _environment.default.APP.API.namespace;

  var _default = _loopback.default.extend({
    loginEndpoint: api + '/Accounts/login'
  });

  _exports.default = _default;
});