define("printpost/templates/components/thumb-min", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6cpq/Vz5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card-container mr-30\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"mini-card \",[21,\"color\"]]]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"mini-card title-card pl-20\"],[9],[0,\"\\n            \"],[7,\"h2\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n        \"],[10],[0,\"        \\n        \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/thumb-min.hbs"
    }
  });

  _exports.default = _default;
});