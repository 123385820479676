define("printpost/routes/prices/pricing", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: async function () {
      var url = this.baseapi.apiLink('PricingTables/precification');
      const obj = await this.baseapi.getHttp(url).then(r => r.data); //find salesman

      let query = {
        filter: {
          where: {
            or: [{
              type: 'salesman'
            }, {
              type: 'vendedor'
            }]
          }
        }
      };
      let strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url_salesman = this.baseapi.apiLink('Accounts/list');
      const obj_salesman = await this.baseapi.getHttp(url_salesman + '?' + strquery).then(r => {
        let objReturn = [];
        let ini = {
          "selectText": "Selecione",
          "selectId": "0",
          "selectValue": "0"
        };
        objReturn.push(ini);
        r.data.forEach(element => {
          let item = {
            "id": element.id,
            "username": element.username,
            "selectText": element.username,
            "selectId": element.id,
            "selectValue": element.id
          };
          objReturn.push(item);
        });
        return objReturn;
      }); //find format options

      var url_formatoptions = this.baseapi.apiLink('PrintFormatOptions/list');
      const obj_formatoptions = await this.baseapi.getHttp(url_formatoptions).then(r => r.data[0]); //find providers

      var url_provider = this.baseapi.apiLink('Providers/list');
      const obj_providers = await this.baseapi.getHttp(url_provider).then(r => r.data); //find channels

      var url_channels = this.baseapi.apiLink('Channels/list');
      const obj_channels = await this.baseapi.getHttp(url_channels).then(r => r.data); //find layouts

      var url_layout = this.baseapi.apiLink('Layouts/list');
      const obj_layout = await this.baseapi.getHttp(url_layout).then(r => r.data);
      var dataLayouts = {
        sms: [],
        email: [],
        whatsapp: [],
        validation: []
      };
      obj_channels.forEach(c => {
        if (c.channel == "carta") return;
        var itens = obj_layout.filter(x => x.channelId == c.id && x.type == 'integration');
        dataLayouts[`${c.channel}`] = itens;
      });
      dataLayouts.validation = obj_layout.filter(x => x.type == 'validation');
      return Ember.RSVP.hash({
        data: obj,
        dataSalesman: obj_salesman,
        dataFormatOptions: obj_formatoptions,
        dataProviders: obj_providers,
        dataChannels: obj_channels,
        dataLayouts: dataLayouts
      });
    }
  });

  _exports.default = _default;
});