define("printpost/routes/operacao/profiles", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function () {
      let url = this.baseapi.apiLink('ComputerProfiles/profiles');
      let r = this.baseapi.getHttp(url);
      let resp = r.then(response => {
        let objReturn = [];
        response.data.forEach(element => {
          let item = {
            "maquinaId": element.maquinaId,
            "maquina": element.maquina,
            "usuario": element.usuario,
            "active": element.active,
            "porta": element.porta,
            "letter": element.letter,
            "sms": element.sms,
            "email": element.email,
            "selectText": element.maquina,
            "selectId": element.maquinaId,
            "selectValue": element.usuario
          };
          objReturn.push(item);
        });
        return objReturn;
      });
      return resp;
    },
    listAllComputersProfile: (0, _emberConcurrency.task)(function () {
      let url = this.baseapi.apiLink('ComputerProfiles/profiles');
      let r = this.baseapi.getHttp(url);
      let resp = r.then(response => {
        return response.data;
      });
      return resp;
    }).keepLatest()
  });

  _exports.default = _default;
});