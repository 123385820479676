define("printpost/routes/operacao/filetreatment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        optionsCard: 'mrl',
        selectedColumnGroup: null,
        filesData: null,
        removeDuplicate: 1,
        optionsQtdItems: [{
          qtd: 1
        }, {
          qtd: 2
        }, {
          qtd: 3
        }, {
          qtd: 4
        }, {
          qtd: 5
        }, {
          qtd: 6
        }, {
          qtd: 7
        }, {
          qtd: 8
        }, {
          qtd: 9
        }, {
          qtd: 10
        }],
        fileXls: null,
        filesDataLenght: 0,
        itenReturnNotFound: '',
        mrl: {
          layout2: {
            qtdPerItem: {
              qtd: 5
            },
            columnCheckPerItem: {
              name: 'contrato'
            },
            removeDuplicate: 1,
            columnsRemove: ['coobrigado', 'referencia', 'motivo_devolucao', 'acordo', 'devolucao', 'modalidade', 'garantia', 'protesto', 'evento'],
            columnsItemNotRepeat: ['nome_devedor', 'cpfcnpj', 'endereco', 'cidade', 'uf', 'cep', 'email_para_contato', 'email_para_contato_1', 'email_para_contato_2']
          }
        },
        dataFileColumns: []
      });
    }

  });

  _exports.default = _default;
});