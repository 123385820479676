define("printpost/controllers/sign/out", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    appController: Ember.inject.controller('application'),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    logoutTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500);
      const token = this.get('session.data.authenticated.id');
      let url = this.baseapi.apiLink('Accounts/logout');
      yield this.baseapi.post(url + '?access_token=' + token, {});
      yield this.get('appController').send('invalidateSession');
      localStorage.clear(); //this.afterLogoutTask.perform() 
    }),
    actions: {
      logout() {
        this.logoutTask.perform();
      }

    }
  });

  _exports.default = _default;
});