define("printpost/services/dashboard", ["exports", "lodash", "axios", "moment", "printpost/config/environment", "printpost/utils/globalFunctions"], function (_exports, _lodash, _axios, _moment, _environment, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    apiLink(path) {
      return [_environment.default.APP.API.host, _environment.default.APP.API.namespace, path].join('/');
    },

    getRequests() {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/Request` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getFilteredRequests(params) {
      if (!params.type) {
        return false;
      }

      const filter = params.type + 'Service';
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/Request` + `/?filter={"where":{${filter}:"true"}}` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getWalletTask(params) {
      const BASE_URL = this.apiLink('Requests/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${params}` + `/wallet` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getRequestById(id) {
      const BASE_URL = this.apiLink('Requests/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${id}` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getSmsTemplates(id) {
      const BASE_URL = this.apiLink('Requests/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${id}` + `/smsTemplate` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getLetterTemplates(id) {
      const BASE_URL = this.apiLink('Requests/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${id}` + `/letterTemplate` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getEmailTemplates(id) {
      const BASE_URL = this.apiLink('Requests/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${id}` + `/emailTemplate` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getTemplateChannel(id) {
      const BASE_URL = this.apiLink('Templates/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${id}` + `/channel` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    listCampaign: async function () {
      const queryRequestTags = {
        filter: {
          fields: {
            name: true,
            id: true
          }
        }
      };
      const strQueryRequestTags = (0, _globalFunctions.apiLinkQuery)(queryRequestTags);
      const linkRequestTags = this.baseapi.apiLink(`RequestTags/find`);
      return this.baseapi.getHttp(linkRequestTags).then(c => c.data);
    },
    listIdsRequest: async function (listCampaign, byCampaign) {
      let campaignNames = [];

      if (byCampaign) {
        campaignNames.push(byCampaign);
      } else {
        // Stores all names
        listCampaign.forEach(c => {
          campaignNames.push(c.name);
        });
      }

      const queryRequest = {
        filter: {
          fields: {
            title: true,
            id: true
          }
        }
      };
      const strQueryRequest = (0, _globalFunctions.apiLinkQuery)(queryRequest);
      const linkRequest = this.baseapi.apiLink(`Requests/list`);
      let listRequest = await this.baseapi.getHttp(linkRequest + '?' + strQueryRequest).then(r => r.data);
      let idsRequest = [];
      listRequest.forEach(r => {
        campaignNames.forEach(campaign => {
          if (campaign === r.title && r.status != 'Pendente' && r.status != 'Cancelado') {
            idsRequest.push(r.id);
          }
        });
      });
      return idsRequest;
    },
    loadDashboard: async function (requestTagId, date, costCenterId = null) {
      let objDataReturn = {
        carta: [],
        cartaNotReceive: 0,
        cartaReceive: 0,
        cartaAll: 0,
        email: [],
        emailNotReceive: 0,
        emailReceive: 0,
        emailAll: 0,
        sms: [],
        smsNotReceive: 0,
        smsReceive: 0,
        smsAll: 0,
        whatsapp: [],
        whatsappNotReceive: 0,
        whatsappReceive: 0,
        whatsappAll: 0
      };
      var objDate = new Date();
      const dateStart = date && date.start ? date.start : `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T00:00:00-03:00`;
      const dateEnd = date && date.end ? date.end : `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T23:59:00-03:00`;
      var query_reqreturn = {
        filter: {
          where: {
            and: [{
              createdAt: {
                gte: dateStart
              }
            }, {
              createdAt: {
                lte: dateEnd
              }
            }]
          }
        }
      };

      if (requestTagId && requestTagId.length > 0) {
        query_reqreturn.filter.where.requestTagId = `${requestTagId}`;
      }

      if (costCenterId && costCenterId.length > 0) {
        query_reqreturn.filter.where.costCenterId = costCenterId;
      }

      const strquery_reqreturn = (0, _globalFunctions.apiLinkQuery)(query_reqreturn);
      const url_typereturn = this.baseapi.apiLink('RequestDatasets/dashboard');
      var obj = await this.baseapi.getHttp(url_typereturn + '?' + strquery_reqreturn).then(r => r.data);

      if (obj) {
        obj.forEach(o => {
          if (o['carta']) {
            for (let i = 0; i < o['carta'].length; i++) {
              let newObj = {};
              const ec = o['carta'][i];
              let keys = Object.keys(ec);

              for (let k = 0; k < keys.length; k++) {
                const key = keys[k];
                newObj[key] = ec[key];
              }

              let item = objDataReturn.carta.find(x => x.id == newObj.id);

              if (item) {
                item.quantity += newObj.quantity;
              } else {
                objDataReturn.carta.push(newObj);
              }
            } // objDataReturn.carta = await serializerItemReturn(element['carta']);


            objDataReturn.cartaAll += o['cartaAll'];
            objDataReturn.cartaNotReceive += o['cartaNotReceive'];
            objDataReturn.cartaReceive += o['cartaReceive'];
          } else if (o['sms']) {
            for (let i = 0; i < o['sms'].length; i++) {
              let newObj = {};
              const ec = o['sms'][i];
              let keys = Object.keys(ec);

              for (let k = 0; k < keys.length; k++) {
                const key = keys[k];
                newObj[key] = ec[key];
              }

              let item = objDataReturn.sms.find(x => x.id == newObj.id);

              if (item) {
                item.quantity += newObj.quantity;
              } else {
                objDataReturn.sms.push(newObj);
              }
            } // objDataReturn.sms = await serializerItemReturn(element['sms']);


            objDataReturn.smsAll += o['smsAll'];
            objDataReturn.smsNotReceive += o['smsNotReceive'];
            objDataReturn.smsReceive += o['smsReceive'];
          } else if (o['email']) {
            for (let i = 0; i < o['email'].length; i++) {
              let newObj = {};
              const ec = o['email'][i];
              let keys = Object.keys(ec);

              for (let k = 0; k < keys.length; k++) {
                const key = keys[k];
                newObj[key] = ec[key];
              }

              let item = objDataReturn.email.find(x => x.id == newObj.id);

              if (item) {
                item.quantity += newObj.quantity;
              } else {
                objDataReturn.email.push(newObj);
              }
            } // objDataReturn.email = await serializerItemReturn(element['email']);


            objDataReturn.emailAll += o['emailAll'];
            objDataReturn.emailNotReceive += o['emailNotReceive'];
            objDataReturn.emailReceive += o['emailReceive'];
          } else if (o['whatsapp']) {
            for (let i = 0; i < o['whatsapp'].length; i++) {
              let newObj = {};
              const ec = o['whatsapp'][i];
              let keys = Object.keys(ec);

              for (let k = 0; k < keys.length; k++) {
                const key = keys[k];
                newObj[key] = ec[key];
              }

              let item = objDataReturn.whatsapp.find(x => x.id == newObj.id);

              if (item) {
                item.quantity += newObj.quantity;
              } else {
                objDataReturn.whatsapp.push(newObj);
              }
            } // objDataReturn.email = await serializerItemReturn(element['email']);


            objDataReturn.whatsappAll += o['whatsappAll'];
            objDataReturn.whatsappNotReceive += o['whatsappNotReceive'];
            objDataReturn.whatsappReceive += o['whatsappReceive'];
          }
        });
      }

      function fieldColor(array) {
        let fc = [];
        array.forEach(element => {
          if (!fc.length) {
            fc.push(element);
          } else {
            element.isPar = fc[fc.length - 1].isPar ? false : true;
            fc.push(element);
          }
        });
        return fc;
      }

      objDataReturn.carta = await new Promise(async resolve => {
        const c = _lodash.default.groupBy(objDataReturn['carta'], 'description');

        const j = await this.junction(c, 'carta');
        resolve(fieldColor(j));
      });
      objDataReturn.email = await new Promise(async resolve => {
        const e = _lodash.default.groupBy(objDataReturn['email'], 'description');

        const j = await this.junction(e, 'email');
        resolve(fieldColor(j));
      });
      objDataReturn.sms = await new Promise(async resolve => {
        const s = _lodash.default.groupBy(objDataReturn['sms'], 'description');

        const j = await this.junction(s, 'sms');
        resolve(fieldColor(j));
      });
      objDataReturn.whatsapp = await new Promise(async resolve => {
        const s = _lodash.default.groupBy(objDataReturn['whatsapp'], 'description');

        const j = await this.junction(s, 'whatsapp');
        resolve(fieldColor(j));
      });
      return objDataReturn;
    },
    junction: async function (action, strAction) {
      let group = [];

      _lodash.default.forEach(action, async c => {
        await c;
        let objPanel = {
          [strAction]: {
            channelName: '',
            description: '',
            isPar: true,
            quantity: 0
          }
        };

        if (c.length > 1) {
          objPanel[strAction].channelName = c[0].channelName;
          objPanel[strAction].description = c[0].description;
          c.reduce(async (p, element) => {
            await p;
            objPanel[strAction].quantity += element.quantity;
          }, Promise.resolve());
        } else if (c.length === 1) {
          objPanel[strAction].channelName = c[0].channelName;
          objPanel[strAction].description = c[0].description;
          objPanel[strAction].quantity = c[0].quantity;
        }

        return group.push(objPanel[strAction]);
      }, Promise.resolve());

      return group;
    },
    dashboardQuantity: async function () {
      const date = (0, _moment.default)().format('YYYY-MM-DD').split('-');
      const url = this.baseapi.apiLink('Requests/dashboard');
      const qtd_all = await this.baseapi.getHttp(url).then(r => r.data);
      let saida = [{
        'checkRequests': qtd_all.analise.total + qtd_all.execucao.total + qtd_all.finalizados.total,
        'unfinished': qtd_all.analise.total + qtd_all.execucao.total,
        'analiseRequests': qtd_all.analise.total,
        'prodRequests': qtd_all.execucao.total,
        'finalizadoRequests': qtd_all.finalizados.total,
        'canceladoRequests': qtd_all.cancelados.total,
        'allRequests': qtd_all.analise.total + qtd_all.execucao.total + qtd_all.finalizados.total + qtd_all.cancelados.total,
        'execucaoRequests': 0,
        'analiseLetter': qtd_all.analise.carta,
        'analiseSms': qtd_all.analise.sms,
        'analiseEmail': qtd_all.analise.email,
        'analiseWhatsapp': qtd_all.analise.whatsapp,
        'execLetter': qtd_all.execucao.carta,
        'execSms': qtd_all.execucao.sms,
        'execEmail': qtd_all.execucao.email,
        'execWhatsapp': qtd_all.execucao.whatsapp,
        'finalizadoLetter': qtd_all.finalizados.carta,
        'finalizadoSms': qtd_all.finalizados.sms,
        'finalizadoEmail': qtd_all.finalizados.email,
        'finalizadoWhatsapp': qtd_all.finalizados.whatsapp,
        'execucaoLetter': 0,
        'execucaoSms': 0,
        'execucaoEmail': 0,
        'month': date[1],
        'year': date[0]
      }];
      return saida;
    }
  });

  _exports.default = _default;
});