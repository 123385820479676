define("printpost/routes/perfis/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: async function () {
      var url = this.baseapi.apiLink('Roles/list');
      var saida = [];
      const obj = await this.baseapi.getHttp(url).then(r => r.data);
      saida = [{
        "objects": obj
      }];
      return Ember.RSVP.hash({
        data: saida
      });
    }
  });

  _exports.default = _default;
});