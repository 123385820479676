define("printpost/templates/components/data-tablev2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S49WfLuN",
    "block": "{\"symbols\":[\"b\",\"k\",\"h\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ember-table ember-view\"],[9],[0,\"\\n  \"],[7,\"table\"],[9],[0,\"\\n    \"],[7,\"thead\"],[11,\"class\",\"ember-view\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"header\"]]],null,{\"statements\":[[0,\"          \"],[7,\"th\"],[9],[1,[22,3,[]],false],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"header\"]]],null,{\"statements\":[[0,\"        \"],[7,\"td\"],[9],[0,\" \"],[1,[27,\"object-dinamic\",[[22,1,[]],[22,2,[]]],null],false],[0,\"  \"],[10],[0,\"  \\n\"]],\"parameters\":[2]},null],[0,\"      \"],[10],[0,\"        \\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/data-tablev2.hbs"
    }
  });

  _exports.default = _default;
});