define("printpost/routes/contatos/peoples", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      var query = {
        filter: {
          where: {
            id: queryParams.id
          }
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url = this.baseapi.apiLink(`ContactFiles/peoples?${strquery}`);
      const obj = await this.baseapi.getHttp(url).then(r => r.data);
      let item = obj && obj.length > 0 ? obj[0] : null;
      console.log('queryParams', queryParams);
      return Ember.RSVP.hash({
        contactFileId: queryParams.id,
        tableColumns: item ? item.columns : [],
        peoplesList: item ? item.contactPeople : [],
        peopleFilter: null,
        walletData: item
      });
    }
  });

  _exports.default = _default;
});