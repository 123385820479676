define("printpost/abilities/config", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    session: Ember.inject.service(),
    user: Ember.computed.reads('session.currentUser').readOnly(),
    canCreate: Ember.computed.reads('user.isAdmin').readOnly()
  });

  _exports.default = _default;
});