define("printpost/routes/transmissao/edit", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      let sendMonitoringCostCenter = {
        list: [],
        main: null
      };
      let url = this.baseapi.apiLink('SendMonitorings/list');
      let item = {
        name: "",
        phone: "",
        mail: "",
        active: true
      };
      let url_costcenter = this.baseapi.apiLink('CostCenters/list');
      const obj_costcenter = await this.baseapi.getHttp(url_costcenter).then(r => r.data);
      let costCenterItem = [];

      if (queryParams && queryParams.id) {
        let query = {
          filter: {
            where: {
              id: queryParams.id
            }
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        url += "?" + strquery;
        const obj = await this.baseapi.getHttp(url).then(r => r.data);
        item = obj.find(x => x.id == queryParams.id);

        if (item.sendMonitoringCostCenter && item.sendMonitoringCostCenter.length) {
          costCenterItem = [];
          item.sendMonitoringCostCenter.forEach(item => {
            if (item.costCenter) {
              costCenterItem.push(item.costCenter);
            }
          });
        }
      }

      return Ember.RSVP.hash({
        item: item,
        organizationId: null,
        obj_costcenter: obj_costcenter,
        sendMonitoringCostCenter: sendMonitoringCostCenter,
        costCenterSelected: costCenterItem
      });
    }
  });

  _exports.default = _default;
});