define("printpost/routes/prices/linkcustomer", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    //[include][reviews]=author
    model: async function () {
      var query = {
        filter: {
          include: ['account', {
            'pricingTable': 'channel'
          }]
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url = this.baseapi.apiLink('LinkCustomers/list');
      var saida = [];
      const obj = await this.baseapi.getHttp(url + '?' + strquery).then(r => r.data);
      saida = [{
        "objects": obj
      }];
      return Ember.RSVP.hash({
        data: saida
      });
    }
  });

  _exports.default = _default;
});