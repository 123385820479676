define("printpost/routes/whitelabel/edit", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      // let id_param = getUrlParameters("id", "", true);
      var url = this.baseapi.apiLink('WhiteLabels/list');
      var list = [];
      let item = {
        name: "",
        url: "",
        logo: {},
        favicon: {},
        banner: {},
        active: true,
        homeMessage: "",
        homeSubMessage: "",
        defaultColor: ""
      };

      if (queryParams && queryParams.id) {
        let query = {
          filter: {
            where: {
              id: queryParams.id
            }
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        url += "?" + strquery;
        const obj = await this.baseapi.getHttp(url).then(r => r.data);
        item = obj.find(x => x.id == queryParams.id);
      }

      return Ember.RSVP.hash({
        item: item
      });
    }
  });

  _exports.default = _default;
});