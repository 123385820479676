define("printpost/routes/blacklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: async function () {
      return Ember.RSVP.hash({
        type: 'email',
        isLoading: false,
        blacklistData: {
          action: '',
          cadastrar: [],
          consultar: [],
          deletar: [],
          findResult: null
        }
      });
    }
  });

  _exports.default = _default;
});