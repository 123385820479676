define("printpost/controllers/operacao/filelink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    isUploadReturn: Ember.computed(function () {
      return false;
    }),
    actions: {
      setFileReturn: function (data) {
        Ember.set(this, 'model.filesReturn', data.target.files);
        Ember.set(this, 'model.filesReturnLenght', data.target.files.length);
      },
      sendFilesDataReturn: function () {
        var filesSend = Ember.get(this, 'model.filesReturn');
        var url = 'rawFiles/upload-file-link';
        let form = new FormData();
        form.set("type", 'fileLink');
        form.set("mode", 'fileLink');

        if (filesSend && filesSend.length > 0) {
          for (let i = 0; i < filesSend.length; i++) {
            const file = filesSend[i];
            form.append("file", file);
          }
        }

        let urlP = this.baseapi.apiLink(url);
        this.preloader.toggleClass('d-none');
        this.baseapi.postFormData(urlP, form).then(resp => {
          this.preloader.toggleClass('d-none');
          Ember.$("#upload-file").val('');
          Ember.set(this, 'model.filesReturn', '');
          Ember.set(this, 'model.filesReturnLenght', 0);

          if (resp.data.success) {
            var msg = '';
            var ok = 'Arquivos enviados com sucesso.';
            this.toast.success('', ok, {
              positionClass: 'toast-bottom-right'
            });
          } else {
            this.toast.error('', resp.data.errors[0].detail, {
              positionClass: 'toast-bottom-right'
            });
          }
        }, error => {
          Ember.$("#upload-file").val('');
          Ember.set(this, 'model.filesReturn', '');
          Ember.set(this, 'model.filesReturnLenght', 0);
          this.preloader.toggleClass('d-none');
          this.toast.error('', `Erro:. ${error.response.data.errors[0].detail}`, {
            positionClass: 'toast-bottom-right'
          });
        });
      }
    }
  });

  _exports.default = _default;
});