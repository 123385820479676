define("printpost/components/mng-address", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    typeAddress: Ember.computed('params.[]', function () {
      return this.params[0];
    }),
    itemIdentify: Ember.computed('params.[]', function () {
      return this.params[1];
    }),
    valueIdentify: Ember.computed('params.[]', function () {
      return this.params[2];
    }),
    organizationId: Ember.computed('params.[]', function () {
      return this.params[3];
    }),
    dataEndereco: Ember.computed('dataEndereco', function () {
      var v = {
        id: '',
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        complemento: '',
        numero: '',
        cidadecep: '',
        userId: '',
        organizationId: this.organizationId,
        type: this.typeAddress
      };
      var keyIdentify = this.get('itemIdentify');
      var valueIdentify = this.get('valueIdentify');
      if (!v[keyIdentify]) v[keyIdentify] = valueIdentify;
      return v;
    }).property().volatile(),
    dataEmpty: Ember.computed(function () {
      return null;
    }),
    actions: {
      doSelectAddress(address) {
        // set(this, 'dataEnderecoAccount', address);
        Ember.set(this, 'dataEndereco', address);
        this.selectAddress(address);
      },

      setAddress(address) {
        let d = {
          id: address.id,
          nome: address.nome,
          endereco: address.endereco,
          bairro: address.bairro,
          cidade: address.cidade,
          uf: address.uf,
          cep: address.cep,
          principal: address.principal,
          complemento: address.complemento,
          numero: address.numero,
          cidadecep: address.cidadecep,
          type: address.type,
          organizationId: address.organizationId
        };
        var keyIdentify = this.get('itemIdentify');
        var valueIdentify = this.get('valueIdentify');
        if (!d[keyIdentify]) d[keyIdentify] = valueIdentify;
        Ember.set(this, 'dataEndereco', d);
      },

      clearDataEndereco() {
        let d = {
          id: '',
          nome: '',
          endereco: '',
          bairro: '',
          cidade: '',
          uf: '',
          cep: '',
          complemento: '',
          numero: '',
          cidadecep: '',
          type: '',
          organizationId: ''
        };
        var keyIdentify = this.get('itemIdentify');
        var valueIdentify = this.get('valueIdentify');
        if (!d[keyIdentify]) d[keyIdentify] = valueIdentify;
        Ember.set(this, 'dataEndereco', d);
      },

      buscaCEP() {
        let t = Ember.$('#cep_addres').val();
        t = (0, _globalFunctions.removeSpecialChar)(t).replace("-", "").replace(".", "");
        Ember.$('#cep_addres').val(t);

        if (t.length < 8) {
          this.toast.error('CEP Inválido!', 'Informe CEP válido.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let url = "//viacep.com.br/ws/" + t + "/json";
        let r = this.baseapi.getHttp(url, false);
        r.then(response => {
          let data = response.data;
          Ember.set(this, 'dataEndereco.cep', t);
          Ember.set(this, 'dataEndereco.endereco', data.logradouro);
          Ember.set(this, 'dataEndereco.bairro', data.bairro);
          Ember.set(this, 'dataEndereco.complemento', data.complemento);
          Ember.set(this, 'dataEndereco.cidade', data.localidade);
          Ember.set(this, 'dataEndereco.uf', data.uf);
          Ember.set(this, 'dataEndereco.cidadecep', data.localidade + ' - ' + data.uf);
        });
      },

      salvarEndereco: function () {
        let modelName = 'Address';
        let modelPluralName = 'Addresses';
        let item = Ember.get(this, 'dataEndereco');
        let account = this.baseapi.getCurrentUser();
        if (account.organizationId) item.organizationId = account.organizationId;
        if (!item.organizationId && this.organizationId) item.organizationId = this.organizationId;
        item.type = this.typeAddress;
        let _object = {
          data: {
            type: modelName,
            attributes: item
          }
        };

        if (item.nome.length > 0 && item.numero > 0 && item.endereco.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink(modelPluralName);
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(() => {
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
              this.reloadAddress(item);
            }, () => {
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
            });
          } else {
            let url = this.baseapi.apiLink(modelPluralName + '/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(() => {
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
              this.reloadAddress(item);
            }, () => {
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
            });
          }
        } else {
          this.toast.error('Item Não cadastrado!', 'Verifique os campos obrigatórios!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      apagarEndereco: function () {
        let modelPluralName = 'Addresses';
        this.preloader.toggleClass('d-none');
        let item_data = Ember.get(this, 'dataEndereco');

        if (item_data.id) {
          let url = this.baseapi.apiLink(modelPluralName);
          let call = this.baseapi.delete(url + '/' + item_data.id);
          call.then(() => {
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
            this.reloadAddress(item_data);
          }, () => {
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
          });
        }
      }
    }
  }).reopenClass({
    positionalParams: 'params'
  });

  _exports.default = _default;
});