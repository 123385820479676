define("printpost/controllers/contatos/peoples", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    reloadMapWallet: (0, _emberConcurrency.task)(function* () {
      console.log('enter her !!!');
      let contactFileId = this.get('model.contactFileId');
      var query = {
        filter: {
          where: {
            id: contactFileId
          }
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');
      var url = this.baseapi.apiLink(`ContactFiles/peoples?${strquery}`); //const data = yield this.baseapi.getHttp(url).then(x => x.data);

      const obj = yield this.baseapi.getHttp(url).then(r => r.data);
      let item = obj && obj.length > 0 ? obj[0] : null;
      this.preloader.toggleClass('d-none');
      this.set('model.peoplesList', item.contactPeople);
    }),
    filterWalletPeople: (0, _emberConcurrency.task)(function* () {
      var defaultFilter = this.get('model.peopleFilter');
      let query = {
        filter: {
          where: defaultFilter
        }
      };
      query.filter.where.contactFileId = this.get('model.contactFileId');
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');
      var url = this.baseapi.apiLink(`ContactFiles/people-filter?${strquery}`);
      var peoples = yield this.baseapi.getHttp(url).then(x => x.data);
      this.preloader.toggleClass('d-none');
      this.set('model.peoplesList', peoples);
    }),
    actions: {
      showInputSearch(idx) {
        Ember.$(`#header${idx}`).toggleClass('d-none');
        Ember.$(`#label${idx}`).toggleClass('d-none');
        console.log(idx);
      },

      searchItem(idx, key) {
        if (key.keyCode == 13) {
          this.get('filterWalletPeople').perform();
          return;
        }

        let dataFilter = this.get('model.peopleFilter');
        var val = Ember.$(`#header${idx}`).val();
        var column = this.get('model.tableColumns')[idx];
        var query = {};

        if (dataFilter) {
          dataFilter[column] = `${val}`;
          query = dataFilter;
        } else {
          query[column] = `${val}`;
        }

        this.set('model.peopleFilter', query);
      },

      updatePeoples() {
        var list = this.get('model.peoplesList');
        var data = list.filter(x => x.changed == true);

        if (data.length == 0) {
          this.toast.error('', 'É necessário alterar algum registro', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let url = this.baseapi.apiLink('ContactPeople');
        this.preloader.toggleClass('d-none');
        this.baseapi.patch(`${url}/update`, {
          data: data
        }).then(resp => {
          this.preloader.toggleClass('d-none');
          this.toast.success('', 'Atualização realizacao com sucesso!', {
            positionClass: 'toast-bottom-right'
          });
          this.get('reloadMapWallet').perform();
        }, () => {
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro na atualizaçao', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      changePeopleData(people, key, input) {
        var data = this.get('model.peoplesList');
        var item = data.find(x => x.id == people.id);
        if (!item) return;
        item[key] = input;
        item['changed'] = true;
        Ember.$(`#tr${people.id}`).addClass('approve-1'); // console.log(data, item);

        this.set('model.peoplesList', data);
      },

      downloadWallet(file) {
        this.preloader.toggleClass('d-none');
        let url = this.baseapi.apiLink('ContactFiles');
        let token = this.baseapi.getToken();
        url += '/download/' + file.id + '?access_token=' + token;
        this.baseapi.getDownload(url, file.name, false);
        this.preloader.toggleClass('d-none');
      }

    }
  });

  _exports.default = _default;
});