define("printpost/routes/invoice/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      reference: null
    },
    model: async function (queryParams) {
      var params = queryParams;
      var monthRef = params.reference ? params.reference : `${this.baseapi.padStr(new Date().getMonth() + 1)}/${new Date().getFullYear()}`;
      var url = this.baseapi.apiLink('Invoices/map-production?');
      var objData = {
        clientData: [],
        totalValues: null,
        providerData: []
      };

      try {
        objData = await this.baseapi.getHttp(`${url}filter[where][monthReference]=${monthRef}`).then(x => x.data);
      } catch (error) {
        this.toast.error('', 'Erro ao buscar dados', {
          positionClass: 'toast-bottom-right'
        });
      }

      return Ember.RSVP.hash({
        data: objData.clientData,
        totalData: objData.totalValues,
        providerData: objData.providerData,
        monthRef: monthRef
      });
    }
  });

  _exports.default = _default;
});