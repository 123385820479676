define("printpost/models/customer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    credit: _emberData.default.attr('string'),
    commercialReference: _emberData.default.attr('string')
  });
  /*
  "name": "string",
  "paymentType": "string",
  "personType": "string",
  "invoiceDay": 0,
  "credit": 0,
  "cnpj": "string",
  "cpf": "string",
  "telephone": "string",
  "approved": true,
  "bankReference": "string",
  "bankReference2": "string",
  "commercialReference": "string",
  "commercialReference2": "string",
  "id": "string",
  "deletedAt": "2018-12-06T11:53:36.393Z",
  "createdAt": "2018-12-06T11:53:36.394Z",
  "updatedAt": "2018-12-06T11:53:36.394Z",
  "customerId": "string",
  "accountId": "string",
  "responsableId": "string"
  */


  _exports.default = _default;
});