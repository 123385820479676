define("printpost/models/_choices/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    state: {
      'seen': 'seen',
      'readed': 'readed',
      'unread': 'unread'
    }
  };
  _exports.default = _default;
});