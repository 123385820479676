define("printpost/controllers/retornos", ["exports", "ember-group-by", "moment"], function (_exports, _emberGroupBy, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.options = this.options || {
        "elements": {
          "line": {
            "tension": 0
          }
        },
        "responsive": true,
        "devicePixelRatio": 1,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#000"
          }
        },
        "scales": {
          "yAxes": [{
            "display": false,
            "ticks": {
              "beginAtZero": true
            },
            "gridLines": {
              "display": true
            }
          }],
          "xAxes": [{
            "display": true,
            "gridLines": {
              "display": true
            }
          }]
        }
      };
    },

    queryParams: ['type'],
    type: null,
    execucaoRequests: Ember.computed('model.execucaoRequests', function () {
      return this.get('model.execucaoRequests');
    }),
    wallet: Ember.computed('model.wallet', function () {
      return this.get('model.wallet');
    }),
    letterReturns: Ember.computed('model.letterReturns', function () {
      return this.get('model.letterReturns');
    }),
    allReturns: Ember.computed('model.allReturns', function () {
      return this.get('model.allReturns');
    }),
    letterReturnMessages: (0, _emberGroupBy.default)('model.letterReturns', 'reason'),
    letterChart: Ember.computed('model.letterReturns.[]', function () {
      var model = this.get('model.letterReturns');
      var modelList = []; //let lista = model.map((item) => item.channel);

      model.forEach(item => {
        modelList.push(item);
      });
      let data = {
        "datasets": [{
          "label": "",
          "fill": false,
          "data": [40, 60, 210, 140, 40, 80, 100],
          "lineTension": 0.1,
          "borderColor": "#A7B1D1",
          "pointBackgroundColor": "#ED9DA8",
          "pointBorderColor": "#ED9DA8"
        }]
      };
      var dateArray = [];
      var returnedArray = []; //DATE CONFIG

      var date = new Date();
      var days = 7;
      var res = date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000);
      var minDate = new Date(res);
      var formatedMin = (0, _moment.default)(minDate).format(); //DATE DATA ITERATOR

      for (var i = 0; i < days; i++) {
        formatedMin = (0, _moment.default)(formatedMin).add(1, 'days');
        dateArray.push((0, _moment.default)(formatedMin).format('DD'));
        var returnedSum = 0;

        for (var x = 0; x < modelList.length; x++) {
          if ((0, _moment.default)(modelList[x].updatedAt).format('DD') == dateArray[i]) {
            returnedSum += 1;
          }
        }

        returnedArray.push(returnedSum);
      }

      data['datasets'][0]['data'] = returnedArray;
      data['labels'] = dateArray;
      return data;
    }),
    smsReturns: Ember.computed('model.smsReturns', function () {
      return this.get('model.smsReturns');
    }),
    smsReturnMessages: (0, _emberGroupBy.default)('model.smsReturns', 'reason'),
    smsChart: Ember.computed('model.smsReturns.[]', function () {
      var model = this.get('model.smsReturns');
      var modelList = []; //let lista = model.map((item) => item.channel);

      model.forEach(item => {
        modelList.push(item);
      });
      let data = {
        "datasets": [{
          "label": "",
          "fill": false,
          "data": [40, 60, 210, 140, 40, 80, 100],
          "lineTension": 0.1,
          "borderColor": "#A7B1D1",
          "pointBackgroundColor": "#ED9DA8",
          "pointBorderColor": "#ED9DA8"
        }]
      };
      var dateArray = [];
      var returnedArray = []; //DATE CONFIG

      var date = new Date();
      var days = 7;
      var res = date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000);
      var minDate = new Date(res);
      var formatedMin = (0, _moment.default)(minDate).format(); //DATE DATA ITERATOR

      for (var i = 0; i < days; i++) {
        formatedMin = (0, _moment.default)(formatedMin).add(1, 'days');
        dateArray.push((0, _moment.default)(formatedMin).format('DD'));
        var returnedSum = 0;

        for (var x = 0; x < modelList.length; x++) {
          if ((0, _moment.default)(modelList[x].updatedAt).format('DD') == dateArray[i]) {
            returnedSum += 1;
          }
        }

        returnedArray.push(returnedSum);
      }

      data['datasets'][0]['data'] = returnedArray;
      data['labels'] = dateArray;
      return data;
    }),
    emailReturns: Ember.computed('model.emailReturns', function () {
      return this.get('model.emailReturns');
    }),
    emailReturnMessages: (0, _emberGroupBy.default)('model.emailReturns', 'reason'),
    emailChart: Ember.computed('model.emailReturns.[]', function () {
      var model = this.get('model.emailReturns');
      var modelList = []; //let lista = model.map((item) => item.channel);

      model.forEach(item => {
        modelList.push(item);
      });
      let data = {
        "datasets": [{
          "label": "",
          "fill": false,
          "data": [40, 60, 210, 140, 40, 80, 100],
          "lineTension": 0.1,
          "borderColor": "#A7B1D1",
          "pointBackgroundColor": "#ED9DA8",
          "pointBorderColor": "#ED9DA8"
        }]
      };
      var dateArray = [];
      var returnedArray = []; //DATE CONFIG

      var date = new Date();
      var days = 7;
      var res = date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000);
      var minDate = new Date(res);
      var formatedMin = (0, _moment.default)(minDate).format(); //DATE DATA ITERATOR

      for (var i = 0; i < days; i++) {
        formatedMin = (0, _moment.default)(formatedMin).add(1, 'days');
        dateArray.push((0, _moment.default)(formatedMin).format('DD'));
        var returnedSum = 0;

        for (var x = 0; x < modelList.length; x++) {
          if ((0, _moment.default)(modelList[x].updatedAt).format('DD') == dateArray[i]) {
            returnedSum += 1;
          }
        }

        returnedArray.push(returnedSum);
      }

      data['datasets'][0]['data'] = returnedArray;
      data['labels'] = dateArray;
      return data;
    })
  });

  _exports.default = _default;
});