define("printpost/controllers/whatsapp/numeros", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = Ember.Object.extend({
    list_number_id: [],
    list_number_id_all: false
  });

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    fileAttach: Ember.computed('fileAttach', function () {
      return '';
    }).property().volatile(),
    reloadNumbers: (0, _emberConcurrency.task)(function* () {
      try {
        this.preloader.toggleClass('d-none');
        var url = this.baseapi.apiLink('WhatsAppNumbers/list?filter[include]=account&filter[include]=organization');
        const obj = yield this.baseapi.getHttp(url).then(r => r.data);
        this.set('model.data', obj);
        this.set('model.qrcode', '');
      } catch (error) {
        this.toast.error(``, `Erro ao listar números`, {
          positionClass: 'toast-bottom-right'
        });
      } finally {
        this.preloader.toggleClass('d-none');
      }
    }),
    reloadConfiguration: (0, _emberConcurrency.task)(function* () {
      try {
        this.preloader.toggleClass('d-none');
        var url = this.baseapi.apiLink('WhatsAppSessions/app-configuration');
        const obj = yield this.baseapi.getHttp(url).then(r => r.data);
        const itemConfiguration = obj.length ? obj[0] : {
          token: '',
          endpoint: ''
        };
        this.set('model.whatsappConfig', itemConfiguration);
      } catch (error) {
        this.toast.error(``, `Erro ao listar números`, {
          positionClass: 'toast-bottom-right'
        });
      } finally {
        this.preloader.toggleClass('d-none');
      }
    }),
    sendRawFile: function () {
      var file = Ember.get(this, 'fileAttach');
      let form = new FormData(); // let reqId = localStorage.getItem('newServiceRequestId');

      form.set("requestId", null);
      form.set("type", "whatsapp_image");
      form.set("path", 'images/templates');
      form.append("file", file);
      let ext = this.baseapi.getFileExtension(file.name);
      let url = this.baseapi.apiLink("rawFiles");
      url += "/upload-file";
      this.preloader.toggleClass('d-none');
      this.baseapi.postFormData(url, form).then(rawObj => {
        this.preloader.toggleClass('d-none');
        Ember.set(this, 'fileAttach', '');
        this.toast.success('', 'Imagem enviada com sucesso.', {
          positionClass: 'toast-bottom-right'
        });
        let data = rawObj.data;
        Ember.set(this, 'model.dataItem.picture', rawObj.data.link);
      }, error => {
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao enviar imagem, tente novamente.', {
          positionClass: 'toast-bottom-right'
        });
      });
    },
    variables: variables.create({
      list_number_id: [],
      list_number_id_all: false
    }),
    organizationForVincule: Ember.computed('organizationForVincule', function () {
      return [];
    }),
    accountsForVincule: Ember.computed('accountsForVincule', function () {
      return [];
    }),
    dataItensFilter: Ember.computed(function () {
      let d = [];
      return d;
    }),
    dataVinculeNumberView: Ember.computed('dataVinculeNumberView', function () {
      return this.get('model.data');
    }).property().volatile(),
    searchAccountTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Accounts/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    searchOrganizationTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Organizations/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    actions: {
      setFileAttach: function (type, data) {
        Ember.set(this, 'fileAttach', data.target.files[0]);
        this.sendRawFile(type);
      },

      salvarNumero() {
        let item = this.get('model.dataItem');

        if (!item.number || !item.name || !item.picture) {
          this.toast.error('', 'Informe o número, nome e imagem de perfil', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let call = {
          exec: () => {
            this.get('reloadNumbers').perform();
          }
        };
        item.profileSincronized = false;
        this.send('salvar', 'WhatsAppNumber', 'WhatsAppNumbers', item, call);
      },

      salvarConfiguracao() {
        let item = this.get('model.whatsappConfig');

        if (!item.token || !item.endpoint) {
          this.toast.error('', 'Informe o token e o endereço do endpoint', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var urlConfig = this.baseapi.apiLink('WhatsAppSessions/app-configuration');
        let call = this.baseapi.post(urlConfig, item);
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');
          this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
            positionClass: 'toast-bottom-right'
          });
          this.get('reloadConfiguration').perform();
        }, error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
            positionClass: 'toast-bottom-right'
          });
          console.log(error);
        });
      },

      salvar: function (modelName, modelPluralName, item, callback) {
        // let modelName = 'WhatsAppNumber'
        // let modelPluralName = 'WhatsAppNumbers';
        // let item = this.get('model.dataItem');
        let _object = {
          data: {
            type: modelName,
            attributes: item
          }
        };

        if (!item.id) {
          let url = this.baseapi.apiLink(modelPluralName);
          let __rp = _object;
          delete __rp.data.attributes.id;
          let call = this.baseapi.post(url, __rp);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            callback.exec();
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        } else {
          let url = this.baseapi.apiLink(modelPluralName + '/' + item.id);
          let __rp = _object;
          __rp.data.id = item.id;
          delete __rp.data.attributes.id;
          let call = this.baseapi.patch(url, __rp);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            item.id = __rp.data.id;
            callback.exec();
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },
      apagarNumero: function () {
        let modelName = 'WhatsAppNumber';
        let modelPluralName = 'WhatsAppNumbers';
        let item_data = this.get('model.dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink(modelPluralName);
          let call = this.baseapi.delete(url + '/delete/' + item_data.id);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.get('reloadNumbers').perform();
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },

      changeDataItem(str, item) {
        Ember.set(this, str, item);
      },

      clearCreateNumber() {
        this.set('model.dataItem', {
          id: '',
          number: '',
          name: '',
          picture: ''
        });
      },

      setActionTask(item) {
        this.set('model.action', item);
      },

      startSession() {
        let item_data = this.get('model.dataItem');
        let item = this.get('model.whatsappConfig');

        if (!item.token || !item.endpoint) {
          this.toast.error('', 'Informe o token e o endereço do endpoint', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (!item_data.number || !item_data.name || !item_data.picture) {
          this.toast.error('', 'Informe o número, nome e imagem de perfil', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (!item_data.id) {
          this.toast.error('', 'Identificador do número não encontrado, por favor atualize a página', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var url = this.baseapi.apiLink(`WhatsAppSessions/start/${item_data.id}`);
        let call = this.baseapi.post(url, {});
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');
          this.toast.success('', 'Sessão iniciada com sucesso', {
            positionClass: 'toast-bottom-right'
          });
          this.get('reloadNumbers').perform();
        }, error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro ao iniciar sessão', {
            positionClass: 'toast-bottom-right'
          });
          console.log(error);
        });
      },

      closeSession() {
        let item_data = this.get('model.dataItem');
        let item = this.get('model.whatsappConfig');

        if (!item.token || !item.endpoint) {
          this.toast.error('', 'Informe o token e o endereço do endpoint', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (!item_data.id) {
          this.toast.error('', 'Identificador do número não encontrado, por favor atualize a página', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var url = this.baseapi.apiLink(`WhatsAppSessions/close/${item_data.id}`);
        let call = this.baseapi.post(url, {});
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');
          this.toast.success('', 'Sessão encerrada com sucesso', {
            positionClass: 'toast-bottom-right'
          });
          this.get('reloadNumbers').perform();
        }, error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro ao encerrar sessão', {
            positionClass: 'toast-bottom-right'
          });
          console.log(error);
        });
      },

      loadQRCode(item_data) {
        let item = this.get('model.whatsappConfig');

        if (!item.token || !item.endpoint) {
          this.toast.error('', 'Informe o token e o endereço do endpoint', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (!item_data.id) {
          this.toast.error('', 'Identificador do número não encontrado, por favor atualize a página', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var url = this.baseapi.apiLink(`WhatsAppSessions/qrcode/${item_data.id}`);
        let call = this.baseapi.getHttp(url);
        call.then(resp => {
          this.set('model.qrcode', resp.data.qrcode_base64);
        }, error => {
          this.toast.error('', 'Erro ao buscar qr code sessão', {
            positionClass: 'toast-bottom-right'
          });
          console.log(error);
        });
      },

      doActionTask() {
        let act = parseInt(this.get('model.action'));

        switch (act) {
          case 1:
            this.send('startSession');
            break;

          case 2:
            this.send('closeSession');
            break;

          case 3:
            this.send('apagarNumero');
            break;

          default:
            break;
        }
      },

      reloadNumbers() {
        this.get('reloadNumbers').perform();
      },

      sendTest(value) {
        let messageTest = this.get('model.messageTest');
        console.log(messageTest, value);
        let item_data = this.get('model.dataItem');
        let item = this.get('model.whatsappConfig');

        if (!item.token || !item.endpoint) {
          this.toast.error('', 'Informe o token e o endereço do endpoint', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (!item_data.id) {
          this.toast.error('', 'Identificador do número não encontrado, por favor atualize a página', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (!messageTest.number || !value) {
          this.toast.error('', 'Informe o número e a mensagem', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var url = this.baseapi.apiLink(`WhatsAppNumbers/${item_data.id}/test`);
        let call = this.baseapi.post(url, {
          to: messageTest.number,
          message: value
        });
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');
          this.toast.success('', 'Mensagem enviada com sucesso', {
            positionClass: 'toast-bottom-right'
          });
        }, error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro ao enviar mensagem', {
            positionClass: 'toast-bottom-right'
          });
          console.log(error);
        });
      },

      setCliente(item) {
        Ember.set(this, 'dataClient', item);
      },

      setOrganization(item) {
        Ember.set(this, 'dataOrganization', item);
      },

      addLinkUser: function () {
        let usuario = Ember.get(this, 'dataClient');
        let idUser = usuario.id;
        let itens = this.variables.get('list_number_id');

        if (!itens || !itens.length) {
          this.toast.error('', 'Erro ao relacionar números, tente novamente', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        const payload = [];
        itens.forEach(x => {
          payload.push({
            "id": x.id,
            "userId": idUser
          });
        });

        if (idUser.length > 0 && payload.length > 0) {
          let url = this.baseapi.apiLink('WhatsAppNumbers');
          let call = this.baseapi.post(url + "/vincular-numero", {
            data: payload
          });
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');

            if (resp.data.errors) {
              this.toast.error('Item Não cadastrado!', resp.data.errors[0].detail, {
                positionClass: 'toast-bottom-right'
              });
              return;
            }

            ;
            this.toast.success('', 'Telefones vinculados com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.send('clearFilter');
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('', 'Erro ao vincular dados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },
      addLinkOrganization: function () {
        let organization = Ember.get(this, 'dataOrganization');
        let idOrganization = organization.id;
        let itens = this.variables.get('list_number_id');
        console.log(`itens:: `, itens);

        if (!itens || !itens.length) {
          this.toast.error('', 'Erro ao relacionar números, tente novamente', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        const payload = [];
        itens.forEach(x => {
          payload.push({
            "id": x.id,
            "organizationId": idOrganization
          });
        });

        if (idOrganization.length > 0 && payload.length > 0) {
          let url = this.baseapi.apiLink('WhatsAppNumbers');
          let call = this.baseapi.post(url + "/vincular-numero", {
            data: payload
          });
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');

            if (resp.data.errors) {
              this.toast.error('Item Não cadastrado!', resp.data.errors[0].detail, {
                positionClass: 'toast-bottom-right'
              });
              return;
            }

            ;
            this.toast.success('', 'Telefones vinculados com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.send('clearFilter');
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('', 'Erro ao vincular dados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },
      filterWhatsappNumber: function (field, item) {
        item = item.trim().toLowerCase();
        let filterItens = Ember.get(this, 'dataItensFilter');

        if (!item) {
          let indexField = filterItens.findIndex(x => x.filterKey == field);

          if (indexField != -1) {
            filterItens = filterItens.slice(indexField, 1);
          }
        }

        let fitem = filterItens.find(x => x.filterKey == field);

        if (!fitem) {
          filterItens.push({
            filterKey: field,
            filterValue: item
          });
        } else {
          fitem.filterValue = item;
        }

        this.send('actionFilterWhatsappNumber');
      },

      actionFilterWhatsappNumber() {
        let filterItens = Ember.get(this, 'dataItensFilter');
        var data = Ember.get(this, 'model.data');
        let result = [];

        if (data.length > 0) {
          for (let i = 0; i < filterItens.length; i++) {
            const item = filterItens[i];
            result = data.filter(x => x[item.filterKey] && x[item.filterKey].toString().trim().toLowerCase().indexOf(item.filterValue) != -1);
          }
        }

        Ember.set(this, 'dataVinculeNumberView', result);
      },

      setClientNumberId(id, sufix = 'cl') {
        let itens = this.variables.get('list_number_id');
        let allItens = this.variables.get('list_number_id_all');
        let drc = this.get('model.data');
        var userType = this.get('dataUser.type');
        var adminType = this.get('adminValue');
        var list = [];
        var valorTotal = 0;

        if (id == "all") {
          if (!allItens) {
            for (let i = 0; i < drc.length; i++) {
              const element = drc[i];
              if (sufix != 'cl' && userType != adminType && element.approve != 0) continue;
              if (sufix != 'cl' && userType == adminType && element.approve != 1) continue;
              if (!itens.find(x => x.id == element.id)) Ember.$(`#${sufix}${element.id}`).click();
              list.push(element);
            } // itens = list;


            this.variables.set('list_number_id_all', true);
          } else {
            for (let i = 0; i < drc.length; i++) {
              const element = drc[i];
              if (sufix != 'cl' && userType != adminType && element.approve != 0) continue;
              if (sufix != 'cl' && userType == adminType && element.approve != 1) continue;
              Ember.$(`#${sufix}${element.id}`).click();
            } // itens = list;


            this.variables.set('list_number_id_all', false);
          }
        } else {
          let idx = itens == null ? false : itens.find(x => x.id == id);

          if (idx) {
            for (let i = 0; i < itens.length; i++) {
              const element = itens[i];
              if (sufix != 'cl' && userType != adminType && element.approve != 0) continue;
              if (sufix != 'cl' && userType == adminType && element.approve != 1) continue;

              if (element.id != idx.id) {
                list.push(element);
              }
            } // itens = list;

          } else {
            if (idx === false) {
              itens = [];
            }

            var v = drc.find(x => x.id == id);
            list = itens.concat([v]);
          }

          for (let j = 0; j < list.length; j++) {
            const element = list[j];
            const req = drc.find(x => x.id == element.id);
            if (!req) continue;
          }
        }

        console.log('list', list);
        this.set('model.reproveMsg', null); // this.set('dataRequestsSelect', list);

        this.variables.set('list_number_id', list);
      },

      clearFilter() {
        this.set('rn', '');
        this.set('rl', '');
        this.set('rt', '');
        this.set('rgp', '');
        this.set('rrf', '');
        this.set('rq', '');
        this.set('rsm', '');
        this.set('rvt', '');
        this.set('dataItensFilter', []);
        this.variables.set('list_number_id', []);
        this.variables.set('list_number_id_all', false);
      }

    }
  });

  _exports.default = _default;
});