define("printpost/controllers/configurations/galeria", ["exports", "ember-concurrency", "printpost/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const newImage = Ember.Object.extend({
    s3FileName: null,
    url: null,
    title: null,
    userId: null
  });

  var _default = Ember.Controller.extend({
    newImage: newImage.create(),
    session: Ember.inject.service(),
    isCreateLoading: false,
    uploadFile: (0, _emberConcurrency.task)(function* (file) {
      const USER = this.get('session.data.authenticated');
      Ember.set(this, 'isCreateLoading', true);
      let post_data = {
        name: Ember.get(file, 'name'),
        size: Ember.get(file, 'size'),
        id: Ember.get(file, 'id')
      };
      let token = this.get('session.data.authenticated.id');
      let opts = {
        'accepts': 'application/json,application/vnd.api+json',
        'headers': {
          'Authorization': 'Bearer ' + token
        },
        'data': post_data
      };
      let urlbase = [_environment.default.APP.API.host, _environment.default.APP.API.namespace].join('/'); //Alterar container

      let response = yield file.upload(urlbase + '/s3FileUploads/printpost-dev/upload' + '?access_token=' + token, opts).then(response => {
        let uploaded = response.body.result.files.file.pop();
        Ember.set(this.newImage, 's3FileName', uploaded.name);
        Ember.set(this.newImage, 'title', uploaded.originalFilename);
        Ember.set(this.newImage, 'url', uploaded.providerResponse.location);
        Ember.set(this, 'isCreateLoading', false);
      });
      let imageFile = this.store.createRecord('user-image', {
        s3FileName: Ember.get(this.newImage, 's3FileName'),
        title: Ember.get(this.newImage, 'title'),
        url: Ember.get(this.newImage, 'url'),
        userId: Ember.get(USER, 'userId')
      });

      try {
        yield imageFile.save();
        this.toast.success('', 'Arquivo enviado com sucesso!', {
          positionClass: 'toast-bottom-right'
        });
        Ember.getOwner(this).lookup('route:configurations.galeria').refresh();
      } catch (err) {
        this.toast.error(err, 'Arquivo enviado com sucesso!', {
          positionClass: 'toast-bottom-right'
        });
      }

      return response;
    }).maxConcurrency(3).enqueue(),
    actions: {
      uploadImage(file) {
        Ember.get(this, 'uploadFile').perform(file);
      }

    }
  });

  _exports.default = _default;
});