define("printpost/utils/normalize-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeErrors;

  function normalizeErrors(response) {
    let errors = {
      message: 'Erro Desconhecido'
    };

    if (response.errors) {
      if (response.errors[0]) {
        // let non_fields = response.responseJSON.non_field_errors || ['Campo Inválido'];
        if (response.errors[0].code == "LOGIN_FAILED") {
          errors.message = "Usuário ou senha inválido.";
        }
      }
    }

    return errors;
  }
});