define("printpost/services/baseapi", ["exports", "axios", "printpost/config/environment"], function (_exports, _axios, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    toast: Ember.inject.service('toast'),

    reloadUser() {
      this.get('currentUser').load();
    },

    getCurrentUser() {
      let data = this.session.data.authenticated.account;
      return data;
    },

    getHeadersAuthorized() {
      let token = this.get('session.data.authenticated.access');
      return {
        'headers': {
          'Authorization': token
        },
        'accepts': 'application/json,application/vnd.api+json'
      };
    },

    getToken() {
      return this.get('session.data.authenticated.id');
    },

    apiLink(path) {
      return _environment.default.APP.API.namespace ? [_environment.default.APP.API.host, _environment.default.APP.API.namespace, path].join('/') : [_environment.default.APP.API.host, path].join('/');
    },

    getFieldSelectValue(nameSelec) {
      var radios = document.getElementsByName(nameSelec);

      for (var i = 0, length = radios.length; i < length; i++) {
        if (radios[i].checked) {
          // do whatever you want with the checked radio
          return radios[i].value; // only one radio can be logically checked, don't check the rest

          break;
        }
      }
    },

    getFieldSelectText(nameSelec, isNext = true) {
      var radios = document.getElementsByName(nameSelec);

      for (var i = 0, length = radios.length; i < length; i++) {
        if (radios[i].checked) {
          // do whatever you want with the checked radio
          var item = document.getElementById(radios[i].id.toString());
          var label = isNext ? item.nextElementSibling : item.previousElementSibling;
          let str = label.innerHTML;
          return str; // only one radio can be logically checked, don't check the rest

          break;
        }
      }
    },

    getHttp(url, withBear = true) {
      if (url != '') {
        if (withBear) {
          //object headT = { headers: { 'Authorization': 'Bearer ' + this.getToken() } };
          //console.log(this.get('session.data.authenticated.id'));
          return _axios.default.get(url, {
            headers: {
              'Authorization': this.getToken()
            }
          });
        } else {
          return _axios.default.get(url);
        }
      }
    },

    delete(url, withBear = true) {
      if (url != '') {
        if (withBear) {
          //object headT = { headers: { 'Authorization': 'Bearer ' + this.getToken() } };
          //console.log(this.get('session.data.authenticated.id'));
          return _axios.default.delete(url, {
            headers: {
              'Authorization': this.getToken()
            }
          });
        } else {
          return _axios.default.delete(url);
        }
      }
    },

    patch(url, data, withBear = true) {
      if (url) {
        if (withBear) {
          //object headT = { headers: { 'Authorization': 'Bearer ' + this.getToken() } };
          //console.log(this.get('session.data.authenticated.id'));
          return _axios.default.patch(url, data, {
            headers: {
              'Authorization': this.getToken()
            }
          });
        } else {
          return _axios.default.patch(url, data);
        }
      }
    },

    post(url, formdata, withBear = true) {
      if (url != '') {
        if (withBear) {
          // let head = this.getHeadersAuthorized();
          return _axios.default.post(url, formdata, {
            headers: {
              'Authorization': this.getToken()
            }
          });
        } else {
          return _axios.default.post(url, formdata);
        }
      }
    },

    postFormData(url, formdata, withBear = true) {
      let token = this.session.data.authenticated.id;

      if (url != '') {
        if (withBear) {
          let head = {
            'headers': {
              'Authorization': token,
              'Content-Type': 'multipart/form-data'
            },
            'accepts': 'application/json,application/vnd.api+json'
          };
          return _axios.default.post(url, formdata, head);
        } else {
          let head = {
            'headers': {
              'Content-Type': 'multipart/form-data'
            }
          };
          return _axios.default.post(url, formdata, head);
        }
      }
    },

    getFileExtension(filename) {
      var ext = /^.+\.([^.]+)$/.exec(filename);
      return ext == null ? "" : ext[1];
    },

    getOverview() {
      const url = `/assets/overview.json`;
      return _axios.default.get(url).then(response => response.data).catch(error => {
        this.debug(error);
        return {};
      });
    },

    getAddresses() {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/address` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getCompany() {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/Company` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getFilters() {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/Filter` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getFilteredTemplates(channelId, filterId) {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/templates` + `/?filter={"where":{"and":[{"filterId": "${filterId}"},{"channelId": "${channelId}"}]}}` + `&access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getSmsTemplates(filterId) {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/templates` + `/?filter={"where":{"and":[{"filterId": "${filterId}"},{"channelId": "5c3f71c786fb985370c9d66c"}]}}` + `&access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getLetterTemplates(filterId) {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/templates` + `/?filter={"where":{"and":[{"filterId": "${filterId}"},{"channelId": "5c3f713ce1c9855370e3e02e"}]}}` + `&access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getEmailTemplates(filterId) {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/templates` + `/?filter={"where":{"and":[{"filterId": "${filterId}"},{"channelId": "5c3f718986fb985370c9d66b"}]}}` + `&access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getTemplates() {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/templates` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getTemplateById(id) {
      const BASE_URL = this.apiLink('Templates/');
      const USER = this.get('session.data.authenticated');
      const urlTemplate = `${BASE_URL}` + id + `?access_token=` + `${USER.id}`;
      const urlType = `${BASE_URL}` + id + `/Channel` + `?access_token=` + `${USER.id}`;
      const urlBase = `${BASE_URL}` + id + `/baseTemplate` + `?access_token=` + `${USER.id}`;

      const template = _axios.default.get(urlTemplate).then(response => response.data);

      const type = _axios.default.get(urlType).then(response => response.data);

      const base = _axios.default.get(urlBase).then(response => response.data);

      return Ember.RSVP.hash({
        template,
        type,
        base
      });
    },

    getWalletById(id) {
      const BASE_URL = this.apiLink('Wallets/');
      const USER = this.get('session.data.authenticated');
      const urlWallet = `${BASE_URL}` + id + `?access_token=` + `${USER.id}`;

      const wallet = _axios.default.get(urlWallet).then(response => response.data);

      return Ember.RSVP.hash({
        wallet
      });
    },

    getPrintingOptions(id) {
      const BASE_URL = this.apiLink('printingOptions/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + id + `?access_token=` + `${USER.id}`;

      const request = _axios.default.get(url).then(response => response.data);

      return request;
    },

    getWalletList() {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `/wallets` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    updateTemplate(json_data) {
      const toUpdate = json_data.template.data.id;
      const type = json_data.template.data.type;
      const attributes = json_data.template.data.attributes;
      const data = JSON.stringify({
        "data": {
          "type": type,
          "id": toUpdate,
          "attributes": attributes
        }
      });
      const parsed_data = JSON.parse(data); // const data = json_data.template.data.attributes

      const BASE_URL = this.apiLink('Templates/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + toUpdate + `?access_token=` + `${USER.id}`;
      return _axios.default.patch(url, parsed_data);
    },

    getPeopleHistories(params) {
      const BASE_URL = this.apiLink('Wallets/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${params}` + `/peopleHistories` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getUrlFile(fileName) {
      const encoded = encodeURIComponent(fileName);
      const BASE_URL = this.apiLink('Wallets/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `download-file/` + `${encoded}` + `?access_token=` + `${USER.id}`;
      const downloadWindow = window.open(url, "", "width=200,height=100");

      downloadWindow.onfocus = function () {
        setTimeout(function () {
          downloadWindow.close();
        }, 900);
      };

      return downloadWindow;
    },

    updateUserDetails(json_data) {
      // const data = JSON.stringify({'data': {'attributes': json_data}});
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `${USER.userId}` + `?access_token=` + `${USER.id}`;
      const data = JSON.stringify({
        "data": {
          "type": "Accounts",
          "id": USER.userId,
          "attributes": json_data
        }
      });
      let options = this.getHeadersAuthorized();
      options['headers']['Content-Type'] = "application/json";
      return _axios.default.patch(url, data, options);
    },

    updatePassword(json_data) {
      const BASE_URL = this.apiLink('Accounts/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `change-password` + `?access_token=` + `${USER.id}`;
      let options = this.getHeadersAuthorized();
      options['headers']['Content-Type'] = "application/json";
      return _axios.default.post(url, {
        oldPassword: json_data.password,
        newPassword: json_data.newPassword
      });
    },

    downloadFile(fileName) {
      const encoded = encodeURIComponent(fileName); //Trocar nome do bucket

      const BASE_URL = this.apiLink('s3FileUploads/printpost-dev/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `download/` + `${encoded}` + `?access_token=` + `${USER.id}`;
      const downloadWindow = window.open(url, "", "width=1200,height=628"); // downloadWindow.onfocus = function () { setTimeout(function () { downloadWindow.close(); }, 1200); }

      return downloadWindow;
    },

    fileDetail(fileName) {
      const encoded = encodeURIComponent(fileName); //Trocar nome do bucket

      const BASE_URL = this.apiLink('s3FileUploads/printpost-dev/');
      const USER = this.get('session.data.authenticated');
      const url = `${BASE_URL}` + `files/` + `${encoded}` + `?access_token=` + `${USER.id}`;
      return _axios.default.get(url).then(response => response.data);
    },

    getCheckoutCode(request) {
      let data = {
        'token': '3A0BE7E329AE4708A5D6DC7F5A8D4E1F',
        'email': 'otavio@65a.com.br',
        'currency': 'BRL',
        'itemId1': request.paymentCode,
        'itemQuantity1': '1',
        'itemDescription1': request.channel,
        'itemAmount1': request.totalCost,
        'itemWeight1': '1000'
      };
      var str = JSON.stringify(data);
      const USER = this.get('session.data.authenticated');
      const BASE_URL = this.apiLink('Requests/');
      const url = `${BASE_URL}` + `getCheckoutCode` + `&data=` + `${str}`;

      var response = _axios.default.get(url, {
        headers: {
          'Authorization': this.getToken()
        }
      }).then(response => response.data);

      return response;
    },

    habledElem(element, isHabled, withClass = false) {
      if (!isHabled) {
        Ember.$(element).attr('disabled', 'disabled');
      } else {
        Ember.$(element).removeAttr('disabled');
      }
    },

    visibilityElem(element, isVisible, isFlex = true) {
      if (!isVisible) {
        Ember.$(element).css('display', 'none');
      } else {
        if (isFlex) {
          Ember.$(element).css('display', 'flex');
        } else {
          Ember.$(element).css('display', 'inherit');
        }
      }
    },

    formatData(value) {
      let data = value;
      let ano = data.substr(0, 4);
      let mes = data.substr(5, 2);
      let dia = data.substr(8, 2);
      let hora = data.substr(11, 2);
      let min = data.substr(14, 2);
      let data_format = dia + "/" + mes + "/" + ano + " - " + hora + ":" + min;
      return data_format;
    },

    padStr(val, size, character = '0') {
      var s = String(val);

      while (s.length < (size || 2)) {
        s = character + s;
      }

      return s;
    },

    getDownload(url, filename, withBear = true) {
      if (url != '') {
        if (withBear) {
          var token = this.getToken();
          url = url.indexOf('?') > -1 ? `${url}&access_token=${token}` : `${url}?access_token=${token}`; //object headT = { headers: { 'Authorization': 'Bearer ' + this.getToken() } };
          //console.log(this.get('session.data.authenticated.id'));
          // axios.get(url, { headers: { 'Authorization': 'Bearer ' + this.getToken() } })
          // .then(success => {
          //   // var blob = new Blob([data], { type: 'application/pdf' });
          //   // var linkElement = document.createElement('a');
          //   // var url = window.URL.createObjectURL(blob);
          //   // linkElement.setAttribute('href', url);
          //   // linkElement.setAttribute("download", filename);
          //   // var clickEvent = new MouseEvent("click", {
          //   //     "view": window,
          //   //     "bubbles": true,
          //   //     "cancelable": false
          //   // });
          //   // linkElement.dispatchEvent(clickEvent);
          // },
          // error => {
          // });
        } else {// return axios.get(url)
            // .then(success => {
            //   // let data = success.data;
            //   // var blob = new Blob([data], { type: 'application/pdf' });
            //   // var linkElement = document.createElement('a');
            //   // var url = window.URL.createObjectURL(blob);
            //   // linkElement.setAttribute('href', url);
            //   // linkElement.setAttribute("download", filename);
            //   // var clickEvent = new MouseEvent("click", {
            //   //     "view": window,
            //   //     "bubbles": true,
            //   //     "cancelable": false
            //   // });
            //   // linkElement.dispatchEvent(clickEvent);
            // },
            // error => {
            // });
          }

        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },

    savePendentRequest(req_id = null) {
      let requestId = localStorage.getItem('newServiceRequestId');
      requestId = requestId == undefined ? 0 : requestId;
      requestId = req_id == null ? requestId : req_id;

      let __sNewRequest = JSON.parse(localStorage.getItem('storage:request'));

      __sNewRequest.status = "Pendente";
      delete __sNewRequest.praticatePrices.sms.smsExceedCharacters;
      delete __sNewRequest.id;
      let __request = {
        data: {
          type: "Request",
          attributes: __sNewRequest
        }
      }; // turn the file into a buffer for uploading

      return new Promise((resolve, reject) => {
        if (__sNewRequest.title != null && __sNewRequest.shippingMethod && (__sNewRequest.letterService || __sNewRequest.emailService || __sNewRequest.smsService || __sNewRequest.whatsappService || __sNewRequest.registryOfficeService)) {
          if (requestId == 0) {
            let url = this.apiLink('Requests');
            let call = this.post(url, __request);
            call.then(resp => {
              // this.transitionToRoute('dashboard');
              // requestElem.value = resp.data.data.id;
              this.updateRequestLocalStorate(resp);
              localStorage.setItem('newServiceRequestId', resp.data.data.id);
              Ember.$("#btn-new-req-exit").click();
              document.getElementById('exibition_show').value = 1;
              resolve(resp.data.data.id);
            }, error => {
              console.log(error);
              reject(error);
            });
          } else {
            let url = this.apiLink('Requests/' + requestId);
            let __rp = __request;
            __rp.data.id = requestId;
            let call = this.patch(url, __rp);
            call.then(resp => {
              //not to do
              this.updateRequestLocalStorate(resp);
              Ember.$("#btn-new-req-exit").click();
              document.getElementById('exibition_show').value = 1;
              resolve(requestId);
            }, error => {
              console.log(error);
              reject(error);
            });
          }
        }
      });
    },

    validatePendentRequest(req_id = null) {
      let requestId = localStorage.getItem('newServiceRequestId');
      requestId = requestId == undefined ? 0 : requestId;
      requestId = req_id == null ? requestId : req_id;

      let __sNewRequest = JSON.parse(localStorage.getItem('storage:request'));

      delete __sNewRequest.praticatePrices.sms.smsExceedCharacters;
      __sNewRequest.status = "Pendente";
      let __request = {
        data: {
          type: "Request",
          attributes: __sNewRequest
        }
      }; // turn the file into a buffer for uploading

      return new Promise((resolve, reject) => {
        if (__sNewRequest.title != null && __sNewRequest.shippingMethod && (__sNewRequest.letterService || __sNewRequest.emailService || __sNewRequest.smsService || __sNewRequest.whatsappService || __sNewRequest.registryOfficeService)) {
          let url = this.apiLink('Requests/validar-pedido');
          let __rp = __request;
          __rp.data.id = requestId;
          let call = this.patch(url, __rp);
          Ember.$('#subpreloader').toggleClass('d-none');
          call.then(resp => {
            //not to do
            Ember.$('#subpreloader').toggleClass('d-none'); // this.updateRequestLocalStorate(resp);

            var requestData = resp.data;

            if (requestData.smsService) {
              let obj = {
                quantityAll: requestData.praticatePrices.sms.quantityExceded,
                data: requestData.praticatePrices.sms.smsExceedCharacters
              };
              localStorage.setItem('excededSms', JSON.stringify(obj));
            }

            var reqOptions = JSON.parse(localStorage.getItem('storage:request-options'));
            if (reqOptions && reqOptions.praticatePrices) requestData.praticatePrices;
            if (!reqOptions) reqOptions = {
              praticatePrices: requestData.praticatePrices
            };
            localStorage.setItem('storage:request-options', JSON.stringify(reqOptions));
            let walletId = requestData.walletId;
            let organizationId = requestData.organizationId ? requestData.organizationId : null;
            let requestTagId = requestData.requestTagId;
            requestData.walletId = walletId;
            requestData.organizationId = organizationId;
            requestData.requestTagId = requestTagId;
            localStorage.setItem('storage:request', JSON.stringify(requestData));
            resolve(requestData);
          }, error => {
            Ember.$('#subpreloader').toggleClass('d-none');
            console.log(error);
            reject(error);
          });
        }
      });
    },

    updateRequestLocalStorate(resp) {
      let walletId = resp.data.data.relationships.wallet.data && resp.data.data.relationships.wallet.data.id ? resp.data.data.relationships.wallet.data.id : 'static';
      let organizationId = resp.data.data.relationships.organization.data && resp.data.data.relationships.organization.data.id ? resp.data.data.relationships.organization.data.id : null;
      let requestTagId = resp.data.data.relationships.tag.data && resp.data.data.relationships.tag.data.id ? resp.data.data.relationships.tag.data.id : null;
      let userId = resp.data.data.relationships.account.data && resp.data.data.relationships.account.data.id ? resp.data.data.relationships.account.data.id : null;
      resp.data.data.attributes.walletId = walletId;
      resp.data.data.attributes.organizationId = organizationId;
      resp.data.data.attributes.requestTagId = requestTagId;
      resp.data.data.attributes.userId = userId;
      localStorage.setItem('storage:request', JSON.stringify(resp.data.data.attributes));
    },

    realStringConvertDecimal(itemValue) {
      let v = 0;

      if (typeof itemValue == 'string' && itemValue.indexOf(',') != -1) {
        let v1 = itemValue.split(',');
        let v2 = v1[0].replace('.', '') + '.' + v1[1];
        v = parseFloat(v2); //.toFixed(2);
      }

      return v;
    },

    numberToReal(numero, digits = 2) {
      numero = numero.toFixed(digits).split('.');
      numero[0] = "" + numero[0].split(/(?=(?:...)*$)/).join('.');
      return numero.join(',');
    },

    getAdmValue() {
      return "administrador";
    },

    getMonthStr(val) {
      let strReturn = "";

      switch (val) {
        case 0:
          strReturn = "Janeiro";
          break;

        case 1:
          strReturn = "Fevereiro";
          break;

        case 2:
          strReturn = "Março";
          break;

        case 3:
          strReturn = "Abril";
          break;

        case 4:
          strReturn = "Maio";
          break;

        case 5:
          strReturn = "Junho";
          break;

        case 6:
          strReturn = "Julho";
          break;

        case 7:
          strReturn = "Agosto";
          break;

        case 8:
          strReturn = "Setembro";
          break;

        case 9:
          strReturn = "Outubro";
          break;

        case 10:
          strReturn = "Novembro";
          break;

        case 11:
          strReturn = "Dezembro";
          break;

        default:
          break;
      }

      return strReturn;
    },

    populeObjSelect: function (data, fieldId = 'id', fieldName = 'name') {
      let objReturn = [];
      let item01 = {
        "id": "0",
        "selectText": "Selecione",
        "selectId": "0",
        "selectValue": "0"
      };
      objReturn.push(item01);
      data.forEach(element => {
        var rand = Math.random() * 9999;
        let item = {
          "id": element[fieldId],
          "selectText": element[fieldName],
          "selectId": rand,
          "selectValue": element[fieldId]
        };
        item.selectText = element[fieldName];
        objReturn.push(item);
      });
      return objReturn;
    },
    returnDate: function (qtdDays = 0, complete = false) {
      var date = new Date();
      date.setDate(date.getDate() + qtdDays);
      const dateTimeFormat = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      var [{
        value: month
      },, {
        value: day
      },, {
        value: year
      },, {
        value: hour
      },, {
        value: minute
      },, {
        value: second
      }] = dateTimeFormat.formatToParts(date);
      let value = day + '/' + month + "/" + year;

      if (complete) {
        value += ` - ${hour}:${minute}`;
      }

      return value;
    },

    downloadPostBuffer(url, data, formdata = false) {
      var header = {
        'Authorization': this.getToken()
      };
      if (formdata) header['Content-Type'] = 'multipart/form-data';
      Ember.$('#subpreloader').toggleClass('d-none');
      (0, _axios.default)({
        url: url,
        data: data,
        headers: header,
        method: 'POST',
        responseType: 'blob'
      }).then(response => {
        Ember.$('#subpreloader').toggleClass('d-none');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let headers = response.headers;
        let filename = 'file';

        if (headers['content-disposition']) {
          var t = headers['content-disposition'].split('filename=');
          filename = t[1];
        }

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }, error => {
        Ember.$('#subpreloader').toggleClass('d-none');
        console.log(error);
      });
    },

    getDateTimeNow(vnow = null, format = 'ddMMyyy', complete = false) {
      function AddZero(num) {
        return num >= 0 && num < 10 ? "0" + num : num + "";
      }

      var now = vnow ? vnow : new Date();
      var strDateTime = [[AddZero(now.getDate()), AddZero(now.getMonth() + 1), now.getFullYear()].join("/"), [AddZero(now.getHours()), AddZero(now.getMinutes())].join(":")].join(" ");
      if (format == 'yyyyMMdd') strDateTime = [[now.getFullYear(), AddZero(now.getMonth() + 1), AddZero(now.getDate())].join("/"), [AddZero(now.getHours()), AddZero(now.getMinutes())].join(":")].join(" ");
      if (complete) strDateTime += `:${AddZero(now.getSeconds())}`;
      return strDateTime;
    },

    saveModel(model, link, attributes, showMessage = true) {
      let objSave = {
        data: {
          type: model,
          attributes: attributes
        }
      };
      var message = "salvo";
      var url = null,
          call = null;

      if (!attributes.id) {
        url = this.apiLink(link);
        delete objSave.data.attributes.id;
        call = this.post(url, objSave);
      } else {
        url = this.apiLink(`${link}/${attributes.id}`);
        objSave.data.id = objSave.data.attributes.id;
        delete objSave.data.attributes.id;
        call = this.patch(url, objSave);
      } // console.log(url,objSave);


      if (call) {
        call.then(() => {
          if (showMessage) this.toast.success(`Item ${message}!`, `Dado ${message} com sucesso`, {
            positionClass: 'toast-bottom-right'
          });
          return;
        }, err => {
          // console.log(err);
          if (showMessage) this.toast.error(`Item não foi ${message}!`, `Dado não foi ${message}!`, {
            positionClass: 'toast-bottom-right'
          });
          return;
        });
      }

      return;
    },

    deleteModel(link, id, showMessage = true) {
      var message = "apagado";
      var url = null,
          call = null;
      url = this.apiLink(link);
      call = this.delete(`${url}/${id}`);

      if (call) {
        call.then(() => {
          if (showMessage) this.toast.success(`Item ${message}!`, `Dado ${message} com sucesso`, {
            positionClass: 'toast-bottom-right'
          });
          return;
        }, err => {
          // console.log(err);
          if (showMessage) this.toast.error(`Item não foi ${message}!`, `Dado não foi ${message}!`, {
            positionClass: 'toast-bottom-right'
          });
          return;
        });
      }

      return;
    },

    getWeekendDays(repeat = 1) {
      var dnow = new Date();
      var year = dnow.getFullYear();
      var month = dnow.getMonth();
      var weekend = [];

      for (let j = 0; j <= repeat; j++) {
        for (var i = 0; i <= new Date(year, month + (j - 1), 0).getDate(); i++) {
          var date = new Date(year, month + (j - 1), i);
          if (date.getDay() == 6 || date.getDay() == 0) weekend.push(this.getDateTimeNow(date, 'yyyyMMdd'));
        }

        ;
      }

      return weekend;
    },

    queryParam(name, url) {
      if (!url) url = location.href;
      name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(url);
      return results == null ? null : results[1];
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    toastDontClear() {
      return {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": 'toast-bottom-right',
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": 0,
        "extendedTimeOut": 0,
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
        "tapToDismiss": false
      };
    },

    downloadRawFile(file) {
      let url = this.apiLink('rawFiles');
      url += `/download-id/${file.id}/1`;
      Ember.$('#subpreloader').toggleClass('d-none');
      this.getDownload(url, file.name);
      Ember.$('#subpreloader').toggleClass('d-none');
    }

  });

  _exports.default = _default;
});