define("printpost/routes/usuarios/edit", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      // let id_param = getUrlParameters("id", "", true);
      var url = this.baseapi.apiLink('Accounts/list');
      var url_perfis = this.baseapi.apiLink('Roles/list');
      var list = [];
      let tipo_account = 'juridica';
      var defaultRole = null; //address vars

      var invoiceAddress = {
        id: '',
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        complemento: '',
        numero: '',
        cidadecep: '',
        type: 'ADDRESS_REGISTER'
      };
      var obj_address = [];
      let url_address = this.baseapi.apiLink('Addresses/list');

      if (queryParams && queryParams.id) {
        let query = {
          filter: {
            where: {
              id: queryParams.id
            },
            include: ['invoiceConfig', 'technicalContact']
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        url += "?" + strquery; //address

        url_address += "?filter[where][type]=ADDRESS_REGISTER_INVOICE&filter[where][userId]=" + queryParams.id;
        obj_address = await this.baseapi.getHttp(url_address).then(resp => resp.data);
        let addressInvoice = obj_address.find(x => x.type.toUpperCase() == 'ADDRESS_REGISTER_INVOICE');
        invoiceAddress = addressInvoice ? addressInvoice : invoiceAddress;
      }

      let item = {
        "type": "",
        "cpf": "",
        "cnpj": "",
        "payment": "faturado",
        "username": "",
        "password": "",
        "email": "",
        "emailVerified": true,
        "phone1": "",
        "phone2": "",
        "razaoSocial": "",
        "inscricaoEstadual": ""
      };
      let technicalContact = {
        "name": "",
        "phone1": "",
        "phone2": "",
        "email": "",
        "userId": "",
        "id": ""
      };
      let date = new Date();
      let monthStr = this.baseapi.padStr(date.getMonth() + 1, 2);
      let date1 = date.getFullYear() + "-" + monthStr + "-01T00:00";
      let date2 = date.getFullYear() + "-" + monthStr + "-30T00:00";
      let invoiceConfig = {
        "period": [date1, date2],
        "dueDate": "",
        "creditLimit": "0,00",
        "userId": "",
        "id": ""
      }; //perfis

      const obj_perfis = await this.baseapi.getHttp(url_perfis).then(r => r.data);

      if (queryParams && queryParams.id) {
        //find user with id
        const obj = await this.baseapi.getHttp(url).then(r => r.data);
        item = obj.find(x => x.id == queryParams.id);
        tipo_account = item.cnpj ? 'juridica' : 'fisica';
        defaultRole = obj_perfis.find(x => x.id == item.roleId);
      }

      if (!item.technicalContact) {
        item.technicalContact = technicalContact;
      }

      if (!item.invoiceConfig) {
        item.invoiceConfig = invoiceConfig;
      } //passwd


      var pass = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      };
      return Ember.RSVP.hash({
        data: obj_perfis,
        item: item,
        address: obj_address,
        invoiceAddress: invoiceAddress,
        pass: pass,
        type: tipo_account,
        defaultRole: defaultRole
      });
    }
  });

  _exports.default = _default;
});