define("printpost/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    load() {
      let userId = this.get('session.data.authenticated.userId');
      /* if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('user', {me: true}).then((user) => {
          this.set('user', user);
        });
        } else {
        return RSVP.resolve();
      } */

      if (!Ember.isEmpty(userId)) {
        console.log(`Checking user ${userId} in service`);
        return this.get('store').findRecord('account', userId).then(user => {
          let firstName = user.username.split(" ");
          user.firstName = firstName[0];
          this.set('user', user);
        }, error => {
          this.get('session').invalidate();
          this.transitionToRoute('/sign/in');
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }

  });

  _exports.default = _default;
});