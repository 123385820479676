define("printpost/controllers/operacao/processamento", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    taskRegularizeDuplicateDataset: (0, _emberConcurrency.task)(function* (requestId, channel) {
      let url = this.baseapi.apiLink(`RequestDatasets/regularize-mail-datasets?type=${channel}&filter[where][id]=${requestId}`);
      this.toast.success('', 'Solicitação realizada com sucesso', {
        positionClass: 'toast-bottom-right'
      });
      yield this.baseapi.getHttp(`${url}`).then(x => x.data, error => this.toast.error('', 'Erro ao realizar solicitação', {
        positionClass: 'toast-bottom-right'
      }));
    }).keepLatest(),
    taskRegularizeReturn: (0, _emberConcurrency.task)(function* (requestId, channel) {
      let url = this.baseapi.apiLink(`RequestDatasets/regularize-mail?type=${channel}&filter[where][id]=${requestId}`);
      this.toast.success('', 'Solicitação realizada com sucesso', {
        positionClass: 'toast-bottom-right'
      });
      yield this.baseapi.getHttp(`${url}`).then(x => x.data, error => this.toast.error('', 'Erro ao realizar solicitação', {
        positionClass: 'toast-bottom-right'
      }));
    }).keepLatest(),
    taskPopulatePanelMail: (0, _emberConcurrency.task)(function* (requestId) {
      let url = this.baseapi.apiLink(`RequestDatasets/popule-panel?filter[where][id]=${requestId}`);
      this.toast.success('', 'Solicitação realizada com sucesso', {
        positionClass: 'toast-bottom-right'
      });
      yield this.baseapi.getHttp(`${url}`).then(x => x.data, error => this.toast.error('', 'Erro ao realizar solicitação', {
        positionClass: 'toast-bottom-right'
      }));
    }).keepLatest(),
    taskPopulatePanelSMS: (0, _emberConcurrency.task)(function* (requestId) {
      let url = this.baseapi.apiLink(`RequestDatasets/popule-panel-sms?filter[where][id]=${requestId}`);
      this.toast.success('', 'Solicitação realizada com sucesso', {
        positionClass: 'toast-bottom-right'
      });
      yield this.baseapi.getHttp(`${url}`).then(x => x.data, error => this.toast.error('', 'Erro ao realizar solicitação', {
        positionClass: 'toast-bottom-right'
      }));
    }).keepLatest(),
    taskPopulateDashboard: (0, _emberConcurrency.task)(function* (requestId) {
      let url = this.baseapi.apiLink(`RequestDatasets/populate?filter[where][id]=${requestId}`);
      this.toast.success('', 'Solicitação realizada com sucesso', {
        positionClass: 'toast-bottom-right'
      });
      yield this.baseapi.getHttp(`${url}`).then(x => x.data, error => this.toast.error('', 'Erro ao realizar solicitação', {
        positionClass: 'toast-bottom-right'
      }));
    }).keepLatest(),
    taskRequestSchedule: (0, _emberConcurrency.task)(function* () {
      var requestId = this.get('model.requestId');
      var channel = this.get('model.channel');
      if (!requestId) return;
      let url = this.baseapi.apiLink(`OperationTasks/schedule-item?id=${requestId}&channel=${channel}`);
      yield this.baseapi.getHttp(`${url}`).then(x => {
        this.set('model.requestId', null);
        this.set('model.channel', null);
        this.toast.success('', 'Solicitação realizada com sucesso', {
          positionClass: 'toast-bottom-right'
        });
      }, error => this.toast.error('', 'Erro ao realizar solicitação', {
        positionClass: 'toast-bottom-right'
      }));
    }).keepLatest(),
    actions: {
      internalKeyPress(key) {
        console.log('enter her');
        console.log('key', key, key.key, key.keyCode);
      },

      doTask(id, requestId, channel) {
        console.log(`task do ${id} requestId(${requestId}) channel(${channel})`);

        switch (parseInt(id)) {
          case 1:
            this.get('taskRegularizeReturn').perform(requestId, channel);
            break;

          case 2:
            this.get('taskPopulatePanelSMS').perform(requestId);
            break;

          case 3:
            this.get('taskPopulatePanelMail').perform(requestId);
            break;

          case 4:
            this.get('taskRegularizeDuplicateDataset').perform(requestId, channel);
            break;

          case 5:
            this.get('taskPopulateDashboard').perform(requestId);
            break;

          case 6:
            this.get('taskRequestSchedule').perform();
            break;

          default:
            break;
        }
      },

      setRequestSchedule(id, channel) {
        this.set('model.requestId', id);
        this.set('model.channel', channel);
      },

      paginationRequest: async function (filterSkip) {
        var query = {
          filter: {
            where: {
              status: {
                in: ['Finalizado', 'Em Execução', 'Execução']
              },
              or: [{
                emailService: true
              }, {
                smsService: true
              }]
            },
            order: 'id DESC',
            skip: filterSkip.skip,
            limit: filterSkip.limit
          }
        };
        this.preloader.toggleClass('d-none');

        try {
          let url = this.baseapi.apiLink('OperationTasks/monit');
          var strquery = (0, _globalFunctions.apiLinkQuery)(query);
          const requestList = await this.baseapi.getHttp(`${url}?${strquery}`).then(x => x.data);
          Ember.set(this, 'model.pages', requestList.totalPages);
          Ember.set(this, 'model.request', requestList.data);
        } catch (error) {
          console.log(error);
          this.toast.error('', 'Erro na consulta dos dados', {
            positionClass: 'toast-bottom-right'
          });
        }

        this.preloader.toggleClass('d-none');
      }
    }
  });

  _exports.default = _default;
});