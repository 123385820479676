define("printpost/helpers/number-abbr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numberAbbr = numberAbbr;
  _exports.default = void 0;

  function log10(x) {
    if (Math.log10) {
      return Math.log10(x);
    } else {
      return Math.log(x) / Math.LN10;
    }
  }

  function getDecimalPlaces(adjustedNumber, maxSignificantPlaces) {
    const baseLength = Math.ceil(log10(adjustedNumber));
    const decimalPlaces = maxSignificantPlaces - (baseLength > 0 ? baseLength : 0);
    return decimalPlaces > 0 ? decimalPlaces : 0;
  }

  function numberAbbr([number = 0, delimiter = '.', maxSignificantPlaces = 3, padding = false, abbreviations = null]
  /*, options*/
  ) {
    // jshint ignore:line
    number = parseInt(number);
    let symbols = abbreviations || Ember.A(['', 'K', 'M', 'B', 'T', 'Qua', 'Qui', 'Sex', 'Sep', 'Oct', 'Non', 'Dec']);
    let results = symbols.map((symbol, index) => {
      const adjustedNumber = number / Math.pow(10, index * 3);
      const decimalPlaces = getDecimalPlaces(adjustedNumber, maxSignificantPlaces);
      const roundedNumber = adjustedNumber.toFixed(decimalPlaces);
      let strippedNumber = padding ? roundedNumber : roundedNumber.replace(/(\.0+$)/, '');
      strippedNumber = strippedNumber.replace(/\./, delimiter);
      return strippedNumber + symbol;
    });
    const result = Ember.A(results).find((result, index) => {
      return number.toString().length <= (index + 1) * 3;
    });
    return result;
  }

  var _default = Ember.Helper.helper(numberAbbr);

  _exports.default = _default;
});