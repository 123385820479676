define("printpost/helpers/format-number", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatNumber = formatNumber;
  _exports.default = void 0;

  function formatNumber(params, hash) {
    // let {format} = hash || {};
    // let {format} = hash || {};
    let number = params;

    if (Ember.isArray(params)) {
      number = params[0];
    }

    if (typeof number === 'undefined') {
      number = null;
    }

    if (isNaN(number)) {
      number = null;
    }

    number = number.toFixed(2).split('.');
    number = number[0].split(/(?=(?:...)*$)/).join('.');
    return number; // return numeral(number).format(format);
  }

  var _default = Ember.Helper.helper(formatNumber);

  _exports.default = _default;
});