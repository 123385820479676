define("printpost/controllers/blacklist", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service('session'),
    preloader: Ember.computed('preloader', function () {
      return $('#subpreloader');
    }).property().volatile(),
    taskBlacklistSave: (0, _emberConcurrency.task)(function* () {
      var urlQuery = this.baseapi.apiLink('Blacklists/save'); // let request = this.get('model.request');

      let contatos = this.get('model.blacklistData.cadastrar');
      let type = this.get('model.type');
      var userId = this.session.data.authenticated.account.id;
      var organizationId = this.session.data.authenticated.account.organizationId;
      var query = {
        filter: {
          where: {
            userId: userId,
            organizationId: organizationId
          }
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      urlQuery += "?" + strquery;

      if (!contatos || contatos.length < 3) {
        this.toast.error('', 'É necessário informar os contatos.', {
          positionClass: 'toast-bottom-right'
        });
        return;
      }

      this.preloader.toggleClass('d-none');

      try {
        const dataResp = yield this.baseapi.post(urlQuery, {
          type: type,
          address: contatos
        }).then(rf => rf.data);
        this.toast.success('', 'Backlist cadastrado com sucesso.', {
          positionClass: 'toast-bottom-right'
        });
      } catch (error) {
        this.toast.error('', 'Backlist erro ao cadastradar dados.', {
          positionClass: 'toast-bottom-right'
        });
      }

      this.preloader.toggleClass('d-none');
    }).keepLatest(),
    taskBlacklistDelete: (0, _emberConcurrency.task)(function* () {
      var urlQuery = this.baseapi.apiLink('Blacklists/delete'); // let request = this.get('model.request');

      let contatos = this.get('model.blacklistData.deletar');
      let type = this.get('model.type');
      var userId = this.session.data.authenticated.account.id;
      var organizationId = this.session.data.authenticated.account.organizationId;
      var query = {
        filter: {
          where: {
            userId: userId,
            organizationId: organizationId
          }
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      urlQuery += "?" + strquery;

      if (!contatos || contatos.length < 3) {
        this.toast.error('', 'É necessário informar os contatos.', {
          positionClass: 'toast-bottom-right'
        });
        return;
      }

      this.preloader.toggleClass('d-none');

      try {
        const dataResp = yield this.baseapi.post(urlQuery, {
          type: type,
          address: contatos
        }).then(rf => rf.data);
        this.toast.success('', 'Casos removidos do blacklist com sucesso.', {
          positionClass: 'toast-bottom-right'
        });
      } catch (error) {
        this.toast.error('', 'Erro para remover casos do blacklist.', {
          positionClass: 'toast-bottom-right'
        });
      }

      this.preloader.toggleClass('d-none');
    }).keepLatest(),
    taskBlacklistFind: (0, _emberConcurrency.task)(function* () {
      var urlQuery = this.baseapi.apiLink('Blacklists/find'); // let request = this.get('model.request');

      let contatos = this.get('model.blacklistData.consultar');
      let type = this.get('model.type');
      var userId = this.session.data.authenticated.account.id;
      var organizationId = this.session.data.authenticated.account.organizationId;
      var query = {
        filter: {
          where: {
            userId: userId,
            organizationId: organizationId
          }
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      urlQuery += "?" + strquery;

      if (!contatos || contatos.length < 3) {
        this.toast.error('', 'É necessário informar os contatos.', {
          positionClass: 'toast-bottom-right'
        });
        return;
      }

      this.preloader.toggleClass('d-none');

      try {
        const dataResp = yield this.baseapi.post(urlQuery, {
          type: type,
          address: contatos
        }).then(rf => rf.data);
        this.set('model.blacklistData.findResult', dataResp);
      } catch (error) {
        this.toast.error('', 'Backlist erro ao cadastradar dados.', {
          positionClass: 'toast-bottom-right'
        });
      }

      this.preloader.toggleClass('d-none');
    }).keepLatest(),
    actions: {
      setBlacklistAction(act) {
        this.set('model.blacklistData.action', act);
      },

      doActionBlacklist() {
        let act = this.get('model.blacklistData.action');

        switch (act) {
          case 'cadastrar':
            this.get('taskBlacklistSave').perform();
            break;

          case 'deletar':
            this.get('taskBlacklistDelete').perform();
            break;

          case 'consultar':
            this.get('taskBlacklistFind').perform();
            break;

          default:
            break;
        }
      },

      getBlacklistFind() {
        this.get('taskBlacklistFind').perform();
      }

    }
  });

  _exports.default = _default;
});