define("printpost/controllers/prices/pricing", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataPriceSend: Ember.computed('model.PriceSend', function () {
      var model = this.get('model.data');
      return model.PriceSend;
    }),
    dataPriceTax: Ember.computed('model.PriceTax', function () {
      var model = this.get('model.data');
      return model.PriceTax;
    }),
    dataPriceColor: Ember.computed('model.PriceColor', function () {
      var model = this.get('model.data');
      return model.PriceColor;
    }),
    dataPriceFinish: Ember.computed('model.PriceFinish', function () {
      var model = this.get('model.data');
      return model.PriceFinish;
    }),
    dataPriceFormat: Ember.computed('model.PriceFormat', function () {
      var model = this.get('model.data');
      return model.PriceFormat;
    }),
    dataPricePackage: Ember.computed('model.PricePackage', function () {
      var model = this.get('model.data');
      return model.PricePackage;
    }),
    dataPriceSalesman: Ember.computed('model.PriceSalesman', function () {
      var model = this.get('model.data');
      return model.PriceSalesman;
    }),
    dataPriceVolume: Ember.computed('model.PriceVolume', function () {
      var model = this.get('model.data');
      return model.PriceVolume;
    }),
    dataPriceVolumeList: Ember.computed('model.PriceVolume', function () {
      var model = this.get('model.data').PriceVolume;
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PriceVolume;
    }),
    dataPrintFormatOptions: Ember.computed('model.PrintFormatOptions', function () {
      var model = this.get('model.data');
      return model.PrintFormatOptions;
    }),
    dataSalesman: Ember.computed('model.dataSalesman', function () {
      var model = this.get('model.dataSalesman');
      return model;
    }),
    dataFormatOptions: Ember.computed('model.dataFormatOptions', function () {
      var model = this.get('model.dataFormatOptions');
      return model;
    }),
    dataProviders: Ember.computed('model.dataProviders', function () {
      var model = this.get('model.dataProviders');
      return model;
    }),
    dataListProviders: Ember.computed('model.dataListProviders', function () {
      var model = this.get('model.dataProviders');
      return model;
    }),
    dataChannels01: Ember.computed('model.dataChannels', function () {
      var model = this.get('model.dataChannels');
      return model;
    }),
    dataSelectVolumeGroup: Ember.computed('model.dataChannels', function () {
      var model = this.get('model.data').PriceVolume;
      let objReturn = this.generateProductSelect(model);
      return objReturn;
    }),
    dataStrFormatOptions: Ember.computed('dataStrFormatOptions', function () {
      return {
        formate: '',
        grammage: '',
        papper: ''
      };
    }),
    rerenderItens: Ember.computed('rerenderItens', function () {
      return {
        formatOptions: true,
        newVolumeItenGrupo: true
      };
    }),
    modelChange: Ember.computed('modalChange', function () {
      return '';
    }),
    modelPluralName: Ember.computed('modelPluralName', function () {
      return '';
    }),
    dataItemSend: Ember.computed('dataItemSend', function () {
      return {
        id: '',
        name: '',
        value: "0,000",
        margin: "0,000",
        totalCost: "0,000",
        providerId: '0',
        channelId: '0'
      };
    }),
    dataItem: Ember.computed('dataItem', function () {
      return {
        id: '',
        name: '',
        value: "0,000",
        margin: "0,000",
        totalCost: "0,000"
      };
    }),
    dataItemPercent: Ember.computed('dataItemPercent', function () {
      return {
        id: '',
        name: '',
        value: "",
        percentage: '00,000'
      };
    }),
    dataItemVolume: Ember.computed('dataItemVolume', function () {
      return {
        id: '',
        name: '',
        value: "",
        percentage: '00,000',
        min: 0,
        max: 0,
        priceVolumeGroupId: '0'
      };
    }),
    dataItemSalesman: Ember.computed('dataItemSalesman', function () {
      return {
        id: '',
        name: '',
        value: "",
        percentage: '00,000',
        salesmanId: '',
        channelId: '0'
      };
    }),
    dataItemFormat: Ember.computed('dataItemFormat', function () {
      return {
        id: '',
        name: '',
        value: "0,000",
        percentage: '00,000',
        margin: '00,000',
        totalCost: "0,000",
        format: '',
        grammage: '',
        papper: ''
      };
    }),
    dataItemProvider: Ember.computed('dataItemProvider', function () {
      return {
        id: '',
        name: '',
        cnpj: "",
        phone: '',
        debitNote: false,
        email: ''
      };
    }),
    dataItemColor: Ember.computed('dataItemColor', function () {
      return {
        id: '',
        name: '',
        value: "0,000",
        margin: "0,000",
        totalCost: "0,000",
        hasVolume: false,
        priceVolumeGroupId: '',
        colored: true
      };
    }),
    dataItemVolumeGroup: Ember.computed('dataItemVolumeGroup', function () {
      return {
        id: '',
        name: '',
        channelId: ''
      };
    }),
    dataTypeDelete: Ember.computed('dataTypeDelete', function () {
      return 0;
    }),
    reloadData: function () {
      this.preloader.toggleClass('d-none');
      var urlprecification = this.baseapi.apiLink('PricingTables/precification');
      this.baseapi.getHttp(urlprecification).then(r => {
        Ember.set(this, 'dataPriceSend', r.data.PriceSend);
        Ember.set(this, 'dataPriceTax', r.data.PriceTax);
        Ember.set(this, 'dataPriceColor', r.data.PriceColor);
        Ember.set(this, 'dataPriceFinish', r.data.PriceFinish);
        Ember.set(this, 'dataPriceFormat', r.data.PriceFormat);
        Ember.set(this, 'dataPricePackage', r.data.PricePackage);
        Ember.set(this, 'dataPriceSalesman', r.data.PriceSalesman);
        Ember.set(this, 'dataPriceVolume', r.data.PriceVolume);
        Ember.set(this, 'dataPrintFormatOptions', r.data.PrintFormatOptions);
        Ember.set(this, 'model.selectSalesman', null);
        Ember.set(this, 'model.selectChannel', null);
        Ember.set(this, 'model.selectSendProvider', null);
        let newDataSelectVolumeGroup = this.generateProductSelect(r.data.PriceVolume);
        Ember.set(this, 'dataSelectVolumeGroup', newDataSelectVolumeGroup);
        let clearDataItem = {
          id: '',
          name: '',
          value: '0,000',
          margin: "0,000",
          totalCost: "0,000"
        };
        let clearDataItemPercent = {
          id: '',
          name: '',
          value: '',
          percentage: '00,000'
        };
        let clearDataItemSend = {
          id: '',
          name: '',
          value: '0,000',
          providerId: '0',
          channelId: '0',
          margin: "0,000",
          totalCost: "0,000"
        };
        let clearDataItemVolume = {
          id: '',
          name: '',
          value: '',
          percentage: '00,000',
          min: 0,
          max: 0,
          priceVolumeGroupId: Ember.get(this, 'dataItemVolume').priceVolumeGroupId
        };
        let clearDataItemSalesman = {
          id: '',
          name: '',
          value: '',
          percentage: '00,000',
          salesmanId: '',
          channelId: '0'
        };
        let clearDataItemFormat = {
          id: '',
          name: '',
          value: '0,000',
          percentage: '00,000',
          format: '',
          grammage: '',
          papper: ''
        };
        let clearDataItemProvider = {
          id: '',
          name: '',
          cnpj: '',
          email: '',
          phone: ''
        };
        let clearDataItemColor = {
          id: '',
          name: '',
          value: '0,000',
          margin: "0,000",
          totalCost: "0,000",
          colored: true
        };
        let clearDataItemVolumeGroup = {
          id: '',
          name: '',
          channelId: '0'
        };
        Ember.set(this, 'dataItem', clearDataItem);
        Ember.set(this, 'dataItemPercent', clearDataItemPercent);
        Ember.set(this, 'dataItemSend', clearDataItemSend);
        Ember.set(this, 'dataItemVolume', clearDataItemVolume);
        Ember.set(this, 'dataItemSalesman', clearDataItemSalesman);
        Ember.set(this, 'dataItemFormat', clearDataItemFormat);
        Ember.set(this, 'dataItemProvider', clearDataItemProvider);
        Ember.set(this, 'dataItemColor', clearDataItemColor);
        Ember.set(this, 'dataItemVolumeGroup', clearDataItemVolumeGroup);
        this.preloader.toggleClass('d-none');
      }); //update providers

      var url_provider = this.baseapi.apiLink('Providers/list');
      this.preloader.toggleClass('d-none');
      this.baseapi.getHttp(url_provider).then(r => {
        this.preloader.toggleClass('d-none');
        Ember.set(this, 'dataProviders', r.data);
      });
    },
    loadCurrentDataItem: function (tipo) {
      let item_data = null;
      let item_data_nopercent = Ember.get(this, 'dataItem');
      item_data_nopercent.totalCost = item_data_nopercent.value;
      let item_data_percent = Ember.get(this, 'dataItemPercent');
      let item_data_volume = Ember.get(this, 'dataItemVolume');
      let item_data_salesman = Ember.get(this, 'dataItemSalesman');
      let item_data_formatoptions = Ember.get(this, 'dataFormatOptions');
      let item_data_provider = Ember.get(this, 'dataItemProvider');
      let item_data_volume_group = Ember.get(this, 'dataItemVolumeGroup');
      let item_data_format = Ember.get(this, 'dataItemFormat');
      item_data_format.totalCost = item_data_format.value;
      let item_data_send = Ember.get(this, 'dataItemSend');
      item_data_send.totalCost = item_data_send.value;
      let item_data_color = Ember.get(this, 'dataItemColor');
      item_data_color.totalCost = item_data_color.value;

      switch (tipo) {
        case 2:
          item_data = item_data_percent;
          break;

        case 3:
          item_data = item_data_volume;
          break;

        case 4:
          item_data = item_data_salesman;
          break;

        case 5:
          item_data = item_data_formatoptions;
          break;

        case 6:
          item_data = item_data_format;
          break;

        case 7:
          item_data = item_data_provider;
          break;

        case 8:
          item_data = item_data_send;
          break;

        case 9:
          item_data = item_data_color;
          break;

        case 10:
          item_data = item_data_volume_group;
          break;

        default:
          item_data = item_data_nopercent;
          break;
      }

      if (item_data.margin && item_data.margin.length > 0) {
        let mv = this.baseapi.realStringConvertDecimal(item_data.margin);
        let pt = this.baseapi.realStringConvertDecimal(item_data.value);
        let price_unity = pt - pt * (mv / 100);
        item_data.totalCost = this.baseapi.numberToReal(price_unity, 3);
      }

      return item_data;
    },
    generateProductSelect: function (model) {
      let objReturn = [];
      var rand = Math.random() * 9999;
      let ini = {
        "selectText": "Todos",
        "selectId": rand,
        "selectValue": "0"
      };
      objReturn.push(ini);
      model.forEach(element => {
        rand = Math.random() * 9999;
        let item = {
          "id": element.id,
          "selectText": element.name.toUpperCase(),
          "selectId": rand,
          "selectValue": element.id
        };
        objReturn.push(item);
      });
      return objReturn;
    },
    populeObjSelect: function (data) {
      let objReturn = [];
      let item01 = {
        "id": "0",
        "selectText": "Selecione",
        "selectId": "0",
        "selectValue": "0"
      };
      objReturn.push(item01);
      data.forEach(element => {
        var rand = Math.random() * 9999;
        let item = {
          "id": element.id,
          "selectText": element.name,
          "selectId": rand,
          "selectValue": element.id
        };

        if (!element.icon && (element.value || element.percentage)) {
          let str = element.name; //let v = this.baseapi.realStringConvertDecimal(element.value);

          let strc = element.value != null && element.value.length > 0 ? "R$ " + element.value : element.percentage;
          item.selectText = str + ' - ' + strc;
        }

        objReturn.push(item);
      });
      return objReturn;
    },
    saveProviderLayoutTask: (0, _emberConcurrency.task)(function* () {
      var itemSms = this.get('model.selectProviderLayoutSMS');
      var itemMail = this.get('model.selectProviderLayoutMail');
      var itemWhatsapp = this.get('model.selectProviderLayoutWhatsapp');
      var itemValidation = this.get('model.selectProviderLayoutValidation');
      var provider = this.get('dataItemProvider');
      var layoutSMS = provider.providerLayout.find(x => x.channel == 'sms');
      var layoutMail = provider.providerLayout.find(x => x.channel == 'email');
      var layoutWhatsapp = provider.providerLayout.find(x => x.channel == 'whatsapp');
      var layoutValidation = provider.providerLayout.find(x => x.type == 'validation');

      try {
        if (!provider || !provider.id) return this.toast.error(``, `É necessário informar o fornecedor`, {
          positionClass: 'toast-bottom-right'
        });
        this.preloader.toggleClass('d-none');

        if (itemSms) {
          var attributesSms = {
            name: itemSms.name,
            channel: itemSms.channelName,
            description: `${itemSms.name} layout`,
            layoutId: itemSms.id,
            providerId: provider.id
          };
          if (layoutSMS) attributesSms.id = layoutSMS.id;
          yield this.baseapi.saveModel('ProviderLayout', 'ProviderLayouts', attributesSms);
        }

        if (itemMail) {
          var attributesMail = {
            name: itemMail.name,
            channel: itemMail.channelName,
            description: `${itemMail.name} layout`,
            layoutId: itemMail.id,
            providerId: provider.id
          };
          if (layoutMail) attributesMail.id = layoutMail.id;
          yield this.baseapi.saveModel('ProviderLayout', 'ProviderLayouts', attributesMail);
        }

        if (itemWhatsapp) {
          var attributesWhatsapp = {
            name: itemWhatsapp.name,
            channel: itemWhatsapp.channelName,
            description: `${itemWhatsapp.name} layout`,
            layoutId: itemWhatsapp.id,
            providerId: provider.id
          };
          if (layoutWhatsapp) attributesWhatsapp.id = layoutWhatsapp.id;
          yield this.baseapi.saveModel('ProviderLayout', 'ProviderLayouts', attributesWhatsapp);
        }

        if (itemValidation) {
          var attributesValidation = {
            name: itemValidation.name,
            channel: itemValidation.channelName,
            description: `${itemValidation.name} layout`,
            layoutId: itemValidation.id,
            providerId: provider.id
          };
          if (layoutValidation) attributesValidation.id = layoutValidation.id;
          yield this.baseapi.saveModel('ProviderLayout', 'ProviderLayouts', attributesValidation);
        }

        this.reloadData();
      } catch (error) {
        this.toast.error(`Erro!`, `Dados não foram salvos!`, {
          positionClass: 'toast-bottom-right'
        });
      } finally {
        this.preloader.toggleClass('d-none');
      }
    }),
    deleteProviderLayoutTask: (0, _emberConcurrency.task)(function* (channel) {
      var provider = this.get('dataItemProvider');
      var layoutSMS = provider.providerLayout.find(x => x.channel == 'sms');
      var layoutMail = provider.providerLayout.find(x => x.channel == 'email');
      var layoutWhatsapp = provider.providerLayout.find(x => x.channel == 'whatsapp');
      var id = channel == 'sms' ? layoutSMS.id : channel == 'whatsapp' ? layoutWhatsapp.id : layoutMail.id;
      this.send('setProviderLayout', channel, null);

      try {
        if (!id) return this.toast.error(``, `Não foi possível encontrar o layout`, {
          positionClass: 'toast-bottom-right'
        });
        this.preloader.toggleClass('d-none');
        yield this.baseapi.deleteModel('ProviderLayouts', id);
        this.reloadData();
      } catch (error) {
        this.toast.error(`Erro!`, `Dados não foram apagados`, {
          positionClass: 'toast-bottom-right'
        });
      } finally {
        this.preloader.toggleClass('d-none');
      }
    }),
    actions: {
      changeDataItem: function (item, m, mpn, str) {
        var channels = this.get('model.dataChannels');

        if (m == 'PriceSend') {
          var providers = this.get('model.dataProviders');
          var provider = providers.find(x => x.id == item.providerId);
          Ember.set(this, 'model.selectSendProvider', provider);
        } else if (m == 'PriceSalesman') {
          var salesmans = this.get('dataSalesman');
          var salesman = salesmans.find(x => x.id == item.salesmanId);
          Ember.set(this, 'model.selectSalesman', salesman);
        } else if (m == 'Provider') {
          var layoutSMS = item.providerLayout.find(x => x.channel == 'sms' && x.layout && x.layout.type == 'integration');
          var layoutMail = item.providerLayout.find(x => x.channel == 'email' && x.layout && x.layout.type == 'integration');
          var layoutWhatsapp = item.providerLayout.find(x => x.channel == 'whatsapp' && x.layout && x.layout.type == 'integration');
          var layoutValidation = item.providerLayout.find(x => x.layout.type == 'validation');

          if (layoutSMS) {
            this.set('model.selectProviderLayoutSMS', layoutSMS.layout);
          } else {
            this.set('model.selectProviderLayoutSMS', null);
          }

          if (layoutMail) {
            this.set('model.selectProviderLayoutMail', layoutMail.layout);
          } else {
            this.set('model.selectProviderLayoutMail', null);
          }

          if (layoutWhatsapp) {
            this.set('model.selectProviderLayoutWhatsapp', layoutWhatsapp.layout);
          } else {
            this.set('model.selectProviderLayoutWhatsapp', null);
          }

          if (layoutValidation) {
            this.set('model.selectProviderLayoutValidation', layoutValidation.layout);
          } else {
            this.set('model.selectProviderLayoutValidation', null);
          }
        }

        if (item.channelId) {
          var channel = channels.find(x => x.id == item.channelId);
          Ember.set(this, 'model.selectChannel', channel);
        }

        Ember.set(this, str, item);
        Ember.set(this, 'modelChange', m);
        Ember.set(this, 'modelPluralName', mpn);
      },
      setVolumeGroup: function (idgroup) {
        Ember.set(this, 'dataItemVolume.priceVolumeGroupId', idgroup);
        this.send('setModelChange', 'PriceVolume', 'PriceVolumes');
        Ember.set(this, 'rerenderItens.newVolumeItenGrupo', false);
        setTimeout(() => {
          Ember.set(this, 'rerenderItens.newVolumeItenGrupo', true);
        }, 500);
      },
      setModelChange: function (m, mpn) {
        Ember.set(this, 'modelChange', m);
        Ember.set(this, 'modelPluralName', mpn);
        Ember.set(this, 'model.selectSalesman', null);
        Ember.set(this, 'model.selectChannel', null);
        Ember.set(this, 'model.selectSendProvider', null);
        let clearDataItem = {
          id: '',
          name: '',
          value: '0,000',
          margin: "0,000",
          totalCost: "0,000"
        };
        let clearDataItemPercent = {
          id: '',
          name: '',
          value: '',
          percentage: '00,000'
        };
        let clearDataItemSend = {
          id: '',
          name: '',
          value: '0,000',
          providerId: '0',
          channelId: '0',
          margin: "0,000",
          totalCost: "0,000"
        };
        let clearDataItemVolume = {
          id: '',
          name: '',
          value: '',
          percentage: '00,000',
          min: 0,
          max: 0,
          priceVolumeGroupId: Ember.get(this, 'dataItemVolume').priceVolumeGroupId
        };
        let clearDataItemSalesman = {
          id: '',
          name: '',
          value: '',
          percentage: '00,000',
          salesmanId: '',
          channelId: '0'
        };
        let clearDataItemFormat = {
          id: '',
          name: '',
          value: '0,000',
          percentage: '00,000',
          format: '',
          grammage: '',
          papper: ''
        };
        let clearDataItemProvider = {
          id: '',
          name: '',
          cnpj: '',
          email: '',
          phone: ''
        };
        let clearDataItemColor = {
          id: '',
          name: '',
          value: '0,000',
          margin: "0,000",
          totalCost: "0,000",
          colored: true
        };
        let clearDataItemVolumeGroup = {
          id: '',
          name: '',
          channelId: '0'
        };
        Ember.set(this, 'dataItem', clearDataItem);
        Ember.set(this, 'dataItemPercent', clearDataItemPercent);
        Ember.set(this, 'dataItemVolume', clearDataItemVolume);
        Ember.set(this, 'dataItemVolumeGroup', clearDataItemVolumeGroup);
        Ember.set(this, 'dataItemSalesman', clearDataItemSalesman);
        Ember.set(this, 'dataItemFormat', clearDataItemFormat);
        Ember.set(this, 'dataItemProvider', clearDataItemProvider);
        Ember.set(this, 'dataItemSend', clearDataItemSend);
        Ember.set(this, 'dataItemColor', clearDataItemColor);
      },
      setTypeDelete: function (val) {
        Ember.set(this, 'dataTypeDelete', val);
      },
      salvar: function (tipo) {
        let modelName = Ember.get(this, 'modelChange');
        let modelPluralName = Ember.get(this, 'modelPluralName');
        modelPluralName = modelPluralName == '' ? modelName + 's' : modelPluralName;
        let item = this.loadCurrentDataItem(tipo);
        let _object = {
          data: {
            type: modelName,
            attributes: item
          }
        };

        if (item.name.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink(modelPluralName);
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.reloadData();
            }, error => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
            });
          } else {
            let url = this.baseapi.apiLink(modelPluralName + '/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              item.id = __rp.data.id;
              this.reloadData();
            }, error => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
            });
          }
        }
      },
      apagar: function () {
        let modelName = Ember.get(this, 'modelChange');
        let modelPluralName = Ember.get(this, 'modelPluralName');
        modelPluralName = modelPluralName == '' ? modelName + 's' : modelPluralName;
        let tipo = Ember.get(this, 'dataTypeDelete');
        let item_data = this.loadCurrentDataItem(tipo);

        if (item_data.id) {
          let url = this.baseapi.apiLink(modelPluralName);
          let call = this.baseapi.delete(url + '/' + item_data.id);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
          this.reloadData();
        }
      },
      updatePercentage: function (itemValue, itemFormat) {
        Ember.set(this, 'dataItemPercent.value', null);
        Ember.set(this, 'dataItemPercent.percentage', itemFormat);
      },
      updatePercentageVolume: function (itemValue, itemFormat) {
        Ember.set(this, 'dataItemVolume.value', null);
        Ember.set(this, 'dataItemVolume.percentage', itemFormat);
      },
      updatePercentageSalesman: function (itemValue, itemFormat) {
        Ember.set(this, 'dataItemSalesman.value', null);
        Ember.set(this, 'dataItemSalesman.percentage', itemFormat);
      },
      updateCNPJProvider: function (itemValue, itemFormat) {
        Ember.set(this, 'dataItemProvider.cnpj', itemFormat);
      },
      updatePhoneProvider: function (itemValue, itemFormat) {
        Ember.set(this, 'dataItemProvider.phone', itemFormat);
      },
      setSalesmanidSelect: function (salesman) {
        Ember.set(this, 'dataItemSalesman.salesmanId', salesman.id);
        Ember.set(this, 'dataItemSalesman.name', salesman.username);
        Ember.set(this, 'model.selectSalesman', salesman);
      },
      addFormatOptions: function (type, str) {
        let formatOptions = Ember.get(this, 'dataFormatOptions');

        if (str.indexOf(";") > 0) {
          str = str.replace(";", "");
          let obj = {
            name: str,
            value: (0, _globalFunctions.removeSpecialChar)(str).toLowerCase()
          };

          if (type == "format") {
            formatOptions.formate.push(obj);
            Ember.set(this, 'dataStrFormatOptions.formate', '');
          } else if (type == "grammage") {
            formatOptions.grammage.push(obj);
            Ember.set(this, 'dataStrFormatOptions.grammage', '');
          } else if (type == "papper") {
            formatOptions.papper.push(obj);
            Ember.set(this, 'dataStrFormatOptions.papper', '');
          }

          Ember.set(this, 'dataFormatOptions', formatOptions);
          Ember.set(this, 'rerenderItens.formatOptions', false);
          setTimeout(() => {
            Ember.set(this, 'rerenderItens.formatOptions', true);
            Ember.set(this, 'modelChange', 'PrintFormatOptions');
            Ember.set(this, 'modelPluralName', 'PrintFormatOptions');
            this.send('salvar', 5);
          }, 2);
        }
      },
      removeFormatOptions: function (item, type) {
        let formatOptions = Ember.get(this, 'dataFormatOptions');

        if (type == "format") {
          let newData = (0, _globalFunctions.removeItemArray)(item, formatOptions.formate);
          Ember.set(formatOptions, 'formate', newData);
        } else if (type == "grammage") {
          let newData = (0, _globalFunctions.removeItemArray)(item, formatOptions.grammage);
          Ember.set(formatOptions, 'grammage', newData);
        } else if (type == "papper") {
          let newData = (0, _globalFunctions.removeItemArray)(item, formatOptions.papper);
          Ember.set(formatOptions, 'papper', newData);
        }

        Ember.set(this, 'dataFormatOptions', formatOptions);
        Ember.set(this, 'rerenderItens.formatOptions', false);
        setTimeout(() => {
          Ember.set(this, 'rerenderItens.formatOptions', true);
          Ember.set(this, 'modelChange', 'PrintFormatOptions');
          Ember.set(this, 'modelPluralName', 'PrintFormatOptions');
          this.send('salvar', 5);
        }, 2);
      },
      saveSalesman: function () {
        Ember.set(this, 'modelChange', 'PriceSalesman');
        Ember.set(this, 'modelPluralName', 'PriceSalesmans');
        this.send('salvar', 4);
      },
      saveVolume: function () {
        var dataItem = Ember.get(this, 'dataItemVolume');
        var listData = Ember.get(this, 'dataPriceVolume');
        let itemVol = listData.find(x => x.id == dataItem.priceVolumeGroupId);
        let validItem = true;

        if (itemVol != null && itemVol.volumeItens.length > 0) {
          itemVol.volumeItens.forEach(item => {
            //if( ( (item.min >= dataItem.min && dataItem.min <= item.max) || (item.min >= dataItem.max && dataItem.max <= item.max) )) {
            if (dataItem.min >= item.min && dataItem.min <= item.max || dataItem.max >= item.min && dataItem.max <= item.max) {
              if (dataItem.id) {
                validItem = dataItem.id == item.id ? true : false;
              } else {
                validItem = false;
              }
            }
          });
        }

        if (validItem) {
          let name = dataItem.min + " a " + dataItem.max;
          Ember.set(this, 'dataItemVolume.name', name);
          Ember.set(this, 'modelChange', 'PriceVolume');
          Ember.set(this, 'modelPluralName', 'PriceVolumes');
          this.send('salvar', 3);
        } else {
          this.toast.error('Item inválido!', 'Já existe preço cadastrado com esse intervalo de volumetria!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      saveFormat: function () {
        var dataItem = Ember.get(this, 'dataItemFormat');
        let format = this.baseapi.getFieldSelectValue('format');
        let grammage = this.baseapi.getFieldSelectValue('grammage');
        let papper = this.baseapi.getFieldSelectValue('papper');
        let strformat = this.baseapi.getFieldSelectText('format');
        let strgrammage = this.baseapi.getFieldSelectText('grammage');
        let strpapper = this.baseapi.getFieldSelectText('papper');
        Ember.set(dataItem, 'format', format);
        Ember.set(dataItem, 'grammage', grammage);
        Ember.set(dataItem, 'papper', papper);
        var listPriceFormat = Ember.get(this, 'dataPriceFormat');
        let validItem = true;

        if (listPriceFormat != null && listPriceFormat.length > 0) {
          listPriceFormat.forEach(item => {
            if (item.format == format && item.papper == papper && item.grammage == grammage) {
              if (dataItem.id) {
                validItem = dataItem.id == item.id ? true : false;
              } else {
                validItem = false;
              }
            }
          });
        }

        if (!strformat || !strgrammage || !strpapper) {
          this.toast.error('Item inválido!', 'É necessário selecionar formato, gramatura e papel', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let nameItem = strformat.trim() + ' /' + strgrammage.trim() + ' /' + strpapper.trim();
        Ember.set(dataItem, 'name', nameItem);
        Ember.set(this, 'modelChange', 'PriceFormat');
        Ember.set(this, 'modelPluralName', 'PriceFormats');
        this.send('salvar', 6);
      },
      setProductidSelect: function (name, item) {
        let id = item.id;
        let strItem = `${name}.channelId`;
        Ember.set(this, 'model.selectChannel', item);
        Ember.set(this, strItem, id);
      },
      setVolumeGroupidSelect: function (item, name) {
        let id = this.baseapi.getFieldSelectValue(name);
        let strItem = item + '.priceVolumeGroupId';

        if (id != "0") {
          Ember.set(this, strItem, id);
        } else {
          Ember.set(this, strItem, "0");
        }
      },
      setProvideridSelect: function (data) {
        Ember.set(this, 'model.selectSendProvider', data);
        Ember.set(this, 'dataItemSend.providerId', data.id);
      },
      saveProvider: function () {
        var dataItem = Ember.get(this, 'dataItemProvider');
        var listData = Ember.get(this, 'dataProviders');
        let validItem = true;

        if (listData != null && listData.length > 0) {
          listData.forEach(item => {
            if (item.cnpj == dataItem.cnpj) {
              if (dataItem.id) {
                validItem = dataItem.id == item.id ? true : false;
              } else {
                validItem = false;
              }
            }
          });
        }

        if (validItem) {
          Ember.set(this, 'modelChange', 'Provider');
          Ember.set(this, 'modelPluralName', 'Providers');
          this.send('salvar', 7);
        } else {
          this.toast.error('Item inválido!', 'Já existe fornecedor cadastrado para esse CNPJ!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      setColored: function (item) {
        Ember.set(this, 'dataItemColor.colored', item);
      },
      setVolume: function (item) {
        Ember.set(this, 'dataItemColor.hasVolume', item);
      },
      setDebitNote: function (item) {
        Ember.set(this, 'dataItemProvider.debitNote', item);
      },
      setVolumeGroupidSelect: function () {
        let id = this.baseapi.getFieldSelectValue("pvolume_selection");

        if (id != "0" && Ember.get(this, 'dataItemColor.hasVolume')) {
          Ember.set(this, 'dataItemColor.priceVolumeGroupId', id);
        }
      },

      saveProviderLayout() {
        this.get('saveProviderLayoutTask').perform();
      },

      setProviderLayout(channel, val) {
        if (channel == 'sms') this.set('model.selectProviderLayoutSMS', val);else if (channel == 'email') this.set('model.selectProviderLayoutMail', val);else if (channel == 'whatsapp') this.set('model.selectProviderLayoutWhatsapp', val);
      },

      setProviderValidation(val) {
        this.set('model.selectProviderLayoutValidation', val);
      },

      deleteProviderLayout(channel) {
        this.get('deleteProviderLayoutTask').perform(channel);
      }

    }
  });

  _exports.default = _default;
});