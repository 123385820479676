define("printpost/controllers/carteiras/details", ["exports", "ember-concurrency", "printpost/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    isValidating: false,

    init() {
      this._super(...arguments); //SEARCH


      this.searchProperties = this.searchProperties || ['attributes.nome', 'attributes.bairro', 'attributes.cpf', 'attributes.cidade', 'attributes.cep', 'attributes.estado']; //TABELA

      this.columns = this.columns || [{
        name: `Nome`,
        valuePath: 'attributes.nome'
      }, {
        name: `Cpf`,
        valuePath: 'attributes.cpf'
      }, {
        name: `Telefone`,
        valuePath: 'attributes.phone'
      }, {
        name: `Endereço`,
        valuePath: 'attributes.endereco'
      }, {
        name: `Número`,
        valuePath: 'attributes.numero'
      }, {
        name: `Bairro`,
        valuePath: 'attributes.bairro'
      }, {
        name: `Cep`,
        valuePath: 'attributes.cep'
      }, {
        name: `Cidade`,
        valuePath: 'attributes.cidade'
      }, {
        name: `Estado`,
        valuePath: 'attributes.estado'
      }];
    },

    uploadImage: (0, _emberConcurrency.task)(function* (file) {
      Ember.set(this, 'isValidating', true);
      let post_data = {
        name: Ember.get(file, 'name'),
        id: Ember.get(this.modelEditor.wallet.data, 'id'),
        channel: Ember.get(file, 'type')
      };

      try {
        let token = this.get('session.data.authenticated.id');
        let opts = {
          'data': post_data
        };
        let urlbase = [_environment.default.APP.API.host, _environment.default.APP.API.namespace].join('/');
        let response = yield file.upload(urlbase + '/Wallets/upload-file' + '?access_token=' + token, opts);
        this.toast.success('', 'Carteira atualizada com sucesso!', {
          positionClass: 'toast-bottom-right'
        });
        Ember.set(this, 'isValidating', false);
        return response;
      } catch (e) {
        for (let err in e.body.errors) {
          Ember.set(this, 'isValidating', false);
          Ember.getOwner(this).lookup('route:carteiras.details').refresh();
          this.toast.error('Revise as colunas e dados de sua carteira!', e.body.errors[err].detail, {
            positionClass: 'toast-bottom-right'
          });
        }
      }
    }).maxConcurrency(3).enqueue(),
    modelEditor: Ember.computed('model.walletInstance.value', function () {
      return this.get('model.walletInstance.value');
    }),
    wallet: Ember.computed('modelEditor.wallet.data.', function () {
      return this.get('modelEditor.wallet.data.attributes');
    }),
    people: Ember.computed('model.peopleHistories.value', function () {
      return this.get('model.peopleHistories.value.data');
    }),
    tableColumns: Ember.computed('modelEditor.wallet.data', function () {
      let getColumns = this.get('modelEditor.wallet.data.attributes.columns');
      let columns = [];

      for (var i in getColumns) {
        columns.push({
          "name": getColumns[i],
          "valuePath": "attributes." + getColumns[i]
        });
      }

      columns.pop();
      return columns;
    }),
    download: (0, _emberConcurrency.task)(function* (fileName) {
      try {
        let request = yield this.baseapi.getUrlFile(fileName);
        return request;
      } catch (e) {
        this.toast.error('Erro!', 'Erro ao baixar a carteira.', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    actions: {
      downloadFile: function (fileName) {
        Ember.get(this, 'download').perform(fileName);
      },
      uploadImage: function (file) {
        Ember.get(this, 'uploadImage').perform(file).then(() => {
          Ember.getOwner(this).lookup('route:carteiras').refresh();
          Ember.getOwner(this).lookup('route:carteiras.details').refresh();
        });
      },
      teste: function (val1, val2, val3) {
        console.log(val1);
        console.log(val2);
        console.log(val3);
      }
    }
  });

  _exports.default = _default;
});