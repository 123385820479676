define("printpost/app", ["exports", "printpost/resolver", "ember-load-initializers", "printpost/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  Ember.Route.reopen({
    hasLayout: true,

    // true by default since most views need layout
    setupController() {
      this._super(...arguments);

      this.controllerFor('application').set('showLayout', this.get('hasLayout'));
    }

  });
  var _default = App;
  _exports.default = _default;
});