define("printpost/controllers/transmissao/list", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service('session'),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataItem: Ember.computed('model', function () {
      return this.get('model.item');
    }),
    adminValue: Ember.computed('adminValue', function () {
      return this.baseapi.getAdmValue();
    }),
    actions: {
      goToLink: function (item) {
        this.transitionToRoute(item);
      },
      ativarItem: function (id, val) {
        let itemId = id == undefined ? 0 : id;
        let list = Ember.get(this, 'model.data');
        let item = list.find(x => x.id == id);

        if (item) {
          Ember.set(item, 'active', !val);
          let __price = {
            data: {
              type: "SendMonitoring",
              id: itemId,
              attributes: item
            }
          };
          delete __price.data.attributes.id;
          let url = this.baseapi.apiLink('SendMonitorings/' + itemId);
          let call = this.baseapi.patch(url, __price);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none'); //not to do

            Ember.set(item, 'id', itemId);
            this.toast.success('Atualizado!', 'Preço atualizado com sucesso!', {
              positionClass: 'toast-bottom-right'
            });
          }, error => {
            this.preloader.toggleClass('d-none');
            console.log(error);
          });
        }
      }
    }
  });

  _exports.default = _default;
});