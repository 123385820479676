define("printpost/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  import {validator, buildValidations} from 'ember-cp-validations';
  
  const Validations = buildValidations({
    username: validator('presence', true),
    first_name: validator('presence', true),
    last_name: validator('presence', true),
    email: [
      validator('presence', true),
      validator('format', {type: 'email'})
    ],
  });
  
  export default DS.Model.extend(Validations, {
    first_name: DS.attr('string'),
    last_name: DS.attr('string'),
    email: DS.attr('string'),
    username: DS.attr('string'),
    groups: DS.attr()
  });
  */
  var _default = _emberData.default.Model.extend({
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    groups: _emberData.default.attr()
  });

  _exports.default = _default;
});