define("printpost/controllers/services/remetente", ["exports", "printpost/controllers/services", "ember-concurrency", "printpost/config/environment"], function (_exports, _services, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const newAddress = Ember.Object.extend({
    endereco: null,
    bairro: null,
    numero: null,
    complemento: null,
    uf: null,
    cep: null,
    cidade: null
  });

  var _default = _services.default.extend({
    file: null,
    newAddress: newAddress.create(),
    isCreateLoading: false,
    cidades: Ember.computed.mapBy('model.city', 'cidades'),
    enderecos: Ember.computed.mapBy('model', 'address'),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    debugger: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    isShowingTranslucent: false,
    uploadFile: (0, _emberConcurrency.task)(function* (file) {
      Ember.set(this, 'isCreateLoading', true);
      let post_data = {
        name: Ember.get(file, 'name'),
        size: Ember.get(file, 'size'),
        id: Ember.get(file, 'id')
      };
      let token = this.get('session.data.authenticated.id');
      let opts = {
        'accepts': 'application/json,application/vnd.api+json',
        'headers': {
          'Authorization': 'Bearer ' + token
        },
        'data': post_data
      };
      let urlbase = [_environment.default.APP.API.host, _environment.default.APP.API.namespace].join('/'); //Alterar container

      let response = yield file.upload(urlbase + '/s3FileUploads/printpost-dev/upload' + '?access_token=' + token, opts).then(response => {
        let uploaded = response.body.result.files.file.pop();
        Ember.set(this.newRequest, 'document', uploaded.name);
        Ember.set(this.newRequest, 'logoId', uploaded.originalFilename);
        Ember.set(this, 'file', uploaded.originalFilename);
        Ember.set(this, 'isCreateLoading', false);
      });
      return response;
    }).maxConcurrency(3).enqueue(),
    createAddressTask: (0, _emberConcurrency.task)(function* (newAddress) {
      const USER = this.get('session.data.authenticated');
      let address = this.store.createRecord('address', {
        endereco: Ember.get(newAddress, 'endereco'),
        bairro: Ember.get(newAddress, 'bairro'),
        numero: Ember.get(newAddress, 'numero'),
        owner: Ember.get(newAddress, 'owner'),
        type: 'REMETENTE',
        complemento: Ember.get(newAddress, 'complemento'),
        uf: Ember.get(newAddress, 'uf.siglaUf'),
        cep: Ember.get(newAddress, 'cep'),
        cidade: Ember.get(newAddress, 'cidade'),
        userId: Ember.get(USER, 'userId')
      });

      try {
        yield address.save();
        this.toast.success('Sucesso', 'Endereço criado com sucesso!', {
          positionClass: 'toast-bottom-right'
        });
        this.set('isShowingTranslucent', false);
        Ember.getOwner(this).lookup('route:services.remetente').refresh();
      } catch (err) {
        this.toast.error(err, 'Erro!', {
          positionClass: 'toast-bottom-right'
        });
        return err;
      }
    }).maxConcurrency(3).enqueue(),
    trasitionTask: (0, _emberConcurrency.task)(function* () {
      let letterServ = Ember.get(this, 'newRequest.letterService');
      let smsServ = Ember.get(this, 'newRequest.smsService');
      let emailServ = Ember.get(this, 'newRequest.emailService');
      let fileDb = Ember.get(this, 'newRequest.fileDb');
      let nextWallet = this.get('newRequest.walletId'); // if (request.fileDb == 'impressao') {
      //   yield this.transitionToRoute('services.test', { queryParams: { wallet: nextWallet}});
      // } else 

      if (letterServ && fileDb == 'printpost') {
        yield this.transitionToRoute('services.letter', {
          queryParams: {
            wallet: nextWallet
          }
        });
      } else if (smsServ) {
        yield this.transitionToRoute('services.sms', {
          queryParams: {
            wallet: nextWallet
          }
        });
      } else if (emailServ) {
        yield this.transitionToRoute('services.email', {
          queryParams: {
            wallet: nextWallet
          }
        });
      } else {
        yield this.transitionToRoute('services.payment', {
          queryParams: {
            wallet: nextWallet
          }
        });
      }
    }).maxConcurrency(3).enqueue(),
    actions: {
      uploadImage(file) {
        Ember.get(this, 'uploadFile').perform(file);
      },

      deleteFile(file) {
        // console.log(file);
        file.destroyRecord();
      },

      createAddress: function (newAddress) {
        Ember.get(this, 'createAddressTask').perform(newAddress).then(() => {
          Ember.getOwner(this).lookup('route:services').refresh();
        });
      },
      updateCity: function (cidade) {
        Ember.set(this.get('newAddress'), 'cidade', cidade);
      },
      updateUf: function (uf) {
        Ember.set(this.get('newAddress'), 'uf', uf);
      },
      proceedTo: function () {
        Ember.get(this, 'trasitionTask').perform();
      }
    }
  });

  _exports.default = _default;
});