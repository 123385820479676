define("printpost/routes/services/new2", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    accountSubaccount: Ember.inject.service(),
    queryParams: {
      edit: false
    },
    model: async function (queryParams) {
      setTimeout(() => {
        Ember.$("#wrapper").attr('style', 'display: none');
      }, 300);
      const editRequest = queryParams.edit === "true" ? true : false;
      var account = this.baseapi.getCurrentUser(); //canais

      var url_canais = this.baseapi.apiLink('Channels/list');
      const obj_canais = await this.baseapi.getHttp(url_canais).then(r => r.data);
      var canal = obj_canais.find(x => x.value == "email"); // let newRequest = JSON.parse(localStorage.getItem('storage:request'));
      // let userId = newRequest.userId;
      // tabela de preço

      let url_prices = this.baseapi.apiLink('LinkCustomers/list');
      const obj_prices_customer = await this.baseapi.getHttp(url_prices).then(resp => resp.data);
      var query_general = {
        filter: {
          where: {
            pricingTableType: 'general_table',
            active: true
          },
          include: ['channel']
        }
      }; // tabela geral

      var strquery_general = (0, _globalFunctions.apiLinkQuery)(query_general);
      let url_price_table = this.baseapi.apiLink('PricingTables/find');
      const obj_prices_general = await this.baseapi.getHttp(url_price_table + '?' + strquery_general).then(resp => resp.data); //templates

      var query = {
        filter: {
          where: {},
          include: 'channel',
          order: 'id DESC'
        }
      };
      let newRequest = JSON.parse(localStorage.getItem('storage:request'));

      if (newRequest && newRequest.userId) {
        var or = [// {userId: newRequest.userId},
        {
          userId: account.id
        }];
        or.push({
          userId: newRequest.userId
        });
        query.filter.where.or = or;
      }

      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url_templates = this.baseapi.apiLink('Templates/list');
      var url_template_find = `${url_templates}?fu=true&${strquery}`;
      const obj_templates = await this.baseapi.getHttp(url_template_find).then(r => r.data); //account subaccount

      const obj_subaccount = await this.accountSubaccount.listAccountSubaccount(); //account

      let url_account = this.baseapi.apiLink('Accounts/list');
      const obj_account = await this.baseapi.getHttp(url_account).then(resp => resp.data); //cost center

      var url_costcenter = this.baseapi.apiLink('CostCenters/list?filter[include]=clickMassaConfig');
      const obj_costcenter = await this.baseapi.getHttp(url_costcenter).then(r => r.data); //custom layout     

      var query_layout = {
        filter: {
          include: ['layoutFile']
        }
      };
      var strquery_layout = (0, _globalFunctions.apiLinkQuery)(query_layout);
      let request_layout = this.baseapi.apiLink(`ClientLayouts/list?${strquery_layout}`);
      const obj_layout_files = await this.baseapi.getHttp(request_layout).then(resp => resp.data);
      let proceedRoute = null;
      let organizationEmails = null;
      let organizationReplyEmails = null;
      var obj_prices_tmp = [];

      if (obj_prices_customer.length > 0) {
        obj_prices_tmp = obj_prices_customer;

        if (obj_prices_customer[0].organization && obj_prices_customer[0].organization.options) {
          proceedRoute = obj_prices_customer[0].organization.options.validateWhatsapp == 1 ? 'services.validation' : 'services.payment';
          if (obj_prices_customer[0].organization.options.emailAddress.length > 0) organizationEmails = obj_prices_customer[0].organization.options.emailAddress;else organizationEmails = [];
          if (obj_prices_customer[0].organization.options.emailReplyAddress && obj_prices_customer[0].organization.options.emailReplyAddress.length > 0) organizationReplyEmails = obj_prices_customer[0].organization.options.emailReplyAddress;else organizationReplyEmails = [];
        }
      } else {
        obj_prices_tmp = obj_prices_general;
      }

      var itenService = {
        sms: {
          having: false,
          templates: [],
          sends: []
        },
        email: {
          having: false,
          templates: [],
          sends: []
        },
        carta: {
          having: false,
          templates: [],
          sends: []
        },
        whatsapp: {
          having: false,
          templates: [],
          sends: []
        },
        registry_office: {
          having: false,
          templates: [],
          sends: []
        }
      };

      if (obj_prices_tmp.find(x => x.channelValue == "whatsapp")) {
        itenService.whatsapp.having = true;
        itenService.whatsapp.templates = obj_templates.filter(x => x.channelName == 'whatsapp');
      }

      if (obj_prices_tmp.find(x => x.channelValue == "registry_office")) {
        itenService.registry_office.having = true;
        itenService.registry_office.templates = [];
      }

      if (obj_prices_tmp.find(x => x.channelValue == "sms")) {
        itenService.sms.having = true;
        itenService.sms.templates = obj_templates.filter(x => x.channelName == 'sms');
      }

      if (obj_prices_tmp.find(x => x.channelValue == "carta")) {
        itenService.carta.having = true;
        itenService.carta.templates = obj_templates.filter(x => x.channelName == 'carta');
      }

      if (obj_prices_tmp.find(x => x.channelValue == "email")) {
        itenService.email.having = true;
        itenService.email.templates = obj_templates.filter(x => x.channelName == 'email');
      }

      obj_prices_tmp.map(x => {
        var obj_prices = [];

        if (x.channelValue == "email") {
          x.options.forEach(j => {
            var obj = {
              id: j.id,
              name: j.name,
              color: j.color,
              format: j.format,
              priceSale: j.priceSale,
              priceVolumeGroupId: j.priceVolumeGroupId,
              send: j.send,
              volumeItens: j.volumeItens,
              channelValue: x.channelValue,
              channelId: x.channelId
            };
            obj_prices.push(obj);
          });
          itenService.email.sends = obj_prices;
        } else if (x.channelValue == "sms") {
          x.options.forEach(j => {
            var obj = {
              id: j.id,
              name: j.name,
              color: j.color,
              format: j.format,
              priceSale: j.priceSale,
              priceVolumeGroupId: j.priceVolumeGroupId,
              send: j.send,
              volumeItens: j.volumeItens,
              channelValue: x.channelValue,
              channelId: x.channelId
            };
            obj_prices.push(obj);
          });
          itenService.sms.sends = obj_prices;
        } else if (x.channelValue == "whatsapp") {
          x.options.forEach(j => {
            var obj = {
              id: j.id,
              name: j.name,
              color: j.color,
              format: j.format,
              priceSale: j.priceSale,
              priceVolumeGroupId: j.priceVolumeGroupId,
              send: j.send,
              volumeItens: j.volumeItens,
              channelValue: x.channelValue,
              channelId: x.channelId
            };
            obj_prices.push(obj);
          });
          itenService.whatsapp.sends = obj_prices;
        } else if (x.channelValue == "registry_office") {
          x.options.forEach(j => {
            var obj = {
              id: j.id,
              name: j.name,
              color: j.color,
              format: j.format,
              priceSale: j.priceSale,
              priceVolumeGroupId: j.priceVolumeGroupId,
              send: j.send,
              volumeItens: j.volumeItens,
              channelValue: x.channelValue,
              channelId: x.channelId
            };
            obj_prices.push(obj);
          });
          itenService.registry_office.sends = obj_prices;
        } else if (x.channelValue == "carta") {
          itenService.carta.sends = x;
        }
      });
      var dataPrintOptions = {
        format: '',
        grammage: '',
        papper: '',
        formatValue: '',
        sendValue: '',
        sendId: '',
        colored: false,
        blackWhite: false
      };
      var reqId = localStorage.getItem('newServiceRequestId');
      var dataRawFiles = [];

      if (reqId) {
        var urlRawFiles = this.baseapi.apiLink('rawFiles/list');
        let query = {
          filter: {
            where: {
              requestId: reqId
            }
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        urlRawFiles += "?" + strquery;
        dataRawFiles = await this.baseapi.getHttp(urlRawFiles).then(rf => rf.data);
      }

      return Ember.RSVP.hash({
        products: [],
        clickmassCenterCost: obj_costcenter.filter(x => x.clickMassaConfig && x.clickMassaConfig.length),
        prices: obj_prices_tmp,
        obj_prices_customer: obj_prices_customer,
        templatesSms: obj_templates,
        templatesWhatsapp: obj_templates,
        templatesEmail: obj_templates,
        templatesFilter: obj_templates,
        request_tags: [],
        contact_files: [],
        layout_files: obj_layout_files,
        optionsLayout: '',
        obj_subaccount: obj_subaccount,
        obj_prices_general: obj_prices_general,
        obj_costcenter: obj_costcenter,
        accounts: obj_account,
        channel: canal,
        editTemplate: {
          email: false,
          sms: false
        },
        helpTutors: 'campaign',
        itenService: itenService,
        dataPrintOptions: dataPrintOptions,
        havingOptionColor: false,
        havingSendOption: false,
        letterColor: {
          colored: false,
          blackWhite: false
        },
        requestAttachs: dataRawFiles,
        optionSend: {
          email: {
            qtd: '0',
            date: '',
            beginHour: '',
            endHour: '',
            events: [],
            frequencyOption: '',
            type: null,
            active: false,
            send: 1
          },
          sms: {
            qtd: '0',
            date: '',
            beginHour: '',
            endHour: '',
            events: [],
            frequencyOption: '',
            type: null,
            active: false,
            send: 1
          },
          whatsapp: {
            qtd: '0',
            date: '',
            beginHour: '',
            endHour: '',
            events: [],
            frequencyOption: '',
            type: null,
            active: false,
            send: 1
          }
        },
        proceedRoute: proceedRoute,
        organizationEmails: organizationEmails,
        organizationReplyEmails: organizationReplyEmails,
        listOptionsSend: [{
          description: 'Hora',
          value: 'hour'
        }, {
          description: 'Dia',
          value: 'day'
        }],
        editRequest: editRequest
      });
    }
  });

  _exports.default = _default;
});