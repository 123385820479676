define("printpost/storages/request", ["exports", "ember-local-storage/local/object", "ember-uuid"], function (_exports, _object, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Storage = _object.default.extend();

  Storage.reopenClass({
    initialState() {
      return {
        title: null,
        originalDocumentName: null,
        shippingMethod: 'grafica',
        sent: null,
        processing: null,
        error: null,
        totalCost: "0,00",
        walletId: "static",
        letterService: null,
        smsService: null,
        whatsappService: null,
        registryOfficeService: null,
        emailService: null,
        phoneContact: null,
        emailContact: "noreply@printpost.com.br",
        emailSubject: null,
        clientIdentifier: null,
        multiChannel: false,
        notarysOffice: false,
        paymentMethod: "faturado",
        pendingApprove: 0,
        observation: null,
        requestTagId: null,
        paymentCode: (0, _emberUuid.v4)(),
        printingOptions: {
          templateLetter: {
            backHTML: null,
            frontHTML: null,
            json: null,
            externalJson: null,
            editorMode: null,
            dataDisparo: null,
            definition: null,
            disparoImediato: true,
            productId: null,
            templateId: null
          },
          templateEmail: {
            backHTML: null,
            frontHTML: null,
            json: null,
            externalJson: null,
            editorMode: null,
            dataDisparo: null,
            definition: null,
            disparoImediato: true,
            productId: null,
            templateId: null,
            responseEmail: {
              hasResponseEmail: false,
              email: ''
            },
            optionSend: {
              qtd: '0',
              date: '',
              beginHour: '',
              endHour: '',
              events: [],
              frequencyOption: '',
              type: null,
              active: false,
              send: 1
            }
          },
          templateSMS: {
            backHTML: null,
            frontHTML: null,
            json: null,
            externalJson: null,
            editorMode: null,
            dataDisparo: null,
            definition: null,
            disparoImediato: true,
            productId: null,
            templateId: null,
            optionSend: {
              qtd: '0',
              date: '',
              beginHour: '',
              endHour: '',
              events: [],
              frequencyOption: '',
              type: null,
              active: false,
              send: 1
            }
          },
          templateWhatsapp: {
            backHTML: null,
            frontHTML: null,
            json: null,
            externalJson: null,
            editorMode: null,
            dataDisparo: null,
            definition: null,
            disparoImediato: true,
            productId: null,
            templateId: null,
            avatar: null,
            conteudo: null,
            optionSend: {
              qtd: '0',
              date: '',
              beginHour: '',
              endHour: '',
              events: [],
              frequencyOption: '',
              type: null,
              active: false,
              send: 1
            }
          },
          templateRegistryOffice: {
            backHTML: null,
            frontHTML: null,
            json: null,
            externalJson: null,
            editorMode: null,
            dataDisparo: null,
            definition: null,
            disparoImediato: true,
            productId: null,
            templateId: null,
            avatar: null,
            conteudo: null,
            optionSend: {
              qtd: '0',
              date: '',
              beginHour: '',
              endHour: '',
              events: [],
              frequencyOption: '',
              type: null,
              active: false,
              send: 1
            }
          }
        },
        devolucao: 'printpost',
        address: [{
          endereco: "Setor de Expansão econômica de Sobradinho",
          bairro: "Sobradinho",
          numero: 0,
          complemento: "Quadra 13 Rua “M” Lote 30",
          cep: "73020413",
          uf: "DF",
          nome: "EMPRESA XYZ CENTRO DE TRATAMENTO DE DEVOLUÇÕES",
          type: "REMETENTE",
          cidade: "Brasília"
        }],
        totalFreight: "0,00",
        step: 1,
        praticatePrices: {
          carta: {
            priceVolumeGroup: '0,000',
            priceSale: '0,000',
            quantity: 0,
            channelId: "",
            channelValue: ""
          },
          email: {
            priceVolumeGroup: '0,000',
            priceSale: '0,000',
            quantity: 0,
            channelId: "",
            channelValue: ""
          },
          sms: {
            priceVolumeGroup: '0,000',
            priceSale: '0,000',
            quantity: 0,
            channelId: "",
            channelValue: ""
          },
          whatsapp: {
            priceVolumeGroup: '0,000',
            priceSale: '0,000',
            quantity: 0,
            channelId: "",
            channelValue: ""
          },
          registry_office: {
            priceVolumeGroup: '0,000',
            priceSale: '0,000',
            quantity: 0,
            channelId: "",
            channelValue: ""
          }
        },
        quantity: {
          carta: 0,
          email: 0,
          sms: 0,
          whatsapp: 0,
          registry_office: 0
        },
        orderSend: {
          first: '',
          second: '',
          third: '',
          fourth: 'carta'
        },
        fileDb: 'printpost',
        typeConfig: 'models_templates',
        optionsLayout: 'normal_layout',
        clientLayoutId: '',
        columnsSelectWhatsapp: null,
        columnsSelectSMS: null,
        columnsSelectEmail: null,
        columns_email: [],
        columns_sms: [],
        columns_whatsapp: [],
        organizationId: JSON.parse(localStorage.getItem('ember_simple_auth-session')).authenticated.account.organizationId,
        userId: JSON.parse(localStorage.getItem('ember_simple_auth-session')).authenticated.account.id,
        validations: {
          email: {
            repeatEmail: 0,
            blacklist: 1
          },
          carta: null,
          sms: {
            whatsapp: 0,
            notCallSP: 0,
            repeatNumber: 0,
            messageLimit: 0,
            blacklist: 1
          },
          whatsapp: {
            repeatNumber: 1,
            blacklist: 1
          }
        },
        immediate_send: {
          email: true,
          carta: true,
          whatsapp: true,
          sms: true
        },
        validateWhatsapp: true
      };
    }

  });
  var _default = Storage;
  _exports.default = _default;
});