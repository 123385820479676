define("printpost/controllers/configurations/payment", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    isRunning: false,
    modelEditor: Ember.computed('model.user', function () {
      return this.get('model.user.value');
    }),
    changePaymentTask: (0, _emberConcurrency.task)(function* (payload) {
      this.set('isRunning', true);

      try {
        yield this.get('baseapi').updateUserDetails(payload);
        this.toast.success('Pagamento preferencia alterado com sucesso.', 'Sucesso!', {
          positionClass: 'toast-bottom-right'
        });
        yield Ember.getOwner(this).lookup('route:configurations.company').refresh();
      } catch (error) {
        this.toast.error('Erro ao tentar atualizar pagamento.', 'Erro!', {
          positionClass: 'toast-bottom-right'
        });
        return error;
      }
    }),
    actions: {
      submit(model) {
        let payload = {
          payment: Ember.get(model, 'payment')
        };
        Ember.get(this, 'changePaymentTask').perform(payload).then(() => {
          this.set('isRunning', false);
        });
      }

    }
  });

  _exports.default = _default;
});