define("printpost/validators/inclusion", ["exports", "ember-cp-validations/validators/inclusion"], function (_exports, _inclusion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _inclusion.default;
    }
  });
});