define("printpost/templates/dashboard-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w7DKxjLR",
    "block": "{\"symbols\":[],\"statements\":[[0,\"    \\n    \"],[2,\" Preloader \"],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"preloader\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"circle\"],[9],[0,\"\\n            \"],[4,\"url-logo\",null,null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n            \"],[7,\"img\"],[11,\"src\",\"/assets/images/loading/loading-2.gif\"],[11,\"style\",\"height: 150px;top: -60px;position: relative;\"],[9],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"id\",\"dashmsgbox\"],[11,\"style\",\"margin-top: -75px;\"],[9],[0,\"\\n                \\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"script\"],[9],[0,\"\\n        var boxmsg = document.getElementById(\\\"dashmsgbox\\\");\\n        var array_messages = [\\n        \\\"Enviei simultaneamente mensagens personalizadas em diversos canais.\\\",\\n        \\\"Tenha uma visão 360° graus na nossa solução Omnichannel.\\\",\\n        \\\"Descubra qual o melhor canal de comunicação do seu cliente.\\\",\\n        \\\"Notifique os seus clientes pelo WhatsApp.\\\",\\n        \\\"Saiba mais sobre nossa cobrança automática.\\\",\\n        \\\"Envie cartas e telegramas com comprovação de entrega\\\"\\n        ]\\n        var rand = parseInt(Math.random() * 5);\\n        boxmsg.innerText = array_messages[rand];\\n    \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/dashboard-loading.hbs"
    }
  });

  _exports.default = _default;
});