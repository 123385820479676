define("printpost/validations/address", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 3,
      max: 40
    })],
    lastName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 3,
      max: 40
    })],
    aboutMe: [(0, _validators.validateLength)({
      allowBlank: true,
      max: 200
    })],
    country: [(0, _validators.validatePresence)(true)],
    gender: [(0, _validators.validatePresence)(true)],
    terms: [(0, _validators.validateInclusion)({
      list: [true],
      message: "Please accept the terms and conditions!"
    })],
    color: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});