define("printpost/controllers/returncenter", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    isUploadReturn: Ember.computed(function () {
      return false;
    }),
    selectedReturn: Ember.computed(function () {
      return null;
    }),
    organizationLetterRequests: (0, _emberConcurrency.task)(function* (organizationId) {
      let url = this.baseapi.apiLink(`Requests/letter-to-vincule?organizationId=${organizationId}`);
      var call = this.baseapi.getHttp(url);

      try {
        this.preloader.toggleClass('d-none');
        yield call.then(response => {
          this.preloader.toggleClass('d-none');
          this.set('model.listArVincule', response.data);
        });
      } catch (error) {
        this.preloader.toggleClass('d-none');

        for (let err in error.errors) {
          if (error.errors.hasOwnProperty(err)) {
            if (error.errors[err].status == 422) {
              this.toast.error('Campos obrigatórios em branco.', 'Erro!', {
                positionClass: 'toast-bottom-right'
              });
            }
          }
        }
      }
    }),
    actions: {
      setTypeReturn: function (dataReturn) {
        this.set('model.selectedReturn', dataReturn);
      },
      setRequest: function (dataReturn) {
        this.set('model.selectedRequest', dataReturn);
      },
      setFileReturn: function (data) {
        Ember.set(this, 'model.filesReturn', data.target.files);
        Ember.set(this, 'model.filesReturnLenght', data.target.files.length);
      },

      setFileExcel(data) {
        Ember.set(this, 'model.fileXls', data.target.files[0]);
      },

      sendFilesDataReturn: function () {
        var dataReturn = Ember.get(this, 'model.selectedReturn');
        var filesSend = Ember.get(this, 'model.filesReturn');
        var fileXls = Ember.get(this, 'model.fileXls');
        var optionCard = Ember.get(this, 'model.optionsCard');
        var url = optionCard == 'disbrave-ar' ? 'RequestReturns/return/upload' : 'RequestReturns/letter-return';
        var checkSend = optionCard == 'disbrave-ar' && dataReturn && filesSend ? true : optionCard != 'disbrave-ar' && dataReturn ? true : false;

        if (checkSend) {
          let form = new FormData();
          form.set("typeReturnId", dataReturn.id);
          form.set("typeReturnDescription", dataReturn.description);

          if (filesSend && filesSend.length > 0) {
            for (let i = 0; i < filesSend.length; i++) {
              const file = filesSend[i];
              form.append("file", file);
            }
          }

          if (optionCard != 'disbrave-ar') form.append("excelmap", fileXls);
          let urlP = this.baseapi.apiLink(url);
          this.preloader.toggleClass('d-none');
          this.baseapi.postFormData(urlP, form).then(resp => {
            this.preloader.toggleClass('d-none');
            Ember.set(this, 'model.fileXls', '');
            Ember.$("#upload-file").val('');
            Ember.$("#upload-xls").val('');
            Ember.set(this, 'model.filesReturn', '');
            Ember.set(this, 'model.filesReturnLenght', 0);

            if (resp.data.success) {
              var msg = '';
              var ok = 'Retornos processados com sucesso.';

              if (resp.data.success[0].source && resp.data.success[0].source.length > 0) {
                msg = `Sucesso:. ${resp.data.success[0].detail} (${resp.data.success[0].source.join(', ')})`;
                this.set('model.itenReturnNotFound', msg);
              }

              this.toast.success('', ok, {
                positionClass: 'toast-bottom-right'
              });
            } else {
              this.toast.error('', resp.data.errors[0].detail, {
                positionClass: 'toast-bottom-right'
              });
            }
          }, error => {
            Ember.set(this, 'model.fileXls', '');
            Ember.$("#upload-file").val('');
            Ember.$("#upload-xls").val('');
            Ember.set(this, 'model.filesReturn', '');
            Ember.set(this, 'model.filesReturnLenght', 0);
            this.preloader.toggleClass('d-none');
            this.toast.error('', `Erro:. ${error.response.data.errors[0].detail}`, {
              positionClass: 'toast-bottom-right'
            });
          });
        } else {
          this.toast.error('Erro!', 'Problemas ao enviar os arquivos', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      sendFileVinculateAR: function () {
        var filesSend = Ember.get(this, 'model.filesReturn');
        var optionCard = Ember.get(this, 'model.optionsCard');
        var url = 'RequestReturns/return/vincule-ar';
        var checkSend = optionCard == 'vincula-disbrave-ar' ? true : false;
        const requestId = this.get('model.selectedRequest.id');
        const organizationId = this.get('model.organizationSelected.id');
        const userId = this.get('model.selectedRequest.userId');

        if (checkSend && requestId && organizationId && userId) {
          let form = new FormData();
          form.set("requestId", requestId);
          form.set("organizationId", organizationId);
          form.set("userId", userId);
          form.set("channel", "carta");
          form.set("id", "null");
          var name = 'upload_day.xslx';

          if (filesSend && filesSend.length > 0) {
            for (let i = 0; i < filesSend.length; i++) {
              const file = filesSend[i];
              name = file.name;
              form.append("file", file);
            }
          }

          form.set("name", name);
          let urlP = this.baseapi.apiLink(url);
          let token = this.baseapi.getToken();
          this.preloader.toggleClass('d-none');
          this.baseapi.postFormData(`${urlP}?access_token=${token}`, form).then(resp => {
            this.preloader.toggleClass('d-none');
            Ember.set(this, 'model.fileXls', '');
            Ember.$("#upload-file").val('');
            Ember.$("#upload-xls").val('');
            Ember.set(this, 'model.filesReturn', '');
            Ember.set(this, 'model.filesReturnLenght', 0);
            Ember.set(this, 'model.selectedRequest', '');

            if (resp.data.success) {
              var msg = '';
              var ok = 'Arquivo vinculado com sucesso.';

              if (resp.data.success[0].source && resp.data.success[0].source.length > 0) {
                msg = `Sucesso:. ${resp.data.success[0].detail} (${resp.data.success[0].source.join(', ')})`;
                this.set('model.itenReturnNotFound', msg);
              }

              this.toast.success('', ok, {
                positionClass: 'toast-bottom-right'
              });
            } else {
              this.toast.error('', resp.data.errors[0].detail, {
                positionClass: 'toast-bottom-right'
              });
            }
          }, error => {
            Ember.set(this, 'model.fileXls', '');
            Ember.$("#upload-file").val('');
            Ember.$("#upload-xls").val('');
            Ember.set(this, 'model.filesReturn', '');
            Ember.set(this, 'model.filesReturnLenght', 0);
            Ember.set(this, 'model.selectedRequest', '');
            this.preloader.toggleClass('d-none');
            this.toast.error('', `Erro:. ${error.response.data.errors[0].detail}`, {
              positionClass: 'toast-bottom-right'
            });
          });
        } else {
          this.toast.error('Erro!', 'Problemas ao enviar os arquivos', {
            positionClass: 'toast-bottom-right'
          });
        }
      },

      setOptionsCard(val) {
        Ember.set(this, 'model.filesReturn', null);
        Ember.set(this, 'model.filesReturnLenght', 0);
        Ember.set(this, 'model.selectedReturn', null);
        Ember.set(this, 'model.fileXls', {
          name: '-'
        });
        Ember.set(this, 'model.selectedRequest', '');
        this.set('model.optionsCard', val);
      },

      closeMessageReturnNotFound() {
        this.set('model.itenReturnNotFound', '');
      },

      setOrganization: async function (organization) {
        this.set('organizationSelected', organization);
        this.set('model.organizationSelected', organization);
        this.set('model.selectedRequest', null);
        this.set('model.filesReturn', null);
        this.set('model.filesReturnLenght', 0);
        await this.get('organizationLetterRequests').perform(organization.id);
      }
    }
  });

  _exports.default = _default;
});