define("printpost/controllers/usuarios/list", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    data: Ember.computed('model', function () {
      var model = this.get('model.data');
      return model[0];
    }),
    accountUser: Ember.computed('accountUser', function () {
      let s = this.session.data.authenticated.account;
      return s;
    }),
    adminValue: Ember.computed('adminValue', function () {
      let s = this.baseapi.getAdmValue();
      return s;
    }),
    principalAccountId: Ember.computed(function () {
      return '';
    }),
    dataSubaccount: Ember.computed(function () {
      return null;
    }).property().volatile(),
    searchSubaccountTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Accounts/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    listAccountSubaccount: function (id) {
      let query = {
        filter: {
          where: {
            principalAccountId: id
          },
          include: ['subAccount']
        }
      };
      let strquery = (0, _globalFunctions.apiLinkQuery)(query);
      let url = this.baseapi.apiLink('AccountSubaccounts/list');
      this.preloader.toggleClass('d-none');
      this.baseapi.getHttp(url + '?' + strquery).then(resp => {
        Ember.set(this, 'accountsSubaccounts', resp.data);
        this.preloader.toggleClass('d-none');

        if (resp.data) {
          let ids_accounts = resp.data.map(x => {
            return x.subAccount.id;
          });
          let query02 = {
            filter: {
              where: {
                id: {
                  nin: ids_accounts
                }
              }
            }
          };

          if (ids_accounts.length == 1) {
            query02.filter.where.id = {
              neq: resp.data[0].subAccount.id
            };
          }

          let strquery02 = ids_accounts.length > 0 ? (0, _globalFunctions.apiLinkQuery)(query02) : '';
          let url_account = this.baseapi.apiLink('Accounts/list');
          this.preloader.toggleClass('d-none');
          this.baseapi.getHttp(url_account + '?' + strquery02).then(resp02 => {
            this.preloader.toggleClass('d-none');
            Ember.set(this, 'subaccountsForVincule', resp02.data);
          });
        }
      });
    },
    actions: {
      goToLink: function (item) {
        this.transitionToRoute(item);
      },
      ativarUser: function (id, val) {
        let accountId = id == undefined ? 0 : id;
        let list = Ember.get(this, 'data').objects;
        let item = list.find(x => x.id == id);

        if (item) {
          Ember.set(item, 'emailVerified', !val);
          let __account = {
            data: {
              type: "Account",
              id: accountId,
              attributes: item
            }
          };
          delete __account.data.attributes.id;
          let url = this.baseapi.apiLink('Accounts/' + accountId);
          let call = this.baseapi.patch(url, __account);
          call.then(resp => {
            //not to do
            Ember.set(item, 'id', accountId);
            this.toast.success('Atualizado!', 'Usuário atualizado com sucesso!', {
              positionClass: 'toast-bottom-right'
            });
          }, error => {
            console.log(error);
          });
        }
      },

      setSubaccount(item) {
        Ember.set(this, 'dataSubaccount', item);
      },

      getAccountSubaccount(id) {
        Ember.set(this, 'principalAccountId', id);
        this.listAccountSubaccount(id);
      },

      saveSubaccountAccount() {
        let usuario = Ember.get(this, 'dataSubaccount');
        let principalAccountId = Ember.get(this, 'principalAccountId');

        if (!usuario.id || !principalAccountId) {
          this.toast.error("", "Selecione o usuário para vinculação", {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let item = {
          "principalAccountId": principalAccountId,
          "subAccountId": usuario.id
        };
        let _object = {
          data: {
            type: "AccountSubaccount",
            attributes: item
          }
        };
        let url = this.baseapi.apiLink('AccountSubaccounts');
        let __rp = _object; // delete __rp.data.attributes.id;

        let call = this.baseapi.post(url, __rp);
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');
          this.toast.success('Usuário vinculado!', 'Usuário vinculado com sucesso', {
            positionClass: 'toast-bottom-right'
          });
        }, error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('Usuário não vinculado!', 'Usuário não foi vinculado!', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      removeUserAccountSubaccount(id) {
        let url = this.baseapi.apiLink(`AccountSubaccounts/${id}`);
        let call = this.baseapi.delete(url);
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');
          this.toast.success('Usuário removido!', 'Usuário removido com sucesso', {
            positionClass: 'toast-bottom-right'
          });
        }, error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('Usuário não removido!', 'Usuário não foi removido!', {
            positionClass: 'toast-bottom-right'
          });
        });
      }

    }
  });

  _exports.default = _default;
});