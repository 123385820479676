define("printpost/templates/components/wizard-js2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YIsGE2wQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n    \"],[7,\"form\"],[11,\"action\",\"\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"id\",\"wizard\"],[9],[0,\"\\n            \"],[14,1],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[2,\" JQUERY STEP \"],[0,\"\\n\"],[7,\"script\"],[11,\"src\",\"/assets/js/steper/jquery.steps.js\"],[9],[10],[0,\"\\n\\n\"],[7,\"script\"],[11,\"src\",\"/assets/js/steper/main.js\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/wizard-js2.hbs"
    }
  });

  _exports.default = _default;
});