define("printpost/routes/prices/edit", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      // let id_param = getUrlParameters("id", "", true);
      var url_precification = this.baseapi.apiLink('PricingTables/precification');
      const obj_precification = await this.baseapi.getHttp(url_precification).then(r => r.data); //find channels

      var url_channels = this.baseapi.apiLink('Channels/list');
      const obj_channels = await this.baseapi.getHttp(url_channels).then(r => r.data); //find format options

      var url_formatoptions = this.baseapi.apiLink('PrintFormatOptions/list');
      const obj_formatoptions = await this.baseapi.getHttp(url_formatoptions).then(r => r.data[0]); //type tables

      var list_typetable = [// {
      //     "selectId": "0",
      //     "selectValue": "0",
      //     "selectText": "Selecione"
      // },
      {
        "selectId": "general_table",
        "selectValue": "general_table",
        "selectText": "Geral para todos os clientes"
      }, {
        "selectId": "mapofprices_table",
        "selectValue": "mapofprices_table",
        "selectText": "Usada no mapa de preços"
      }, {
        "selectId": "customersprice_table",
        "selectValue": "customersprice_table",
        "selectText": "Para atribuir a cliente específico"
      }];
      let item = {
        id: '',
        name: '',
        active: true,
        isPrinted: false,
        has: {
          tax: true,
          salesman: true,
          volume: true
        },
        order: 0,
        description: '',
        formatOptions: {
          format: [],
          papper: [],
          grammage: [],
          blackWhite: false,
          colored: false
        },
        sendOptions: [],
        printSimulator: {
          format: '',
          grammage: '',
          papper: '',
          colored: false,
          sendId: ''
        },
        color: {
          priceColor: '0',
          priceBlackWhite: '0',
          priceColorId: '0',
          priceBlackWhiteId: '0'
        },
        praticatePrices: {
          priceSend: '',
          priceColor: '',
          priceFinish: '',
          priceFormat: '',
          pricePackage: '',
          priceSalesman: '',
          priceTax: '',
          priceVolumeGroup: '',
          volume: '',
          priceSale: ''
        },
        channelId: '0',
        pricingTableType: '0',
        priceSendId: "0",
        priceFinishId: "0",
        pricePackageId: "0",
        priceSalesmanId: "0",
        priceTaxId: "0",
        priceVolumeGroupId: "0",
        priceFormatId: "0",
        priceColorId: "0",
        colored: false,
        pvItem: null,
        valuesCalculate: {
          marginGain: '',
          priceCost: '',
          priceCostUnity: '',
          priceDiscount: '',
          priceDiscountUnity: '',
          priceGraph: '',
          priceGraphUnity: '',
          priceLiquid: '',
          priceLiquidUnity: '',
          priceSale: '',
          priceSalesman: '',
          priceSalesmanUnity: '',
          priceSend: '',
          priceSendUnity: '',
          priceTax: '',
          priceTaxUnity: '',
          priceUnitySale: '',
          priceUnitySugest: '',
          quantity: ''
        },
        priceValues: []
      };
      var pvItem = {
        format: "",
        send: "",
        color: "",
        id: ""
      };
      var url = this.baseapi.apiLink('PricingTables/list');
      let selectProduct = null;
      let selectPricingTableType = null;

      if (queryParams && queryParams.id) {
        let query = {
          filter: {
            where: {
              id: queryParams.id
            },
            include: ['priceValues']
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        url += "?" + strquery;
        const obj = await this.baseapi.getHttp(url).then(r => r.data);
        var item_pt = obj.find(x => x.id == queryParams.id);
        item.id = item_pt.id;
        item.name = item_pt.name;
        item.active = item_pt.active;
        item.description = item_pt.description;
        item.isPrinted = item_pt.isPrinted;
        item.pricingTableType = item_pt.pricingTableType;
        item.formatOptions = item_pt.formatOptions;
        item.channelId = item_pt.channelId;
        item.channelValue = item_pt.channelValue;
        selectProduct = obj_channels.find(x => x.id == item.channelId);
        selectPricingTableType = list_typetable.find(x => x.selectId == item.pricingTableType);
        item.priceValues = item_pt.priceValues;
      }

      var listPricesValues = [];
      item.priceValues.forEach(pv => {
        var obj = {
          id: pv.id,
          name: pv.name,
          strFormat: pv.strFormat,
          strSend: pv.strSend,
          marginGain: pv.valuesCalculate.marginGain,
          priceCostUnity: pv.valuesCalculate.priceCostUnity,
          priceSale: pv.praticatePrices.priceSale
        };
        listPricesValues.push(obj);
      });
      return Ember.RSVP.hash({
        dataPrecification: obj_precification,
        dataChannels: obj_channels,
        dataTypeTables: list_typetable,
        dataFormatOptions: obj_formatoptions,
        item: item,
        mostrarCadastrar: false,
        pvItem: pvItem,
        listPricesValues: listPricesValues,
        selectProduct: selectProduct,
        selectPricingTableType: selectPricingTableType,
        selectDataPriceSend: null,
        selectDataPriceVolume: null,
        selectDataPriceSalesman: null,
        selectDataPriceTax: null,
        selectDataPricePackage: null,
        selectDataPriceFormat: null,
        selectDataPriceFinish: null,
        selectAdditionalSend: null,
        itemOptionName: null
      });
    }
  });

  _exports.default = _default;
});