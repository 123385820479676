define("printpost/controllers/reports/panelwhatsapp", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    menusFilter: Ember.computed('menusFilter', function () {
      var itens = ['filter_mes', 'filter_ano', 'filter_inicio'];
      return itens;
    }),
    dataWallets: Ember.computed('model.dataWallets', function () {
      var model = this.get('model.dataWallets');
      return model;
    }),
    dataCostCenter: Ember.computed('model.obj_costcenter', function () {
      var model = this.get('model.obj_costcenter');
      return model;
    }),
    dataDateFilter: Ember.computed('model.dataDateFilter', function () {
      var model = this.get('model.dataDateFilter');
      return model;
    }),
    dataDateFilterStr: Ember.computed('model.dataDateFilterStr', function () {
      var model = this.get('model.dataDateFilterStr');
      return model;
    }),
    taskFilterReturn: (0, _emberConcurrency.task)(function* (onlyItens = false) {
      var name = Ember.get(this, 'cStrWalletId');
      var costCenterId = Ember.get(this, 'cStrCostCenterId');
      let key = Ember.get(this, 'model.key');
      key = key ? key : 'aguardando';
      var query = {
        filter: {
          where: {
            and: [{
              createdAt: {
                gte: Ember.get(this, 'cStrDateBegin')
              }
            }, {
              createdAt: {
                lte: Ember.get(this, 'cStrDateEnd')
              }
            }]
          }
        }
      };

      if (name && name.length > 0) {
        query.filter.where.title = {
          regexp: `^${name}/i`
        };
      }

      if (costCenterId && costCenterId.length > 0) {
        query.filter.where.costCenterId = costCenterId;
      }

      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');

      try {
        let url = this.baseapi.apiLink('RequestDatasets/panel-whatsapp');
        let urlItens = this.baseapi.apiLink('Reports/panel-itens');
        if (key) strquery += `&key=${key}`;
        let filter = Ember.get(this, 'model.filter');
        if (filter.skip > 0) strquery += `&filter[skip]=${filter.skip}`;
        var data = [];
        if (!onlyItens) data = yield this.baseapi.getHttp(`${url}?${strquery}`).then(x => x.data);
        const dataItens = yield this.baseapi.getHttp(`${urlItens}?${strquery}&type=whatsapp`).then(x => x.data);
        this.preloader.toggleClass('d-none'); // construct pie graph

        var colors = ["#31B404", "#FE9A2E", "#FFBF00", "#D8CEF6", "#FE2EC8", "#8A084B", "#9AFE2E", "#61380B", "#0B615E", "#380B61", "#5FB404"];
        var objPieGraph = {
          datasets: [{
            label: "Status de e-mail",
            fill: false,
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 0
          }],
          color: [],
          labels: []
        };
        this.set('model.dataItens', dataItens);
        if (onlyItens) return;
        data.qtdStatus.forEach((itemData, idx) => {
          objPieGraph.datasets[0].backgroundColor.push(colors[idx]);
          objPieGraph.datasets[0].borderColor.push(colors[idx]);
          objPieGraph.color.push(colors[idx]);
          objPieGraph.labels.push(itemData.message);
          objPieGraph.datasets[0].data.push(itemData.qtd);
        });
        this.set('model.data', data);
        this.set('model.objPieGraph', objPieGraph);
      } catch (error) {
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao consultar dados', this.baseapi.toastDontClear());
      }
    }).keepLatest(),
    taskFilterContactResponse: (0, _emberConcurrency.task)(function* () {
      var name = Ember.get(this, 'cStrWalletId');
      var costCenterId = Ember.get(this, 'cStrCostCenterId');
      let key = Ember.get(this, 'model.key');
      key = key ? key : '*';
      var query = {
        filter: {
          where: {
            and: [{
              createdAt: {
                gte: Ember.get(this, 'cStrDateBegin')
              }
            }, {
              createdAt: {
                lte: Ember.get(this, 'cStrDateEnd')
              }
            }]
          }
        }
      };

      if (name && name.length > 0) {
        query.filter.where.title = {
          regexp: `^${name}/i`
        };
      }

      if (costCenterId && costCenterId.length > 0) {
        query.filter.where.costCenterId = costCenterId;
      }

      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');

      try {
        let urlItens = this.baseapi.apiLink('Reports/contact-response');
        if (key) strquery += `&key=${key}`;
        let filter = Ember.get(this, 'model.filter');
        if (filter.skip > 0) strquery += `&filter[skip]=${filter.skip}`;
        const dataItens = yield this.baseapi.getHttp(`${urlItens}?${strquery}&type=whatsapp`).then(x => x.data);
        this.preloader.toggleClass('d-none');
        this.set('model.dataItens', dataItens);
        this.set('model.qtdContactResponse', dataItens.total);
      } catch (error) {
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao consultar dados', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    datePeriodo: [],
    cStrDateBegin: Ember.computed('strDateBegin', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-01T00:00:00-03:00';
      return strDate;
    }),
    cStrDateEnd: Ember.computed('strDateEnd', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + 'T23:59:00-03:00';
      return strDate;
    }),
    taskListAnalitycalFiles: (0, _emberConcurrency.task)(function* () {
      var urlRawFiles = this.baseapi.apiLink('rawFiles/list');
      var query = {
        filter: {
          where: {
            or: [{
              type: 'analitycal_report'
            }, {
              type: 'whatsapp_response'
            }],
            mode: 'whatsapp',
            name: {
              nin: ['', 'null']
            }
          },
          order: 'id DESC'
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      urlRawFiles += "?" + strquery;
      this.preloader.toggleClass('d-none');
      const dataRawFiles = yield this.baseapi.getHttp(urlRawFiles).then(rf => rf.data);
      this.preloader.toggleClass('d-none');
      this.set('model.filesReport', dataRawFiles);
    }).keepLatest(),
    actions: {
      actionFilterReturn: function (strfilter) {
        let listFilterMenus = Ember.get(this, 'menusFilter');
        let objDate = new Date();
        let strDateBegin = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-01T00:00:00-03:00';
        let strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
        listFilterMenus.forEach(item => {
          if (strfilter != item) {
            Ember.$('#' + item).addClass('text-lightgray');
          } else {
            Ember.$('#' + item).removeClass('text-lightgray');

            if (item == 'filter_ano') {
              strDateBegin = objDate.getFullYear() + '-01-01T00:00:00-03:00';
              strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
            } else if (item == 'filter_inicio') {
              strDateBegin = '2019-01-01T00:00:00-03:00';
              strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
            }
          }
        });
        Ember.set(this, 'cStrDateBegin', strDateBegin);
        Ember.set(this, 'cStrDateEnd', strDateEnd);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes_setas').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
        }, 500);
        Ember.get(this, 'taskFilterReturn').perform();
      },
      actionFilterWallet: function (name) {
        this.set('model.filter', {
          skip: 0,
          limit: 0
        });
        Ember.$('#list_wallets li').each((index, item) => {
          var nameCampaign = Ember.$(item).attr('data-id');

          if (name == nameCampaign) {
            Ember.$(item).removeClass('text-lightgray');
            name != 'filter_all' ? Ember.set(this, 'cStrWalletId', name) : Ember.set(this, 'cStrWalletId', '');
            Ember.get(this, 'taskFilterReturn').perform();
          } else {
            Ember.$(item).addClass('text-lightgray');
          }

          if (name == "filter_all") Ember.$(item).removeClass('text-lightgray');
        });
      },
      actionFilterCost: function (name) {
        this.set('model.filter', {
          skip: 0,
          limit: 0
        });
        Ember.$('#list_costcenter li').each((index, item) => {
          var nameCampaign = Ember.$(item).attr('data-name');
          var valueText = Ember.$(item).attr('data-id');

          if (name == nameCampaign) {
            Ember.$(item).removeClass('text-lightgray');
            name != 'filter_all' ? Ember.set(this, 'cStrCostCenterId', valueText) : Ember.set(this, 'cStrCostCenterId', '');
            Ember.get(this, 'taskFilterReturn').perform();
          } else {
            Ember.$(item).addClass('text-lightgray');
          }

          if (name == "filter_all") Ember.$(item).removeClass('text-lightgray');
        });
      },

      filterCostcenter() {
        let input, filter, li;
        input = document.getElementById('filterCostcenter');
        filter = input.value.toUpperCase();
        li = this.get('model.obj_costcenter');
        var new_list = li.filter(x => x.description.toUpperCase().indexOf(filter) > -1);
        Ember.set(this, 'dataCostCenter', new_list);
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });

        if (!dateFlat) {
          return;
        }

        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":00-03:00";
        let d = this.datePeriodo;

        if (d.length == 0) {
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        } else if (d.length == 1) {
          let st = year + "-" + month + "-" + day + "T23:59:00-03:00";
          d.push(st);
          Ember.set(this, 'cStrDateEnd', st);
          Ember.get(this, 'taskFilterReturn').perform();
        } else if (d.length == 2) {
          d = [];
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        }

        this.datePeriodo = d;
        setTimeout(() => {
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').addClass('text-lightgray');
          Ember.$('#filter_periodo').removeClass('text-lightgray');
        }, 500);
      },

      beforeMonth() {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() - 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth(), 0);
        let strDate = d1.getFullYear() + '-' + this.baseapi.padStr(d1.getMonth() + 1, 2) + '-01T00:00:00-03:00';
        let strDateEnd = d2.getFullYear() + '-' + this.baseapi.padStr(d2.getMonth() + 1, 2) + '-' + this.baseapi.padStr(d2.getDate(), 2) + 'T23:59:59-03:00';
        let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        Ember.set(this, 'cStrDateBegin', strDate);
        Ember.set(this, 'cStrDateEnd', strDateEnd);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').removeClass('text-lightgray');
        }, 500);
        Ember.get(this, 'taskFilterReturn').perform();
      },

      afterMonth() {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() + 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth() + 2, 0);
        let strDate = d1.getFullYear() + '-' + this.baseapi.padStr(d1.getMonth() + 1, 2) + '-01T00:00:00-03:00';
        let strDateEnd = d2.getFullYear() + '-' + this.baseapi.padStr(d2.getMonth() + 1, 2) + '-' + this.baseapi.padStr(d2.getDate(), 2) + 'T23:59:59-03:00';
        let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        Ember.set(this, 'cStrDateBegin', strDate);
        Ember.set(this, 'cStrDateEnd', strDateEnd);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').removeClass('text-lightgray');
        }, 500);
        Ember.get(this, 'taskFilterReturn').perform();
      },

      filterCampaign() {
        let input, filter, li;
        input = document.getElementById('filterCampaign');
        filter = input.value.toUpperCase();
        li = this.get('model.dataWallets');
        var new_list = li.filter(x => x.name.toUpperCase().indexOf(filter) > -1);
        Ember.set(this, 'dataWallets', new_list);
      },

      setFormat(v) {
        this.set('model.format', v);
        this.get('taskFilterReturn').perform();
      },

      paginationRequest(filterSkip) {
        this.set('model.filter', filterSkip);

        if (this.get('model.returnMessage') == 'Respostas') {
          this.get('taskFilterContactResponse').perform(true);
        } else {
          this.get('taskFilterReturn').perform(true);
        }
      },

      loadItens(idx) {
        var dataItens = this.get('model.data.qtdStatus');
        var item = dataItens[idx];
        dataItens.forEach((d, j) => {
          if (j == idx) Ember.$(`#status${j}`).addClass('panel-active');else Ember.$(`#status${j}`).removeClass('panel-active');
        });
        this.set('model.key', item.key);
        this.set('model.returnMessage', item.message);
        this.set('model.filter.skip', 0);
        this.get('taskFilterReturn').perform(true);
      },

      downloadAnalitycalXLS() {
        var name = Ember.get(this, 'cStrWalletId');
        var costCenterId = Ember.get(this, 'cStrCostCenterId');
        var query = {
          filter: {
            where: {
              and: [{
                createdAt: {
                  gte: Ember.get(this, 'cStrDateBegin')
                }
              }, {
                createdAt: {
                  lte: Ember.get(this, 'cStrDateEnd')
                }
              }]
            },
            include: ['costCenter']
          }
        };

        if (name && name.length > 0) {
          query.filter.where.title = {
            regexp: `^${name}/i`
          };
        }

        if (costCenterId && costCenterId.length > 0) {
          query.filter.where.costCenterId = costCenterId;
        }

        var strquery = (0, _globalFunctions.apiLinkQuery)(query);
        let url = this.baseapi.apiLink('Reports/analitycal-mail-xlsx');
        let urlSmsResponse = this.baseapi.apiLink('Reports/sms-response-xlsx');
        let key = Ember.get(this, 'model.key');
        let returnMessage = Ember.get(this, 'model.returnMessage');

        if (key) {
          strquery += `&key=${key}`;
        } else {
          strquery += `&key=*`;
        }

        if (returnMessage) strquery += `&message=${returnMessage}`;

        if (returnMessage != 'Respostas') {
          this.preloader.toggleClass('d-none');
          this.baseapi.getHttp(`${url}?${strquery}&type=whatsapp`).then(() => this.toast.success('', 'Relatório solicitado, aguarde alguns instantes e acesse a área de downloa clicando no ícone cinza', this.baseapi.toastDontClear()), () => this.toast.error('', 'Erro ao solicitar o relatório', this.baseapi.toastDontClear()));
          this.preloader.toggleClass('d-none');
        } else {
          this.preloader.toggleClass('d-none');
          this.baseapi.getHttp(`${urlSmsResponse}?${strquery}&type=whatsapp`).then(() => this.toast.success('', 'Relatório solicitado, aguarde alguns instantes e acesse a área de downloa clicando no ícone cinza', this.baseapi.toastDontClear()), () => this.toast.error('', 'Erro ao solicitar o relatório', this.baseapi.toastDontClear()));
          this.preloader.toggleClass('d-none');
        }
      },

      downloadAllAnalitycalXLS() {
        this.set('model.key', "*");
        this.send("downloadAnalitycalXLS");
      },

      listAnalitycalFiles() {
        this.get('taskListAnalitycalFiles').perform();
      },

      downloadFile(file) {
        this.baseapi.downloadRawFile(file);
      },

      loadContactResponse() {
        var dataItens = this.get('model.data.qtdStatus');
        dataItens.forEach((v, j) => Ember.$(`#status${j}`).removeClass('panel-active'));
        Ember.$(`#responseSms`).addClass('panel-active');
        this.set('model.key', '*');
        this.set('model.returnMessage', 'Respostas');
        this.set('model.filter.skip', 0);
        this.get('taskFilterContactResponse').perform(true);
      }

    }
  });

  _exports.default = _default;
});