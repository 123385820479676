define("printpost/controllers/organization/list", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    accountsOrganization: Ember.computed(function () {
      return null;
    }),
    organizationLayout: Ember.computed(function () {
      return null;
    }),
    layoutFileForVincule: Ember.computed(function () {
      return null;
    }),
    accountsForVincule: Ember.computed(function () {
      return [];
    }),
    showAddUser: Ember.computed(function () {
      return false;
    }),
    organizationId: Ember.computed(function () {
      return '';
    }),
    dataClient: Ember.computed(function () {
      return null;
    }).property().volatile(),
    dataSelectLayout: Ember.computed(function () {
      return null;
    }).property().volatile(),
    dataVinculeLayout: Ember.computed('dataVinculeLayout', function () {
      return {
        layoutFileId: '',
        name: '',
        module: ''
      };
    }),
    searchTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Accounts/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    searchLayout: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Layoutes/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][name]=${term}&filter[where][name][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    listOrganizationAccount: function (id) {
      let query = {
        filter: {
          where: {
            organizationId: id
          },
          include: ['account']
        }
      };
      let strquery = (0, _globalFunctions.apiLinkQuery)(query);
      let url = this.baseapi.apiLink('AccountOrganizations/list');
      this.preloader.toggleClass('d-none');
      this.baseapi.getHttp(url + '?' + strquery).then(resp => {
        Ember.set(this, 'accountsOrganization', resp.data);
        this.preloader.toggleClass('d-none');

        if (resp.data) {
          let ids_accounts = resp.data.map(x => {
            return x.account.id;
          });
          let query02 = {
            filter: {
              where: {
                id: {
                  nin: ids_accounts
                }
              }
            }
          };

          if (ids_accounts.length == 1) {
            query02.filter.where.id = {
              neq: resp.data[0].account.id
            };
          }

          let strquery02 = ids_accounts.length > 0 ? (0, _globalFunctions.apiLinkQuery)(query02) : '';
          let url_account = this.baseapi.apiLink('Accounts/list');
          this.preloader.toggleClass('d-none');
          this.baseapi.getHttp(url_account + '?' + strquery02).then(resp02 => {
            this.preloader.toggleClass('d-none');
            Ember.set(this, 'accountsForVincule', resp02.data);
          });
        }
      });
    },
    listOrganizationLayout: function (id) {
      let query = {
        filter: {
          where: {
            organizationId: id
          },
          include: ['layoutFile']
        }
      };
      let strquery = (0, _globalFunctions.apiLinkQuery)(query);
      let url = this.baseapi.apiLink('ClientLayouts/list');
      this.preloader.toggleClass('d-none');
      this.baseapi.getHttp(url + '?' + strquery).then(resp => {
        Ember.set(this, 'organizationLayout', resp.data);
        this.preloader.toggleClass('d-none');

        if (resp.data) {
          let ids_layouts = resp.data.map(x => {
            return x.layoutFileId;
          });
          let query02 = {
            filter: {
              where: {
                id: {
                  nin: ids_layouts
                }
              }
            }
          };

          if (ids_layouts.length == 1) {
            query02.filter.where.id = {
              neq: resp.data[0].layoutFileId
            };
          }

          let strquery02 = ids_layouts.length > 0 ? (0, _globalFunctions.apiLinkQuery)(query02) : '';
          let url_account = this.baseapi.apiLink('LayoutFiles/list');
          this.preloader.toggleClass('d-none');
          this.baseapi.getHttp(url_account + '?' + strquery02).then(resp02 => {
            this.preloader.toggleClass('d-none');
            Ember.set(this, 'layoutFileForVincule', resp02.data);
          });
        }
      });
    },
    actions: {
      saveAccountOrganization() {
        let usuario = Ember.get(this, 'dataClient');
        let organization_id = Ember.get(this, 'organizationId');

        if (!usuario.id || !organization_id) {
          this.toast.error("", "Selecione o usuário da empresa", {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let item = {
          "organizationId": organization_id,
          "accountId": usuario.id,
          "master": true
        };
        let _object = {
          data: {
            type: "AccountOrganization",
            attributes: item
          }
        };
        let url = this.baseapi.apiLink('AccountOrganizations');
        let __rp = _object; // delete __rp.data.attributes.id;

        let call = this.baseapi.post(url, __rp);
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');
          this.toast.success('Usuário vinculado!', 'Usuário vinculado com sucesso', {
            positionClass: 'toast-bottom-right'
          });
        }, error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('Usuário não vinculado!', 'Usuário não foi vinculado!', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      setCliente(item) {
        Ember.set(this, 'dataClient', item);
      },

      getOrganizationAccount(id) {
        Ember.set(this, 'organizationId', id);
        this.listOrganizationAccount(id);
      },

      removeUser(user_id) {
        let url = this.baseapi.apiLink(`AccountOrganizations/${user_id}`);
        let call = this.baseapi.delete(url);
        this.preloader.toggleClass('d-none');
        call.then(() => {
          this.preloader.toggleClass('d-none');
          this.toast.success('Usuário removido!', 'Usuário removido com sucesso', {
            positionClass: 'toast-bottom-right'
          });
        }, () => {
          this.preloader.toggleClass('d-none');
          this.toast.error('Usuário não removido!', 'Usuário não foi removido!', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      editUserCompany(userId) {
        this.transitionToRoute('usuarios.company', {
          queryParams: {
            id: userId
          }
        });
      },

      removeClientLayout(user_id) {
        let url = this.baseapi.apiLink(`ClientLayouts/${user_id}`);
        let call = this.baseapi.delete(url);
        this.preloader.toggleClass('d-none');
        call.then(() => {
          this.preloader.toggleClass('d-none');
          this.toast.success('Usuário removido!', 'Layout removido com sucesso', {
            positionClass: 'toast-bottom-right'
          });
        }, () => {
          this.preloader.toggleClass('d-none');
          this.toast.error('Usuário não removido!', 'Layout não foi removido!', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      editOrganizationLayout(clientLayout) {
        console.log('clientLayout', clientLayout); // this.transitionToRoute('usuarios.company', { queryParams: { id: userId}});
      },

      getOrganizationLayout(id) {
        Ember.set(this, 'organizationId', id);
        this.listOrganizationLayout(id);
      },

      setLayout(item) {
        Ember.set(this, 'dataSelectLayout', item);
        Ember.set(this, 'dataVinculeLayout.layoutFileId', item.id);
        Ember.set(this, 'dataVinculeLayout.module', item.module);
      },

      saveClientLayout() {
        let layout = Ember.get(this, 'dataVinculeLayout');
        let organization_id = Ember.get(this, 'organizationId');

        if (!layout.layoutFileId || !organization_id) {
          this.toast.error("", "Selecione o layout da empresa", {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let item = {
          "organizationId": organization_id,
          "layoutFileId": layout.layoutFileId,
          "module": layout.module,
          "name": layout.name,
          "active": true
        };
        let _object = {
          data: {
            type: "ClientLayout",
            attributes: item
          }
        };
        let url = this.baseapi.apiLink('ClientLayouts');
        let __rp = _object; // delete __rp.data.attributes.id;

        let call = this.baseapi.post(url, __rp);
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');
          this.toast.success('Layout vinculado!', 'Layout vinculado com sucesso', {
            positionClass: 'toast-bottom-right'
          });
          Ember.set(this, 'dataVinculeLayout', {
            layoutFileId: '',
            name: '',
            module: ''
          });
          Ember.set(this, 'dataSelectLayout', null);
        }, error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('Layout não vinculado!', 'Layout não foi vinculado!', {
            positionClass: 'toast-bottom-right'
          });
        });
      }

    }
  });

  _exports.default = _default;
});