define("printpost/routes/carteiras/list", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: async function () {
      let query = {
        filter: {
          order: 'id DESC',
          limit: 20
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url = this.baseapi.apiLink(`Wallets/wallet-map?${strquery}`);
      const data = await this.baseapi.getHttp(url).then(x => x.data);
      return Ember.RSVP.hash({
        wallets: data,
        walletData: {},
        tableColumns: [],
        peoplesList: [],
        walletsList: [],
        peopleFilter: null,
        totalPagePeoples: 1,
        unifyWallets: []
      });
    }
  });

  _exports.default = _default;
});