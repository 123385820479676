define("printpost/models/address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    owner: _emberData.default.attr('string'),
    endereco: _emberData.default.attr('string'),
    bairro: _emberData.default.attr('string'),
    numero: _emberData.default.attr('number'),
    complemento: _emberData.default.attr('string'),
    cep: _emberData.default.attr('string'),
    uf: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    cidade: _emberData.default.attr(),
    userId: _emberData.default.attr()
  });

  _exports.default = _default;
});