define("printpost/controllers/services/validation", ["exports", "printpost/controllers/services", "printpost/config/environment", "ember-concurrency"], function (_exports, _services, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _services.default.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    notification: Ember.inject.service('toast'),
    preloader: Ember.computed('preloader', function () {
      return $('#subpreloader');
    }).property().volatile(),
    // willDestroyElement() {
    //   console.log('destroy element');
    //   this.socket.disconnect();
    //   this._super(...arguments);
    // },
    socketFirstConnection: (0, _emberConcurrency.task)(function* (sockerId) {
      var newRequest = JSON.parse(localStorage.getItem('storage:request'));
      let urlValidation = this.baseapi.apiLink(`/Wallets/validation-sms/${newRequest.walletId}`);
      let urlSocket = this.baseapi.apiLink(`sockets/sio/${sockerId}?filter[where][id]=${newRequest.walletId}`);
      yield this.baseapi.getHttp(urlValidation).then(resp => resp.data).catch(error => {
        this.toast.error('', 'Erro, informe ao suporte', {
          positionClass: 'toast-bottom-right'
        });
      });
      yield this.baseapi.getHttp(urlSocket).then(resp => resp.data).catch(error => {
        this.toast.error('', 'Erro, informe ao suporte', {
          positionClass: 'toast-bottom-right'
        });
      });
      return '';
    }).keepLatest(),
    socketValidation: (0, _emberConcurrency.task)(function* () {
      var newRequest = JSON.parse(localStorage.getItem('storage:request'));
      let urlValidation = this.baseapi.apiLink(`/Wallets/validation-sms/${newRequest.walletId}`);
      this.set('newRequest', newRequest);
      yield this.baseapi.getHttp(urlValidation).then(resp => {
        resp.data;
        this.get('socketGetData').perform();
      }).catch(error => {
        this.toast.error('', 'Erro, informe ao suporte', {
          positionClass: 'toast-bottom-right'
        });
      });
      return '';
    }).keepLatest(),
    socketGetData: (0, _emberConcurrency.task)(function* () {
      var newRequest = JSON.parse(localStorage.getItem('storage:request'));
      let urlSocket = this.baseapi.apiLink(`sockets/sms-validation?filter[where][id]=${newRequest.walletId}`);
      var tmp_wppSuccess = this.get('whatsAppSuccess');
      var tmp_wppError = this.get('whatsAppError');
      var total = 0;

      while (total < newRequest.quantity.sms) {
        tmp_wppError = this.get('whatsAppError');
        tmp_wppSuccess = this.get('whatsAppSuccess');
        yield this.baseapi.getHttp(urlSocket).then(resp => {
          const msg = resp.data;
          let wppSuccess = {
            label: msg.qtdWhatss.having,
            style: Ember.String.htmlSafe(`width: ${msg.qtdWhatss.pixelsSuccess}px`)
          };
          let wppError = {
            label: msg.qtdWhatss.notHaving,
            style: Ember.String.htmlSafe(`width: ${msg.qtdWhatss.pixelsError}px`)
          };
          this.set('whatsAppSuccess', wppSuccess);
          this.set('whatsAppError', wppError);
          this.set('walletValidate', msg);
          total = msg.qtdWhatss.having + msg.qtdWhatss.notHaving;
          var percentageEconomy = (msg.qtdPhones - msg.qtdTotalValids) * 100 / msg.qtdPhones;
          if (percentageEconomy > 0) this.set('economyPercent', `Você economizou ${this.baseapi.numberToReal(percentageEconomy)}%`);
        }).catch(error => {
          this.toast.error('', 'Erro, informe ao suporte', {
            positionClass: 'toast-bottom-right'
          });
        });
        yield this.baseapi.sleep(500);
      }

      if (total == newRequest.quantity.sms) {
        if (this.get('newRequest.praticatePrices.sms.quantityExceded') > 0) {
          this.toast.warning('Atenção!', 'Existem mensagens com mais de 160 caracteres que serão cobradas adicionalmente', this.baseapi.toastDontClear());
        }

        this.toast.success('', 'Validação concluida com sucesso!', this.baseapi.toastDontClear());
      }

      return '';
    }).keepLatest(),
    walletValidate: Ember.computed('walletValidate', function () {
      return null;
    }).property().volatile(),
    whatsAppSuccess: Ember.computed('whatsAppSuccess', function () {
      return null;
    }).property().volatile(),
    whatsAppError: Ember.computed('whatsAppError', function () {
      return null;
    }).property().volatile(),
    economyPercent: Ember.computed('economyPercent', function () {
      return '';
    }).property().volatile(),
    flatMinDate: Ember.computed('flatMinDate', function () {
      var model = {
        carta: '',
        email: '',
        sms: ''
      };
      model.carta = new Date().setDate(new Date().getDate() + 2);
      model.email = new Date().setMinutes(new Date().getMinutes() + 30);
      model.sms = new Date().setMinutes(new Date().getMinutes() + 20);
      return model;
    }).property().volatile(),
    flatMaxDate: Ember.computed('flatMaxDate', function () {
      var model = {
        carta: '',
        email: '',
        sms: ''
      };
      model.carta = new Date().setMonth(new Date().getMonth() + 2, 1);
      model.email = new Date().setMonth(new Date().getMonth() + 2, 1);
      model.sms = new Date().setMonth(new Date().getMonth() + 2, 1);
      return model;
    }).property().volatile(),
    myFlatpickrRef: Ember.computed(function () {
      return {
        carta: null,
        email: null,
        sms: null
      };
    }),
    dateSendRequest: Ember.computed(function () {
      var model = {
        carta: '',
        email: '',
        sms: ''
      };
      var printOption = Ember.get(this, 'model.request.printingOptions');
      var imediate = {
        carta: printOption.templateLetter.disparoImediato,
        email: printOption.templateEmail.disparoImediato,
        sms: printOption.templateSMS.disparoImediato
      };
      let data_carta = !printOption.templateLetter.disparoImediato ? printOption.templateLetter.dataDisparo : this.baseapi.returnDate(3, true);
      let data_email = !printOption.templateEmail.disparoImediato ? printOption.templateEmail.dataDisparo : this.baseapi.returnDate(0, true);
      let data_sms = !printOption.templateSMS.disparoImediato ? printOption.templateSMS.dataDisparo : this.baseapi.returnDate(0, true);
      model.carta = data_carta;
      model.email = data_email;
      model.sms = data_sms;
      Ember.set(this, 'changeDateSendMod.originValues', model);
      Ember.set(this, 'changeDateSendMod.imediateValues', imediate);
      return model;
    }).property().volatile(),
    changeDateSendMod: Ember.computed(function () {
      let m = {
        mode: '',
        originValues: {
          carta: '',
          email: '',
          sms: ''
        },
        imediateValues: {
          carta: '',
          email: '',
          sms: ''
        },
        newValues: {
          carta: '',
          email: '',
          sms: ''
        },
        dataFlat: {
          carta: '',
          email: '',
          sms: ''
        }
      };
      return m;
    }),
    changeStorageRequestOptionsTask: function (chave, valor, template) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions[template][chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      let str = `newRequest.printingOptions.${template}.${chave}`;
      Ember.set(this, str, valor);
    },
    changeValidationsTask: function (chave, valor, template) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.validations[template][chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      let str = `newRequest.validations.${template}.${chave}`;
      Ember.set(this, str, valor);
    },
    contactsSend: Ember.computed('contactSend', function () {
      return {
        emailContact: '',
        smsContact: ''
      };
    }),
    actions: {
      navigate(url) {
        this.transitionToRoute(url);
      },

      bill: function () {
        let notification = this.get('notification');
        const USER = this.get('session.data.authenticated');
        let sNewRequest = JSON.parse(localStorage.getItem('storage:request'));
        let requestId = localStorage.getItem('newServiceRequestId');
        requestId = requestId == undefined ? 0 : requestId;

        if (sNewRequest.emailService && (!sNewRequest.emailSubject || !sNewRequest.emailContact)) {
          this.toast.error('', 'Não foi informado o ASSUNTO do e-mail ou o email de resposta.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (requestId) {
          sNewRequest.id = requestId;
        }

        if (!sNewRequest.walletId && sNewRequest.typeConfig == "pdf_fechado") {
          sNewRequest.walletId = 'static';
        }

        let objPost = {
          Request: sNewRequest
        };
        this.preloader.toggleClass('d-none');
        let url = this.baseapi.apiLink('Requests/bill');
        this.baseapi.post(url, objPost).then(resp => {
          if (resp.data.success) {
            this.preloader.toggleClass('d-none');
            let data = resp.data.success[0];
            let dataMsg = data && data.detail ? data.detail : "Pedido criado com sucesso!";
            this.send('clearDataRequest');
            notification.success('', dataMsg, {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('services.completed');
          } else {
            notification.error('', resp.data.error.message, {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
          }
        }, error => {
          this.variables.set('empty_resp', error);
          notification.error('', 'O pedido não pode ser criado!', {
            positionClass: 'toast-bottom-right'
          });
          this.preloader.toggleClass('d-none');
        });
      },

      clearDataRequest() {
        try {
          Ember.get(this, 'newRequest').clear();
        } catch (error) {}

        localStorage.removeItem('storage:request');
        localStorage.removeItem('newServiceRequestId');
        localStorage.removeItem('storage:wallet-columns');
        localStorage.removeItem('storage:excededSms');
        localStorage.removeItem('storage:template-selected');
        localStorage.removeItem('storage:request-options');
        localStorage.removeItem('storage:force-wallet');
        localStorage.removeItem('serviceLoad');
      },

      homeService() {
        localStorage.setItem('serviceLoad', true);
        this.send('navigate', 'services.new2');
      },

      changeFlatPicker: function (mod) {
        Ember.set(this, 'changeDateSendMod.mode', mod);
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f[mod].selectedDates.forEach(function (date) {
          dateFlat = date;
        });
        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = day + "/" + month + "/" + year + " - " + hour + ":" + minute;
        let data = Ember.get(this, 'changeDateSendMod.newValues');
        let dataFlat = Ember.get(this, 'changeDateSendMod.dataFlat');
        data[mod] = strDate;
        dataFlat[mod] = f[mod];
        let template = mod == 'sms' ? 'newRequest.printingOptions.templateSMS.dataDisparo' : mod == 'email' ? 'newRequest.printingOptions.templateEmail.dataDisparo' : 'newRequest.printingOptions.templateLetter.dataDisparo';
        let template2 = mod == 'sms' ? 'templateSMS' : mod == 'email' ? 'templateEmail' : 'templateLetter';
        let immediate = mod == 'sms' ? 'newRequest.printingOptions.templateSMS.disparoImediato' : mod == 'email' ? 'newRequest.printingOptions.templateEmail.disparoImediato' : 'newRequest.printingOptions.templateLetter.disparoImediato';
        let updateValue = '';
        Ember.set(this, template, strDate);
        Ember.set(this, `${template.replace('newRequest', 'model.request')}`, strDate);
        Ember.set(this, immediate, false);
        this.changeStorageRequestOptionsTask('dataDisparo', strDate, template2);
        this.changeStorageRequestOptionsTask('disparoImediato', false, template2);
        Ember.set(this, 'changeDateSendMod.newValues', data);
        Ember.set(this, 'changeDateSendMod.dataFlat', dataFlat);
      },
      sendSMSTest: function () {
        try {
          var request = JSON.parse(localStorage.getItem("storage:request"));
          var requestOptions = "templateSMS";
          var printingOptionsStorage = request.printingOptions[requestOptions];
          let walletId = Ember.get(this, 'newRequest.walletId');
          let _sms = printingOptionsStorage.frontHTML;
          let form = {};

          if (walletId && _sms && Ember.get(this, 'contactsSend.smsContact')) {
            form.send = Ember.get(this, 'contactsSend.smsContact');
          } else {
            this.toast.error('Erro!', 'Alguns campos não foram identificados.', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          form.walletId = walletId;
          form.requestId = localStorage.getItem("newServiceRequestId");
          let url = this.baseapi.apiLink('Sms/test');
          let r = this.baseapi.post(url, form);
          this.preloader.toggleClass('d-none');
          r.then(() => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Sucesso!', 'SMS enviado, verifique seu celular em instantes.', {
              positionClass: 'toast-bottom-right'
            });
          }).catch(error => {
            this.variables.set('empty_resp', error);
            this.preloader.toggleClass('d-none');
            this.toast.error('Erro!', 'Problemas ao enviar os testes.', {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (e) {
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },

      sendEmailTest() {
        try {
          var request = JSON.parse(localStorage.getItem("storage:request"));
          var requestOptions = "templateEmail";
          var printingOptionsStorage = request.printingOptions[requestOptions];
          let reply_to = Ember.get(this, 'newRequest.emailContact');
          let subject = Ember.get(this, 'newRequest.emailSubject'); // let printOptions = get(this, 'newRequest.printingOptions');

          let walletId = Ember.get(this, 'newRequest.walletId');
          let _html = printingOptionsStorage.frontHTML; // let form = new FormData();

          let form = {};

          if (walletId && _html && reply_to && subject && Ember.get(this, 'contactsSend.emailContact')) {
            let email_send = Ember.get(this, 'contactsSend.emailContact');
            form.send = email_send;
          } else {
            this.toast.error('Erro!', 'Alguns campos não foram identificados.', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          form.walletId = walletId;
          form.requestId = localStorage.getItem("newServiceRequestId");
          let url = this.baseapi.apiLink('Emails/test');
          let r = this.baseapi.post(url, form);
          this.preloader.toggleClass('d-none');
          r.then(response => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Sucesso!', 'Email enviado, verifique na sua caixa de entrada em instantes.', {
              positionClass: 'toast-bottom-right'
            });
          }).catch(error => {
            this.variables.set('empty_resp', error);
            this.preloader.toggleClass('d-none');
            this.toast.error('Erro!', 'Problemas ao enviar os testes.', {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (e) {
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },

      saveDateShipping() {},

      newConnect() {
        this.get('socketValidation').perform(); // websocket connection
        // var sio = io(config.APP.API.host, {
        //   withCredentials: true,
        //   extraHeaders: {
        //     "Origin": config.APP.API.host
        //   }
        // });
        // // console.log('sio.id:: ',sio, sio.r.id);
        // sio.on('chat message', (msg) => {
        //   console.log(`message receive :: `, msg);
        //   let wppSuccess = {
        //     label: msg.qtdWhatss.having,
        //     style: Ember.String.htmlSafe(`width: ${msg.qtdWhatss.pixelsSuccess}px`)
        //   }
        //   let wppError = {
        //     label: msg.qtdWhatss.notHaving,
        //     style: Ember.String.htmlSafe(`width: ${msg.qtdWhatss.pixelsError}px`)
        //   }
        //   this.set('whatsAppSuccess', wppSuccess);
        //   this.set('whatsAppError', wppError);
        //   this.set('walletValidate', msg);
        // });
        // sio.on('disconnect', (resp) => {
        //   console.log(`disconnected socket`);
        //   this.toast.success('', 'Validação do arquivo finalizada.', { positionClass: 'toast-bottom-right' });
        // });
        // sio.on('connect', () => {
        //   console.log('connected with id', sio.id);
        //   get(this, 'socketFirstConnection').perform(sio.id);
        // });
      },

      smsValidation(key) {
        let field = `newRequest.validations.sms.${key}`;
        let currentValue = this.get(field);
        let newValue = currentValue == 0 ? 1 : 0;
        this.changeValidationsTask(key, newValue, 'sms');
      },

      emailValidation(key) {
        let field = `newRequest.validations.email.${key}`;
        let currentValue = this.get(field);
        let newValue = currentValue == 0 ? 1 : 0;
        this.changeValidationsTask(key, newValue, 'email');
      },

      saveEmailSubject() {
        var v = this.get('emailSubject');
        this.set('model.request.emailSubject', v);
        this.get('changeStorageRequestTask').perform('emailSubject', v);
      }

    }
  });

  _exports.default = _default;
});