define("printpost/controllers/templates/edit", ["exports", "ember-concurrency", "printpost/utils/globalFunctions", "fetch", "printpost/config/environment"], function (_exports, _emberConcurrency, _globalFunctions, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DOMTinymce = [];

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    accountUser: Ember.computed('accountUser', function () {
      return this.session.data.authenticated.account;
    }),
    adminValue: Ember.computed('adminValue', function () {
      return this.baseapi.getAdmValue();
    }),
    textTinymceSMS: Ember.computed(function () {
      return {
        dados: '',
        options: {
          selector: 'textarea',
          // change this value according to your html
          min_height: 1000,
          min_width: 500,
          content_style: "div {margin: 10px 15px 10px 10px; padding: 3px 15px 0 0px}",
          menubar: false,
          //theme: 'modern', 
          body_class: 'iframe-sms-template',
          plugins: 'code template link',
          // note the comma at the end of the line!
          default_link_target: '_blank',
          toolbar: 'undo redo | link | cut copy paste selectall | code template ',
          templates: [{
            "title": "nome",
            "content": "{{ nome }}"
          }, {
            "title": "email",
            "content": "{{ email }}"
          }, {
            "title": "telefone",
            "content": "{{ telefone }}"
          }, {
            "title": "cpf",
            "content": "{{ cpf }}"
          }, {
            "title": "endereco",
            "content": "{{ endereco }}"
          }, {
            "title": "bairro",
            "content": "{{ bairro }}"
          }, {
            "title": "cep",
            "content": "{{ cep }}"
          }, {
            "title": "cidade",
            "content": "{{ cidade }}"
          }, {
            "title": "numero",
            "content": "{{ numero }}"
          }, {
            "title": "complemento",
            "content": "{{ complemento }}"
          }, {
            "title": "uf",
            "content": "{{ uf }}"
          }, {
            "title": "identificador",
            "content": "{{ identificador }}"
          }],
          setup: function (ed) {
            ed.on('init', function () {
              //var c = $(ed.getContainer()); // this would be the text area
              let iframe = Ember.$(ed.getContainer()).find('iframe');
              iframe.addClass('iframe-sms-template');
            });
            DOMTinymce.push(ed);
          }
        }
      };
    }),
    loadPlugin: function () {
      // Use run loop if you need to setup the DOM first
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$.getScript('https://d5aoblv5p04cg.cloudfront.net/editor-2/loader/build.js');
      });
    }.on('init'),
    selectedChannelReturn: Ember.computed(function () {
      return null;
    }).property().volatile(),
    dataItem: Ember.computed('model.item', function () {
      var model = this.get('model.item');
      this.set('model.selectedChannelReturn', model.channel);
      this.set('selectedProductReturn', model.product);
      this.set('cartaModeEdit', '');
      return model;
    }).property().volatile(),
    dataProducts: Ember.computed('model.products', function () {
      let list = this.get('model.products');
      return list;
    }),
    dataChannels: Ember.computed('model.channels', function () {
      let list = this.accountUser.type != this.adminValue ? this.get('model.channel') : this.get('model.channel').filter(x => x.channel != "carta");
      return list;
    }),
    fileAttach: Ember.computed('fileAttach', function () {
      return '';
    }).property().volatile(),
    sendRawFile: function (type) {
      var file = Ember.get(this, 'fileAttach');
      let form = new FormData(); // let reqId = localStorage.getItem('newServiceRequestId');

      form.set("requestId", null);
      form.set("type", "template_image");
      form.set("path", 'images/templates');
      form.append("file", file);
      let ext = this.baseapi.getFileExtension(file.name);
      let url = this.baseapi.apiLink("rawFiles");
      url += "/upload-file";
      this.preloader.toggleClass('d-none');
      this.baseapi.postFormData(url, form).then(rawObj => {
        this.preloader.toggleClass('d-none');
        Ember.set(this, 'fileAttach', '');
        this.toast.success('', 'Arquivo enviado com sucesso.', {
          positionClass: 'toast-bottom-right'
        });
        let data = rawObj.data;

        if (type == 1) {
          Ember.set(this, 'model.item.thumbsImage.frente', rawObj.data.link);
        } else {
          Ember.set(this, 'model.item.thumbsImage.verso', rawObj.data.link);
        }
      }, error => {
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao enviar o arquivo, tente novamente.', {
          positionClass: 'toast-bottom-right'
        });
      });
    },
    updateStoreTemplate: function (key, valor) {
      var obj = JSON.parse(localStorage.getItem('admin-templates'));
      obj[key] = valor;
      localStorage.setItem('admin-templates', JSON.stringify(obj));
    },
    searchTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Accounts/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return (0, _fetch.default)(url).then(resp => resp.json()).then(json => json);
    }),
    cartaModeEdit: Ember.computed(function () {
      return '';
    }),
    organizationForVincule: Ember.computed('organizationForVincule', function () {
      return [];
    }),
    searchOrganizationTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Organizations/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return (0, _fetch.default)(url).then(resp => resp.json()).then(json => json);
    }),
    actions: {
      salvar: function () {
        let item_data = Ember.get(this, 'dataItem');
        let item_user = Ember.get(this, 'model.dataClient');
        let organization = Ember.get(this, 'model.dataOrganization');
        let idOrganization = organization ? organization.id : null;
        var templateData = JSON.parse(localStorage.getItem("edited-templates"));
        var admin_templates = JSON.parse(localStorage.getItem("admin-templates"));
        var editor_mode = admin_templates.editor_mode;
        var obj = templateData[editor_mode];
        var objcarta = templateData['carta'];
        var objcartaverso = templateData['carta_verso'];
        let item = {
          "id": item_data.id,
          "name": item_data.name,
          "thumbsImage": {
            "frente": item_data.thumbsImage.frente,
            "verso": item_data.thumbsImage.verso
          },
          "internal": '',
          "externalHtml": '',
          "json": '',
          "externalJson": '',
          "productId": item_data.productId,
          "channelId": item_data.channelId,
          "allClients": item_data.allClients,
          "impressPre": item_data.impressPre,
          "editorMode": editor_mode,
          "channelName": item_data.channelName,
          "imageImpressPre": item_data.imageImpressPre,
          "userId": item_data.userId,
          "organizationId": idOrganization
        };

        if (editor_mode == "carta_verso" || editor_mode == "carta") {
          item.externalHtml = objcartaverso.html;
          item.internal = objcarta.html;
          item.externalJson = objcartaverso.json;
          item.json = objcarta.json;
        } else if (editor_mode != "carta_pre_impresso") {
          item.internal = obj.html;
          item.json = obj.json;
          item.externalJson = '';
          item.externalHtml = '';
        }

        if (editor_mode == "sms") {
          item.internal = item_data.internal;
          item.json = item_data.json;
          item.externalJson = '';
          item.externalHtml = '';
          console.log('editor_mode', editor_mode);
        }

        if (editor_mode == "carta_pre_impresso" && item_data.impressPre) {
          item.internal = templateData["carta_pre_impresso"].html;
          item.json = templateData["carta_pre_impresso"].json;
          item.externalJson = '';
          item.externalHtml = '';
        }

        if (editor_mode == "email") {
          item.internal = templateData["email"].html;
          item.json = templateData["email"].json;
          item.externalJson = '';
          item.externalHtml = '';
        }

        let _object = {
          data: {
            type: "Templates",
            attributes: item
          }
        };
        var validData = true; // if(!item.productId){
        //   validData = false
        //   this.toast.error("", "Selecione o produto do template", { positionClass: 'toast-bottom-right' });
        // }

        if (validData && (!item.json || !item.internal)) {
          validData = false;
          this.toast.error("", "Não foi encontrado o template, salve no editor e tente novamente", {
            positionClass: 'toast-bottom-right'
          });
        }

        if (validData && (editor_mode == "carta_verso" || editor_mode == "carta") && (!item.externalHtml || !item.externalJson)) {
          validData = false;
          this.toast.error("", "Não foi encontrado o template externo, salve no editor e tente novamente", {
            positionClass: 'toast-bottom-right'
          });
        }

        if (validData && !item.allClients && !item.userId) {
          validData = false;
          this.toast.error("", "Para um cliente específico, selecione um cliente para o template", {
            positionClass: 'toast-bottom-right'
          });
        }

        if (validData && editor_mode != "sms" && !item.thumbsImage.frente) {
          validData = false;
          this.toast.error("", "Miniatura do template não foi selecionada", {
            positionClass: 'toast-bottom-right'
          });
        }

        if (validData && (editor_mode == "carta_verso" || editor_mode == "carta") && !item.thumbsImage.verso) {
          validData = false;
          this.toast.error("", "É necessário selecionar a miniatura do verso", {
            positionClass: 'toast-bottom-right'
          });
        }

        if (validData && editor_mode == "carta_pre_impresso" && !item.imageImpressPre) {
          validData = false;
          this.toast.error("", "É necessário selecionar imagem do pré impresso que será removida", {
            positionClass: 'toast-bottom-right'
          });
        }

        if (validData) {
          // if (!item.id) {
          {
            let url = this.baseapi.apiLink('Templates/save');
            let __rp = _object; // delete __rp.data.attributes.id;

            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              localStorage.removeItem("edited-templates");
              localStorage.removeItem("admin-templates");
              this.transitionToRoute('templates.view');
            }, error => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
            });
          }
        }
      },
      setChannelIdSelect: function () {
        let item = this.baseapi.getFieldSelectValue("channel_selection");

        if (item != "0") {
          Ember.set(this, 'model.item.impressPre', false);
          Ember.set(this, 'model.item.channelId', item);
        }
      },
      setProductIdSelect: function () {
        let item = this.baseapi.getFieldSelectValue("product_selection");

        if (item != "0") {
          Ember.set(this, 'model.item.productId', item);
        }
      },
      setUserIdSelect: function () {
        let item = this.baseapi.getFieldSelectValue("product_selection");

        if (item != "0") {
          Ember.set(this, 'model.item.productId', item);
        }
      },
      apagar: function () {
        let item_data = Ember.get(this, 'dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink('Templates');
          let call = this.baseapi.delete(url + '/' + item_data.id);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('templates.list');
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },

      setTinymceValueFront(value) {
        Ember.set(this, 'model.item.internal', value);
        Ember.set(this, 'model.item.json', {
          valorHtml: value
        });
      },

      setTinymceValueBack(value) {
        if (value.indexOf("inicio_remetente") == -1) {
          value = '<!-- inicio_remetente -->' + '<img style="width:99.9%" src="https://printpost-dev.s3.us-east-2.amazonaws.com/images/templates/verso_enderecador.jpg">' + '<!-- fim_remetente --> <br> <br> <p>' + value + '<p>';
        }

        if (value.indexOf("ex_img_r") == -1) {
          value += '<img id="ex_img_r" style="width: 140px;position: absolute;right: 100px;margin-top: 60px;" >';
        }

        if (value.indexOf("ex_img_l") == -1) {
          value += '<img id="ex_img_l" style="width: 140px;margin-left: 100px;margin-top: 50px;position: absolute;" >';
        }

        Ember.set(this, 'model.item.externalHtml', value);
      },

      setFileAttach: function (type, data) {
        Ember.set(this, 'fileAttach', data.target.files[0]);
        this.sendRawFile(type);
      },

      uploadFile() {},

      showEdit(mode) {
        TopolPlugin.destroy();
        Ember.set(this, 'cartaModeEdit', mode);

        if (mode == "back") {
          this.updateStoreTemplate("editor_mode", "carta_verso");
        } else if (mode == "front") {
          this.updateStoreTemplate("editor_mode", "carta");
        } else if (mode == "carta_pre_impresso") {
          this.updateStoreTemplate("editor_mode", "carta_pre_impresso");
        }
      },

      setChannel(item) {
        TopolPlugin.destroy();
        Ember.set(this, 'model.item.channelId', item.id);
        Ember.set(this, 'model.item.channel', item);
        Ember.set(this, 'model.item.channelName', item.channel);
        Ember.set(this, 'model.selectedChannelReturn', item);
        this.updateStoreTemplate("editor_mode", item.channel);
      },

      setProduct(item) {
        Ember.set(this, 'model.item.productId', item.id);
        Ember.set(this, 'selectedProductReturn', item);
      },

      setImpressPre(item) {
        TopolPlugin.destroy();
        Ember.set(this, 'model.item.impressPre', item);

        if (!item) {
          Ember.set(this, 'model.item.editorMode', 'carta');
          this.updateStoreTemplate("editor_mode", "carta");
        } else {
          Ember.set(this, 'model.item.editorMode', 'carta_pre_impresso');
          this.updateStoreTemplate("editor_mode", "carta_pre_impresso");
        }
      },

      setDisponivel(item) {
        Ember.set(this, 'model.item.allClients', item);
      },

      setCliente(item) {
        Ember.set(this, 'model.dataClient', item);
        Ember.set(this, 'model.item.userId', item.id);
      },

      testAction() {
        var filter = {
          filter: {
            where: {
              title: {
                like: `*.jon.*`,
                options: "i"
              }
            }
          }
        };
        var strquery = (0, _globalFunctions.apiLinkQuery)(filter);
      },

      downloadMakeTemplate: function () {
        try {
          let templateData = JSON.parse(localStorage.getItem('edited-templates'));
          let admin_templates = JSON.parse(localStorage.getItem('admin-templates'));
          var editor_mode = admin_templates.editor_mode;
          var obj = templateData[editor_mode];
          var objcarta = templateData['carta'];
          var objcartaverso = templateData['carta_verso'];
          let form = new FormData();
          let html = "";

          if (editor_mode == "carta_verso" || editor_mode == "carta") {
            html = objcarta.html + "<footer></footer>" + objcartaverso.html;
          } else if (editor_mode != "carta_pre_impresso") {
            html = obj.html;
          }

          if (editor_mode == "carta_pre_impresso") {
            html = templateData["carta_pre_impresso"].html;
          } // let _html = printOptions.templateLetter.frontHTML + '<footer></footer>' + printOptions.templateLetter.backHTML


          form.set("html", html);
          form.set("walletId", "admin-template-test");
          let host = _environment.default.APP.API.host_py;
          let token = this.get('session.data.authenticated.id');
          let url = host + '/api/generatep/';
          let r = this.baseapi.postFormData(url + '?access_token=' + token, form, false);
          this.preloader.toggleClass('d-none');
          r.then(response => {
            this.preloader.toggleClass('d-none');
            let data = response.data;
            this.baseapi.getDownload(url + 'view?file=' + data, 'kkkk.pdf', false);
          }).catch(error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Erro!', 'Problemas ao tratar o template.', {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (e) {
          this.toast.error('Erro!', 'Ocorreu algum problema ao baixar seu arquivo.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },

      findImages() {},

      selectedImagePreImpress(item) {
        Ember.set(this, 'model.item.imageImpressPre', item.link);
      },

      setOrganization(item) {
        Ember.set(this, 'model.dataOrganization', item);
      },

      duplicateTemplate(id) {
        let url = this.baseapi.apiLink(`Templates/${id}/duplicate`);
        let call = this.baseapi.post(url, {});
        this.preloader.toggleClass('d-none');
        call.then(response => {
          this.preloader.toggleClass('d-none');
          let data = response.data;
          this.toast.success('', 'Template duplicado com sucesso.', {
            positionClass: 'toast-bottom-right'
          });
        }).catch(error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Problemas ao duplicar o template.', {
            positionClass: 'toast-bottom-right'
          });
        });
      }

    }
  });

  _exports.default = _default;
});