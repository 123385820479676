define("printpost/controllers/menus/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    data: Ember.computed('model', function () {
      var model = this.get('model.data');
      return model[0];
    }),
    actions: {
      goToLink: function (item) {
        this.transitionToRoute(item);
      },
      ativarMenu: function (id, val) {
        let menuId = id == undefined ? 0 : id;
        let list = Ember.get(this, 'data').objects;
        let item = list.find(x => x.id == id);

        if (item) {
          Ember.set(item, 'active', !val);
          let __menu = {
            data: {
              type: "Menu",
              id: menuId,
              attributes: item
            }
          };
          delete __menu.data.attributes.id;
          let url = this.baseapi.apiLink('Menus/' + menuId);
          let call = this.baseapi.patch(url, __menu);
          call.then(resp => {
            //not to do
            Ember.set(item, 'id', menuId);
            this.toast.success('Atualizado!', 'Menu atualizado com sucesso!', {
              positionClass: 'toast-bottom-right'
            });
          }, error => {
            console.log(error);
          });
        }
      }
    }
  });

  _exports.default = _default;
});