define("printpost/routes/invoice/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: async function () {
      let objDate = new Date();
      var currentDate = objDate;
      var url = this.baseapi.apiLink('Invoices/months');
      const listData = await this.baseapi.getHttp(url).then(r => r.data);
      var listDateDisable = this.baseapi.getWeekendDays(2);
      listDateDisable.forEach(x => x = x.replace(' 00:00'));
      return Ember.RSVP.hash({
        dataMonths: listData,
        area: 1,
        dataInvoices: [],
        dataDateFilter: currentDate,
        dataDateFilterStr: '',
        invoicesGroupAttach: [],
        dueDate: '',
        flatpickerDateDisable: listDateDisable
      });
    }
  });

  _exports.default = _default;
});