define("printpost/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "printpost/config/environment", "ember-inflector"], function (_exports, _emberData, _dataAdapterMixin, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*import DS from 'ember-data';
  
  export default DS.RESTAdapter.extend({
    host: 'http://localhost:3000',
    namespace: 'api',
    defaultSerializer: 'JSONSerializer'
  });
  */
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;
  const api = _environment.default.APP.API.host;
  const namespace = _environment.default.APP.API.namespace;

  var _default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    'authorizer': 'authorizer:loopback',
    'namespace': namespace,
    'host': api,

    pathForType(type) {
      return (0, _emberInflector.pluralize)(Ember.String.classify(type));
    }

  });

  _exports.default = _default;
});