define("printpost/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c6G3utRM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"body-wrapper\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showLayout\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"      \"],[15,\"navbar\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n        \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"      \"],[4,\"app-footer\",null,null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[4,\"pre-loader\",null,null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\\n\\n\"],[7,\"script\"],[9],[0,\"\\n    $(function () {\\n       $('[data-toggle=\\\"tooltip\\\"]').tooltip()\\n    })\\n\"],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "printpost/templates/application.hbs"
    }
  });

  _exports.default = _default;
});