define("printpost/controllers/services/letter", ["exports", "printpost/controllers/services", "ember-concurrency", "printpost/config/environment", "printpost/controllers/carteiras/new", "printpost/utils/globalFunctions"], function (_exports, _services, _emberConcurrency, _environment, _new2, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DOMTinymce = [];
  var logos_positions = ['none', '#img_tl', '#img_tr', '#img_bl', '#img_br', '#img_marcadagua'];
  var logos_positions_externo = ['none', '#ex_img_l', '#ex_img_r'];
  let hasRotateTemplate = false;

  var _default = _services.default.extend({
    loadPlugin: function () {
      // Use run loop if you need to setup the DOM first
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$.getScript('https://d5aoblv5p04cg.cloudfront.net/editor-2/loader/build.js');
      });
    }.on('init'),
    test: Ember.inject.service('test-service'),
    dataFilter: Ember.computed('filter', function () {
      return [];
    }),
    dataProducts: Ember.computed('model.products', function () {
      var model = this.get('model.products');
      return model;
    }),
    dataTemplates: Ember.computed('model.templates', function () {
      var model = this.get('model.templates');
      return model;
    }),
    dataChannel: Ember.computed('model.channel', function () {
      var model = this.get('model.channel');
      return model;
    }),
    dataHtml: Ember.computed('dataHtml', function () {
      return {
        frontHTML: '',
        backHTML: ''
      };
    }),
    dataTemplate: Ember.computed('dataTemplate', function () {
      return {};
    }),
    reloadTemplate: Ember.computed('reloadTemplate', function () {
      return true;
    }),
    intro_exec: Ember.computed(function () {
      return 1;
    }),
    baseapi: Ember.inject.service(),
    newFile: Ember.computed(function () {
      return null;
    }),
    selectLogo: Ember.computed(function () {
      return false;
    }),
    templateLetter: Ember.computed(function () {
      let t = Ember.get(this, 'newRequest.printingOptions.templateLetter');
      return t;
    }),
    remetente: Ember.computed(function () {
      return {
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        complemento: '',
        cidadecep: ''
      };
    }),
    tagsControl: Ember.computed(function () {
      let data = [];
      let wd = Ember.get(this, 'walletColumns.data');
      wd.forEach(element => {
        let item = {
          title: element,
          content: "{{ " + element + " }}"
        };
        data.push(item);
      });
      return data;
    }),
    queryParams: Ember.computed(function () {
      return ['product', 'wallet', 'size'];
    }),
    product: Ember.computed(function () {
      return null;
    }),
    templates: Ember.computed(function () {
      return [];
    }),
    htmlTemplate: Ember.computed(function () {
      return {
        frente: '<h1 style="text-align: center;">FRENTE</h1>',
        verso: '<h1 style="text-align: center;">VERSO</h1>'
      };
    }),
    templateSelected: Ember.computed(function () {
      return null;
    }),
    isShowingTranslucent: Ember.computed(function () {
      return false;
    }),
    myFlatpickrRef: Ember.computed(function () {
      return null;
    }),
    // filterModels: computed('model.products', function () {
    //   return this.get('model.products');
    // }),
    requestPrintingOption: Ember.computed(function () {
      let npo = Ember.get(this, 'newRequest.printingOptions');
      return npo;
    }),
    findTemplate: (0, _emberConcurrency.task)(function* (filter_query) {
      return yield this.get('store').query('template', {
        filter: filter_query
      }); // return request;    
    }).keepLatest(),
    selectTemplate: (0, _emberConcurrency.task)(function* () {
      let _remetente = Ember.get(this, 'remetente');

      if (_remetente.cep != '' && _remetente.cep.length > 1) {
        Ember.set(this.newRequest, 'address', _remetente);
      } //let printing = this.store.createRecord('printing-option', hash);
      //set(this.newRequest, 'printingOptions', printing.get('id'));
      //printing.save();
      // yield set(this.newRequest, 'letterTemplateId', this.templateLetter.templateId);


      yield Ember.set(this.newPrintingOptions, 'templateLetter', this.templateLetter); // let npo = get(this, 'newRequest.printingOptions');
      // yield set(npo, 'templateLetter', this.templateLetter);
      // set(this.newRequest,'printingOptions', npo);
    }).keepLatest(),
    // nextItem: function() {
    // }.property(),
    trasitionTask: (0, _emberConcurrency.task)(function* () {
      let smsService = this.get('newRequest.smsService');
      let emailService = this.get('newRequest.emailService');
      let nextWallet = this.get('newRequest.walletId');
      let nr = this.get('newRequest.typeConfig');
      TopolPlugin.destroy();

      if (nextWallet != 'static') {
        let reqId = localStorage.getItem('newServiceRequestId');
        let url_wallet = this.baseapi.apiLink('Wallets/find-tags');
        const objReturn = yield this.baseapi.getHttp(`${url_wallet}/${nextWallet}?requestId=${reqId}`).then(r => r.data);
        var tags = [];

        for (let j = 0; j < objReturn.length; j++) {
          const column = objReturn[j];
          var obj = {
            value: `[${column.title}]`,
            text: column.title,
            label: column.title
          };
          tags.push(obj);
        }

        Ember.set(this, 'storageTemplateSelected.tags', tags);
      } // this.send('salvarPendente');


      if (smsService) {
        yield this.transitionToRoute('services.sms', {
          queryParams: {
            wallet: nextWallet
          }
        });
      } else if (emailService) {
        Ember.set(this, 'storageTemplateSelected.editor_mode', 'email');
        yield this.transitionToRoute('services.email', {
          queryParams: {
            wallet: nextWallet
          }
        });
      } else if (nr != "file_layout") {
        yield this.transitionToRoute('services.test', {
          queryParams: {
            wallet: nextWallet
          }
        });
      } else {
        yield this.transitionToRoute('services.payment', {
          queryParams: {
            wallet: nextWallet
          }
        });
      }
    }).maxConcurrency(3).enqueue(),
    checkTemplateTags: (0, _emberConcurrency.task)(function () {
      if (this.tagsControl) {
        this.tagsControl.forEach((val, index) => {
          if (Ember.get(this, 'templateLetter.frontHTML').indexOf(val.content) != -1) {
            Ember.$('#' + index + "_front").addClass('taghaving-active');
            Ember.$('#' + index + "_back").addClass('taghaving-active');
          } else if (Ember.get(this, 'templateLetter.backHTML').indexOf(val.content) != -1) {
            Ember.$('#' + index + "_front").addClass('taghaving-active');
            Ember.$('#' + index + "_back").addClass('taghaving-active');
          } else {
            Ember.$('#' + index + "_front").removeClass('taghaving-active');
            Ember.$('#' + index + "_back").removeClass('taghaving-active');
          }
        });
      }
    }),
    checkTemplateTagsFunc: function () {
      if (this.tagsControl) {
        this.tagsControl.forEach((val, index) => {
          if (Ember.get(this, 'templateLetter.frontHTML').indexOf(val.content) != -1) {
            Ember.$('#' + index + "_front").addClass('taghaving-active');
            Ember.$('#' + index + "_back").addClass('taghaving-active');
          } else if (Ember.get(this, 'templateLetter.backHTML').indexOf(val.content) != -1) {
            Ember.$('#' + index + "_front").addClass('taghaving-active');
            Ember.$('#' + index + "_back").addClass('taghaving-active');
          } else {
            Ember.$('#' + index + "_front").removeClass('taghaving-active');
            Ember.$('#' + index + "_back").removeClass('taghaving-active');
          }
        });
      }
    },
    changeStorageRequestFunc: function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions.templateLetter[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.printingOptions.templateLetter.' + chave, valor);
    },
    changeStorageRequestTask: (0, _emberConcurrency.task)(function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions.templateLetter[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.printingOptions.templateLetter.' + chave, valor);
    }).keepLatest(),
    getStorageRequestTask: (0, _emberConcurrency.task)(function (chave) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      return _newReq.printingOptions.templateLetter[chave];
    }),
    flatMinDate: Ember.computed('flatMinDate', function () {
      return new Date().setDate(new Date().getDate() + 2);
    }),
    flatMaxDate: Ember.computed('flatMaxDate', function () {
      return new Date().setMonth(new Date().getMonth() + 2, 1);
    }),
    templatesDefault: Ember.computed('templatesDefault', function () {
      let m = Ember.get(this, 'templateZero');
      return m;
    }),
    template: Ember.computed('template', function () {
      let m = Ember.get(this, 'templateSelected');
      return m;
    }),
    editFront: Ember.computed(function () {
      let m = true;
      return m;
    }),
    nextRouterTask: (0, _emberConcurrency.task)(function* () {
      let nr = Ember.get(this, 'newRequest.typeConfig');
      let nextWallet = this.get('newRequest.walletId');

      if (nr == 'pdf_fechado') {
        this.transitionToRoute('services.data');
      } else if (nr == 'models_templates' || nr == 'file_layout') {
        //route models #2
        let npo = Ember.get(this, 'newRequest.printingOptions');
        let step = Ember.get(this, 'newRequest.step');
        let sms = Ember.get(this, 'newRequest.smsService');
        let email = Ember.get(this, 'newRequest.emailService');

        if (!sms && !email) {
          this.transitionToRoute('services.info');
        } else if (sms) {
          this.transitionToRoute('services.sms', {
            queryParams: {
              wallet: nextWallet
            }
          });
        } else if (email) {
          this.transitionToRoute('services.email', {
            queryParams: {
              wallet: nextWallet
            }
          });
        } else {
          this.transitionToRoute('services.info');
        }
      } else if (nr == 'creation') {
        // model creation
        this.transitionToRoute('services.data');
      }
    }),
    actions: {
      selectTemplateStep2: function () {
        var order = this.test.verifySendOrder();
        let dataRequest = JSON.parse(localStorage.getItem('storage:request'));
        Ember.set(this, 'newRequest.printingOptions', dataRequest.printingOptions); // this.send('salvarPendente');

        Ember.get(this, 'selectTemplate').perform().then(() => {
          TopolPlugin.destroy();
          this.toast.success('', 'Template selecionada com sucesso, aguarde até ser direcionado.', {
            positionClass: 'toast-bottom-right'
          });
          Ember.get(this, 'trasitionTask').perform();
        });
      },
      doRefresh: function () {
        window.location.reload(true);
      },
      setProdSelect: function () {
        let id_prod = this.baseapi.getFieldSelectValue("product_selection");
        Ember.$('#selection-id').val(id_prod);
        this.changeStorageRequestFunc('productId', id_prod);
        Ember.set(this, 'templateLetter.productId', id_prod);
        Ember.$("#next-modalidade").removeAttr('disabled');
      },
      changeSizeParams: function (size) {
        this.transitionToRoute('services.letter', {
          queryParams: {
            size: size
          }
        });
        Ember.getOwner(this).lookup('route:services.letter').refresh();
      },
      setFileLogo: function (file) {
        Ember.get(this, 'setFileLogo').perform(file).then();
      },
      rotateTemplate: function () {
        if (!hasRotateTemplate) {
          Ember.$('.box-waiting-rotate').toggleClass('flip-box-rotate-right');
          hasRotateTemplate = true;
        } else {
          Ember.$('.box-waiting-rotate').toggleClass('flip-box-rotate-right');
          hasRotateTemplate = false;
        }

        return;
      },
      transition: function () {
        let product_id = Ember.$('#selection-id').val();
        let query = {
          where: {
            'channelId': '5c3f713ce1c9855370e3e02e',
            'productId': product_id
          },
          skip: 0,
          limit: 12,
          order: "_id DESC"
        };
        Ember.get(this, 'findTemplate').perform(query);
      },
      setTemplate: function (id_template) {
        if (id_template.length > 1) {
          Ember.$("#next-template").removeAttr('disabled');
        } else {
          Ember.$("#next-template").attr('disabled', 'disabled');
        }

        this.changeStorageRequestFunc('templateId', id_template);
      },

      changeLogo() {
        //$('#logo64').val();
        let logo_64 = Ember.$('#logo64').val();
        let val_posicao_logo = Ember.$('#val_posicao_logo').val(); // console.log(DOMTinymce);

        let iframe = Ember.$(DOMTinymce[0].getContainer()).find('iframe');

        if (val_posicao_logo >= logos_positions.length) {
          val_posicao_logo = 0;
        }

        logos_positions.forEach((item, index) => {
          let imgsLogo = iframe.contents().find("img" + item);
          Ember.$(imgsLogo).removeAttr('src');

          if (index == val_posicao_logo && index >= 1) {
            Ember.$(imgsLogo).attr('src', logo_64);
          }
        });
        val_posicao_logo++;
        Ember.$('#val_posicao_logo').val(val_posicao_logo);
        Ember.$('#posicao_logo').text('Posição da Logo (' + val_posicao_logo + ')');

        if (val_posicao_logo == 1) {
          Ember.$('#posicao_logo').text('Posição da Logo (Sem logo)');
        } //$(imgsLogo).attr('src', 'data:image/jpg;base64,' + logo_64); 


        return;
      },

      changeLogoExterno() {
        //$('#logo64').val();
        let logo_64_ex = Ember.$('#logo64').val();
        let val_posicao_logo_ex = Ember.$('#val_posicao_logo_externo').val(); // console.log(DOMTinymce);

        let iframe_ex = Ember.$(DOMTinymce[1].getContainer()).find('iframe');

        if (val_posicao_logo_ex >= logos_positions_externo.length) {
          val_posicao_logo_ex = 0;
        }

        logos_positions_externo.forEach((item, index) => {
          let imgsLogo_ex = iframe_ex.contents().find("img" + item);
          Ember.$(imgsLogo_ex).removeAttr('src');

          if (index == val_posicao_logo_ex && index >= 1) {
            Ember.$(imgsLogo_ex).attr('src', logo_64_ex);
          }
        });
        val_posicao_logo_ex++;
        Ember.$('#val_posicao_logo_externo').val(val_posicao_logo_ex);
        Ember.$('#posicao_logo_externo').text('Posição da Logo (' + val_posicao_logo_ex + ')');

        if (val_posicao_logo_ex == 1) {
          Ember.$('#posicao_logo_externo').text('Posição da Logo (Sem logo)');
        } //$(imgsLogo).attr('src', 'data:image/jpg;base64,' + logo_64); 


        return;
      },

      buscaCEP() {
        let t = Ember.$('#cep_remetente').val();
        let url = "//viacep.com.br/ws/" + t + "/json";
        let r = this.baseapi.getHttp(url, false);
        r.then(response => {
          let data = response.data;
          Ember.set(this, 'remetente.endereco', data.logradouro);
          Ember.set(this, 'remetente.bairro', data.bairro);
          Ember.set(this, 'remetente.complemento', data.complemento);
          Ember.set(this, 'remetente.cidade', data.localidade);
          Ember.set(this, 'remetente.uf', data.uf);
          Ember.set(this, 'remetente.cidadecep', data.localidade + ' - ' + data.uf);
        });
      },

      changeStorageRequest(chave, valor) {
        this.changeStorageRequestFunc(chave, valor);
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });
        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = day + "/" + month + "/" + year + " - " + hour + ":" + minute;
        this.changeStorageRequestFunc('dataDisparo', strDate);
      },

      changeCheckbox(item, tipo, val) {
        let df = Ember.get(this, 'dataFilter');

        if (val) {
          let o = {};
          o[tipo] = item;
          df.push(o);
        } else {
          for (let i = 0; i < df.length; i++) {
            const element = df[i];

            if (element[tipo] == item) {
              df.splice(i, 1);
            }
          }
        }

        Ember.set(this, 'dataFilter', df);
      },

      filterTemplate: async function () {
        let r = Ember.get(this, 'dataFilter');
        let channel = Ember.get(this, 'dataChannel');
        let query = {
          filter: {
            where: {
              "channelId": channel.id
            },
            include: 'channel'
          }
        };

        if (r.length >= 1) {
          query.filter.where.or = [];
          r.forEach(item => {
            if (item.productId) {
              query.filter.where.or.push({
                "productId": item.productId
              });
            }
          });
        }

        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        var url_templates = this.baseapi.apiLink('Templates/list');
        const obj_templates = await this.baseapi.getHttp(url_templates + "?" + strquery).then(r => r.data);
        Ember.set(this, 'dataTemplates', obj_templates);
      },
      selectTemplate: async function (id) {
        if (id != "construir_do_zero") {
          Ember.set(this, 'reloadTemplate', false);
          let query = {
            filter: {
              where: {
                "id": id
              },
              fields: {
                name: false
              }
            }
          };
          let strquery = (0, _globalFunctions.apiLinkQuery)(query);
          var url_templates = this.baseapi.apiLink('Templates/list');
          const obj = await this.baseapi.getHttp(url_templates + "?" + strquery).then(r => r.data[0]);
          Ember.set(this, 'dataTemplate', obj);
          Ember.set(this, 'dataHtml.frontHTML', obj.internal);
          Ember.set(this, 'dataHtml.backHTML', obj.externalHtml);
          var tmplSelect = {
            html: '',
            json: obj.json
          };
          var tmplSelectVerso = {
            html: '',
            json: obj.externalJson
          };
          Ember.set(this, 'storageTemplateSelected.editor_mode', 'carta');
          Ember.set(this, 'storageTemplateSelected.carta', tmplSelect);
          Ember.set(this, 'storageTemplateSelected.carta_verso', tmplSelectVerso);
          Ember.set(this, 'reloadTemplate', true);
        } else if (id == "construir_do_zero") {
          var data = Ember.get(this, 'templateZero.carta');
          var data_verso = Ember.get(this, 'templateZero.carta_verso');
          var obj = {
            html: '',
            json: data
          };
          var obj_verso = {
            html: '',
            json: data_verso
          };
          Ember.set(this, 'storageTemplateSelected.editor_mode', 'carta');
          Ember.set(this, 'storageTemplateSelected.carta', obj);
          Ember.set(this, 'storageTemplateSelected.carta_verso', obj_verso); // var tags = [];
          // var columns = get(this, 'walletColumns.data');
          // for (let index = 0; index < columns.length; index++) {
          //   const item = columns[index];
          //   var obj = {
          //       value: `[[${item}]]`,
          //       text: item,
          //       label: item
          //   }
          //   tags.push(obj);            
          // }
          // set(this, 'storageTemplateSelected.tags', tags);

          this.changeStorageRequestFunc('templateId', 'construir_do_zero');
          this.send('nextRouter');
        }
      },
      defineTemplate: function () {
        let t = Ember.get(this, 'dataTemplate');
        var editor_mode = t.editorMode;
        var objempty = {
          html: '',
          json: ''
        };
        var obj = {
          html: t.internal,
          json: t.json
        };
        var objverso = {
          html: t.externalHtml,
          json: t.externalJson
        };
        Ember.set(this, 'storageTemplateSelected.editor_mode', editor_mode);
        let pto = this.get('newRequest.printingOptions');
        Ember.set(pto, 'templateLetter.editorMode', editor_mode);
        this.set('newRequest.printingOptions', pto);

        if (editor_mode == "carta" || editor_mode == "carta_verso") {
          Ember.set(this, 'storageTemplateSelected.carta', obj);
          Ember.set(this, 'storageTemplateSelected.carta_verso', objverso);
        } else if (editor_mode == "carta_pre_impresso") {
          Ember.set(this, 'storageTemplateSelected.carta', obj);
          Ember.set(this, 'storageTemplateSelected.carta_pre_impresso', obj);
          Ember.set(this, 'storageTemplateSelected.carta_verso', objempty);
        }

        this.changeStorageRequestFunc('frontHTML', t.internal);
        this.changeStorageRequestFunc('backHTML', t.externalHtml);
        this.changeStorageRequestFunc('disparoImediato', true);
        this.changeStorageRequestFunc('productId', t.productId);
        this.changeStorageRequestFunc('templateId', t.id);
        this.send('nextRouter');
      },
      nextRouter: function () {
        Ember.get(this, 'nextRouterTask').perform();
      },
      checkTemplateTag: function () {
        let t = Ember.get(this, 'tagsControl');

        if (t) {
          t.forEach((val, index) => {
            if (Ember.get(this, 'templateLetter.frontHTML').indexOf(val.content) != -1) {
              Ember.$('#' + index + "_front").addClass('taghaving-active');
            } else {
              Ember.$('#' + index + "_front").removeClass('taghaving-active');
            }
          });
        }
      },

      backSelectTemplate() {
        Ember.set(this, 'newRequest.step', 1);
        TopolPlugin.destroy();
        this.transitionToRoute('services.config');
      },

      editTemplate: function (val) {
        TopolPlugin.destroy();
        this.set('model.beginEdit', true);

        if (val == 'front') {
          Ember.set(this, 'editFront', true);
        } else {
          Ember.set(this, 'editFront', false);
        }
      },
      selectAndDefineTemplate: async function (template) {
        if (template) {
          if (template == "construir_do_zero") {
            Ember.set(this, 'dataHtml.frontHTML', '');
            Ember.set(this, 'dataHtml.backHTML', '');
            this.changeStorageRequestFunc('templateId', 'construir_do_zero');
            this.send('nextRouter');
            return;
          }

          this.changeStorageRequestFunc('frontHTML', template.internal);
          this.changeStorageRequestFunc('backHTML', template.externalHtml);
          this.changeStorageRequestFunc('disparoImediato', true);
          this.changeStorageRequestFunc('productId', template.productId);
          this.changeStorageRequestFunc('templateId', template.id);
          this.changeStorageRequestFunc('editorMode', 'carta');
          this.send('nextRouter'); // console.log('depois after route');
        }
      }
    }
  });

  _exports.default = _default;
});