define("printpost/routes/services/data", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    model: async function () {
      var url = this.baseapi.apiLink('Wallets/list');
      var saida = [];
      const obj = await this.baseapi.getHttp(url).then(r => r.data);
      var reqId = localStorage.getItem('newServiceRequestId');
      var dataRawFiles = [];

      if (reqId) {
        var urlRawFiles = this.baseapi.apiLink('rawFiles/list');
        var query = {
          filter: {
            where: {
              requestId: reqId
            }
          }
        };
        var strquery = (0, _globalFunctions.apiLinkQuery)(query);
        urlRawFiles += "?" + strquery;
        dataRawFiles = await this.baseapi.getHttp(urlRawFiles).then(rf => rf.data);
      } // let r = this.get('findWalletTask').perform();
      // console.log(r);


      return Ember.RSVP.hash({
        wallet: obj,
        requestAttachs: dataRawFiles
      });
    } // findWalletTask:task(function *(){
    //     const USER = this.get('session.data.authenticated');
    //     return yield this.get('store').query('wallet',{
    //       filter: {
    //         where: { },
    //         order: 'id DESC'
    //       },
    //     });
    //   }).keepLatest(),

  });

  _exports.default = _default;
});