define("printpost/routes/b", ["exports", "printpost/mixins/open-route-mixin", "axios"], function (_exports, _openRouteMixin, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_openRouteMixin.default, {
    hasLayout: false,
    baseapi: Ember.inject.service(),
    templateName: 'v',
    model: async function (params) {
      let url = this.baseapi.apiLink('Emails/template');
      console.log(params);
      var data = await _axios.default.get(`${url}/${params.b_id}`, {
        headers: {
          'x-api-key': 'showApplication',
          'x-api-url': 'link2'
        }
      }).then(x => x.data.data);
      return Ember.RSVP.hash({
        template: data
      });
    }
  });

  _exports.default = _default;
});