define("printpost/templates/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R275nwL2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"footer\"],[11,\"class\",\"footer text-center\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"text-muted\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/footer.hbs"
    }
  });

  _exports.default = _default;
});