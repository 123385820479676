define("printpost/controllers/application", ["exports", "ember-concurrency", "axios", "printpost/config/environment"], function (_exports, _emberConcurrency, _axios, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    userNotifications: Ember.inject.service(),
    afterLogoutTask: (0, _emberConcurrency.task)(function* () {
      console.log(`Checking user ${userId} in app controller`);
      yield (0, _emberConcurrency.timeout)(100);
      this.transitionToRoute('/sign/in');
    }),
    menusRoles: Ember.computed(function () {
      let mr = this.session.data.authenticated.menuRoles;
      return mr;
    }),
    accountUser: Ember.computed(function () {
      let s = this.session.data.authenticated.account;
      return s;
    }),
    actions: {
      invalidateSession() {
        this.get('session').invalidate(this.afterLogoutTask.perform());
      },

      goToLink: function (item) {
        this.transitionToRoute(item);
      }
    }
  });

  _exports.default = _default;
  setTimeout(() => {
    function getToken() {
      // { headers: { 'Authorization': this.getToken() } }
      let userLogin = JSON.parse(localStorage.getItem('ember_simple_auth-session'));
      return userLogin.authenticated.id;
    }

    function apiLink(path) {
      return [_environment.default.APP.API.host, _environment.default.APP.API.namespace, path].join('/');
    }

    function showModalSaveRequest(str_check) {
      let exibition_show = document.getElementById(str_check).value; // let requestElem = document.getElementById("request_id");

      let requestId = localStorage.getItem('newServiceRequestId');
      requestId = requestId == undefined ? 0 : requestId;

      if (true) {
        let __sNewRequest = JSON.parse(localStorage.getItem('storage:request'));

        const requestOptions = JSON.parse(localStorage.getItem('storage:request-options'));
        __sNewRequest.status = "Pendente";
        let __request = {
          data: {
            type: "Request",
            attributes: __sNewRequest
          }
        };

        if (__sNewRequest.title != null && __sNewRequest.shippingMethod && (__sNewRequest.letterService || __sNewRequest.emailService || __sNewRequest.smsService)) {
          if (requestId == 0) {
            let url = apiLink('Requests');

            let call = _axios.default.post(url, __request, {
              headers: {
                'Authorization': getToken()
              }
            });

            call.then(resp => {
              // this.transitionToRoute('dashboard');
              // requestElem.value = resp.data.data.id;
              localStorage.setItem('newServiceRequestId', resp.data.data.id);
              let walletId = resp.data.data.relationships.wallet.data && resp.data.data.relationships.wallet.data.id ? resp.data.data.relationships.wallet.data.id : 'static';
              let organizationId = resp.data.data.relationships.organization.data && resp.data.data.relationships.organization.data.id ? resp.data.data.relationships.organization.data.id : null;
              let requestTagId = resp.data.data.relationships.tag.data && resp.data.data.relationships.tag.data.id ? resp.data.data.relationships.tag.data.id : null;
              let userId = resp.data.data.relationships.account.data && resp.data.data.relationships.account.data.id ? resp.data.data.relationships.account.data.id : null;
              resp.data.data.attributes.walletId = walletId;
              resp.data.data.attributes.organizationId = organizationId;
              resp.data.data.attributes.requestTagId = requestTagId;
              resp.data.data.attributes.userId = userId;
              localStorage.setItem('storage:request', JSON.stringify(resp.data.data.attributes));
            }, error => {
              console.log(error);
            });
          } else {
            let url = apiLink('Requests/' + requestId);
            let __rp = __request;
            __rp.data.id = requestId;

            let call = _axios.default.patch(url, __rp, {
              headers: {
                'Authorization': getToken()
              }
            });

            call.then(resp => {
              //not to do                
              if (resp.data.data.attributes.smsService) {
                let qtdExcededSMS = 0;
                resp.data.data.attributes.praticatePrices.sms.smsExceedCharacters.forEach(element => {
                  qtdExcededSMS += element.chargedFor;
                });
                let obj = {
                  quantityAll: qtdExcededSMS,
                  data: resp.data.data.attributes.praticatePrices.sms.smsExceedCharacters
                };
                localStorage.setItem('excededSms', JSON.stringify(obj));
              }

              var reqOptions = JSON.parse(localStorage.getItem('storage:request-options'));
              reqOptions.praticatePrices = resp.data.data.attributes.praticatePrices;
              localStorage.setItem('storage:request-options', JSON.stringify(reqOptions));
              let walletId = resp.data.data.relationships.wallet.data && resp.data.data.relationships.wallet.data.id ? resp.data.data.relationships.wallet.data.id : 'static';
              let organizationId = resp.data.data.relationships.organization.data && resp.data.data.relationships.organization.data.id ? resp.data.data.relationships.organization.data.id : null;
              let requestTagId = resp.data.data.relationships.tag.data && resp.data.data.relationships.tag.data.id ? resp.data.data.relationships.tag.data.id : null;
              let userId = resp.data.data.relationships.account.data && resp.data.data.relationships.account.data.id ? resp.data.data.relationships.account.data.id : null;
              resp.data.data.attributes.walletId = walletId;
              resp.data.data.attributes.organizationId = organizationId;
              resp.data.data.attributes.requestTagId = requestTagId;
              resp.data.data.attributes.userId = userId;
              localStorage.setItem('storage:request', JSON.stringify(resp.data.data.attributes));
            }, error => {
              console.log(error);
            });
          } // localStorage.removeItem('storage:request');


          Ember.$("#btn-new-req-exit").click();
          exibition_show++;
          document.getElementById(str_check).value = exibition_show;
        }
      }
    }

    function startNextPreviousCheck() {
      function scroll_to_class(element_class, removed_height) {
        var scroll_to = Ember.$(element_class).offset().top - removed_height;

        if (Ember.$(window).scrollTop() != scroll_to) {
          Ember.$('html, body').stop().animate({
            scrollTop: scroll_to
          }, 0);
        }
      }

      function bar_progress(progress_line_object, direction) {
        var number_of_steps = progress_line_object.data('number-of-steps');
        var now_value = progress_line_object.data('now-value');
        var new_value = 0;

        if (direction == 'right') {
          new_value = now_value + 100 / number_of_steps;
        } else if (direction == 'left') {
          new_value = now_value - 100 / number_of_steps;
        }

        progress_line_object.attr('style', 'width: ' + new_value + '%;').data('now-value', new_value);
      }

      Ember.$('.f1-buttons .btn-next').on('click', function () {
        var parent_fieldset = Ember.$(this).parents('fieldset');
        var next_step = true; // navigation steps / progress steps

        var current_active_step = Ember.$(this).parents('.f1').find('.f1-step.active');
        var progress_line = Ember.$(this).parents('.f1').find('.f1-progress-line'); // fields validation

        parent_fieldset.find('input[type="text"], input[type="password"], textarea').each(function () {// if ($(this).val() == "") {
          //   $(this).addClass('input-error');
          //   next_step = false;
          // }
          // else {
          //   $(this).removeClass('input-error');
          // }
        }); // fields validation

        if (next_step) {
          parent_fieldset.fadeOut(400, function () {
            // change icons
            current_active_step.removeClass('active').addClass('activated').next().addClass('active'); // progress bar

            bar_progress(progress_line, 'right'); // show next step

            Ember.$(this).next().fadeIn(); // scroll window to beginning of the form

            scroll_to_class(Ember.$('.f1'), 20);
          });
        }
      });
      Ember.$('.f1-buttons .btn-previous').on('click', function () {
        // navigation steps / progress steps
        var current_active_step = Ember.$(this).parents('.f1').find('.f1-step.active');
        var progress_line = Ember.$(this).parents('.f1').find('.f1-progress-line');
        Ember.$(this).parents('fieldset').fadeOut(400, function () {
          // change icons
          current_active_step.removeClass('active').prev().removeClass('activated').addClass('active'); // progress bar

          bar_progress(progress_line, 'left'); // show previous step

          Ember.$(this).prev().fadeIn(); // scroll window to beginning of the form

          scroll_to_class(Ember.$('.f1'), 20);
        });
      });
    } // document.getElementsByClassName("navbar-nav")[0].addEventListener("click", function() {
    //   // for now we can just use an alert
    //   if(window.location.href.indexOf('services/') != -1){
    //     showModalSaveRequest('exibition_show');
    //   }else{
    //     // nothing to do
    //   }
    // });
    // window.addEventListener("mouseout", function(e) {
    //   e = e ? e : window.event;
    //   var from = e.relatedTarget || e.toElement;
    //   if (!from || from.nodeName == "HTML") {
    //       // for now we can just use an alert
    //       if(window.location.href.indexOf('services/') != -1 && window.location.href.indexOf('payment') != -1){
    //         showModalSaveRequest('exibition_show');
    //       }else{
    //         // nothing to do
    //       }
    //   }
    // });
    // document.addEventListener("mouseenter", function(e){
    //   //fazer events auto loads
    //   if(
    //     window.location.href.indexOf('services/letter') != -1 ||
    //     window.location.href.indexOf('services/email') != -1 ||
    //     window.location.href.indexOf('services/sms') != -1){
    //     // console.log('rout is services/ letter or email or sms');
    //     // startNextPreviousCheck();
    //   }
    //   else{
    //     // nothing to do
    //   }
    // });

  }, 700);
});