define("printpost/controllers/perfis/edit", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    adminValue: Ember.computed('adminValue', function () {
      return this.baseapi.getAdmValue();
    }),
    dataItem: Ember.computed('model', function () {
      var model = this.get('model.item');
      return model;
    }),
    dataList: Ember.computed('model.list', function () {
      let list = this.get('model.data');
      return list;
    }),
    dataListMenus: Ember.computed('model.menus', function () {
      let list = this.get('model.menus');
      return list;
    }),
    dataId: Ember.computed('model.id', function () {
      let id = this.get('model.id');
      return id;
    }),
    dataOrder: Ember.computed('order', function () {
      return 1;
    }),
    actions: {
      salvar: function () {
        let item_data = Ember.get(this, 'dataItem');
        let name_value = (0, _globalFunctions.removeSpecialChar)(item_data.description).toLowerCase();
        let adminPerfil = this.get('model.adminPerfil');

        if (adminPerfil) {
          name_value = "administrador";
        }

        let item = {
          "id": item_data.id,
          "name": name_value,
          "description": item_data.description
        };
        let _object = {
          data: {
            type: "Role",
            attributes: item
          }
        };

        if (item.description.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink('Roles');
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.transitionToRoute('perfis.list');
            }, error => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
            });
          } else {
            let url = this.baseapi.apiLink('Roles/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
            }, error => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
            });
          }
        }
      },
      setTypenameSelect: function () {
        let select_value = this.baseapi.getFieldSelectValue("tiporole_selection");
        Ember.set(this, 'dataItem.name', select_value);
      },
      apagar: function () {
        let item_data = Ember.get(this, 'dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink('Roles');
          let call = this.baseapi.delete(url + '/' + item_data.id);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('perfis.list');
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },
      ativarMenu: function (id, val) {
        let menuId = id == undefined ? 0 : id;
        let list = Ember.get(this, 'dataListMenus');
        let roleId = Ember.get(this, 'dataId');
        let item = list.find(x => x.id == id);

        if (item) {
          Ember.set(item, 'active', !val);
          let itemMenuRole = {
            "active": !val,
            "name": item.name,
            "roleId": roleId,
            "menuId": menuId,
            "order": item.menuRole.order
          };
          let __menuRole = {
            data: {
              type: "MenuRoles",
              attributes: itemMenuRole
            }
          };

          if (item.menuRole.id) {
            let url = this.baseapi.apiLink('MenuRoles/' + item.menuRole.id);
            let call = this.baseapi.patch(url, __menuRole);
            __menuRole.data.id = item.menuRole.id;
            itemMenuRole.order = item.menuRole.order;
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none'); //not to do
              // set(itemMenuRole, 'id', menuId);

              this.toast.success('Atualizado!', 'Menu atualizado com sucesso!', {
                positionClass: 'toast-bottom-right'
              });
            }, error => {
              this.preloader.toggleClass('d-none');
              console.log(error);
            });
          } else {
            // delete __menuRole.data.attributes.id;
            //simulando um insert e atualizando lista
            // set(item, 'menuRole', itemMenuRole);
            let url = this.baseapi.apiLink('MenuRoles');
            let call = this.baseapi.post(url, __menuRole);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none'); //not to do

              Ember.set(itemMenuRole, 'id', resp.data.data.id);
              Ember.set(item, 'menuRole', itemMenuRole);
              this.toast.success('Atualizado!', 'Menu atualizado com sucesso!', {
                positionClass: 'toast-bottom-right'
              });
            }, error => {
              this.preloader.toggleClass('d-none');
              console.log(error);
            });
          }
        }
      }
    }
  });

  _exports.default = _default;
});