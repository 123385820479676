define("printpost/templates/b", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tKA9kDEp",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"style\"],[9],[0,\"\\nbody {\\n   font-family: Arial, Helvetica, sans-serif;\\n   color: #000000\\n}\\n\\nspan, div {\\n   font-family: inherit;\\n}\\n\"],[10],[0,\"\\n\\n\"],[1,[23,[\"model\",\"template\"]],true],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/b.hbs"
    }
  });

  _exports.default = _default;
});