define("printpost/components/plot-ly", ["exports", "ember-cli-plotly/components/plot-ly"], function (_exports, _plotLy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _plotLy.default;
    }
  });
});