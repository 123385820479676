define("printpost/controllers/remetente/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    actions: {
      removeSender(item) {
        this.set('model.emailTrash', item);
      },

      apagar() {
        let email = Ember.get(this, 'model.emailTrash');

        if (email) {
          let url = this.baseapi.apiLink('Emails/sender');
          let call = this.baseapi.delete(url + '/' + email);
          this.preloader.toggleClass('d-none');
          call.then(() => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.send('routerReload');
          }, () => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
          });
        }
      },

      addSender() {
        let email = Ember.get(this, 'model.emailAdd');

        if (email) {
          let url = this.baseapi.apiLink('Emails/sender');
          let call = this.baseapi.post(url, {
            email: email
          });
          this.preloader.toggleClass('d-none');
          call.then(() => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Email enviado!', 'Solicite ao remetente para acessar sua conta e clicar no link de ativação enviado', {
              positionClass: 'toast-bottom-right'
            });
            this.send('routerReload');
          }, () => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Erro ao enviar email!', 'Não foi possível enviar o email para cadastro!', {
              positionClass: 'toast-bottom-right'
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});