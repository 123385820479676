define("printpost/routes/configurations/galeria", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function () {
      return {
        'images': this.get('getUserImages').perform()
      };
    },
    getUserImages: (0, _emberConcurrency.task)(function* () {
      let userId = this.get('session.data.authenticated.userId');
      console.log(userId);
      let request = yield this.get('store').query('user-image', {
        filter: {
          where: {
            'userId': userId
          }
        }
      }).then(request => {
        return request;
      });
      return request;
    }).keepLatest()
  });

  _exports.default = _default;
});