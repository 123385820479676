define("printpost/components/drag-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({// classNames: ['drag-box'],
    // attributeBindings: ['draggable'],
    // draggable: true,
    // x: 0,
    // y: 0,
    // positionChanged: Ember.observer('x', 'y', function(){
    //   this.$().css({
    //     left: `${this.get('x')}px`,
    //     top: `${this.get('y')}px`,
    //   });
    // }),
    // dragStart(e) {
    //   var x = parseInt(this.$().css('left')) - e.originalEvent.clientX;
    //   var y = parseInt(this.$().css('top')) - e.originalEvent.clientY;
    //   e.originalEvent.dataTransfer.setData("position", `${x},${y}`);
    // },
    // windowDrop(e) {
    //   // origin
    //   // var xy = e.dataTransfer.getData("position").split(',');
    //   // this.setProperties({
    //   //   x: `${e.originalEvent.clientX + parseInt(xy[0])}`,
    //   //   y: `${e.originalEvent.clientY + parseInt(xy[1])}`,
    //   // });
    //   var xy = e.dataTransfer.getData("position").split(',');
    //   var area = document.getElementById('cont_area').getBoundingClientRect();
    //   //console.log(area); 
    //   var target_x = (area.right - area.left) - 77;
    //   var target_y = (area.bottom - area.top) - 5;
    //   //perimetro x
    //   var new_x = (e.originalEvent.clientX + parseInt(xy[0])) >= target_x  ? target_x : (e.originalEvent.clientX + parseInt(xy[0]));
    //   new_x = new_x < area.left ? (area.left + 5) : new_x;
    //   //console.log(new_x); 
    //   //perimetro y
    //   var new_y = (e.originalEvent.clientY + parseInt(xy[1])) >= target_y  ? target_y : (e.originalEvent.clientY + parseInt(xy[1]));
    //   new_y = new_y < area.top ? (area.top + 5) : new_y;
    //   //console.log(new_y);
    //   this.setProperties({
    //     x: `${new_x}`,
    //     y: `${new_y}`,
    //   });
    // },
    // windowDragOver(e) {
    //   e.originalEvent.preventDefault();
    // },
    // didRender() {
    //   var self = this;
    //   $(document)
    //     .on('drop', $.proxy(self.windowDrop, self))
    //     .on('dragover', $.proxy(self.windowDragOver, self));
    // },
    // didDestroyElement() {
    //   $(document)
    //     .off('drop')
    //     .off('dragover');
    // }
  });

  _exports.default = _default;
});