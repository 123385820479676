define("printpost/routes/returncenter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    model: async function () {
      let positivo = {
        channelId: "5c3f713ce1c9855370e3e02e",
        channelName: "carta",
        companyReturnId: null,
        description: "Positivo",
        id: "idxpositivo"
      };
      let url = this.baseapi.apiLink('TypeReturns/list?filter[where][channelName]=carta');
      var listReturn = await this.baseapi.getHttp(url).then(r => r.data);
      listReturn.unshift(positivo); // let disbraveArVinculeURL = this.baseapi.apiLink('Requests/request-disbrave');
      // var listDisbraveArVincule = await this.baseapi.getHttp(disbraveArVinculeURL).then(r => r.data);
      // let trinusArVinculeURL = this.baseapi.apiLink('Requests/request-trinus');
      // var listTrinusArVincule = await this.baseapi.getHttp(trinusArVinculeURL).then(r => r.data);
      // let lealArVinculeURL = this.baseapi.apiLink('Requests/request-leal');
      // var listLealArVincule = await this.baseapi.getHttp(lealArVinculeURL).then(r => r.data);
      //organization

      let url_organization = this.baseapi.apiLink('Organizations/list');
      const obj_organization = await this.baseapi.getHttp(url_organization).then(resp => resp.data);
      return Ember.RSVP.hash({
        optionsCard: null,
        selectedReturn: null,
        selectedRequest: null,
        filesReturn: null,
        fileXls: null,
        filesReturnLenght: 0,
        itenReturnNotFound: '',
        dataTypeReturn: listReturn,
        listArVincule: [],
        organizationSelected: null,
        organizations: obj_organization // listTrinusArVincule: listTrinusArVincule,
        // listLealArVincule: listLealArVincule,

      });
    }
  });

  _exports.default = _default;
});