define("printpost/routes/operacao/pdfcombine", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    queryParams: {
      requestId: null
    },
    model: async function (queryParams) {
      let reqId = queryParams.requestId;
      var list_linkcustomer = []; // var list_channels = [];

      var obj_prices_table = [];

      if (reqId) {
        // let url_channels = this.baseapi.apiLink('Channels/list');
        // list_channels = await this.baseapi.getHttp(url_channels).then(x => x.data);
        let url_request = this.baseapi.apiLink('Requests/list');
        let dataRequest = await this.baseapi.getHttp(url_request + '?filter[where][id]=' + reqId).then(x => x.data[0]);
        let url_price_table = this.baseapi.apiLink('PricingTables/find');
        obj_prices_table = await this.baseapi.getHttp(url_price_table + '?' + 'filter[where][type]=general_table').then(resp => resp.data);

        if (dataRequest) {
          var query = {
            filter: {
              where: {
                userId: dataRequest.userId,
                active: true
              },
              include: ['pricingTable']
            }
          };
          var strquery = (0, _globalFunctions.apiLinkQuery)(query);
          let url_linkcustomer = this.baseapi.apiLink('LinkCustomers/find');
          let obp = await this.baseapi.getHttp(url_linkcustomer + '?' + strquery).then(x => {
            let obj = x.data;
            obj.forEach(item => {
              list_linkcustomer.push(item.pricingTable);
            });
          });
        }
      }

      return Ember.RSVP.hash({
        requestId: reqId,
        linkCustomer: list_linkcustomer,
        priceGeneral: obj_prices_table,
        options: {
          quantityProccess: 0
        }
      });
    }
  });

  _exports.default = _default;
});