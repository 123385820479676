define("printpost/models/base-template", ["exports", "ember-data", "cheerio"], function (_exports, _emberData, _cheerio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    internal: _emberData.default.attr('string'),
    externalHtml: _emberData.default.attr('string'),
    thumb: _emberData.default.attr('string'),
    channelId: _emberData.default.attr(),
    productId: _emberData.default.attr(),
    templateFilter: _emberData.default.belongsTo('template-filter'),
    //PRODUCT
    wallet: _emberData.default.belongsTo('wallet'),
    channel: _emberData.default.belongsTo('channel'),
    product: _emberData.default.belongsTo('product'),
    paperSize: _emberData.default.attr('string'),
    userId: _emberData.default.attr(),
    editableInternalList: Ember.computed('internal', 'channel', function () {
      if (this.channel.content.channel == 'sms') {
        return null;
      } else {
        var editableInternal = null;

        var $ = _cheerio.default.load(this.internal);

        if (this.internal) {
          editableInternal = $('.editable');
        }

        var lista = [];

        for (var i = 0; i < editableInternal.length; i++) {
          var config = {};

          for (var x in editableInternal[i].attribs) {
            if (x == 'id' || x == 'name' || x == 'size' || x == 'src') {
              config[x] = editableInternal[i].attribs[x];
            }
          }

          config['prop'] = editableInternal[i].name;
          config['target'] = 'internal';
          config['value'] = '';
          lista.push(config);
        }

        return lista;
      }
    }),
    editableExternalList: Ember.computed('externalHtml', function () {
      var editableExternal = null;

      var $ = _cheerio.default.load(this.externalHtml);

      if (this.externalHtml) {
        editableExternal = $('.editable');
      }

      var lista = [];

      for (var i = 0; i < editableExternal.length; i++) {
        var config = {};

        for (var x in editableExternal[i].attribs) {
          if (x == 'id' || x == 'name' || x == 'size' || x == 'src') {
            config[x] = editableExternal[i].attribs[x];
          }
        }

        config['prop'] = editableExternal[i].name;
        config['target'] = 'external';
        config['value'] = '';
        lista.push(config);
      }

      return lista;
    })
  });

  _exports.default = _default;
});