define("printpost/routes/analises/carteira", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    queryParams: {
      id: null
    },
    model: function (queryParams) {
      var params = queryParams;
      return Ember.RSVP.hash({
        'wallet': this.get('requestTask').perform(params)
      });
    },
    requestTask: (0, _emberConcurrency.task)(function* (params) {
      let request = yield this.store.query('wallet', {
        filter: {
          where: {
            'id': params.id
          }
        }
      });
      return request;
    }).keepLatest()
  });

  _exports.default = _default;
});