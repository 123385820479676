define("printpost/controllers/operacao/filetreatment", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    isUploadReturn: Ember.computed(function () {
      return false;
    }),
    selectedColumnGroup: Ember.computed(function () {
      return null;
    }),
    getFileColumns: (0, _emberConcurrency.task)(function* () {
      let n = this.get('model.fileXls');

      try {
        let form = new FormData(); // form.set("name", n.name);

        form.append("file", n);
        this.preloader.toggleClass('d-none');
        let url = this.baseapi.apiLink('OperationTasks/file-columns');
        var resp = yield this.baseapi.postFormData(url, form);
        if (!resp || resp.data.length == 0) this.toast.error('', 'Nenhum coluna encontrada', {
          positionClass: 'toast-bottom-right'
        });
        this.set('model.dataFileColumns', resp.data);
      } catch (error) {
        this.toast.error('', 'Revise as colunas e dados de sua carteira!', {
          positionClass: 'toast-bottom-right'
        });
      } finally {
        this.preloader.toggleClass('d-none');
      }
    }),
    actions: {
      setColumnGroup: function (columGroup) {
        this.set('model.selectedColumnGroup', columGroup);
      },
      setFileReturn: function (data) {
        Ember.set(this, 'model.filesData', data.target.files);
        Ember.set(this, 'model.filesDataLenght', data.target.files.length);
      },

      setFileExcel(data) {
        Ember.set(this, 'model.fileXls', data.target.files[0]);
        this.get('getFileColumns').perform();
      },

      sendFilesDataMrl1() {
        var columGroup = Ember.get(this, 'model.selectedColumnGroup');
        var removeDuplicate = Ember.get(this, 'model.removeDuplicate');
        var fileXls = Ember.get(this, 'model.fileXls');
        var optionCard = Ember.get(this, 'model.optionsCard');
        var url = optionCard == 'mrl' ? 'OperationTasks/group-itens-column' : '';
        var checkSend = optionCard == 'mrl' && columGroup && fileXls && url ? true : false;

        if (checkSend) {
          let form = new FormData();
          form.set("groupColumn", columGroup.name);
          form.set("removeDuplicate", removeDuplicate);
          form.append("file", fileXls);
          let urlP = this.baseapi.apiLink(url);
          this.preloader.toggleClass('d-none');

          try {
            this.baseapi.downloadPostBuffer(urlP, form, true);
          } catch (error) {
            this.toast.error('Erro!', 'Problemas ao salvar o arquivo', {
              positionClass: 'toast-bottom-right'
            });
          } finally {
            this.preloader.toggleClass('d-none');
          }
        } else {
          this.toast.error('Erro!', 'Problemas ao enviar os arquivos', {
            positionClass: 'toast-bottom-right'
          });
        }
      },

      sendFilesDataMrl2() {
        var columGroup = Ember.get(this, 'model.selectedColumnGroup');
        var optionsQtdItems = Ember.get(this, 'model.mrl.layout2.qtdPerItem');
        var columnCheckPerItem = Ember.get(this, 'model.mrl.layout2.columnCheckPerItem');
        var columnsRemove = Ember.get(this, 'model.mrl.layout2.columnsRemove');
        var columnsItemNotRepeat = Ember.get(this, 'model.mrl.layout2.columnsItemNotRepeat');
        var fileXls = Ember.get(this, 'model.fileXls');
        var url = 'OperationTasks/ungroup-data';
        var checkSend = columGroup && fileXls && url && optionsQtdItems && columnCheckPerItem && columnsItemNotRepeat.length > 0 ? true : false;

        if (checkSend) {
          console.log(optionsQtdItems, columnCheckPerItem);
          let form = new FormData();
          form.set("groupColumn", columGroup.name);
          form.set("optionsQtdItems", optionsQtdItems.qtd);
          form.set("columnCheckPerItem", columnCheckPerItem.name);
          form.set("columnsRemove", columnsRemove.join(';'));
          form.set("columnsItemNotRepeat", columnsItemNotRepeat.join(';'));
          form.append("file", fileXls);
          let urlP = this.baseapi.apiLink(url);
          this.preloader.toggleClass('d-none');

          try {
            this.baseapi.downloadPostBuffer(urlP, form, true);
          } catch (error) {
            this.toast.error('Erro!', 'Problemas ao salvar o arquivo', {
              positionClass: 'toast-bottom-right'
            });
          } finally {
            this.preloader.toggleClass('d-none');
          }
        } else {
          this.toast.error('Erro!', 'Problemas ao enviar os arquivos', {
            positionClass: 'toast-bottom-right'
          });
        }
      },

      setOptionsCard(val) {
        Ember.set(this, 'model.filesData', null);
        Ember.set(this, 'model.filesDataLenght', 0);
        Ember.set(this, 'model.selectedColumnGroup', null);
        Ember.set(this, 'model.fileXls', {
          name: '-'
        });
        this.set('model.optionsCard', val);
      },

      closeMessageReturnNotFound() {
        this.set('model.itenReturnNotFound', '');
      },

      setMrlRemoveDuplicate() {
        var v = this.get('model.removeDuplicate');
        v = v == 1 ? 0 : 1;
        this.set('model.removeDuplicate', v);
      },

      addTag(tag) {
        this.get('model.mrl.layout2.columnsRemove').pushObject(tag.toLowerCase());
      },

      removeTagAtIndex(index) {
        this.get('model.mrl.layout2.columnsRemove').removeAt(index);
      },

      addTagNotRepeat(tag) {
        this.get('model.mrl.layout2.columnsItemNotRepeat').pushObject(tag.name.toLowerCase());
      },

      removeTagAtIndexNotRepeat(index) {
        this.get('model.mrl.layout2.columnsItemNotRepeat').removeAt(index);
      },

      setMrlLayout2OptionsPerItem(val) {
        this.set('model.mrl.layout2.qtdPerItem', val);
      },

      setMrlLayout2ColumnPerItem(val) {
        this.set('model.mrl.layout2.columnCheckPerItem', val);
      }

    }
  });

  _exports.default = _default;
});