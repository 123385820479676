define("printpost/controllers/sign/in", ["exports", "printpost/utils/normalize-errors"], function (_exports, _normalizeErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    actions: {
      authenticate() {
        this.set('isLoginLoading', true);
        let {
          identification,
          password
        } = this.getProperties('identification', 'password');
        this.get('session').authenticate('authenticator:loopback', identification.toLowerCase().trim(), password).catch(reason => {
          this.set('errorMessage', (0, _normalizeErrors.default)(reason).message);
          this.set('hasError', true);
          this.set('isLoginLoading', false);
        }).then(() => {
          if (this.get('session.isAuthenticated')) {
            this.set('isLoading', false);
            this.transitionToRoute('index');
          }
        });
      }

    }
  });

  _exports.default = _default;
});