define("printpost/routes/requests/detail", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    model: function (params) {
      return Ember.RSVP.hash({
        'request': this.get('requestTask').perform(params)
      });
    },
    requestTask: (0, _emberConcurrency.task)(function* (params) {
      let request = yield this.store.peekRecord('request', params.id, {
        filter: {
          include: ['wallet', 'sentStatuses']
        }
      });
      return request;
    }).keepLatest(),
    actions: {
      visibilityDetails: function (value) {
        if (!value) {
          this.baseapi.visibilityElem("#list-requests", true);
          this.baseapi.visibilityElem("#header-requests", true);
          this.baseapi.visibilityElem("#details-request", false);
        }
      }
    }
  });

  _exports.default = _default;
});