define("printpost/initializers/ember-simple-auth-jwt", ["exports", "ember-simple-auth-jwt/authenticators/jwt"], function (_exports, _jwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-simple-auth-jwt',
    before: 'ember-simple-auth',

    initialize(container) {
      container.register('authenticator:jwt', _jwt.default);
    }

  };
  _exports.default = _default;
});