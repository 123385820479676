define("printpost/router", ["exports", "printpost/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('settings');
    this.route('services', function () {
      this.route('system');
      this.route('applications');
      this.route('new', {
        path: 'old-new'
      });
      this.route('data');
      this.route('sms', function () {
        this.route('edit', {
          path: ':id'
        });
      });
      this.route('email', function () {
        this.route('edit', {
          path: ':id'
        });
      });
      this.route('payment');
      this.route('test');
      this.route('letter', function () {
        this.route('edit', {
          path: ':id'
        });
      });
      this.route('remetente');
      this.route('completed');
      this.route('config');
      this.route('info');
      this.route('simplesms');
      this.route('simplemail');
      this.route('new2', {
        path: 'new'
      });
      this.route('validation');
    });
    this.route('cloud', function () {
      this.route('instances');
      this.route('images');
    });
    this.route('upload');
    this.route('dashboard');
    this.route('profile', function () {
      this.route('settings');
    });
    this.route('icons');
    this.route('events', function () {
      this.route('all', function () {
        this.route('active');
        this.route('shared');
        this.route('completed');
        this.route('unknown');
        this.route('late');
      });
      this.route('in', {
        path: '/in/:env_id'
      }, function () {
        this.route('active');
        this.route('shared');
        this.route('completed');
        this.route('unknown');
        this.route('late');
      });
    });
    this.route('manager');
    this.route('sign', function () {
      this.route('out');
      this.route('in');
      this.route('create');
      this.route('recovery');
    });
    this.route('sign.twofactor', {
      path: '/sign/two-factor'
    });
    this.route('carteiras', function () {
      this.route('details', {
        path: ':id'
      });
      this.route('new');
      this.route('multicanal');
      this.route('list');
    });
    this.route('templates', function () {
      this.route('new', {
        path: '/novo/:id'
      });
      this.route('view');
      this.route('edit');
    });
    this.route('retornos');
    this.route('requests', function () {
      this.route('detail', {
        path: ':id'
      });
    });
    this.route('configurations', function () {
      this.route('payment');
      this.route('company');
      this.route('galeria');
    });
    this.route('reqdetail');
    this.route('operacao', function () {
      this.route('dash');
      this.route('processamento');
      this.route('profiles');
      this.route('pdfcombine');
      this.route('enrich');
      this.route('filetreatment');
      this.route('filelink');
      this.route('fornecedores');
    });
    this.route('analises', function () {
      this.route('carteira');
      this.route('cliente');
    });
    this.route('menus', function () {
      this.route('list');
      this.route('view');
      this.route('edit');
    });
    this.route('produtos', function () {
      this.route('list');
      this.route('edit');
    });
    this.route('precos');
    this.route('usuarios', function () {
      this.route('edit');
      this.route('list');
      this.route('company');
    });
    this.route('perfis', function () {
      this.route('list');
      this.route('edit');
    });
    this.route('prices', function () {
      this.route('list');
      this.route('edit');
      this.route('linkcustomer');
      this.route('addlinkcustomer');
      this.route('pricing');
    });
    this.route('channel', function () {
      this.route('list');
      this.route('edit');
    });
    this.route('cart');
    this.route('invoice', function () {
      this.route('list');
      this.route('map');
    });
    this.route('peoples', function () {
      this.route('list');
      this.route('omnichannel');
    });
    this.route('organization', function () {
      this.route('edit');
      this.route('list');
    });
    this.route('reports', function () {
      this.route('list');
      this.route('panelmail');
      this.route('panelsms');
      this.route('panelletter');
      this.route('panelwhatsapp');
    });
    this.route('centrodecusto', function () {
      this.route('edit');
      this.route('list');
    });
    this.route('returncenter');
    this.route('unsubscribe');
    this.route('unsubsuccess');
    this.route('emailconfirm');
    this.route('emailcfailure');
    this.route('remetente', function () {
      this.route('list');
      this.route('edit');
    });
    this.route('v', {
      path: '/v/:v_id'
    });
    this.route('b', {
      path: '/b/:b_id'
    });
    this.route('whitelabel', function () {
      this.route('list');
      this.route('edit');
    });
    this.route('blacklist');
    this.route('contatos', function () {
      this.route('list');
      this.route('peoples');
    });
    this.route('whatsapp', function () {
      this.route('numeros');
    });
    this.route('transmissao', function () {
      this.route('list');
      this.route('edit');
    });
  });
  var _default = Router;
  _exports.default = _default;
});