define("printpost/models/app", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    /* auto gerado por ember-models.py em  2018-06-05 19:37:46 */
    app_name: _emberData.default.attr('string'),
    repository_slug: _emberData.default.attr('string'),
    repository_source: _emberData.default.attr(),
    branch: _emberData.default.attr('string'),
    branch_path: _emberData.default.attr('string'),
    has_repository: _emberData.default.attr()
  });

  _exports.default = _default;
});