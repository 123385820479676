define("printpost/controllers/services/simplesms", ["exports", "printpost/controllers/services", "printpost/config/environment", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _services, _environment, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const newWallet = Ember.Object.extend({
    name: null,
    newFile: null,
    description: null,
    separator: ';'
  });
  var DOMTinymce = [];

  var _default = _services.default.extend({
    newWallet: newWallet.create(),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }),
    loadInit: Ember.computed(function () {
      setTimeout(() => {
        this.get('newRequest').reset();
        this.get('storageTemplateSelected').reset();
        this.get('requestOptions').reset();
        this.get('walletColumns').reset();
        this.set('selectedCostCenter', null);
        this.set('selectedRequestTagFilter', null);
        this.set('selectedFilter', null);
      }, 300);
      return 123;
    }).property().volatile(),
    textTinymce: Ember.computed(function () {
      setTimeout(() => {
        let iframe_edit = Ember.$(DOMTinymce[0].getContainer()).find('iframe');
        let document_body = iframe_edit.contents().find("body");
        Ember.$(document_body).attr('style', 'font-size: 14pt; padding: 5px 5px 0px 20px');
      }, 1000);
      return {
        dados: '',
        options: {
          selector: 'textarea',
          // change this value according to your html
          min_height: 1000,
          min_width: 500,
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14pt; padding: 5px 5px 0px 20px }",
          menubar: false,
          //theme: 'modern', 
          body_class: 'iframe-sms-template',
          plugins: 'code template link',
          // note the comma at the end of the line!
          default_link_target: '_blank',
          toolbar: 'undo redo | link | cut copy paste selectall | code template ',
          templates: [_environment.default.APP.API.host, _environment.default.APP.API.namespace, 'Wallets/find-tags/'].join('/') + JSON.parse(localStorage.getItem('storage:request')).walletId + '?requestId=' + localStorage.getItem('newServiceRequestId') + '&access_token=' + JSON.parse(localStorage.getItem('ember_simple_auth-session')).authenticated.id,
          setup: function (ed) {
            ed.on('init', function () {
              //var c = $(ed.getContainer()); // this would be the text area
              let iframe = Ember.$(ed.getContainer()).find('iframe');
              iframe.addClass('iframe-sms-template');
            });
            DOMTinymce.push(ed);
          }
        }
      };
    }).property().volatile(),
    accountUser: Ember.computed('accountUser', function () {
      return this.session.data.authenticated.account;
    }),
    adminValue: Ember.computed('adminValue', function () {
      return this.baseapi.getAdmValue();
    }),
    isCreateWallet: Ember.computed('isCreateWallet', function () {
      return false;
    }),
    loadingApprove: Ember.computed('loadingApprove', function () {
      return false;
    }),
    newFile: Ember.computed('newFile', function () {
      return null;
    }).property().volatile(),
    tipoDados: Ember.computed('tipoDados', function () {
      return 'excel';
    }),
    fileWalletReload: Ember.computed(function () {
      return true;
    }),
    tableColumns: Ember.computed('tableColumns', function () {
      return [];
    }),
    dataAproveWallet: Ember.computed('dataAproveWallet', function () {
      return [];
    }),
    dataQtd: Ember.computed('dataQtd', function () {
      return {
        emailCount: 0,
        addressCount: 0,
        phoneCount: 0
      };
    }).property().volatile(),
    dataHtml: Ember.computed('dataHtml', function () {
      return {
        frontHTML: '',
        backHTML: ''
      };
    }),
    myFlatpickrRef: Ember.computed(function () {
      return null;
    }),
    selectedFilter: Ember.computed('selectedFilter', function () {
      let a = this.get('model.accounts').find(x => x.id == this.get('newRequest.userId'));

      if (a) {
        if (a.type == this.baseapi.getAdmValue()) {
          this.set('newRequest.userId', '');
          return null;
        }

        this.set('userAdminSelected', a.id);
        return a;
      }

      return null;
    }).property().volatile(),
    selectedCostCenter: Ember.computed(function () {
      return null;
    }).property().volatile(),
    selectedRequestTagFilter: Ember.computed(function () {
      return null;
    }).property().volatile(),
    createWalletTask: (0, _emberConcurrency.task)(function* (newWallet) {
      Ember.set(this, 'isCreateWallet', true); // const USER = this.get('session.data.authenticated');

      const USER = this.get('newRequest.userId');
      let wallet = this.store.createRecord('wallet', {
        name: Ember.get(newWallet, 'name'),
        description: Ember.get(newWallet, 'description'),
        file: Ember.get(newWallet, 'fileName'),
        userId: Ember.get(USER, 'userId')
      });
      let id_wallet = null;

      try {
        yield wallet.save().then(response => {
          id_wallet = response.id;
        });
        return id_wallet;
      } catch (error) {
        for (let err in error.errors) {
          if (error.errors.hasOwnProperty(err)) {
            if (error.errors[err].status == 422) {
              this.toast.error('Campos obrigatórios em branco.', 'Erro!', {
                positionClass: 'toast-bottom-right'
              });
            }
          }
        }
      }
    }),
    aproveDataWallet: function () {
      //id_wallet = '5d9b8c14a4a29d29f0ddf538';
      let n = Ember.get(this, 'newFile');
      let saida = false;
      let prices = Ember.get(this, 'model.prices');

      if (prices.length == 0) {
        this.toast.error('', 'Não conseguimos verificar o valor cadastrado, procure o comercial!', {
          positionClass: 'toast-bottom-right'
        });
        return;
      }

      var praticatePrices = {
        carta: {
          priceVolumeGroup: '0,000',
          priceSale: '0,000',
          quantity: 0,
          channelId: "",
          channelValue: ""
        },
        email: {
          priceVolumeGroup: '0,000',
          priceSale: '0,000',
          quantity: 0,
          channelId: "",
          channelValue: ""
        },
        sms: {
          priceVolumeGroup: '0,000',
          priceSale: '0,000',
          quantity: 0,
          channelId: "",
          channelValue: ""
        }
      };
      this.changeStorageRequestFunc('smsService', true);
      this.changeStorageRequestFunc('emailService', false);
      this.changeStorageRequestFunc('letterService', false);
      this.changeStorageRequestFunc('orderSend', {
        first: 'sms',
        second: '',
        third: ''
      });
      Ember.set(this, 'isCreateWallet', true);

      try {
        let form = new FormData();
        form.set("name", n.name);
        form.set("channel", "-");
        form.set("id", 'waiting_wallet');
        form.set("userId", this.get('newRequest.userId'));
        let sep = this.newWallet.separator;
        form.set("separator", sep);
        Ember.set(this, 'newRequest.step', 2);
        form.append("file", n);
        let token = this.get('session.data.authenticated.id');
        let url = this.baseapi.apiLink('Wallets/aprove-file' + '?access_token=' + token);

        if (this.tipoDados == "excel") {
          url = this.baseapi.apiLink('Wallets/aprove-file-xls' + '?access_token=' + token);
        }

        var call = this.baseapi.postFormData(url, form, false);
        call.then(resp => {
          Ember.set(this, 'isCreateWallet', false);

          if (resp.data.errors && resp.data.errors[0].detail) {
            Ember.set(this, 'newFile', {});
            Ember.set(this, 'newWallet', {});
            this.toast.error('', resp.data.errors[0].detail, {
              positionClass: 'toast-bottom-right'
            });
            setTimeout(() => {
              Ember.$('#closeWallet').click();
            }, 300);
            return;
          }

          if (resp.data.counter.addressCount == 0 && resp.data.counter.emailCount == 0 && resp.data.counter.phoneCount == 0) {
            this.toast.error('Não conseguimos identificar nenhum E-mail, SMS ou Endereço, verifique seu arquivo!', '', {
              positionClass: 'toast-bottom-right'
            });
          }

          Ember.set(this, 'dataAproveWallet', resp.data.parser);
          Ember.set(this, 'dataQtd', resp.data.counter); // qtds

          if (prices.length == 1) {
            praticatePrices.sms.channelId = prices[0].channelId;
            praticatePrices.sms.channelValue = prices[0].channelValue;
            praticatePrices.sms.priceSale = prices[0].priceSale;
            praticatePrices.sms.priceVolumeGroup = '0,00';
            praticatePrices.sms.quantity = resp.data.counter.phoneCount;
            praticatePrices.sms.smsExceedCharacters = [];
            praticatePrices.sms.totalCost = '0,00';
            this.changeStorageRequestOptionsFunc('definition', {
              sendValue: prices[0].send
            });
            this.changeStorageRequestFunc('praticatePrices', praticatePrices);
          }

          this.changeStorageRequestFunc('quantity', {
            email: 0,
            sms: resp.data.counter.phoneCount,
            carta: 0
          });
          let keys = Object.keys(resp.data.maxlength);
          Ember.set(this, 'tableColumns', keys);
          this.send('createWallet', this.newWallet);
        }).catch(error => {
          console.log(error);
          Ember.set(this, 'isCreateWallet', false);
          Ember.set(this, 'newFile', {});
          Ember.set(this, 'newWallet', {});
          this.toast.error('Revise as colunas e dados de sua carteira!', '', {
            positionClass: 'toast-bottom-right'
          });
          setTimeout(() => {
            Ember.$('#closeWallet').click();
          }, 300);
        });
      } catch (e) {
        Ember.set(this, 'isCreateWallet', false);

        for (let err in e.body.errors) {
          Ember.set(this, 'isValidating', false);
          this.toast.error('Revise as colunas e dados de sua carteira!', e.body.errors[err].detail, {
            positionClass: 'toast-bottom-right'
          });
        }
      }
    },
    saveFileWallets: (0, _emberConcurrency.task)(function* (id_wallet) {
      //id_wallet = '5d9b8c14a4a29d29f0ddf538';
      let n = Ember.get(this, 'newFile');
      let saida = false;

      if (n != null && id_wallet != null) {
        try {
          let form = new FormData();
          form.set("name", n.name);
          form.set("channel", n.type);
          form.set("id", id_wallet);
          let sep = this.newWallet.separator;
          form.set("separator", sep);
          form.set("userId", this.get('newRequest.userId'));
          form.set("requestId", localStorage.getItem("newServiceRequestId"));
          let organizationId = Ember.get(this, 'newRequest.organizationId');
          var dataUser = this.session.data.authenticated.account;

          if (organizationId) {
            form.set("organizationId", organizationId);
          } else if (dataUser.organizationId) {
            form.set("organizationId", dataUser.organizationId);
          }

          form.append("file", n);
          let token = this.get('session.data.authenticated.id');
          let url = this.baseapi.apiLink('Wallets/upload-file' + '?access_token=' + token);

          if (this.tipoDados == "excel") {
            url = this.baseapi.apiLink('Wallets/upload-file-xls' + '?access_token=' + token);
          }

          var call = this.baseapi.postFormData(url, form, false);

          try {
            // pegar os campos e salvar no walletColumns
            yield call.then(resp => {
              var data = resp.data;
              var obj = {
                data: data.columns,
                dataEmail: data.columns_email,
                dataSMS: data.columns_sms
              };
              Ember.set(this, 'newRequest.walletId', data.id);
              Ember.set(this, 'newRequest.columns_email', data.columns_email);
              Ember.set(this, 'newRequest.columns_sms', data.columns_sms);
              Ember.set(this, 'newRequest.columnsSelectEmail', data.columns_email);
              Ember.set(this, 'newRequest.columnsSelectSMS', data.columns_sms);
              Ember.set(this, 'walletColumns.data', data.columns);
              Ember.set(this, 'walletColumns.dataSMS', data.columns_sms);
              Ember.set(this, 'walletColumns.dataEmail', data.columns_email);
            });
          } catch (error) {
            this.toast.error('', 'Revise as colunas e dados de sua carteira!', {
              positionClass: 'toast-bottom-right'
            });
            Ember.set(this, 'isCreateWallet', false);
          } // this.set('newRequest.walletId', id_wallet);
          // this.toast.success('', 'Dados salvos com sucesso', {positionClass: 'toast-bottom-right'});

        } catch (e) {
          this.toast.error('', 'Revise as colunas e dados de sua carteira!', {
            positionClass: 'toast-bottom-right'
          });

          for (let err in e.body.errors) {
            Ember.set(this, 'isValidating', false);
          }
        }
      }
    }),
    trasitionTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'newRequest.step', 2);
      let nextWallet = this.get('newRequest.walletId');

      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.walletId = nextWallet;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      let nr = _newReq.typeConfig;

      if (nextWallet != 'static') {
        let url_wallet = this.baseapi.apiLink('Wallets/list');
        const objReturn = yield this.baseapi.getHttp(url_wallet + '?filter[where][id]=' + nextWallet).then(r => r.data[0]);

        if (_newReq.columns_complement) {
          let newColumns = [];

          _newReq.columns_complement.forEach(rc => {
            newColumns.push(rc);
          });

          objReturn.columns.forEach(c => {
            newColumns.push(c);
          });
          Ember.set(this, 'walletColumns.data', newColumns);
        } else {
          Ember.set(this, 'walletColumns.data', objReturn.columns);
        }

        Ember.set(this, 'walletColumns.name', objReturn.name);
        Ember.set(this, 'walletColumns.dataSMS', objReturn.columns_sms);
        Ember.set(this, 'walletColumns.dataEmail', objReturn.columns_email);
        Ember.set(this, 'newRequest.columns_email', objReturn.columns_email);
        Ember.set(this, 'newRequest.columns_sms', objReturn.columns_sms);
        Ember.set(this, 'newRequest.columnsSelectEmail', objReturn.columns_email);
        Ember.set(this, 'newRequest.columnsSelectSMS', objReturn.columns_sms);
      }

      _newReq.walletId = nextWallet;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.columns_email', Ember.get(this, 'walletColumns.dataEmail'));
      Ember.set(this, 'newRequest.columns_sms', Ember.get(this, 'walletColumns.dataSMS'));
      _newReq.columns_email = Ember.get(this, 'walletColumns.dataEmail');
      _newReq.columns_sms = Ember.get(this, 'walletColumns.dataSMS');
      _newReq.columnsSelectEmail = Ember.get(this, 'walletColumns.dataEmail');
      _newReq.columnsSelectSMS = Ember.get(this, 'walletColumns.dataSMS');
      yield this.baseapi.savePendentRequest().then();
      yield this.transitionToRoute('services.payment', {
        queryParams: {
          wallet: nextWallet
        }
      });
    }).maxConcurrency(3).enqueue(),
    changeStorageRequestOptionsFunc: function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions.templateSMS[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.printingOptions.templateSMS.' + chave, valor);
    },
    changeStorageRequestFunc: function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.' + chave, valor);
    },
    changeStorageRequestOptionsTask: (0, _emberConcurrency.task)(function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions.templateSMS[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.printingOptions.templateSMS.' + chave, valor);
    }).keepLatest(),
    loadRequestTags: async function (id) {
      //request tags      
      var query_tag = {
        filter: {
          where: {
            userId: id
          }
        }
      };
      var strquery_tag = (0, _globalFunctions.apiLinkQuery)(query_tag);
      let request_tag = this.baseapi.apiLink(`RequestTags/list?${strquery_tag}`);
      const obj_request_tags = await this.baseapi.getHttp(request_tag).then(resp => resp.data);
      this.set('model.request_tags', obj_request_tags);
    },
    loadCostCenter: async function (id) {
      //request tags      
      var query_tag = {
        filter: {
          where: {
            userId: id
          }
        }
      };
      var strquery_tag = (0, _globalFunctions.apiLinkQuery)(query_tag);
      let request_tag = this.baseapi.apiLink(`CostCenters/list?${strquery_tag}`);
      const obj_costcenter = await this.baseapi.getHttp(request_tag).then(resp => resp.data);
      this.set('model.obj_costcenter', obj_costcenter);
    },
    getLinkCustomerAccount: async function (id, reloadTag = true) {
      if (id) {
        this.preloader.toggleClass('d-none');
        var query = {
          filter: {
            where: {
              userId: id,
              active: true
            }
          }
        };
        var strquery = (0, _globalFunctions.apiLinkQuery)(query);
        let notAdmin = this.baseapi.getCurrentUser().type != this.baseapi.getAdmValue() ? "list" : "find";
        let url_prices = this.baseapi.apiLink(`LinkCustomers/${notAdmin}?${strquery}`);
        const obj_prices_customer = await this.baseapi.getHttp(url_prices).then(resp => resp.data);
        let prices_general = this.get('model.obj_prices_general');
        var obj_prices_tmp = [];

        if (obj_prices_customer.length > 0) {
          obj_prices_tmp = obj_prices_customer;
        } else {
          obj_prices_tmp = prices_general;
        }

        var obj_prices = [];
        obj_prices_tmp.map(x => {
          if (x.channelValue != "sms") return;
          x.options.forEach(j => {
            var obj = {
              color: j.color,
              format: j.format,
              priceSale: j.priceSale,
              priceVolumeGroupId: j.priceVolumeGroupId,
              send: j.send,
              volumeItens: j.volumeItens,
              channelValue: x.channelValue,
              channelId: x.channelId
            };
            obj_prices.push(obj);
          });
        });
        this.set('model.prices', obj_prices);
        await this.loadRequestTags(id);
        await this.loadCostCenter(id);
        this.preloader.toggleClass('d-none');
      }
    },
    getTemplates: async function (id) {
      var canal = this.get('model.channel'); //templates

      var query = {
        filter: {
          where: {
            channelId: canal.id,
            userId: id
          },
          include: 'channel'
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url_templates = this.baseapi.apiLink('Templates/list');
      var url_template_find = `${url_templates}?fu=true&${strquery}`;
      const obj_templates = await this.baseapi.getHttp(url_template_find).then(r => r.data);
      this.set('model.templates', obj_templates);
    },
    actions: {
      setTypeConfig(value) {
        this.set('data_type', value);
        this.send('clearFiles');
      },

      clearFiles() {
        Ember.$("#upload-photo").val('');
        Ember.set(this, 'fileWalletReload', false);
        Ember.set(this, 'newFile', null);
        Ember.set(this, 'fileWalletReload', true);
      },

      createWallet: async function (newWallet) {
        await this.baseapi.savePendentRequest();
        var id_wallet = await this.get('createWalletTask').perform(newWallet);

        if (id_wallet != null) {
          await this.get('saveFileWallets').perform(id_wallet);
          this.set('newRequest.walletId', id_wallet); // this.toast.success('', 'Arquivo salvos com sucesso, você será encaminhado para a próxima tela!', { positionClass: 'toast-bottom-right' });
          // $("#closeWallet").click();
          // await sleep(500);
          // $("#validFileClose").click();
          // this.send('proceedTo');

          Ember.set(this, 'isCreateWallet', false);
        }

        Ember.set(this, 'finishSaveWallet', false);
        Ember.$("#modal-dismiss-validatefile").click();
      },
      aproveWallet: function () {
        this.aproveDataWallet();
      },
      setFileWallet: function (file) {
        // this.setFileWallet(file);
        var data = file.target.files[0];
        var filename = `Arquivo: ${data.name}`;
        Ember.set(this, 'newFile', data);
        Ember.set(this, 'newWallet.name', filename);
        this.aproveDataWallet(); // $('#btnModalAproveWallet').click();
      },
      proceedTo: function () {
        Ember.get(this, 'trasitionTask').perform();
      },
      selectTemplate: async function (template) {
        if (template) {
          if (template == "construir_do_zero") {
            Ember.set(this, 'dataHtml.frontHTML', '');
            Ember.set(this, 'dataHtml.backHTML', '');
            this.changeStorageRequestOptionsFunc('templateId', 'construir_do_zero'); // this.send('nextRouter');

            return;
          }

          Ember.set(this, 'newRequest.step', 2);
          Ember.set(this, 'dataHtml.frontHTML', template.internal);
          Ember.set(this, 'dataHtml.backHTML', template.externalHtml);
          this.changeStorageRequestOptionsFunc('frontHTML', template.internal);
          this.changeStorageRequestOptionsFunc('backHTML', template.externalHtml);
          this.changeStorageRequestOptionsFunc('disparoImediato', true);
          this.changeStorageRequestOptionsFunc('productId', template.productId);
          this.changeStorageRequestOptionsFunc('templateId', template.id);
          this.changeStorageRequestOptionsFunc('editorMode', 'sms');
        }
      },

      viewTemplate(template) {
        Ember.set(this, 'dataHtml.frontHTML', template.internal);
        Ember.set(this, 'dataHtml.backHTML', template.externalHtml);
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });
        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = day + "/" + month + "/" + year + " - " + hour + ":" + minute;
        this.changeStorageRequestOptionsFunc('dataDisparo', strDate);
      },

      changeStorageRequestOptions(chave, valor) {
        this.changeStorageRequestOptionsFunc(chave, valor);
      },

      setTinymceValueFront(value) {
        this.changeStorageRequestOptionsFunc('frontHTML', value);
      },

      setAccount: function (subAccount = false, a) {
        this.set('selectedFilter', a);
        this.set('userAdminSelected', a.id);
        this.set('newRequest.userId', a.id);

        if (subAccount) {
          this.set('userAdminSelected', a.subAccountId);
          this.set('newRequest.userId', a.subAccountId);
        }

        if (!a || !a.id) {
          this.toast.error('', 'Erro ao selecionar centro de custo, tente novamente.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (subAccount) {
          this.getLinkCustomerAccount(a.subAccountId);
          this.getTemplates(a.subAccountId);
        } else {
          this.getLinkCustomerAccount(a.id);
          this.getTemplates(a.id);
        }
      },
      setRequestTag: function (rt) {
        this.set('selectedRequestTagFilter', rt);
        this.set('newRequest.title', rt.name);
        this.set('newRequest.multiChannel', false);
        this.set('newRequest.notarysOffice', false);
        this.set('newRequest.requestTagId', rt.id);
        var v2 = this.get('requestOptions');
      },

      saveRequestTag() {
        var account = this.session.data.authenticated.account;
        var idUser = account.id;
        var idUserSelected = this.get('userAdminSelected');

        if (idUserSelected) {
          idUser = idUserSelected;
        }

        if (!idUser) {
          this.toast.error('', 'É necessário selecionar o usuário.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var obj = {
          data: {
            type: "RequestTag",
            attributes: {
              name: this.get('dataCategory'),
              userId: idUser
            }
          }
        };
        let url = this.baseapi.apiLink('RequestTags');
        let call = this.baseapi.post(url, obj);
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');
          this.variables.set('empty_resp', resp);
          this.toast.success('', 'Item cadastrado com sucesso', {
            positionClass: 'toast-bottom-right'
          });
        }, error => {
          this.preloader.toggleClass('d-none');
          this.variables.set('empty_resp', error);
          this.toast.error('', 'Erro ao cadastrar item.', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      deleteFilter(filter) {
        var removeItem = this.get('model.request_tags').findBy('name', filter.name);
        this.get('model.request_tags').removeObject(removeItem);
        let url = this.baseapi.apiLink(`RequestTags/delete/${filter.id}`);
        let call = this.baseapi.delete(url);
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.variables.set('empty_resp', resp);
          this.preloader.toggleClass('d-none');
        }, error => {
          this.variables.set('empty_resp', error);
          this.preloader.toggleClass('d-none');
        });
      },

      loadRequestTags() {
        this.loadRequestTags(this.get('userAdminSelected'));
      },

      setColumnSMS: function (value) {
        let cols = Ember.get(this, 'newRequest.columnsSelectSMS');

        if (cols.find(r => r == value) == undefined) {
          cols.push(value);
        } else {
          let new_cols = [];
          cols.forEach(elem => {
            if (elem != value) {
              new_cols.push(elem);
            }
          });
          cols = new_cols;
        } // set(this, 'columns_selected', cols);


        Ember.set(this.newRequest, 'columnsSelectSMS', cols);
        this.changeStorageRequestFunc('columnsSelectSMS', cols);
      },

      setShippingMethod(price) {
        var praticatePrices = {
          carta: {
            priceVolumeGroup: '0,000',
            priceSale: '0,000',
            quantity: 0,
            channelId: "",
            channelValue: ""
          },
          email: {
            priceVolumeGroup: '0,000',
            priceSale: '0,000',
            quantity: 0,
            channelId: "",
            channelValue: ""
          },
          sms: {
            priceVolumeGroup: '0,000',
            priceSale: '0,000',
            quantity: 0,
            channelId: "",
            channelValue: ""
          }
        };
        praticatePrices.sms.channelId = price.channelId;
        praticatePrices.sms.channelValue = price.channelValue;
        praticatePrices.sms.priceSale = price.priceSale;
        praticatePrices.sms.priceVolumeGroup = '0,00';
        praticatePrices.sms.quantity = this.get('newRequest.quantity.sms');
        praticatePrices.sms.smsExceedCharacters = [];
        praticatePrices.sms.totalCost = '0,00';
        this.changeStorageRequestOptionsFunc('definition', {
          sendValue: price.send
        });
        this.changeStorageRequestFunc('praticatePrices', praticatePrices);
      },

      setCostCenter(item) {
        Ember.set(this, 'newRequest.costCenterId', item.id);
        Ember.set(this, 'selectedCostCenter', item);
      }

    }
  });

  _exports.default = _default;
});