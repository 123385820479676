define("printpost/routes/reqdetail", ["exports", "ember-concurrency", "printpost/models/request", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _request, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    queryParams: {
      id: null
    },
    model: async function (queryParams) {
      var params = queryParams;
      var dataRawFiles = null;
      var reqId = params.id;
      var item = [];
      var dataTotalCost = "0";
      var dataQtdDigital = 0;
      var lastStatus = {};
      var files_observation = [];
      var pdfHomologation = {
        result: 'approve',
        observation: ''
      };
      let whatsappTemplate = [];

      if (reqId) {
        // request detail
        var url_req = this.baseapi.apiLink('Requests');
        var detailRequest = await this.baseapi.getHttp(`${url_req}/${reqId}/detail`).then(r => r.data);
        var attachNotaFiscal = null;
        var attachLogProcess = null;
        var comprovantePostagem = null;
        var arquivoRegistro = null;
        var pdfHomologacao = {
          file: {},
          uri: ''
        };
        dataRawFiles = detailRequest.rawFiles;
        pdfHomologacao.file = dataRawFiles.filter(x => x.type == "file_homolog");

        if (pdfHomologacao.file.length > 0) {
          pdfHomologacao.file = pdfHomologacao.file[0];
          let token = this.baseapi.getToken();
          pdfHomologacao.uri = this.baseapi.apiLink(`rawFiles/view/${pdfHomologacao.file.id}?access_token=${token}#zoom=100`);
        }

        arquivoRegistro = dataRawFiles.find(x => x.type == "registry_office_file");
        comprovantePostagem = dataRawFiles.find(x => x.type == "receipt_attach");
        files_observation = dataRawFiles.filter(x => x.type == "request_attach_observation");
        var responseRequestAttach = detailRequest.invoiceFiles;

        if (responseRequestAttach) {
          attachNotaFiscal = responseRequestAttach.find(x => x.mode == "nota_fiscal");
        }

        item = detailRequest.request;
        item.progress.sort((a, b) => a.number < b.number ? 1 : -1);
        lastStatus = item.progress[0];

        if (detailRequest.totalCost) {
          let tcDec = this.baseapi.realStringConvertDecimal(detailRequest.totalCost);
          let tfDec = this.baseapi.realStringConvertDecimal(detailRequest.totalFreight);
          let s = tcDec + tfDec;
          dataTotalCost = this.baseapi.numberToReal(s);
        }

        if (item.printingOptions.templateWhatsapp.frontHTML) {
          whatsappTemplate = [item.printingOptions.templateWhatsapp.frontHTML.replace(/(<([^>]+)>)/ig, '')];
        }
      } //envio


      const obj_prices_sends = detailRequest.priceSend; //fornecedor

      const obj_provider = detailRequest.providers; //fornecedor pedido

      const obj_req_provider = detailRequest.requestProvider; //dados de retorno

      const obj_req_return = detailRequest.return; //dados de homologacao

      const obj_req_homolog = detailRequest.homolog;
      var graphReturn = {
        showDataReturn: false,
        percentTotalReceived: 0,
        sumNotReceive: 0,
        sumReceive: 0,
        sumAll: item.quantity.sms + item.quantity.email + item.quantity.carta,
        sumDigital: item.quantity.sms + item.quantity.email,
        sumLetter: item.quantity.carta
      };

      for (let i = 0; i < obj_req_return.length; i++) {
        const element = obj_req_return[i];

        if (element.carta && item.letterService) {
          // graphReturn.sumLetter += element.cartaAll;
          graphReturn.sumNotReceive += element.cartaNotReceive;
          graphReturn.sumReceive += element.cartaReceive; // graphReturn.sumAll += element.cartaAll;
        }

        if (element.email && item.emailService) {
          // graphReturn.sumDigital += element.emailAll;
          graphReturn.sumNotReceive += element.emailNotReceive;
          graphReturn.sumReceive += element.emailReceive; // graphReturn.sumAll += element.emailAll;
        }

        if (element.sms && item.smsService) {
          // graphReturn.sumDigital += element.smsAll;
          graphReturn.sumNotReceive += element.smsNotReceive;
          graphReturn.sumReceive += element.smsReceive; // graphReturn.sumAll += element.smsAll;
        }
      }

      graphReturn.showDataReturn = graphReturn.sumAll > 0 ? true : false;
      graphReturn.percentTotalReceived = (graphReturn.sumReceive * 100 / graphReturn.sumAll).toFixed(2); //channels

      const obj_channels = detailRequest.channels; // obj_dispatchs

      const obj_dispatchs = detailRequest.dispatchs;
      var listEnvios = {
        carta: [],
        email: [],
        sms: []
      };
      var dataEnvios = {
        carta: [],
        email: [],
        sms: []
      };
      let cartaId = obj_channels.find(x => x.value.toLowerCase() == "carta").id;
      let emailId = obj_channels.find(x => x.value.toLowerCase() == "email").id;
      let smsId = obj_channels.find(x => x.value.toLowerCase() == "sms").id;

      if (obj_prices_sends.length > 0) {
        listEnvios = {
          carta: obj_prices_sends.filter(x => x.channelId == cartaId),
          email: obj_prices_sends.filter(x => x.channelId == emailId),
          sms: obj_prices_sends.filter(x => x.channelId == smsId)
        };
        dataEnvios = {
          carta: listEnvios.carta,
          email: listEnvios.email,
          sms: listEnvios.sms
        };
      } // providers


      var listProviders = {
        carta: [],
        email: [],
        sms: []
      };

      if (obj_provider.length > 0) {
        obj_prices_sends.map(s => {
          //carta
          if (s.channelId == cartaId) {
            var p = obj_provider.find(x => x.id == s.providerId);

            if (p && listProviders.carta.length == 0) {
              listProviders.carta.push(p);
            } else if (p && !listProviders.carta.find(lp => lp.id == p.id)) {
              listProviders.carta.push(p);
            }
          } //email


          if (s.channelId == emailId) {
            var p = obj_provider.find(x => x.id == s.providerId);

            if (p && listProviders.email.length == 0) {
              listProviders.email.push(p);
            } else if (p && !listProviders.email.find(lp => lp.id == p.id)) {
              listProviders.email.push(p);
            }
          } //sms


          if (s.channelId == smsId) {
            var p = obj_provider.find(x => x.id == s.providerId);

            if (p && listProviders.sms.length == 0) {
              listProviders.sms.push(p);
            } else if (p && !listProviders.sms.find(lp => lp.id == p.id)) {
              listProviders.sms.push(p);
            }
          }
        });
      }

      listProviders.carta = listProviders.carta.length > 0 ? this.populeObjSelect(listProviders.carta) : listProviders.carta;
      listProviders.email = listProviders.email.length > 0 ? this.populeObjSelect(listProviders.email) : listProviders.email;
      listProviders.sms = listProviders.sms.length > 0 ? this.populeObjSelect(listProviders.sms) : listProviders.sms; //checklist data

      const checklistData = {
        verificouCarteira: true,
        letter: {
          dataEnvioAceitavel: false,
          opcaoPapelAnotada: false,
          validacaoImpressa: false,
          dobra: false,
          enderecamento: false,
          usoDosCorreios: false,
          serrilhas: false,
          datasInternas: false,
          ortografia: false,
          chancelas: false
        },
        email: {
          dataEnvioAceitavel: false,
          templateValidado: false,
          assuntoRespondeValidos: false
        },
        sms: {
          dataEnvioAceitavel: false,
          templateValidado: false
        },
        registry_office: {
          arquivoValidado: false
        }
      };
      var showItem = {
        carta: false,
        email: false,
        sms: false
      };
      var totalSMSRsponse = 0;

      if (item.smsService) {
        let urlSMSResponse = this.baseapi.apiLink('Reports/contact-response');
        const dataSMSResponse = await this.baseapi.getHttp(`${urlSMSResponse}?filter[where][id]=${reqId}&type=sms&key=*`).then(x => x.data);
        totalSMSRsponse = dataSMSResponse && dataSMSResponse.total ? dataSMSResponse.total : 0;
      }

      var colors = ["#31B404", "#FE9A2E", "#FFBF00", "#D8CEF6", "#FE2EC8", "#8A084B", "#9AFE2E", "#61380B", "#0B615E", "#380B61", "#5FB404"];
      const piegraphOptions = {
        "responsive": true,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#000"
          }
        },
        "scales": {
          "yAxes": [{
            "display": false,
            "ticks": {
              "beginAtZero": false
            },
            "gridLines": {
              "display": false
            }
          }],
          "xAxes": [{
            "display": false,
            "gridLines": {
              "display": false
            }
          }]
        }
      };
      const typePanel = _request.default.smsService ? 'sms' : _request.default.emailService ? 'email' : null; // 'request': this.get('requestTask').perform(params),

      return Ember.RSVP.hash({
        request: item,
        rawFiles: dataRawFiles,
        totalCost: dataTotalCost,
        totalDigital: dataQtdDigital,
        lastStatus: lastStatus,
        attachNotaFiscal: attachNotaFiscal,
        attachLogProcess: attachLogProcess,
        checklistAprovacao: checklistData,
        obj_prices_sends: obj_prices_sends,
        listEnvios: listEnvios,
        listProviders: listProviders,
        dataEnvios: dataEnvios,
        showItem: showItem,
        cartaId: cartaId,
        emailId: emailId,
        smsId: smsId,
        listHomologation: obj_req_homolog,
        pdfHomologation: pdfHomologation,
        pdfHomologacao: pdfHomologacao,
        comprovantePostagem: comprovantePostagem,
        arquivoRegistro: arquivoRegistro,
        requestProvider: obj_req_provider,
        requestReturn: obj_req_return,
        graphReturn: graphReturn,
        requestDispatchs: obj_dispatchs,
        observationFiles: files_observation,
        whatsappTemplate: whatsappTemplate,
        noFileUpload: false,
        key: '',
        filter: {
          skip: 0,
          limit: 0
        },
        panelDataItens: {},
        panelData: {},
        objPieGraphPanelData: {},
        piegraphOptions: piegraphOptions,
        qtdContactResponse: totalSMSRsponse,
        returnMessage: '',
        colors: colors,
        typePanel: typePanel,
        painelPagePagination: 1,
        isLoadingResume: true,
        type: item.emailService ? 'email' : item.smsService ? 'sms' : null,
        filesRequestReturnReport: [],
        blacklistData: {
          action: '',
          cadastrar: [],
          consultar: [],
          deletar: [],
          findResult: null
        },
        walletColumns: item.wallet && item.wallet.columns ? item.wallet.columns.join(';').split(';') : []
      });
    },
    populeObjSelect: function (data) {
      let objReturn = [];
      let item01 = {
        "id": "0",
        "selectText": "Selecione",
        "selectId": "0",
        "selectValue": "0"
      };
      objReturn.push(item01);
      data.forEach(element => {
        var rand = Math.random() * 9999;
        let item = {
          "id": element.id,
          "selectText": element.name,
          "selectId": rand,
          "selectValue": element.id
        };
        item.selectText = element.name;
        objReturn.push(item);
      });
      return objReturn;
    },
    actions: {
      sessionChanged: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});