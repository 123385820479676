define("printpost/templates/components/power-selected-create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pPSrl+WX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-block rounded-0 border-top text-primary fs-12\"],[11,\"data-toggle\",\"modal\"],[11,\"data-target\",\"#modalCreateCategory\"],[9],[0,\"\\n  + Novo\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/power-selected-create.hbs"
    }
  });

  _exports.default = _default;
});