define("printpost/components/url-logo", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    baseapi: Ember.inject.service(),
    widthValue: null,
    defaultLogo: '',

    // timer
    // Periodically refresh product info
    init() {
      this.get('taskFilterReturn').perform();

      this._super(...arguments);
    },

    taskFilterReturn: (0, _emberConcurrency.task)(function* () {
      var currentUrl = window.location.href;
      var urlMain = currentUrl.replace('://', '@').split('/')[0].replace('@', '://');
      let url = this.baseapi.apiLink('WhiteLabels/logo');
      const whitelabelLogo = yield this.baseapi.post(url, {
        url: urlMain
      }).then(x => x.data);
      this.set('defaultLogo', whitelabelLogo);
    })
  });

  _exports.default = _default;
});