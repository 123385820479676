define("printpost/authorizers/loopback", ["exports", "ember-simple-auth-loopback/authorizers/loopback"], function (_exports, _loopback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _loopback.default.extend();

  _exports.default = _default;
});