define("printpost/controllers/contatos/list", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    isCreate: Ember.computed('isCreate', function () {
      return false;
    }),
    dataQtd: Ember.computed('dataQtd', function () {
      return {
        emailCount: 0,
        addressCount: 0,
        phoneCount: 0
      };
    }).property().volatile(),
    tipoDados: Ember.computed('tipoDados', function () {
      return 'excel';
    }),
    separator: Ember.computed('separator', function () {
      return ';';
    }),
    fileName: Ember.computed('fileName', function () {
      return '';
    }),
    newFile: Ember.computed('newFile', function () {
      return null;
    }).property().volatile(),
    preloader: Ember.computed('preloader', function () {
      return $('#subpreloader');
    }),
    saveFile: (0, _emberConcurrency.task)(function* () {
      //id_wallet = '5d9b8c14a4a29d29f0ddf538';
      let n = this.get('newFile');
      let fileName = this.get('fileName');
      let separator = this.get('separator');
      let saida = false;

      if (n != null) {
        try {
          this.set('isCreate', true);
          let form = new FormData();
          form.set("name", fileName);
          let sep = separator;
          form.set("separator", sep);
          form.append("file", n);
          let token = this.get('session.data.authenticated.id');
          let url = this.baseapi.apiLink('ContactFiles/upload-file' + '?access_token=' + token);

          if (this.tipoDados == "excel") {
            url = this.baseapi.apiLink('ContactFiles/upload-file-xls' + '?access_token=' + token);
          }

          var call = this.baseapi.postFormData(url, form);

          try {
            // pegar os campos e salvar no walletColumns
            yield call.then(resp => {
              var data = resp.data;
              var obj = {
                data: data.columns,
                dataEmail: data.columns_email,
                dataSMS: data.columns_sms
              };
            });
            this.get('reloadItens').perform();
            this.set('isCreate', false);
            this.toast.success('', 'Arquivo enviado com sucesso!', {
              positionClass: 'toast-bottom-right'
            });
          } catch (error) {
            this.toast.error('', 'Revise as colunas e dados de sua carteira!', {
              positionClass: 'toast-bottom-right'
            });
            this.set('isCreate', false);
          }
        } catch (e) {
          this.toast.error('', 'Revise as colunas e dados de sua carteira!', {
            positionClass: 'toast-bottom-right'
          });
        }
      }
    }),
    deleteFile: (0, _emberConcurrency.task)(function* (id) {
      //id_wallet = '5d9b8c14a4a29d29f0ddf538';
      try {
        let deleteFileId = this.get('model.deleteFileId');
        this.preloader.toggleClass('d-none');
        let url = this.baseapi.apiLink('ContactFiles/' + deleteFileId);
        var call = this.baseapi.delete(url);

        try {
          // pegar os campos e salvar no walletColumns
          yield call.then();
          this.get('reloadItens').perform();
          this.preloader.toggleClass('d-none');
          this.toast.success('', 'Lista apagada com sucesso!', {
            positionClass: 'toast-bottom-right'
          });
          this.set('model.deleteFileId', null);
        } catch (error) {
          this.toast.error('', 'Erro ao apagar a lista! [0002]', {
            positionClass: 'toast-bottom-right'
          });
          this.preloader.toggleClass('d-none');
          this.set('model.deleteFileId', null);
        }
      } catch (e) {
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao apagar a lista! [0001]', {
          positionClass: 'toast-bottom-right'
        });
        this.set('model.deleteFileId', null);
      }
    }),
    reloadItens: (0, _emberConcurrency.task)(function* () {
      this.preloader.toggleClass('d-none');
      var url = this.baseapi.apiLink('ContactFiles/list');
      const obj = yield this.baseapi.getHttp(url).then(r => r.data);
      this.set('model.data', obj);
      this.preloader.toggleClass('d-none');
    }),
    actions: {
      setFile: function (file) {
        // this.setFileWallet(file);
        var data = file.target.files[0];
        this.set('newFile', data);
        this.get('saveFile').perform(); // $('#btnModalAproveWallet').click();
      },

      deleteFile(id) {
        this.get('deleteFile').perform();
      },

      setFileDelete(id) {
        this.set('model.deleteFileId', id);
      }

    }
  });

  _exports.default = _default;
});