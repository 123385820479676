define("printpost/controllers/services/applications", ["exports", "printpost/controllers/services"], function (_exports, _services) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _services.default.extend({});

  _exports.default = _default;
});