define("printpost/routes/templates/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: async function () {
      var url_products = this.baseapi.apiLink('Products/list');
      var url_templates = this.baseapi.apiLink('Templates/list?filter[include]=channel&filter[order]=id DESC');
      const obj_products = await this.baseapi.getHttp(url_products).then(r => r.data);
      const obj_templates = await this.baseapi.getHttp(url_templates).then(r => r.data);
      return Ember.RSVP.hash({
        products: obj_products,
        templates: obj_templates
      });
    }
  });

  _exports.default = _default;
});