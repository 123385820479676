define("printpost/helpers/firstcase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.firstcase = firstcase;
  _exports.default = void 0;

  function firstcase(params
  /*, hash*/
  ) {
    let str = params;

    if (Ember.isArray(params)) {
      str = params[0];
    }

    if (typeof str === 'undefined') {
      str = '';
    }

    if (str && typeof str === "string") {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return '';
  }

  var _default = Ember.Helper.helper(firstcase);

  _exports.default = _default;
});