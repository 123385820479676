define("printpost/routes/services/simplemail", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    accountSubaccount: Ember.inject.service(),
    model: async function () {
      setTimeout(() => {
        Ember.$("#wrapper").attr('style', 'display: none');
      }, 300);
      var account = this.baseapi.getCurrentUser(); //canais

      var url_canais = this.baseapi.apiLink('Channels/list');
      const obj_canais = await this.baseapi.getHttp(url_canais).then(r => r.data);
      var canal = obj_canais.find(x => x.value == "email"); // let newRequest = JSON.parse(localStorage.getItem('storage:request'));
      // let userId = newRequest.userId;
      // tabela de preço

      let url_prices = this.baseapi.apiLink('LinkCustomers/list');
      const obj_prices_customer = await this.baseapi.getHttp(url_prices).then(resp => resp.data);
      var query_general = {
        filter: {
          where: {
            pricingTableType: 'general_table',
            active: true
          },
          include: ['channel']
        }
      }; // tabela geral

      var strquery_general = (0, _globalFunctions.apiLinkQuery)(query_general);
      let url_price_table = this.baseapi.apiLink('PricingTables/find');
      const obj_prices_general = await this.baseapi.getHttp(url_price_table + '?' + strquery_general).then(resp => resp.data);
      var obj_prices_tmp = [];

      if (obj_prices_customer.length > 0) {
        obj_prices_tmp = obj_prices_customer.filter(x => x.channelValue == "email");
      } else {
        obj_prices_tmp = obj_prices_general.filter(x => x.channelValue == "email");
      }

      var obj_prices = [];

      if (obj_prices_tmp.length == 1) {
        obj_prices_tmp.map(x => {
          if (x.channelValue != "email") return;
          x.options.forEach(j => {
            var obj = {
              color: j.color,
              format: j.format,
              priceSale: j.priceSale,
              priceVolumeGroupId: j.priceVolumeGroupId,
              send: j.send,
              volumeItens: j.volumeItens,
              channelValue: x.channelValue,
              channelId: x.channelId
            };
            obj_prices.push(obj);
          });
        });
      } //templates


      var query = {
        filter: {
          where: {
            channelId: canal.id
          },
          include: 'channel'
        }
      };
      let newRequest = JSON.parse(localStorage.getItem('storage:request'));

      if (newRequest && newRequest.userId) {
        var or = [// {userId: newRequest.userId},
        {
          userId: account.id
        }];
        or.push({
          userId: newRequest.userId
        });
        query.filter.where.or = or;
      }

      ;
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url_templates = this.baseapi.apiLink('Templates/list');
      var url_template_find = `${url_templates}?fu=true&${strquery}`;
      const obj_templates = await this.baseapi.getHttp(url_template_find).then(r => r.data); //account subaccount

      const obj_subaccount = await this.accountSubaccount.listAccountSubaccount(); //account

      let url_account = this.baseapi.apiLink('Accounts/list');
      const obj_account = await this.baseapi.getHttp(url_account).then(resp => resp.data); //cost center

      var url_costcenter = this.baseapi.apiLink('CostCenters/list');
      const obj_costcenter = await this.baseapi.getHttp(url_costcenter).then(r => r.data);
      return Ember.RSVP.hash({
        products: [],
        prices: obj_prices,
        obj_prices_customer: obj_prices_customer,
        templates: obj_templates,
        request_tags: [],
        obj_subaccount: obj_subaccount,
        obj_prices_general: obj_prices_general,
        obj_costcenter: obj_costcenter,
        accounts: obj_account,
        channel: canal,
        editTemplate: false
      });
    }
  });

  _exports.default = _default;
});