define("printpost/controllers/requests/detail", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    isShowingTranslucent: true,
    request: Ember.computed('model.request.data', function () {
      return this.get('model.request.data');
    }),
    wallet: Ember.computed('model.request.wallet', function () {
      return this.get('model.request.wallet.content');
    }),
    statuses: Ember.computed('model.request.sentStatuses', function () {
      return this.get('model.request.sentStatuses.content');
    }),
    download: (0, _emberConcurrency.task)(function* (fileName) {
      try {
        let request = yield this.baseapi.downloadFile(fileName);
        return request;
      } catch (e) {
        this.toast.error('Erro!', 'Ocorreu algum problema ao baixar seu arquivo.', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    totalItems: Ember.computed('this.{statuses,wallet,request}', function () {
      let data = {};
      let execucao = 0;
      let finalizados = 0;
      let retornos = 0;
      var model = this.statuses;
      var modelList = [];
      model.forEach(item => {
        modelList.push(item);
      });

      for (let i = 0; i < modelList.length; i++) {
        finalizados += modelList[i].finalizados;
        retornos += modelList[i].retornos;
      }

      if (this.request.letterService) {
        execucao += this.wallet.addressCount;
      }

      if (this.request.smsService) {
        execucao += this.wallet.phoneCount;
      }

      if (this.request.emailService) {
        execucao += this.wallet.emailCount;
      }

      data['execucao'] = execucao;
      data['finalizados'] = finalizados;
      data['retornos'] = retornos;
      return data;
    }),
    getEmailTemplates: (0, _emberConcurrency.task)(function* (id) {
      let request = yield this.dashboard.getEmailTemplates(id);
      return request;
    }).keepLatest(),
    getLetterTemplates: (0, _emberConcurrency.task)(function* (id) {
      let request = yield this.dashboard.getLetterTemplates(id);
      return request;
    }).keepLatest(),
    getSmsTemplates: (0, _emberConcurrency.task)(function* (id) {
      let request = yield this.dashboard.getSmsTemplates(id);
      return request;
    }).keepLatest(),
    availableTemplates: Ember.computed('model.request.data.[]', function () {
      let model = this.get('model.request');
      let id = this.get('model.request.id');
      let request = [];

      if (model.emailService) {
        let emailTemplate = Ember.get(this, 'getEmailTemplates').perform(id);
        request.push({
          "name": "Email",
          "tipo": "monitor",
          "item": emailTemplate
        });
      }

      if (model.letterService) {
        let letterTemplate = Ember.get(this, 'getLetterTemplates').perform(id);
        request.push({
          "name": "Carta",
          "tipo": "mail",
          "item": letterTemplate
        });
      }

      if (model.smsService) {
        let smsTemplate = Ember.get(this, 'getSmsTemplates').perform(id);
        request.push({
          "name": "Sms",
          "tipo": "conversation",
          "item": smsTemplate
        });
      }

      return request;
    }),
    actions: {
      downloadDocument: function (fileName) {
        Ember.get(this, 'download').perform(fileName);
      },
      refreshAndClose: function () {
        this.transitionToRoute('requests').then(() => document.location.reload(true));
      },
      transitionToTemplate: function (id) {
        this.transitionToRoute('templates.edit', id).then(() => document.location.reload());
      },
      transitionToWallet: function (id) {
        this.transitionToRoute('carteiras.details', id).then(() => document.location.reload());
      }
    }
  });

  _exports.default = _default;
});