define("printpost/templates/operacao/dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jmIMka3H",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row mt-20 mr-0\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-12 au-container\"],[9],[0,\"\\n\\n\"],[4,\"link-to\",[\"operacao.processamento\"],null,{\"statements\":[[4,\"card-thumb\",null,[[\"title\",\"color\"],[\"Processamento\",\"orange\"]],{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"operacao.profiles\"],null,{\"statements\":[[4,\"card-thumb\",null,[[\"title\",\"color\"],[\"Profiles Ativos\",\"blue\"]],{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"operacao.pdfcombine\"],null,{\"statements\":[[4,\"card-thumb\",null,[[\"title\",\"color\"],[\"PDF Combinados\",\"red\"]],{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/operacao/dash.hbs"
    }
  });

  _exports.default = _default;
});