define("printpost/controllers/transmissao/edit", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service('session'),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataItem: Ember.computed('model', function () {
      return this.get('model.item');
    }),
    accountUser: Ember.computed('accountUser', function () {
      return this.session.data.authenticated.account;
    }),
    adminValue: Ember.computed('adminValue', function () {
      return this.baseapi.getAdmValue();
    }),
    searchTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Accounts/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    searchOrganizationTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Organizations/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    loadCostCenter: async function () {
      const organization = this.get('dataItem.organization');
      const user = this.get('dataItem.account'); //request tags      

      var query_tag = {
        filter: {
          where: {
            active: true
          }
        }
      };

      if (organization) {
        query_tag.filter.where.organizationId = organization.id;
      }

      if (user) {
        query_tag.filter.where.userId = user.id;
      }

      var strquery_tag = (0, _globalFunctions.apiLinkQuery)(query_tag);
      let request_tag = this.baseapi.apiLink(`CostCenters/list?${strquery_tag}`);
      const obj_costcenter = await this.baseapi.getHttp(request_tag).then(resp => resp.data);
      this.set('model.obj_costcenter', obj_costcenter);
    },
    actions: {
      salvar: function () {
        let item_data = Ember.get(this, 'dataItem');
        let organization = Ember.get(this, 'dataItem.organization');
        let organizationId = organization ? organization.id : '';
        if (Ember.get(this, 'accountUser.organizationId')) organizationId = Ember.get(this, 'accountUser.organizationId');
        var userId = item_data.userId;

        if (Ember.get(this, 'accountUser.type') != Ember.get(this, 'adminValue')) {
          userId = Ember.get(this, 'accountUser.id');
        }

        let costCenters = this.get('model.costCenterSelected');
        let item = {
          id: item_data.id,
          name: item_data.name,
          phone: item_data.phone,
          mail: item_data.mail,
          active: item_data.active,
          costCenters: costCenters,
          organizationId: organizationId,
          userId: userId
        };
        let _object = {
          data: {
            type: "SendMonitorings",
            attributes: item
          }
        };

        if (item.name.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink('SendMonitorings');
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(`${url}/save`, __rp);
            this.preloader.toggleClass('d-none');
            call.then(() => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.transitionToRoute('transmissao.list');
            }, () => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
            });
          } else {
            let url = this.baseapi.apiLink('SendMonitorings/save');
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(() => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
            }, () => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
            });
          }
        }
      },
      apagar: function () {
        let item_data = Ember.get(this, 'dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink('SendMonitorings');
          let call = this.baseapi.delete(url + '/delete/' + item_data.id);
          this.preloader.toggleClass('d-none');
          call.then(() => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('transmissao.list');
          }, () => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
          });
        }
      },

      setOrganization(item) {
        Ember.set(this, 'dataItem.organization', item);
        Ember.set(this, 'dataItem.organizationId', item.id);
        Ember.set(this, 'model.organizationId', item.id);
        this.loadCostCenter();
      },

      setCliente(item) {
        Ember.set(this, 'dataItem.account', item);
        Ember.set(this, 'dataItem.userId', item.id);
        this.loadCostCenter();
      },

      clearClient() {
        Ember.set(this, 'dataItem.account', null);
        Ember.set(this, 'dataItem.userId', '');
        this.send('salvar');
      },

      clearOrganization() {
        Ember.set(this, 'dataItem.organization', null);
        Ember.set(this, 'dataItem.organizationId', '');
        this.send('salvar');
      },

      addTagFileReturn(tag) {
        let list = this.get('model.costCenterSelected');

        if (!list.find(x => x.id == tag.id)) {
          this.get('model.costCenterSelected').pushObject(tag);
        }
      },

      removeTagFileReturn(index) {
        this.get('model.costCenterSelected').removeAt(index);
      }

    }
  });

  _exports.default = _default;
});