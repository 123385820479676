define("printpost/controllers/operacao/fornecedores", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    taskFilter: (0, _emberConcurrency.task)(function* () {
      let dateStart = this.get('model.dateStart');
      let objDate = new Date(dateStart);
      let dateFilterIni = `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T00:00:00-03:00`;
      let dateFilterEnd = `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T23:59:59-03:00`;
      const strDate = `${this.baseapi.padStr(objDate.getDate())}/${this.baseapi.padStr(objDate.getMonth() + 1)}/${objDate.getFullYear()}`;
      var query = {
        filter: {
          where: {
            status: {
              inq: ['Finalizado', 'Em Execução']
            },
            or: [{
              emailService: true
            }, {
              smsService: true
            }],
            and: [{
              createdAt: {
                gte: dateFilterIni
              }
            }, {
              createdAt: {
                lte: dateFilterEnd
              }
            }]
          }
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');

      try {
        let url = this.baseapi.apiLink('Providers/quality');
        let objData = {
          requestList: [],
          providerList: []
        };
        objData = yield this.baseapi.getHttp(`${url}?${strquery}`).then(x => x.data);
        this.preloader.toggleClass('d-none');
        objData.requestList.forEach(x => {
          x.chart = {
            "datasets": [{
              "label": "Desempenho",
              "fill": false,
              "data": [x.received, x.notReceived],
              "backgroundColor": ["#50e2d4", "#ff8da0"],
              "borderColor": ["#50e2d4", "#ff8da0"],
              "borderWidth": 0
            }],
            "color": ["#50e2d4", "#ff8da0"],
            "labels": ["Recebido", "Não recebido"]
          };
        });
        objData.providerList.forEach(x => {
          x.chart = {
            "datasets": [{
              "label": "Desempenho",
              "fill": false,
              "data": [x.received, x.notReceived],
              "backgroundColor": ["#50e2d4", "#ff8da0"],
              "borderColor": ["#50e2d4", "#ff8da0"],
              "borderWidth": 0
            }],
            "color": ["#50e2d4", "#ff8da0"],
            "labels": ["Recebido", "Não recebido"]
          };
        });
        this.set('model.data', objData.requestList);
        this.set('model.providerData', objData.providerList);
        this.set('model.strDate', strDate);
      } catch (error) {
        console.log(error);
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao consultar dados', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    options: Ember.computed(function () {
      return {
        "responsive": true,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#000"
          }
        },
        "scales": {
          "yAxes": [{
            "display": false,
            "ticks": {
              "beginAtZero": false
            },
            "gridLines": {
              "display": false
            }
          }],
          "xAxes": [{
            "display": false,
            "gridLines": {
              "display": false
            }
          }]
        }
      };
    }),
    actions: {
      beforeMonth() {
        let m = this.get('model.dateStart');
        m.setDate(m.getDate() - 1);
        this.set('model.dateStart', m);
        this.get('taskFilter').perform();
      },

      afterMonth() {
        let m = this.get('model.dateStart');
        m.setDate(m.getDate() + 1);
        this.set('model.dateStart', m);
        this.get('taskFilter').perform();
      }

    }
  });

  _exports.default = _default;
});