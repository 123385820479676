define("printpost/components/validated-input/types/-themes/uikit/select", ["exports", "ember-validated-form/components/validated-input/types/-themes/uikit/select"], function (_exports, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _select.default;
    }
  });
});