define("printpost/routes/peoples/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    model: async function () {
      var url = this.baseapi.apiLink('OmniChannelPeople/list?filter[limit]=6000');
      const listData = await this.baseapi.getHttp(url).then(r => r.data);
      return Ember.RSVP.hash({
        peopleHistories: listData
      });
    }
  });

  _exports.default = _default;
});