define("printpost/routes/services/test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    test: Ember.inject.service('test-service'),
    model: async function () {
      // return this.modelFor('services');
      var order = this.test.verifySendOrder();
      let dataRequest = JSON.parse(localStorage.getItem('storage:request'));
      var reqId = localStorage.getItem('newServiceRequestId');
      var url = this.baseapi.apiLink(`Requests/${reqId}/bind-template`);
      var bindingTemplates = await this.baseapi.getHttp(url).then(resp => resp.data);
      return Ember.RSVP.hash({
        order: order,
        bindingTemplates: bindingTemplates
      });
    }
  });

  _exports.default = _default;
});