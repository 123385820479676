define("printpost/services/mask", ["exports", "ember-text-mask"], function (_exports, _emberTextMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    lastPos: 0,

    do(text, mask) {
      let result = (0, _emberTextMask.conformToMask)(text, mask);
      let maskedValue = result.conformedValue;
      return maskedValue;
    },

    maskInput(input, text, mask) {
      let maskedValue = this.do(text, mask);
      let pos = this.getCursorPosition(input);
      let lastPosition = 0;

      if (input[0].lastPosition) {
        lastPosition = input[0].lastPosition;
      } // console.log("lastPosition:", lastPosition)
      // console.log("pos:", pos);


      input.val(maskedValue);
      this.setTextCursorPosition(input, pos, lastPosition, maskedValue, mask);
    },

    setTextCursorPosition(ele, pos, lastPosition, maskedValue, mask) {
      let placeholders = [];
      let regexs = {};

      for (let i = 0; i < mask.length; i++) {
        if (typeof mask[i] === 'string') {
          placeholders.push(mask[i]);
        } else {
          regexs[i] = mask[i];
        }
      } // console.log(placeholders);


      let index = pos - 1;
      let character = maskedValue.substr(index, 1);
      let isPlaceholder = placeholders.includes(character);

      if (lastPosition > pos) {
        // console.log(placeholders)
        if (isPlaceholder) {
          pos = pos - 1;
        }

        ele[0].lastPosition = pos;
      } else {
        if (isPlaceholder) {
          pos = pos + 1;
        } else if (character == '_') {
          pos = pos - 1;
        }

        ele[0].lastPosition = pos;
      }

      ele.prop("selectionStart", pos);
      ele.prop("selectionEnd", pos);
    },

    getCursorPosition(element) {
      var el = Ember.$(element).get(0);
      var pos = 0;

      if ('selectionStart' in el) {
        pos = el.selectionStart;
      } else if ('selection' in document) {
        el.focus();
        var Sel = document.selection.createRange();
        var SelLength = document.selection.createRange().text.length;
        Sel.moveStart('character', -el.value.length);
        pos = Sel.text.length - SelLength;
      }

      return pos;
    }

  });

  _exports.default = _default;
});