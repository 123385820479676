define("printpost/routes/prices/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: async function () {
      var url = this.baseapi.apiLink('PricingTables/list?filter[include]=channel&filter[include]=priceValues');
      var saida = [];
      var robj = await this.baseapi.getHttp(url).then(r => r.data);
      robj.forEach(a => {
        a.priceValuesQtd = a.priceValues.length;
      });
      return Ember.RSVP.hash({
        data: robj
      });
    }
  });

  _exports.default = _default;
});