define("printpost/services/user-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import {task} from 'ember-concurrency';
  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    logger: Ember.inject.service('debugger'),
    counters: Ember.computed(function () {
      return {
        total: 0,
        newer: 0
      };
    }),

    load() {
      if (this.get('session.isAuthenticated')) {
        this.set('counters.total', 0);
        this.set('counters.newer', 0);
        this.logger.debug('Loading Data');
        return this.get('store').query('notification', {}).then(nots => {
          this.set('notifications', nots);
          this.set('counters.total', this.get('notifications').get('length'));
          this.set('counters.newer', this.get('notifications').filterBy('state', 'unread').get('length'));
        });
      }

      return Ember.RSVP.resolve();
    },

    reload() {
      this.load();
    }

  });

  _exports.default = _default;
});