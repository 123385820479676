define("printpost/controllers/configurations/company", ["exports", "ember-concurrency", "printpost/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const newAddress = Ember.Object.extend({
    endereco: null,
    bairro: null,
    numero: null,
    complemento: null,
    uf: null,
    cep: null,
    cidade: null
  });

  var _default = Ember.Controller.extend({
    newAddress: newAddress.create(),
    cidades: Ember.computed.mapBy('model.city', 'cidades'),
    enderecos: Ember.computed.mapBy('model.address.data', 'attributes'),
    company: Ember.computed('model.company', function () {
      return this.get('model.company');
    }),
    // companyId: computed('model.company', function () {
    //   return this.get('model.company.data.id');
    // }),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    debugger: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    user: Ember.computed('session.data.authenticated', function () {
      return this.get('session.data.authenticated');
    }),
    createAddressTask: (0, _emberConcurrency.task)(function* (newAddress) {
      const USER = this.get('session.data.authenticated');
      let address = this.store.createRecord('address', {
        endereco: Ember.get(newAddress, 'endereco'),
        bairro: Ember.get(newAddress, 'bairro'),
        numero: Ember.get(newAddress, 'numero'),
        owner: Ember.get(newAddress, 'owner'),
        type: Ember.get(newAddress, 'type'),
        complemento: Ember.get(newAddress, 'complemento'),
        uf: Ember.get(newAddress, 'uf.siglaUf'),
        cep: Ember.get(newAddress, 'cep'),
        cidade: Ember.get(newAddress, 'cidade'),
        userId: Ember.get(USER, 'userId')
      });

      try {
        yield address.save();
        this.toast.success('Sucesso', 'Endereço criado com sucesso!', {
          positionClass: 'toast-bottom-right'
        });
        this.set('isShowingTranslucent', false);
        Ember.getOwner(this).lookup('route:configurations.company').refresh();
      } catch (err) {
        this.toast.error(err, 'Erro!', {
          positionClass: 'toast-bottom-right'
        });
        return err;
      }
    }).maxConcurrency(3).enqueue(),
    uploadFile: (0, _emberConcurrency.task)(function* (file) {
      let post_data = {
        name: Ember.get(file, 'name'),
        size: Ember.get(file, 'size'),
        id: Ember.get(file, 'id')
      };
      Ember.set(this.company, 'fileLogo', post_data.id);

      try {
        let token = this.get('session.data.authenticated.id');
        /*
        file.readAsDataURL().then(function (url) {
          //
        });
        //*/

        let opts = {
          'accepts': 'application/json,application/vnd.api+json',
          'headers': {
            'Authorization': 'Bearer ' + token
          },
          'data': post_data
        };
        let urlbase = [_environment.default.APP.API.host, _environment.default.APP.API.namespace].join('/');
        let response = yield file.upload(urlbase + '/FileContainers/printpost-dev/upload' + '?access_token=' + token, opts);

        if (response) {// print(response.body.data.attributes.file);
        }

        this.company.save();
      } catch (e) {// fileRecord.rollback();
      }
    }).maxConcurrency(3).enqueue(),
    actions: {
      createAddress: function (newAddress) {
        Ember.get(this, 'createAddressTask').perform(newAddress);
      },
      updateCity: function (cidade) {
        Ember.set(this.get('newAddress'), 'cidade', cidade);
      },
      updateUf: function (uf) {
        Ember.set(this.get('newAddress'), 'uf', uf);
      },

      uploadImage(file) {
        Ember.get(this, 'uploadFile').perform(file);
      }

    }
  });

  _exports.default = _default;
});