define("printpost/validations/password", ["exports", "ember-changeset-validations/validators", "printpost/validators/password-strength"], function (_exports, _validators, _passwordStrength) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // local validator
  var _default = {
    oldPassword: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 3,
      max: 25
    })],
    newPassword: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 3
    }), (0, _passwordStrength.default)({
      minScore: 80
    })],
    confirmNew: [(0, _validators.validatePresence)(true), (0, _validators.validateConfirmation)({
      on: 'newPassword'
    })] // terms: [
    //   validateInclusion({
    //     list: [true],
    //     message: "Please accept the terms and conditions!"
    //   })
    // ],

  };
  _exports.default = _default;
});