define("printpost/controllers/organization/edit", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataItem: Ember.computed('model', function () {
      return this.get('model.item');
    }).property().volatile(),
    dataEmpty: Ember.computed(function () {
      return null;
    }),
    dataEmailAddress: Ember.computed('dataEmailAddress', function () {
      return {
        nameContact: '',
        emailContact: ''
      };
    }),
    dataAddressesOrganization: Ember.computed('model.address', function () {
      var model = this.get('model.address');
      return model;
    }).property().volatile(),
    dataEndereco: Ember.computed('dataEndereco', function () {
      return {
        id: '',
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        complemento: '',
        numero: '',
        cidadecep: '',
        userId: '',
        organizationId: '',
        type: 'ORGANIZATION_ADDRESS'
      };
    }).property().volatile(),
    reloadAddress: function (id) {
      //addres
      this.preloader.toggleClass('d-none');
      let url_address = this.baseapi.apiLink('Addresses/list?filter[where][type]=ORGANIZATION_ADDRESS');

      if (id && id.length > 10) {
        url_address += "&filter[where][organizationId]=" + id;
      }

      this.baseapi.getHttp(url_address).then(resp => {
        this.set('model.address', resp.data); //set(this, 'dataAddressesAccount', resp.data);

        let addressOrganization = resp.data.find(x => x.type.toUpperCase() == 'ORGANIZATION_ADDRESS');
        this.set('model.organizationAddress', addressOrganization);
        this.preloader.toggleClass('d-none');
      });
    },
    dataList: Ember.computed('model.data', function () {
      return this.get('model.data');
    }),
    msgPeriodoFatura: Ember.computed('msgPeriodoFatura', function () {
      return '';
    }),
    datePeriodo: [],
    flatDateValue: Ember.computed('flatDateValue', function () {
      let item = Ember.get(this, 'dataItem');
      let vfm = item.invoiceConfig.period[0].split("-");
      let vfnm = item.invoiceConfig.period[1].split("-");
      let msg = vfm[1] == vfnm[1] ? "do mesmo mês" : "do mês seguinte";
      Ember.set(this, 'msgPeriodoFatura', msg);
      let ret = [new Date(item.invoiceConfig.period[0]), new Date(item.invoiceConfig.period[1])];
      return ret;
    }).property().volatile(),
    emailAddresType: Ember.computed(function () {}),
    actions: {
      salvar: function (redirect = true) {
        let item_data = Ember.get(this, 'dataItem');
        let item = {
          id: item_data.id,
          cnpj: item_data.cnpj,
          username: item_data.username,
          email: item_data.email,
          phone1: item_data.phone1,
          razaoSocial: item_data.razaoSocial,
          inscricaoEstadual: item_data.inscricaoEstadual,
          options: item_data.options,
          invoiceConfig: item_data.invoiceConfig
        };
        let _object = {
          data: {
            type: "Organization",
            attributes: item
          }
        };
        let isValide = true;

        if (item.username.length <= 0 || item.email.length <= 0) {
          isValide = false;
        }

        if (item.cnpj.length == 18 && (item.razaoSocial.length <= 0 || item.inscricaoEstadual.length <= 0)) {
          isValide = false;
        }

        if (isValide) {
          if (!item.id) {
            let url = this.baseapi.apiLink('Organizations');
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });

              if (redirect) {
                this.transitionToRoute('organization.list');
              } else {
                Ember.set(this, 'model.item.id', resp.data.success[0].source.id);
                this.send('salvarEndereco', false);
              }
            }, error => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              this.set('dataEmpty', error);
            });
          } else {
            let url = this.baseapi.apiLink('Organizations/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.set('dataEmpty', resp);
            }, error => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
              this.set('dataEmpty', error);
            });
          }
        }
      },
      apagar: function () {
        let item_data = this.get('model.item');

        if (item_data.id) {
          let url = this.baseapi.apiLink('Organizations');
          let call = this.baseapi.delete(url + '/' + item_data.id);
          call.then(resp => {
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('organization.list');
            this.set('dataEmpty', resp);
          }, error => {
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            this.set('dataEmpty', error);
          });
        }
      },
      selectAddress: function (address) {
        // set(this, 'dataEnderecoAccount', address);
        Ember.set(this, 'dataEndereco', address);
        this.set('model.organizationAddress', address);
        this.send('salvarEndereco');
      },
      setAddress: function (address) {
        let d = {
          id: address.id,
          nome: address.nome,
          endereco: address.endereco,
          bairro: address.bairro,
          cidade: address.cidade,
          uf: address.uf,
          cep: address.cep,
          complemento: address.complemento,
          numero: address.numero,
          cidadecep: address.cidadecep,
          type: address.type,
          organizationId: address.organizationId
        };
        Ember.set(this, 'dataEndereco', d);
      },
      clearDataEndereco: function () {
        let d = {
          id: '',
          nome: '',
          endereco: '',
          bairro: '',
          cidade: '',
          uf: '',
          cep: '',
          complemento: '',
          numero: '',
          cidadecep: '',
          type: '',
          organizationId: ''
        };
        Ember.set(this, 'dataEndereco', d);
      },

      buscaCEP() {
        let t = Ember.$('#cep_addres').val();
        t = (0, _globalFunctions.removeSpecialChar)(t).replace("-", "").replace(".", "");
        Ember.$('#cep_addres').val(t);

        if (t.length < 8) {
          this.toast.error('CEP Inválido!', 'Informe CEP válido.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let url = "//viacep.com.br/ws/" + t + "/json";
        let r = this.baseapi.getHttp(url, false);
        r.then(response => {
          let data = response.data;
          Ember.set(this, 'dataEndereco.cep', t);
          Ember.set(this, 'dataEndereco.endereco', data.logradouro);
          Ember.set(this, 'dataEndereco.bairro', data.bairro);
          Ember.set(this, 'dataEndereco.complemento', data.complemento);
          Ember.set(this, 'dataEndereco.cidade', data.localidade);
          Ember.set(this, 'dataEndereco.uf', data.uf);
          Ember.set(this, 'dataEndereco.cidadecep', data.localidade + ' - ' + data.uf);
        });
      },

      salvarEndereco: function (primEvent = true) {
        let modelName = 'Address';
        let modelPluralName = 'Addresses';
        let item = Ember.get(this, 'dataEndereco');
        let organization = Ember.get(this, 'dataItem');

        if (organization && organization.id) {
          item.organizationId = organization.id;
        } else {
          if (primEvent) {
            this.send('salvar', false);
            organization = Ember.get(this, 'dataItem');
            item.organizationId = organization.id;
            return;
          }
        }

        item.type = 'ORGANIZATION_ADDRESS';
        let _object = {
          data: {
            type: modelName,
            attributes: item
          }
        };

        if (item.nome.length > 0 && item.numero > 0 && item.endereco.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink(modelPluralName);
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              let listAddress = this.get('model.address');

              if (listAddress && listAddress.length > 1) {
                //atualizando demais endereços
                let url2 = this.baseapi.apiLink(modelPluralName + "/update-organization-register");
                this.baseapi.patch(url2, {
                  data: {
                    organizationId: item.organizationId,
                    addressId: item.id
                  }
                }).then(sucessUpdate => {
                  this.preloader.toggleClass('d-none');

                  if (sucessUpdate.data.errors) {
                    this.toast.error('Erro interno! #2', 'Demais dados não foram atualizados!', {
                      positionClass: 'toast-bottom-right'
                    });
                  }
                }, errorUpdate => {
                  this.preloader.toggleClass('d-none');
                  this.toast.error('Erro interno! #2', 'Demais dados não foram atualizados!', {
                    positionClass: 'toast-bottom-right'
                  });
                  this.set('dataEmpty', errorUpdate);
                });
              } else {
                this.preloader.toggleClass('d-none');
              }

              this.set('dataEmpty', resp);
              this.reloadAddress(item.organizationId);
            }, error => {
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
              this.set('dataEmpty', error);
            });
          } else {
            let url = this.baseapi.apiLink(modelPluralName + '/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              item.id = __rp.data.id;
              let listAddress = this.get('model.address');

              if (listAddress && listAddress.length > 1) {
                //atualizando demais endereços
                let url2 = this.baseapi.apiLink(modelPluralName + "/update-organization-register");
                this.baseapi.patch(url2, {
                  data: {
                    organizationId: item.organizationId,
                    addressId: item.id
                  }
                }).then(sucessUpdate => {
                  this.preloader.toggleClass('d-none');

                  if (sucessUpdate.data.errors) {
                    this.toast.error('Erro interno! #2', 'Demais dados não foram atualizados!', {
                      positionClass: 'toast-bottom-right'
                    });
                  }
                }, errorUpdate => {
                  this.preloader.toggleClass('d-none');
                  this.toast.error('Erro interno! #2', 'Demais dados não foram atualizados!', {
                    positionClass: 'toast-bottom-right'
                  });
                  this.set('dataEmpty', errorUpdate);
                });
              } else {
                this.preloader.toggleClass('d-none');
              }

              this.set('dataEmpty', resp);
              this.reloadAddress(item.organizationId);
            }, error => {
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
              this.set('dataEmpty', error);
            });
          }
        } else {
          this.toast.error('Item Não cadastrado!', 'Verifique os campos obrigatórios!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      apagarEndereco: function () {
        let modelPluralName = 'Addresses';
        this.preloader.toggleClass('d-none');
        let item_data = Ember.get(this, 'dataEndereco');

        if (item_data.id) {
          let url = this.baseapi.apiLink(modelPluralName);
          let call = this.baseapi.delete(url + '/' + item_data.id);
          call.then(resp => {
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
            this.set('dataEmpty', resp);
          }, error => {
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
            this.set('dataEmpty', error);
          });
          this.reloadAddress(item_data.organizationId);
        }
      },

      updateCPF(unmasked, masked) {
        if (unmasked.length == 11 || unmasked.length == 14) {
          if ((0, _globalFunctions.validaCpfCnpj)(masked)) {
            this.toast.success('CPF/CNPJ válido!', 'CPF/CNPJ válido!', {
              positionClass: 'toast-bottom-right'
            });
          } else {
            this.toast.error('CPF/CNPJ inválido!', 'CPF/CNPJ inválido, verificar dados inseridos', {
              positionClass: 'toast-bottom-right'
            });
          }
        }

        Ember.set(this, 'model.item.cnpj', masked);
      },

      updatePhone(type, unmasked, masked) {
        if (type == 1) {
          Ember.set(this, 'model.item.phone1', masked);
        }
      },

      ativarMenu: function (id, val) {
        let menuId = id == undefined ? 0 : id;
        let list = this.get('model.menus');
        let organizationId = Ember.get(this, 'model.item.id');
        let item = list.find(x => x.id == id);

        if (item) {
          Ember.set(item, 'active', !val);
          let itemMenuRole = {
            "active": !val,
            "name": item.name,
            "organizationId": organizationId,
            "menuId": menuId,
            "order": item.menuRole.order
          };
          let __menuRole = {
            data: {
              type: "MenuRoles",
              attributes: itemMenuRole
            }
          };

          if (item.menuRole.id) {
            let url = this.baseapi.apiLink('MenuRoles/' + item.menuRole.id);
            let call = this.baseapi.patch(url, __menuRole);
            __menuRole.data.id = item.menuRole.id;
            itemMenuRole.order = item.menuRole.order;
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Atualizado!', 'Menu atualizado com sucesso!', {
                positionClass: 'toast-bottom-right'
              });
              this.set('dataEmpty', resp);
            }, error => {
              this.preloader.toggleClass('d-none');
              this.set('dataEmpty', error);
            });
          } else {
            let url = this.baseapi.apiLink('MenuRoles');
            let call = this.baseapi.post(url, __menuRole);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none'); //not to do

              Ember.set(itemMenuRole, 'id', resp.data.data.id);
              Ember.set(item, 'menuRole', itemMenuRole);
              this.toast.success('Atualizado!', 'Menu atualizado com sucesso!', {
                positionClass: 'toast-bottom-right'
              });
            }, error => {
              this.preloader.toggleClass('d-none');
              this.set('dataEmpty', error);
            });
          }
        }
      },

      clearEmailAddress() {
        this.set('dataEmailAddress', {
          nameContact: '',
          emailContact: ''
        });
      },

      addEmailAddress() {
        var ger = this.get('dataEmailAddress');
        let item_data = Ember.get(this, 'model.item');
        let emailAddresType = Ember.get(this, 'emailAddresType');
        let newEmails = [];

        if (emailAddresType == 1) {
          if (item_data.options.emailAddress.length == 0) newEmails.push(ger);
        } else {
          if (item_data.options.emailReplyAddress.length == 0) newEmails.push(ger);
        }

        if (emailAddresType == 1) {
          item_data.options.emailAddress.forEach(e => {
            if (e.emailContact != ger.emailContact && !newEmails.find(rf => rf.emailContact == ger.emailContact)) {
              newEmails.push(ger);
              return;
            }

            ;
          });
        } else {
          item_data.options.emailReplyAddress.forEach(e => {
            if (e.emailContact != ger.emailContact && !newEmails.find(rf => rf.emailContact == ger.emailContact)) {
              newEmails.push(ger);
              return;
            }
          });
        }

        var arr = emailAddresType == 1 ? Ember.get(item_data, 'options.emailAddress') : Ember.get(item_data, 'options.emailReplyAddress');
        newEmails = newEmails.concat(arr);

        if (emailAddresType == 1) {
          Ember.set(item_data, 'options.emailAddress', newEmails);
        } else {
          Ember.set(item_data, 'options.emailReplyAddress', newEmails);
        }

        this.set('dataItem', item_data);
        this.set('model.item', item_data);
      },

      removeEmailAddress(email) {
        let item_data = Ember.get(this, 'model.item');
        let newEmails = [];
        let emailAddresType = Ember.get(this, 'emailAddresType');

        if (emailAddresType == 1) {
          item_data.options.emailAddress.forEach(e => {
            if (e.emailContact != email) newEmails.push(e);
          });
        } else {
          item_data.options.emailReplyAddress.forEach(e => {
            if (e.emailContact != email) newEmails.push(e);
          });
        }

        if (emailAddresType == 1) {
          Ember.set(item_data, 'options.emailAddress', newEmails);
        } else {
          Ember.set(item_data, 'options.emailReplyAddress', newEmails);
        }

        Ember.set(this, 'dataItem', item_data);
        Ember.set(this, 'model.item', item_data);
      },

      setEmailAddress(emailItem) {
        this.set('dataEmailAddress', emailItem);
      },

      setDivisionData(val) {
        this.set('model.item.options.viewOrganizationData', val);
        this.set('dataItem.options.viewOrganizationData', val);
      },

      setServiceSMS(val) {
        this.set('model.item.options.smsServiceId', val);
        this.set('dataItem.options.smsServiceId', val);
      },

      setValidateWhatsapp(val) {
        this.set('model.item.options.validateWhatsapp', val);
        this.set('dataItem.options.validateWhatsapp', val);
      },

      setWhatsappClickmassa(val) {
        this.set('model.clickMassaOptions.active', val);
        const item = this.get('model.clickMassaOptions');
        this.set('model.item.options.clickmassa', item);
      },

      setDivisionInvoice(val) {
        this.set('model.item.options.invoiceOrganizationData', val);
        this.set('dataItem.options.invoiceOrganizationData', val);
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });

        if (!dateFlat) {
          return;
        }

        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = year + "-" + month + "-" + day + "T11:00";
        let d = this.datePeriodo;

        if (d.length == 0) {
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        } else if (d.length == 1) {
          let st = year + "-" + month + "-" + day + "T11:00";
          d.push(st);
          Ember.set(this, 'cStrDateEnd', st);
        } else if (d.length == 2) {
          d = [];
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
          Ember.set(this, 'msgPeriodoFatura', '');
        }

        if (d.length == 2) {
          let vfm = d[0].split("-");
          let vfnm = d[1].split("-");
          let msg = vfm[1] == vfnm[1] ? "do mesmo mês" : "do mês seguinte";
          Ember.set(this, 'msgPeriodoFatura', msg);
          Ember.set(this, 'model.item.invoiceConfig.period', d);
        }

        this.datePeriodo = d;
      },

      checkFlatPicker() {},

      setModePayInvoice(val) {
        Ember.set(this, 'model.item.invoiceConfig.invoiceModePay', val);
      },

      setEmailAddresType(value) {
        Ember.set(this, 'emailAddresType', value);
      }

    }
  });

  _exports.default = _default;
});