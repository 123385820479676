define("printpost/templates/components/url-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JnaMcFrQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"widthValue\"]]],null,{\"statements\":[[7,\"img\"],[12,\"src\",[28,[[21,\"defaultLogo\"]]]],[11,\"alt\",\"\"],[12,\"style\",[28,[\"width: \",[21,\"widthValue\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"img\"],[12,\"src\",[28,[[21,\"defaultLogo\"]]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/url-logo.hbs"
    }
  });

  _exports.default = _default;
});