define("printpost/components/tag-having", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // capturar todas as teclas pressionadas
    //   didRender: function() {
    //     this.$().attr({ tabindex: 1 });
    //     this.$().focus();
    //   },
    //   shortcutKey: null,
    //   keyDown(event) {
    //     this.set('shortcutKey', String.fromCharCode(event.keyCode));
    //     console.log(String.fromCharCode(event.keyCode));
    //   }
    actions: {
      showItens: function () {
        let list_items = Ember.$('.taghaving-item');
        Ember.$('#tag-eye').toggleClass('fa-eye');
        Ember.$('#tag-eye').toggleClass('fa-eye-slash');
        Ember.$.each(list_items, function (index, item) {
          Ember.$(item).toggleClass('show-tags');
        });
        this.dataEvent();
      }
    }
  });

  _exports.default = _default;
});