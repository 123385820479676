define("printpost/templates/components/notification-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HaYCO5Cp",
    "block": "{\"symbols\":[\"notification\",\"&default\"],\"statements\":[[7,\"button\"],[11,\"class\",\"notification-button\"],[9],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[4,\"if\",[[23,[\"counters\",\"newer\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"floating ui circular mini label\"],[9],[1,[23,[\"counters\",\"newer\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"ui popup  transition hidden notification\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"ui menu\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"title\"],[9],[1,\"Notificações\",false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"right menu\"],[9],[7,\"a\"],[11,\"href\",\"#\"],[9],[1,\"Marcar tudo como lido\",false],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"ui fluid link celled selection list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"notifications\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[28,[\"item \",[22,1,[\"state\"]]]]],[3,\"action\",[[22,0,[]],\"clickedOn\",[22,1,[]]]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n          \"],[1,[22,1,[\"title\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n          \"],[1,[22,1,[\"message\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"footer\"],[9],[0,\"\\n          \"],[1,[27,\"moment-from-now\",[[22,1,[\"created_at\"]]],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/notification-dropdown.hbs"
    }
  });

  _exports.default = _default;
});