define("printpost/templates/services/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6l3ArdWY",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"content-bg au-boxshadow mt-0\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[11,\"style\",\"padding: 80px;\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"completed-mark\"],[9],[0,\"\\n                \"],[7,\"span\"],[11,\"class\",\"pt-5 drip-icon drip-meter\"],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"completed-text w-60 mx-auto\"],[9],[0,\"\\n                \"],[7,\"p\"],[9],[0,\"Excelente, agora a próxima etapa\"],[10],[0,\"\\n                \"],[7,\"h4\"],[9],[0,\"Você já selecionou os templates,agora nessa próxima etapa precisaremos do seu arquivo, de certas definições e depois basta finalizar.\"],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"text-center proceed-button w-30 mt-50\"],[9],[0,\"\\n                \"],[7,\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"nextRouter\"],null]],[11,\"class\",\"btn btn-lg btn-block btn-success pt-10 pb-10\"],[9],[0,\"\\n                    \"],[7,\"span\"],[9],[0,\"AVANÇAR\"],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/services/info.hbs"
    }
  });

  _exports.default = _default;
});