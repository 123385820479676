define("printpost/controllers/invoice/list", ["exports", "ember-concurrency", "printpost/utils/globalFunctions", "printpost/config/environment"], function (_exports, _emberConcurrency, _globalFunctions, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = Ember.Object.extend({
    statusInvoice: -99,
    validationInvoice: -99,
    paymentInvoice: -99,
    filterReturn: "filter_mes",
    empty_resp: null,
    list_request_id: null,
    list_request_id_all: false,
    request_closed: '0,00',
    request_closedv2: '0,00',
    userId: null,
    currentMode: 'carta',
    invoiceReopenId: '',
    competencia: null,
    invoiceReferenceId: null
  });

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataInvoices: Ember.computed('model.dataInvoices', function () {
      var model = this.get('model.dataInvoices');
      return model;
    }),
    dataUser: Ember.computed('dataUser', function () {
      // let d = this.baseapi.getCurrentUser();
      const d = this.get('session.data.authenticated');
      return d.account;
    }).property().volatile(),
    adminValue: Ember.computed('adminValue', function () {
      let s = this.baseapi.getAdmValue();
      return s;
    }),
    dataCompetencias: Ember.computed('dataCompetencias', function () {
      var meses = 7;
      var date = new Date();
      date.setMonth(date.getMonth() + 1);
      var competencias = [];

      for (let i = 0; i < meses; i++) {
        var m = date.getMonth() + 1;
        var str = `${this.baseapi.padStr(m)}/${date.getFullYear()}`;
        competencias.push(str);
        date.setMonth(date.getMonth() - 1);
      }

      return competencias;
    }),
    checkedAll: Ember.computed('checkedAll', function () {
      return false;
    }).property().volatile(),
    menusFilter: Ember.computed('menusFilter', function () {
      var itens = ['filter_mes', 'filter_ano', 'filter_inicio'];
      return itens;
    }),
    dataRequestsClose: Ember.computed('dataRequestsClose', function () {
      let d = [];
      return d;
    }).property().volatile(),
    dataRequestsSelect: Ember.computed('dataRequestsClose', function () {
      return [];
    }).property().volatile(),
    dataRequestsCloseView: Ember.computed('dataRequestsCloseView', function () {
      let d = [];
      return d;
    }).property().volatile(),
    dataRequestsCloseView2: Ember.computed('dataRequestsCloseView2', function () {
      let d = [];
      return d;
    }).property().volatile(),
    dataItensFilter: Ember.computed(function () {
      let d = [];
      return d;
    }),
    dataRequestsCloseServices: Ember.computed('dataRequestsCloseServices', function () {
      let d = {
        carta: false,
        email: false,
        sms: false,
        whatsapp: false,
        registry_office: false
      };
      return d;
    }).property().volatile(),
    reloadViewRequestClosed: Ember.computed(function () {
      return true;
    }),
    dataRequestsList: Ember.computed('dataRequestsList', function () {
      let d = [];
      return d;
    }).property().volatile(),
    // statusInvoice: computed('statusInvoice', function() {
    //   let m = -99;
    //   return m;
    // }),
    variables: variables.create({
      statusInvoice: -99
    }),
    taskFilterReturn: (0, _emberConcurrency.task)(function* (showMonth = false) {
      //type return
      // var url_typereturn = this.baseapi.apiLink('TypeReturns/list?filter[order]="channelName"');
      yield (0, _emberConcurrency.timeout)(100);
      this.preloader.toggleClass('d-none');
      this.set('model.showFilterMonth', showMonth);
      var dataIni = Ember.get(this, 'cStrDateBegin');
      var dataFim = Ember.get(this, 'cStrDateEnd'); // var statusInvoice = get(this, 'statusInvoice');

      var statusInvoice = this.variables.get('statusInvoice');
      var validationInvoice = this.variables.get('validationInvoice');
      var paymentInvoice = this.variables.get('paymentInvoice');
      var query = {
        filter: {
          where: {
            and: [{
              createdAt: {
                gte: new Date(dataIni)
              }
            }, {
              createdAt: {
                lte: new Date(dataFim)
              }
            }]
          }
        }
      };
      let strFind = this.get('model.dataDateFilterStr');

      if (showMonth) {
        let objDate = new Date(dataIni); // let strFind = this.baseapi.padStr(objDate.getMonth() + 1) + "/" + objDate.getFullYear();

        query = {
          filter: {
            where: {
              monthReference: strFind
            }
          }
        };
      }

      if (statusInvoice != -99) {
        query.filter.where.open = statusInvoice;
      }

      if (validationInvoice != -99) {
        query.filter.where.validationMessage = validationInvoice;
      }

      if (paymentInvoice != -99) {
        query.filter.where.statusPayment = paymentInvoice;
      }

      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url_typereturn = this.baseapi.apiLink('Invoices/list');
      let user = Ember.get(this, 'dataUser');

      if (user.type != this.baseapi.getAdmValue() && user.type != 'vendedor') {
        url_typereturn = this.baseapi.apiLink('Invoices/find');
      }

      var obj = yield this.baseapi.getHttp(url_typereturn + "?" + strquery).then(r => r.data);
      var url_invoice = this.baseapi.apiLink('Invoices/list');
      var infoInvoice = yield this.baseapi.getHttp(`${url_invoice}?${strquery}`).then(ri => ri.data);
      this.preloader.toggleClass('d-none');
      var invoicesGroupAttach = [];

      if (obj.length > 0) {
        let itens = obj.filter(x => x.open == 2);
        itens.forEach(item => {
          let objNew = {
            name: 'Fatura Ref: ' + item.reference,
            number: item.invoiceNumber,
            rawFiles: item.rawFiles,
            quantity: obj.filter(x => x.invoiceReferenceId == item.invoiceReferenceId).reduce((total, element) => total += element.quantity, 0),
            invoiceReferenceId: item.invoiceReferenceId
          };

          if (infoInvoice && infoInvoice.length > 0) {
            var itemInvoice = infoInvoice.find(x => x.invoiceNumber == item.invoiceNumber);

            if (itemInvoice) {
              objNew.paymentMessage = itemInvoice.paymentMessage;
              objNew.statusPayment = itemInvoice.statusPayment;
              objNew.total = itemInvoice.total;
            }
          }

          if (invoicesGroupAttach.length == 0) {
            invoicesGroupAttach.push(objNew);
          } else {
            let has = invoicesGroupAttach.find(x => x.invoiceReferenceId == objNew.invoiceReferenceId);

            if (!has) {
              invoicesGroupAttach.push(objNew);
            }
          }
        });
      }

      this.set('model.invoicesGroupAttach', invoicesGroupAttach);
      if (obj) this.set('model.dataInvoices', obj);
    }),
    taskRequestClose: (0, _emberConcurrency.task)(function* (userId, invoiceReferenceId, requestTitle) {
      //type return
      // var url_typereturn = this.baseapi.apiLink('TypeReturns/list?filter[order]="channelName"');
      this.preloader.toggleClass('d-none');
      var url_typereturn = this.baseapi.apiLink(`Invoices/${userId}/invoices`);

      if (invoiceReferenceId || requestTitle) {
        var query = {
          filter: {
            where: {}
          }
        };
        if (invoiceReferenceId) query.filter.where.id = invoiceReferenceId;
        if (requestTitle) query.filter.where.requestTitle = requestTitle;
        url_typereturn += `?${(0, _globalFunctions.apiLinkQuery)(query)}`;
      }

      var dados = yield this.baseapi.getHttp(url_typereturn).then(resp => resp.data);
      this.preloader.toggleClass('d-none');
      var listRequestId = [];
      let dataSet = [];
      let hasDataset = {
        carta: false,
        email: false,
        sms: false
      };
      let currentMode = 'carta';

      if (dados.email && dados.email.length > 0) {
        dataSet = dados.email;
        hasDataset.email = true;
        currentMode = 'email';
      }

      if (dados.sms && dados.sms.length > 0) {
        dataSet = dados.sms;
        hasDataset.sms = true;
        currentMode = 'sms';
      }

      if (dados.carta && dados.carta.length > 0) {
        dataSet = dados.carta;
        hasDataset.carta = true;
        currentMode = 'carta';
      }

      if (dados.whatsapp && dados.whatsapp.length > 0) {
        dataSet = dados.whatsapp;
        hasDataset.whatsapp = true;
        currentMode = 'whatsapp';
      }

      if (dados.registry_office && dados.registry_office.length > 0) {
        dataSet = dados.registry_office;
        hasDataset.registry_office = true;
        currentMode = 'registry_office';
      }

      dataSet.forEach(x => listRequestId.push(x.requestId)); // if(listRequestId.length > 0) this.variables.set('list_request_id', listRequestId);

      Ember.set(this, 'dataRequestsSelect', []);
      Ember.set(this, 'dataRequestsClose', dados);
      Ember.set(this, 'dataRequestsCloseView', dataSet);
      Ember.set(this, 'dataRequestsCloseView2', dataSet);
      Ember.set(this, 'dataRequestsCloseServices', hasDataset);
      this.variables.set('list_request_id', []);
      this.variables.set('currentMode', currentMode);
      return dados;
    }),
    taskRequestList: (0, _emberConcurrency.task)(function* (invoiceReferenceId) {
      //type return
      // var url_typereturn = this.baseapi.apiLink('TypeReturns/list?filter[order]="channelName"');
      this.preloader.toggleClass('d-none');
      var url_typereturn = this.baseapi.apiLink(`Invoices/${invoiceReferenceId}/requests`);
      var dados = yield this.baseapi.getHttp(url_typereturn).then(resp => resp.data);
      this.preloader.toggleClass('d-none');
      this.set('dataRequestsList', dados);
      return dados;
    }),
    taskCampanhaList: (0, _emberConcurrency.task)(function* (requestId) {
      //type return
      // var url_typereturn = this.baseapi.apiLink('TypeReturns/list?filter[order]="channelName"');
      this.preloader.toggleClass('d-none');
      var url_typereturn = this.baseapi.apiLink(`Invoices/${requestId}/campanha`);
      var dados = yield this.baseapi.getHttp(url_typereturn).then(resp => resp.data);
      this.preloader.toggleClass('d-none');
      this.set('dataRequestsList', dados);
      return dados;
    }),
    datePeriodo: Ember.computed(function () {
      return [];
    }),
    cStrDateBegin: Ember.computed('strDateBegin', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-01 00:00:00';
      return strDate;
    }),
    cStrDateEnd: Ember.computed('strDateEnd', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + ' 19:59:00';
      return strDate;
    }),
    isChangeRawFiles: Ember.computed(function () {
      return false;
    }),
    isCreateLoading: Ember.computed(function () {
      return false;
    }),
    invoiceAttachs: Ember.computed('invoiceAttachs', function () {
      let r = [];
      return r;
    }),
    invoiceId: Ember.computed('invoiceId', function () {
      return '';
    }),
    organizationId: Ember.computed('organizationId', function () {
      return '';
    }),
    closeInvoiceId: Ember.computed('closeInvoiceId', function () {
      return '';
    }),
    invoiceUserId: Ember.computed('invoiceUserId', function () {
      return '';
    }),
    invoiceMode: Ember.computed('invoiceMode', function () {
      return '';
    }),
    fileAttachName: Ember.computed('fileAttachName', function () {
      return '';
    }),
    fileAttach: Ember.computed('fileAttach', function () {
      return '';
    }).property().volatile(),
    sendRawFile: function () {
      this.preloader.toggleClass('d-none');
      Ember.set(this, 'isChangeRawFile', true);
      let invId = Ember.get(this, 'invoiceId');
      let mode = Ember.get(this, 'invoiceMode');
      let userId = Ember.get(this, 'invoiceUserId');
      let organizationId = Ember.get(this, 'organizationId');
      var file = Ember.get(this, 'fileAttach');
      let form = new FormData(); // let reqId = localStorage.getItem('newServiceRequestId');

      form.set("type", "invoice_attach");
      form.set("mode", mode);
      form.set("invoiceId", invId);
      form.set("requestId", null);
      form.set("userId", userId);
      form.set("path", 'invoices');
      form.append("file", file);

      if (organizationId.length > 3) {
        form.set("organizationId", organizationId);
      } // let ext = this.baseapi.getFileExtension(file.name);


      let url = this.baseapi.apiLink("rawFiles");
      url += "/upload-file";
      this.baseapi.postFormData(url, form).then(rawObj => {
        this.variables.set('empty_resp', rawObj);
        this.preloader.toggleClass('d-none');
        Ember.set(this, 'isChangeRawFiles', false);
        Ember.set(this, 'fileAttach', '');
        this.toast.success('', 'Arquivo enviado com sucesso.', {
          positionClass: 'toast-bottom-right'
        });

        if (mode == "nota_fiscal") {
          this.send('billedInvoice', invId);
        } else if (mode == 'boleto') {
          this.send('invoiceDueDate', invId);
        } else {
          Ember.get(this, 'taskFilterReturn').perform(true);
        }
      }, error => {
        this.variables.set('empty_resp', error);
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao enviar o arquivo, tente novamente.', {
          positionClass: 'toast-bottom-right'
        });
      });
    },
    myFlatpickrRef: Ember.computed(function () {
      return null;
    }),
    flatMinDate: Ember.computed('flatMinDate', function () {
      return new Date().setDate(new Date().getDate() + 0);
    }),
    actions: {
      actionFilterReturn: function (strfilter) {
        let listFilterMenus = Ember.get(this, 'menusFilter');
        let objDate = new Date();
        let strDateBegin = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-01 00:00:00';
        let strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + ' 19:59:00';

        if (strfilter == "filter_mes") {
          let d1 = new Date(objDate.getFullYear(), objDate.getMonth(), 1);
          let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
          this.set('model.dataDateFilter', d1);
          this.set('model.dataDateFilterStr', str);
        }

        this.variables.set('filterReturn', strfilter); // listFilterMenus.forEach(item => {
        //   if(strfilter != item){
        //     $('#'+item).addClass('text-lightgray');
        //   }else{
        //     $('#'+item).removeClass('text-lightgray');
        //     if(item == 'filter_ano'){
        //       strDateBegin =  objDate.getFullYear() + '-01-01 00:00:00';  
        //       strDateEnd =  objDate.getFullYear() + '-'+ this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + ' 19:59:00';
        //     }else if(item == 'filter_inicio'){
        //       strDateBegin =  '2019-01-01 00:00:00';  
        //       strDateEnd =  objDate.getFullYear() + '-'+ this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + ' 19:59:00';
        //     }
        //   }
        // });

        Ember.get(this, 'myFlatpickrRef').clear();
        Ember.set(this, 'datePeriodo', []);
        Ember.set(this, 'cStrDateBegin', strDateBegin);
        Ember.set(this, 'cStrDateEnd', strDateEnd);
        var showMonth = strfilter == "filter_mes";
        Ember.get(this, 'taskFilterReturn').perform(showMonth);
      },

      actionFilterTypes(value) {
        this.variables.set('statusInvoice', value);
        var showMonth = false;

        if (this.variables.get('filterReturn') == "filter_mes") {
          showMonth = true;
        }

        let idFind = "t0" + value;
        Ember.$('#list_types li').each((index, item) => {
          var lId = Ember.$(item).attr('data-id');

          if (idFind == lId) {
            Ember.$(item).removeClass('text-lightgray');
            Ember.get(this, 'taskFilterReturn').perform(showMonth);
          } else {
            Ember.$(item).addClass('text-lightgray');
          }

          if (idFind == "t0-99") {
            Ember.$(item).removeClass('text-lightgray');
          }
        }); // get(this, 'taskFilterReturn').perform();
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });

        if (!dateFlat) {
          return;
        }

        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = `${day}/${month}/${year}`;
        this.set('model.dueDate', strDate);
      },

      uploadFile() {
        this.sendRawFile();
      },

      setInvoiceAttach(item, mode) {
        Ember.set(this, 'invoiceId', item.invoiceReferenceId);
        Ember.set(this, 'invoiceUserId', item.userId);
        Ember.set(this, 'invoiceMode', mode);

        if (item.organizationId) {
          Ember.set(this, 'organizationId', item.organizationId);
        }
      },

      removeFile(file) {
        let url = this.baseapi.apiLink('rawFiles');
        url += '/remove/' + file.id;
        Ember.set(this, 'isChangeRawFiles', true);
        this.baseapi.delete(url).then(resp => {
          if (resp.data.undefined.success) {
            Ember.set(this, 'isChangeRawFiles', false);
          } else {
            this.toast.error('', 'Erro ao remover o arquivo', {
              positionClass: 'toast-bottom-right'
            });
            Ember.set(this, 'isChangeRawFiles', false);
          }
        }, error => {
          this.variables.set('empty_resp', error);
          this.toast.error('', 'Erro ao remover o arquivo', {
            positionClass: 'toast-bottom-right'
          });
          Ember.set(this, 'isChangeRawFiles', false);
        });
      },

      setFileAttach: function (data) {
        Ember.set(this, 'fileAttach', data.target.files[0]);
      },

      download(file) {
        this.preloader.toggleClass('d-none');
        let url = this.baseapi.apiLink('rawFiles');
        let token = this.baseapi.getToken();
        url += '/download-invoice-attach/' + file.id + '/1?access_token=' + token;
        this.baseapi.getDownload(url, file.name, false);
        this.preloader.toggleClass('d-none');
      },

      billedInvoice: function (id) {
        if (id) {
          var url = this.baseapi.apiLink('Invoices/billed/' + id);
          let call = this.baseapi.patch(url, {});
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.variables.set('empty_resp', resp);
            this.preloader.toggleClass('d-none');
            this.toast.success('', 'Fatura concluída com sucesso!', {
              positionClass: 'toast-bottom-right'
            });
            Ember.get(this, 'taskFilterReturn').perform(true); //not to do
          }, error => {
            this.variables.set('empty_resp', error);
            this.preloader.toggleClass('d-none');
            this.toast.error('', 'Erro ao fechar fatura', {
              positionClass: 'toast-bottom-right'
            });
          });
        }
      },
      invoiceDueDate: function (id) {
        console.log(2, this.get('model.dueDate'));
        let dueDate = this.get('model.dueDate');
        if (!id) return;
        if (!dueDate) return;
        var url = this.baseapi.apiLink(`Invoices/${id}/due-date`);
        let call = this.baseapi.post(url, {
          dueDate: dueDate
        });
        this.preloader.toggleClass('d-none');
        call.then(() => {
          this.preloader.toggleClass('d-none');
          Ember.get(this, 'taskFilterReturn').perform(true);
        }, () => {
          this.preloader.toggleClass('d-none');
        });
      },

      confirmPayInvoice() {
        var invoiceReopenId = this.variables.get('invoiceReopenId');

        if (invoiceReopenId) {
          this.preloader.toggleClass('d-none');
          var url = this.baseapi.apiLink(`Invoices/${invoiceReopenId}/paid`);
          this.baseapi.patch(url, {}).then(() => {
            this.preloader.toggleClass('d-none');
            Ember.get(this, 'taskFilterReturn').perform(true);
            this.toast.success('', 'Pagamento confirmado com sucesso.', {
              positionClass: 'toast-bottom-right'
            });
          }, () => {
            this.preloader.toggleClass('d-none');
            this.toast.error('', 'Erro ao confirmar pagamento.', {
              positionClass: 'toast-bottom-right'
            });
          });
        }
      },

      setInvoiceClose(userId, invoiceReferenceId, requestTitle) {
        Ember.set(this, 'variables.list_request_id', []);
        this.set('dataRequestsCloseView', []);
        this.variables.set('list_request_id_all', false);
        this.variables.set('request_closed', '0,00');
        this.variables.set('request_closedv2', '0,00');
        this.variables.set('userId', userId);
        this.variables.set('invoiceReferenceId', invoiceReferenceId);
        this.variables.set('competencia', null);
        Ember.get(this, 'taskRequestClose').perform(userId, invoiceReferenceId, requestTitle);
        this.send("clearFilter");
      },

      beforeMonth() {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() - 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth(), 0);
        let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        Ember.set(this, 'cStrDateBegin', d1);
        Ember.set(this, 'cStrDateEnd', d2);
        Ember.get(this, 'taskFilterReturn').perform(true);
      },

      afterMonth() {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() + 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth() + 2, 0);
        let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        Ember.set(this, 'cStrDateBegin', d1);
        Ember.set(this, 'cStrDateEnd', d2);
        Ember.get(this, 'taskFilterReturn').perform(true);
      },

      setRequestIdInvoiceClose(requestId, sufix = 'cl') {
        let itens = this.variables.get('list_request_id');
        let allItens = this.variables.get('list_request_id_all');
        let drc = this.get('dataRequestsCloseView');
        var userType = this.get('dataUser.type');
        var adminType = this.get('adminValue');
        var list = [];
        var valorTotal = 0;

        if (requestId == "all") {
          if (!allItens) {
            for (let i = 0; i < drc.length; i++) {
              const element = drc[i];
              if (sufix != 'cl' && userType != adminType && element.approve != 0) continue;
              if (sufix != 'cl' && userType == adminType && element.approve != 1) continue;
              if (!itens.find(x => x.requestId == element.requestId)) Ember.$(`#${sufix}${element.requestId}`).click();
              let valorItem = this.baseapi.realStringConvertDecimal(element.requestTotal);
              valorTotal += valorItem;
              list.push(element);
            } // itens = list;


            this.variables.set('list_request_id_all', true);
          } else {
            for (let i = 0; i < drc.length; i++) {
              const element = drc[i];
              if (sufix != 'cl' && userType != adminType && element.approve != 0) continue;
              if (sufix != 'cl' && userType == adminType && element.approve != 1) continue;
              Ember.$(`#${sufix}${element.requestId}`).click();
            } // itens = list;


            this.variables.set('list_request_id_all', false);
          }
        } else {
          let idx = itens == null ? false : itens.find(x => x.requestId == requestId);

          if (idx) {
            for (let i = 0; i < itens.length; i++) {
              const element = itens[i];
              if (sufix != 'cl' && userType != adminType && element.approve != 0) continue;
              if (sufix != 'cl' && userType == adminType && element.approve != 1) continue;

              if (element.requestId != idx.requestId) {
                list.push(element);
              }
            } // itens = list;

          } else {
            if (idx === false) {
              itens = [];
            }

            var v = drc.find(x => x.requestId == requestId);
            list = itens.concat([v]);
          }

          for (let j = 0; j < list.length; j++) {
            const element = list[j];
            const req = drc.find(x => x.requestId == element.requestId);
            if (!req) continue;
            let valorItem = this.baseapi.realStringConvertDecimal(req.requestTotal);
            valorTotal += valorItem;
          }
        }

        console.log(list);
        this.set('model.reproveMsg', null); // this.set('dataRequestsSelect', list);

        this.variables.set('list_request_id', list);
        this.variables.set('request_closed', this.baseapi.numberToReal(valorTotal));
        this.variables.set('request_closedv2', this.baseapi.numberToReal(valorTotal));
      },

      setCompetencia(item) {
        this.variables.set('competencia', item);
      },

      saveNewInvoice() {
        var list_request = this.variables.get('list_request_id');
        var competencia = this.variables.get('competencia');
        var userId = this.variables.get('userId');
        var currentMode = this.variables.get('currentMode');

        if (list_request == null || list_request.length <= 0 || competencia === null || competencia.length <= 0) {
          this.toast.error('', 'É necessário selecionar a competência e os pedido.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var obj = {
          competencia: competencia,
          userId: userId,
          mode: currentMode,
          requests: list_request
        };
        var url = this.baseapi.apiLink("Invoices/close-invoice");
        this.preloader.toggleClass('d-none');
        this.baseapi.post(url, obj).then(resp => {
          this.preloader.toggleClass('d-none');

          if (resp.data.success) {
            // create and upload report
            let host = _environment.default.APP.API.host_py;
            let token = this.get('session.data.authenticated.id');
            let url_py = host + '/api/generatep';
            obj.invoiceId = resp.data.success[0].data.id;

            if (obj.invoiceId) {
              this.preloader.toggleClass('d-none');
              this.baseapi.post(url_py + '/relatorio-faturas?finished=true&access_token=' + token, obj).then(resp => {
                this.variables.set('empty_resp', resp);
                this.preloader.toggleClass('d-none');
                this.toast.success('', 'Nova fatura criada com sucesso!', {
                  positionClass: 'toast-bottom-right'
                });
                this.send("actionFilterReturn", "filter_mes");
              }, error => {
                this.variables.set('empty_resp', error);
                this.preloader.toggleClass('d-none');
                this.toast.error('', 'Erro ao enviar documento do relatório da fatura', {
                  positionClass: 'toast-bottom-right'
                });
              });
            }

            if (!obj.invoiceId) {
              this.toast.success('', 'Nova fatura criada com sucesso!', {
                positionClass: 'toast-bottom-right'
              });
              this.send("actionFilterReturn", "filter_mes");
            }
          } else {
            this.toast.error('', 'Erro ao criar nova fatura', {
              positionClass: 'toast-bottom-right'
            });
          }
        }, error => {
          this.variables.set('empty_resp', error);
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro ao criar nova fatura', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      listDetailRequests: function (invoiceReferenceId) {
        Ember.get(this, 'taskRequestList').perform(invoiceReferenceId);
      },
      listDetailCampanha: function (requestId) {
        Ember.get(this, 'taskCampanhaList').perform(requestId);
      },
      setDataRequestView: function (mode) {
        Ember.set(this, 'reloadViewRequestClosed', false);
        let strGet = `dataRequestsClose.${mode}`;
        let data = Ember.get(this, strGet);
        Ember.set(this, 'dataRequestsCloseView', data);
        Ember.set(this, 'dataRequestsCloseView2', data);
        Ember.set(this, 'reloadViewRequestClosed', true);
        this.variables.set('currentMode', mode);
        this.send('clearFilter');
      },

      downloadReport() {
        var list_request = this.variables.get('list_request_id');
        var competencia = this.variables.get('competencia');
        var userId = this.variables.get('userId');

        if (list_request == null || list_request.length <= 0 || competencia === null || competencia.length <= 0) {
          this.toast.error('', 'É necessário selecionar a competência e os pedido.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var obj = {
          competencia: competencia,
          userId: userId,
          requests: list_request
        };
        let host = _environment.default.APP.API.host_py;
        let token = this.get('session.data.authenticated.id');
        let url = host + '/api/generatep';
        this.preloader.toggleClass('d-none');
        this.baseapi.post(url + '/relatorio-faturas?access_token=' + token, obj).then(resp => {
          this.preloader.toggleClass('d-none');

          if (resp.data) {
            this.baseapi.getDownload(url + '/view?file=' + resp.data, 'report.pdf', false);
          }
        }, error => {
          this.variables.set('empty_resp', error);
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro ao criar relatório da fatura', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      filterRequestClose: function (field, item) {
        item = item.trim().toLowerCase();
        let filterItens = Ember.get(this, 'dataItensFilter');

        if (!item) {
          let indexField = filterItens.findIndex(x => x.filterKey == field);

          if (indexField != -1) {
            filterItens = filterItens.slice(indexField, 1);
          }
        }

        let fitem = filterItens.find(x => x.filterKey == field);

        if (!fitem) {
          filterItens.push({
            filterKey: field,
            filterValue: item
          });
        } else {
          fitem.filterValue = item;
        }

        this.send('actionFilterDataRequestCloseView');
      },

      actionFilterDataRequestCloseView() {
        let filterItens = Ember.get(this, 'dataItensFilter');
        var data = Ember.get(this, 'dataRequestsCloseView2');

        if (data.length > 0) {
          for (let i = 0; i < filterItens.length; i++) {
            const item = filterItens[i];
            data = data.filter(x => x[item.filterKey] && x[item.filterKey].toString().trim().toLowerCase().indexOf(item.filterValue) != -1);
          }
        }

        Ember.set(this, 'dataRequestsCloseView', data);
      },

      clearFilter() {
        this.set('rn', '');
        this.set('rl', '');
        this.set('rt', '');
        this.set('rgp', '');
        this.set('rrf', '');
        this.set('rq', '');
        this.set('rsm', '');
        this.set('rvt', '');
        this.set('dataItensFilter', []);
      },

      setDescount(item, valor) {
        let reqDesc = this.variables.get('request_closedv2');
        let desc = this.baseapi.realStringConvertDecimal(valor);
        reqDesc = this.baseapi.realStringConvertDecimal(reqDesc);
        reqDesc -= desc;
        this.variables.set('request_closed', this.baseapi.numberToReal(reqDesc));
        Ember.set(item, 'desconto', valor);
      },

      setInvoiceReopen(id) {
        this.variables.set('invoiceReopenId', id);
      },

      reopenInvoice() {
        var invoiceReopenId = this.variables.get('invoiceReopenId');

        if (invoiceReopenId) {
          this.preloader.toggleClass('d-none');
          var url = this.baseapi.apiLink(`Invoices/${invoiceReopenId}/delete`);
          this.baseapi.delete(url).then(resp => {
            if (resp.data) {
              this.preloader.toggleClass('d-none');
              this.toast.success('', 'Fatura reaberta com sucesso.', {
                positionClass: 'toast-bottom-right'
              });
              Ember.get(this, 'taskFilterReturn').perform(true);
            }
          }, error => {
            this.preloader.toggleClass('d-none');
            this.variables.set('empty_resp', error);
            this.toast.error('', 'Erro ao reabrir fatura.', {
              positionClass: 'toast-bottom-right'
            });
          });
        }
      },

      downloadExcelReport() {
        var list_request = this.variables.get('list_request_id');
        var competencia = this.variables.get('competencia');
        var userId = this.variables.get('userId');

        if (list_request == null || list_request.length <= 0 || competencia === null || competencia.length <= 0) {
          this.toast.error('', 'É necessário selecionar a competência e os pedido.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var obj = {
          competencia: competencia,
          userId: userId,
          requests: list_request
        };
        let url = this.baseapi.apiLink("Reports/invoice-xlsx");
        this.preloader.toggleClass('d-none');
        this.baseapi.downloadPostBuffer(url, {
          data: obj
        });
        this.preloader.toggleClass('d-none');
      },

      setMonthFilter(str) {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() - 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth(), 0);
        this.set('model.area', 2);
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        Ember.set(this, 'cStrDateBegin', d1);
        Ember.set(this, 'cStrDateEnd', d2);
        Ember.get(this, 'taskFilterReturn').perform(true);
      },

      setArea(v) {
        this.set('model.area', v);
      },

      approveInvoice(listRequestId, opt) {
        var invoiceReprove = this.get('model.invoiceReprove');
        var data = [];
        listRequestId = typeof listRequestId == 'string' ? [{
          requestId: listRequestId
        }] : listRequestId;
        var firstRequest = this.get('dataRequestsCloseView.0');
        listRequestId.forEach(x => {
          var obj = {
            requestId: x.requestId,
            approve: 1,
            observation: ''
          };

          if (opt == 2) {
            obj.approve = 99;
            obj.observation = invoiceReprove.observation;
          }

          if (opt == 3) obj.approve = 3;
          data.push(obj);
        });
        var url = this.baseapi.apiLink("Invoices/approve");
        this.preloader.toggleClass('d-none');
        this.baseapi.post(url, {
          requests: data
        }).then(() => {
          console.log('firstRequest', firstRequest);
          this.preloader.toggleClass('d-none');
          this.toast.success('', 'Pedidos da fatura aprovados com sucesso!', {
            positionClass: 'toast-bottom-right'
          });
          if (opt == 3) Ember.get(this, 'taskRequestClose').perform(firstRequest.userId, firstRequest.invoiceReferenceId, null);else Ember.get(this, 'taskRequestClose').perform(firstRequest.userId, firstRequest.invoiceReferenceId, firstRequest.requestTitle);
          Ember.get(this, 'taskFilterReturn').perform(true);
        }, () => {
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro ao aprovar pedidos da fatura, tente novamente', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      setInvoiceReprove(invoice) {
        this.set('model.invoiceReprove', invoice);
      },

      showReproveMessage(val) {
        this.set('model.reproveMsg', val);
      },

      approveAll(status) {
        this.send('approveInvoice', this.variables.get('list_request_id'), status);
      },

      actionFilterValidation(value) {
        let strValue = value == 1 ? 'Validação cliente' : value == 2 ? 'Validação interna' : value == 3 ? 'Validado' : -99;
        this.variables.set('validationInvoice', strValue);
        var showMonth = false;

        if (this.variables.get('filterReturn') == "filter_mes") {
          showMonth = true;
        }

        let idFind = "t0" + value;
        Ember.$('#list_situation li').each((index, item) => {
          var lId = Ember.$(item).attr('data-id');

          if (idFind == lId) {
            Ember.$(item).removeClass('text-lightgray');
            Ember.get(this, 'taskFilterReturn').perform(showMonth);
          } else {
            Ember.$(item).addClass('text-lightgray');
          }

          if (idFind == "t0-99") {
            Ember.$(item).removeClass('text-lightgray');
          }
        }); // get(this, 'taskFilterReturn').perform();
      },

      actionFilterPayment(value) {
        // let strValue = value == 1 ? 'Ag. Pagamento' : (value == 2 ? 'Vencido' : (value == 3 ? 'Pagamento confirmado' : -99));
        this.variables.set('paymentInvoice', value);
        var showMonth = false;

        if (this.variables.get('filterReturn') == "filter_mes") {
          showMonth = true;
        }

        let idFind = "t0" + value;
        Ember.$('#list_payment li').each((index, item) => {
          var lId = Ember.$(item).attr('data-id');

          if (idFind == lId) {
            Ember.$(item).removeClass('text-lightgray');
            Ember.get(this, 'taskFilterReturn').perform(showMonth);
          } else {
            Ember.$(item).addClass('text-lightgray');
          }

          if (idFind == "t0-99") {
            Ember.$(item).removeClass('text-lightgray');
          }
        }); // get(this, 'taskFilterReturn').perform();
      },

      downloadInvoiceList() {
        var userId = this.variables.get('userId');
        var invoiceReferenceId = this.variables.get('invoiceReferenceId');
        var requestTitle = null;
        var url = this.baseapi.apiLink(`Invoices/${userId}/invoices-download-xls`);

        if (invoiceReferenceId || requestTitle) {
          var query = {
            filter: {
              where: {}
            }
          };
          if (invoiceReferenceId) query.filter.where.id = invoiceReferenceId;
          if (requestTitle) query.filter.where.requestTitle = requestTitle;
          url += `?${(0, _globalFunctions.apiLinkQuery)(query)}`;
        }

        ;
        this.preloader.toggleClass('d-none');
        this.baseapi.getDownload(`${url}`, 'file_download.xlsx', true);
        this.preloader.toggleClass('d-none');
      },

      updateCompetence() {
        var list_request = this.variables.get('list_request_id');
        var competencia = this.variables.get('competencia');
        var userId = this.variables.get('userId');
        var currentMode = this.variables.get('currentMode');

        if (list_request == null || list_request.length <= 0 || competencia === null || competencia.length <= 0) {
          this.toast.error('', 'É necessário selecionar a competência e os pedido.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var obj = {
          competencia: competencia,
          userId: userId,
          mode: currentMode,
          requests: list_request
        };
        var url = this.baseapi.apiLink("Invoices/update-competence");
        this.preloader.toggleClass('d-none');
        this.baseapi.post(url, obj).then(resp => {
          this.preloader.toggleClass('d-none');

          if (resp.data.success) {
            this.toast.success('', 'Competências alteradas com sucesso!', {
              positionClass: 'toast-bottom-right'
            });
            Ember.get(this, 'taskFilterReturn').perform(true);
          } else {
            this.toast.error('', 'Erro ao mudar competências', {
              positionClass: 'toast-bottom-right'
            });
          }
        }, error => {
          this.variables.set('empty_resp', error);
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro ao mudar competências', {
            positionClass: 'toast-bottom-right'
          });
        });
      }

    }
  });

  _exports.default = _default;
});