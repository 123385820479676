define("printpost/controllers/sign/create", ["exports", "ember-concurrency", "printpost/masks", "printpost/validations/user", "printpost/validations/password"], function (_exports, _emberConcurrency, _masks, _user, _password) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const newUser = Ember.Object.extend({
    username: null,
    email: null,
    password: null,
    cnpj: null,
    cpf: null
  });

  var _default = Ember.Controller.extend({
    newUser: newUser.create(),
    UserValidations: _user.default,
    PasswordValidations: _password.default,
    mask: Ember.inject.service(),
    pessoa: 'fisica',
    pessoasMasks: _masks.default,
    submit: (0, _emberConcurrency.task)(function* () {
      yield this.changeset.save();
    }),
    createUserTask: (0, _emberConcurrency.task)(function* (payload) {
      this.set('errorResults', []);
      this.set('isRunning', true);
      let user = this.store.createRecord('account', payload);

      try {
        yield user.save();
        this.toast.success('Quase lá! Agora só resta confirmar sua conta através do endereço de email.', 'Conta criada com sucesso!', {
          positionClass: 'toast-bottom-right'
        });
        this.set('isRunning', false);
      } catch (error) {
        this.toast.error(error, 'Erro!', {
          positionClass: 'toast-bottom-right'
        });
        return error;
      }
    }),
    actions: {
      // doFormMask: function(src, type, formId, inputName) {
      //     formId = '#' + formId;
      //     inputName = 'input[name="' + inputName + '"]'
      //     let input = $(formId + ' ' + inputName);            
      //     this.mask.maskInput(input, this.changeset.get(src), pessoasMasks[type]);
      //     //console.log($('#form-create input[name="name"]'))
      //     //this.mask.updateInput(src, maskedValue);
      // }
      submitForm(model) {
        let payload = {
          username: Ember.get(model, 'username'),
          email: Ember.get(model, 'email'),
          password: Ember.get(model, 'password'),
          cpf: Ember.get(model, 'cpf'),
          cnpj: Ember.get(model, 'cnpj'),
          type: 'customer'
        };
        Ember.get(this, 'createUserTask').perform(payload);
      }

    }
  });

  _exports.default = _default;
});