define("printpost/components/new-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      this.$('[autofocus]').focus();
    },

    actions: {
      saveRequest(ev) {
        ev.preventDefault();
        let servicerequest = this.store.createRecord('address', this.model);
        servicerequest.save();
      }

    }
  });

  _exports.default = _default;
});