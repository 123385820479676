define("printpost/controllers/usuarios/company", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    accountUser: Ember.computed('accountUser', function () {
      let s = this.session.data.authenticated.account;
      return s;
    }),
    adminValue: Ember.computed('adminValue', function () {
      let s = this.baseapi.getAdmValue();
      return s;
    }),
    dataItem: Ember.computed('model.item', function () {
      var model = this.get('model.item');
      return model;
    }).property().volatile(),
    dataPassword: Ember.computed('model.pass', function () {
      var model = this.get('model.pass');
      return model;
    }).property().volatile(),
    dataAddressesAccount: Ember.computed('model.address', function () {
      var model = this.get('model.address');
      return model;
    }).property().volatile(),
    // dataEnderecoAccount: computed('model.invoiceAddress', function() {
    //     var model = this.get('model.invoiceAddress');
    //     return model;
    // }).property().volatile(),
    dataEndereco: Ember.computed('dataEndereco', function () {
      return {
        id: '',
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        complemento: '',
        numero: '',
        cidadecep: '',
        userId: '',
        type: 'ADDRESS_FREIGHT'
      };
    }).property().volatile(),
    reloadAddress: function (userId) {
      //addres
      this.preloader.toggleClass('d-none');
      let url_address = this.baseapi.apiLink('Addresses/list');

      if (userId && userId.length > 10) {
        url_address += "?filterUser=" + userId;
      }

      this.baseapi.getHttp(url_address).then(resp => {
        this.set('model.address', resp.data); //set(this, 'dataAddressesAccount', resp.data);

        let addressInvoice = resp.data.find(x => x.type.toUpperCase() == 'ADDRESS_REGISTER_INVOICE');
        this.set('model.invoiceAddress', addressInvoice);
        this.preloader.toggleClass('d-none');
      });
    },
    changePasswordTask: (0, _emberConcurrency.task)(function* () {
      let item_data = Ember.get(this, 'dataItem');
      var changePass = Ember.get(this, 'dataPassword');

      try {
        let payload = changePass;
        payload.id = item_data.id;

        if (changePass.newPassword == changePass.confirmPassword) {
          let url = this.baseapi.apiLink('Accounts');
          let call = this.baseapi.post(url + "/alterar-senha", payload);
          call.then(resp => {
            if (resp.data.errors) {
              this.toast.error(resp.data.errors[0].detail, 'Erro!', {
                positionClass: 'toast-bottom-right'
              });
            } else {
              this.toast.success('Senha alterada com sucesso', 'Sucesso!', {
                positionClass: 'toast-bottom-right'
              });
              this.transitionToRoute('usuarios.list');
            }
          });
        }
      } catch (error) {
        this.toast.error('Senha antiga incorreta.', 'Erro!', {
          positionClass: 'toast-bottom-right'
        });
        return error;
      }
    }),
    dataList: Ember.computed('model.data', function () {
      let list = this.get('model.data');
      return list;
    }),
    actions: {
      salvar: function (redirect = true) {
        let item_data = Ember.get(this, 'dataItem');
        let item_data_account = Ember.get(this, 'accountUser');
        let item = {
          "id": item_data.id,
          "type": "cliente_comum",
          "roleId": item_data.roleId,
          "cpf": item_data.cpf,
          "cnpj": item_data.cnpj,
          "payment": item_data.payment,
          "username": item_data.username,
          "email": item_data.email,
          "emailVerified": item_data.emailVerified,
          "phone1": item_data.phone1,
          "phone2": item_data.phone2,
          "razaoSocial": item_data.razaoSocial,
          "inscricaoEstadual": item_data.inscricaoEstadual,
          "organizationId": item_data_account.organizationId
        };
        let dataPass = Ember.get(this, 'dataPassword');

        if (!item.id) {
          if (!dataPass.newPassword || !dataPass.confirmPassword || dataPass.newPassword != dataPass.confirmPassword) {
            this.toast.error('Senhas não conferem!', 'Verificar se a senha e a confirmação são iguais', {
              positionClass: 'toast-bottom-right'
            });
            return;
          } else {
            item.password = dataPass.newPassword;
          }
        }

        let _object = {
          data: item
        };
        let isValide = true;

        if (item.username.length <= 0 || item.email.length <= 0 || item.type.length <= 0) {
          isValide = false;
        }

        if (item.cpf.length < 14 && item.cnpj.length < 18) {
          isValide = false;
        }

        if (item.cnpj.length == 18 && (item.razaoSocial.length <= 0 || item.inscricaoEstadual.length <= 0)) {
          isValide = false;
        }

        if (isValide) {
          let url = this.baseapi.apiLink('Accounts/save');
          let call = this.baseapi.post(url, _object);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            if (resp.data.success) {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });

              if (redirect) {
                this.transitionToRoute('usuarios.list');
              } else {
                Ember.set(this, 'dataItem.id', resp.data.success[0].source.id);
                this.send('salvarEndereco', false);
              }
            }

            if (resp.data.errors) {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não cadastrado!', resp.data.errors[0].detail, {
                positionClass: 'toast-bottom-right'
              });
            }
          }, error => {
            this.preloader.toggleClass('d-none');
            var message = error.response.data.errors[0].detail;
            this.toast.error('Item Não cadastrado!', `Dados não foram cadastrados! ${message}`, {
              positionClass: 'toast-bottom-right'
            });
          });
        } else {
          this.toast.error('Campos obrigatórios pendentes!', 'Verifique os campos obrigatórios e preencha todos!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      checkSenha: function (obj) {
        var passwordStength = (0, _globalFunctions.checkPassStrength)(obj);
        var box = Ember.$("#box-password-stength");
        box.removeClass('hidden');

        switch (passwordStength) {
          case "good":
            box.removeClass('stength-weak');
            box.removeClass('stength-best');
            box.addClass('stength-good');
            break;

          case 'weak':
            box.removeClass('stength-good');
            box.removeClass('stength-best');
            box.addClass('stength-weak');
            break;

          case 'strong':
            box.removeClass('stength-weak');
            box.removeClass('stength-good');
            box.addClass('stength-best');
            break;

          default:
            box.removeClass('stength-good');
            box.removeClass('stength-best');
            box.addClass('stength-weak');
            break;
        }
      },
      alterarSenha: function () {
        Ember.get(this, 'changePasswordTask').perform();
      },
      apagar: function () {
        let item_data = Ember.get(this, 'dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink('Accounts');
          let call = this.baseapi.delete(url + '/' + item_data.id);
          call.then(resp => {
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('usuarios.list');
          }, error => {
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },

      updateCPF(unmasked, masked) {
        if (unmasked.length == 11 || unmasked.length == 14) {
          if ((0, _globalFunctions.validaCpfCnpj)(masked)) {
            this.toast.success('CPF/CNPJ válido!', 'CPF/CNPJ válido!', {
              positionClass: 'toast-bottom-right'
            });
          } else {
            this.toast.error('CPF/CNPJ inválido!', 'CPF/CNPJ inválido, verificar dados inseridos', {
              positionClass: 'toast-bottom-right'
            });
          }
        }

        if (unmasked.length <= 11) {
          Ember.set(this, 'dataItem.cpf', masked);
          Ember.set(this, 'dataItem.cnpj', '');
          Ember.set(this, 'dataItem.razaoSocial', '');
          Ember.set(this, 'dataItem.inscricaoEstadual', '');
        } else {
          Ember.set(this, 'dataItem.cnpj', masked);
          Ember.set(this, 'dataItem.cpf', '');
        }
      },

      updatePhoneTech(type, unmasked, masked) {
        if (type == 1) {
          Ember.set(this, 'dataItem.technicalContact.phone1', masked);
        } else {
          Ember.set(this, 'dataItem.technicalContact.phone2', masked);
        }
      },

      updatePhone(type, unmasked, masked) {
        if (type == 1) {
          Ember.set(this, 'dataItem.phone1', masked);
        } else {
          Ember.set(this, 'dataItem.phone2', masked);
        }
      },

      ativarMenu: function (id, val) {
        let menuId = id == undefined ? 0 : id;
        let list = this.get('model.menus');
        let organizationId = Ember.get(this, 'dataItem.organizationId');
        let userId = Ember.get(this, 'dataItem.id');
        let item = list.find(x => x.id == id);

        if (item) {
          Ember.set(item, 'active', !val);
          let itemMenuRole = {
            "active": !val,
            "name": item.name,
            "userId": userId,
            "organizationId": organizationId,
            "menuId": menuId,
            "order": item.menuRole.order
          };
          let __menuRole = {
            data: {
              type: "MenuOrganizations",
              attributes: itemMenuRole
            }
          };

          if (item.menuRole.id) {
            let url = this.baseapi.apiLink('MenuOrganizations/' + item.menuRole.id);
            let call = this.baseapi.patch(url, __menuRole);
            __menuRole.data.id = item.menuRole.id;
            itemMenuRole.order = item.menuRole.order;
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Atualizado!', 'Menu atualizado com sucesso!', {
                positionClass: 'toast-bottom-right'
              });
              this.set('dataEmpty', resp);
            }, error => {
              this.preloader.toggleClass('d-none');
              this.set('dataEmpty', error);
            });
          } else {
            let url = this.baseapi.apiLink('MenuOrganizations');
            let call = this.baseapi.post(url, __menuRole);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none'); //not to do

              Ember.set(itemMenuRole, 'id', resp.data.data.id);
              Ember.set(item, 'menuRole', itemMenuRole);
              this.toast.success('Atualizado!', 'Menu atualizado com sucesso!', {
                positionClass: 'toast-bottom-right'
              });
            }, error => {
              this.preloader.toggleClass('d-none');
              this.set('dataEmpty', error);
            });
          }
        }
      },
      hiddenMenu: function (menuHiddenId, menuOrgId, userId) {
        let url = this.baseapi.apiLink('MenuOrganizations');
        let call = this.baseapi.patch(`${url}/${menuOrgId}/hidden/${menuHiddenId}/${userId}`, {});
        this.preloader.toggleClass('d-none');
        call.then(() => {
          this.preloader.toggleClass('d-none');
          this.toast.success('Atualizado!', 'Menu atualizado com sucesso!', {
            positionClass: 'toast-bottom-right'
          });
        }, () => {
          this.preloader.toggleClass('d-none');
        });
      },
      setUserMaster: function (val) {
        let userOrg = Ember.get(this, 'model.userOrg');
        let organizationId = Ember.get(this, 'dataItem.organizationId');
        let userId = Ember.get(this, 'dataItem.id');
        this.set('userAdm', val);
        let attributes = {
          id: userOrg.id,
          accountId: userId,
          organizationId: organizationId,
          master: val
        };

        try {
          this.baseapi.saveModel('AccountOrganization', 'AccountOrganizations', attributes, false);
        } catch (error) {
          this.toast.error(`Erro!`, `Dado não foi salvo!`, {
            positionClass: 'toast-bottom-right'
          });
        } finally {
          this.preloader.toggleClass('d-none');
        }
      }
    }
  });

  _exports.default = _default;
});