define("printpost/components/vis-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({// actions: {
    //     teste: function(rarara) {
    //         console.log('rarara');
    //         console.log(rarara);
    //     }
    // }
  });

  _exports.default = _default;
});