define("printpost/controllers/templates/view", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataFilter: Ember.computed('filter', function () {
      return [];
    }),
    dataProducts: Ember.computed('model.products', function () {
      var model = this.get('model.products');
      return model;
    }),
    dataTemplates: Ember.computed('model.templates', function () {
      var model = this.get('model.templates');
      return model;
    }),
    adminValue: Ember.computed('adminValue', function () {
      let s = this.baseapi.getAdmValue();
      return s;
    }),
    myFlatpickrRef: Ember.computed(function () {
      return null;
    }),
    dataItensFilter: Ember.computed(function () {
      let d = [];
      return d;
    }),
    datePeriodo: Ember.computed(function () {
      return [];
    }),
    dataUser: Ember.computed('dataUser', function () {
      // let d = this.baseapi.getCurrentUser();
      const d = this.get('session.data.authenticated');
      return d.account;
    }).property().volatile(),
    organizationForVincule: Ember.computed('organizationForVincule', function () {
      return [];
    }),
    searchTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Accounts/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    searchOrganizationTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Organizations/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    actions: {
      goToLink: function (item) {
        this.transitionToRoute(item);
      },

      changeCheckbox(item, tipo, val) {
        let df = Ember.get(this, 'dataFilter');

        if (val) {
          let o = {};
          o[tipo] = item;
          df.push(o);
        } else {
          for (let i = 0; i < df.length; i++) {
            const element = df[i];

            if (element[tipo] == item) {
              df.splice(i, 1);
            }
          }
        }

        Ember.set(this, 'dataFilter', df);
      },

      filterTemplate: async function () {
        let r = Ember.get(this, 'dataFilter');
        let query = {
          filter: {
            where: {},
            include: 'channel',
            order: 'id DESC'
          }
        };
        let filterItens = Ember.get(this, 'dataItensFilter');

        if (filterItens.length > 0) {
          filterItens.forEach(fitem => {
            if (fitem.filterKey != "status" && fitem.filterKey != "createdAt") query.filter.where[fitem.filterKey] = fitem.filterValue;

            if (fitem.filterKey == "status" || fitem.filterKey == "name" || fitem.filterKey == "clientIdentifier") {
              query.filter.where[fitem.filterKey] = {
                regexp: `${fitem.filterValue}/i`
              };
            }

            if (fitem.filterKey == "createdAt") {
              let dtIni = Ember.get(this, 'cStrDateBegin');
              let dtEnd = Ember.get(this, 'cStrDateEnd');
              query.filter.where["and"] = [{
                createdAt: {
                  gte: dtIni
                }
              }, {
                createdAt: {
                  lte: dtEnd
                }
              }];
            }
          });
        }

        if (r.length > 1) {
          query.filter.where.or = [];
          r.forEach(item => {
            if (item.channelId) {
              query.filter.where.or.push({
                "channelId": item.channelId
              });
            }

            if (item.productId) {
              query.filter.where.or.push({
                "productId": item.productId
              });
            }
          });
        } else if (r.length == 1) {
          if (r[0].channelId) {
            query.filter.where = {
              "channelId": r[0].channelId
            };
          }

          if (r[0].productId) {
            query.filter.where = {
              "productId": r[0].productId
            };
          }
        }

        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        this.preloader.toggleClass('d-none');
        var url_templates = this.baseapi.apiLink('Templates/list');
        const obj_templates = await this.baseapi.getHttp(url_templates + "?" + strquery).then(r => r.data);
        this.preloader.toggleClass('d-none');
        Ember.set(this, 'dataTemplates', obj_templates);
      },
      setTemplate: function (id_template) {
        console.log(id_template);
      },

      setUserFilter(item) {
        Ember.set(this, 'rc', item);
        this.send('filterItens', 'userId', item.id);
      },

      setOrganization(item) {
        Ember.set(this, 'dataOrganization', item);
        this.send('filterItens', 'organizationId', item.id);
      },

      filterItens: function (field, item) {
        item = typeof item == "string" ? item.trim().toLowerCase() : item;
        let filterItens = Ember.get(this, 'dataItensFilter'); // valor vazio

        if (!item) {
          let indexField = filterItens.findIndex(x => x.filterKey == field);

          if (indexField != -1) {
            var newItens = [];
            filterItens.forEach((item, idx) => {
              if (idx != indexField) {
                newItens.push(item);
              }
            });
            Ember.set(this, 'dataItensFilter', newItens);
            return;
          }
        }

        let fitem = filterItens.find(x => x.filterKey == field);

        if (!fitem && item) {
          filterItens.push({
            filterKey: field,
            filterValue: item
          });
        } else if (item) {
          fitem.filterValue = item;
        }

        Ember.set(this, 'dataItensFilter', filterItens);
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });

        if (!dateFlat) {
          return;
        }

        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = year + "-" + month + "-" + day + "T00:00:00-03:00";
        let d = Ember.get(this, 'datePeriodo');

        if (d.length == 0) {
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        } else if (d.length == 1) {
          let st = year + "-" + month + "-" + day + "T23:59:00-03:00";
          d.push(st);
          Ember.set(this, 'cStrDateEnd', st);
        } else if (d.length == 2) {
          d = [];
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        }

        Ember.set(this, 'datePeriodo', d);
        this.send('filterItens', 'createdAt', strDate);
      }

    }
  });

  _exports.default = _default;
});