define("printpost/models/request-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    requestId: _emberData.default.attr(),
    carta: _emberData.default.attr(),
    sms: _emberData.default.attr(),
    email: _emberData.default.attr()
  });

  _exports.default = _default;
});