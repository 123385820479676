define("printpost/controllers/operacao/pdfcombine", ["exports", "ember-concurrency", "printpost/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      requestId: null
    },
    requestId: Ember.computed('model.requestId', function () {
      let reqId = Ember.get(this, 'model.requestId');
      return reqId;
    }),
    dataPricingCustomer: Ember.computed('pricingTable', function () {
      let listLinkCustomer = Ember.get(this, 'model.linkCustomer'); // let item = null;
      // if(listLinkCustomer){
      //     let c = get(this, 'channels');
      //     let channell_letter = c.find(x => x.channel == "carta");
      //     item = listLinkCustomer.find(x => x.pricingTable.channelId == channell_letter.id)
      //     item = item.pricingTable;
      // }

      return listLinkCustomer;
    }),
    dataPriceGeneral: Ember.computed('model.priceGeneral', function () {
      return this.get('model.priceGeneral');
    }),
    calculePriceRequest: function () {
      let dataPriceCustomer = Ember.get(this, 'dataPricingCustomer');
      let dataPriceGeneral = Ember.get(this, 'dataPriceGeneral');
      let actualPrices = dataPriceCustomer.length > 0 ? dataPriceCustomer : dataPriceGeneral;
      let p = actualPrices.find(x => x.channelName == "carta");
      p = p ? p : dataPriceGeneral.find(x => x.channelName == "carta");
      let tc = this.baseapi.realStringConvertDecimal(p.totalCost);
      let totalCost = tc; // totalCost = this.baseapi.realStringConvertDecimal(totalCost);

      let rId = this.requestId;
      let qp = this.quantityProccess;
      let valueRequest = totalCost * qp;
      valueRequest = this.baseapi.numberToReal(valueRequest);
      Ember.set(this, 'totalCostResult', valueRequest);
    },
    xlsFile: {
      name: ''
    },
    pdfFile: {
      name: ''
    },
    fileLog: '',
    totalCostResult: '',
    quantityProccess: 0,
    quantityXLS: 0,
    isWaiting: false,
    combinPDFTask: (0, _emberConcurrency.task)(function () {
      let pdf = Ember.get(this, 'pdfFile');
      let xls = Ember.get(this, 'xlsFile');
      Ember.set(this, 'isWaiting', true);

      try {
        let form = new FormData();
        form.append("arquivo_pdf", pdf);
        form.append("arquivo_xls", xls);
        let host = _environment.default.APP.API.host_py;
        let token = this.get('session.data.authenticated.id');
        let url = host + '/api/generatep/combinepdf';
        let r = this.baseapi.postFormData(url + '?access_token=' + token, form, false);
        r.then(response => {
          let data = response.data; //this.baseapi.getDownload(url + 'view?file=' + data, 'kkkk.pdf', false);

          Ember.set(this, 'isWaiting', false);

          if (data.success) {
            let nomeFile = data.msg.combined_pdf;
            Ember.set(this, 'fileLog', data.msg.fileLog);
            Ember.set(this, 'quantityProccess', data.msg.quantityProccess);
            Ember.set(this, 'quantityXLS', data.msg.quantityXLS);

            let _urlDown = host + '/api/generatep/view?file=' + nomeFile + '&isCombinePdf=1';

            this.toast.success('', 'PDF combinado com sucesso.', {
              positionClass: 'toast-bottom-right'
            });
            this.baseapi.getDownload(_urlDown, 'kkkk.pdf', false);
            this.calculePriceRequest();
          } else {
            this.toast.error('', 'Erro ao combinar PDF.', {
              positionClass: 'toast-bottom-right'
            });
          }
        });
      } catch (error) {
        this.toast.error('', 'Erro ao combinar PDF.', {
          positionClass: 'toast-bottom-right'
        });
      }
    }),
    setFileExcel: (0, _emberConcurrency.task)(function (data) {
      //ler https://www.html5rocks.com/pt/tutorials/file/dndfiles/
      Ember.set(this, 'xlsFile', data.target.files[0]);
    }).maxConcurrency(3).enqueue(),
    setFilePDF: (0, _emberConcurrency.task)(function (data) {
      //ler https://www.html5rocks.com/pt/tutorials/file/dndfiles/
      Ember.set(this, 'pdfFile', data.target.files[0]);
    }).maxConcurrency(3).enqueue(),
    actions: {
      setFilePDF: function (file) {
        Ember.get(this, 'setFilePDF').perform(file).then();
      },
      setFileExcel: function (file) {
        Ember.get(this, 'setFileExcel').perform(file).then();
      },
      downloadLog: function () {
        let file = Ember.get(this, 'fileLog');
        let host = _environment.default.APP.API.host_py;

        let _urlDown = host + '/api/generatep/view?file=' + file + '&isLogPdf=1';

        this.baseapi.getDownload(_urlDown, 'kkkk.pdf', false);
      },
      combinePDF: function () {
        Ember.get(this, 'combinPDFTask').perform();
      },
      calculePrice: function () {
        this.calculePriceRequest();
      },
      updateRequest: function () {
        let tc = this.baseapi.realStringConvertDecimal(this.totalCostResult);
        let item = {
          quantity: this.quantityProccess,
          totalCost: tc
        };
        let _object = {
          data: {
            type: "Request",
            attributes: item,
            id: this.requestId
          }
        };
        let url = this.baseapi.apiLink('Requests');
        url = url + "/" + this.requestId;
        this.baseapi.patch(url, _object).then(x => {
          if (x.data) {
            this.toast.success('', 'Informações atualizadas com sucesso.', {
              positionClass: 'toast-bottom-right'
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});