define("printpost/templates/components/tag-having", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AtCy8LHF",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"taghaving-box \",[21,\"classTagValue\"]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"taghaving-title\"],[9],[0,\"\\n        Tags utilizadas \\n        \"],[7,\"i\"],[11,\"id\",\"tag-eye\"],[11,\"class\",\"ml-10 fa fa-eye clickable\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"showItens\"],null]],[11,\"style\",\"cursor: pointer;\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"id\",[28,[[22,2,[]],\"_\",[21,\"prefix\"]]]],[11,\"class\",\"taghaving-item\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"mr-40\"],[9],[0,\"\\n            \"],[1,[22,1,[\"title\"]],false],[0,\"\\n        \"],[10],[0,\"    \\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"id\",[28,[[21,\"index\"],\"_\",[21,\"prefix\"]]]],[11,\"class\",\"taghaving-item\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"mr-40\"],[9],[0,\"\\n            Nenhuma tag encontrada\\n        \"],[10],[0,\"    \\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"   \\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/tag-having.hbs"
    }
  });

  _exports.default = _default;
});