define("printpost/controllers/prices/addlinkcustomer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    user: Ember.computed('user', function () {
      return null;
    }),
    dataItem: Ember.computed('model.item', function () {
      var model = this.get('model.item');
      model = model[0];

      if (model.userId) {
        let listUser = Ember.get(this, 'dataListAccounts');

        let _user = listUser.find(x => x.id == model.userId);

        Ember.set(this, 'user', _user);
      } else {
        Ember.set(this, 'user', null);
      }

      return model;
    }),
    dataListPricingTables: Ember.computed('model.pricingtables', function () {
      let list = this.get('model.pricingtables');
      return list;
    }),
    dataListAccounts: Ember.computed('model.accounts', function () {
      let list = this.get('model.accounts');
      return list;
    }),
    dataId: Ember.computed('model.id', function () {
      let id = this.get('model.id');
      return id;
    }),
    dataOrder: Ember.computed('order', function () {
      return 1;
    }),
    actions: {
      setUser: function (valor) {
        let listUser = Ember.get(this, 'dataListAccounts');

        let _user = Ember.get(this, 'user');

        if (_user && _user.id == valor) {
          Ember.set(this, 'user', null);
          Ember.set(this, 'dataItem.userId', "");
        } else {
          _user = listUser.find(x => x.id == valor);
          Ember.set(this, 'user', _user);
          Ember.set(this, 'dataItem.userId', valor);
        }
      },
      setPricingTable: function (valor) {
        Ember.set(this, 'dataItem.pricingTableId', valor); // set(this, 'dataItem.pricingTableId2Teste', valor);
      },
      salvar: function () {
        let item_data = Ember.get(this, 'dataItem');
        let item = {
          "id": item_data.id,
          "name": item_data.name,
          "userId": item_data.userId,
          "active": item_data.active,
          "pricingTableId": item_data.pricingTableId
        };
        let _object = {
          data: {
            type: "LinkCustomer",
            attributes: item
          }
        };

        if (item.userId.length > 0 && item.pricingTableId.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink('LinkCustomers');
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            call.then(resp => {
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.transitionToRoute('prices.linkcustomer');
            }, error => {
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
            });
          } else {
            let url = this.baseapi.apiLink('LinkCustomers/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            call.then(resp => {
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
            }, error => {
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
            });
          }
        }
      },
      setTypenameSelect: function () {
        let select_value = this.baseapi.getFieldSelectValue("tiporole_selection");
        Ember.set(this, 'dataItem.name', select_value);
      },
      apagar: function () {
        let item_data = Ember.get(this, 'dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink('LinkCustomers');
          let call = this.baseapi.delete(url + '/' + item_data.id);
          call.then(resp => {
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('prices.linkcustomer');
          }, error => {
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      }
    }
  });

  _exports.default = _default;
});