define("printpost/services/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    model() {
      this.get('store').findAll('notification');
    },

    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});