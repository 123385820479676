define("printpost/controllers/peoples/list", ["exports", "printpost/controllers/peoples"], function (_exports, _peoples) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _peoples.default.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    searchProperties: Ember.computed('searchProperties', function () {
      let d = ['nome', 'cpf_cnpj', 'telefone', 'email'];
      return d;
    }),
    people: Ember.computed('model.people', function () {
      let model = this.get('model.people').data.attributes;
      setTimeout(() => {
        Ember.set(this, 'dataPeople.enterPeople', false);
        Ember.set(this, 'dataPeople.nome', '');
      }, 1000); // set(this, 'dataPeople.nome', 'fulano');

      return model;
    }).property().volatile(),
    dataTypeReturn: Ember.computed('dataTypeReturn', function () {
      return [{
        description: 'Todos',
        status: -1
      }, {
        description: 'Aguardando',
        status: 1
      }, {
        description: 'Positivo',
        status: 3
      }, {
        description: 'Negativo',
        status: 2
      }];
    }),
    peopleList: Ember.computed('model.peopleHistories.data', function () {
      let d = this.get('model.peopleHistories.data');
      return d;
    }),
    tableColumns: Ember.computed('modelEditor.wallet.data', function () {
      let itens = [{
        name: `Nome`,
        valuePath: 'nome'
      }, {
        name: `CPF/CNPJ`,
        valuePath: 'cpf_cnpj'
      }, {
        name: `Telefone`,
        valuePath: 'telefone'
      }, {
        name: `Email`,
        valuePath: 'email'
      }];
      return itens;
    }),
    actions: {
      setTypeReturn(item) {
        console.log('item', item);
        Ember.set(this, 'selectedReturn', item);
        let url = this.baseapi.apiLink(`OmniChannelPeople/filter-return/${item.status}`);

        if (item.status == -1) {
          url = this.baseapi.apiLink(`OmniChannelPeople/list?filter[limit]=6000`);
        }

        this.preloader.toggleClass('d-none');
        this.baseapi.getHttp(url).then(resp => {
          this.preloader.toggleClass('d-none');
          let itens = resp.data;
          this.set('model.peopleHistories', itens.data);
          Ember.set(this, 'peopleList', itens.data);
        }, error => {
          this.toast.error('', 'Problemas ao listar dados', {
            positionClass: 'toast-bottom-right'
          });
          this.preloader.toggleClass('d-none');
        });
      }

    }
  });

  _exports.default = _default;
});