define("printpost/templates/profile-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ZDOvVw6",
    "block": "{\"symbols\":[],\"statements\":[[0,\"    \\n    \"],[2,\" Preloader \"],[0,\"\\n    \"],[4,\"pre-loader\",null,[[\"type\"],[\"loading\"]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/profile-loading.hbs"
    }
  });

  _exports.default = _default;
});