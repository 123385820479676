define("printpost/routes/perfis/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      // let id_param = getUrlParameters("id", "", true);
      var url = this.baseapi.apiLink('Roles/list');
      var url_menus = this.baseapi.apiLink('MenuRoles/list?id=' + queryParams.id);
      var list = [];
      var list_menus = [];
      let item = {
        "name": "",
        "description": ""
      };
      const obj_menus = await this.baseapi.getHttp(url_menus).then(m => m.data);

      if (obj_menus.length > 0) {
        obj_menus.forEach(function (item) {
          if (!item.menuRole) {
            item.menuRole = {
              order: ""
            };
          }
        });
        list_menus = obj_menus;
      }

      const obj = await this.baseapi.getHttp(url).then(r => {
        let objReturn = [];
        r.data.forEach(element => {
          let item = {
            "id": element.id,
            "name": element.name,
            "description": element.description,
            "selectText": element.description,
            "selectId": element.id,
            "selectValue": element.name
          };
          objReturn.push(item);
        });
        return objReturn;
      });
      list = obj;
      let adminP = false;

      if (queryParams && queryParams.id) {
        item = obj.find(x => x.id == queryParams.id);
        adminP = this.baseapi.getAdmValue() == item.name;
      }

      return Ember.RSVP.hash({
        data: list,
        item: item,
        menus: list_menus,
        adminPerfil: adminP,
        id: queryParams.id
      });
    }
  });

  _exports.default = _default;
});