define("printpost/components/models-table/themes/semanticui/columns-dropdown", ["exports", "ember-models-table/components/models-table/themes/semanticui/columns-dropdown"], function (_exports, _columnsDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _columnsDropdown.default;
    }
  });
});