define("printpost/controllers/requests", ["exports", "ember-concurrency", "fetch", "lodash", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _fetch, _lodash, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);
    },

    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    requestService: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataUser: Ember.computed('dataUser', function () {
      // let d = this.baseapi.getCurrentUser();
      const d = this.get('session.data.authenticated');
      return d.account;
    }).property().volatile(),
    adminValue: Ember.computed('adminValue', function () {
      let s = this.baseapi.getAdmValue();
      return s;
    }),
    queryParams: ['type'],
    type: null,
    wallet: Ember.computed('model.request.wallet', function () {
      return this.get('model.request.wallet.content');
    }),
    request: Ember.computed('model.request', function () {
      return this.get('model.request');
    }),
    bkpRequest: Ember.computed('model.bkpRequest', function () {
      return this.get('model.bkpRequest');
    }),
    totals: Ember.computed('model.totals', function () {
      return this.get('model.totals');
    }),
    dataItensFilter: Ember.computed(function () {
      let d = [];
      return d;
    }),
    requestDuplicate: Ember.computed('requestDuplicate', function () {
      return null;
    }),
    continueEdit: (0, _emberConcurrency.task)(function* (idvalue) {
      if (idvalue) {
        let _request = null;
        let userId = this.get('session.data.authenticated.userId');
        let query = {
          filter: {
            where: {
              id: idvalue
            },
            fields: {
              "id": true,
              "title": true,
              "paymentCode": true,
              "channel": true,
              "devolucao": true,
              "withdraw": true,
              "shippingMethod": true,
              "emailSubject": true,
              "paymentMethod": true,
              "paymentStatus": true,
              "status": true,
              "number": true,
              "quantity": true,
              "sent": true,
              "processing": true,
              "error": true,
              "totalCost": true,
              "billingRelated": false,
              "letterService": true,
              "smsService": true,
              "emailService": true,
              "emailContact": true,
              "phoneContact": true,
              "document": true,
              "originalDocumentName": true,
              "columnsSelectSMS": true,
              "columnsSelectEmail": true,
              "userId": true,
              "invoiceId": true,
              "walletId": true,
              "fileDb": true,
              "templateId": true,
              "databaseId": true,
              "printingOptions": true,
              "address": true,
              "logoId": true,
              "letterTemplateId": true,
              "smsTemplateId": true,
              "emailTemplateId": true
            }
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        let url = this.baseapi.apiLink('Requests');
        _request = yield this.baseapi.getHttp(url + "/list?" + strquery).then(r => r.data);
        _request = _request[0];
        delete _request.createdAt;

        if (_request != null) {
          _request.userId = userId;
          localStorage.removeItem('storage:request');
          localStorage.removeItem('newServiceRequestId');
          localStorage.removeItem('storage:wallet-columns');
          localStorage.removeItem('storage:excededSms');
          localStorage.removeItem('storage:template-selected');
          localStorage.removeItem('storage:request-options');
          localStorage.setItem('newServiceRequestId', idvalue);
          localStorage.setItem('storage:request', JSON.stringify(_request));
          window.location.href = "services/new";
        }
      }
    }).keepLatest(),
    duplicateRequestQuestion: (0, _emberConcurrency.task)(function* () {
      let r = Ember.$("input[name='id_request']:checked").val();

      if (r == undefined) {
        Ember.$('#btn-duplicate-req-empty').click();
      } else {
        let _request = null;
        yield this.store.findRecord('request', r).then(function (data_obj) {
          _request = data_obj.data;

          if (_request.status.trim().toLowerCase() == "pendente" || _request.status.trim().toLowerCase() == "cancelado") {
            Ember.$('#btn-duplicate-req-empty').click();
            return;
          }

          let printingCheck = _request.printingOptions;
          let isImediatoEmail = printingCheck.templateEmail ? printingCheck.templateEmail.disparoImediato : true;
          let isImediatoCarta = printingCheck.templateLetter ? printingCheck.templateLetter.disparoImediato : true;
          let isImediatoSMS = printingCheck.templateSMS ? printingCheck.templateSMS.disparoImediato : true;
          let isImediato = isImediatoCarta * isImediatoEmail * isImediatoSMS ? true : false;

          if (isImediato) {
            // question edit any information
            Ember.$('#btn-duplicate-req-1').click();
          } else {
            // direct edit any information
            Ember.$('#btn-duplicate-req-2').click();
          }
        });
        Ember.set(this, 'requestDuplicate', _request);
      }
    }).keepLatest(),
    searchTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Accounts/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return (0, _fetch.default)(url).then(resp => resp.json()).then(json => json);
    }),
    myFlatpickrRef: Ember.computed(function () {
      return null;
    }),
    datePeriodo: Ember.computed(function () {
      return [];
    }),
    organizationForVincule: Ember.computed('organizationForVincule', function () {
      return [];
    }),
    searchOrganizationTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Organizations/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return (0, _fetch.default)(url).then(resp => resp.json()).then(json => json);
    }),
    optionsChart: Ember.computed(function () {
      return {
        responsive: true,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false
            },
            display: false
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: false
            },
            display: false
          }]
        }
      };
    }),
    getRequestIndicators: (0, _emberConcurrency.task)(function* () {
      let dtIni = Ember.get(this, 'model.strDateBegin');
      let dtEnd = Ember.get(this, 'model.strDateEnd');
      var query = {
        filter: {
          where: {
            status: {
              nin: ['Pendente', 'Em Pendente']
            },
            and: [{
              createdAt: {
                gte: dtIni
              }
            }, {
              createdAt: {
                lte: dtEnd
              }
            }]
          },
          order: 'id DESC',
          include: ['wallet']
        }
      };
      let filterItens = Ember.get(this, 'dataItensFilter');
      let filterUser = false;

      if (filterItens.length > 0) {
        filterItens.forEach(fitem => {
          if (fitem.filterKey != "status" && fitem.filterKey != "createdAt") query.filter.where[fitem.filterKey] = fitem.filterValue;

          if (fitem.filterKey == "status" || fitem.filterKey == "title" || fitem.filterKey == "clientIdentifier") {
            query.filter.where[fitem.filterKey] = {
              regexp: `${fitem.filterValue}/i`
            };
          }

          ;

          if (fitem.filterKey == "createdAt") {
            let dtIni = Ember.get(this, 'cStrDateBegin');
            let dtEnd = Ember.get(this, 'cStrDateEnd');
            query.filter.where["and"] = [{
              createdAt: {
                gte: dtIni
              }
            }, {
              createdAt: {
                lte: dtEnd
              }
            }];
          }

          ;
          if (fitem.filterKey == "userId") filterUser = true;
        });
      }

      try {
        const indicators = yield this.requestService.quantityData(query, filterUser);
        Ember.set(this, 'model.indicators', indicators);
        const chart = {
          "datasets": [{
            label: ["Não entregues"],
            data: [parseFloat(indicators.chart.naoEntregue)],
            stack: "Não entregues",
            backgroundColor: ['#d80000']
          }, {
            label: ["Entregues"],
            data: [parseFloat(indicators.chart.entregue)],
            stack: "Entregues",
            backgroundColor: ['#40bbaf']
          }],
          "labels": ["Entregues"]
        };
        Ember.set(this, 'model.chart', chart);
      } catch (error) {
        this.toast.error('', 'Erro na consulta dos dados', {
          positionClass: 'toast-bottom-right'
        });
      }
    }),
    actions: {
      duplicateRequest: function (status) {
        let r = Ember.$("input[name='id_request']:checked").val();

        if (r == undefined) {
          Ember.$('#btn-duplicate-req-empty').click();
        } else {
          let _request = Ember.get(this, 'requestDuplicate');

          if (_request) {
            status = Number(status.toString());
            const user = this.get('session.data.authenticated').account;

            if (user.type == "cliente_comum") {
              return;
            }

            let url = this.baseapi.apiLink('Requests/replicate');
            url += "/" + r;

            if (status == 1) {
              url += "/2";
            } else {
              url += "/1";
            }

            let call = this.baseapi.post(url, {});
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.preloader.toggleClass('d-none');

              if (resp.data.success) {
                this.toast.success('', 'Pedido replicado com sucesso', {
                  positionClass: 'toast-bottom-right'
                });
                Ember.$('#btn-duplicate-success').click();
              } else {
                this.toast.error('', 'Problemas identificados ao replicar pedido', {
                  positionClass: 'toast-bottom-right'
                });
              }
            }, error => {
              this.preloader.toggleClass('d-none');
              this.toast.error('', 'Erro ao replicar pedido, tente novamente.', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
            });
          }
        }
      },
      reloadPage: function () {
        window.location.reload(true);
      },
      continueEditing: function (idvalue) {
        Ember.get(this, 'continueEdit').perform(idvalue);
      },
      duplicateRequestQuestion: function () {
        Ember.get(this, 'duplicateRequestQuestion').perform();
      },
      setDataRequestFiles: function (f) {
        this.set('model.dataRequestFiles', f);
      },

      filterStatus(status) {
        this.send('filterItens', 'status', status);
        this.send('paginationRequest', {
          skip: 0,
          limit: 20
        });
        this.get('getRequestIndicators').perform();
      },

      paginationRequest: async function (filterSkip) {
        let dtIni = Ember.get(this, 'model.strDateBegin');
        let dtEnd = Ember.get(this, 'model.strDateEnd');
        var query = {
          filter: {
            where: {
              status: {
                nin: ['Pendente', 'Em Pendente']
              },
              and: [{
                createdAt: {
                  gte: dtIni
                }
              }, {
                createdAt: {
                  lte: dtEnd
                }
              }]
            },
            order: 'id DESC',
            skip: filterSkip.skip,
            limit: filterSkip.limit,
            include: ['wallet', 'account', 'files']
          }
        };
        let filterItens = Ember.get(this, 'dataItensFilter');
        let filterUser = false;

        if (filterItens.length > 0) {
          filterItens.forEach(fitem => {
            if (fitem.filterKey != "status" && fitem.filterKey != "createdAt") query.filter.where[fitem.filterKey] = fitem.filterValue;

            if (fitem.filterKey == "status" || fitem.filterKey == "title" || fitem.filterKey == "clientIdentifier") {
              query.filter.where[fitem.filterKey] = {
                regexp: `${fitem.filterValue}/i`
              };
            }

            ;

            if (fitem.filterKey == "createdAt") {
              let dtIni = Ember.get(this, 'cStrDateBegin');
              let dtEnd = Ember.get(this, 'cStrDateEnd');
              query.filter.where["and"] = [{
                createdAt: {
                  gte: dtIni
                }
              }, {
                createdAt: {
                  lte: dtEnd
                }
              }];
            }

            ;
            if (fitem.filterKey == "userId") filterUser = true;
          });
        }

        this.preloader.toggleClass('d-none');

        try {
          const requestList = await this.requestService.list(query, filterUser);
          Ember.set(this, 'model.pages', requestList.totalPages);
          Ember.set(this, 'model.request', requestList.data);
        } catch (error) {
          this.toast.error('', 'Erro na consulta dos dados', {
            positionClass: 'toast-bottom-right'
          });
        }

        this.preloader.toggleClass('d-none');
      },

      clearFilter() {
        this.set('rn', '');
        this.set('rl', '');
        this.set('rt', '');
        this.set('rs', '');
        this.set('rc', '');
        this.set('rss', '');
        this.set('rdi', '');
        this.set('rde', '');
        this.set('dataOrganization', '');
        Ember.get(this, 'myFlatpickrRef').clear();
        this.set('dataItensFilter', []);
        this.set('datePeriodo', []);
        this.send('paginationRequest', {
          skip: 0,
          limit: 20
        });
        this.set('cStrDateBegin', this.get('model.strDateBegin'));
        this.set('cStrDateEnd', this.get('model.strDateEnd'));
        this.get('getRequestIndicators').perform();
      },

      filterItens: function (field, item) {
        item = typeof item == "string" ? item.trim().toLowerCase() : item;
        let filterItens = Ember.get(this, 'dataItensFilter'); // valor vazio

        if (!item) {
          let indexField = filterItens.findIndex(x => x.filterKey == field);

          if (indexField != -1) {
            var newItens = [];
            filterItens.forEach((item, idx) => {
              if (idx != indexField) {
                newItens.push(item);
              }
            });
            Ember.set(this, 'dataItensFilter', newItens);
            return;
          }
        }

        let fitem = filterItens.find(x => x.filterKey == field);

        if (!fitem && item) {
          filterItens.push({
            filterKey: field,
            filterValue: item
          });
        } else if (item) {
          fitem.filterValue = item;
        }

        Ember.set(this, 'dataItensFilter', filterItens);
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });

        if (!dateFlat) {
          return;
        }

        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = year + "-" + month + "-" + day + "T00:00:00-03:00";
        let d = Ember.get(this, 'datePeriodo');

        if (d.length == 0) {
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        } else if (d.length == 1) {
          let st = year + "-" + month + "-" + day + "T23:59:00-03:00";
          d.push(st);
          Ember.set(this, 'cStrDateEnd', st);
        } else if (d.length == 2) {
          d = [];
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        }

        Ember.set(this, 'datePeriodo', d);
        this.send('filterItens', 'createdAt', strDate);
      },

      setStatusFilter(item) {
        Ember.set(this, 'rs', item);
        this.send('filterItens', 'status', item.value);
      },

      setUserFilter(item) {
        Ember.set(this, 'rc', item);
        this.send('filterItens', 'userId', item.id);
      },

      setServiceFilter(item) {
        Ember.set(this, 'rss', item);
        let services = Ember.get(this, 'model.listTipoService');
        services.forEach(s => {
          this.send('filterItens', s.field, '');
        });
        this.send('filterItens', item.field, item.value);
      },

      filterRequest() {
        this.send('paginationRequest', {
          skip: 0,
          limit: 20
        });
        this.get('getRequestIndicators').perform();
      },

      goRouter(url) {
        // localStorage.removeItem('storage:request');
        localStorage.removeItem('newServiceRequestId'); // localStorage.removeItem('storage:wallet-columns');
        // localStorage.removeItem('storage:excededSms');
        // localStorage.removeItem('storage:template-selected');
        // localStorage.removeItem('storage:request-options');

        this.transitionToRoute(url);
      },

      downloadRequestList: async function () {
        var query = {
          filter: {
            where: {
              status: {
                nin: ['Pendente', 'Em Pendente']
              }
            },
            order: 'id DESC',
            include: ['account', 'files', 'costCenter']
          }
        };
        let filterItens = Ember.get(this, 'dataItensFilter');
        let filterUser = false;

        if (filterItens.length > 0) {
          filterItens.forEach(fitem => {
            if (fitem.filterKey != "status" && fitem.filterKey != "createdAt") query.filter.where[fitem.filterKey] = fitem.filterValue;

            if (fitem.filterKey == "status" || fitem.filterKey == "title" || fitem.filterKey == "clientIdentifier") {
              query.filter.where[fitem.filterKey] = {
                regexp: `${fitem.filterValue}/i`
              };
            }

            ;

            if (fitem.filterKey == "createdAt") {
              let dtIni = Ember.get(this, 'cStrDateBegin');
              let dtEnd = Ember.get(this, 'cStrDateEnd');
              query.filter.where["and"] = [{
                createdAt: {
                  gte: dtIni
                }
              }, {
                createdAt: {
                  lte: dtEnd
                }
              }];
            }

            ;
            if (fitem.filterKey == "userId") filterUser = true;
          });
        }

        var strquery = (0, _globalFunctions.apiLinkQuery)(query);
        let url = this.baseapi.apiLink('Requests/download-list-xls');
        this.preloader.toggleClass('d-none');
        this.baseapi.getDownload(`${url}?${strquery}`, 'file_download.xlsx', true);
        this.preloader.toggleClass('d-none');
      },

      setOrganization(item) {
        Ember.set(this, 'dataOrganization', item);
        this.send('filterItens', 'organizationId', item.id);
      }

    }
  });

  _exports.default = _default;
});