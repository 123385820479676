define("printpost/services/request-service", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    /**
     * Consulta de pedidos
     * @param {object} filter Object loopback filter para consultar
     * @param {boolean} filterUser True para buscar pelo id do usuário (apenas Adm)
     * @returns {any[]} Lista de pedidos
     */
    list: async function (filter, filterUser = false) {
      const base_url = this.baseapi.apiLink('Requests');
      const strquery = filter ? (0, _globalFunctions.apiLinkQuery)(filter) : '';
      var url = !filterUser ? `${base_url}/list?mp=true&${strquery}` : `${base_url}/list?mp=true&fu=true&${strquery}`;
      return await this.baseapi.getHttp(url).then(response => response.data);
    },

    /**
     * Consulta de pedidos
     * @param {object} filter Object loopback filter para consultar
     * @param {boolean} filterUser True para buscar pelo id do usuário (apenas Adm)
     * @returns {any[]} Lista de pedidos
     */
    quantityData: async function (filter, filterUser = false) {
      const base_url = this.baseapi.apiLink('Requests');
      const strquery = filter ? (0, _globalFunctions.apiLinkQuery)(filter) : '';
      var url = !filterUser ? `${base_url}/indicators?mp=true&${strquery}` : `${base_url}/indicators?mp=true&fu=true&${strquery}`;
      return await this.baseapi.getHttp(url).then(response => response.data);
    }
  });

  _exports.default = _default;
});