define("printpost/routes/channel/edit", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      // let id_param = getUrlParameters("id", "", true);
      var url = this.baseapi.apiLink('Channels/list');
      var list = [];
      let item = {
        "channel": "",
        "icon": "",
        "value": "",
        "active": false,
        "hasFreight": false,
        "hasVerse": false,
        "isPrinted": false,
        "name": "",
        "order": 0
      };

      if (queryParams && queryParams.id) {
        let query = {
          filter: {
            where: {
              id: queryParams.id
            }
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        url += "?" + strquery;
        const obj = await this.baseapi.getHttp(url).then(r => r.data);
        item = obj.find(x => x.id == queryParams.id);
      } // list = obj;


      return Ember.RSVP.hash({
        item: item,
        listIcons: this.get('cListIcons')
      });
    },
    cListIcons: Ember.computed('cListIcons', function () {
      return [{
        "value": "drip-icon drip-alarm",
        "type": "alarm"
      }, {
        "value": "drip-icon drip-align-center",
        "type": "align"
      }, {
        "value": "drip-icon drip-align-justify",
        "type": "align"
      }, {
        "value": "drip-icon drip-align-left",
        "type": "align"
      }, {
        "value": "drip-icon drip-align-right",
        "type": "align"
      }, {
        "value": "drip-icon drip-anchor",
        "type": "anchor"
      }, {
        "value": "drip-icon drip-archive",
        "type": "archive"
      }, {
        "value": "drip-icon drip-arrow-down",
        "type": "arrow"
      }, {
        "value": "drip-icon drip-arrow-left",
        "type": "arrow"
      }, {
        "value": "drip-icon drip-arrow-right",
        "type": "arrow"
      }, {
        "value": "drip-icon drip-arrow-thin-down",
        "type": "arrow"
      }, {
        "value": "drip-icon drip-arrow-thin-left",
        "type": "arrow"
      }, {
        "value": "drip-icon drip-arrow-thin-right",
        "type": "arrow"
      }, {
        "value": "drip-icon drip-arrow-thin-up",
        "type": "arrow"
      }, {
        "value": "drip-icon drip-arrow-up",
        "type": "arrow"
      }, {
        "value": "drip-icon drip-article",
        "type": "article"
      }, {
        "value": "drip-icon drip-backspace",
        "type": "backspace"
      }, {
        "value": "drip-icon drip-basket",
        "type": "basket"
      }, {
        "value": "drip-icon drip-basketball",
        "type": "basketball"
      }, {
        "value": "drip-icon drip-battery-empty",
        "type": "battery"
      }, {
        "value": "drip-icon drip-battery-full",
        "type": "battery"
      }, {
        "value": "drip-icon drip-battery-low",
        "type": "battery"
      }, {
        "value": "drip-icon drip-battery-medium",
        "type": "battery"
      }, {
        "value": "drip-icon drip-bell",
        "type": "bell"
      }, {
        "value": "drip-icon drip-blog",
        "type": "blog"
      }, {
        "value": "drip-icon drip-bluetooth",
        "type": "bluetooth"
      }, {
        "value": "drip-icon drip-bold",
        "type": "bold"
      }, {
        "value": "drip-icon drip-bookmark",
        "type": "bookmark"
      }, {
        "value": "drip-icon drip-bookmarks",
        "type": "bookmarks"
      }, {
        "value": "drip-icon drip-box",
        "type": "box"
      }, {
        "value": "drip-icon drip-briefcase",
        "type": "briefcase"
      }, {
        "value": "drip-icon drip-brightness-low",
        "type": "brightness"
      }, {
        "value": "drip-icon drip-brightness-max",
        "type": "brightness"
      }, {
        "value": "drip-icon drip-brightness-medium",
        "type": "brightness"
      }, {
        "value": "drip-icon drip-broadcast",
        "type": "broadcast"
      }, {
        "value": "drip-icon drip-browser",
        "type": "browser"
      }, {
        "value": "drip-icon drip-browser-upload",
        "type": "browser"
      }, {
        "value": "drip-icon drip-brush",
        "type": "brush"
      }, {
        "value": "drip-icon drip-calendar",
        "type": "calendar"
      }, {
        "value": "drip-icon drip-camcorder",
        "type": "camcorder"
      }, {
        "value": "drip-icon drip-camera",
        "type": "camera"
      }, {
        "value": "drip-icon drip-card",
        "type": "card"
      }, {
        "value": "drip-icon drip-cart",
        "type": "cart"
      }, {
        "value": "drip-icon drip-checklist",
        "type": "checklist"
      }, {
        "value": "drip-icon drip-checkmark",
        "type": "checkmark"
      }, {
        "value": "drip-icon drip-chevron-down",
        "type": "chevron"
      }, {
        "value": "drip-icon drip-chevron-left",
        "type": "chevron"
      }, {
        "value": "drip-icon drip-chevron-right",
        "type": "chevron"
      }, {
        "value": "drip-icon drip-chevron-up",
        "type": "chevron"
      }, {
        "value": "drip-icon drip-clipboard",
        "type": "clipboard"
      }, {
        "value": "drip-icon drip-clock",
        "type": "clock"
      }, {
        "value": "drip-icon drip-clockwise",
        "type": "clockwise"
      }, {
        "value": "drip-icon drip-cloud",
        "type": "cloud"
      }, {
        "value": "drip-icon drip-cloud-download",
        "type": "cloud"
      }, {
        "value": "drip-icon drip-cloud-upload",
        "type": "cloud"
      }, {
        "value": "drip-icon drip-code",
        "type": "code"
      }, {
        "value": "drip-icon drip-contract",
        "type": "contract"
      }, {
        "value": "drip-icon drip-contract-2",
        "type": "contract"
      }, {
        "value": "drip-icon drip-conversation",
        "type": "conversation"
      }, {
        "value": "drip-icon drip-copy",
        "type": "copy"
      }, {
        "value": "drip-icon drip-crop",
        "type": "crop"
      }, {
        "value": "drip-icon drip-cross",
        "type": "cross"
      }, {
        "value": "drip-icon drip-crosshair",
        "type": "crosshair"
      }, {
        "value": "drip-icon drip-cutlery",
        "type": "cutlery"
      }, {
        "value": "drip-icon drip-device-desktop",
        "type": "device"
      }, {
        "value": "drip-icon drip-device-mobile",
        "type": "device"
      }, {
        "value": "drip-icon drip-device-tablet",
        "type": "device"
      }, {
        "value": "drip-icon drip-direction",
        "type": "direction"
      }, {
        "value": "drip-icon drip-disc",
        "type": "disc"
      }, {
        "value": "drip-icon drip-document",
        "type": "document"
      }, {
        "value": "drip-icon drip-document-delete",
        "type": "document"
      }, {
        "value": "drip-icon drip-document-edit",
        "type": "document"
      }, {
        "value": "drip-icon drip-document-new",
        "type": "document"
      }, {
        "value": "drip-icon drip-document-remove",
        "type": "document"
      }, {
        "value": "drip-icon drip-dot",
        "type": "dot"
      }, {
        "value": "drip-icon drip-dots-2",
        "type": "dots"
      }, {
        "value": "drip-icon drip-dots-3",
        "type": "dots"
      }, {
        "value": "drip-icon drip-download",
        "type": "download"
      }, {
        "value": "drip-icon drip-duplicate",
        "type": "duplicate"
      }, {
        "value": "drip-icon drip-enter",
        "type": "enter"
      }, {
        "value": "drip-icon drip-exit",
        "type": "exit"
      }, {
        "value": "drip-icon drip-expand",
        "type": "expand"
      }, {
        "value": "drip-icon drip-expand-2",
        "type": "expand"
      }, {
        "value": "drip-icon drip-experiment",
        "type": "experiment"
      }, {
        "value": "drip-icon drip-export",
        "type": "export"
      }, {
        "value": "drip-icon drip-feed",
        "type": "feed"
      }, {
        "value": "drip-icon drip-flag",
        "type": "flag"
      }, {
        "value": "drip-icon drip-flashlight",
        "type": "flashlight"
      }, {
        "value": "drip-icon drip-folder",
        "type": "folder"
      }, {
        "value": "drip-icon drip-folder-open",
        "type": "folder"
      }, {
        "value": "drip-icon drip-forward",
        "type": "forward"
      }, {
        "value": "drip-icon drip-gaming",
        "type": "gaming"
      }, {
        "value": "drip-icon drip-gear",
        "type": "gear"
      }, {
        "value": "drip-icon drip-graduation",
        "type": "graduation"
      }, {
        "value": "drip-icon drip-graph-bar",
        "type": "graph"
      }, {
        "value": "drip-icon drip-graph-line",
        "type": "graph"
      }, {
        "value": "drip-icon drip-graph-pie",
        "type": "graph"
      }, {
        "value": "drip-icon drip-headset",
        "type": "headset"
      }, {
        "value": "drip-icon drip-heart",
        "type": "heart"
      }, {
        "value": "drip-icon drip-help",
        "type": "help"
      }, {
        "value": "drip-icon drip-home",
        "type": "home"
      }, {
        "value": "drip-icon drip-hourglass",
        "type": "hourglass"
      }, {
        "value": "drip-icon drip-inbox",
        "type": "inbox"
      }, {
        "value": "drip-icon drip-information",
        "type": "information"
      }, {
        "value": "drip-icon drip-italic",
        "type": "italic"
      }, {
        "value": "drip-icon drip-jewel",
        "type": "jewel"
      }, {
        "value": "drip-icon drip-lifting",
        "type": "lifting"
      }, {
        "value": "drip-icon drip-lightbulb",
        "type": "lightbulb"
      }, {
        "value": "drip-icon drip-link",
        "type": "link"
      }, {
        "value": "drip-icon drip-link-broken",
        "type": "link"
      }, {
        "value": "drip-icon drip-list",
        "type": "list"
      }, {
        "value": "drip-icon drip-loading",
        "type": "loading"
      }, {
        "value": "drip-icon drip-location",
        "type": "location"
      }, {
        "value": "drip-icon drip-lock",
        "type": "lock"
      }, {
        "value": "drip-icon drip-lock-open",
        "type": "lock"
      }, {
        "value": "drip-icon drip-mail",
        "type": "mail"
      }, {
        "value": "drip-icon drip-map",
        "type": "map"
      }, {
        "value": "drip-icon drip-media-loop",
        "type": "media"
      }, {
        "value": "drip-icon drip-media-next",
        "type": "media"
      }, {
        "value": "drip-icon drip-media-pause",
        "type": "media"
      }, {
        "value": "drip-icon drip-media-play",
        "type": "media"
      }, {
        "value": "drip-icon drip-media-previous",
        "type": "media"
      }, {
        "value": "drip-icon drip-media-record",
        "type": "media"
      }, {
        "value": "drip-icon drip-media-shuffle",
        "type": "media"
      }, {
        "value": "drip-icon drip-media-stop",
        "type": "media"
      }, {
        "value": "drip-icon drip-medical",
        "type": "medical"
      }, {
        "value": "drip-icon drip-menu",
        "type": "menu"
      }, {
        "value": "drip-icon drip-message",
        "type": "message"
      }, {
        "value": "drip-icon drip-meter",
        "type": "meter"
      }, {
        "value": "drip-icon drip-microphone",
        "type": "microphone"
      }, {
        "value": "drip-icon drip-minus",
        "type": "minus"
      }, {
        "value": "drip-icon drip-monitor",
        "type": "monitor"
      }, {
        "value": "drip-icon drip-move",
        "type": "move"
      }, {
        "value": "drip-icon drip-music",
        "type": "music"
      }, {
        "value": "drip-icon drip-network-1",
        "type": "network"
      }, {
        "value": "drip-icon drip-network-2",
        "type": "network"
      }, {
        "value": "drip-icon drip-network-3",
        "type": "network"
      }, {
        "value": "drip-icon drip-network-4",
        "type": "network"
      }, {
        "value": "drip-icon drip-network-5",
        "type": "network"
      }, {
        "value": "drip-icon drip-pamphlet",
        "type": "pamphlet"
      }, {
        "value": "drip-icon drip-paperclip",
        "type": "paperclip"
      }, {
        "value": "drip-icon drip-pencil",
        "type": "pencil"
      }, {
        "value": "drip-icon drip-phone",
        "type": "phone"
      }, {
        "value": "drip-icon drip-photo",
        "type": "photo"
      }, {
        "value": "drip-icon drip-photo-group",
        "type": "photo"
      }, {
        "value": "drip-icon drip-pill",
        "type": "pill"
      }, {
        "value": "drip-icon drip-pin",
        "type": "pin"
      }, {
        "value": "drip-icon drip-plus",
        "type": "plus"
      }, {
        "value": "drip-icon drip-power",
        "type": "power"
      }, {
        "value": "drip-icon drip-preview",
        "type": "preview"
      }, {
        "value": "drip-icon drip-print",
        "type": "print"
      }, {
        "value": "drip-icon drip-pulse",
        "type": "pulse"
      }, {
        "value": "drip-icon drip-question",
        "type": "question"
      }, {
        "value": "drip-icon drip-reply",
        "type": "reply"
      }, {
        "value": "drip-icon drip-reply-all",
        "type": "reply"
      }, {
        "value": "drip-icon drip-return",
        "type": "return"
      }, {
        "value": "drip-icon drip-retweet",
        "type": "retweet"
      }, {
        "value": "drip-icon drip-rocket",
        "type": "rocket"
      }, {
        "value": "drip-icon drip-scale",
        "type": "scale"
      }, {
        "value": "drip-icon drip-search",
        "type": "search"
      }, {
        "value": "drip-icon drip-shopping-bag",
        "type": "shopping"
      }, {
        "value": "drip-icon drip-skip",
        "type": "skip"
      }, {
        "value": "drip-icon drip-stack",
        "type": "stack"
      }, {
        "value": "drip-icon drip-star",
        "type": "star"
      }, {
        "value": "drip-icon drip-stopwatch",
        "type": "stopwatch"
      }, {
        "value": "drip-icon drip-store",
        "type": "store"
      }, {
        "value": "drip-icon drip-suitcase",
        "type": "suitcase"
      }, {
        "value": "drip-icon drip-swap",
        "type": "swap"
      }, {
        "value": "drip-icon drip-tag",
        "type": "tag"
      }, {
        "value": "drip-icon drip-tag-delete",
        "type": "tag"
      }, {
        "value": "drip-icon drip-tags",
        "type": "tags"
      }, {
        "value": "drip-icon drip-thumbs-down",
        "type": "thumbs"
      }, {
        "value": "drip-icon drip-thumbs-up",
        "type": "thumbs"
      }, {
        "value": "drip-icon drip-ticket",
        "type": "ticket"
      }, {
        "value": "drip-icon drip-time-reverse",
        "type": "time"
      }, {
        "value": "drip-icon drip-to-do",
        "type": "to"
      }, {
        "value": "drip-icon drip-toggles",
        "type": "toggles"
      }, {
        "value": "drip-icon drip-trash",
        "type": "trash"
      }, {
        "value": "drip-icon drip-trophy",
        "type": "trophy"
      }, {
        "value": "drip-icon drip-upload",
        "type": "upload"
      }, {
        "value": "drip-icon drip-user",
        "type": "user"
      }, {
        "value": "drip-icon drip-user-group",
        "type": "user"
      }, {
        "value": "drip-icon drip-user-id",
        "type": "user"
      }, {
        "value": "drip-icon drip-vibrate",
        "type": "vibrate"
      }, {
        "value": "drip-icon drip-view-apps",
        "type": "view"
      }, {
        "value": "drip-icon drip-view-list",
        "type": "view"
      }, {
        "value": "drip-icon drip-view-list-large",
        "type": "view"
      }, {
        "value": "drip-icon drip-view-thumb",
        "type": "view"
      }, {
        "value": "drip-icon drip-volume-full",
        "type": "volume"
      }, {
        "value": "drip-icon drip-volume-low",
        "type": "volume"
      }, {
        "value": "drip-icon drip-volume-medium",
        "type": "volume"
      }, {
        "value": "drip-icon drip-volume-off",
        "type": "volume"
      }, {
        "value": "drip-icon drip-wallet",
        "type": "wallet"
      }, {
        "value": "drip-icon drip-warning",
        "type": "warning"
      }, {
        "value": "drip-icon drip-web",
        "type": "web"
      }, {
        "value": "drip-icon drip-weight",
        "type": "weight"
      }, {
        "value": "drip-icon drip-wifi",
        "type": "wifi"
      }, {
        "value": "drip-icon drip-wrong",
        "type": "wrong"
      }, {
        "value": "drip-icon drip-zoom-in",
        "type": "zoom"
      }, {
        "value": "drip-icon drip-zoom-out",
        "type": "zoom"
      }];
    })
  });

  _exports.default = _default;
});