define("printpost/controllers/carteiras/new", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const newWallet = Ember.Object.extend({
    name: null,
    fileName: null,
    description: null
  });

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    newWallet: newWallet.create(),
    isShowingTranslucent: true,
    // uploadImage: task(function* (file) {
    //
    //   let post_data =
    //     {
    //       name: get(file, 'name'),
    //       id: get(file, 'id'),
    //       channel: get(file, 'type'),
    //     };
    //
    //   set(this.newWallet,'fileName', post_data.name);
    //
    //   try {
    //     let token = this.get('session.data.authenticated.id');
    //     let opts = {
    //       'data': post_data
    //     };
    //     let urlbase = [config.APP.API.host, config.APP.API.namespace].join('/');
    //     let response = yield file.upload(urlbase + '/Wallets/upload-file' + '?access_token=' + token, opts);
    //     if (response) {
    //       // print(response.body.data.attributes.file);
    //     }
    //   } catch (e) {
    //     // fileRecord.rollback();
    //   }
    // }).maxConcurrency(3).enqueue(),
    createWalletTask: (0, _emberConcurrency.task)(function* (newWallet) {
      const USER = this.get('session.data.authenticated');
      let wallet = this.store.createRecord('wallet', {
        name: Ember.get(newWallet, 'name'),
        description: Ember.get(newWallet, 'description'),
        file: Ember.get(newWallet, 'fileName'),
        userId: Ember.get(USER, 'userId')
      });

      try {
        yield wallet.save();
        this.toast.success('', 'Salvo com sucesso', {
          positionClass: 'toast-bottom-right'
        });
        Ember.getOwner(this).lookup('route:carteiras').refresh();
        this.transitionToRoute('carteiras');
      } catch (error) {
        for (let err in error.errors) {
          if (error.errors.hasOwnProperty(err)) {
            if (error.errors[err].status == 422) {
              this.toast.error('Campos obrigatórios em branco.', 'Erro!', {
                positionClass: 'toast-bottom-right'
              });
            }
          }
        }

        return error;
      }
    }).maxConcurrency(3).enqueue(),
    actions: {
      refreshAndClose: function () {
        Ember.getOwner(this).lookup('route:carteiras').refresh();
        this.transitionToRoute('carteiras');
      },
      createWallet: function (newWallet) {
        Ember.get(this, 'createWalletTask').perform(newWallet);
      },

      uploadImage(file) {
        Ember.get(this, 'uploadImage').perform(file);
      }

    }
  });

  _exports.default = _default;
});