define("printpost/validators/password-strength", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatePasswordStrength;

  // import buildMessage from 'ember-changeset-validations/utils/validation-errors';
  function passwordScorer(password
  /*, user_inputs = []*/
  ) {
    password = password.trim();
    let len = password.length;
    let score = 0;
    if (len <= 0) return 0;
    let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^\\&\\*])(?=.{8,})");
    let mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    let badPasswords = ['iloveyou', 'teamo', 'euteamo', 'f\\*ckyou', 'a\\*\\*hole', 'f\\*ckoff', 'iloveme', 'fodase', 'trustno1', 'beautiful', 'ihateyou', 'bullsh\\*t', 'lovelove', 'amoramor', 'amor', '1q2w3e4r', '1qaz2wsx', '1qazxsw2', 'zaq12wsx', '\\!qaz2wsx', '1qaz\\@wsx', 'myspace', 'mustang', 'linkedin', 'ferrari', 'playboy', 'mercedes', 'cocacola', 'snickers', 'corvette', 'skittles', 'superman', 'pokemon', 'slipknot', 'starwars', 'metallica', 'nirvana', 'blink182', 'spiderman', 'greenday', 'rockstar', 'liverpool', 'chelsea', 'arsenal', 'barcelona', 'manchester', 'brasil', 'brazil', 'teste', '123', '12345'];
    let badRegex = new RegExp("^(" + badPasswords.join('|') + ")");
    if (mediumRegex.test(password)) score = 45;
    if (strongRegex.test(password)) score = 69; // Adições

    if (/[^0-9a-zA-Z]+/.test(password)) score += 10;
    if (/[\\*\\@]+/.test(password)) score += 10;
    if (/[A-Z]+/.test(password)) score += 5;
    if (/[a-z]+/.test(password)) score += 5; // Size

    if (len > 4) score += len * 2.13; // Punições
    // ...

    if (badRegex.test(password)) score = score * 0.3; // -70%

    if (/(001|100|0101|1010)/.test(password)) score -= 5;
    if (score > 100) score = 100;
    if (score < 0) score = 0; // console.log(password, parseInt(score));

    return score;
  }

  function validatePasswordStrength(options) {
    options = options || {
      minScore: 50
    };
    return (key, newValue
    /*, oldValue, changes, content*/
    ) => {
      if (newValue === undefined) newValue = '';
      let result = false;
      let score = passwordScorer(newValue);
      if (score >= options.minScore) result = true;
      return result === true ? true : 'Senha muito fraca'; //  (' + score + ')
      // return (result === true) ? true : buildMessage('invalid', newValue, oldValue, changes, content);
    };
  }
});