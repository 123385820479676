define("printpost/controllers/services/email/edit", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isShowingTranslucent: true,
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    modelEditor: Ember.computed('model.taskInstance.value', function () {
      return this.get('model.taskInstance.value');
    }),
    updateTemplate: (0, _emberConcurrency.task)(function* (json_data) {
      let request = yield this.baseapi.updateTemplate(json_data);
      return request;
    }).keepLatest(),
    actions: {
      refreshAndClose: function () {
        Ember.getOwner(this).lookup('route:services').refresh();
        this.transitionToRoute('services.email');
      },
      createTemplate: function (json_data) {
        Ember.get(this, 'updateTemplate').perform(json_data).then(() => {
          this.toast.success('', 'Template editada com sucesso!', {
            positionClass: 'toast-bottom-right'
          });
          Ember.getOwner(this).lookup('route:services.email').refresh();
          this.transitionToRoute('services.email');
        }).catch(error => {
          this.toast.error('Falha na edição, verifique campos obrigatórios!', 'Erro!', {
            positionClass: 'toast-bottom-right'
          });
          return error;
        });
      }
    }
  });

  _exports.default = _default;
});