define("printpost/storages/force-wallet", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Storage = _object.default.extend();

  Storage.reopenClass({
    initialState() {
      return {
        name: null,
        emailCount: null,
        addressCount: null,
        phoneCount: null,
        totalPeople: null,
        link: null,
        etag: null,
        bucket: null,
        file: null,
        phone: null,
        description: null,
        columns: null,
        columns_email: null,
        columns_sms: null,
        columns_identifies: null,
        trateOmni: null,
        id: null,
        userId: null,
        deletedAt: null,
        createdAt: null,
        updatedAt: null,
        organizationId: null,
        image: null,
        maxLength: null,
        peoples: null,
        version: null,
        qtdSend: null
      };
    }

  });
  var _default = Storage;
  _exports.default = _default;
});