define("printpost/controllers/analises/carteira", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: {
      id: null
    },
    options: Ember.computed('model.analises.options', function () {
      return {
        "responsive": true,
        "legend": {
          "display": true,
          "labels": {
            "fontColor": "#000"
          }
        },
        "scales": {
          "yAxes": [{
            "display": false,
            "ticks": {
              "beginAtZero": false
            },
            "gridLines": {
              "display": false
            }
          }],
          "xAxes": [{
            "display": false,
            "gridLines": {
              "display": false
            }
          }]
        }
      };
    }),
    wallet: Ember.computed('model.wallet', function () {
      var data = this.get('model.wallet.firstObject');
      return data; //return this.get('model.request.firstObject');
    }),
    randomScalingFactor: function (value = 100) {
      return Math.round(Math.random() * value);
    },
    v1: Ember.computed('model.analises.letterQtd', function () {
      return this.randomScalingFactor();
    }),
    v2: Ember.computed('model.analises.smsQtd', function () {
      return this.randomScalingFactor();
    }),
    v3: Ember.computed('model.analises.emailQtd', function () {
      return this.randomScalingFactor();
    }),
    dataGrafPie1: Ember.computed('model.analises.p1', function () {
      let d = {
        "datasets": [{
          "label": "Desempenho",
          "fill": false,
          "data": [this.randomScalingFactor(), this.randomScalingFactor(20), this.randomScalingFactor()],
          "backgroundColor": ['#50E2D4', '#ED9DA8', '#247ED6'],
          "borderColor": ['#50E2D4', '#ED9DA8', '#247ED6'],
          "borderWidth": 0
        }],
        "color": ["#50E2D4", "#ED9DA8", "#247ED6"],
        "labels": ["Entregues", "Não Entregues", "Digitais"]
      };
      return d;
    }),
    dataGrafPie2: Ember.computed('model.analises.p2', function () {
      let d = {
        "datasets": [{
          "label": "Desempenho",
          "fill": false,
          "data": [this.randomScalingFactor(), this.randomScalingFactor(20), this.randomScalingFactor()],
          "backgroundColor": ['#50E2D4', '#ED9DA8', '#247ED6'],
          "borderColor": ['#50E2D4', '#ED9DA8', '#247ED6'],
          "borderWidth": 0
        }],
        "color": ["#50E2D4", "#ED9DA8", "#247ED6"],
        "labels": ["Entregues", "Não Entregues", "Digitais"]
      };
      return d;
    }),
    dataGrafPie3: Ember.computed('model.analises.p3', function () {
      let d = {
        "datasets": [{
          "label": "Desempenho",
          "fill": false,
          "data": [this.randomScalingFactor(), this.randomScalingFactor(20), this.randomScalingFactor()],
          "backgroundColor": ['#50E2D4', '#ED9DA8', '#247ED6'],
          "borderColor": ['#50E2D4', '#ED9DA8', '#247ED6'],
          "borderWidth": 0
        }],
        "color": ["#50E2D4", "#ED9DA8", "#247ED6"],
        "labels": ["Entregues", "Não Entregues", "Digitais"]
      };
      return d;
    }) // let d = {
    //     "datasets": [
    //         {
    //         "label": "Envios",
    //         "fill": false,
    //         "data": [
    //             this.randomScalingFactor(), 
    //             this.randomScalingFactor(), 
    //             this.randomScalingFactor(), 
    //         ],
    //         "pointBackgroundColor": "#50E2D4",
    //         "pointBorderColor" : "#50E2D4",
    //         "borderColor" : "#50E2D4"
    //         },
    //         {
    //         "label": "Retornos",
    //         "fill": false,
    //         "data": [
    //             this.randomScalingFactor(), 
    //             this.randomScalingFactor(), 
    //             this.randomScalingFactor(), 
    //             this.randomScalingFactor(), 
    //             this.randomScalingFactor(), 
    //             this.randomScalingFactor(),
    //             this.randomScalingFactor()
    //         ],
    //         "pointBackgroundColor": "#ED9DA8",
    //         "pointBorderColor" : "#ED9DA8",
    //         "borderColor" : "#ED9DA8"
    //         },
    //     ],
    //     "labels": [ "Jan", "Fev", "Mar", "Abril", "Maio", "Junho", "Julho" ]
    // }

  });

  _exports.default = _default;
});