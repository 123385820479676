define("printpost/routes/usuarios/company", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      // let id_param = getUrlParameters("id", "", true);
      var url = this.baseapi.apiLink('AccountOrganizations/list');
      var list = [];
      let tipo_account = 'fisica';
      var userAdm = false;
      var objitem = null;
      let item = {
        "type": "",
        "cpf": "",
        "cnpj": "",
        "payment": "faturado",
        "username": "",
        "password": "",
        "email": "",
        "emailVerified": true,
        "phone1": "",
        "phone2": "",
        "razaoSocial": "",
        "inscricaoEstadual": "",
        master: false
      };

      if (queryParams && queryParams.id) {
        let query = {
          filter: {
            where: {
              accountId: queryParams.id
            },
            include: 'account'
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        url += "?" + strquery; //find user with id

        const obj = await this.baseapi.getHttp(url).then(r => r.data);
        objitem = obj.find(x => x.accountId == queryParams.id);

        if (objitem) {
          userAdm = objitem.master;
          item = objitem.account;
        }

        tipo_account = item.cnpj ? 'juridica' : 'fisica';
      } //menus organization


      var url_menus = this.baseapi.apiLink(`MenuOrganizations/list`);

      if (queryParams && queryParams.id) {
        url_menus += `?id=${queryParams.id}`;
      }

      var list_menus = [];
      const obj_menus = await this.baseapi.getHttp(url_menus).then(m => m.data);

      if (obj_menus.length > 0) {
        obj_menus.forEach(function (item) {
          if (!item.menuRole) {
            item.menuRole = {
              order: ""
            };
          }
        });
        list_menus = obj_menus;
      } //passwd


      var pass = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      };
      return Ember.RSVP.hash({
        data: list,
        item: item,
        pass: pass,
        type: tipo_account,
        menus: list_menus,
        userAdm: userAdm,
        userOrg: objitem
      });
    }
  });

  _exports.default = _default;
});