define("printpost/routes/analises/cliente", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    queryParams: {
      id: null
    },
    model: function (queryParams) {
      let id = queryParams.id;
      let url = this.baseapi.apiLink('PeopleHistories/' + id);
      let r = this.baseapi.getHttp(url);
      let resp = r.then(response => {
        let objReturn = response.data.data.attributes;
        return objReturn;
      });
      return resp;
    }
  });

  _exports.default = _default;
});