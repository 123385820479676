define("printpost/templates/sign/out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mRdk7dw/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-signin\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sp-12\"],[9],[10],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"font-weight-normal text-center header-title logout-loading\"],[9],[1,[21,\"loading-spinner\"],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/sign/out.hbs"
    }
  });

  _exports.default = _default;
});