define("printpost/components/clickmassa-config", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    configurationList: Ember.computed(function () {
      return [];
    }),
    costCenterId: '',
    dataItem: Ember.computed(function () {
      return {
        name: '',
        token: '',
        url: '',
        costCenterId: ''
      };
    }).property().volatile(),
    loadConfiguration: (0, _emberConcurrency.task)(function* () {
      var url = this.baseapi.apiLink('CostCenters/list');
      const data = yield this.baseapi.getHttp(`${url}?filter[where][id]=${Ember.get(this, 'costCenterId')}&filter[include]=clickMassaConfig`).then(x => x.data);

      if (data && data.length) {
        Ember.set(this, 'configurationList', data[0].clickMassaConfig);
      } else {
        Ember.set(this, 'configurationList', []);
      }
    }),

    init() {
      this._super(...arguments);

      Ember.get(this, 'loadConfiguration').perform();
    },

    // appVersion: computed(function () {
    //     let url = this.baseapi.apiLink('app/version');
    //     return DS.PromiseObject.create({
    //         promise: this.baseapi.getHttp(url).then(x => x.data.version)
    //     });
    // }).property().volatile(),
    // didRender() {
    //     this._super(...arguments);
    //     let url = this.baseapi.apiLink('app/version');
    //     DS.PromiseObject.create({
    //         promise: this.baseapi.getHttp(url).then(x => x.data)
    //     }).then((()) => {
    //         let version = ().version;
    //         let local = this.get('localVersion')
    //         if (version != local) this.send('toggleToast')
    //     });
    // },
    actions: {
      selectItem: function () {},
      setItem: function (data) {
        let d = {
          id: data.id,
          name: data.name,
          token: data.token,
          url: data.url,
          costCenterId: Ember.get(this, 'costCenterId')
        };
        Ember.set(this, 'dataItem', d);
      },
      clearData: function () {
        let d = {
          id: null,
          name: null,
          token: null,
          url: null,
          costCenterId: Ember.get(this, 'costCenterId')
        };
        Ember.set(this, 'dataItem', d);
      },
      salvarItem: function () {
        let modelName = 'ClickMassaConfig';
        let modelPluralName = 'ClickMassaConfigs';
        let item = Ember.get(this, 'dataItem');
        let _object = {
          data: {
            type: modelName,
            attributes: item
          }
        };

        if (item.name.length > 0 && item.token.length > 0 && item.costCenterId.length > 0 && item.url.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink(modelPluralName);
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(() => {
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
              this.send('clearData');
            }, () => {
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
              this.send('clearData');
            });
            Ember.get(this, 'loadConfiguration').perform();
          } else {
            let url = this.baseapi.apiLink(modelPluralName + '/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(() => {
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              item.id = __rp.data.id;
              this.preloader.toggleClass('d-none');
              this.send('clearData');
            }, () => {
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
              this.preloader.toggleClass('d-none');
              this.send('clearData');
            });
            Ember.get(this, 'loadConfiguration').perform();
          }
        } else {
          this.toast.error('Item Não cadastrado!', 'Verifique os campos obrigatórios!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      apagarItem: function () {
        let modelPluralName = 'ClickMassaConfigs';
        this.preloader.toggleClass('d-none');
        let item_data = Ember.get(this, 'dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink(modelPluralName);
          let call = this.baseapi.delete(url + '/' + item_data.id);
          call.then(() => {
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
            this.send('clearData');
          }, () => {
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
            this.send('clearData');
          });
          Ember.get(this, 'loadConfiguration').perform();
        }
      }
    }
  });

  _exports.default = _default;
});