define("printpost/controllers/usuarios/edit", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataItem: Ember.computed('model.item', function () {
      var model = this.get('model.item');
      return model;
    }).property().volatile(),
    dataPassword: Ember.computed('model.pass', function () {
      var model = this.get('model.pass');
      return model;
    }).property().volatile(),
    dataAddressesAccount: Ember.computed('model.address', function () {
      var model = this.get('model.address');
      return model;
    }).property().volatile(),
    // dataEnderecoAccount: computed('model.invoiceAddress', function() {
    //     var model = this.get('model.invoiceAddress');
    //     return model;
    // }).property().volatile(),
    dataEndereco: Ember.computed('dataEndereco', function () {
      return {
        id: '',
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
        cep: '',
        complemento: '',
        numero: '',
        cidadecep: '',
        userId: '',
        type: 'ADDRESS_FREIGHT'
      };
    }).property().volatile(),
    reloadAddress: function (userId) {
      //addres
      this.preloader.toggleClass('d-none');
      let url_address = this.baseapi.apiLink('Addresses/list?filter[where][type]=ADDRESS_REGISTER_INVOICE');

      if (userId && userId.length > 10) {
        url_address += "filter[where][userId]=" + userId;
      }

      this.baseapi.getHttp(url_address).then(resp => {
        this.set('model.address', resp.data); //set(this, 'dataAddressesAccount', resp.data);

        let addressInvoice = resp.data.find(x => x.type.toUpperCase() == 'ADDRESS_REGISTER_INVOICE');
        this.set('model.invoiceAddress', addressInvoice);
        this.preloader.toggleClass('d-none');
      });
    },
    changePasswordTask: (0, _emberConcurrency.task)(function* () {
      let item_data = Ember.get(this, 'dataItem');
      var changePass = Ember.get(this, 'dataPassword');

      try {
        let payload = changePass;
        payload.id = item_data.id;

        if (changePass.newPassword == changePass.confirmPassword) {
          let url = this.baseapi.apiLink('Accounts');
          let call = this.baseapi.post(url + "/alterar-senha", payload);
          call.then(resp => {
            if (resp.data.errors) {
              this.toast.error(resp.data.errors[0].detail, 'Erro!', {
                positionClass: 'toast-bottom-right'
              });
            } else {
              this.toast.success('Senha alterada com sucesso', 'Sucesso!', {
                positionClass: 'toast-bottom-right'
              });
              this.transitionToRoute('usuarios.list');
            }
          });
        }
      } catch (error) {
        this.toast.error('Senha antiga incorreta.', 'Erro!', {
          positionClass: 'toast-bottom-right'
        });
        return error;
      }
    }),
    dataList: Ember.computed('model.data', function () {
      let list = this.get('model.data');
      return list;
    }),
    flatDateValue: Ember.computed('flatDateValue', function () {
      let item = Ember.get(this, 'dataItem');
      let vfm = item.invoiceConfig.period[0].split("-");
      let vfnm = item.invoiceConfig.period[1].split("-");
      let msg = vfm[1] == vfnm[1] ? "do mesmo mês" : "do mês seguinte";
      Ember.set(this, 'msgPeriodoFatura', msg);
      let ret = [new Date(item.invoiceConfig.period[0]), new Date(item.invoiceConfig.period[1])];
      console.log(ret);
      return ret;
    }).property().volatile(),
    myFlatpickrRef: Ember.computed(function () {
      return null;
    }),
    datePeriodo: [],
    cStrDateBegin: Ember.computed('strDateBegin', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-01 00:00:00';
      return strDate;
    }),
    cStrDateEnd: Ember.computed('strDateEnd', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + ' 19:59:00';
      return strDate;
    }),
    msgPeriodoFatura: Ember.computed('msgPeriodoFatura', function () {
      return '';
    }),
    actions: {
      salvar: function (redirect = true) {
        let item_data = Ember.get(this, 'dataItem');
        let item = {
          "id": item_data.id,
          "type": item_data.type,
          "roleId": item_data.roleId,
          "cpf": item_data.cpf,
          "cnpj": item_data.cnpj,
          "payment": item_data.payment,
          "username": item_data.username,
          "email": item_data.email,
          "emailVerified": item_data.emailVerified,
          "phone1": item_data.phone1,
          "phone2": item_data.phone2,
          "razaoSocial": item_data.razaoSocial,
          "inscricaoEstadual": item_data.inscricaoEstadual,
          "technicalContact": item_data.technicalContact,
          "invoiceConfig": item_data.invoiceConfig
        };
        let dataPass = Ember.get(this, 'dataPassword');

        if (!item.id) {
          if (!dataPass.newPassword || !dataPass.confirmPassword || dataPass.newPassword != dataPass.confirmPassword) {
            this.toast.error('Senhas não conferem!', 'Verificar se a senha e a confirmação são iguais', {
              positionClass: 'toast-bottom-right'
            });
            return;
          } else {
            item.password = dataPass.newPassword;
          }
        }

        let _object = {
          data: item
        };
        let isValide = true;

        if (item.username.length <= 0 || item.email.length <= 0 || item.type.length <= 0) {
          isValide = false;
        }

        if (item.cpf.length < 14 && item.cnpj.length < 18) {
          isValide = false;
        }

        if (item.cnpj.length == 18 && (item.razaoSocial.length <= 0 || item.inscricaoEstadual.length <= 0)) {
          isValide = false;
        }

        if (item.type == "faturado" && (item.invoiceConfig.dueDate.length <= 0 || item.invoiceConfig.period.length < 2)) {
          isValide = false;
        }

        if (isValide) {
          let url = this.baseapi.apiLink('Accounts/save');
          let call = this.baseapi.post(url, _object);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            if (resp.data.success) {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });

              if (redirect) {
                this.transitionToRoute('usuarios.list');
              } else {
                Ember.set(this, 'dataItem.id', resp.data.success[0].source.id);
                this.send('salvarEndereco', false);
              }
            }

            if (resp.data.errors) {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não cadastrado!', resp.data.errors[0].detail, {
                positionClass: 'toast-bottom-right'
              });
            }
          }, error => {
            this.preloader.toggleClass('d-none');
            var message = error.response.data.errors[0].detail;
            this.toast.error('Item Não cadastrado!', `Dados não foram cadastrados! ${message}`, {
              positionClass: 'toast-bottom-right'
            });
          });
        } else {
          this.toast.error('Campos obrigatórios pendentes!', 'Verifique os campos obrigatórios e preencha todos!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      setUserPerfilSelect: function () {
        let perfilType = this.baseapi.getFieldSelectValue("userperfil_selection");

        if (perfilType != "0") {
          let perfis = this.get('model.data');
          let item = perfis.find(x => x.id == perfilType);
          Ember.set(this, 'dataItem.type', item.name);
          Ember.set(this, 'dataItem.roleId', item.id);
        }
      },
      checkSenha: function (obj) {
        var passwordStength = (0, _globalFunctions.checkPassStrength)(obj);
        var box = Ember.$("#box-password-stength");
        box.removeClass('hidden');

        switch (passwordStength) {
          case "good":
            box.removeClass('stength-weak');
            box.removeClass('stength-best');
            box.addClass('stength-good');
            break;

          case 'weak':
            box.removeClass('stength-good');
            box.removeClass('stength-best');
            box.addClass('stength-weak');
            break;

          case 'strong':
            box.removeClass('stength-weak');
            box.removeClass('stength-good');
            box.addClass('stength-best');
            break;

          default:
            box.removeClass('stength-good');
            box.removeClass('stength-best');
            box.addClass('stength-weak');
            break;
        }
      },
      alterarSenha: function () {
        Ember.get(this, 'changePasswordTask').perform();
      },
      apagar: function () {
        let item_data = Ember.get(this, 'dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink('Accounts');
          let call = this.baseapi.delete(url + '/' + item_data.id);
          call.then(resp => {
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('usuarios.list');
          }, error => {
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },
      selectAddress: function (address) {
        // set(this, 'dataEnderecoAccount', address);
        Ember.set(this, 'dataEndereco', address);
        this.set('model.invoiceAddress', address);
        this.send('salvarEndereco');
      },
      setAddress: function (address) {
        let d = {
          id: address.id,
          nome: address.nome,
          endereco: address.endereco,
          bairro: address.bairro,
          cidade: address.cidade,
          uf: address.uf,
          cep: address.cep,
          complemento: address.complemento,
          numero: address.numero,
          cidadecep: address.cidadecep,
          type: address.type,
          userId: address.userId
        };
        Ember.set(this, 'dataEndereco', d);
      },
      clearDataEndereco: function () {
        let d = {
          id: '',
          nome: '',
          endereco: '',
          bairro: '',
          cidade: '',
          uf: '',
          cep: '',
          complemento: '',
          numero: '',
          cidadecep: '',
          type: '',
          userId: ''
        };
        Ember.set(this, 'dataEndereco', d);
      },

      buscaCEP() {
        let t = Ember.$('#cep_addres').val();
        t = (0, _globalFunctions.removeSpecialChar)(t).replace("-", "").replace(".", "");
        Ember.$('#cep_addres').val(t);

        if (t.length < 8) {
          this.toast.error('CEP Inválido!', 'Informe CEP válido.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let url = "//viacep.com.br/ws/" + t + "/json";
        let r = this.baseapi.getHttp(url, false);
        r.then(response => {
          let data = response.data;
          Ember.set(this, 'dataEndereco.cep', t);
          Ember.set(this, 'dataEndereco.endereco', data.logradouro);
          Ember.set(this, 'dataEndereco.bairro', data.bairro);
          Ember.set(this, 'dataEndereco.complemento', data.complemento);
          Ember.set(this, 'dataEndereco.cidade', data.localidade);
          Ember.set(this, 'dataEndereco.uf', data.uf);
          Ember.set(this, 'dataEndereco.cidadecep', data.localidade + ' - ' + data.uf);
        });
      },

      salvarEndereco: function (primEvent = true) {
        let modelName = 'Address';
        let modelPluralName = 'Addresses';
        let item = Ember.get(this, 'dataEndereco');
        let user = Ember.get(this, 'dataItem');

        if (user && user.id) {
          item.userId = user.id;
        } else {
          if (primEvent) {
            this.send('salvar', false);
            user = Ember.get(this, 'dataItem');
            item.userId = user.id;
            return;
          }
        }

        item.type = 'ADDRESS_REGISTER_INVOICE';
        let _object = {
          data: {
            type: modelName,
            attributes: item
          }
        };

        if (item.nome.length > 0 && item.numero > 0 && item.endereco.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink(modelPluralName);
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              let listAddress = this.get('model.address');

              if (listAddress && listAddress.length > 1) {
                //atualizando demais endereços
                let url2 = this.baseapi.apiLink(modelPluralName + "/update-address-register");
                this.baseapi.patch(url2, {
                  data: {
                    userId: item.userId,
                    addressId: item.id
                  }
                }).then(sucessUpdate => {
                  this.preloader.toggleClass('d-none');

                  if (sucessUpdate.data.errors) {
                    this.toast.error('Erro interno! #2', 'Demais dados não foram atualizados!', {
                      positionClass: 'toast-bottom-right'
                    });
                  }
                }, errorUpdate => {
                  this.preloader.toggleClass('d-none');
                  this.toast.error('Erro interno! #2', 'Demais dados não foram atualizados!', {
                    positionClass: 'toast-bottom-right'
                  });
                });
              } else {
                this.preloader.toggleClass('d-none');
              }

              this.reloadAddress(item.userId);
            }, error => {
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
              this.preloader.toggleClass('d-none');
            });
          } else {
            let url = this.baseapi.apiLink(modelPluralName + '/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(resp => {
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              item.id = __rp.data.id;
              let listAddress = this.get('model.address');

              if (listAddress && listAddress.length > 1) {
                //atualizando demais endereços
                let url2 = this.baseapi.apiLink(modelPluralName + "/update-address-register");
                this.baseapi.patch(url2, {
                  data: {
                    userId: item.userId,
                    addressId: item.id
                  }
                }).then(sucessUpdate => {
                  this.preloader.toggleClass('d-none');

                  if (sucessUpdate.data.errors) {
                    this.toast.error('Erro interno! #2', 'Demais dados não foram atualizados!', {
                      positionClass: 'toast-bottom-right'
                    });
                  }
                }, errorUpdate => {
                  this.preloader.toggleClass('d-none');
                  this.toast.error('Erro interno! #2', 'Demais dados não foram atualizados!', {
                    positionClass: 'toast-bottom-right'
                  });
                });
              } else {
                this.preloader.toggleClass('d-none');
              }

              this.reloadAddress(item.userId);
            }, error => {
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
              console.log(error);
              this.preloader.toggleClass('d-none');
            });
          }
        } else {
          this.toast.error('Item Não cadastrado!', 'Verifique os campos obrigatórios!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      apagarEndereco: function () {
        let modelName = 'Address';
        let modelPluralName = 'Addresses';
        this.preloader.toggleClass('d-none');
        let item_data = Ember.get(this, 'dataEndereco');

        if (item_data.id) {
          let url = this.baseapi.apiLink(modelPluralName);
          let call = this.baseapi.delete(url + '/' + item_data.id);
          call.then(resp => {
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
          }, error => {
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
            console.log(error);
          });
          this.reloadAddress(item_data.userId);
        }
      },

      updateCPF(unmasked, masked) {
        if (unmasked.length == 11 || unmasked.length == 14) {
          if ((0, _globalFunctions.validaCpfCnpj)(masked)) {
            this.toast.success('CPF/CNPJ válido!', 'CPF/CNPJ válido!', {
              positionClass: 'toast-bottom-right'
            });
          } else {
            this.toast.error('CPF/CNPJ inválido!', 'CPF/CNPJ inválido, verificar dados inseridos', {
              positionClass: 'toast-bottom-right'
            });
          }
        }

        if (unmasked.length <= 11) {
          Ember.set(this, 'dataItem.cpf', masked);
          Ember.set(this, 'dataItem.cnpj', '');
          Ember.set(this, 'dataItem.razaoSocial', '');
          Ember.set(this, 'dataItem.inscricaoEstadual', '');
        } else {
          Ember.set(this, 'dataItem.cnpj', masked);
          Ember.set(this, 'dataItem.cpf', '');
        }
      },

      updatePhoneTech(type, unmasked, masked) {
        if (type == 1) {
          Ember.set(this, 'dataItem.technicalContact.phone1', masked);
        } else {
          Ember.set(this, 'dataItem.technicalContact.phone2', masked);
        }
      },

      updatePhone(type, unmasked, masked) {
        if (type == 1) {
          Ember.set(this, 'dataItem.phone1', masked);
        } else {
          Ember.set(this, 'dataItem.phone2', masked);
        }
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });

        if (!dateFlat) {
          return;
        }

        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = year + "-" + month + "-" + day + "T11:00";
        let d = this.datePeriodo;

        if (d.length == 0) {
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        } else if (d.length == 1) {
          let st = year + "-" + month + "-" + day + "T11:00";
          d.push(st);
          Ember.set(this, 'cStrDateEnd', st);
        } else if (d.length == 2) {
          d = [];
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
          Ember.set(this, 'msgPeriodoFatura', '');
        }

        if (d.length == 2) {
          let vfm = d[0].split("-");
          let vfnm = d[1].split("-");
          let msg = vfm[1] == vfnm[1] ? "do mesmo mês" : "do mês seguinte";
          Ember.set(this, 'msgPeriodoFatura', msg);
          Ember.set(this, 'dataItem.invoiceConfig.period', d);
        }

        this.datePeriodo = d;
      },

      checkFlatPicker() {},

      setModePayInvoice(val) {
        this.set('model.item.invoiceConfig.invoiceModePay', val);
      },

      setUsePerfil(item) {
        Ember.set(this, 'model.defaultRole', item);
        Ember.set(this, 'dataItem.type', item.name);
        Ember.set(this, 'dataItem.roleId', item.id);
      }

    }
  });

  _exports.default = _default;
});