define("printpost/controllers/prices/edit", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataPriceSend: Ember.computed('model.PriceSend', function () {
      var model = this.get('model.dataPrecification').PriceSend;
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PriceSend;
    }),
    dataPriceTax: Ember.computed('model.PriceTax', function () {
      var model = this.get('model.dataPrecification').PriceTax;
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PriceTax;
    }),
    dataPriceColor: Ember.computed('model.PriceColor', function () {
      var model = this.get('model.dataPrecification').PriceColor;
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PriceColor;
    }),
    dataPriceColored: Ember.computed('model.dataPriceColored', function () {
      var model = this.get('model.dataPrecification').PriceColor;
      model = model.filter(x => x.colored == true);
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PriceColor;
    }),
    dataPriceBlackWhite: Ember.computed('model.PriceColor', function () {
      var model = this.get('model.dataPrecification').PriceColor;
      model = model.filter(x => x.colored == false);
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PriceColor;
    }),
    dataPriceFinish: Ember.computed('model.PriceFinish', function () {
      var model = this.get('model.dataPrecification').PriceFinish;
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PriceFinish;
    }),
    dataPriceFormat: Ember.computed('model.PriceFormat', function () {
      var model = this.get('model.dataPrecification').PriceFormat;
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PriceFormat;
    }),
    dataPricePackage: Ember.computed('model.PricePackage', function () {
      var model = this.get('model.dataPrecification').PricePackage;
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PricePackage;
    }),
    dataPriceSalesman: Ember.computed('model.PriceSalesman', function () {
      var model = this.get('model.dataPrecification').PriceSalesman;
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PriceSalesman;
    }),
    dataPriceVolume: Ember.computed('model.PriceVolume', function () {
      var model = this.get('model.dataPrecification').PriceVolume;
      let objReturn = this.populeObjSelect(model);
      return objReturn; // return model.PriceVolume;
    }),
    dataChannels: Ember.computed('model.dataChannels', function () {
      var model = this.get('model.dataChannels');
      let objReturn = this.populeObjSelect(model);
      return objReturn;
    }),
    dataListTypeTable: Ember.computed('model.dataTypeTables', function () {
      let list = this.get('model.dataTypeTables');
      return list;
    }),
    dataFormatOptions: Ember.computed('model.dataFormatOptions', function () {
      var model = this.get('model.dataFormatOptions');
      return model;
    }),
    deletePriceValueId: Ember.computed('deletePriceValueId', function () {
      var model = "0";
      return model;
    }),
    dataItemFormat: Ember.computed('dataItemFormat', function () {
      return {
        id: '',
        name: '',
        format: '',
        grammage: '',
        papper: ''
      };
    }),
    dataItem: Ember.computed('model.item', function () {
      var data = this.get('model.item');
      return data;
    }).property().volatile(),
    dataSimulator: Ember.computed('model.item.valuesCalculate', function () {
      var model = this.get('model.item.valuesCalculate');
      return model;
    }),
    rerenderOptions: Ember.computed('rerenderOptions', function () {
      return {
        formatOptions: true,
        selectsFielfs: true
      };
    }),
    reloadFilterItens: function () {
      let clearDataSimulator = {
        marginGain: '100,00',
        quantity: '1000',
        priceUnitySale: '0,00',
        priceSale: '0,00',
        priceCostUnity: '0,00',
        priceCost: '0,00',
        priceDiscountUnity: '0,00',
        priceDiscount: '0,00',
        priceLiquidUnity: '0,00',
        priceLiquid: '0,00',
        priceGraphUnity: '0,00',
        priceGraph: '0,00',
        volumeItens: []
      };
      let clearData = {
        id: Ember.get(this, 'model.item.id'),
        name: Ember.get(this, 'model.item.name'),
        active: true,
        isPrinted: Ember.get(this, 'model.item.isPrinted'),
        has: {
          tax: true,
          salesman: true,
          volume: true
        },
        order: 0,
        description: '',
        formatOptions: {
          format: [],
          papper: [],
          grammage: [],
          blackWhite: false,
          colored: false
        },
        sendOptions: [],
        printSimulator: {
          format: '',
          grammage: '',
          papper: '',
          colored: false
        },
        color: {
          priceColor: '0',
          priceBlackWhite: '0',
          priceColorId: '0',
          priceBlackWhiteId: '0'
        },
        pvItem: null,
        praticatePrices: {
          priceSend: '',
          priceColor: '',
          priceFinish: '',
          priceFormat: '',
          pricePackage: '',
          priceSalesman: '',
          priceTax: '',
          priceVolumeGroup: '',
          volume: '',
          priceSale: ''
        },
        channelId: Ember.get(this, 'model.item.channelId'),
        pricingTableType: Ember.get(this, 'model.item.pricingTableType'),
        priceSendId: "0",
        priceFinishId: "0",
        pricePackageId: "0",
        priceSalesmanId: "0",
        priceTaxId: "0",
        priceVolumeGroupId: "0",
        priceFormatId: "0",
        priceColorId: "0",
        colored: false,
        priceValues: Ember.get(this, 'model.item.priceValues')
      };
      var clearPvItem = {
        format: "",
        send: "",
        color: "",
        id: ""
      };
      Ember.set(this, 'dataSimulator', clearDataSimulator);
      Ember.set(this, 'model.item', clearData);
      Ember.set(this, 'model.pvItem', clearPvItem);
    },
    populeObjSelect: function (data) {
      let objReturn = [];
      let item01 = {
        "id": "0",
        "selectText": "Selecione",
        "selectId": "0",
        "selectValue": "0"
      };
      objReturn.push(item01);
      data.forEach(element => {
        var rand = Math.random() * 9999;
        let item = {
          "id": element.id,
          "selectText": element.name,
          "selectId": rand,
          "selectValue": element.id
        };

        if (!element.icon && (element.value || element.percentage)) {
          let str = element.name; //let v = this.baseapi.realStringConvertDecimal(element.value);

          let strc = element.value != null && element.value.length > 0 ? "R$ " + element.value : element.percentage + "%";
          item.selectText = str + ' - ' + strc;
        }

        if (element.provider) item.selectText += ` (${element.provider.name})`;
        objReturn.push(item);
      });
      return objReturn;
    },
    loadItensProduct: function () {
      let data = Ember.get(this, 'model.item');

      if (data.channelId != "0") {
        let dataSend = this.get('model.dataPrecification').PriceSend;
        let dataVolume = this.get('model.dataPrecification').PriceVolume;
        let dataSalesman = this.get('model.dataPrecification').PriceSalesman;
        let send = dataSend.filter(x => x.channelId == data.channelId);
        let volume = dataVolume.filter(x => x.channelId == data.channelId);
        let salesman = dataSalesman.filter(x => x.channelId == data.channelId || x.channelId == "0");
        let obj_send = this.populeObjSelect(send);
        let obj_volume = this.populeObjSelect(volume);
        let obj_salesman = this.populeObjSelect(salesman); // obj_send.unshift(ini);
        // obj_volume.unshift(ini);
        // obj_salesman.unshift(ini);

        Ember.set(this, 'dataPriceSend', obj_send);
        Ember.set(this, 'dataPriceVolume', obj_volume);
        Ember.set(this, 'dataPriceSalesman', obj_salesman);
      }
    },
    getFormatOptions: function (type, val) {
      let model = this.get('model.dataFormatOptions');
      let item = model[type].find(x => x.value == val);
      return item;
    },
    actions: {
      setProductidSelect(val) {
        let id = val.id;
        let channel = this.get('model.dataChannels').find(x => x.id == id);
        Ember.set(this, 'model.selectProduct', val);

        if (id != "0") {
          Ember.set(this, 'model.item.channelId', id);
          Ember.set(this, 'model.item.isPrinted', channel.isPrinted);
          this.reloadFilterItens();
          this.loadItensProduct();
        }
      },

      setPricinTableType(val) {
        // let id = this.baseapi.getFieldSelectValue("typetable_selection");
        Ember.set(this, 'model.selectPricingTableType', val);

        if (val.selectId != "0") {
          Ember.set(this, 'model.item.pricingTableType', val.selectId);
        }
      },

      setSendOption: function (item) {
        let data = Ember.get(this, 'dataItem').sendOptions;
        let v = data.find(x => x == item);

        if (!v) {
          data.push(item);
        } else {
          let nd = [];
          data.forEach(r => {
            if (r.id != v.id) {
              nd.push(r);
            }
          }); // set(data, 'format', nd);

          Ember.set(this, 'model.item.sendOptions', nd);
        }

        Ember.set(this, 'rerenderOptions.formatOptions', false);
        setTimeout(() => {
          Ember.set(this, 'rerenderOptions.formatOptions', true);
        }, 500);
      },
      setHas: function (option, item) {
        let str = 'model.item.has.' + option;

        if (!item) {
          let stritem = "";
          let strPraticate = "";

          if (option == "volume") {
            stritem = 'model.item.priceVolumeGroupId';
            strPraticate = 'model.item.praticatePrices.priceVolumeGroup';
            Ember.set(this, 'dataSimulator.volumeItens', []);
          } else if (option == "tax") {
            stritem = 'model.item.priceTaxId';
            strPraticate = 'model.item.praticatePrices.priceTax';
          } else if (option == "salesman") {
            stritem = 'model.item.priceSalesmanId';
            strPraticate = 'model.item.praticatePrices.priceSalesman';
          }

          Ember.set(this, stritem, '0');
          Ember.set(this, strPraticate, "0");
          this.send('simulatorVolumeItens');
        }

        Ember.set(this, str, item);
      },
      setBlackWhite: function (item) {
        Ember.set(this, 'model.item.formatOptions.blackWhite', item);
      },
      setColored: function (item) {
        Ember.set(this, 'model.item.formatOptions.colored', item);
      },
      setAdditionalSend: function (option) {
        const priceSend = this.get('model.selectDataPriceSend');
        const modelPriceSend = this.get('model.dataPrecification').PriceSend;
        let salesAdditionSend = 0;
        let salesPrice = 0;

        for (let i = 0; i < option.length; i++) {
          const item = option[i];
          let hasItem = modelPriceSend.find(x => x.id == item.id);

          if (hasItem && (hasItem.percentage || hasItem.value)) {
            salesAdditionSend += this.baseapi.realStringConvertDecimal(hasItem.value, 4);
          }
        }

        let hasItem = modelPriceSend.find(x => x.id == priceSend.id);

        if (hasItem && (hasItem.percentage || hasItem.value)) {
          salesPrice += this.baseapi.realStringConvertDecimal(hasItem.value, 4);
        }

        Ember.set(this, 'model.item.praticatePrices.priceSend', this.baseapi.numberToReal(salesPrice + salesAdditionSend, 4));
        Ember.set(this, 'model.selectAdditionalSend', option);
        this.send('calculateSimulator');
      },
      pushSalesManId: function (option) {
        let salesmanPrice = 0;
        var model = this.get('model.dataPrecification').PriceSalesman;

        for (let i = 0; i < option.length; i++) {
          const item = option[i];
          let hasItem = model.find(x => x.id == item.id);

          if (hasItem && hasItem.percentage) {
            salesmanPrice += this.baseapi.realStringConvertDecimal(hasItem.percentage, 4);
          }
        }

        Ember.set(this, 'model.item.praticatePrices.priceSalesman', this.baseapi.numberToReal(salesmanPrice, 4));
        Ember.set(this, 'model.selectDataPriceSalesman', option);
        this.send('calculateSimulator');
      },
      setDataItemPrice: function (option, select) {
        // let id = this.baseapi.getFieldSelectValue(select);
        let id = select.id;
        let strmodel = (0, _globalFunctions.capitalize)(option);
        let stritem = 'model.item.' + option + 'Id';
        let strPraticate = 'model.item.praticatePrices.' + option;
        var model = this.get('model.dataPrecification')[strmodel];

        if (id != "0") {
          Ember.set(this, stritem, id);
          let item = model.find(x => x.id == id);

          if (option == "priceSend") {
            this.set('model.pvItem.send', item.name);
          } // let v = this.baseapi.realStringConvertDecimal(item.value);


          let value = item.value != null && item.value.length > 0 ? item.value : item.percentage;
          Ember.set(this, strPraticate, value);
        }

        let selectName = `model.selectData${strmodel}`;
        Ember.set(this, selectName, select);
        this.send('calculateSimulator');
      },
      setDataItemFormat: function (option, select) {
        // let id = this.baseapi.getFieldSelectValue(select);
        // set(this, 'model.selectDataPriceSend', select)
        let id = select.id;
        let strmodel = (0, _globalFunctions.capitalize)(option);
        let stritem = 'model.item.' + option + 'Id';
        let strPraticate = 'model.item.praticatePrices.' + option;
        var model = this.get('model.dataPrecification')[strmodel];

        if (id != "0") {
          Ember.set(this, stritem, id);
          let item = model.find(x => x.id == id); // let v = this.baseapi.realStringConvertDecimal(item.value);

          let value = item.value != null && item.value.length > 0 ? item.value : item.percentage;
          let formatOption = this.getFormatOptions("formate", item.format);
          let grammageOption = this.getFormatOptions("grammage", item.grammage);
          let papperOption = this.getFormatOptions("papper", item.papper);
          this.set('model.pvItem.format', item.name);
          let update = {
            format: [formatOption],
            papper: [papperOption],
            grammage: [grammageOption],
            blackWhite: Ember.get(this, 'model.item.formatOptions.blackWhite'),
            colored: Ember.get(this, 'model.item.formatOptions.colored')
          };
          Ember.set(this, 'model.item.formatOptions', update);
          Ember.set(this, strPraticate, value);
          let selectName = `model.selectData${strmodel}`;
          Ember.set(this, selectName, select);
        }

        this.send('calculateSimulator');
      },

      setVolumeGroupidSelect(val) {
        // let id = this.baseapi.getFieldSelectValue("pvolume_selection");
        Ember.set(this, 'model.selectDataPriceVolume', val);
        let id = val.id;
        let model = this.get('model.dataPrecification').PriceVolume;
        let item = model.find(x => x.id == id);

        if (id != "0" && Ember.get(this, 'model.item.has.volume')) {
          Ember.set(this, 'model.item.priceVolumeGroupId', id);
          Ember.set(this, 'dataSimulator.volumeItens', item.volumeItens);
          this.send('simulatorVolumeItens');
        }
      },

      setColorColoredIdSelect(val) {
        // let id = this.baseapi.getFieldSelectValue("pcolored_selection");
        let id = val.id;

        if (id != "0") {
          var model = this.get('model.dataPrecification').PriceColor;
          let item = model.find(x => x.id == id); // let v = this.baseapi.realStringConvertDecimal(item.value);

          let value = item.value != null && item.value.length > 0 ? item.value : item.percentage;
          Ember.set(this, 'model.item.color.priceColor', value);
          Ember.set(this, 'model.item.color.priceColorId', id);
          Ember.set(this, 'model.selectDataPriceColored', val);
        }
      },

      setColorBlackWhiteIdSelect(val) {
        // let id = this.baseapi.getFieldSelectValue("pblackwhite_selection");
        let id = val.id;

        if (id != "0") {
          var model = this.get('model.dataPrecification').PriceColor;
          let item = model.find(x => x.id == id); // let v = this.baseapi.realStringConvertDecimal(item.value);

          let value = item.value != null && item.value.length > 0 ? item.value : item.percentage;
          Ember.set(this, 'model.item.color.priceBlackWhite', value);
          Ember.set(this, 'model.item.color.priceBlackWhiteId', id);
          Ember.set(this, 'model.selectDataPriceBlackWhite', val);
        }
      },

      setColorSimulator: function (value) {
        Ember.set(this, 'model.item.printSimulator.colored', value);
        Ember.set(this, 'model.item.colored', value);
        let c = Ember.get(this, 'model.item.color');

        if (value) {
          this.set('model.pvItem.color', "Colorido");
          Ember.set(this, 'model.item.praticatePrices.priceColor', c.priceColor);
          Ember.set(this, 'model.item.priceColorId', c.priceColorId);
        } else {
          this.set('model.pvItem.color', "Preto e Branco");
          Ember.set(this, 'model.item.praticatePrices.priceColor', c.priceBlackWhite);
          Ember.set(this, 'model.item.priceColorId', c.priceBlackWhiteId);
        }

        this.send('calculateSimulator');
      },
      updatePercentageGain: function (itemValue, itemFormat) {
        //margem
        let marginGainStr = itemFormat;
        let marginGainDecimal = this.baseapi.realStringConvertDecimal(marginGainStr);
        let priceCost = this.baseapi.realStringConvertDecimal(Ember.get(this, 'dataSimulator.priceCost'));
        let priceUnity = this.baseapi.realStringConvertDecimal(Ember.get(this, 'dataSimulator.priceCost'));
        let x = priceCost * marginGainDecimal / 100 + priceUnity;
        let xStr = this.baseapi.numberToReal(x, 3);
        Ember.set(this, 'dataSimulator.marginGain', itemFormat);
        Ember.set(this, 'dataSimulator.priceUnitySale', xStr); // this.send('calculateSimulator');
      },
      updatePriceSale: function (itemValue, itemFormat) {
        let d = Ember.get(this, 'dataSimulator');
        let valDecimal = this.baseapi.realStringConvertDecimal(d.priceCost);
        let itemDecimal = this.baseapi.realStringConvertDecimal(itemValue);
        Ember.set(this, 'dataSimulator.priceUnitySale', itemValue);
        this.send('calculateSimulator');
      },
      updateQuantity: function (qq) {
        let val = Ember.$("#simulator_quantity").val();
        Ember.set(this, 'dataSimulator.quantity', val);
        this.send('simulatorVolumeItens');
      },
      simulatorVolumeItens: function () {
        let data = Ember.get(this, 'dataSimulator');

        if (data.volumeItens.length > 0) {
          let v = data.volumeItens.find(x => x.min <= data.quantity && data.quantity <= x.max);

          if (v) {
            Ember.set(this, 'model.item.praticatePrices.priceVolumeGroup', v.percentage);
          } else {
            Ember.set(this, 'model.item.praticatePrices.priceVolumeGroup', '0');
          }
        }

        this.send('calculateSimulator');
      },
      calculateSimulator: function () {
        let d = Ember.get(this, 'model.item');
        let data = {
          priceSend: this.baseapi.realStringConvertDecimal(d.praticatePrices.priceSend),
          priceColor: this.baseapi.realStringConvertDecimal(d.praticatePrices.priceColor),
          priceFinish: this.baseapi.realStringConvertDecimal(d.praticatePrices.priceFinish),
          priceFormat: this.baseapi.realStringConvertDecimal(d.praticatePrices.priceFormat),
          pricePackage: this.baseapi.realStringConvertDecimal(d.praticatePrices.pricePackage),
          priceSalesman: this.baseapi.realStringConvertDecimal(d.praticatePrices.priceSalesman),
          priceTax: this.baseapi.realStringConvertDecimal(d.praticatePrices.priceTax),
          priceVolumeGroup: this.baseapi.realStringConvertDecimal(d.praticatePrices.priceVolumeGroup),
          volume: this.baseapi.realStringConvertDecimal(d.praticatePrices.volume)
        }; // Calcular com variação de formato.
        // if(d.printSimulator.format.length > 0 && d.printSimulator.grammage.length > 0 && d.printSimulator.papper.length > 0){
        //     let model = this.get('model.dataPrecification').PriceFormat;
        //     let dpf = model.find(x => x.format == d.printSimulator.format && x.grammage == d.printSimulator.grammage && x.papper == d.printSimulator.papper);
        //     if(dpf){
        //         set(this, 'model.item.praticatePrices.priceFormat', dpf.value);
        //         data.priceFormat = this.baseapi.realStringConvertDecimal(dpf.value);
        //     }
        // }
        //preço venda unitario            

        let priceSaleUnityDecimal = this.baseapi.realStringConvertDecimal(Ember.get(this, 'dataSimulator.priceUnitySale')); //priceSaleUnityDecimal = (priceSaleUnityDecimal == 0) ? priceCostDecimal02 : priceSaleUnityDecimal;
        //preco de envio unit

        let priceCostSendUnit = data.priceColor + data.priceFormat + data.priceSend + data.priceFinish + data.pricePackage; //preco da grafica unit

        let priceGraphicUnit = data.priceColor + data.priceFormat + data.priceFinish + data.pricePackage;
        let priceGraphic = this.baseapi.realStringConvertDecimal(this.baseapi.numberToReal(priceGraphicUnit, 4)) * Ember.get(this, 'dataSimulator.quantity'); //preco de envio selecionado

        let priceSendUnit = data.priceSend;
        let priceSend = data.priceSend * Ember.get(this, 'dataSimulator.quantity'); //preco imposto unitario

        let priceTaxUnit = (priceSaleUnityDecimal - priceSendUnit) * (data.priceTax / 100);
        let priceTax = this.baseapi.realStringConvertDecimal(this.baseapi.numberToReal(priceTaxUnit, 4)) * Ember.get(this, 'dataSimulator.quantity'); //preco vendedor unit

        let priceSalesmanUnit = (priceSaleUnityDecimal - priceCostSendUnit - priceTaxUnit) * (data.priceSalesman / 100);
        let priceSalesman = this.baseapi.realStringConvertDecimal(this.baseapi.numberToReal(priceSalesmanUnit, 4)) * Ember.get(this, 'dataSimulator.quantity'); //desconto volumetria unitario

        let priceVolumeUnit = priceSaleUnityDecimal * (data.priceVolumeGroup / 100); //preco custo bruto unitario

        let priceCostDecimalUnity = priceVolumeUnit + priceCostSendUnit + priceSalesmanUnit + priceTaxUnit; // * get(this, 'dataSimulator.quantity');

        let priceCostStrUnity = this.baseapi.numberToReal(priceCostDecimalUnity, 4); //preco liquido (marg. liquida) unitario

        let priceLiquidDecimalUnity = priceSaleUnityDecimal - priceCostDecimalUnity;
        let priceLiquidStrUnity = this.baseapi.numberToReal(priceLiquidDecimalUnity, 4); //preco liquido (marg. liquida) total

        let priceLiquidDecimal = (priceSaleUnityDecimal - priceCostDecimalUnity) * Ember.get(this, 'dataSimulator.quantity');
        let priceLiquidStr = this.baseapi.numberToReal(priceLiquidDecimal, 4); //preco custo bruto total

        let priceCostDecimal = priceCostSendUnit + priceSalesmanUnit + priceTaxUnit;
        let priceCostStr = this.baseapi.realStringConvertDecimal(this.baseapi.numberToReal(priceCostDecimal, 4)) * Ember.get(this, 'dataSimulator.quantity');
        priceCostStr = this.baseapi.numberToReal(priceCostStr, 4); //preço disconto total

        let priceDiscountDecimal = priceVolumeUnit * Ember.get(this, 'dataSimulator.quantity');
        let priceDiscountStr = this.baseapi.numberToReal(priceDiscountDecimal, 4); //preco venda total            

        let priceSaleDecimal = priceSaleUnityDecimal * Ember.get(this, 'dataSimulator.quantity'); //((priceSaleUnityDecimal * ( marginGainDecimal / 100)) + priceSaleUnityDecimal); // * get(this, 'dataSimulator.quantity') ;

        let priceSaleStr = this.baseapi.numberToReal(priceSaleDecimal, 4); //margem

        let marginGainStr = Ember.get(this, 'dataSimulator.marginGain');
        let marginGainDecimal = this.baseapi.realStringConvertDecimal(marginGainStr);
        marginGainDecimal = priceLiquidDecimalUnity * 100 / priceCostDecimalUnity;
        marginGainStr = this.baseapi.numberToReal(marginGainDecimal, 4) + "";
        marginGainStr = this.baseapi.padStr(marginGainStr, 6, "0"); //marginGainStr = marginGainDecimal <= 0 ? "000,00" : this.baseapi.padStr(marginGainStr, 6, "0");

        Ember.set(this, 'model.item.praticatePrices.priceSale', this.baseapi.numberToReal(priceSaleUnityDecimal, 4));
        Ember.set(this, 'dataSimulator.priceSale', priceSaleStr);
        Ember.set(this, 'dataSimulator.marginGain', marginGainStr);
        Ember.set(this, 'dataSimulator.priceDiscount', priceDiscountStr);
        Ember.set(this, 'dataSimulator.priceDiscountUnity', this.baseapi.numberToReal(priceVolumeUnit, 4));
        Ember.set(this, 'dataSimulator.priceCost', priceCostStr);
        Ember.set(this, 'dataSimulator.priceCostUnity', priceCostStrUnity);
        Ember.set(this, 'dataSimulator.priceLiquid', priceLiquidStr);
        Ember.set(this, 'dataSimulator.priceLiquidUnity', priceLiquidStrUnity);
        Ember.set(this, 'dataSimulator.priceGraph', this.baseapi.numberToReal(priceGraphic, 4));
        Ember.set(this, 'dataSimulator.priceGraphUnity', this.baseapi.numberToReal(priceGraphicUnit, 4));
        Ember.set(this, 'dataSimulator.priceSend', this.baseapi.numberToReal(priceSend, 4));
        Ember.set(this, 'dataSimulator.priceSendUnity', this.baseapi.numberToReal(priceSendUnit, 4));
        Ember.set(this, 'dataSimulator.priceTax', this.baseapi.numberToReal(priceTax, 4));
        Ember.set(this, 'dataSimulator.priceTaxUnity', this.baseapi.numberToReal(priceTaxUnit, 4));
        Ember.set(this, 'dataSimulator.priceSalesman', this.baseapi.numberToReal(priceSalesman, 4));
        Ember.set(this, 'dataSimulator.priceSalesmanUnity', this.baseapi.numberToReal(priceSalesmanUnit, 4)); // log('dataItem', get(this, 'dataItem'));
        // log('dataSimulator', get(this, 'dataSimulator'));
        // console.log('priceSaleUnityDecimal (%s), priceTaxUnit (%s), priceCostSendUnit (%s), priceSalesmanUnit (%s), priceVolumeUnit(%s), priceCostDecimalUnity (%s), priceLiquidDecimalUnity(%s)', priceSaleUnityDecimal, priceTaxUnit, priceCostSendUnit, priceSalesmanUnit, priceVolumeUnit, priceCostDecimalUnity, priceLiquidDecimalUnity);
      },
      setFormatSimulator: function (id, type) {
        let stritem = 'model.item.printSimulator.' + type;

        if (id != "0") {
          Ember.set(this, stritem, id);
        }

        this.send('calculateSimulator');
      },
      salvar: function () {
        let item_data = Ember.get(this, 'model.item');
        let item_data_salesman = Ember.get(this, 'model.selectDataPriceSalesman');

        if (item_data_salesman && item_data_salesman.length) {
          item_data_salesman = item_data_salesman.map(x => x.id);
        }

        let item_data_additional_send = Ember.get(this, 'model.selectAdditionalSend');

        if (item_data_additional_send && item_data_additional_send.length) {
          item_data_additional_send = item_data_additional_send.map(x => x.id);
        }

        let item_data_calculate = Ember.get(this, 'dataSimulator');
        let item = {
          id: item_data.id,
          name: item_data.name,
          active: item_data.active,
          description: item_data.description,
          isPrinted: item_data.isPrinted,
          pricingTableType: item_data.pricingTableType,
          channelId: item_data.channelId,
          channelValue: item_data.channelId,
          formatOptions: item_data.formatOptions,
          order: item_data.order,
          color: item_data.color,
          printSimulator: item_data.printSimulator,
          praticatePrices: item_data.praticatePrices,
          has: item_data.has,
          priceSendId: item_data.priceSendId,
          priceFinishId: item_data.priceFinishId,
          pricePackageId: item_data.pricePackageId,
          priceSalesmanId: item_data_salesman,
          priceAdditionalSendId: item_data_additional_send,
          priceTaxId: item_data.priceTaxId,
          priceVolumeGroupId: item_data.priceVolumeGroupId,
          valuesCalculate: item_data_calculate,
          priceColorId: item_data.priceColorId,
          priceFormatId: item_data.priceFormatId,
          colored: item_data.colored,
          pvItem: this.get('model.pvItem')
        };
        item.pvItem.name = this.get('model.itemOptionName');
        let _object = {
          item: item
        };
        let psale = this.baseapi.realStringConvertDecimal(item.praticatePrices.priceSale);

        if (!item.name) {
          this.toast.error('Campos pendetes!', 'Nome não informado', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (item.channelId == "0") {
          this.toast.error('Campos pendetes!', 'Canal não informado', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (item.pricingTableType == "0") {
          this.toast.error('Campos pendetes!', 'Tipo da tabela não informado', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (item.priceSendId == "0") {
          this.toast.error('Campos pendetes!', 'Envio não informado', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (item.has.volume && item.priceVolumeGroupId == "0") {
          this.toast.error('Campos pendetes!', 'Volumetria não informado', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (item.has.salesman && item.priceSalesmanId == "0") {
          this.toast.error('Campos pendetes!', 'Vendedor não informado', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (item.has.tax && item.priceTaxId == "0") {
          this.toast.error('Campos pendetes!', 'Imposto não informado', {
            positionClass: 'toast-bottom-right'
          });
          return;
        } // apenas em carta


        let channel = this.get('model.dataChannels').find(x => x.id == item.channelId);
        item.channelValue = channel.value;
        let strInfoCarta = "";

        if (channel.value.toLowerCase() == "carta") {
          strInfoCarta = "Revise as opções do formato, envio e cor";

          if (item.priceFinishId == "0") {
            this.toast.error('Campos pendetes!', 'Acabamento não informado', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          if (item.pricePackageId == "0") {
            this.toast.error('Campos pendetes!', 'Embalagem não informado', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          if (item.priceFormatId == "0") {
            this.toast.error('Campos pendetes!', 'Formato não informado', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          if (item.priceColorId == "0") {
            this.toast.error('Campos pendetes!', 'Valor de cores/preto e branco não informado', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }
        }

        if (psale <= 0) {
          this.toast.error('Campos pendetes!', 'Valor unitário não informado', {
            positionClass: 'toast-bottom-right'
          });
          return;
        } // não validar o nome mais
        // if(item_data.priceValues.length > 0){
        //     let v = `${item.pvItem.format} - ${item.pvItem.color}`;
        //     let s = `${item.pvItem.send}`;
        //     let hasItem = item_data.priceValues.find(x => x.strFormat == v && x.strSend == s);
        //     if(hasItem && hasItem.id != item.pvItem.id){
        //         this.toast.error('Valor existente!', `Já existe um valor informado para essa combinação de custos. ${strInfoCarta}`, { positionClass: 'toast-bottom-right' });
        //         return;
        //     }
        // }


        let url = this.baseapi.apiLink('PricingTables');
        let call = this.baseapi.post(`${url}/cadastrar`, _object);
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');

          if (resp.data.errors) {
            this.toast.error('Item Não cadastrado!', resp.data.errors[0].detail, {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          ;
          this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
            positionClass: 'toast-bottom-right'
          }); // this.reloadFilterItens();

          this.transitionToRoute('prices.list'); // window.location.href = "list";
        }, error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('Item Não cadastrado!', `Dados não foram cadastrados!`, {
            positionClass: 'toast-bottom-right'
          });
          console.log(error);
        });
      },
      apagar: function () {
        let item_data = Ember.get(this, 'model.item');

        if (item_data.id) {
          let url = this.baseapi.apiLink('PricingTables');
          let call = this.baseapi.delete(url + '/' + item_data.id);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('prices.list');
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },
      novoCadastrado: function () {
        this.reloadFilterItens();
        this.set('model.mostrarCadastrar', true);
      },
      setDeletePriceValue: function (id) {
        Ember.set(this, 'deletePriceValueId', id);
      },
      deletePriceValue: function () {
        let id = Ember.get(this, 'deletePriceValueId');
        let item_data = Ember.get(this, 'model.item.priceValues').find(x => x.id == id);

        if (item_data.id) {
          let url = this.baseapi.apiLink('PricingTables');
          let call = this.baseapi.delete(url + '/product-item/' + item_data.id);
          this.preloader.toggleClass('d-none');
          call.then(resp => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('prices.list');
          }, error => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
            console.log(error);
          });
        }
      },
      editPriceValue: function (id) {
        let item_data = this.get('model.item').priceValues.find(x => x.id == id); // console.log('price value', item_data);

        this.send("loadSelectedItens", item_data);
        this.loadItensProduct(); //set(this, 'model.item.pvItem.id', id);

        this.set('model.pvItem', item_data.pvItem);
        this.set('model.pvItem.id', id);
        Ember.set(this, 'model.item.pvItem', item_data.pvItem);
        Ember.set(this, 'model.item.pvItem.id', id);
        Ember.set(this, 'model.item.formatOptions', item_data.formatOptions);
        Ember.set(this, 'model.item.order', item_data.order);
        Ember.set(this, 'model.item.color', item_data.color); // set(this, 'model.item.printSimulator', item_data.printSimulator);

        Ember.set(this, 'model.item.has', item_data.has);
        Ember.set(this, 'model.item.priceSendId', item_data.priceSendId);
        Ember.set(this, 'model.item.priceFinishId', item_data.priceFinishId);
        Ember.set(this, 'model.item.pricePackageId', item_data.pricePackageId);
        Ember.set(this, 'model.item.priceSalesmanId', item_data.priceSalesmanId);
        Ember.set(this, 'model.item.priceTaxId', item_data.priceTaxId);
        Ember.set(this, 'model.item.priceVolumeGroupId', item_data.priceVolumeGroupId);
        Ember.set(this, 'model.item.priceColorId', item_data.priceColorId);
        Ember.set(this, 'model.item.priceFormatId', item_data.priceFormatId);
        Ember.set(this, 'model.item.colored', item_data.colored);
        Ember.set(this, 'model.itemOptionName', item_data.name);
        this.set('model.mostrarCadastrar', true);
        Ember.set(this, 'rerenderOptions.formatOptions', false); // set(this, 'model.item.praticatePrices', item_data.praticatePrices);
        // set(this, 'model.item.valuesCalculate', item_data.valuesCalculate);
        // set(this, 'dataSimulator', item_data.valuesCalculate);

        setTimeout(() => {
          Ember.set(this, 'rerenderOptions.formatOptions', true);
        }, 500);
      },

      loadSelectedItens(priceValue) {
        // load send
        let selectDataPriceSend = this.get('dataPriceSend').find(x => x.id == priceValue.priceSendId);
        const modelPriceSend = this.get('model.dataPrecification').PriceSend;
        const hasModelPriceSend = modelPriceSend.find(x => x.id == priceValue.priceSendId);
        let salesPrice = 0;

        if (hasModelPriceSend && (hasModelPriceSend.value || hasModelPriceSend.percentage)) {
          salesPrice = hasModelPriceSend.percentage ? this.baseapi.realStringConvertDecimal(hasModelPriceSend.percentage, 4) : this.baseapi.realStringConvertDecimal(hasModelPriceSend.value, 4); // set(this, 'model.item.praticatePrices.priceSend', this.baseapi.numberToReal(salesPrice, 4));
        } // load volume data


        let selectDataPriceVolume = priceValue.has.volume ? this.get('dataPriceVolume').find(x => x.id == priceValue.priceVolumeGroupId) : null; // load additional send

        let selectDataAdditionalSend = [];
        let salesAdditionSend = 0;

        if (priceValue.priceAdditionalSendId && priceValue.priceAdditionalSendId.length) {
          this.get('dataPriceSend').forEach(dps => {
            if (priceValue.priceAdditionalSendId.includes(dps.id)) {
              selectDataAdditionalSend.push(dps);
              let hasModelItem = modelPriceSend.find(x => x.id == dps.id);
              salesAdditionSend += hasModelItem.percentage ? this.baseapi.realStringConvertDecimal(hasModelItem.percentage, 4) : this.baseapi.realStringConvertDecimal(hasModelItem.value, 4);
            }
          });
        }

        Ember.set(this, 'model.item.praticatePrices.priceSend', this.baseapi.numberToReal(salesPrice + salesAdditionSend, 4)); // load salesman

        let selectDataPriceSalesman = [];
        let salesmanPrice = 0;

        if (priceValue.has.salesman) {
          const model = this.get('model.dataPrecification').PriceSalesman;

          if (typeof priceValue.priceSalesmanId == 'string') {
            let hasItem = this.get('dataPriceSalesman').find(x => x.id == priceValue.priceSalesmanId);
            let hasModelItem = model.find(x => x.id == priceValue.priceSalesmanId);
            selectDataPriceSalesman = [hasItem];

            if (hasModelItem && hasModelItem.percentage) {
              salesmanPrice = this.baseapi.realStringConvertDecimal(hasModelItem.percentage, 4);
            }
          } else {
            this.get('dataPriceSalesman').forEach(dps => {
              if (priceValue.priceSalesmanId.length && priceValue.priceSalesmanId.includes(dps.id)) {
                selectDataPriceSalesman.push(dps);
                let hasModelItem = model.find(x => x.id == dps.id);

                if (hasModelItem && hasModelItem.percentage) {
                  salesmanPrice += this.baseapi.realStringConvertDecimal(hasModelItem.percentage, 4);
                }
              }
            });
          }

          Ember.set(this, 'model.item.praticatePrices.priceSalesman', this.baseapi.numberToReal(salesmanPrice, 4));
        } // load tax


        let selectDataPriceTax = priceValue.has.tax ? this.get('dataPriceTax').find(x => x.id == priceValue.priceTaxId) : null;
        const modelPriceTax = this.get('model.dataPrecification').PriceTax;
        const hasModelPriceTax = modelPriceTax.find(x => x.id == priceValue.priceTaxId);

        if (hasModelPriceTax && (hasModelPriceTax.value || hasModelPriceTax.percentage)) {
          let salesPrice = hasModelPriceTax.percentage ? this.baseapi.realStringConvertDecimal(hasModelPriceTax.percentage, 4) : this.baseapi.realStringConvertDecimal(hasModelPriceTax.value, 4);
          Ember.set(this, 'model.item.praticatePrices.priceTax', this.baseapi.numberToReal(salesPrice, 4));
        } // load price finish


        let selectDataPriceFinish = priceValue.channelValue == 'carta' ? this.get('dataPriceFinish').find(x => x.id == priceValue.priceFinishId) : null;
        const modelPriceFinish = this.get('model.dataPrecification').PriceFinish;
        const hasModelPriceFinish = modelPriceFinish.find(x => x.id == priceValue.priceFinishId);

        if (hasModelPriceFinish && (hasModelPriceFinish.value || hasModelPriceFinish.percentage)) {
          let salesPrice = hasModelPriceFinish.percentage ? this.baseapi.realStringConvertDecimal(hasModelPriceFinish.percentage, 4) : this.baseapi.realStringConvertDecimal(hasModelPriceFinish.value, 4);
          Ember.set(this, 'model.item.praticatePrices.priceFinsih', this.baseapi.numberToReal(salesPrice, 4));
        } // load price format


        let selectDataPriceFormat = priceValue.channelValue == 'carta' ? this.get('dataPriceFormat').find(x => x.id == priceValue.priceFormatId) : null;
        const modelPriceFormat = this.get('model.dataPrecification').PriceFormat;
        const hasModelPriceFormat = modelPriceFormat.find(x => x.id == priceValue.priceFormatId);

        if (hasModelPriceFormat && (hasModelPriceFormat.value || hasModelPriceFormat.percentage)) {
          let salesPrice = hasModelPriceFormat.percentage ? this.baseapi.realStringConvertDecimal(hasModelPriceFormat.percentage, 4) : this.baseapi.realStringConvertDecimal(hasModelPriceFormat.value, 4);
          Ember.set(this, 'model.item.praticatePrices.priceFormat', this.baseapi.numberToReal(salesPrice, 4));
        } // load price package


        let selectDataPricePackage = priceValue.channelValue == 'carta' ? this.get('dataPricePackage').find(x => x.id == priceValue.pricePackageId) : null;
        const modelPricePackage = this.get('model.dataPrecification').PricePackage;
        const hasModelPricePackage = modelPricePackage.find(x => x.id == priceValue.pricePackageId);

        if (hasModelPricePackage && (hasModelPricePackage.value || hasModelPricePackage.percentage)) {
          let salesPrice = hasModelPricePackage.percentage ? this.baseapi.realStringConvertDecimal(hasModelPricePackage.percentage, 4) : this.baseapi.realStringConvertDecimal(hasModelPricePackage.value, 4);
          Ember.set(this, 'model.item.praticatePrices.pricePackage', this.baseapi.numberToReal(salesPrice, 4));
        } // load price value


        let selectDataPriceBlackWhite = priceValue.channelValue == 'carta' && priceValue.formatOptions.blackWhite ? this.get('dataPriceBlackWhite').find(x => x.id == priceValue.priceColorId) : null;
        let selectDataPriceColored = priceValue.channelValue == 'carta' && priceValue.formatOptions.colored ? this.get('dataPriceColored').find(x => x.id == priceValue.priceColorId) : null;
        const modelPriceColor = this.get('model.dataPrecification').PriceColor;
        const hasModelPriceColor = modelPriceColor.find(x => x.id == priceValue.priceColorId);

        if (hasModelPriceColor && (hasModelPriceColor.value || hasModelPriceColor.percentage)) {
          let salesPrice = hasModelPriceColor.percentage ? this.baseapi.realStringConvertDecimal(hasModelPriceColor.percentage, 4) : this.baseapi.realStringConvertDecimal(hasModelPriceColor.value, 4);
          Ember.set(this, 'model.item.praticatePrices.priceColor', this.baseapi.numberToReal(salesPrice, 4));
        } // set price sale


        Ember.set(this, 'model.item.praticatePrices.priceSale', (priceValue.praticatePrices.priceSale, 4));
        Ember.set(this, 'model.item.praticatePrices.priceVolumeGroup', (priceValue.praticatePrices.priceVolumeGroup, 4)); // set data simulator

        Ember.set(this, 'dataSimulator.quantity', 1000);
        Ember.set(this, 'dataSimulator.priceUnitySale', priceValue.praticatePrices.priceSale);
        Ember.set(this, 'model.selectDataPriceSend', selectDataPriceSend);
        Ember.set(this, 'model.selectDataPriceVolume', selectDataPriceVolume);
        Ember.set(this, 'model.selectDataPriceSalesman', selectDataPriceSalesman);
        Ember.set(this, 'model.selectAdditionalSend', selectDataAdditionalSend);
        Ember.set(this, 'model.selectDataPriceTax', selectDataPriceTax);
        Ember.set(this, 'model.selectDataPriceFinish', selectDataPriceFinish);
        Ember.set(this, 'model.selectDataPriceFormat', selectDataPriceFormat);
        Ember.set(this, 'model.selectDataPricePackage', selectDataPricePackage);
        Ember.set(this, 'model.selectDataPriceBlackWhite', selectDataPriceBlackWhite);
        Ember.set(this, 'model.selectDataPriceColored', selectDataPriceColored);
        this.send('calculateSimulator');
      }

    }
  });

  _exports.default = _default;
});