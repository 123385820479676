define("printpost/controllers/carteiras/multicanal", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    dataUser: Ember.computed('dataUser', function () {
      // let d = this.baseapi.getCurrentUser();
      const d = this.get('session.data.authenticated');
      return d.account;
    }).property().volatile(),
    adminValue: Ember.computed('adminValue', function () {
      let s = this.baseapi.getAdmValue();
      return s;
    }),
    menusFilter: Ember.computed('menusFilter', function () {
      var itens = ['filter_mes', 'filter_ano', 'filter_inicio'];
      return itens;
    }),
    dataWallets: Ember.computed('model.dataWallets', function () {
      var model = this.get('model.dataWallets');
      return model;
    }),
    dataReturn: Ember.computed('model.dataReturn', function () {
      var model = this.get('model.dataReturn');
      return model;
    }),
    taskFilterReturn: (0, _emberConcurrency.task)(function* () {
      //type return
      // var url_typereturn = this.baseapi.apiLink('TypeReturns/list?filter[order]="channelName"');
      var dataIni = Ember.get(this, 'cStrDateBegin');
      var dataFim = Ember.get(this, 'cStrDateEnd');
      var walletId = Ember.get(this, 'cStrWalletId');
      var query = {
        filter: {
          where: {
            and: [{
              createdAt: {
                gt: new Date(dataIni)
              }
            }, {
              createdAt: {
                lt: new Date(dataFim)
              }
            }]
          }
        }
      };

      if (walletId.length > 0) {
        query.filter.where.walletId = walletId;
      }

      return;
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url_typereturn = this.baseapi.apiLink('RequestReturns/data-dashboard');
      var objDataReturn = {
        carta: [],
        cartaNotReceive: 0,
        cartaReceive: 0,
        cartaAll: 0,
        heightCarta: 230,
        email: [],
        emailNotReceive: 0,
        emailReceive: 0,
        emailAll: 0,
        heightEmail: 230,
        sms: [],
        smsNotReceive: 0,
        smsReceive: 0,
        smsAll: 0,
        heightSms: 230
      };
      this.baseapi.getHttp(url_typereturn + "?" + strquery).then(r => {
        var obj = r.data;

        if (obj) {
          obj.forEach((element, index) => {
            var par = index % 2 == 0 ? true : false;

            if (element['carta']) {
              objDataReturn.carta = element['carta'];
              let sumHeigh = 40 * objDataReturn.carta.length;
              objDataReturn.heightCarta += sumHeigh;
              objDataReturn.cartaAll += element['cartaAll'];
              objDataReturn.cartaNotReceive += element['cartaNotReceive'];
              objDataReturn.cartaReceive += element['cartaReceive'];
              objDataReturn.carta.forEach((odr_c, index_c) => {
                var par = index_c % 2 == 0 ? true : false;
                odr_c.isPar = par;
              });
            } else if (element['sms']) {
              objDataReturn.sms = element['sms'];
              let sumHeigh = 40 * objDataReturn.sms.length;
              objDataReturn.heightSms += sumHeigh;
              objDataReturn.smsAll += element['smsAll'];
              objDataReturn.smsNotReceive += element['smsNotReceive'];
              objDataReturn.smsReceive += element['smsReceive'];
              objDataReturn.sms.forEach((odr_c, index_c) => {
                var par = index_c % 2 == 0 ? true : false;
                odr_c.isPar = par;
              });
            } else if (element['email']) {
              objDataReturn.email = element['email'];
              let sumHeigh = 40 * objDataReturn.email.length;
              objDataReturn.heightEmail += sumHeigh;
              objDataReturn.emailAll += element['emailAll'];
              objDataReturn.emailNotReceive += element['emailNotReceive'];
              objDataReturn.emailReceive += element['emailReceive'];
              objDataReturn.email.forEach((odr_c, index_c) => {
                var par = index_c % 2 == 0 ? true : false;
                odr_c.isPar = par;
              });
            }
          });
          objDataReturn.heightCarta = String(objDataReturn.heightCarta) + 'px';
          objDataReturn.heightEmail = String(objDataReturn.heightEmail) + 'px';
          objDataReturn.heightSms = String(objDataReturn.heightSms) + 'px';
          this.set('model.dataReturn', objDataReturn);
        }
      });
    }).keepLatest(),
    datePeriodo: [],
    cStrDateBegin: Ember.computed('strDateBegin', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-01 00:00:00';
      return strDate;
    }),
    cStrDateEnd: Ember.computed('strDateEnd', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + ' 19:59:00';
      return strDate;
    }),
    cStrWalletId: Ember.computed('strWalletId', function () {
      return '';
    }),
    myFlatpickrRef: Ember.computed(function () {
      return null;
    }),
    randomScalingFactor: function (value = 100) {
      return Math.round(Math.random() * value);
    },
    dataGrafPie1: Ember.computed('dataGrafPie1', function () {
      let model = Ember.get(this, 'dataGrafPie');
      return model;
    }).property().volatile(),
    dataGrafPie2: Ember.computed('dataGrafPie2', function () {
      let model = Ember.get(this, 'dataGrafPie');
      return model;
    }).property().volatile(),
    dataGrafOptionsPie1: Ember.computed('dataGrafOptionsPie1', function () {
      let m = {
        digital: this.randomScalingFactor(),
        carta: this.randomScalingFactor(),
        retorno: this.randomScalingFactor(),
        total: 0
      };
      m.total = m.digital + m.carta + m.retorno;
      return m;
    }).property().volatile(),
    dataGrafOptionsPie2: Ember.computed('dataGrafOptionsPie', function () {
      let m = {
        digital: this.randomScalingFactor(),
        carta: this.randomScalingFactor(),
        retorno: this.randomScalingFactor(),
        total: 0
      };
      m.total = m.digital + m.carta + m.retorno;
      return m;
    }).property().volatile(),
    dataOptionsRandom: Ember.computed('dataOptionsRandom', function () {
      let m = {
        sms: this.randomScalingFactor(100000),
        carta: this.randomScalingFactor(100000),
        email: this.randomScalingFactor(100000),
        cartaRet: this.randomScalingFactor(1000),
        emailRet: this.randomScalingFactor(1000),
        smsRet: this.randomScalingFactor(1000),
        cartaNot: 0,
        emailNot: 0,
        smsNot: 0,
        notAll: 0,
        correiosAll: 0
      };
      m.cartaNot = m.carta - m.cartaRet;
      m.emailNot = m.email - m.emailRet;
      m.smsNot = m.sms - m.smsRet;
      m.notAll = m.smsRet + m.cartaRet + m.emailRet;
      m.correiosAll = m.cartaNot;
      return m;
    }).property().volatile(),
    dataGrafPie: Ember.computed('model.analises.p1', function () {
      var dataTotalAddress = this.randomScalingFactor();
      var dataTotalDig = this.randomScalingFactor();
      var dataNotRet = this.randomScalingFactor();
      let d = {
        "datasets": [{
          "label": "Desempenho",
          "fill": false,
          "data": [dataTotalDig, dataTotalAddress, dataNotRet],
          "backgroundColor": ["#50e2d4", "#b9e2f1", "#ff8da0"],
          "borderColor": ["#50e2d4", "#b9e2f1", "#ff8da0"],
          "borderWidth": 0
        }],
        "color": ["#50e2d4", "#b9e2f1", "#ff8da0"],
        "labels": ["Digital", "Cartas", "Não Entregues"]
      };
      return d;
    }),
    options: Ember.computed('model.analises.options', function () {
      return {
        "responsive": true,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#000"
          }
        },
        "scales": {
          "yAxes": [{
            "display": false,
            "ticks": {
              "beginAtZero": false
            },
            "gridLines": {
              "display": false
            }
          }],
          "xAxes": [{
            "display": false,
            "gridLines": {
              "display": false
            }
          }]
        }
      };
    }),
    actions: {
      actionFilterReturn: function (strfilter) {
        let listFilterMenus = Ember.get(this, 'menusFilter');
        let objDate = new Date();
        let strDateBegin = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-01 00:00:00';
        let strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + ' 19:59:00';
        listFilterMenus.forEach(item => {
          if (strfilter != item) {
            Ember.$('#' + item).addClass('text-lightgray');
          } else {
            Ember.$('#' + item).removeClass('text-lightgray');

            if (item == 'filter_ano') {
              strDateBegin = objDate.getFullYear() + '-01-01 00:00:00';
              strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + ' 19:59:00';
            } else if (item == 'filter_inicio') {
              strDateBegin = '2019-01-01 00:00:00';
              strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + ' 19:59:00';
            }
          }
        });
        Ember.get(this, 'myFlatpickrRef').clear();
        this.datePeriodo = [];
        Ember.set(this, 'cStrDateBegin', strDateBegin);
        Ember.set(this, 'cStrDateEnd', strDateEnd);
        Ember.get(this, 'taskFilterReturn').perform();
      },
      actionFilterWallet: function (id) {
        console.log(id);
        Ember.$('#list_wallets li').each((index, item) => {
          var lId = Ember.$(item).attr('data-id');

          if (id == lId) {
            Ember.$(item).removeClass('text-lightgray');
            Ember.set(this, 'cStrWalletId', id);
            Ember.get(this, 'taskFilterReturn').perform();
          } else {
            Ember.$(item).addClass('text-lightgray');
          }
        });
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });

        if (!dateFlat) {
          return;
        }

        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":00";
        let d = this.datePeriodo;

        if (d.length == 0) {
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        } else if (d.length == 1) {
          let st = year + "-" + month + "-" + day + " 22:59:00";
          d.push(st);
          Ember.set(this, 'cStrDateEnd', st);
          Ember.get(this, 'taskFilterReturn').perform();
        } else if (d.length == 2) {
          d = [];
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        }

        this.datePeriodo = d;
      }

    }
  });

  _exports.default = _default;
});