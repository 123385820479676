define("printpost/controllers/bkp--templates", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const textTinymce = Ember.Object.extend({
    dados: '',
    options: {
      selector: 'textarea',
      // change this value according to your html
      min_height: 1000,
      min_width: 500,
      body_class: 'tinymce_class',
      content_style: "div {margin: 10px; border: 5px solid red; padding: 3px}",
      menubar: false,
      //theme: 'modern', //content_style: "div {margin: 10px; border: 5px solid red; padding: 3px}", //
      plugins: 'code template textcolor',
      // note the comma at the end of the line!
      toolbar: 'undo redo | styleselect bold italic | alignleft aligncenter alignright bullist numlist outdent indent forecolor backcolor | cut copy paste selectall | code template ',
      templates: [{
        title: 'Some title 1',
        content: 'My content'
      }, {
        title: 'Some title 2',
        content: 'development.html'
      }],
      textcolor_map: ["000000", "Black", "993300", "Burnt orange", "333300", "Dark olive", "003300", "Dark green", "003366", "Dark azure", "000080", "Navy Blue", "333399", "Indigo", "333333", "Very dark gray", "800000", "Maroon", "FF6600", "Orange", "808000", "Olive", "008000", "Green", "008080", "Teal", "0000FF", "Blue", "666699", "Grayish blue", "808080", "Gray", "FF0000", "Red", "FF9900", "Amber", "99CC00", "Yellow green", "339966", "Sea green", "33CCCC", "Turquoise", "3366FF", "Royal blue", "800080", "Purple", "999999", "Medium gray", "FF00FF", "Magenta", "FFCC00", "Gold", "FFFF00", "Yellow", "00FF00", "Lime", "00FFFF", "Aqua", "00CCFF", "Sky blue", "993366", "Red violet", "FFFFFF", "White", "FF99CC", "Pink", "FFCC99", "Peach", "FFFF99", "Light yellow", "CCFFCC", "Pale green", "CCFFFF", "Pale cyan", "99CCFF", "Light sky blue", "CC99FF", "Plum"]
    }
  });

  var _default = Ember.Controller.extend({
    queryParams: ['type', 'channel', 'visualizing'],
    channel: '5c3f713ce1c9855370e3e02e',
    type: null,
    visualizing: 'base-template',
    textTinymce: textTinymce.create(),
    filterModels: Ember.computed('model.products', function () {
      return this.get('model.products');
    }),
    baseTemplates: Ember.computed('model.getTemplate', function () {
      return this.get('model.getTemplate');
    }),
    actions: {
      selectionOn: function () {
        function DropDown(el) {
          this.dd = el;
          this.placeholder = this.dd.children('span');
          this.opts = this.dd.find('.dropdown a');
          this.val = '';
          this.index = -1;
          this.initEvents();
        }

        DropDown.prototype = {
          initEvents: function () {
            var obj = this;
            obj.dd.on('click', function (event) {
              Ember.$(this).toggleClass('active');
              return false;
            });
            obj.opts.on('click', function () {
              var opt = Ember.$(this);
              obj.val = opt.text();
              obj.index = opt.index();
              obj.placeholder.text(obj.val);
            });
          },
          getValue: function () {
            return this.val;
          },
          getIndex: function () {
            return this.index;
          }
        };
        var dd = new DropDown(Ember.$('#dd'));
      },

      next() {
        function scroll_to_class(element_class, removed_height) {
          var scroll_to = Ember.$(element_class).offset().top - removed_height;

          if (Ember.$(window).scrollTop() != scroll_to) {
            Ember.$('html, body').stop().animate({
              scrollTop: scroll_to
            }, 0);
          }
        }

        function bar_progress(progress_line_object, direction) {
          var number_of_steps = progress_line_object.data('number-of-steps');
          var now_value = progress_line_object.data('now-value');
          var new_value = 0;

          if (direction == 'right') {
            new_value = now_value + 100 / number_of_steps;
          } else if (direction == 'left') {
            new_value = now_value - 100 / number_of_steps;
          }

          progress_line_object.attr('style', 'width: ' + new_value + '%;').data('now-value', new_value);
        }

        Ember.$('.f1 .btn-next').on('click', function () {
          var parent_fieldset = Ember.$(this).parents('fieldset');
          var next_step = true; // navigation steps / progress steps

          var current_active_step = Ember.$(this).parents('.f1').find('.f1-step.active');
          var progress_line = Ember.$(this).parents('.f1').find('.f1-progress-line'); // fields validation

          parent_fieldset.find('input[type="text"], input[type="password"], textarea').each(function () {
            if (Ember.$(this).val() == "") {
              Ember.$(this).addClass('input-error');
              next_step = false;
            } else {
              Ember.$(this).removeClass('input-error');
            }
          }); // fields validation

          if (next_step) {
            parent_fieldset.fadeOut(400, function () {
              // change icons
              current_active_step.removeClass('active').addClass('activated').next().addClass('active'); // progress bar

              bar_progress(progress_line, 'right'); // show next step

              Ember.$(this).next().fadeIn(); // scroll window to beginning of the form

              scroll_to_class(Ember.$('.f1'), 20);
            });
          }
        });
      },

      previous() {
        function scroll_to_class(element_class, removed_height) {
          var scroll_to = Ember.$(element_class).offset().top - removed_height;

          if (Ember.$(window).scrollTop() != scroll_to) {
            Ember.$('html, body').stop().animate({
              scrollTop: scroll_to
            }, 0);
          }
        }

        function bar_progress(progress_line_object, direction) {
          var number_of_steps = progress_line_object.data('number-of-steps');
          var now_value = progress_line_object.data('now-value');
          var new_value = 0;

          if (direction == 'right') {
            new_value = now_value + 100 / number_of_steps;
          } else if (direction == 'left') {
            new_value = now_value - 100 / number_of_steps;
          }

          progress_line_object.attr('style', 'width: ' + new_value + '%;').data('now-value', new_value);
        }

        Ember.$('.f1 .btn-previous').on('click', function () {
          // navigation steps / progress steps
          var current_active_step = Ember.$(this).parents('.f1').find('.f1-step.active');
          var progress_line = Ember.$(this).parents('.f1').find('.f1-progress-line');
          Ember.$(this).parents('fieldset').fadeOut(400, function () {
            // change icons
            current_active_step.removeClass('active').prev().removeClass('activated').addClass('active'); // progress bar

            bar_progress(progress_line, 'left'); // show previous step

            Ember.$(this).prev().fadeIn(); // scroll window to beginning of the form

            scroll_to_class(Ember.$('.f1'), 20);
          });
        });
      },

      previousEditTemplate() {
        console.log(this.textTinymce.dados);
        Ember.set(this, 'text', this.textTinymce.dados);

        function scroll_to_class(element_class, removed_height) {
          var scroll_to = Ember.$(element_class).offset().top - removed_height;

          if (Ember.$(window).scrollTop() != scroll_to) {
            Ember.$('html, body').stop().animate({
              scrollTop: scroll_to
            }, 0);
          }
        }

        function bar_progress(progress_line_object, direction) {
          var number_of_steps = progress_line_object.data('number-of-steps');
          var now_value = progress_line_object.data('now-value');
          var new_value = 0;

          if (direction == 'right') {
            new_value = now_value + 100 / number_of_steps;
          } else if (direction == 'left') {
            new_value = now_value - 100 / number_of_steps;
          }

          progress_line_object.attr('style', 'width: ' + new_value + '%;').data('now-value', new_value);
        }

        Ember.$('.f1 .btn-previous').on('click', function () {
          // navigation steps / progress steps
          var current_active_step = Ember.$(this).parents('.f1').find('.f1-step.active');
          var progress_line = Ember.$(this).parents('.f1').find('.f1-progress-line');
          Ember.$(this).parents('fieldset').fadeOut(400, function () {
            // change icons
            current_active_step.removeClass('active').prev().removeClass('activated').addClass('active'); // progress bar

            bar_progress(progress_line, 'left'); // show previous step

            Ember.$(this).prev().fadeIn(); // scroll window to beginning of the form

            scroll_to_class(Ember.$('.f1'), 20);
          });
        });
      },

      myOnChangedAction(value) {
        // Do something with the value.
        // At least the text should be updated:
        console.log(value);
        this.set('text', value);
      }

    }
  });

  _exports.default = _default;
});