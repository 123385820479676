define("printpost/helpers/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.date = date;
  _exports.default = void 0;

  function date(params
  /*, hash*/
  ) {
    function AddZero(num) {
      return num >= 0 && num < 10 ? "0" + num : num + "";
    }

    let str = params;
    let now = new Date();
    var strDateTime = [AddZero(now.getDate()), AddZero(now.getMonth() + 1), now.getFullYear()].join("/");

    if (Ember.isArray(params)) {
      str = params[0];
    }

    if (typeof str === 'undefined') {
      str = '';
    }

    switch (str) {
      case 'yyyy':
        strDateTime = now.getFullYear();
        break;

      case 'dd':
        strDateTime = AddZero(now.getDate());
        break;

      case 'MM':
        strDateTime = AddZero(now.getMonth() + 1);
        break;

      case 'ddMMyyyy':
        break;

      case 'yyyyMMdd':
        strDateTime = [now.getFullYear(), AddZero(now.getMonth() + 1), AddZero(now.getDate())].join("/");
        break;

      default:
        break;
    }

    return strDateTime;
  }

  var _default = Ember.Helper.helper(date);

  _exports.default = _default;
});