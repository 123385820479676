define("printpost/services/timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    interval: 5000,

    // Schedules function `f` to be executed on interval
    schedule(f, interval) {
      const time = interval || this.get('interval');
      return Ember.run.later(this, function () {
        f.apply(this);
        this.set('timer', this.schedule(f, time));
      }, time);
    },

    // Stop the timer
    stop() {
      Ember.run.cancel(this.get('timer'));
    },

    // Start the timer: execute callback every interval
    start(callback, interval) {
      this.set('timer', this.schedule(callback, interval));
    }

  });

  _exports.default = _default;
});