define("printpost/routes/cloud/images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      return this.store.query('cloud-image', {
        page: {
          number: params.page,
          size: params.size
        }
      });
    },
    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});