define("printpost/routes/whatsapp/numeros", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: async function () {
      var url = this.baseapi.apiLink('WhatsAppNumbers/list?filter[include]=account&filter[include]=organization');
      const obj = await this.baseapi.getHttp(url).then(r => r.data);
      var urlConfig = this.baseapi.apiLink('WhatsAppSessions/app-configuration');
      const configuration = await this.baseapi.getHttp(urlConfig).then(r => r.data);
      let itemConfiguration = configuration.length ? configuration[0] : {
        token: '',
        endpoint: ''
      };
      return Ember.RSVP.hash({
        data: obj,
        action: '0',
        qrcode: '',
        dataItem: {
          id: '',
          number: '',
          name: '',
          picture: '',
          profileSincronized: false
        },
        whatsappConfig: itemConfiguration,
        messageTest: {
          number: '',
          message: ''
        }
      });
    }
  });

  _exports.default = _default;
});