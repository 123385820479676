define("printpost/services/omnichannel-service", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    /**
     * Consulta de itens
     * @param {object} filter Object loopback filter para consultar
     * @param {boolean} pagination True para retornar os dados como paginação
     * @returns {any[]} Lista de itens omnichannel
     */
    list: async function (filter, pagination = true) {
      const base_url = this.baseapi.apiLink('OmniChannelPeople');
      const strquery = filter ? `&${(0, _globalFunctions.apiLinkQuery)(filter)}` : '';
      var url = !strquery ? `${base_url}/list?mp=${pagination}` : `${base_url}/list?mp=${pagination}${strquery}`;
      return await this.baseapi.getHttp(url).then(response => response.data);
    },

    /**
     * Filtros de status e data
     * @param {number} statusReturn Retorno para consulta (1 - Aguardando, 2 - Positivo, 3 - Negativo)
     * @param {object} dateFilter Object filter para consultar entre datas
     * @param {object} filter Object loopback filter para consultar
     * @returns {any[]} Lista de itens omnichannel
     */
    filter: async function (statusReturn, dateFilter = null, filter = null) {
      const base_url = this.baseapi.apiLink(`OmniChannelPeople/filter-return/${statusReturn}`);
      const strquery = filter ? `&${(0, _globalFunctions.apiLinkQuery)(filter)}` : '';
      var url = !strquery ? `${base_url}` : `${base_url}?${strquery}`;
      if (dateFilter && dateFilter.dateIni && dateFilter.dateEnd) url = `${url}&dateIni=${dateFilter.dateIni}&dateEnd=${dateFilter.dateEnd}`;
      if (dateFilter && dateFilter.service) url = `${url}&service=${dateFilter.service}`;
      return await this.baseapi.getHttp(url).then(response => response.data);
    }
  });

  _exports.default = _default;
});