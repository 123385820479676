define("printpost/services/test-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    baseapi: Ember.inject.service(),
    verifySendOrder: function () {
      let dataRequest = JSON.parse(localStorage.getItem('storage:request'));
      let hasCartorio = dataRequest.printingOptions.templateEmail.definition && dataRequest.printingOptions.templateEmail.definition.sendValue.toLowerCase().indexOf('cartório') > 0 || dataRequest.printingOptions.templateEmail.definition && dataRequest.printingOptions.templateEmail.definition.sendValue.toLowerCase().indexOf('cartorio') > 0 || dataRequest.printingOptions.templateSMS.definition && dataRequest.printingOptions.templateSMS.definition.sendValue.toLowerCase().indexOf('cartório') > 0 || dataRequest.printingOptions.templateSMS.definition && dataRequest.printingOptions.templateSMS.definition.sendValue.toLowerCase().indexOf('cartorio') > 0 || dataRequest.printingOptions.templateLetter.definition && dataRequest.printingOptions.templateLetter.definition.sendValue.toLowerCase().indexOf('cartório') > 0 || dataRequest.printingOptions.templateLetter.definition && dataRequest.printingOptions.templateLetter.definition.sendValue.toLowerCase().indexOf('cartorio') > 0 ? true : false;
      dataRequest.notarysOffice = hasCartorio;
      let dateFlat = new Date();
      let year = dateFlat.getFullYear();
      let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
      let day = this.baseapi.padStr(dateFlat.getDate() + 2, 2);
      let dayTwo = this.baseapi.padStr(dateFlat.getDate() + 3, 2);
      let dayTree = this.baseapi.padStr(dateFlat.getDate() + 5, 2);
      let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
      let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);

      if (hasCartorio || dataRequest.multiChannel) {
        if (dataRequest.printingOptions.templateSMS.disparoImediato) {
          dataRequest.printingOptions.templateSMS.disparoImediato = false;
          dataRequest.printingOptions.templateSMS.dataDisparo = `${day}/${month}/${year} - ${hour}:${minute}`;
        }

        if (dataRequest.printingOptions.templateEmail.disparoImediato) {
          dataRequest.printingOptions.templateEmail.disparoImediato = false;
          dataRequest.printingOptions.templateEmail.dataDisparo = `${dayTwo}/${month}/${year} - ${hour}:${minute}`;
        }

        if (dataRequest.printingOptions.templateLetter.disparoImediato) {
          dataRequest.printingOptions.templateLetter.disparoImediato = false;
          dataRequest.printingOptions.templateLetter.dataDisparo = `${dayTree}/${month}/${year} - ${hour}:${minute}`;
        }
      }

      let order = [];

      if (dataRequest.smsService) {
        let obj = {
          value: 'sms',
          name: 'SMS',
          date: new Date()
        };

        if (!dataRequest.printingOptions.templateSMS.disparoImediato && dataRequest.printingOptions.templateSMS.dataDisparo) {
          let dateObj = dataRequest.printingOptions.templateSMS.dataDisparo.replace(' - ', '/').replace(':', '/').split('/');
          obj.date = new Date(parseInt(dateObj[2]), parseInt(dateObj[1]), parseInt(dateObj[0]), parseInt(dateObj[3]), parseInt(dateObj[4]), 0);
        }

        order.push(obj);
      }

      if (dataRequest.emailService) {
        let obj = {
          value: 'email',
          name: 'E-mail',
          date: new Date()
        };

        if (!dataRequest.printingOptions.templateEmail.disparoImediato && dataRequest.printingOptions.templateEmail.dataDisparo) {
          let dateObj = dataRequest.printingOptions.templateEmail.dataDisparo.replace(' - ', '/').replace(':', '/').split('/');
          obj.date = new Date(parseInt(dateObj[2]), parseInt(dateObj[1]), parseInt(dateObj[0]), parseInt(dateObj[3]), parseInt(dateObj[4]), 0);
        }

        order.push(obj);
      }

      if (dataRequest.letterService) {
        let obj = {
          value: 'carta',
          name: 'Carta',
          date: new Date()
        };

        if (!dataRequest.printingOptions.templateLetter.disparoImediato && dataRequest.printingOptions.templateLetter.dataDisparo) {
          let dateObj = dataRequest.printingOptions.templateLetter.dataDisparo.replace(' - ', '/').replace(':', '/').split('/');
          obj.date = new Date(parseInt(dateObj[2]), parseInt(dateObj[1]), parseInt(dateObj[0]), parseInt(dateObj[3]), parseInt(dateObj[4]), 0);
        }

        order.push(obj);
      }

      order.sort((a, b) => {
        if (a.date > b.date) {
          return 1;
        }

        if (a.date < b.date) {
          return -1;
        }

        return 0;
      });

      if (dataRequest.multiChannel) {
        order.forEach((x, i) => {
          if (i == 0) {
            dataRequest.orderSend.first = x.value;
          } else if (i == 1) {
            dataRequest.orderSend.second = x.value;
          } else if (i == 2) {
            dataRequest.orderSend.third = x.value;
          }
        });
      }

      localStorage.setItem("storage:request", JSON.stringify(dataRequest));
      return order;
    }
  });

  _exports.default = _default;
});