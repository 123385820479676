define("printpost/controllers/whitelabel/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service('session'),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataItem: Ember.computed('model', function () {
      return this.get('model.item');
    }),
    fileAttach: Ember.computed('fileAttach', function () {
      return '';
    }).property().volatile(),
    actions: {
      salvar: function () {
        let item_data = Ember.get(this, 'dataItem');
        let item = {
          id: item_data.id,
          name: item_data.name,
          url: item_data.url,
          logo: item_data.logo,
          banner: item_data.banner,
          favicon: item_data.favicon,
          active: true,
          homeMessage: item_data.homeMessage,
          homeSubMessage: item_data.homeSubMessage,
          defaultColor: item_data.defaultColor
        };
        let _object = {
          data: {
            type: "WhiteLabel",
            attributes: item
          }
        };

        if (item.name.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink('WhiteLabels');
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(() => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.transitionToRoute('whitelabel.list');
            }, () => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
            });
          } else {
            let url = this.baseapi.apiLink('WhiteLabels/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(() => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
            }, () => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
            });
          }
        }
      },
      apagar: function () {
        let item_data = Ember.get(this, 'dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink('WhiteLabels/delete');
          let call = this.baseapi.delete(url + '/' + item_data.id);
          this.preloader.toggleClass('d-none');
          call.then(() => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('whitelabel.list');
          }, () => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
          });
        }
      },
      uploadImage: function (type, data) {
        Ember.set(this, 'fileAttach', data.target.files[0]);
        this.send('sendRawFile', type);
      },

      sendRawFile(type) {
        var file = Ember.get(this, 'fileAttach');
        let form = new FormData(); // let reqId = localStorage.getItem('newServiceRequestId');

        form.set("requestId", null);
        form.set("type", "template_image");
        form.set("path", 'images/templates');
        form.append("file", file);
        let ext = this.baseapi.getFileExtension(file.name);
        let url = this.baseapi.apiLink("rawFiles");
        url += "/upload-file";
        this.preloader.toggleClass('d-none');
        this.baseapi.postFormData(url, form).then(rawObj => {
          this.preloader.toggleClass('d-none');
          Ember.set(this, 'fileAttach', '');
          this.toast.success('', 'Arquivo enviado com sucesso.', {
            positionClass: 'toast-bottom-right'
          });
          let data = rawObj.data;

          if (type == 'logo') {
            Ember.set(this, 'dataItem.logo', rawObj.data);
          } else if (type == 'banner') {
            Ember.set(this, 'dataItem.banner', rawObj.data);
          } else if (type == 'favicon') {
            Ember.set(this, 'dataItem.favicon', rawObj.data);
          }
        }, error => {
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro ao enviar o arquivo, tente novamente.', {
            positionClass: 'toast-bottom-right'
          });
        });
      }

    }
  });

  _exports.default = _default;
});