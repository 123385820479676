define("printpost/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    beforeModel() {
      let mr = this.session.data.authenticated.menuRoles;
      let hasDashboard = mr.find(x => x.menus.url.toLowerCase().indexOf('dashboard') > -1);

      if (hasDashboard) {
        this.replaceWith('dashboard');
      } else {
        this.replaceWith('');
      }
    },

    model: function () {
      return Ember.RSVP.hash({
        currentUser: this.currentUser
      });
    }
  });

  _exports.default = _default;
});