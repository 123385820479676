define("printpost/controllers/carteiras/list", ["exports", "ember-concurrency", "printpost/utils/globalFunctions", "ember-local-storage"], function (_exports, _emberConcurrency, _globalFunctions, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = Ember.Object.extend({
    list_people_id: null
  });

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    variables: variables.create({
      list_people_id: null
    }),
    forceWallet: (0, _emberLocalStorage.storageFor)('force-wallet'),
    walletColumns: (0, _emberLocalStorage.storageFor)('wallet-columns'),
    newRequest: (0, _emberLocalStorage.storageFor)('request'),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    getWalletPeople: (0, _emberConcurrency.task)(function* (wallet, skip = 0, limit = 20) {
      let query = {
        filter: {
          order: 'id DESC',
          where: {
            id: {
              inq: wallet
            }
          }
        }
      };
      if (wallet.length == 1) query.filter.where.id = wallet[0]; // var defaultFilter = this.get('model.peopleFilter');
      // if(defaultFilter) query.filter.where = defaultFilter.filter;

      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');
      var url = this.baseapi.apiLink(`Wallets/peoples?${strquery}`);
      var wallets = yield this.baseapi.getHttp(url).then(x => x.data);
      this.preloader.toggleClass('d-none');
      this.set('model.walletsList', wallets);
    }),
    reloadMapWallet: (0, _emberConcurrency.task)(function* () {
      let query = {
        filter: {
          order: 'id DESC',
          limit: 20
        }
      };
      this.preloader.toggleClass('d-none');
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url = this.baseapi.apiLink(`Wallets/wallet-map?${strquery}`);
      const data = yield this.baseapi.getHttp(url).then(x => x.data);
      this.preloader.toggleClass('d-none');
      this.set('model.wallets', data);
    }),
    filterWalletPeople: (0, _emberConcurrency.task)(function* (wallet) {
      var defaultFilter = this.get('model.peopleFilter');
      let query = {
        filter: {
          where: defaultFilter
        }
      };
      query.filter.where.walletId = wallet.id;
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');
      var url = this.baseapi.apiLink(`Wallets/people-filter?${strquery}`);
      var peoples = yield this.baseapi.getHttp(url).then(x => x.data);
      this.preloader.toggleClass('d-none');
      this.set('model.peoplesList', peoples);
    }),
    taskUnifyWallets: (0, _emberConcurrency.task)(function* () {
      var wallet = this.get('model.unifyWallets');
      let query = {
        filter: {
          order: 'id DESC',
          where: {
            id: {
              inq: wallet
            }
          }
        }
      };
      if (wallet.length == 1) query.filter.where.id = wallet[0];
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');
      var url = this.baseapi.apiLink(`Wallets/unify-wallets?${strquery}`);
      var data = yield this.baseapi.getHttp(url).then(x => x.data);
      data.requestData.walletId = data.walletData.id;
      data.requestData.columns_email = data.walletData.columns_email;
      data.requestData.columnsSelectEmail = data.walletData.columns_email;
      data.requestData.columnsSelectSMS = data.walletData.columns_sms;
      data.requestData.columns_sms = data.walletData.columns_sms;
      localStorage.setItem('newServiceRequestId', data.requestData.id);
      localStorage.setItem('serviceLoad', true);
      this.preloader.toggleClass('d-none');
      var requestKeys = Object.keys(data.requestData);
      requestKeys.forEach(k => {
        this.set(`newRequest.${k}`, data.requestData[k]);
      });
      var walletKeys = Object.keys(data.walletData);
      walletKeys.forEach(k => {
        this.set(`forceWallet.${k}`, data.walletData[k]);
      });
      this.transitionToRoute('/services/new');
    }),
    actions: {
      listWalletData(wallet) {
        // this.set('model.tableColumns', wallet.columns);
        // this.set('model.walletData', wallet);
        // this.set('model.peopleFilter', null);
        this.get('getWalletPeople').perform(wallet);
      },

      setPeopleId(people) {
        let itens = this.variables.get('list_people_id');
        let idx = itens == null ? false : itens.find(x => x.id == people.id);
        var list = [];

        if (idx) {
          itens.map(x => {
            if (x.id != people.id) list.push(x);
          });
        } else {
          if (idx === false) {
            itens = [];
          }

          list = itens.concat([people]);
        }

        console.log('list', list);
        this.variables.set('list_people_id', list);
      },

      changePeopleData(people, key, input) {
        var data = this.get('model.peoplesList');
        var item = data.find(x => x.id == people.id);
        if (!item) return;
        item[key] = input;
        item['changed'] = true;
        Ember.$(`#tr${people.id}`).addClass('approve-1'); // console.log(data, item);

        this.set('model.peoplesList', data);
      },

      downloadWallet(file) {
        this.preloader.toggleClass('d-none');
        let url = this.baseapi.apiLink('Wallets');
        let token = this.baseapi.getToken();
        url += '/download-file-version/' + file.id + '?access_token=' + token;
        this.baseapi.getDownload(url, file.name);
        this.preloader.toggleClass('d-none');
      },

      updatePeoples() {
        var list = this.get('model.peoplesList');
        var data = list.filter(x => x.changed == true);
        if (data.length == 0) this.toast.error('', 'É necessário alterar algum registro', {
          positionClass: 'toast-bottom-right'
        });
        let url = this.baseapi.apiLink('PeopleHistories');
        this.preloader.toggleClass('d-none');
        this.baseapi.patch(`${url}/update`, {
          data: data
        }).then(resp => {
          this.preloader.toggleClass('d-none');
          this.toast.success('', 'Atualização realizacao com sucesso!', {
            positionClass: 'toast-bottom-right'
          });
          this.get('reloadMapWallet').perform();
        }, () => {
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro na atualizaçao', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      paginationPeoples(filterSkip) {
        let wallet = this.get('model.walletData');
        this.get('getWalletPeople').perform(wallet, filterSkip.skip, filterSkip.limit);
      },

      showInputSearch(idx) {
        Ember.$(`#header${idx}`).toggleClass('d-none');
        Ember.$(`#label${idx}`).toggleClass('d-none');
        console.log(idx);
      },

      searchItem(idx, key) {
        if (key.keyCode == 13) {
          let wallet = this.get('model.walletData');
          this.get('filterWalletPeople').perform(wallet);
          return;
        }

        let dataFilter = this.get('model.peopleFilter');
        var val = Ember.$(`#header${idx}`).val();
        var column = this.get('model.tableColumns')[idx];
        var query = {};

        if (dataFilter) {
          dataFilter[column] = val;
          query = dataFilter;
        } else {
          query[column] = val;
        }

        this.set('model.peopleFilter', query);
      },

      doUnifyWallet() {
        this.get('taskUnifyWallets').perform();
      },

      showWalletPeopleList(wallet) {
        this.preloader.toggleClass('d-none');
        this.set('model.walletData', wallet);
        this.set('model.tableColumns', wallet.columns);
        this.set('model.peoplesList', wallet.peopleHistories);
        this.preloader.toggleClass('d-none');
      },

      unifyWallets(wallets) {
        this.set('model.unifyWallets', wallets);
      }

    }
  });

  _exports.default = _default;
});