define("printpost/templates/components/counter-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uzq9dJj9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"counter-data\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"counter\"],[9],[1,[21,\"counter\"],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"data\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"chart\",\"data\"]]],null,{\"statements\":[[0,\"\\n      \"],[1,[27,\"ember-chart\",null,[[\"type\",\"data\",\"options\",\"width\",\"height\"],[\"line\",[23,[\"chart\",\"data\"]],[23,[\"chart\",\"options\"]],140,50]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/counter-block.hbs"
    }
  });

  _exports.default = _default;
});