define("printpost/controllers/templates/new", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import {inject as service} from '@ember/service';
  const newTemplate = Ember.Object.extend({
    name: null,
    internal: null,
    externalHtml: null,
    baseTemplate: null,
    walletId: null,
    productId: null,
    channelId: null
  });

  var _default = Ember.Controller.extend({
    newTemplate: newTemplate.create(),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    isShowingTranslucent: true,
    isShowingTranslucentForWallet: true,
    selected: 'interno',
    wallet: null,
    baseTemplate: Ember.computed('model.base.data', function () {
      return this.get('model.base.data');
    }),
    channel: Ember.computed('model.base', function () {
      return this.get('model.base.channel.content');
    }),
    product: Ember.computed('model.base', function () {
      return this.get('model.base.product.content');
    }),
    selectedWallet: Ember.computed('wallet.wallet.data.attributes', function () {
      return this.get('wallet.wallet.data.attributes');
    }),
    maxLength: Ember.computed('wallet.wallet.data.attributes', function () {
      return this.get('wallet.wallet.data.attributes.maxLength');
    }),
    createTemplateTask: (0, _emberConcurrency.task)(function* (newTemplate) {
      const USER = this.get('session.data.authenticated');
      let template = this.store.createRecord('template', {
        name: Ember.get(newTemplate, 'name'),
        walletId: Ember.get(newTemplate, 'walletId'),
        internal: Ember.get(this.get('baseTemplate'), 'internal'),
        externalHtml: Ember.get(this.get('baseTemplate'), 'externalHtml'),
        channelId: Ember.get(this.get('channel'), 'id'),
        productId: Ember.get(this.get('product'), 'id'),
        paperSize: Ember.get(this.get('baseTemplate'), 'paperSize'),
        userId: Ember.get(USER, 'userId'),
        baseTemplateId: Ember.get(this.get('model.base'), 'id')
      });

      try {
        yield template.save();
        this.toast.success('', 'Salvo com sucesso', {
          positionClass: 'toast-bottom-right'
        });
        this.transitionToRoute('templates').then(() => document.location.reload(true));
      } catch (error) {
        for (let err in error.errors) {
          if (error.errors.hasOwnProperty(err)) {
            if (error.errors[err].status == 422) {
              this.toast.error('Campos obrigatórios em branco.', 'Erro!', {
                positionClass: 'toast-bottom-right'
              });
            }
          }
        }

        return error;
      }
    }).maxConcurrency(3).enqueue(),
    selectWallet: (0, _emberConcurrency.task)(function* (json_data) {
      let request = yield this.baseapi.getWalletById(json_data.id);
      Ember.set(this.newTemplate, 'walletId', json_data.id);
      Ember.set(this, 'wallet', request);
    }).keepLatest(),
    actions: {
      proceedWithoutWallet: function () {
        Ember.set(this.newTemplate, 'walletId', 'static');
        Ember.set(this, 'wallet', null);
        Ember.set(this, 'isShowingTranslucentForWallet', false);
      },
      proceedWithWallet: function () {
        Ember.set(this, 'isShowingTranslucentForWallet', false);
      },
      refreshAndClose: function () {
        this.transitionToRoute('templates').then(() => document.location.reload(true));
      },
      createTemplate: function (newTemplate) {
        Ember.get(this, 'createTemplateTask').perform(newTemplate);
      },
      internalKeyPress: function (editableValue) {
        Ember.run.debounce(this, this.changeInternalText, editableValue, 200);
      },
      externalKeyPress: function (editableValue) {
        Ember.run.debounce(this, this.changeExternalText, editableValue, 200);
      },
      selectWallet: function (json_data) {
        Ember.get(this, 'selectWallet').perform(json_data).then(() => {
          this.toast.success('', 'Carteira selecionada com sucesso!', {
            positionClass: 'toast-bottom-right'
          });
        });
      },
      appendColumnSms: function (column) {
        var textArea = Ember.$('#sms');
        var startPosition = textArea[0].selectionStart; // var endPosition = textArea.selectionEnd;

        var textAreaValue = textArea.val();
        var txtToAdd = column;
        textArea.val(textAreaValue.substring(0, startPosition) + "%" + txtToAdd + "%" + textAreaValue.substring(startPosition));
        var document = textArea;
        Ember.set(this.baseTemplate, 'internal', document.val());
      },
      appendColumnInternal: function (column, editableValue) {
        var textArea = Ember.$('#' + editableValue.id);
        var length = parseInt(Ember.$('#' + editableValue.id + 'Chars').text());
        var startPosition = textArea[0].selectionStart; // var endPosition = textArea.selectionEnd;

        var textAreaValue = textArea.val();
        var txtToAdd = column;
        var total = length - this.maxLength[txtToAdd];

        if (total >= 0) {
          textArea.val(textAreaValue.substring(0, startPosition) + "%" + txtToAdd + "%" + textAreaValue.substring(startPosition));
          Ember.$('#' + editableValue.id + 'Chars').text(total);
          var document = Ember.$('#' + editableValue.target).contents().find("html");
          document.find('#' + editableValue.id).text(textArea.val());
          Ember.set(this.baseTemplate, 'internal', document.html());
        } else {
          this.toast.error('Quantidade máxima de texto atingida.', 'Erro!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      appendColumnExternal: function (column, editableValue) {
        var textArea = Ember.$('#' + editableValue.id);
        var length = parseInt(Ember.$('#' + editableValue.id + 'Chars').text());
        var startPosition = textArea[0].selectionStart; // var endPosition = textArea.selectionEnd;

        var textAreaValue = textArea.val();
        var txtToAdd = column;
        var total = length - this.maxLength[txtToAdd];

        if (total >= 0) {
          textArea.val(textAreaValue.substring(0, startPosition) + "%" + txtToAdd + "%" + textAreaValue.substring(startPosition));
          Ember.$('#' + editableValue.id + 'Chars').text(total);
          var document = Ember.$('#' + editableValue.target).contents().find("html");
          document.find('#' + editableValue.id).text(textArea.val());
          Ember.set(this.baseTemplate, 'externalHtml', document.html());
        } else {
          this.toast.error('Quantidade máxima de texto atingida.', 'Erro!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      changeInternalImg: function (image, editableValue) {
        var document = Ember.$('#' + editableValue.target).contents().find("html");
        document.find('#' + editableValue.id).attr('src', image.url);
        document.find('#' + editableValue.id).attr('style', 'width: 100%;');
        Ember.set(this.baseTemplate, 'internal', document.html());
      },
      changeExternalImg: function (image, editableValue) {
        var document = Ember.$('#' + editableValue.target).contents().find("html");
        document.find('#' + editableValue.id).attr('src', image.url);
        document.find('#' + editableValue.id).attr('style', 'width: 100%;');
        Ember.set(this.baseTemplate, 'externalHtml', document.html());
      }
    },
    changeInternalText: function (editableValue) {
      var document = Ember.$('#' + editableValue.target).contents().find("html");
      var before = document.find('#' + editableValue.id).text().length;
      document.find('#' + editableValue.id).text(editableValue.value);
      var after = document.find('#' + editableValue.id).text().length;
      var changes = after - before;
      var length = parseInt(Ember.$('#' + editableValue.id + 'Chars').text());
      var total = length - changes;

      if (total >= 0) {
        Ember.$('#' + editableValue.id + 'Chars').text(total);
        Ember.set(this.baseTemplate, 'internal', document.html());
      } else {
        this.toast.error('Quantidade máxima de texto atingida.', 'Erro!', {
          positionClass: 'toast-bottom-right'
        });
      }
    },
    changeExternalText: function (editableValue) {
      var document = Ember.$('#' + editableValue.target).contents().find("html");
      var before = document.find('#' + editableValue.id).text().length;
      document.find('#' + editableValue.id).text(editableValue.value);
      var after = document.find('#' + editableValue.id).text().length;
      var changes = after - before;
      var length = parseInt(Ember.$('#' + editableValue.id + 'Chars').text());
      var total = length - changes;

      if (total >= 0) {
        Ember.$('#' + editableValue.id + 'Chars').text(total);
        Ember.set(this.baseTemplate, 'externalHtml', document.html());
      } else {
        this.toast.error('Quantidade máxima de texto atingida.', 'Erro!', {
          positionClass: 'toast-bottom-right'
        });
      }
    }
  });

  _exports.default = _default;
});