define("printpost/helpers/lowercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lowercase = lowercase;
  _exports.default = void 0;

  function lowercase(params
  /*, hash*/
  ) {
    let str = params;

    if (Ember.isArray(params)) {
      str = params[0];
    }

    if (typeof str === 'undefined') {
      str = '';
    }

    if (str && typeof str === "string") {
      return str.toLowerCase();
    }

    return '';
  }

  var _default = Ember.Helper.helper(lowercase);

  _exports.default = _default;
});