define("printpost/models/computer-profile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    maquinaId: _emberData.default.attr('number'),
    maquina: _emberData.default.attr('string'),
    usuario: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    porta: _emberData.default.attr('number'),
    letter: _emberData.default.attr(),
    sms: _emberData.default.attr(),
    email: _emberData.default.attr()
  });

  _exports.default = _default;
});