define("printpost/controllers/centrodecusto/edit", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service('session'),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    dataItem: Ember.computed('model', function () {
      return this.get('model.item');
    }),
    accountUser: Ember.computed('accountUser', function () {
      return this.session.data.authenticated.account;
    }),
    adminValue: Ember.computed('adminValue', function () {
      return this.baseapi.getAdmValue();
    }),
    searchTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Accounts/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    searchOrganizationTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      let url_account = this.baseapi.apiLink('Organizations/list');
      var token = this.get('session.data.authenticated.id');
      var strquery = `&filter[where][username][like]=${term}&filter[where][username][options]=i`;
      let url = `${url_account}?${strquery}&access_token=${token}`;
      return fetch(url).then(resp => resp.json()).then(json => json);
    }),
    actions: {
      salvar: function () {
        let item_data = Ember.get(this, 'dataItem');
        let organization = Ember.get(this, 'dataItem.organization');
        let organizationId = organization ? organization.id : '';
        if (Ember.get(this, 'accountUser.organizationId')) organizationId = Ember.get(this, 'accountUser.organizationId');
        var userId = item_data.userId;

        if (Ember.get(this, 'accountUser.type') != Ember.get(this, 'adminValue')) {
          userId = Ember.get(this, 'accountUser.id');
        }

        let item = {
          id: item_data.id,
          description: item_data.description,
          type: item_data.type,
          cpf: item_data.cpf,
          cnpj: item_data.cnpj,
          razaoSocial: item_data.razaoSocial,
          inscricaoEstadual: item_data.inscricaoEstadual,
          username: item_data.username,
          userId: userId,
          organizationId: organizationId
        };
        let _object = {
          data: {
            type: "CostCenters",
            attributes: item
          }
        };

        if (item.description.length > 0) {
          if (!item.id) {
            let url = this.baseapi.apiLink('CostCenters');
            let __rp = _object;
            delete __rp.data.attributes.id;
            let call = this.baseapi.post(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(() => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Cadastrado!', 'Dados cadastrados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
              this.transitionToRoute('centrodecusto.list');
            }, () => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não cadastrado!', 'Dados não foram cadastrados!', {
                positionClass: 'toast-bottom-right'
              });
            });
          } else {
            let url = this.baseapi.apiLink('CostCenters/' + item.id);
            let __rp = _object;
            __rp.data.id = item.id;
            delete __rp.data.attributes.id;
            let call = this.baseapi.patch(url, __rp);
            this.preloader.toggleClass('d-none');
            call.then(() => {
              this.preloader.toggleClass('d-none');
              this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
                positionClass: 'toast-bottom-right'
              });
            }, () => {
              this.preloader.toggleClass('d-none');
              this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
                positionClass: 'toast-bottom-right'
              });
            });
          }
        }
      },
      apagar: function () {
        let item_data = Ember.get(this, 'dataItem');

        if (item_data.id) {
          let url = this.baseapi.apiLink('CostCenters');
          let call = this.baseapi.delete(url + '/' + item_data.id);
          this.preloader.toggleClass('d-none');
          call.then(() => {
            this.preloader.toggleClass('d-none');
            this.toast.success('Item Apagado!', 'Item apagado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.transitionToRoute('centrodecusto.list');
          }, () => {
            this.preloader.toggleClass('d-none');
            this.toast.error('Item Não apagado!', 'Dados não foram apagados!', {
              positionClass: 'toast-bottom-right'
            });
          });
        }
      },

      setCliente(item) {
        Ember.set(this, 'dataItem.account', item);
        Ember.set(this, 'dataItem.userId', item.id);
      },

      updateCPF(unmasked, masked) {
        if (unmasked.length == 11 || unmasked.length == 14) {
          if ((0, _globalFunctions.validaCpfCnpj)(masked)) {
            this.toast.success('CPF/CNPJ válido!', 'CPF/CNPJ válido!', {
              positionClass: 'toast-bottom-right'
            });
          } else {
            this.toast.error('CPF/CNPJ inválido!', 'CPF/CNPJ inválido, verificar dados inseridos', {
              positionClass: 'toast-bottom-right'
            });
          }
        }

        if (unmasked.length <= 11) {
          Ember.set(this, 'dataItem.cpf', masked);
          Ember.set(this, 'dataItem.cnpj', '');
          Ember.set(this, 'dataItem.razaoSocial', '');
          Ember.set(this, 'dataItem.inscricaoEstadual', '');
        } else {
          Ember.set(this, 'dataItem.cnpj', masked);
          Ember.set(this, 'dataItem.cpf', '');
        }
      },

      setOrganization(item) {
        Ember.set(this, 'dataItem.organization', item);
        Ember.set(this, 'dataItem.organizationId', item.id);
        Ember.set(this, 'model.organizationId', item.id);
      },

      selectAddress(item) {
        let url = this.baseapi.apiLink('Addresses');
        let call = this.baseapi.patch(`${url}/${item.id}/main/${item.type}`, item);
        this.preloader.toggleClass('d-none');
        call.then(() => {
          this.preloader.toggleClass('d-none');
          this.toast.success('Item Atualizado!', 'Dados atualizados com sucesso', {
            positionClass: 'toast-bottom-right'
          });
          this.set('model.address.main', item);
        }, () => {
          this.toast.error('Item Não atualizado!', 'Dados não foram atualizados!', {
            positionClass: 'toast-bottom-right'
          });
          this.preloader.toggleClass('d-none');
        });
      },

      reloadAddress(item) {
        //addres
        this.preloader.toggleClass('d-none');
        let url_address = this.baseapi.apiLink('Addresses/list?filter[where][type]=COSTCENTER_ADDRESS');
        let id = item.costCenterId;

        if (id && id.length > 10) {
          url_address += "&filter[where][costCenterId]=" + id;
        }

        this.baseapi.getHttp(url_address).then(resp => {
          this.set('model.address.list', resp.data);
          if (resp.data.length == 0) this.set('model.address.main', '');
          this.preloader.toggleClass('d-none');
        });
      },

      clearClient() {
        Ember.set(this, 'dataItem.account', null);
        Ember.set(this, 'dataItem.userId', '');
        this.send('salvar');
      },

      clearOrganization() {
        Ember.set(this, 'dataItem.organization', null);
        Ember.set(this, 'dataItem.organizationId', '');
        this.send('salvar');
      }

    }
  });

  _exports.default = _default;
});