define("printpost/routes/reports/panelmail", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dashboard: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    model: async function () {
      let url_tag = this.baseapi.apiLink('RequestTags/find');
      const listCampaign = await this.baseapi.getHttp(url_tag).then(x => x.data);
      var url_costcenter = this.baseapi.apiLink('CostCenters/list');
      const obj_costcenter = await this.baseapi.getHttp(url_costcenter).then(r => r.data);
      const objDate = new Date();
      const strDate = this.baseapi.getMonthStr(objDate.getMonth()) + " - " + objDate.getFullYear();
      const dateStart = `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T00:00:00-03:00`;
      const dateEnd = `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T23:59:00-03:00`;
      let url = this.baseapi.apiLink('RequestDatasets/panel-mail');
      const data = await this.baseapi.getHttp(`${url}?filter[where][and][0][createdAt][gte]=${dateStart}&filter[where][and][1][createdAt][lte]=${dateEnd}`).then(x => x.data);
      let urlItens = this.baseapi.apiLink('Reports/panel-itens');
      const dataItens = await this.baseapi.getHttp(`${urlItens}?filter[where][and][0][createdAt][gte]=${dateStart}&filter[where][and][1][createdAt][lte]=${dateEnd}`).then(x => x.data);
      const graphOptions = {
        "responsive": true,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#ffffff"
          }
        },
        "scales": {
          "borderColor": "#ffffff",
          "tickColor": "#FFFFFF",
          "yAxes": [{
            "display": true,
            "ticks": {
              "beginAtZero": true,
              "zeroLineColor": "#FFFFFF",
              "fontColor": "#FFFFFF"
            },
            "gridLines": {
              "display": true,
              "zeroLineColor": "#294354",
              "color": "#294354"
            }
          }],
          "xAxes": [{
            "display": true,
            "gridLines": {
              "display": true
            },
            "ticks": {
              "beginAtZero": true,
              "zeroLineColor": "#FFFFFF",
              "fontColor": "#FFFFFF",
              "fontSize": 10
            }
          }]
        }
      };
      const piegraphOptions = {
        "responsive": true,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#000"
          }
        },
        "scales": {
          "yAxes": [{
            "display": false,
            "ticks": {
              "beginAtZero": false
            },
            "gridLines": {
              "display": false
            }
          }],
          "xAxes": [{
            "display": false,
            "gridLines": {
              "display": false
            }
          }]
        }
      }; // construct pie graph

      var objPieGraph = {
        datasets: [{
          label: "Status de e-mail",
          fill: false,
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 0
        }],
        color: [],
        labels: []
      };
      data.qtdStatus.forEach(itemData => {
        let _color = itemData.color; // colors[idx]

        objPieGraph.datasets[0].backgroundColor.push(_color);
        objPieGraph.datasets[0].borderColor.push(_color);
        objPieGraph.color.push(_color);
        objPieGraph.labels.push(itemData.message);
        objPieGraph.datasets[0].data.push(itemData.qtd);
      });
      return Ember.RSVP.hash({
        data: data,
        dataItens: dataItens,
        dataWallets: listCampaign,
        obj_costcenter: obj_costcenter,
        dataDateFilterStr: strDate,
        dataDateFilter: objDate,
        objPieGraph: objPieGraph,
        piegraphOptions: piegraphOptions,
        key: '',
        returnMessage: '',
        filesReport: [],
        searchContact: '',
        filter: {
          skip: 0,
          limit: 0
        },
        isLoading: false
      });
    }
  });

  _exports.default = _default;
});