define("printpost/validations/create", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 3,
      max: 40
    })],
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 8,
      max: 80
    })] // username: [validatePresence(true), validateLength({min: 5, max: 60})],
    // aboutMe: [validateLength({allowBlank: true, max: 200})],
    // country: [validatePresence(true)],
    // gender: [validatePresence(true)],

    /*
    terms: [
      validateInclusion({
        list: [true],
        message: "Please accept the terms and conditions!"
      })
    ],
    color: [validatePresence(true)]
    */

  };
  _exports.default = _default;
});