define("printpost/routes/services/payment", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    test: Ember.inject.service('test-service'),
    model: async function () {
      const validateRequest = await this.baseapi.validatePendentRequest().then(x => x);
      var order = this.test.verifySendOrder();
      let newRequest = JSON.parse(localStorage.getItem('storage:request')); // let url_wallet = this.baseapi.apiLink('Wallets/list');
      // var obj_wallet = null;

      var excededSMS = JSON.parse(localStorage.getItem('excededSms'));
      excededSMS = excededSMS ? excededSMS : {
        quantityAll: 0,
        data: []
      };
      var qtdSMSAll = 0;
      var qtdEmailAll = 0;
      var qtdWhatsappAll = 0;
      const requestOptions = JSON.parse(localStorage.getItem('storage:request-options'));
      const obj_prices = requestOptions.dataPrices;
      const printingOptions = requestOptions.dataPrintOptions;
      const praticatePrices = requestOptions.praticatePrices;
      const dataSendOption = requestOptions.dataSendOption;
      let total_value = "0,00";
      let valQuantity = 0;
      let optionPrinted = false;
      var bindingTemplates = null;

      if (newRequest.walletId && newRequest.walletId != 'static') {
        // obj_wallet = await this.baseapi.getHttp(url_wallet + '?filter[where][id]=' + newRequest.walletId).then(x => x.data[0]);
        // qtdSMSAll = praticatePrices.sms.quantity + excededSMS.quantityAll;
        // qtdEmailAll = praticatePrices.email.quantity;
        //dados para visualizar e testar
        var reqId = localStorage.getItem('newServiceRequestId');
        var url = this.baseapi.apiLink(`Requests/${reqId}/bind-template`);
        bindingTemplates = await this.baseapi.getHttp(url).then(resp => resp.data);

        if (newRequest.whatsappService && bindingTemplates.whatsapp && bindingTemplates.whatsapp.length > 1) {
          bindingTemplates.whatsapp = bindingTemplates.whatsapp.slice(0, 1);
        }
      } //papper weight
      // let url_papper_weight = this.baseapi.apiLink('PapperWeights/list');
      // const obj_papper_weight = await this.baseapi.getHttp(url_papper_weight).then(resp => resp.data);
      //addres
      // let url_address = this.baseapi.apiLink('Addresses/list');
      // const obj_address = await this.baseapi.getHttp(url_address).then(resp => resp.data);
      //raw file


      var reqId = localStorage.getItem('newServiceRequestId');
      var dataRawFiles = null;
      var sendMonitors = null;

      if (reqId) {
        var urlRawFiles = this.baseapi.apiLink('rawFiles/list');
        var query = {
          filter: {
            where: {
              requestId: reqId,
              type: 'request_attach_observation'
            }
          }
        };
        var strquery = (0, _globalFunctions.apiLinkQuery)(query);
        urlRawFiles += "?" + strquery;
        dataRawFiles = await this.baseapi.getHttp(urlRawFiles).then(rf => rf.data);
      }

      if (newRequest.costCenterId) {
        var urlMonitorSend = this.baseapi.apiLink('SendMonitoringCostCenters/list');
        var queryMonitorSend = {
          filter: {
            where: {
              costCenterId: newRequest.costCenterId
            }
          }
        };
        var strqueryMonitorSend = (0, _globalFunctions.apiLinkQuery)(queryMonitorSend);
        urlMonitorSend += "?" + strqueryMonitorSend;
        sendMonitors = await this.baseapi.getHttp(urlMonitorSend).then(rf => rf.data);
      }

      return Ember.RSVP.hash({
        // wallet: obj_wallet,
        prices: obj_prices,
        printingOptions: printingOptions,
        praticatePrices: praticatePrices,
        dataSendOption: dataSendOption,
        excededSMS: excededSMS,
        qtdSMSAll: qtdSMSAll,
        qtdWhatsappAll: qtdWhatsappAll,
        qtdEmailAll: qtdEmailAll,
        totalValue: total_value,
        optionPrinted: optionPrinted,
        // papperWeight: obj_papper_weight,
        // address: obj_address,
        order: order,
        rawFiles: dataRawFiles,
        bindingTemplates: bindingTemplates,
        request: validateRequest,
        reloadAll: true,
        qtds: {
          disponivel: {
            email: 0,
            sms: 0
          }
        },
        runningValidation: false,
        messageTest: {
          number: ''
        },
        sendMonitors: sendMonitors
      });
    },
    actions: {
      routerReload: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});