define("printpost/controllers/reports/list", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    menusFilter: Ember.computed('menusFilter', function () {
      var itens = ['filter_mes', 'filter_ano', 'filter_inicio'];
      return itens;
    }),
    dataWallets: Ember.computed('model.dataWallets', function () {
      var model = this.get('model.dataWallets');
      return model;
    }),
    dataCostCenter: Ember.computed('model.obj_costcenter', function () {
      var model = this.get('model.obj_costcenter');
      return model;
    }),
    dataDateFilter: Ember.computed('model.dataDateFilter', function () {
      var model = this.get('model.dataDateFilter');
      return model;
    }),
    dataDateFilterStr: Ember.computed('model.dataDateFilterStr', function () {
      var model = this.get('model.dataDateFilterStr');
      return model;
    }),
    taskFilterReturn: (0, _emberConcurrency.task)(function* () {
      var name = Ember.get(this, 'cStrWalletId');
      var costCenterId = Ember.get(this, 'cStrCostCenterId');
      var query = {
        filter: {
          where: {
            and: [{
              createdAt: {
                gte: Ember.get(this, 'cStrDateBegin')
              }
            }, {
              createdAt: {
                lte: Ember.get(this, 'cStrDateEnd')
              }
            }]
          }
        }
      };

      if (name && name.length > 0) {
        query.filter.where.title = {
          regexp: `${name}/i`
        };
      }

      if (costCenterId && costCenterId.length > 0) {
        query.filter.where.costCenterId = costCenterId;
      }

      const strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');

      try {
        let format = Ember.get(this, 'model.format');
        let url = this.baseapi.apiLink('Reports/dash-analytical');
        const data = yield this.baseapi.getHttp(`${url}?${strquery}`).then(x => x.data);
        this.preloader.toggleClass('d-none');
        this.set('model.barSendGraph', data.barSendGraph);
        this.set('model.daySendGraph', data.daySendGraph.chartjs.graph);
        this.set('model.pieSendGraph', data.pieSendGraph.chartjs.graph);
        this.set('model.cards', data.cards);
        this.set('model.journeyData', null);
      } catch (error) {
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao consultar dados', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    taskFilterJourney: (0, _emberConcurrency.task)(function* () {
      var name = Ember.get(this, 'cStrWalletId');
      var costCenterId = Ember.get(this, 'cStrCostCenterId');
      var type = Ember.get(this, 'journeyType');
      var query = {
        filter: {
          where: {
            and: [{
              createdAt: {
                gte: Ember.get(this, 'cStrDateBegin')
              }
            }, {
              createdAt: {
                lte: Ember.get(this, 'cStrDateEnd')
              }
            }]
          }
        }
      };

      if (costCenterId && costCenterId.length > 0) {
        query.filter.where.costCenterId = costCenterId;
      }

      if (name && name.length > 0) {
        query.filter.where.title = {
          regexp: `^${name}/i`
        };
      }

      const strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');

      try {
        let format = Ember.get(this, 'model.format');
        let url = this.baseapi.apiLink('Reports/journey');
        const data = yield this.baseapi.getHttp(`${url}/${type}?${strquery}`).then(x => x.data);
        this.set('model.journeyData', data);
        this.preloader.toggleClass('d-none');
      } catch (error) {
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao consultar dados', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    datePeriodo: [],
    cStrDateBegin: Ember.computed('strDateBegin', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-01 00:00:00';
      return strDate;
    }),
    cStrDateEnd: Ember.computed('strDateEnd', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + ' 19:59:00';
      return strDate;
    }),
    // request: computed('request', function*() {
    //     return yield this.baseapi.getHttp("http://localhost:3001/v1/Requests/list?filter[where][id]=6070af122d14af2be2f1ceaa&filter[include]=wallet&filter[include]=sentStatuses&filter[include]=account&filter[include]=progress&filter[include]=organization&filter[fields][printingOptions]=true&filter[fields][quantity]=true&filter[fields][typeConfig]=true&filter[fields][totalCost]=true&filter[fields][totalFreight]=true&filter[fields][id]=true&filter[fields][title]=true&filter[fields][channel]=true&filter[fields][createdAt]=true&filter[fields][letterService]=true&filter[fields][smsService]=true&filter[fields][emailService]=true&filter[fields][emailSubject]=true&filter[fields][emailContact]=true&filter[fields][status]=true&filter[fields][fileDb]=true&filter[fields][walletId]=true&filter[fields][shippingMethod]=true&filter[fields][number]=true&filter[fields][userId]=true&filter[fields][paymentStatus]=true&filter[fields][organizationId]=true&filter[fields][notarysOffice]=true&filter[fields][multiChannel]=true&filter[fields][pendingApprove]=true&filter[fields][observation]=true&filter[fields][orderSend]=true").then(x => x.data);
    // }),
    // reportMultichannel: computed('reportMultichannel', function*() {
    //     return yield this.baseapi.getHttp("http://localhost:3001/v1/Reports/multichannel-analytical?id=6070af122d14af2be2f1ceaa&dispatch=1&type=1").then(x => x.data);
    // }),
    actions: {
      actionFilterReturn: function (strfilter) {
        let listFilterMenus = Ember.get(this, 'menusFilter');
        let objDate = new Date();
        let strDateBegin = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-01T00:00:00-03:00';
        let strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
        listFilterMenus.forEach(item => {
          if (strfilter != item) {
            Ember.$('#' + item).addClass('text-lightgray');
          } else {
            Ember.$('#' + item).removeClass('text-lightgray');

            if (item == 'filter_ano') {
              strDateBegin = objDate.getFullYear() + '-01-01T00:00:00-03:00';
              strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
            } else if (item == 'filter_inicio') {
              strDateBegin = '2019-01-01T00:00:00-03:00';
              strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
            }
          }
        });
        Ember.set(this, 'cStrDateBegin', strDateBegin);
        Ember.set(this, 'cStrDateEnd', strDateEnd);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes_setas').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
        }, 500);
        Ember.get(this, 'taskFilterReturn').perform();
      },
      actionFilterWallet: function (name) {
        Ember.$('#list_wallets li').each((index, item) => {
          var nameCampaign = Ember.$(item).attr('data-id');

          if (name == nameCampaign) {
            Ember.$(item).removeClass('text-lightgray');
            name != 'filter_all' ? Ember.set(this, 'cStrWalletId', name) : Ember.set(this, 'cStrWalletId', '');
            Ember.get(this, 'taskFilterReturn').perform();
          } else {
            Ember.$(item).addClass('text-lightgray');
          }

          if (name == "filter_all") Ember.$(item).removeClass('text-lightgray');
        });
      },
      actionFilterCost: function (name) {
        Ember.$('#list_costcenter li').each((index, item) => {
          var nameCampaign = Ember.$(item).attr('data-name');
          var valueText = Ember.$(item).attr('data-id');

          if (name == nameCampaign) {
            Ember.$(item).removeClass('text-lightgray');
            name != 'filter_all' ? Ember.set(this, 'cStrCostCenterId', valueText) : Ember.set(this, 'cStrCostCenterId', '');
            Ember.get(this, 'taskFilterReturn').perform();
          } else {
            Ember.$(item).addClass('text-lightgray');
          }

          if (name == "filter_all") Ember.$(item).removeClass('text-lightgray');
        });
      },

      filterCostcenter() {
        let input, filter, li;
        input = document.getElementById('filterCostcenter');
        filter = input.value.toUpperCase();
        li = this.get('model.obj_costcenter');
        var new_list = li.filter(x => x.description.toUpperCase().indexOf(filter) > -1);
        Ember.set(this, 'dataCostCenter', new_list);
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });

        if (!dateFlat) {
          return;
        }

        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":00-03:00";
        let d = this.datePeriodo;

        if (d.length == 0) {
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        } else if (d.length == 1) {
          let st = year + "-" + month + "-" + day + "T23:59:00-03:00";
          d.push(st);
          Ember.set(this, 'cStrDateEnd', st);
          Ember.get(this, 'taskFilterReturn').perform();
        } else if (d.length == 2) {
          d = [];
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        }

        this.datePeriodo = d;
        setTimeout(() => {
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').addClass('text-lightgray');
          Ember.$('#filter_periodo').removeClass('text-lightgray');
        }, 500);
      },

      beforeMonth() {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() - 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth(), 0);
        let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        Ember.set(this, 'cStrDateBegin', d1);
        Ember.set(this, 'cStrDateEnd', d2);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').removeClass('text-lightgray');
        }, 500);
        Ember.get(this, 'taskFilterReturn').perform(true);
      },

      afterMonth() {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() + 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth() + 2, 0);
        let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        Ember.set(this, 'cStrDateBegin', d1);
        Ember.set(this, 'cStrDateEnd', d2);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').removeClass('text-lightgray');
        }, 500);
        Ember.get(this, 'taskFilterReturn').perform(true);
      },

      filterCampaign() {
        let input, filter, li;
        input = document.getElementById('filterCampaign');
        filter = input.value.toUpperCase();
        li = this.get('model.dataWallets');
        var new_list = li.filter(x => x.name.toUpperCase().indexOf(filter) > -1);
        Ember.set(this, 'dataWallets', new_list);
      },

      setFormat(v) {
        this.set('model.format', v);
        this.get('taskFilterReturn').perform();
      },

      setJourneyType(v) {
        this.set('journeyType', v);
        this.get('taskFilterJourney').perform();
      }

    }
  });

  _exports.default = _default;
});