define("printpost/routes/carteiras/details", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //
  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),

    model(params) {
      return {
        'walletId': params.id,
        'walletInstance': this.get('walletTask').perform(params.id),
        'peopleHistories': this.get('peopleHistoriesTask').perform(params.id)
      };
    },

    walletTask: (0, _emberConcurrency.task)(function* (id) {
      let request = yield this.baseapi.getWalletById(id);
      return request;
    }),
    peopleHistoriesTask: (0, _emberConcurrency.task)(function* (id) {
      let request = yield this.baseapi.getPeopleHistories(id);
      return request;
    })
  });

  _exports.default = _default;
});