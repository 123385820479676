define("printpost/components/ember-load-remover", ["exports", "ember-load/components/ember-load-remover"], function (_exports, _emberLoadRemover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberLoadRemover.default;
    }
  });
});