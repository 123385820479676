define("printpost/components/json-editor", ["exports", "ember-jsoneditor/components/json-editor"], function (_exports, _jsonEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _jsonEditor.default;
    }
  });
});