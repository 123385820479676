define("printpost/routes/services/validation", ["exports", "printpost/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    model: async function () {
      const validateRequest = await this.baseapi.validatePendentRequest().then(x => x);
      const requestOptions = JSON.parse(localStorage.getItem('storage:request-options'));
      const praticatePrices = requestOptions.praticatePrices;
      const obj_prices = requestOptions.dataPrices;
      const printingOptions = requestOptions.dataPrintOptions;
      const dataSendOption = requestOptions.dataSendOption;
      var excededSMS = JSON.parse(localStorage.getItem('excededSms'));
      excededSMS = excededSMS ? excededSMS : {
        quantityAll: 0,
        data: []
      };
      var bindingTemplates = null;

      if (validateRequest.walletId && validateRequest.walletId != 'static') {
        //dados para visualizar e testar
        var reqId = localStorage.getItem('newServiceRequestId');
        var url = this.baseapi.apiLink(`Requests/${reqId}/bind-template`);
        bindingTemplates = await this.baseapi.getHttp(url).then(resp => resp.data);
      }

      return Ember.RSVP.hash({
        request: validateRequest,
        praticatePrices: praticatePrices,
        excededSMS: excededSMS,
        socketFirstConnection: true,
        bindingTemplates: bindingTemplates
      });
    }
  });

  _exports.default = _default;
});