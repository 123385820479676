define("printpost/controllers/manager", ["exports", "ember-parachute", "ember-concurrency"], function (_exports, _emberParachute, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  const queryParams = new _emberParachute.default({
    tags: {
      defaultValue: [],
      as: 'tags',
      refresh: true,

      serialize(value) {
        return value.toString();
      },

      deserialize(value = '') {
        return value.split(',');
      }

    },
    dates: {
      defaultValue: [{
        'title': 'Últimos 7 dias',
        'value': 604800
      }, {
        'title': 'Últimos 30 dias',
        'value': 18144000
      }],
      as: 'dates',

      serialize(value) {
        return value.toString();
      },

      deserialize(value = '') {
        return value.split(',');
      }

    }
  });
  _exports.queryParams = queryParams;

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    queryParamsChanged: Ember.computed.or('queryParamsState.{tags,dates}.changed'),
    newTagValue: '',

    setup({
      queryParams
    }) {
      if (queryParams.tags || queryParams.dates) {
        this.get('fetchModel').perform();
      }
    },

    fetchModel: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(1000);
    }).restartable(),

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      if (shouldRefresh && queryParams.dates) {
        this.get('fetchModel').perform();
      }
    },

    actions: {
      resetAll() {
        // reset all query params to their default values specified in the query param map
        this.resetQueryParams();
      },

      addTag() {
        this.get('tags').addObject(this.get('newTagValue'));
      },

      removeTag(tag) {
        this.get('tags').removeObject(tag);
      },

      myChangeAction(tag) {
        return tag; //console.log(tag, 'is selected')
      },

      changePeriodAction() {
        this.get('dates');
      }

    }
  });

  _exports.default = _default;
});