define("printpost/components/file-manager-wtp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    breadCrumb: Ember.computed(function () {
      return [];
    }),
    listFileManager: Ember.computed(function () {
      return [];
    }),

    init() {
      this._super(...arguments);

      var url = this.baseapi.apiLink("rawFiles/filemanager?path=/");
      this.baseapi.getHttp(url).then(resp => {
        this.set('listFileManager', resp.data);
      });
    },

    baseapi: Ember.inject.service(),
    session: Ember.inject.service('session'),
    actions: {
      download(file) {
        let url = this.baseapi.apiLink('rawFiles');
        let token = this.get('session.data.authenticated.id');
        url += '/download-id/' + file.id + '/1?access_token=' + token;
        console.log('url', url);
        this.baseapi.getDownload(url, file.name, false);
      },

      remove(file) {
        this.dataEvent(file);
      },

      selectItem(type, name, obj) {
        if (type && type == "folder") {
          name = name == "@raiz" ? '' : `${name}/`;
          var url = this.baseapi.apiLink(`rawFiles/filemanager?path=/${name}`);
          this.baseapi.getHttp(url).then(resp => {
            this.set('listFileManager', resp.data);
            let p = this.get('breadCrumb');
            let f = name.replace("/", "");

            if (f != '') {
              let i = p.indexOf(f);

              if (i < 0) {
                var k = [];

                for (let i = 0; i < p.length; i++) {
                  const element = p[i];
                  k[i] = element;
                }

                k[k.length] = f;
                this.set('breadCrumb', k);
              } else {
                var np = p.slice(0, i + 1);
                this.set('breadCrumb', np);
              }
            } else {
              this.set('breadCrumb', []);
            }
          });
        } else {
          this.actionSelectedImage(obj);
        }
      }

    }
  });

  _exports.default = _default;
});