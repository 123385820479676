define("printpost/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rUeeBe6g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"welcome-wrapper au-boxshadow row pt-10 pb-10 mt-10 mb-0 col-md-12\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-8 row\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"title-icon pt-10\"],[9],[7,\"span\"],[11,\"class\",\"drip-icon drip-home\"],[9],[10],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"fs-20 bold pt-20 pl-20\"],[9],[0,\"Olá \"],[1,[23,[\"model\",\"currentUser\",\"user\",\"firstName\"]],false],[0,\" bem vindo!\"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \\n        \"],[7,\"div\"],[11,\"class\",\"dashboard-button-right\"],[11,\"style\",\"right: 0;\"],[9],[0,\"\\n            \\n\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"index-circle\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"index-circle-icon\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/index.hbs"
    }
  });

  _exports.default = _default;
});