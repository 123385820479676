define("printpost/routes/operacao/processamento", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    model: async function () {
      let query = {
        filter: {
          where: {
            status: {
              in: ['Finalizado', 'Em Execução', 'Execução']
            },
            or: [{
              emailService: true
            }, {
              smsService: true
            }]
          },
          order: 'id DESC',
          limit: 20
        }
      };
      let url = this.baseapi.apiLink('OperationTasks/monit');
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      const requestList = await this.baseapi.getHttp(`${url}?${strquery}`).then(x => x.data);
      return Ember.RSVP.hash({
        'request': requestList.data,
        'pages': requestList.totalPages,
        'totals': requestList.total
      });
    }
  });

  _exports.default = _default;
});