define("printpost/helpers/object-dinamic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectDinamic = objectDinamic;
  _exports.default = void 0;

  function objectDinamic(params) {
    var obj = params[0];
    var key = params[1];
    return obj[key];
  }

  ;

  var _default = Ember.Helper.helper(objectDinamic);

  _exports.default = _default;
});