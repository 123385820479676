define("printpost/templates/components/pre-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VG/UI0c7",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[2,\" Preloader \"],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"type\"]],\"loading\"],null]],null,{\"statements\":[[7,\"div\"],[11,\"id\",\"preloader\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"circle\"],[9],[0,\"\\n        \"],[4,\"url-logo\",null,null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"img\"],[11,\"src\",\"/assets/images/loading/loading-2.gif\"],[11,\"style\",\"height: 150px;top: -60px;position: relative;\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"div\"],[11,\"id\",\"subpreloader\"],[11,\"class\",\"d-none\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"circle\"],[9],[0,\"\\n        \"],[4,\"url-logo\",null,null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"img\"],[11,\"src\",\"/assets/images/loading/loading-2.gif\"],[11,\"style\",\"height: 150px;top: -60px;position: relative;\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/pre-loader.hbs"
    }
  });

  _exports.default = _default;
});