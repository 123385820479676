define("printpost/storages/wallet-columns", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Storage = _object.default.extend();

  Storage.reopenClass({
    initialState() {
      return {
        name: '',
        data: [],
        dataSMS: [],
        dataEmail: []
      };
    }

  });
  var _default = Storage;
  _exports.default = _default;
});