define("printpost/templates/components/card-thumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LFDGmlEj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card-container mr-30\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"card \",[21,\"color\"]]]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"title-card\"],[9],[0,\"\\n            \"],[7,\"h2\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-arrow-right\"],[9],[10],[0,\"\\n        \"],[2,\" <p>a lonely trip.</p> \"],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"pic\"],[9],[10],[0,\"\\n        \"],[7,\"ul\"],[9],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n            \"],[7,\"li\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"button\"],[9],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/card-thumb.hbs"
    }
  });

  _exports.default = _default;
});