define("printpost/routes/centrodecusto/edit", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      id: null
    },
    baseapi: Ember.inject.service(),
    model: async function (queryParams) {
      // let id_param = getUrlParameters("id", "", true);
      var address = {
        list: [],
        main: null
      };
      var url = this.baseapi.apiLink('CostCenters/list');
      let item = {
        description: "",
        type: "juridica",
        cpf: "",
        cnpj: "",
        razaoSocial: "",
        inscricaoEstadual: "",
        username: "",
        userId: "",
        organizationId: "",
        options: {
          clickmassa: {
            active: 1,
            url: '',
            token: ''
          }
        }
      };
      let clickmassaConfiguration = [];

      if (queryParams && queryParams.id) {
        let query = {
          filter: {
            where: {
              id: queryParams.id
            },
            include: ['account', 'organization']
          }
        };
        let query_address = {
          filter: {
            where: {
              type: 'COSTCENTER_ADDRESS',
              costCenterId: queryParams.id
            }
          }
        };
        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        let strquery_address = (0, _globalFunctions.apiLinkQuery)(query_address);
        url += "?" + strquery;
        const obj = await this.baseapi.getHttp(url).then(r => r.data);
        item = obj.find(x => x.id == queryParams.id);
        let url_address = this.baseapi.apiLink(`Addresses/list?${strquery_address}`);
        address.list = await this.baseapi.getHttp(url_address).then(r => r.data);
        address.main = address.list.find(x => x.main == true);
      }

      return Ember.RSVP.hash({
        item: item,
        organizationId: item.organizationId,
        address: address
      });
    }
  });

  _exports.default = _default;
});