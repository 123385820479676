define("printpost/routes/reports/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dashboard: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    model: async function () {
      let url_tag = this.baseapi.apiLink('RequestTags/find?filter[where][typeConfig]=models_templates');
      const listCampaign = await this.baseapi.getHttp(url_tag).then(x => x.data);
      const objDate = new Date();
      const dateStart = `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-01T00:00:00-03:00`;
      const dateEnd = `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T23:59:00-03:00`;
      let url = this.baseapi.apiLink('Reports/dash-analytical');
      const data = await this.baseapi.getHttp(`${url}?filter[where][and][0][createdAt][gte]=${dateStart}&filter[where][and][1][createdAt][lte]=${dateEnd}`).then(x => x.data);
      const strDate = this.baseapi.getMonthStr(objDate.getMonth()) + " - " + objDate.getFullYear();
      var url_costcenter = this.baseapi.apiLink('CostCenters/list');
      const obj_costcenter = await this.baseapi.getHttp(url_costcenter).then(r => r.data);
      const graphOptions = {
        "responsive": true,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#ffffff"
          }
        },
        "scales": {
          "borderColor": "#ffffff",
          "tickColor": "#FFFFFF",
          "yAxes": [{
            "display": true,
            "ticks": {
              "beginAtZero": true,
              "zeroLineColor": "#FFFFFF",
              "fontColor": "#FFFFFF"
            },
            "gridLines": {
              "display": true,
              "zeroLineColor": "#294354",
              "color": "#294354"
            }
          }],
          "xAxes": [{
            "display": true,
            "gridLines": {
              "display": true
            },
            "ticks": {
              "beginAtZero": true,
              "zeroLineColor": "#FFFFFF",
              "fontColor": "#FFFFFF",
              "fontSize": 10
            }
          }]
        }
      };
      const linegraphOptions = {
        "responsive": true,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#000000"
          }
        },
        "scales": {
          "borderColor": "#ffffff",
          "tickColor": "#FFFFFF",
          "yAxes": [{
            "display": true,
            "ticks": {
              "beginAtZero": true,
              "zeroLineColor": "#000000",
              "fontColor": "#000000"
            },
            "gridLines": {
              "display": true,
              "zeroLineColor": "#294354",
              "color": "#294354"
            }
          }],
          "xAxes": [{
            "display": true,
            "gridLines": {
              "display": true
            },
            "ticks": {
              "beginAtZero": true,
              "zeroLineColor": "#000000",
              "fontColor": "#000000",
              "fontSize": 10
            }
          }]
        }
      };
      const piegraphOptions = {
        "responsive": true,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#000"
          }
        },
        "scales": {
          "yAxes": [{
            "display": false,
            "ticks": {
              "beginAtZero": false
            },
            "gridLines": {
              "display": false
            }
          }],
          "xAxes": [{
            "display": false,
            "gridLines": {
              "display": false
            }
          }]
        }
      };
      return Ember.RSVP.hash({
        format: 3,
        dataWallets: listCampaign,
        dataDateFilterStr: strDate,
        dataDateFilter: objDate,
        barSendGraph: data.barSendGraph,
        daySendGraph: data.daySendGraph.chartjs.graph,
        daySendGraph: data.daySendGraph.chartjs.graph,
        pieSendGraph: data.pieSendGraph.chartjs.graph,
        cards: data.cards,
        graphOptions: graphOptions,
        linegraphOptions: linegraphOptions,
        piegraphOptions: piegraphOptions,
        obj_costcenter: obj_costcenter,
        journeyData: null
      });
    }
  });

  _exports.default = _default;
});