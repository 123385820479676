define("printpost/controllers/services/sms", ["exports", "printpost/controllers/services", "ember-concurrency", "printpost/config/environment", "printpost/utils/globalFunctions"], function (_exports, _services, _emberConcurrency, _environment, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DOMTinymce = [];

  var _default = _services.default.extend({
    queryParams: Ember.computed(function () {
      return ['product', 'wallet'];
    }),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    session: Ember.inject.service(),
    test: Ember.inject.service('test-service'),
    dataUser: Ember.computed('dataUser', function () {
      // let d = this.baseapi.getCurrentUser();
      const d = this.get('session.data.authenticated');
      return d.account;
    }).property().volatile(),
    isShowingTranslucent: Ember.computed(function () {
      return false;
    }),
    smsQtdCaracter: Ember.computed(function () {
      return 160;
    }),
    smsQtd: Ember.computed(function () {
      return 1;
    }),
    smsQtdText: Ember.computed(function () {
      return "";
    }).property().volatile(),
    smsText: Ember.computed(function () {
      return '';
    }),

    //mceu_7 ==> id do button de tags
    init() {
      this._super(...arguments);
    },

    textTinymce: Ember.computed(function () {
      setTimeout(() => {
        let iframe_edit = Ember.$(DOMTinymce[0].getContainer()).find('iframe');
        let document_body = iframe_edit.contents().find("body");
        Ember.$(document_body).attr('style', 'font-size: 14pt; padding: 5px 5px 0px 20px');
      }, 1000);
      return {
        dados: '',
        options: {
          selector: 'textarea',
          // change this value according to your html
          min_height: 1000,
          min_width: 500,
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14pt; padding: 5px 5px 0px 20px }",
          menubar: false,
          //theme: 'modern', 
          body_class: 'iframe-sms-template',
          plugins: 'code template link',
          // note the comma at the end of the line!
          default_link_target: '_blank',
          toolbar: 'undo redo | link | cut copy paste selectall | code template ',
          templates: [_environment.default.APP.API.host, _environment.default.APP.API.namespace, 'Wallets/find-tags/'].join('/') + JSON.parse(localStorage.getItem('storage:request')).walletId + '?requestId=' + localStorage.getItem('newServiceRequestId') + '&access_token=' + JSON.parse(localStorage.getItem('ember_simple_auth-session')).authenticated.id,
          setup: function (ed) {
            ed.on('init', function () {
              //var c = $(ed.getContainer()); // this would be the text area
              let iframe = Ember.$(ed.getContainer()).find('iframe');
              iframe.addClass('iframe-sms-template');
            });
            DOMTinymce.push(ed);
          }
        }
      };
    }).property().volatile(),
    baseapi: Ember.inject.service(),
    dataFilter: Ember.computed('filter', function () {
      return [];
    }),
    dataProducts: Ember.computed('model.products', function () {
      var model = this.get('model.products');
      return model;
    }),
    dataTemplates: Ember.computed('model.templates', function () {
      var model = this.get('model.templates');
      return model;
    }),
    dataChannel: Ember.computed('model.channel', function () {
      var model = this.get('model.channel');
      return model;
    }),
    dataHtml: Ember.computed('dataHtml', function () {
      return {
        frontHTML: '',
        backHTML: ''
      };
    }),
    dataTemplate: Ember.computed('dataTemplate', function () {
      return {};
    }),
    reloadTemplate: Ember.computed('reloadTemplate', function () {
      return true;
    }),
    showModal: Ember.computed(function () {
      return false;
    }),
    steps_load: Ember.computed(function () {
      return [{
        element: document.getElementById('step1'),
        intro: 'Clicando neste ícone, você poderá escolher qual informação do seu arquivo deverá ser preenchida no template <br><br> Ex: o campo {{ nome }}, será substituído pela informação ANA MARIA SANTOS',
        highlightClass: 'intro-showitem'
      }, {
        element: document.getElementById('step3'),
        intro: 'Esta é a área de edição do seu template, nela você pode escrever e editar o texto a vontade! <br> <br> <b>Caso deseje acessar novamente, basta clicar no botão Ajuda</b>',
        highlightClass: 'intro-showitem'
      }];
    }),
    intro_exec: Ember.computed(function () {
      return 1;
    }),
    tagsControl: Ember.computed(function () {
      let data = [];
      let wd = Ember.get(this, 'walletColumns.data');
      wd.forEach(element => {
        let item = {
          title: element,
          content: "{{ " + element + " }}"
        };
        data.push(item);
      });
      return data;
    }),
    checkTemplateTags: function () {
      if (this.tagsControl) {
        this.tagsControl.forEach((val, index) => {
          if (Ember.get(this, 'templateSMS.frontHTML').indexOf(val.content) != -1) {
            Ember.$('#' + index + "_front").addClass('taghaving-active');
          } else {
            Ember.$('#' + index + "_front").removeClass('taghaving-active');
          }
        });
      }
    },
    templateSMS: Ember.computed(function () {
      let t = Ember.get(this, 'newRequest.printingOptions.templateSMS');
      return t;
    }),
    columns_wallet: Ember.computed(function () {
      return [];
    }),
    columns_sms: Ember.computed(function () {
      let data = Ember.get(this, 'walletColumns.dataSMS');
      Ember.set(this.newRequest, 'columnsSelectSMS', data);
      return data;
    }).property().volatile(),
    columns_selected: Ember.computed(function () {
      let data = Ember.get(this, 'walletColumns.dataSMS');
      return data;
    }),
    templates: Ember.computed(function () {
      return [];
    }),
    htmlTemplate: Ember.computed(function () {
      return {
        frente: '<h1 style="text-align: center;">FRENTE</h1>',
        verso: '<h1 style="text-align: center;">VERSO</h1>'
      };
    }),
    templateSelected: Ember.computed(function () {
      return null;
    }),
    filterModels: Ember.computed('model.products', function () {
      return this.get('model.products');
    }),
    findTemplate: (0, _emberConcurrency.task)(function* (filter_query) {
      return yield this.get('store').query('template', {
        filter: filter_query
      }); // return request;    
    }).keepLatest(),
    requestPrintingOption: Ember.computed(function () {
      let npo = Ember.get(this, 'newRequest.printingOptions');
      return npo;
    }),
    selectTemplate: (0, _emberConcurrency.task)(function* () {
      // yield set(this.newRequest,'smsTemplateId', this.templateSMS.templateId);
      // let npo = get(this, 'newRequest.printingOptions');
      yield Ember.set(this.newPrintingOptions, 'templateSMS', this.templateSMS); // yield set(npo, 'templateSMS', this.templateSMS);
      // set(this.newRequest,'printingOptions', npo);
    }).keepLatest(),
    trasitionTask: (0, _emberConcurrency.task)(function* () {
      let emailService = this.get('newRequest.emailService');
      let nextWallet = this.get('newRequest.walletId');
      let nr = this.get('newRequest.typeConfig');

      if (nextWallet != 'static') {
        let reqId = localStorage.getItem('newServiceRequestId');
        let url_wallet = this.baseapi.apiLink('Wallets/find-tags');
        const objReturn = yield this.baseapi.getHttp(`${url_wallet}/${nextWallet}?requestId=${reqId}`).then(r => r.data);
        var tags = [];

        for (let j = 0; j < objReturn.length; j++) {
          const column = objReturn[j];
          var obj = {
            value: `[${column.title}]`,
            text: column.title,
            label: column.title
          };
          tags.push(obj);
        }

        Ember.set(this, 'storageTemplateSelected.tags', tags);
      }

      let t = Ember.get(this, 'getStorageRequestTask').perform('frontHTML');
      t = t.iterator.replace("<p>", "").replace("</p>", "");
      t = (0, _globalFunctions.decodeHTML)(t, false, false);
      this.changeStorageRequestFunc('frontHTML', t); // this.send('salvarPendente');

      if (emailService) {
        yield this.transitionToRoute('services.email', {
          queryParams: {
            wallet: nextWallet
          }
        }); // } else if(nr != "file_layout"){
        //   yield this.transitionToRoute('services.test', { queryParams: { wallet: nextWallet}});
      } else {
        yield this.transitionToRoute('services.payment', {
          queryParams: {
            wallet: nextWallet
          }
        });
      }
    }).maxConcurrency(3).enqueue(),
    flatMinDate: Ember.computed('flatMinDate', function () {
      return new Date().setMinutes(new Date().getMinutes() + 20);
    }),
    flatMaxDate: Ember.computed('flatMaxDate', function () {
      return new Date().setMonth(new Date().getMonth() + 2, 1);
    }),
    myFlatpickrRef: Ember.computed(function () {
      return null;
    }),
    changeStorageRequestFunc: function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions.templateSMS[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.printingOptions.templateSMS.' + chave, valor);
    },
    changeStorageRequestTask: (0, _emberConcurrency.task)(function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions.templateSMS[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.printingOptions.templateSMS.' + chave, valor);
    }).keepLatest(),
    getStorageRequestTask: (0, _emberConcurrency.task)(function (chave) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      return _newReq.printingOptions.templateSMS[chave];
    }),
    nextRouterTask: (0, _emberConcurrency.task)(function* () {
      let nr = Ember.get(this, 'newRequest.typeConfig');
      let nextWallet = this.get('newRequest.walletId');

      try {
        this.preloader.toggleClass('d-none'); // yield this.baseapi.savePendentRequest();

        this.preloader.toggleClass('d-none');
      } catch (error) {
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao salvar a etapa do pedido', {
          positionClass: 'toast-bottom-right'
        });
      }

      if (nr == 'pdf_fechado') {
        this.transitionToRoute('services.data');
      } else if (nr == 'models_templates' || nr == 'file_layout') {
        //route models #2
        let npo = Ember.get(this, 'newRequest.printingOptions');
        let step = Ember.get(this, 'newRequest.step');
        let email = Ember.get(this, 'newRequest.emailService');
        let letter = Ember.get(this, 'newRequest.letterService');

        if (email) {
          this.transitionToRoute('services.email', {
            queryParams: {
              wallet: nextWallet
            }
          });
        } else if (letter) {
          this.transitionToRoute('services.letter', {
            queryParams: {
              wallet: nextWallet
            }
          });
        } else {
          if (nextWallet == "static") {
            this.transitionToRoute('services.data'); // set(this, 'newRequest.step', 2);     
          } else if (npo.templateSMS.templateId != "construir_do_zero") {
            Ember.set(this, 'newRequest.step', 2);
            this.transitionToRoute('services.payment');
          }
        }
      } else if (nr == 'creation') {
        // model creation
        this.transitionToRoute('services.data');
      }
    }),
    actions: {
      selectTemplateStep2: function (json_data) {
        var order = this.test.verifySendOrder(); // let dataRequest = JSON.parse(localStorage.getItem('storage:request'));
        // set(this, 'newRequest.printingOptions', dataRequest.printingOptions);
        // this.send('salvarPendente');

        Ember.get(this, 'selectTemplate').perform(json_data).then(() => {
          this.toast.success('', 'Template selecionada com sucesso!', {
            positionClass: 'toast-bottom-right'
          });
          Ember.get(this, 'trasitionTask').perform();
        });
      },
      doRefresh: function () {
        window.location.reload(true);
      },
      setProdSelect: function () {
        let id_prod = this.baseapi.getFieldSelectValue("product_selection");
        Ember.$('#selection-id').val(id_prod);
        Ember.get(this, 'changeStorageRequestTask').perform('productId', id_prod);
        Ember.set(this, 'templateSMS.productId', id_prod);
        Ember.$("#next-modalidade").removeAttr('disabled');
      },
      setTemplate: function (id_template) {
        if (id_template.length > 1) {
          Ember.$("#next-template").removeAttr('disabled');
        } else {
          Ember.$("#next-template").attr('disabled', 'disabled');
        }

        Ember.get(this, 'changeStorageRequestTask').perform('templateId', id_template);
      },
      setColumnSMS: function (value) {
        let cols = Ember.get(this, 'newRequest.columnsSelectSMS');

        if (cols.find(r => r == value) == undefined) {
          cols.push(value);
        } else {
          let new_cols = [];
          cols.forEach(elem => {
            if (elem != value) {
              new_cols.push(elem);
            }
          });
          cols = new_cols;
        }

        Ember.set(this, 'columns_selected', cols);
        Ember.set(this.newRequest, 'columnsSelectSMS', cols);
      },

      setShowModal(isHelp = false) {
        this.taskSetShowModal(isHelp);
        this.checkTemplateTags();
      },

      disableItensIntro() {
        Ember.set(this, 'showModal', false);
        let itens = Ember.get(this, 'steps_load');
        let iframe_edit = Ember.$(DOMTinymce[0].getContainer()).find('iframe');
        let document_body = iframe_edit.contents().find("body");
        Ember.$(document_body).attr('style', 'font-size: 14pt; padding: 5px 5px 0px 20px');
        itens.forEach(val => {
          let item = val.element;
          item.style.display = "none";
        });
        this.checkTemplateTags();

        function stripHTML(html) {
          var tmp = document.implementation.createHTMLDocument("New").body;
          tmp.innerHTML = html;
          return tmp.textContent || tmp.innerText || "";
        }

        let qtdCharacter = Ember.get(this, 'smsQtdCaracter');
        let t = Ember.get(this, 'getStorageRequestTask').perform('frontHTML');
        let text = stripHTML(t.iterator);
        let l = Math.ceil(text.length / qtdCharacter);
        Ember.set(this, 'smsText', text);
        Ember.set(this, 'smsQtd', l);
        Ember.set(this, 'smsQtdText', "A sua mensagem ultrapassou 160 caracteres e será cobrado por " + l + " envios a cada número de telefone");
      },

      setTinymceValueFront(value) {
        Ember.set(this, 'templateSMS.frontHTML', value); // get(this, 'changeStorageRequestTask').perform('frontHTML', value);

        this.changeStorageRequestFunc('frontHTML', value);

        function stripHTML(html) {
          var tmp = document.implementation.createHTMLDocument("New").body;
          tmp.innerHTML = html;
          return tmp.textContent || tmp.innerText || "";
        }

        let qtdCharacter = Ember.get(this, 'smsQtdCaracter');
        let t = Ember.get(this, 'templateSMS.frontHTML');
        let text = stripHTML(t);
        let l = Math.ceil(text.length / qtdCharacter);
        Ember.set(this, 'smsText', text);
        Ember.set(this, 'smsQtd', l);
        Ember.set(this, 'smsQtdText', "A sua mensagem ultrapassou 160 caracteres e será cobrado por " + l + " envios a cada número de telefone");
        this.checkTemplateTags();
      },

      nextClick(itemid) {
        document.getElementById(itemid).click();
      },

      changeStorageRequest(chave, valor) {
        Ember.get(this, 'changeStorageRequestTask').perform(chave, valor);
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });
        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = day + "/" + month + "/" + year + " - " + hour + ":" + minute;
        Ember.get(this, 'changeStorageRequestTask').perform('dataDisparo', strDate);
      },

      changeCheckbox(item, tipo, val) {
        let df = Ember.get(this, 'dataFilter');

        if (val) {
          let o = {};
          o[tipo] = item;
          df.push(o);
        } else {
          for (let i = 0; i < df.length; i++) {
            const element = df[i];

            if (element[tipo] == item) {
              df.splice(i, 1);
            }
          }
        }

        Ember.set(this, 'dataFilter', df);
      },

      filterTemplate: async function () {
        let r = Ember.get(this, 'dataFilter');
        let channel = Ember.get(this, 'dataChannel');
        let query = {
          filter: {
            where: {
              "channelId": channel.id
            },
            include: 'channel'
          }
        };

        if (r.length >= 1) {
          query.filter.where.or = [];
          r.forEach(item => {
            if (item.productId) {
              query.filter.where.or.push({
                "productId": item.productId
              });
            }
          });
        }

        let strquery = (0, _globalFunctions.apiLinkQuery)(query);
        var url_templates = this.baseapi.apiLink('Templates/list');
        const obj_templates = await this.baseapi.getHttp(url_templates + "?" + strquery).then(r => r.data);
        Ember.set(this, 'dataTemplates', obj_templates);
      },
      selectTemplate: async function (id) {
        if (id) {
          if (id == "construir_do_zero") {
            Ember.set(this, 'dataHtml.frontHTML', '');
            Ember.set(this, 'dataHtml.backHTML', '');
            this.changeStorageRequestFunc('templateId', 'construir_do_zero');
            this.send('nextRouter');
            return;
          }

          Ember.set(this, 'reloadTemplate', false);
          let query = {
            filter: {
              where: {
                "id": id
              },
              fields: {
                name: false
              }
            }
          };
          let strquery = (0, _globalFunctions.apiLinkQuery)(query);
          var url_templates = this.baseapi.apiLink('Templates/list');
          const obj = await this.baseapi.getHttp(url_templates + "?" + strquery).then(r => r.data[0]);
          Ember.set(this, 'dataTemplate', obj);
          Ember.set(this, 'dataHtml.frontHTML', obj.internal);
          Ember.set(this, 'dataHtml.backHTML', obj.externalHtml);
          Ember.set(this, 'reloadTemplate', true);
        }
      },
      defineTemplate: function () {
        let t = Ember.get(this, 'dataTemplate');
        this.changeStorageRequestFunc('frontHTML', t.internal);
        this.changeStorageRequestFunc('backHTML', t.externalHtml);
        this.changeStorageRequestFunc('disparoImediato', true);
        this.changeStorageRequestFunc('productId', t.productId);
        this.changeStorageRequestFunc('templateId', t.id);
        this.send('nextRouter');
      },
      nextRouter: function () {
        Ember.get(this, 'nextRouterTask').perform();
      },
      checkTemplateTag: function () {
        let t = Ember.get(this, 'tagsControl');

        if (t) {
          t.forEach((val, index) => {
            if (Ember.get(this, 'templateSMS.frontHTML').indexOf(val.content) != -1) {
              Ember.$('#' + index + "_front").addClass('taghaving-active');
            } else {
              Ember.$('#' + index + "_front").removeClass('taghaving-active');
            }
          });
        }
      },

      backSelectTemplate() {
        Ember.set(this, 'newRequest.step', 1); // this.transitionToRoute('services.config');
      },

      setViewTutorial(link) {
        Ember.set(this, 'urlVideoTutorial', link);
      },

      selectAndDefineTemplate: async function (template) {
        if (template) {
          if (template == "construir_do_zero") {
            Ember.set(this, 'dataHtml.frontHTML', '');
            Ember.set(this, 'dataHtml.backHTML', '');
            this.changeStorageRequestFunc('templateId', 'construir_do_zero');
            this.send('nextRouter');
            return;
          }

          this.changeStorageRequestFunc('frontHTML', template.internal);
          this.changeStorageRequestFunc('backHTML', template.externalHtml);
          this.changeStorageRequestFunc('disparoImediato', true);
          this.changeStorageRequestFunc('productId', template.productId);
          this.changeStorageRequestFunc('templateId', template.id);
          this.changeStorageRequestFunc('editorMode', 'sms');
          this.send('nextRouter'); // console.log('depois after route');
        }
      }
    }
  });

  _exports.default = _default;
});