define("printpost/controllers/services", ["exports", "ember-concurrency", "ember-local-storage"], function (_exports, _emberConcurrency, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const newPrintingOptions = Ember.Object.extend({
    type: 'carta',
    size: 'A4',
    grammage: '75g',
    colored: true,
    papper: 'couche_fosco',
    PC: {
      padrao: null
    },
    templateLetter: null,
    templateSMS: null,
    templateEmail: null,
    requestId: null,
    reload: false
  });

  var _default = Ember.Controller.extend({
    loadPlugin: function () {// Use run loop if you need to setup the DOM first
      // scheduleOnce('afterRender', this, function () {
      //   $.getScript('https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js');
      // });
    }.on('init'),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    notification: Ember.inject.service('toast'),
    checkoutCode: null,
    isOpenLightbox: false,
    newRequest: (0, _emberLocalStorage.storageFor)('request'),
    templateZero: (0, _emberLocalStorage.storageFor)('template-zero'),
    storageTemplateSelected: (0, _emberLocalStorage.storageFor)('template-selected'),
    // newPrintingOptions : storageFor('print-options'), 
    newPrintingOptions: newPrintingOptions.create(),
    modelEditor: Ember.computed('model.request.value.[]', function () {
      return this.get('model.request.value');
    }),
    showBreadcrump: Ember.computed(function () {
      return true;
    }).property().volatile(),
    forceWallet: (0, _emberLocalStorage.storageFor)('force-wallet'),
    walletColumns: (0, _emberLocalStorage.storageFor)('wallet-columns'),
    requestOptions: (0, _emberLocalStorage.storageFor)('request-options'),
    actions: {
      // createRequest: function (newRequest) {
      //   get(this, 'createRequestTask').perform(newRequest);
      // },
      salvarPendente: function () {
        let requestId = localStorage.getItem('newServiceRequestId');
        requestId = requestId == undefined ? 0 : requestId;

        let __sNewRequest = JSON.parse(localStorage.getItem('storage:request'));

        __sNewRequest.status = "Pendente";
        let __request = {
          data: {
            type: "Request",
            attributes: __sNewRequest
          }
        };

        if (__sNewRequest.title != null && __sNewRequest.shippingMethod && (__sNewRequest.letterService || __sNewRequest.emailService || __sNewRequest.smsService)) {
          if (requestId == 0) {
            let url = this.baseapi.apiLink('Requests');
            let call = this.baseapi.post(url, __request);
            call.then(resp => {
              // this.transitionToRoute('dashboard');
              // requestElem.value = resp.data.data.id;
              localStorage.setItem('newServiceRequestId', resp.data.data.id);
            }, error => {
              console.log(error);
            });
          } else {
            let url = this.baseapi.apiLink('Requests/' + requestId);
            let __rp = __request;
            __rp.data.id = requestId;
            let call = this.baseapi.patch(url, __rp);
            call.then(resp => {
              if (resp.data.data.attributes.smsService) {
                let qtdExcededSMS = 0;
                resp.data.data.attributes.praticatePrices.sms.smsExceedCharacters.forEach(element => {
                  qtdExcededSMS += element.chargedFor;
                });
                let obj = {
                  quantityAll: qtdExcededSMS,
                  data: resp.data.data.attributes.praticatePrices.sms.smsExceedCharacters
                };
                localStorage.setItem('excededSms', JSON.stringify(obj));
              }

              var reqOptions = JSON.parse(localStorage.getItem('storage:request-options'));
              reqOptions.praticatePrices = resp.data.data.attributes.praticatePrices;
              localStorage.setItem('storage:request-options', JSON.stringify(reqOptions));
            }, error => {
              console.log(error);
            });
          } // localStorage.removeItem('storage:request');


          Ember.$("#btn-new-req-exit").click();
          document.getElementById('exibition_show').value = 1;
        }
      },
      serviceNextRouter: function () {
        let letterService = this.get('newRequest.letterService');
        let smsService = this.get('newRequest.smsService');
        let emailService = this.get('newRequest.emailService');
        let nextWallet = this.get('newRequest.walletId');

        let _newReq = JSON.parse(localStorage.getItem('storage:request'));

        _newReq.walletId = nextWallet;
        localStorage.setItem('storage:request', JSON.stringify(_newReq));
        let nr = _newReq.typeConfig;
        let step = _newReq.step;
        let npo = Ember.get(this, 'newRequest.printingOptions');
        let sms = (Ember.get(npo, 'templateSMS.templateId') == null || Ember.get(npo, 'templateSMS.templateId') == "construir_do_zero") && Ember.get(this, 'newRequest.smsService') ? true : false;
        let email = (Ember.get(npo, 'templateSMS.templateId') == null || Ember.get(npo, 'templateSMS.templateId') == "construir_do_zero") && Ember.get(this, 'newRequest.emailService') ? true : false;
        console.log('enter aqui');

        if (step == 1) {
          if (!letterService) {
            if (smsService) {
              this.transitionToRoute('services.sms', {
                queryParams: {
                  wallet: nextWallet
                }
              });
            } else if (emailService) {
              this.transitionToRoute('services.email', {
                queryParams: {
                  wallet: nextWallet
                }
              });
            }
          } else {
            this.transitionToRoute('services.config', {
              queryParams: {
                wallet: nextWallet
              }
            });
          }
        } else {
          if (Ember.get(this, 'newRequest.walletId') == "static") {
            this.transitionToRoute('services.data');
          } else if (sms) {
            this.transitionToRoute('services.sms', {
              queryParams: {
                wallet: nextWallet
              }
            });
          } else if (email) {
            this.transitionToRoute('services.letter', {
              queryParams: {
                wallet: nextWallet
              }
            });
          } else {
            this.transitionToRoute('services.payment');
          }
        } // if (letterService && (nextWallet != 'static' || nr == "file_layout")) {
        //   // set(this, 'storageTemplateSelected.editor_mode', 'carta');
        //   this.transitionToRoute('services.letter', { queryParams: { wallet: nextWallet } });
        // } else if (smsService) {
        //   if ((nextWallet != 'static' || nr == "file_layout") && _newReq.printingOptions.templateSMS.templateId == "construir_do_zero") {
        //     this.transitionToRoute('services.sms', { queryParams: { wallet: nextWallet } });
        //   } else {
        //     this.transitionToRoute('services.payment');
        //   }
        // } else if (emailService && (nextWallet != 'static' || nr == "file_layout")) {
        //   set(this, 'storageTemplateSelected.editor_mode', 'email');
        //   this.transitionToRoute('services.email', { queryParams: { wallet: nextWallet } });
        // } else {
        //   // this.transitionToRoute('services.test', { queryParams: { wallet: nextWallet}});
        //   this.transitionToRoute('services.payment', { queryParams: { wallet: nextWallet } });
        // }

      }
    }
  });

  _exports.default = _default;
});