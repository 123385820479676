define("printpost/components/whitelabel-banner", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    baseapi: Ember.inject.service(),
    widthValue: null,
    image: '',
    title: '',
    favicon: '',
    message: '',

    // timer
    // Periodically refresh product info
    init() {
      this.get('taskFilterReturn').perform();

      this._super(...arguments);
    },

    taskFilterReturn: (0, _emberConcurrency.task)(function* () {
      var currentUrl = window.location.href;
      var urlMain = currentUrl.replace('://', '@').split('/')[0].replace('@', '://');
      let url = this.baseapi.apiLink('WhiteLabels/banner');
      const banner = yield this.baseapi.post(url, {
        url: urlMain
      }).then(x => x.data);
      this.set('image', banner.banner);
      this.set('favicon', banner.favicon);
      this.set('title', banner.title);
      this.set('message', banner.message);
    })
  });

  _exports.default = _default;
});