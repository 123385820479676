define("printpost/routes/profile/settings", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function () {
      return {
        'user': this.get('getAuthenticated').perform()
      };
    },
    getAuthenticated: (0, _emberConcurrency.task)(function* () {
      let request = yield this.get('session.data.authenticated');
      return request;
    }).keepLatest()
  });

  _exports.default = _default;
});