define("printpost/components/file-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service('session'),
    actions: {
      download(file) {
        let url = this.baseapi.apiLink('rawFiles');
        let token = this.get('session.data.authenticated.id');
        url += '/download-id/' + file.id + '/1?access_token=' + token;
        console.log('url', url);
        this.baseapi.getDownload(url, file.name, false);
      },

      remove(file) {
        this.dataEvent(file);
      }

    }
  });

  _exports.default = _default;
});