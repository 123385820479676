define("printpost/templates/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YLH47NAq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"headline\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"welcome-wrapper\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"title-icon\"],[9],[7,\"span\"],[11,\"class\",\"drip-icon drip-user\"],[9],[10],[10],[0,\"\\n            \"],[7,\"h2\"],[9],[1,[23,[\"currentUserModel\",\"username\"]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"content-bg\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"request-container\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\n                \"],[7,\"div\"],[11,\"class\",\"col-2\"],[9],[0,\"\\n                    \"],[7,\"div\"],[11,\"class\",\"sidebar-menus profile\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"profile.settings\"],[[\"tagName\",\"class\"],[\"li\",\"menu-header\"]],{\"statements\":[[0,\"                        \"],[7,\"a\"],[11,\"href\",\"\"],[11,\"class\",\"menu-title\"],[9],[7,\"span\"],[11,\"class\",\"icon\"],[9],[10],[0,\"Conta de usuário\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"                    \"],[10],[0,\"\\n                \"],[10],[0,\"\\n\\n                \"],[7,\"div\"],[11,\"class\",\"col-10 left-bordered\"],[9],[0,\"\\n                    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n                        \"],[1,[21,\"outlet\"],false],[0,\"\\n                    \"],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/profile.hbs"
    }
  });

  _exports.default = _default;
});