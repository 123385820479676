define("printpost/templates/components/simple-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5zDf5wzz",
    "block": "{\"symbols\":[\"n\",\"idx\"],\"statements\":[[7,\"div\"],[11,\"class\",\"content_detail__pagination cdp\"],[12,\"actpage\",[28,[[21,\"page\"]]]],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"cdp_i\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"listEventLt\"],null]],[9],[0,\"<<\"],[10],[0,\"\\n\"],[4,\"each\",[[27,\"repeat\",[[23,[\"totalPages\"]],\"p\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"listEvent\",[22,2,[]]],null]],[11,\"class\",\"cdp_i\"],[9],[1,[27,\"sum\",[[22,2,[]],1],null],false],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\\t\"],[7,\"div\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"listEventGt\"],null]],[11,\"class\",\"cdp_i\"],[9],[0,\">>\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/simple-pagination.hbs"
    }
  });

  _exports.default = _default;
});