define("printpost/components/notification-dropdown", ["exports", "printpost/models/_choices/notification"], function (_exports, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { runInThisContext } from 'vm';
  var _default = Ember.Component.extend({
    timer: Ember.inject.service(),
    interval: Ember.computed(function () {
      return 30 * 1000;
    }),
    userNotifications: Ember.inject.service(),
    title: "",
    classNames: ['notification-dropdown'],
    notifications: Ember.A(),
    counters: Ember.computed(function () {
      return {
        total: 0,
        newer: 0
      };
    }),
    refreshCounter: function () {// Todo: Activate refresh counter
      //this.set('counters.total', this.get('notifications').get('length'));
      //this.set('counters.newer', this.get('notifications').filterBy('state','unread').get('length'));
    },

    didInsertElement() {
      this._super.call(this);

      this.$('button').popup({
        lastResort: 'bottom right',
        position: 'bottom right',
        popup: this.$('.popup'),
        on: 'click'
      });
      this.refreshCounter();
    },

    // timer
    // Periodically refresh product info
    init() {
      this.get('timer').start(() => {
        if (this.get('userNotifications')) {
          this.get('userNotifications').reload();
          this.refreshCounter();
        }
      }, this.get('interval'));

      this._super(...arguments);
    },

    actions: {
      clickedOn: function (notification) {
        if (notification.get('state') === _notification.default.state.unread) {
          notification.set('state', _notification.default.state.readed);
          notification.save();
          this.refreshCounter();
        }
      },

      cancelReloadTask() {
        this.get('timer').stop();
      },

      restartReloadTask() {
        this.get('timer').start(() => {//
        });
      }

    },

    willDestroyElement() {
      this._super.call(this);

      this.$('.button').popup('destroy');
      this.get('timer').stop();
    }

  });

  _exports.default = _default;
});