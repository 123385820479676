define("printpost/templates/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0NtsAqwo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"\"],[12,\"style\",[28,[\"width: \",[21,\"width\"],\";\"]]],[9],[0,\"    \\n  \"],[7,\"div\"],[12,\"class\",[28,[\"progress2 \",[27,\"if\",[[23,[\"moved\"]],\"progress-moved\",\"\"],null]]]],[11,\"style\",\"display: flex;\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"ml-20 \"],[11,\"style\",\"font-size: 13px;\"],[9],[0,\"\\n        \"],[1,[21,\"label\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"mode\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[[21,\"classBar\"]]]],[12,\"style\",[28,[\"width: \",[21,\"width\"],\"; animation: \",[21,\"funcName\"],\" 1.5s infinite;\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"styleAttr\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[[21,\"classBar\"]]]],[12,\"style\",[21,\"styleAttr\"]],[9],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\" \\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/progress-bar.hbs"
    }
  });

  _exports.default = _default;
});