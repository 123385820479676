define("printpost/controllers/services/data", ["exports", "printpost/controllers/services", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _services, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import config from 'printpost/config/environment';
  const newWallet = Ember.Object.extend({
    name: null,
    newFile: null,
    description: null,
    separator: ';'
  });

  var _default = _services.default.extend({
    baseapi: Ember.inject.service(),
    debugger: Ember.inject.service(),
    session: Ember.inject.service(),
    file: Ember.computed('file', function () {
      let m = null;
      return m;
    }),
    dataUser: Ember.computed('dataUser', function () {
      // let d = this.baseapi.getCurrentUser();
      const d = this.get('session.data.authenticated');
      return d.account;
    }).property().volatile(),
    adminValue: Ember.computed('adminValue', function () {
      let s = this.baseapi.getAdmValue();
      return s;
    }),
    organizationId: Ember.computed('organizationId', function () {
      return '';
    }),
    isCreateLoading: Ember.computed('isCreateLoading', function () {
      let m = false;
      return m;
    }),
    isChangeRawFiles: Ember.computed('isChangeRawFiles', function () {
      let m = false;
      return m;
    }),
    isCreateWallet: Ember.computed('isCreateWallet', function () {
      let m = false;
      return m;
    }),
    finishSaveWallet: Ember.computed('finishSaveWallet', function () {
      let m = true;
      return m;
    }),
    newFile: Ember.computed('newFile', function () {
      let m = null;
      return m;
    }),
    tableColumns: Ember.computed('tableColumns', function () {
      return [];
    }),
    dataAproveWallet: Ember.computed('dataAproveWallet', function () {
      return [];
    }),
    dataQtd: Ember.computed('dataQtd', function () {
      let m = {
        emailCount: '-',
        addressCount: '-',
        phoneCount: '-'
      };
      return m;
    }).property().volatile(),
    dataWalletColumns: Ember.computed(function () {
      let m = {
        data: [],
        dataEmail: [],
        dataSMS: []
      };
      return m;
    }),
    tipoDados: Ember.computed('tipoDados', function () {
      let m = 'excel';
      return m;
    }),
    newWallet: newWallet.create(),

    init() {
      this._super(...arguments); //SEARCH


      this.filterTableAproveWallet = this.filterTableAproveWallet || ['attributes.nome', 'attributes.bairro', 'attributes.cpf', 'attributes.cidade', 'attributes.cep', 'attributes.estado'];
    },

    //SEARCH
    wallets: Ember.computed('model.w', function () {
      let r = Ember.get(this, 'model.wallet');
      return r;
    }),
    walletsNew: Ember.computed('wNew', function () {
      return [];
    }),
    isWalletsNew: Ember.computed('isWNew', function () {
      return false;
    }),
    testeFunc: (0, _emberConcurrency.task)(function () {
      this.baseapi.getCurrentUser(); // let url = this.baseapi.apiLink('Wallets?filter[where][userId]=5b5155faf63c61ead3369100');
      // var call = this.baseapi.getHttp(url);  
    }),
    reloadWallets: (0, _emberConcurrency.task)(async function () {
      // const USER = this.get('session.data.authenticated');
      // return yield this.get('store').query('wallet', {
      //   filter: {
      //     where: {
      //       'userId': USER.userId,
      //     },
      //     order: 'id DESC'
      //   }
      // });
      setTimeout(() => {
        location.reload();
      }, 1000);
    }),
    aproveDataWallet: function () {
      //id_wallet = '5d9b8c14a4a29d29f0ddf538';
      let n = Ember.get(this, 'newFile');
      let saida = false;
      Ember.set(this, 'isCreateWallet', true);

      try {
        let form = new FormData();
        form.set("name", n.name);
        form.set("channel", "-");
        form.set("id", 'waiting_wallet');
        form.set("userId", this.get('newRequest.userId'));
        let sep = this.newWallet.separator;
        form.set("separator", sep);
        Ember.set(this, 'newRequest.step', 2);
        form.append("file", n);
        let token = this.get('session.data.authenticated.id');
        let url = this.baseapi.apiLink('Wallets/aprove-file' + '?access_token=' + token);

        if (this.tipoDados == "excel") {
          url = this.baseapi.apiLink('Wallets/aprove-file-xls' + '?access_token=' + token);
        }

        var call = this.baseapi.postFormData(url, form, false);
        call.then(resp => {
          Ember.set(this, 'isCreateWallet', false);

          if (resp.data.errors && resp.data.errors[0].detail) {
            Ember.set(this, 'newFile', {});
            Ember.set(this, 'newWallet', {});
            this.toast.error('', resp.data.errors[0].detail, {
              positionClass: 'toast-bottom-right'
            });
            setTimeout(() => {
              Ember.$('#closeWallet').click();
            }, 300);
            return;
          }

          saida = true;
          Ember.set(this, 'dataAproveWallet', resp.data.parser);

          if (resp.data.counter.addressCount == 0 && resp.data.counter.emailCount == 0 && resp.data.counter.phoneCount == 0) {
            this.toast.error('Não conseguimos identificar nenhum E-mail, SMS ou Endereço, verifique seu arquivo!', '', {
              positionClass: 'toast-bottom-right'
            });
          }

          Ember.set(this, 'dataQtd', resp.data.counter);
          let keys = Object.keys(resp.data.maxlength);
          Ember.set(this, 'tableColumns', keys);
        }).catch(error => {
          console.log(error);
          Ember.set(this, 'isCreateWallet', false);
          Ember.set(this, 'newFile', {});
          Ember.set(this, 'newWallet', {});
          this.toast.error('Revise as colunas e dados de sua carteira!', '', {
            positionClass: 'toast-bottom-right'
          });
          setTimeout(() => {
            Ember.$('#closeWallet').click();
          }, 300);
        });
      } catch (e) {
        Ember.set(this, 'isCreateWallet', false);

        for (let err in e.body.errors) {
          Ember.set(this, 'isValidating', false);
          this.toast.error('Revise as colunas e dados de sua carteira!', e.body.errors[err].detail, {
            positionClass: 'toast-bottom-right'
          });
        }
      }

      return saida;
    },
    saveFileWallets: (0, _emberConcurrency.task)(function* (id_wallet) {
      //id_wallet = '5d9b8c14a4a29d29f0ddf538';
      let n = Ember.get(this, 'newFile');
      let saida = false;

      if (n != null && id_wallet != null) {
        try {
          let form = new FormData();
          form.set("name", n.name);
          form.set("channel", n.type);
          form.set("id", id_wallet);
          let sep = this.newWallet.separator;
          form.set("separator", sep);
          form.set("userId", this.get('newRequest.userId'));
          form.set("requestId", localStorage.getItem("newServiceRequestId"));
          let organizationId = Ember.get(this, 'newRequest.organizationId');
          var dataUser = this.get('dataUser');

          if (organizationId) {
            form.set("organizationId", organizationId);
          } else if (dataUser.organizationId) {
            form.set("organizationId", dataUser.organizationId);
          }

          form.append("file", n);
          let token = this.get('session.data.authenticated.id');
          let url = this.baseapi.apiLink('Wallets/upload-file' + '?access_token=' + token);

          if (this.tipoDados == "excel") {
            url = this.baseapi.apiLink('Wallets/upload-file-xls' + '?access_token=' + token);
          }

          var call = this.baseapi.postFormData(url, form, false);

          try {
            // pegar os campos e salvar no walletColumns
            yield call.then(resp => {
              var data = resp.data;
              var obj = {
                data: data.columns,
                dataEmail: data.columns_email,
                dataSMS: data.columns_sms
              };
              Ember.set(this, 'newRequest.walletId', data.id);
              Ember.set(this, 'newRequest.columns_email', data.columns_email);
              Ember.set(this, 'newRequest.columns_sms', data.columns_sms);
              Ember.set(this, 'walletColumns.data', data.columns);
              Ember.set(this, 'walletColumns.dataSMS', data.columns_sms);
              Ember.set(this, 'walletColumns.dataEmail', data.columns_email);
            });
          } catch (error) {
            this.toast.error('', 'Revise as colunas e dados de sua carteira!', {
              positionClass: 'toast-bottom-right'
            });
          } // set(this, 'isCreateWallet', false);
          // this.set('newRequest.walletId', id_wallet);
          // this.toast.success('', 'Dados salvos com sucesso', {positionClass: 'toast-bottom-right'});

        } catch (e) {
          for (let err in e.body.errors) {
            Ember.set(this, 'isValidating', false);
            this.toast.error('', 'Revise as colunas e dados de sua carteira!', {
              positionClass: 'toast-bottom-right'
            });
          }
        }
      }
    }),
    trasitionTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'newRequest.step', 2);
      let letterService = this.get('newRequest.letterService');
      let smsService = this.get('newRequest.smsService');
      let emailService = this.get('newRequest.emailService');
      let nextWallet = this.get('newRequest.walletId');

      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.walletId = nextWallet;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      let nr = _newReq.typeConfig;

      if (nextWallet != 'static') {
        let url_wallet = this.baseapi.apiLink('Wallets/list');
        const objReturn = yield this.baseapi.getHttp(url_wallet + '?filter[where][id]=' + nextWallet).then(r => r.data[0]);

        if (_newReq.columns_complement) {
          let newColumns = [];

          _newReq.columns_complement.forEach(rc => {
            newColumns.push(rc);
          });

          objReturn.columns.forEach(c => {
            newColumns.push(c);
          });
          Ember.set(this, 'walletColumns.data', newColumns);
        } else {
          Ember.set(this, 'walletColumns.data', objReturn.columns);
        }

        Ember.set(this, 'walletColumns.name', objReturn.name);
        Ember.set(this, 'walletColumns.dataSMS', objReturn.columns_sms);
        Ember.set(this, 'walletColumns.dataEmail', objReturn.columns_email);
        Ember.set(this, 'newRequest.columns_email', objReturn.columns_email);
        Ember.set(this, 'newRequest.columns_sms', objReturn.columns_sms);
        Ember.set(this, 'newRequest.columnsSelectEmail', objReturn.columns_email);
        Ember.set(this, 'newRequest.columnsSelectSMS', objReturn.columns_sms);
        var walletColumnsData = Ember.get(this, 'walletColumns.data');
        var keysWC = Object.keys(walletColumnsData);
        var tags = [];

        for (let j = 0; j < walletColumnsData.length; j++) {
          const column = walletColumnsData[j];
          var obj = {
            value: `[${column}]`,
            text: column,
            label: column
          };
          tags.push(obj);
        }

        Ember.set(this, 'storageTemplateSelected.tags', tags);
      }

      _newReq.walletId = nextWallet;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.columns_email', Ember.get(this, 'walletColumns.dataEmail'));
      Ember.set(this, 'newRequest.columns_sms', Ember.get(this, 'walletColumns.dataSMS'));
      _newReq.columns_email = Ember.get(this, 'walletColumns.dataEmail');
      _newReq.columns_sms = Ember.get(this, 'walletColumns.dataSMS');
      _newReq.columnsSelectEmail = Ember.get(this, 'walletColumns.dataEmail');
      _newReq.columnsSelectSMS = Ember.get(this, 'walletColumns.dataSMS'); // if(letterService && (nextWallet != 'static' || nr == "file_layout")){
      //   // set(this, 'storageTemplateSelected.editor_mode', 'carta');
      //   yield this.transitionToRoute('services.letter', { queryParams: { wallet: nextWallet } });
      // } else 

      yield this.baseapi.savePendentRequest().then();

      if (smsService) {
        if ((nextWallet != 'static' || nr == "file_layout") && _newReq.printingOptions.templateSMS.templateId == "construir_do_zero") {
          yield this.transitionToRoute('services.sms', {
            queryParams: {
              wallet: nextWallet
            }
          });
        } else {
          yield this.transitionToRoute('services.payment');
        }
      } else if (emailService && (nextWallet != 'static' || nr == "file_layout")) {
        Ember.set(this, 'storageTemplateSelected.editor_mode', 'email');
        yield this.transitionToRoute('services.email', {
          queryParams: {
            wallet: nextWallet
          }
        });
      } else {
        // yield this.transitionToRoute('services.test', { queryParams: { wallet: nextWallet}});
        yield this.transitionToRoute('services.payment', {
          queryParams: {
            wallet: nextWallet
          }
        });
      }
    }).maxConcurrency(3).enqueue(),
    createWalletTask: (0, _emberConcurrency.task)(function* (newWallet) {
      Ember.set(this, 'isCreateWallet', true); // const USER = this.get('session.data.authenticated');

      const USER = this.get('newRequest.userId');
      let wallet = this.store.createRecord('wallet', {
        name: Ember.get(newWallet, 'name'),
        description: Ember.get(newWallet, 'description'),
        file: Ember.get(newWallet, 'fileName'),
        userId: Ember.get(USER, 'userId')
      });
      let id_wallet = null;

      try {
        yield wallet.save().then(response => {
          id_wallet = response.id;
        });
        return id_wallet;
      } catch (error) {
        for (let err in error.errors) {
          if (error.errors.hasOwnProperty(err)) {
            if (error.errors[err].status == 422) {
              this.toast.error('Campos obrigatórios em branco.', 'Erro!', {
                positionClass: 'toast-bottom-right'
              });
            }
          }
        }
      }
    }),
    selectTemplate: function (json_data) {
      // set(this, 'walletColumns.data', json_data.columns);
      // set(this, 'walletColumns.dataSMS', json_data.columns_sms);
      // set(this, 'walletColumns.dataEmail', json_data.columns_email);
      Ember.set(this, 'newRequest.walletId', json_data.id);
    },
    // requestAttachs: computed('itemRequestAttachs', function () {
    //   let r = get(this, 'model.rawFiles');
    //   r = (r == null) ? [] : r;
    //   return r;
    // }).property().volatile(),
    countRequestAttachs: Ember.computed('countRequestAttach', function () {
      let r = Ember.get(this, 'model.requestAttachs');
      return r.length;
    }),
    sendRawFile: function (file, reqId) {
      Ember.set(this, 'isCreateLoading', true);
      Ember.set(this, 'isChangeRawFile', true);
      let form = new FormData(); // let reqId = localStorage.getItem('newServiceRequestId');

      form.set("type", "request_attach");
      form.set("requestId", reqId);
      form.append("file", file.blob);
      let organizationId = Ember.get(this, 'newRequest.organizationId');
      var dataUser = this.get('dataUser');

      if (organizationId) {
        form.set("organizationId", organizationId);
      } else if (dataUser.organizationId) {
        form.set("organizationId", dataUser.organizationId);
      }

      let ext = this.baseapi.getFileExtension(file.name);
      let url = this.baseapi.apiLink("rawFiles");
      url += "/upload-file";
      this.baseapi.postFormData(url, form).then(rawObj => {
        let p = Ember.get(this, 'model.requestAttachs');
        let u = [rawObj.data];
        p.forEach(item => {
          u.push(item);
        });
        Ember.set(this, 'model.requestAttachs', u);
        Ember.set(this, 'isCreateLoading', false);
        Ember.set(this, 'isChangeRawFiles', false);
      }, error => {
        this.toast.error('', 'Erro ao enviar o arquivo, tente novamente.', {
          positionClass: 'toast-bottom-right'
        });
      });
    },
    removeFileTask: (0, _emberConcurrency.task)(function (file) {
      let url = this.baseapi.apiLink('rawFiles');
      url += '/remove/' + file.id;
      Ember.set(this, 'isChangeRawFiles', true);
      this.baseapi.delete(url).then(resp => {
        if (resp.data.undefined.success) {
          let data = Ember.get(this, 'model.requestAttachs');
          let dataResult = [];

          for (var i = 0; i < data.length; i++) {
            if (data[i].id != file.id) {
              dataResult.push(data[i]);
            }
          }

          Ember.set(this, 'model.requestAttachs', dataResult);
          Ember.set(this, 'isChangeRawFiles', false);
        } else {
          this.toast.error('', 'Erro ao remover o arquivo', {
            positionClass: 'toast-bottom-right'
          });
          Ember.set(this, 'isChangeRawFiles', false);
        }
      }, error => {
        this.toast.error('', 'Erro ao remover o arquivo', {
          positionClass: 'toast-bottom-right'
        });
        Ember.set(this, 'isChangeRawFiles', false);
      });
    }).keepLatest(),
    fileWalletReload: Ember.computed(function () {
      let m = true;
      return m;
    }),
    changeStorageRequestTask: (0, _emberConcurrency.task)(function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
    }).keepLatest(),
    actions: {
      uploadImage(file) {
        Ember.get(this, 'uploadFile').perform(file);
      },

      deleteFile(file) {
        file.destroyRecord();
      },

      selectTemplate: function (json_data) {
        this.selectTemplate(json_data);
        this.toast.success('', 'Carteira selecionada com sucesso!', {
          positionClass: 'toast-bottom-right'
        });
      },
      selectStatic: function () {
        Ember.set(this.newRequest, 'walletId', 'static');
      },
      createWallet: async function (newWallet) {
        var id_wallet = await this.get('createWalletTask').perform(newWallet);

        if (id_wallet != null) {
          await this.get('saveFileWallets').perform(id_wallet);
          this.set('newRequest.walletId', id_wallet);
          this.toast.success('', 'Arquivo salvos com sucesso, você será encaminhado para a próxima tela!', {
            positionClass: 'toast-bottom-right'
          });
          Ember.$("#closeWallet").click();
          await (0, _globalFunctions.sleep)(500);
          Ember.$("#validFileClose").click();
          this.send('proceedTo');
        }

        Ember.set(this, 'finishSaveWallet', false);
        Ember.$("#modal-dismiss-validatefile").click();
      },
      aproveWallet: function () {
        this.aproveDataWallet();
      },
      setFileWallet: function (file) {
        // this.setFileWallet(file);
        var data = file.target.files[0];
        var filename = `Arquivo: ${data.name}`;
        Ember.set(this, 'newFile', data);
        Ember.set(this, 'newWallet.name', filename);
        this.aproveDataWallet();
        Ember.$('#btnModalAproveWallet').click();
      },
      proceedTo: function () {
        Ember.get(this, 'trasitionTask').perform();
      },

      testeGo() {},

      uploadFile(file) {
        let r = localStorage.getItem('newServiceRequestId');

        if (!r) {
          Ember.set(this, 'isChangeRawFiles', true);
          this.baseapi.savePendentRequest().then(data => {
            this.sendRawFile(file, data);
            Ember.set(this, 'isChangeRawFiles', false);
          }, error => {
            console.log(error);
            Ember.set(this, 'isChangeRawFiles', false);
          });
        } else {
          this.sendRawFile(file, r);
        }
      },

      removeFile(file) {
        let url = this.baseapi.apiLink('rawFiles');
        url += '/remove/' + file.id;
        Ember.set(this, 'isChangeRawFiles', true);
        this.baseapi.delete(url).then(resp => {
          if (resp.data.undefined.success) {
            let data = Ember.get(this, 'model.requestAttachs');
            let dataResult = [];

            for (var i = 0; i < data.length; i++) {
              if (data[i].id != file.id) {
                dataResult.push(data[i]);
              }
            }

            Ember.set(this, 'model.requestAttachs', dataResult);
            Ember.set(this, 'isChangeRawFiles', false);
          } else {
            this.toast.error('', 'Erro ao remover o arquivo', {
              positionClass: 'toast-bottom-right'
            });
            Ember.set(this, 'isChangeRawFiles', false);
          }
        }, error => {
          this.toast.error('', 'Erro ao remover o arquivo', {
            positionClass: 'toast-bottom-right'
          });
          Ember.set(this, 'isChangeRawFiles', false);
        });
      },

      setTypeConfig(value) {
        this.set('data_type', value);
        this.send('clearFiles');
      },

      clearFiles() {
        Ember.$("#upload-photo").val('');
        Ember.set(this, 'fileWalletReload', false);
        Ember.set(this, 'newFile', null);
        Ember.set(this, 'fileWalletReload', true);
      },

      confirmDataLayout() {
        let data_type = this.get('data_type');

        if (data_type == "layout") {
          this.set('newRequest.walletId', 'static');
          this.set('newRequest.fileDb', 'layout');
          this.set('newRequest.typeConfig', 'layout');
        }
      }

    }
  });

  _exports.default = _default;
});