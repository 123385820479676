define("printpost/controllers/services/test", ["exports", "printpost/controllers/services", "ember-concurrency", "printpost/config/environment"], function (_exports, _services, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = Ember.Object.extend({
    printingOptions: null,
    emailContact: null,
    emailSubject: null,
    walletId: null,
    empty_resp: null
  });

  var _default = _services.default.extend({
    variables: variables.create({
      printingOptions: null
    }),
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    test: Ember.inject.service('test-service'),
    solicitarTestes: 1,
    contactsSend: Ember.computed('contactSend', function () {
      return {
        emailContact: '',
        smsContact: ''
      };
    }),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    smsReturn: '',
    emailReturn: '',
    download: (0, _emberConcurrency.task)(function* (fileName) {
      try {
        let request = yield this.baseapi.downloadFile(fileName);
        return request;
      } catch (e) {
        this.toast.error('Erro!', 'Ocorreu algum problema ao baixar seu arquivo.', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    downloadMakeTemplate: function () {
      try {
        let _newReq = JSON.parse(localStorage.getItem('storage:request'));

        let printOptions = _newReq.printingOptions;
        let walletId = _newReq.walletId;
        let form = new FormData();

        let _html = printOptions.templateLetter.frontHTML + '<footer></footer>' + printOptions.templateLetter.backHTML;

        if (walletId && _html && printOptions.templateLetter != null && printOptions.templateLetter != undefined) {
          form.set("html", _html);
          form.set("walletId", walletId);
        } else {
          this.toast.error('Erro!', 'Alguns campos não foram identificados.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        let host = _environment.default.APP.API.host_py;
        let token = this.get('session.data.authenticated.id');
        let url = host + '/api/generatep/';
        let r = this.baseapi.postFormData(url + '?access_token=' + token, form, false);
        this.preloader.toggleClass('d-none');
        r.then(response => {
          this.preloader.toggleClass('d-none');
          let data = response.data;
          this.baseapi.getDownload(url + 'view?file=' + data, 'kkkk.pdf', false);
        }).catch(error => {
          this.variables.set('empty_resp', error);
          this.preloader.toggleClass('d-none');
          this.toast.error('Erro!', 'Problemas ao tratar o template.', {
            positionClass: 'toast-bottom-right'
          });
        });
      } catch (e) {
        this.toast.error('Erro!', 'Ocorreu algum problema ao baixar seu arquivo.', {
          positionClass: 'toast-bottom-right'
        });
      }
    },
    sendTestsTask: (0, _emberConcurrency.task)(function () {
      try {
        let printOptions = Ember.get(this, 'newPrintingOptions');
        let walletId = Ember.get(this, 'newRequest.walletId');
        let form = new FormData();

        if (printOptions.templateEmail != null && printOptions.templateEmail != undefined) {
          let _html = printOptions.templateEmail.frontHTML;
          form.set("email_html", _html);
          form.set("email_send", Ember.get(this, 'contactsSend.emailContact'));
        }

        if (printOptions.templateSMS != null && printOptions.templateSMS != undefined) {
          let _sms = printOptions.templateSMS.frontHTML;
          form.set("sms_html", _sms);
          form.set("sms_send", Ember.get(this, 'contactsSend.smsContact'));
        }

        form.set("walletId", walletId);
        let token = this.get('session.data.authenticated.id');
        let host = _environment.default.APP.API.host_py;
        let url = host + '/api/generatep/sendtest/';
        let r = this.baseapi.postFormData(url + '?access_token=' + token, form, false);
        r.then(response => {
          this.variables.set('empty_resp', response);
          Ember.set(this, 'solicitarTestes', 3);
        });
      } catch (e) {
        this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    paperOption: Ember.computed('paperOption', function () {
      // var data = this.get('model.request.firstObject');
      var printingOptions = Ember.get(this, 'newRequest.printingOptions');

      if (printingOptions.templateLetter) {
        let papel = "";
        let cor = "";

        if (printingOptions.templateLetter.definition.papper == "couche_fosco") {
          papel = "Couché fosco";
        } else if (printingOptions.templateLetter.definition.papper == "couche_brilhoso") {
          papel = "Couché brilhoso";
        } else if (printingOptions.templateLetter.definition.papper == "apergaminhado") {
          papel = "Apergam.";
        }

        if (printingOptions.templateLetter.definition.colored) {
          cor = "Colorido";
        } else {
          cor = "Preto e branco";
        }

        let _p = printingOptions.templateLetter.definition.format + " - " + printingOptions.templateLetter.definition.grammage + " /" + papel + " /" + cor;

        return _p;
      } //return this.get('model.request.firstObject');

    }).property().volatile(),
    myFlatpickrRef: Ember.computed(function () {
      return {
        carta: null,
        email: null,
        sms: null
      };
    }),
    dateSendRequest: Ember.computed(function () {
      var model = {
        carta: '',
        email: '',
        sms: ''
      };
      var printOption = Ember.get(this, 'newRequest.printingOptions');
      var imediate = {
        carta: printOption.templateLetter.disparoImediato,
        email: printOption.templateEmail.disparoImediato,
        sms: printOption.templateSMS.disparoImediato
      };
      let data_carta = !printOption.templateLetter.disparoImediato ? printOption.templateLetter.dataDisparo : this.baseapi.returnDate(3, true);
      let data_email = !printOption.templateEmail.disparoImediato ? printOption.templateEmail.dataDisparo : this.baseapi.returnDate(0, true);
      let data_sms = !printOption.templateSMS.disparoImediato ? printOption.templateSMS.dataDisparo : this.baseapi.returnDate(0, true);
      model.carta = data_carta;
      model.email = data_email;
      model.sms = data_sms;
      Ember.set(this, 'changeDateSendMod.originValues', model);
      Ember.set(this, 'changeDateSendMod.imediateValues', imediate);
      return model;
    }).property().volatile(),
    flatMinDate: Ember.computed('flatMinDate', function () {
      var model = {
        carta: '',
        email: '',
        sms: ''
      };
      model.carta = new Date().setDate(new Date().getDate() + 2);
      model.email = new Date().setHours(new Date().getHours() + 1);
      model.sms = new Date().setHours(new Date().getHours() + 1);
      return model;
    }).property().volatile(),
    flatMaxDate: Ember.computed('flatMaxDate', function () {
      var model = {
        carta: '',
        email: '',
        sms: ''
      };
      model.carta = new Date().setMonth(new Date().getMonth() + 2, 1);
      model.email = new Date().setMonth(new Date().getMonth() + 2, 1);
      model.sms = new Date().setMonth(new Date().getMonth() + 2, 1);
      return model;
    }).property().volatile(),
    changeDateSendMod: Ember.computed(function () {
      let m = {
        mode: '',
        originValues: {
          carta: '',
          email: '',
          sms: ''
        },
        imediateValues: {
          carta: '',
          email: '',
          sms: ''
        },
        newValues: {
          carta: '',
          email: '',
          sms: ''
        },
        dataFlat: {
          carta: '',
          email: '',
          sms: ''
        }
      };
      return m;
    }),
    changeStorageRequestTask: function (mod, chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      var item = "templateLetter";

      if (mod == "carta") {
        item = "templateLetter";
      } else if (mod == "email") {
        item = "templateEmail";
      } else if (mod == "sms") {
        item = "templateSMS";
      }

      _newReq.printingOptions[item][chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.printingOptions.templateEmail.' + chave, valor);
    },
    newRequestStorage: Ember.computed(function () {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      this.variables.set('printingOptions', _newReq.printingOptions);
      this.variables.set('emailContact', _newReq.emailContact);
      this.variables.set('emailSubject', _newReq.emailSubject);
      this.variables.set('walletId', _newReq.walletId);
      return _newReq;
    }).property().volatile(),
    actions: {
      downloadFile: function (fileName) {
        Ember.get(this, 'download').perform(fileName);
      },
      downloadGenerateTemplate: function () {
        this.downloadMakeTemplate();
      },
      setOption: function () {
        var valores = JSON.parse(localStorage.getItem("storage:request"));
        Ember.set(this, 'newRequest.printingOptions', valores.printingOptions);
        Ember.set(this, 'newRequest.orderSend', valores.orderSend);
        Ember.set(this, 'newRequest.notarysOffice', valores.notarysOffice);
      },
      sendTests: function () {
        Ember.set(this, 'solicitarTestes', 2);

        try {
          let printOptions = Ember.get(this, 'newRequest.printingOptions');
          let walletId = Ember.get(this, 'newRequest.walletId');
          let dataRequestId = Ember.get(this, 'newRequest.id');
          let form = new FormData();

          if (printOptions.templateEmail != null && printOptions.templateEmail != undefined) {
            let _html = printOptions.templateEmail.frontHTML;
            let reply_to = Ember.get(this.newRequest, 'emailContact');
            let subject = Ember.get(this.newRequest, 'emailSubject');
            let email_send = Ember.get(this, 'contactsSend.emailContact'); // if(email_send.index('@') == -1){
            //     this.toast.error('Email inválido!', 'Por gentileza insira um e-mail válido.', { positionClass: 'toast-bottom-right'});
            //     return;
            // }

            form.set("email_html", _html);
            form.set("email_replyto", reply_to);
            form.set("email_subject", subject);
            form.set("email_send", email_send);
          }

          if (printOptions.templateSMS != null && printOptions.templateSMS != undefined) {
            let _sms = printOptions.templateSMS.frontHTML;
            form.set("sms_html", _sms);
            form.set("sms_send", Ember.get(this, 'contactsSend.smsContact'));
          }

          form.set("walletId", walletId);
          form.set("requestId", dataRequestId);
          let token = this.get('session.data.authenticated.id');
          let host = _environment.default.APP.API.host_py;
          let url = host + '/api/generatep/sendtest';
          let r = this.baseapi.postFormData(url + '?access_token=' + token, form, false);
          r.then(response => {
            let data = response.data; // let str_data = data.replace(/'/g,"\"");
            // data = JSON.parse(str_data);        

            Ember.set(this, 'emailReturn', data.email);
            Ember.set(this, 'smsReturn', data.sms);
            Ember.set(this, 'solicitarTestes', 3);
          }).catch(error => {
            this.variables.set('empty_resp', error);
            this.toast.error('Erro!', 'Problemas ao enviar os testes.', {
              positionClass: 'toast-bottom-right'
            });
            Ember.set(this, 'solicitarTestes', 1);
          });
        } catch (e) {
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        } // setTimeout(() => { 
        //     set(this, 'solicitarTestes', 3);
        // }, 3000);

      },
      sendSMSTest: function () {
        try {
          var request = JSON.parse(localStorage.getItem("storage:request"));
          var requestOptions = "templateSMS";
          var printingOptionsStorage = request.printingOptions[requestOptions]; // let printOptions = get(this, 'newRequest.printingOptions')

          let walletId = Ember.get(this, 'newRequest.walletId');
          let _sms = printingOptionsStorage.frontHTML;
          let form = new FormData();

          if (walletId && _sms && Ember.get(this, 'contactsSend.smsContact')) {
            form.set("sms_html", _sms);
            form.set("sms_send", Ember.get(this, 'contactsSend.smsContact'));
          } else {
            this.toast.error('Erro!', 'Alguns campos não foram identificados.', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          form.set("walletId", walletId);
          form.set("requestId", localStorage.getItem("newServiceRequestId"));
          let token = this.get('session.data.authenticated.id');
          let host = _environment.default.APP.API.host_py;
          let url = host + '/api/generatep/sendtest';
          let r = this.baseapi.postFormData(url + '?access_token=' + token, form, false);
          this.preloader.toggleClass('d-none');
          r.then(response => {
            this.preloader.toggleClass('d-none');
            let data = response.data; // let str_data = data.replace(/'/g,"\"");
            // data = JSON.parse(str_data);        

            Ember.set(this, 'smsReturn', data.sms);
            this.toast.success('Sucesso!', 'SMS enviado, verifique seu celular em instantes.', {
              positionClass: 'toast-bottom-right'
            });
          }).catch(error => {
            this.variables.set('empty_resp', error);
            this.preloader.toggleClass('d-none');
            this.toast.error('Erro!', 'Problemas ao enviar os testes.', {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (e) {
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },

      sendEmailTest() {
        try {
          var request = JSON.parse(localStorage.getItem("storage:request"));
          var requestOptions = "templateEmail";
          var printingOptionsStorage = request.printingOptions[requestOptions];
          let reply_to = Ember.get(this, 'newRequest.emailContact');
          let subject = Ember.get(this, 'newRequest.emailSubject'); // let printOptions = get(this, 'newRequest.printingOptions');

          let walletId = Ember.get(this, 'newRequest.walletId');
          let _html = printingOptionsStorage.frontHTML;
          let form = new FormData();

          if (walletId && _html && reply_to && subject && Ember.get(this, 'contactsSend.emailContact')) {
            let email_send = Ember.get(this, 'contactsSend.emailContact');
            form.set("email_html", _html);
            form.set("email_replyto", reply_to);
            form.set("email_subject", subject);
            form.set("email_send", email_send);
          } else {
            this.toast.error('Erro!', 'Alguns campos não foram identificados.', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          form.set("walletId", walletId);
          form.set("requestId", localStorage.getItem("newServiceRequestId"));
          let token = this.get('session.data.authenticated.id');
          let host = _environment.default.APP.API.host_py;
          let url = host + '/api/generatep/sendtest';
          let r = this.baseapi.postFormData(url + '?access_token=' + token, form, false);
          this.preloader.toggleClass('d-none');
          r.then(response => {
            this.preloader.toggleClass('d-none');
            let data = response.data; // let str_data = data.replace(/'/g,"\"");
            // data = JSON.parse(str_data);        

            Ember.set(this, 'emailReturn', data.email);
            this.toast.success('Sucesso!', 'Email enviado, verifique na sua caixa de entrada em instantes.', {
              positionClass: 'toast-bottom-right'
            });
          }).catch(error => {
            this.variables.set('empty_resp', error);
            this.preloader.toggleClass('d-none');
            this.toast.error('Erro!', 'Problemas ao enviar os testes.', {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (e) {
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },

      changeFlatPicker: function (mod) {
        Ember.set(this, 'changeDateSendMod.mode', mod);
        Ember.$("#confirmChangeSend").click();
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f[mod].selectedDates.forEach(function (date) {
          dateFlat = date;
        });
        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = day + "/" + month + "/" + year + " - " + hour + ":" + minute;
        let data = Ember.get(this, 'changeDateSendMod.newValues');
        let dataFlat = Ember.get(this, 'changeDateSendMod.dataFlat');
        data[mod] = strDate;
        dataFlat[mod] = f[mod];
        Ember.set(this, 'changeDateSendMod.newValues', data);
        Ember.set(this, 'changeDateSendMod.dataFlat', dataFlat);
      },
      confirmChangeSend: function (confirm) {
        if (!confirm) {
          return;
        }

        let dataRequest = JSON.parse(localStorage.getItem('storage:request'));
        let imediate = Ember.get(this, 'changeDateSendMod.imediateValues');
        let newData = Ember.get(this, 'changeDateSendMod.newValues');
        let mode = Ember.get(this, 'changeDateSendMod.mode');
        let letterService = dataRequest.letterService;
        let emailService = dataRequest.emailService;
        let smsService = dataRequest.smsService;

        if (emailService && mode == "email") {
          let disparoImediato = confirm ? false : imediate.email;
          dataRequest.printingOptions.templateEmail.disparoImediato = disparoImediato;
          dataRequest.printingOptions.templateEmail.dataDisparo = newData.email;
        }

        if (letterService && mode == "carta") {
          let disparoImediato = confirm ? false : imediate.carta;
          dataRequest.printingOptions.templateLetter.disparoImediato = disparoImediato;
          dataRequest.printingOptions.templateLetter.dataDisparo = newData.carta;
        }

        if (smsService && mode == "sms") {
          let disparoImediato = confirm ? false : imediate.sms;
          dataRequest.printingOptions.templateSMS.disparoImediato = disparoImediato;
          dataRequest.printingOptions.templateSMS.dataDisparo = newData.sms;
        }

        Ember.set(this, 'newRequest.printingOptions', dataRequest.printingOptions);
        let order = this.test.verifySendOrder();
        Ember.set(this, 'model.order', order);
      },
      letterChangeView: function () {
        let v = Ember.$("#pos_view").val();
        Ember.$("#iframe-front").toggleClass('display-none');
        Ember.$("#iframe-back").toggleClass('display-none');

        if (v == 0) {
          Ember.$("#btn_letterChangeView").html("Visualizar Frente");
          Ember.$("#pos_view").val(1);
        } else {
          Ember.$("#btn_letterChangeView").html("Visualizar Verso");
          Ember.$("#pos_view").val(0);
        }
      },
      setOrderMultichannel: function (order, item) {
        let orderData = Ember.get(this, 'newRequest.orderSend');

        if (order == 1) {
          orderData.first = item.value;
          Ember.set(this, 'newRequest.orderSend', orderData);
          Ember.set(this, 'firstOrderSelected', item);
        } else if (order == 2) {
          orderData.second = item.value;
          Ember.set(this, 'newRequest.orderSend', orderData);
          Ember.set(this, 'secondOrderSelected', item);
        }
      }
    }
  });

  _exports.default = _default;
});