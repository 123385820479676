define("printpost/helpers/character-limit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.characterLimit = characterLimit;
  _exports.default = void 0;

  function characterLimit(params, hash) {
    var value = params[0];
    var len = hash.limit;
    var out = '';

    if (value) {
      out = value.substr(0, len);

      if (value.length > len) {
        out += '...';
      }
    } else {
      out = '';
    }

    return out;
  }

  var _default = Ember.Helper.helper(characterLimit);

  _exports.default = _default;
});