define("printpost/controllers/peoples/omnichannel", ["exports", "printpost/controllers/peoples", "moment", "ember-concurrency", "printpost/utils/globalFunctions", "fetch"], function (_exports, _peoples, _moment, _emberConcurrency, _globalFunctions, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = Ember.Object.extend({
    list_request_id: null,
    list_request_id_all: false
  });

  var _default = _peoples.default.extend({
    baseapi: Ember.inject.service(),
    omnichannelService: Ember.inject.service(),
    variables: variables.create(),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    menusFilter: Ember.computed('menusFilter', function () {
      var itens = ['filter_mes', 'filter_ano', 'filter_inicio'];
      return itens;
    }),
    datePeriodo: [],
    cStrDateBegin: Ember.computed('strDateBegin', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + 'T00:00:00-03:00';
      return strDate;
    }),
    cStrDateEnd: Ember.computed('strDateEnd', function () {
      let objDate = new Date();
      let strDate = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1, 2) + '-' + this.baseapi.padStr(objDate.getDate(), 2) + 'T23:59:00-03:00';
      return strDate;
    }),
    randomScalingFactor: function (value = 100) {
      return Math.round(Math.random() * value);
    },
    taskLinhaTempo: function () {
      let l = [];
      let la = [];
      var data = Ember.get(this, 'model.people');

      if (data.omniHistory && data.omniHistory.length > 0) {
        for (let i = 0; i < data.omniHistory.length; i++) {
          const item = data.omniHistory[i];

          if (item.returns && item.returns.length > 0) {
            for (let r = 0; r < item.returns.length; r++) {
              const dataReturn = item.returns[r];
              var strStatus = '';
              var strModalidade = '';

              switch (dataReturn.status) {
                case 1:
                  strStatus = "Enviado(a)";
                  break;

                default:
                  strStatus = ` - ${dataReturn.motivoNegativo}`;
                  break;
              }

              switch (dataReturn.channel.toLowerCase().trim()) {
                case "carta":
                  strModalidade = "Carta";
                  break;

                case "email":
                  strModalidade = "E-mail";
                  break;

                case "sms":
                  strModalidade = "SMS";
                  break;

                default:
                  break;
              }

              var idMask = `${dataReturn.peopleHistoryId.substr(0, 5)}******${dataReturn.peopleHistoryId.substr(-5)}`;
              var dg = dataReturn.requestProcess && dataReturn.requestProcess.digitalContact ? dataReturn.requestProcess.digitalContact : '';
              var obj = {
                data: (0, _moment.default)(dataReturn.createdAt).format('DD/MM/YYYY'),
                titulo: `${strModalidade} ${strStatus}`,
                cartaId: `${idMask}`,
                peopleId: `${dataReturn.peopleHistoryId}`,
                digitalContact: dg,
                requestProcessId: dataReturn.requestProcessId,
                status: dataReturn.status,
                pedido: `Pedido: #${dataReturn.request.number} - ${dataReturn.request.title}`,
                modalidade: dataReturn.channel
              };

              if (!item.archivedAt) {
                l.push(obj);
              } else {
                la.push(obj);
              }
            }
          }
        }
      }

      this.funcGrafPie1();
      this.funcGrafBar1();
      Ember.set(this, 'model.dataLinhaTempo', l);
      Ember.set(this, 'model.dataLinhaTempoNoArquivada', l);
      Ember.set(this, 'model.dataLinhaTempoArquivada', la); // return l;
    },
    msgGraf: Ember.computed('msgGraf', function () {
      var da = this.get('model.dataAnalitics');
      var people = this.get('model.people');
      var total = da.dataTotalDig + da.dataTotalAddress;
      var entregue = da.dataPosRet;
      var notentregue = da.dataNotRet;
      var percentDigital = `${(da.dataTotalDig * 100 / total).toFixed(0)}`;
      var percentReceived = `${(entregue * 100 / total).toFixed(0)}`;
      var msg = `Muito bom! ${people.primeiro_nome} recebeu ${percentReceived}% dos comunicados`;

      if (entregue == 0 && people.omniHistory[0].returns.length > 1) {
        msg = `Enviamos 100% dos casos e tivemos uma alta quantidade de retornos`;
      }

      if (da.dataTotalDig > 0) {
        msg = msg + `, sendo que ${percentDigital}% deles foi por meio digital`;
      } else if (entregue == 0 && notentregue == 0) {
        msg = `Enviamos 100% dos casos, em breve teremos as informações de retorno`;
      }

      return msg;
    }).property().volatile(),
    funcGrafBar1: function () {
      var da = this.get('model.people.successMap');
      let d = {
        "datasets": [{
          "label": "Desempenho",
          "fill": false,
          "data": [da.email.positive, da.sms.positive, da.carta.positive],
          "backgroundColor": ["#50e2d4", "#50e2d4", "#50e2d4"],
          "borderColor": ["#50e2d4", "#50e2d4", "#50e2d4"],
          "borderWidth": 0
        }],
        "color": ["#50e2d4", "#b9e2f1", "#ff8da0"],
        "labels": ["E-mail", "SMS", "Carta"]
      };
      Ember.set(this, 'model.dataGrafBar1', d);
    },
    funcGrafPie1: function () {
      var da = this.get('model.people.successMap');
      let d = {
        "datasets": [{
          "label": "Índice de sucesso",
          "fill": false,
          "data": [da.total.send, da.total.positive],
          "backgroundColor": ["#50e2d4", "#98fff5"],
          "borderColor": ["#50e2d4", "#50e2d4"],
          "borderWidth": 0
        }],
        "color": ["#50e2d4", "#b9e2f1"],
        "labels": ["Total", "Positivo"]
      };
      Ember.set(this, 'model.dataGrafPie1', d);
    },
    cartaView: Ember.computed('cartaView', function () {
      let m = {};
      return m;
    }),
    renderDetail: Ember.computed('renderDetail', function () {
      return false;
    }),
    taskFindDataPeople: (0, _emberConcurrency.task)(function* (id) {
      try {
        var url = this.baseapi.apiLink(`PeopleHistories/${id}`);
        const listData = yield this.baseapi.getHttp(url).then(r => r.data[0]);
        var p = listData;
        var columns = p.wallet.columns;
        var listColumns = [];

        for (let i = 0; i < columns.length; i++) {
          const c = columns[i];
          listColumns.push({
            name: c,
            valuePath: c
          });
        }

        delete p.wallet;
        this.set('model.peopleViewDetail.columns', columns);
        this.set('model.peopleViewDetail.data', [p]);
        this.set('model.renderDetail', true);
        return;
      } catch (error) {
        this.toast.error('', 'Erro ao listar dados #2020', {
          positionClass: 'toast-bottom-right'
        });
      }
    }),
    tableColumns: Ember.computed('modelEditor.wallet.data', function () {
      let itens = [{
        name: `Nome`,
        valuePath: 'nome'
      }, {
        name: `CPF/CNPJ`,
        valuePath: 'cpf_cnpj'
      }, {
        name: `Telefone`,
        valuePath: 'telefone'
      }, {
        name: `Email`,
        valuePath: 'email'
      }];
      return itens;
    }),
    optionsBar: Ember.computed(function () {
      return {
        indexAxis: "y",
        plugins: {
          legend: {
            position: 'right'
          }
        },
        responsive: true,
        legend: {
          display: false,
          labels: {
            fontColor: "#000"
          }
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }],
          xAxes: [{
            display: false,
            gridLines: {
              display: false
            }
          }]
        }
      };
    }),
    optionsPie: Ember.computed(function () {
      return {
        responsive: true,
        legend: {
          display: false,
          labels: {
            fontColor: "#000"
          }
        },
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: false
            },
            gridLines: {
              display: false
            }
          }],
          xAxes: [{
            display: false,
            gridLines: {
              display: false
            }
          }]
        }
      };
    }),
    searchTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);
      term = term.toLowerCase().trim();
      var query = {
        filter: {
          where: {
            or: [{
              nome: {
                like: term,
                options: 'i'
              }
            }, {
              cpf_cnpj: {
                like: term,
                options: 'i'
              }
            }]
          }
        }
      };
      return yield this.omnichannelService.list(query, false);
    }),
    searchPeople: (0, _emberConcurrency.task)(function* (id) {
      this.preloader.toggleClass('d-none');
      let url = this.baseapi.apiLink(`OmniChannelPeople/people/${id}`);
      var data = yield this.baseapi.getHttp(url).then(r => r.data);
      this.preloader.toggleClass('d-none');
      this.set('model.people', data);
      this.taskLinhaTempo(); // return data;
    }),
    peopleList: Ember.computed('model.peopleHistories.data', function () {
      let d = this.get('model.peopleHistories.data');
      return d;
    }),
    dataTypeReturn: Ember.computed('dataTypeReturn', function () {
      return [{
        description: 'Todos',
        status: null
      }, {
        description: 'Aguardando',
        status: 1
      }, {
        description: 'Positivo',
        status: 3
      }, {
        description: 'Negativo',
        status: 2
      }, {
        description: 'Arquivados',
        status: 5
      }];
    }),
    tableColumns: Ember.computed('modelEditor.wallet.data', function () {
      let itens = [{
        name: `Nome`,
        valuePath: 'nome'
      }, {
        name: `CPF/CNPJ`,
        valuePath: 'cpf_cnpj'
      }, {
        name: `Telefone`,
        valuePath: 'telefone'
      }, {
        name: `Email`,
        valuePath: 'email'
      }]; // let itens = ['nome', 'cpf_cnpj', 'telefone', 'email']

      return itens;
    }),
    taskArchiveRecords: (0, _emberConcurrency.task)(function* (type = 'archive') {
      try {
        let itens = this.variables.get('list_request_id');
        const body = [];
        itens.map(x => body.push(x.id));
        this.preloader.toggleClass('d-none');

        if (body.length && type == 'archive') {
          var url = this.baseapi.apiLink(`OmniChannelPeople/archive-record`);
          yield this.baseapi.post(url, body).then(r => r.data[0]);
          this.toast.success('Item arquivado!', 'Dados arquivados com sucesso', {
            positionClass: 'toast-bottom-right'
          });
        } else {
          var url = this.baseapi.apiLink(`OmniChannelPeople/unarchive-record`);
          yield this.baseapi.post(url, body).then(r => r.data[0]);
          this.toast.success('Item desarquivado!', 'Dados desarquivados com sucesso', {
            positionClass: 'toast-bottom-right'
          });
        }

        this.preloader.toggleClass('d-none');
        this.variables.set('list_request_id', []);
        this.send('paginationRequest', {
          skip: 0,
          limit: 20
        });
      } catch (error) {
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao atualizar dados #2050', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    actions: {
      findDataPeople(id, requestProcessId) {
        try {
          this.set('model.renderDetail', false);
          let people = this.get('model.people');
          let history = people.omniHistory.find(x => x.peopleHistoryId == id && x.returns.length > 0);
          let token = this.baseapi.getToken();
          let url = this.baseapi.apiLink(`RequestReturns/file-return/${id}?access_token=${token}#zoom=100`);
          var r = history.returns.find(x => x.status != 1 && x.requestProcessId == requestProcessId);
          var r2 = history.returns.find(x => x.requestProcessId == requestProcessId);
          var mod = '';

          switch (r2.channel.toLowerCase().trim()) {
            case "carta":
              mod = "Carta";
              break;

            case "email":
              mod = "E-mail";
              break;

            case "sms":
              mod = "SMS";
              break;

            default:
              break;
          }

          var object_return = {
            type: "Positivo",
            description: "",
            icon: "fa fa-paper-plane",
            iconColor: "#50e3c2",
            status: 3,
            channel: mod,
            uri: url
          };

          if (!r) {
            object_return.type = "Aguardando";
            object_return.icon = "fa-paper-plane";
            object_return.iconColor = "#5197d2";
            object_return.description = "";
            object_return.status = 1;
          } else if (r.status == 2) {
            object_return.type = "Negativo";
            object_return.icon = "fa fa-thumbs-down";
            object_return.iconColor = "#ff8da0";
            object_return.description = r.motivoNegativo;
            object_return.status = 2;
          }

          this.set('model.peopleViewDetail.history', history);
          this.set('model.peopleViewDetail.request', history.returns[0].request);
          this.set('model.peopleViewDetail.return', object_return);
        } catch (error) {
          this.toast.error('', 'Erro ao listar dados #2010', {
            positionClass: 'toast-bottom-right'
          });
        }

        this.get("taskFindDataPeople").perform(id);
      },

      actionFilterWallet: function (name) {
        Ember.$('#list_wallets li').each((index, item) => {
          var nameCampaign = Ember.$(item).attr('data-id');

          if (name == nameCampaign) {
            Ember.$(item).addClass('bold');
            name != 'all' ? Ember.set(this, 'filterService', name) : Ember.set(this, 'filterService', '');
            this.send('paginationRequest', {
              skip: 0,
              limit: 20
            }, true);
          } else {
            Ember.$(item).removeClass('bold');
          }

          if (name == "all") Ember.$(item).removeClass('bold');
        });
      },
      actionFilterReturn: function (strfilter) {
        let listFilterMenus = Ember.get(this, 'menusFilter');
        let objDate = new Date();
        let strDateBegin = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-01T00:00:00-03:00';
        let strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
        listFilterMenus.forEach(item => {
          if (strfilter != item) {
            Ember.$('#' + item).addClass('text-lightgray');
          } else {
            Ember.$('#' + item).removeClass('text-lightgray');

            if (item == 'filter_ano') {
              strDateBegin = objDate.getFullYear() + '-01-01T00:00:00-03:00';
              strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
            } else if (item == 'filter_inicio') {
              strDateBegin = '2019-01-01T00:00:00-03:00';
              strDateEnd = objDate.getFullYear() + '-' + this.baseapi.padStr(objDate.getMonth() + 1) + '-' + this.baseapi.padStr(objDate.getDate()) + 'T23:59:00-03:00';
            }
          }
        });
        Ember.set(this, 'cStrDateBegin', strDateBegin);
        Ember.set(this, 'cStrDateEnd', strDateEnd);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes_setas').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
        }, 500);
        this.send('paginationRequest', {
          skip: 0,
          limit: 20
        }, true);
      },

      changeFlatPicker() {
        let f = Ember.get(this, 'myFlatpickrRef');
        let dateFlat = null;
        f.selectedDates.forEach(function (date) {
          dateFlat = date;
        });

        if (!dateFlat) {
          return;
        }

        let year = dateFlat.getFullYear();
        let month = this.baseapi.padStr(dateFlat.getMonth() + 1, 2);
        let day = this.baseapi.padStr(dateFlat.getDate(), 2);
        let hour = this.baseapi.padStr(dateFlat.getHours(), 2);
        let minute = this.baseapi.padStr(dateFlat.getMinutes(), 2);
        let strDate = year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":00-03:00";
        let d = this.datePeriodo;

        if (d.length == 0) {
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        } else if (d.length == 1) {
          let st = year + "-" + month + "-" + day + "T23:59:00-03:00";
          d.push(st);
          Ember.set(this, 'cStrDateEnd', st);
          this.send('paginationRequest', {
            skip: 0,
            limit: 20
          }, true);
        } else if (d.length == 2) {
          d = [];
          d.push(strDate);
          Ember.set(this, 'cStrDateBegin', strDate);
        }

        this.datePeriodo = d;
        setTimeout(() => {
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').addClass('text-lightgray');
          Ember.$('#filter_periodo').removeClass('text-lightgray');
        }, 500);
      },

      beforeMonth() {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() - 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth(), 0);
        let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        let d1Str = `${this.baseapi.getDateTimeNow(d1, 'yyyyMMdd', true).replace(/\//g, '-').replace(' ', 'T')}-03:00`;
        let d2Str = `${this.baseapi.getDateTimeNow(d2, 'yyyyMMdd', true).replace(/\//g, '-').replace(' ', 'T')}-03:00`;
        Ember.set(this, 'cStrDateBegin', d1Str);
        Ember.set(this, 'cStrDateEnd', d2Str);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').removeClass('text-lightgray');
        }, 500);
        this.send('paginationRequest', {
          skip: 0,
          limit: 20
        });
      },

      afterMonth() {
        let m = this.get('model.dataDateFilter');
        let d1 = new Date(m.getFullYear(), m.getMonth() + 1, 1);
        let d2 = new Date(m.getFullYear(), m.getMonth() + 2, 0);
        let str = this.baseapi.getMonthStr(d1.getMonth()) + " - " + d1.getFullYear();
        this.set('model.dataDateFilter', d1);
        this.set('model.dataDateFilterStr', str);
        let d1Str = `${this.baseapi.getDateTimeNow(d1, 'yyyyMMdd', true).replace(/\//g, '-').replace(' ', 'T')}-03:00`;
        let d2Str = `${this.baseapi.getDateTimeNow(d2, 'yyyyMMdd', true).replace(/\//g, '-').replace(' ', 'T')}-03:00`;
        Ember.set(this, 'cStrDateBegin', d1Str);
        Ember.set(this, 'cStrDateEnd', d2Str);
        setTimeout(() => {
          Ember.get(this, 'myFlatpickrRef').clear();
          Ember.$('#filter_mes').addClass('text-lightgray');
          Ember.$('#filter_ano').addClass('text-lightgray');
          Ember.$('#filter_inicio').addClass('text-lightgray');
          Ember.$('#filter_periodo').addClass('text-lightgray');
          Ember.$('#filter_mes_setas').removeClass('text-lightgray');
        }, 500);
        this.send('paginationRequest', {
          skip: 0,
          limit: 20
        });
      },

      filterCampaign() {
        let input, filter, li;
        input = document.getElementById('filterCampaign');
        filter = input.value.toUpperCase();
        li = this.get('model.dataWallets');
        var new_list = li.filter(x => x.name.toUpperCase().indexOf(filter) > -1);
        Ember.set(this, 'dataWallets', new_list);
      },

      setCliente(item) {
        this.set('dataClient', item);
        this.get('searchPeople').perform(item.id);
      },

      setTypeReturn: async function (item) {
        Ember.set(this, 'selectedReturn', item);

        if (item) {
          this.send('paginationRequest', {
            skip: 0,
            limit: 20
          });
        }
      },

      setPeople(people) {
        // let url = this.baseapi.apiLink(`OmniChannelPeople/people/${id}`);
        // let peopleData = await this.baseapi.getHttp(url).then(r =>  r.data);
        this.get('searchPeople').perform(people.id); // this.get('searchPeople').perform("5fb3716320d9c6d5c87b544d");
      },

      voltar() {
        this.set('model.people', null);
        this.set('dataClient', null);
      },

      paginationRequest: async function (filterSkip, run = null) {
        var query = {
          filter: {
            order: 'id DESC',
            skip: filterSkip.skip,
            limit: filterSkip.limit
          }
        };
        var itemReturn = Ember.get(this, 'selectedReturn');
        var dtIni = Ember.get(this, 'cStrDateBegin');
        var dtEnd = Ember.get(this, 'cStrDateEnd');
        var filterService = Ember.get(this, 'filterService');
        filterService = filterService == 'all' ? null : filterService;
        var dateFilter = dtIni && dtEnd ? {
          dateIni: dtIni,
          dateEnd: dtEnd,
          service: filterService
        } : null;

        if (itemReturn && itemReturn.status || run) {
          this.preloader.toggleClass('d-none');

          try {
            // filterService = filterService == 'all' ? null : filterService;
            // var dateFilter = dtIni && dtEnd ? { dateIni: dtIni, dateEnd: dtEnd, service: filterService } : null;
            var statusReturn = itemReturn && itemReturn.status ? itemReturn.status : 1;
            const omniList = await this.omnichannelService.filter(statusReturn, dateFilter, query);
            this.set('model.peopleHistories', omniList.data);
            this.set('model.pages', omniList.totalPages);
          } catch (error) {
            this.toast.error('', 'Problemas ao listar dados #1022', {
              positionClass: 'toast-bottom-right'
            });
          } finally {
            this.preloader.toggleClass('d-none');
          }

          return;
        }

        this.preloader.toggleClass('d-none');

        try {
          const omniList = await this.omnichannelService.list(query);
          Ember.set(this, 'model.pages', omniList.totalPages);
          Ember.set(this, 'model.peopleHistories', omniList.data);
        } catch (error) {
          this.toast.error('', 'Erro na consulta dos dados', {
            positionClass: 'toast-bottom-right'
          });
        }

        this.preloader.toggleClass('d-none');
      },

      limparFiltros() {
        Ember.set(this, 'selectedReturn', null);
        Ember.set(this, 'filterService', null);
        Ember.set(this, 'cStrDateBegin', null);
        Ember.set(this, 'cStrDateEnd', null);
        Ember.set(this, 'dataClient', null);
        this.send('paginationRequest', {
          skip: 0,
          limit: 20
        });
      },

      setOmniChannelId(requestId) {
        let itens = this.variables.get('list_request_id');
        let allItens = this.variables.get('list_request_id_all');
        var list = [];
        var drc = this.get('model.peopleHistories');
        let idx = itens == null ? false : itens.find(x => x.id == requestId);

        if (idx) {
          for (let i = 0; i < itens.length; i++) {
            const element = itens[i];

            if (element.id != idx.id) {
              list.push(element);
            }
          } // itens = list;

        } else {
          if (idx === false) {
            itens = [];
          }

          var v = drc.find(x => x.id == requestId);
          list = itens.concat([v]);
        }

        for (let j = 0; j < list.length; j++) {
          const element = list[j];
          const req = drc.find(x => x.id == element);
          if (!req) continue;
        } // this.set('dataRequestsSelect', list);


        this.variables.set('list_request_id', list);
      },

      archive() {
        this.get('taskArchiveRecords').perform('archive');
      },

      unarchive() {
        this.get('taskArchiveRecords').perform('unarchive');
      },

      downloadList() {
        var query = {
          filter: {
            order: 'id DESC'
          }
        };
        var itemReturn = Ember.get(this, 'selectedReturn');
        var dtIni = Ember.get(this, 'cStrDateBegin');
        var dtEnd = Ember.get(this, 'cStrDateEnd');
        var filterService = Ember.get(this, 'filterService');

        if (itemReturn && itemReturn.status) {
          this.preloader.toggleClass('d-none');

          try {
            filterService = filterService == 'all' ? null : filterService;
            var dateFilter = dtIni && dtEnd ? {
              dateIni: dtIni,
              dateEnd: dtEnd,
              service: filterService
            } : null;
            var statusReturn = itemReturn && itemReturn.status ? itemReturn.status : 1;
            const base_url = this.baseapi.apiLink(`OmniChannelPeople/download-xls-return/${statusReturn}`);
            const strquery = query ? `&${(0, _globalFunctions.apiLinkQuery)(query)}` : '';
            var url = !strquery ? `${base_url}` : `${base_url}?${strquery}`;
            if (dateFilter && dateFilter.dateIni && dateFilter.dateEnd) url = `${url}&dateIni=${dateFilter.dateIni}&dateEnd=${dateFilter.dateEnd}`;
            if (dateFilter && dateFilter.service) url = `${url}&service=${dateFilter.service}`;
            this.baseapi.getDownload(`${url}`, 'file_download.xlsx', true);
          } catch (error) {
            this.toast.error('', 'Problemas ao listar dados #1022', {
              positionClass: 'toast-bottom-right'
            });
          } finally {
            this.preloader.toggleClass('d-none');
          }

          return;
        }
      },

      exibirNaoArquivados() {
        Ember.set(this, 'model.linhaTempoMode', 'normal');
        Ember.set(this, 'model.dataLinhaTempo', Ember.get(this, 'model.dataLinhaTempoNoArquivada'));
      },

      exibirArquivados() {
        Ember.set(this, 'model.linhaTempoMode', 'arquivados');
        Ember.set(this, 'model.dataLinhaTempo', Ember.get(this, 'model.dataLinhaTempoArquivada'));
      }

    }
  });

  _exports.default = _default;
});