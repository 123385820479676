define("printpost/routes/services", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resourceName: 'Request',
    baseapi: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.hash({
        'city': this.get('findCityTask').perform()
      });
    },
    findAddressTask: (0, _emberConcurrency.task)(function* () {
      const USER = this.get('session.data.authenticated');
      return yield this.get('store').query('address', {
        filter: {
          where: {
            'userId': USER.userId
          }
        }
      });
    }).keepLatest(),
    findCityTask: (0, _emberConcurrency.task)(function* () {
      let request = yield this.get('store').findAll('city');
      return request;
    }).keepLatest()
  });

  _exports.default = _default;
});