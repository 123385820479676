define("printpost/routes/_route_extended", ["exports", "@ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      error(error
      /*, transition*/
      ) {
        let alert_message = ""; // conexão abortada ou erro no backend

        if (error.status === 'undefined') {
          alert_message = "Connection Error";
        } else if (error.status === '500') {
          alert_message = "Server Error";
        } else if (error.status === '404') {
          // this.replaceWith('/sign/in');
          alert_message = "Page not Found";
        } else {
          alert_message = "Undefined"; // Let the route above this handle the error.
          // return true;
        }

        _ember.Logger.error(alert_message);

        return true;
      }

    }
  });

  _exports.default = _default;
});