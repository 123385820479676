define("printpost/models/request-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    execucao: _emberData.default.attr('number'),
    finalizados: _emberData.default.attr('number'),
    retornos: _emberData.default.attr('number'),
    channel: _emberData.default.attr('string'),
    requestId: _emberData.default.belongsTo('request'),
    updatedAt: _emberData.default.attr()
  });

  _exports.default = _default;
});