define("printpost/templates/sign/in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1+paoajI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"form-signin\"],[3,\"action\",[[22,0,[]],\"authenticate\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"create-form-box\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"sp-12\"],[9],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"font-weight-normal text-center header-title\"],[9],[0,\"Acesse a área do cliente com seu email e senha.\"],[10],[0,\"\\n\\n\\n\"],[4,\"if\",[[23,[\"hasError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-center\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"alert alert-danger\"],[9],[1,[21,\"errorMessage\"],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"label\"],[11,\"for\",\"identification\"],[11,\"class\",\"sr-only\"],[9],[0,\"Login\"],[10],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"id\",\"placeholder\",\"class\",\"value\"],[\"identification\",\"Seu e-mail\",\"form-control\",[23,[\"identification\"]]]]],false],[0,\"\\n    \"],[7,\"label\"],[11,\"for\",\"password\"],[11,\"class\",\"sr-only\"],[9],[0,\"Password\"],[10],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"id\",\"type\",\"placeholder\",\"class\",\"value\"],[\"password\",\"password\",\"Sua senha\",\"form-control\",[23,[\"password\"]]]]],false],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-login btn-lg btn-block btn-full\"],[11,\"type\",\"submit\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoginLoading\"]]],null,{\"statements\":[[0,\"        \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\"],[9],[0,\"ENTRAR\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"p\"],[11,\"class\",\"text-center forgot\"],[9],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"Esqueceu sua senha? Clique aqui.\"],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[0,\"\\n\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/sign/in.hbs"
    }
  });

  _exports.default = _default;
});