define("printpost/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    colors: _emberData.default.attr('boolean'),
    onlyFront: _emberData.default.attr('boolean'),
    channel: _emberData.default.attr('string'),
    AR: _emberData.default.attr('boolean'),
    unitWeight: _emberData.default.attr('number'),
    productionCost: _emberData.default.attr('number'),
    logisticCost: _emberData.default.attr('number'),
    minimumPrice: _emberData.default.attr('number')
  });

  _exports.default = _default;
});