define("printpost/routes/emailcfailure", ["exports", "printpost/mixins/open-route-mixin"], function (_exports, _openRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default Route.extend(UnauthenticatedRouteMixin, {
  var _default = Ember.Route.extend(_openRouteMixin.default, {
    hasLayout: false,
    baseapi: Ember.inject.service(),
    model: function () {
      var c = this.baseapi.queryParam('c');
      console.log(c);
      setTimeout(() => {
        window.location.href = '/sign/in';
      }, 3000);
      return Ember.RSVP.hash({
        confirm: c
      });
    }
  });

  _exports.default = _default;
});