define("printpost/routes/emailconfirm", ["exports", "printpost/mixins/open-route-mixin"], function (_exports, _openRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default Route.extend(UnauthenticatedRouteMixin, {
  var _default = Ember.Route.extend(_openRouteMixin.default, {
    hasLayout: false,
    baseapi: Ember.inject.service(),
    actions: {
      unsub() {
        window.location.href = '/emailcfailure?c=1';
      }

    }
  });

  _exports.default = _default;
});