define("printpost/controllers/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //
  var _default = Ember.Controller.extend({
    envData: Ember.computed('model.[]', function () {
      return this.get('model');
    }),
    dataAll: Ember.computed('model.[@each]', function () {
      let res = {
        counts: 0,
        active: 0,
        unknown: 0,
        late: 0,
        completed: 0
      };
      this.get('model.[]').forEach(element => {
        res.counts += element.counts;
      });
      return res;
    })
  });

  _exports.default = _default;
});