define("printpost/routes/cart", ["exports", "ember-concurrency", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.hash({
        'request': this.get('requestTask').perform()
      });
    },
    requestTask: (0, _emberConcurrency.task)(function* () {
      let url = this.baseapi.apiLink('Requests');
      let query = {
        filter: {
          where: {
            status: 'Pendente'
          },
          include: 'wallet',
          order: 'id DESC'
        }
      };
      let strquery = (0, _globalFunctions.apiLinkQuery)(query);
      let call = this.baseapi.getHttp(url + "/list?" + strquery);
      let r = yield call.then(response => response.data);
      return r;
    }).keepLatest()
  });

  _exports.default = _default;
});