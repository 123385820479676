define("printpost/routes/operacao/fornecedores", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      reference: null
    },
    model: async function (queryParams) {
      var params = queryParams;
      var objDate = new Date();
      const dateStart = `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T00:00:00-03:00`;
      const strDate = `${this.baseapi.padStr(objDate.getDate())}/${this.baseapi.padStr(objDate.getMonth() + 1)}/${objDate.getFullYear()}`;
      let query = {
        filter: {
          where: {
            status: {
              inq: ['Finalizado', 'Em Execução']
            },
            // number: { inq: [17618, 17617] },
            or: [{
              emailService: true
            }, {
              smsService: true
            }],
            createdAt: {
              gte: dateStart
            }
          }
        }
      };
      const strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url = this.baseapi.apiLink('Providers/quality');
      var objData = {
        requestList: [],
        providerList: []
      };

      try {
        objData = await this.baseapi.getHttp(`${url}?${strquery}`).then(x => x.data);
      } catch (error) {
        this.toast.error('', 'Erro ao buscar dados', {
          positionClass: 'toast-bottom-right'
        });
      }

      objData.requestList.forEach(x => {
        x.chart = {
          "datasets": [{
            "label": "Desempenho",
            "fill": false,
            "data": [x.received, x.notReceived],
            "backgroundColor": ["#50e2d4", "#ff8da0"],
            "borderColor": ["#50e2d4", "#ff8da0"],
            "borderWidth": 0
          }],
          "color": ["#50e2d4", "#ff8da0"],
          "labels": ["Recebido", "Não recebido"]
        };
      });
      objData.providerList.forEach(x => {
        x.chart = {
          "datasets": [{
            "label": "Desempenho",
            "fill": false,
            "data": [x.received, x.notReceived],
            "backgroundColor": ["#50e2d4", "#ff8da0"],
            "borderColor": ["#50e2d4", "#ff8da0"],
            "borderWidth": 0
          }],
          "color": ["#50e2d4", "#ff8da0"],
          "labels": ["Recebido", "Não recebido"]
        };
      });
      return Ember.RSVP.hash({
        data: objData.requestList,
        providerData: objData.providerList,
        dateStart: objDate,
        strDate: strDate
      });
    }
  });

  _exports.default = _default;
});