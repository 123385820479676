define("printpost/templates/components/drag-headerbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lGqYbWKr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[9],[1,[21,\"x_header\"],false],[0,\",\"],[1,[21,\"y_header\"],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/drag-headerbox.hbs"
    }
  });

  _exports.default = _default;
});