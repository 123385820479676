define("printpost/routes/services/email", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resourceName: 'Request',
    baseapi: Ember.inject.service(),
    model: async function () {
      var url_products = this.baseapi.apiLink('Products/list');
      const obj_products = await this.baseapi.getHttp(url_products).then(response => {
        let objReturn = [];
        response.data.forEach(element => {
          let item = {
            "id": element.id,
            "name": element.name,
            "colors": element.colors,
            "unitWeight": element.unitWeight,
            "productionCost": element.productionCost,
            "logisticCost": element.logisticCost,
            "minimumPrice": element.minimumPrice,
            "deletedAt": element.deletedAt,
            "selectText": element.name,
            "selectId": element.id,
            "selectValue": element.id
          };
          objReturn.push(item);
        });
        return objReturn;
      });
      let newRequest = JSON.parse(localStorage.getItem('storage:request'));
      let userId = newRequest.userId; //canais

      var url_canais = this.baseapi.apiLink('Channels/list');
      const obj_canais = await this.baseapi.getHttp(url_canais).then(r => r.data);
      var canal = obj_canais.find(x => x.value == "email"); //templates

      var query = {
        filter: {
          where: {
            channelId: canal.id,
            userId: newRequest.userId
          },
          include: 'channel'
        }
      }; // if (newRequest.organizationId) {
      //     delete query.filter.where.userId;
      //     query.filter.where.organizationId = newRequest.organizationId;
      // }

      var strquery = (0, _globalFunctions.apiLinkQuery)(query); //templates

      var url_templates = this.baseapi.apiLink('Templates/list');
      const obj_templates = await this.baseapi.getHttp(`${url_templates}?fu=true&${strquery}`).then(r => r.data);
      return Ember.RSVP.hash({
        products: obj_products,
        templates: obj_templates,
        channel: canal
      });
    }
  });

  _exports.default = _default;
});