define("printpost/controllers/services/new", ["exports", "printpost/controllers/services", "printpost/utils/globalFunctions", "ember-concurrency"], function (_exports, _services, _globalFunctions, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = Ember.Object.extend({
    dataSendOption: null,
    empty_resp: null
  });

  var _default = _services.default.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service('session'),
    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }),
    loadInit: Ember.computed(function () {
      setTimeout(() => {
        this.get('newRequest').reset();
        this.get('storageTemplateSelected').reset();
        this.get('requestOptions').reset();
        this.get('walletColumns').reset();
        this.set('selectedCostCenter', null);
        this.set('selectedRequestTagFilter', null);
        this.set('selectedFilter', null);
      }, 300);
      return 123;
    }).property().volatile(),
    variables: variables.create({
      dataSendOption: []
    }),
    smsChecked: Ember.computed('smsChecked', function () {
      return false;
    }),
    emailChecked: Ember.computed('emailChecked', function () {
      return false;
    }),
    letterChecked: Ember.computed('letterChecked', function () {
      return false;
    }),
    dataTotalValue: Ember.computed('model.totalValue', function () {
      let m = this.get('model.totalValue');
      return m;
    }).property().volatile(),
    dataPrices: Ember.computed('model.prices', function () {
      return this.get('model.prices');
    }).property().volatile(),
    dataOptionPrinted: Ember.computed('model.optionPrinted', function () {
      return this.get('model.optionPrinted');
    }),
    dataAccountList: Ember.computed('model.accounts', function () {
      let data = this.get('model.accounts');
      return data;
    }),
    userAdminSelected: Ember.computed('userAdminSelected', function () {
      let m = '';
      return m;
    }),
    adminValue: Ember.computed('adminValue', function () {
      let s = this.baseapi.getAdmValue();
      return s;
    }),
    accountUser: Ember.computed('accountUser', function () {
      let s = this.session.data.authenticated.account;
      return s;
    }),
    dataCategory: Ember.computed('dataCategory', function () {
      return '';
    }),
    selectedFilter: Ember.computed('selectedFilter', function () {
      let a = this.get('model.accounts').find(x => x.id == this.get('newRequest.userId'));

      if (a) {
        if (a.type == this.get('adminValue')) {
          this.set('newRequest.userId', '');
          return null;
        }

        this.set('userAdminSelected', a.id); // this.getLinkCustomerAccount(a.id, false);

        return a;
      }

      return null;
    }).property().volatile(),
    selectedCostCenter: Ember.computed(function () {
      return null;
    }).property().volatile(),
    selectedRequestTagFilter: Ember.computed(function () {
      return null;
    }).property().volatile(),
    loadRequestTags: async function (id) {
      //request tags      
      var query_tag = {
        filter: {
          where: {
            userId: id
          }
        }
      };
      var strquery_tag = (0, _globalFunctions.apiLinkQuery)(query_tag);
      let request_tag = this.baseapi.apiLink(`RequestTags/list?${strquery_tag}`);
      const obj_request_tags = await this.baseapi.getHttp(request_tag).then(resp => resp.data);
      this.set('model.request_tags', obj_request_tags);
    },
    loadCostCenter: async function (id) {
      //request tags      
      var query_tag = {
        filter: {
          where: {
            userId: id
          }
        }
      };
      var strquery_tag = (0, _globalFunctions.apiLinkQuery)(query_tag);
      let request_tag = this.baseapi.apiLink(`CostCenters/list?${strquery_tag}`);
      const obj_costcenter = await this.baseapi.getHttp(request_tag).then(resp => resp.data);
      this.set('model.obj_costcenter', obj_costcenter);
    },
    getLinkCustomerAccount: async function (id, reloadTag = true) {
      if (id) {
        this.preloader.toggleClass('d-none');
        var query = {
          filter: {
            where: {
              userId: id,
              active: true
            }
          }
        };
        var strquery = (0, _globalFunctions.apiLinkQuery)(query);
        let notAdmin = this.baseapi.getCurrentUser().type != this.baseapi.getAdmValue() ? "list" : "find";
        let url_prices = this.baseapi.apiLink(`LinkCustomers/${notAdmin}?${strquery}`);
        const obj_prices_customer = await this.baseapi.getHttp(url_prices).then(resp => resp.data);
        let prices_general = this.get('model.obj_prices_general');
        var obj_prices = [];

        if (obj_prices_customer && obj_prices_customer.length > 0) {
          this.set('dataPrices', obj_prices_customer);
          this.set('model.prices', obj_prices_customer);
          obj_prices = obj_prices_customer;
        } else if (prices_general && prices_general.length > 0) {
          this.set('dataPrices', prices_general);
          this.set('model.prices', prices_general);
          obj_prices = prices_general;
        } else {
          this.set('dataPrices', []);
          this.set('model.prices', []);
          obj_prices = [];
        }

        var channelSimulator = [];
        obj_prices.map(item => {
          var rsi = {
            channel: item.channel,
            quantity: 0,
            channelId: item.channelId,
            channelValue: item.channelValue,
            options: item.options
          };
          channelSimulator.push(rsi);
        });
        var optionPrinted = channelSimulator.find(x => x.channelValue == "carta") ? true : false;
        await this.loadRequestTags(id);
        await this.loadCostCenter(id);
        this.set('model.optionPrinted', optionPrinted);
        this.set('model.channelSimulator', channelSimulator);
        this.set('dataTotalValue', '0,00');
        this.set('model.totalValue', '0,00');
        let v = {
          format: '',
          grammage: '',
          papper: '',
          formatValue: '',
          sendValue: '',
          colored: false,
          blackWhite: false
        };
        this.set('model.computedItens.dataPrintOptions', v);
        this.set('havingSendOption', false);
        this.set('havingOptionColor', false);
        this.set('newRequest.letterService', false);
        this.set('newRequest.emailService', false);
        this.set('newRequest.smsService', false);

        if (reloadTag) {
          this.set('selectedRequestTagFilter', null);
        }

        this.preloader.toggleClass('d-none');
      }
    },
    dataPrintOptions: Ember.computed('dataPrintOptions', function () {
      let v = {
        format: '',
        grammage: '',
        papper: '',
        formatValue: '',
        sendValue: '',
        colored: false,
        blackWhite: false
      };
      return v;
    }),
    dataPrintOptionsView: Ember.computed('dataPrintOptionsView', function () {
      let m = this.get('model.prices').find(x => x.channelValue.toLowerCase() == "carta").formatOptions;
      let v = {
        format: m.format,
        grammage: m.grammage,
        papper: m.papper,
        colored: false,
        blackWhite: false,
        formatValue: ''
      };
      return v;
    }),
    dataSimulator: Ember.computed('dataSimulator', function () {
      return {
        cep: '',
        freight: []
      };
    }),
    havingOptionColor: Ember.computed('havingOptionColor', function () {
      let m = false;
      return m;
    }),
    praticatePrices: Ember.computed('praticatePrices', function () {
      return {
        carta: {
          priceVolumeGroup: '0,000',
          priceSale: '0,000',
          totalCost: '0,000',
          quantity: 0,
          channelId: "",
          channelValue: ""
        },
        email: {
          priceVolumeGroup: '0,000',
          priceSale: '0,000',
          totalCost: '0,000',
          quantity: 0,
          channelId: "",
          channelValue: ""
        },
        sms: {
          priceVolumeGroup: '0,000',
          priceSale: '0,000',
          totalCost: '0,000',
          quantity: 0,
          channelId: "",
          channelValue: "",
          smsExceedCharacters: []
        }
      };
    }),
    havingSendOption: Ember.computed('havingSendOption', function () {
      let m = false;
      return m;
    }),
    // dataSendOption: computed('dataSendOption', function() {
    //   let m = [];
    //   return m;
    // }),
    dataPapperWeights: Ember.computed('model.papperWeight', function () {
      return this.get('model.papperWeight');
    }),
    trasitionTask: (0, _emberConcurrency.task)(function* () {
      let channels = this.get('model.channelSimulator');
      let qtdServices = 0;
      qtdServices = Ember.get(this, 'newRequest.smsService') + Ember.get(this, 'newRequest.emailService') + Ember.get(this, 'newRequest.letterService');
      let valueMultichannel = qtdServices < 2 ? false : true;
      Ember.set(this, 'requestOptions.options.showMultichannel', valueMultichannel);
      Ember.set(this, 'newRequest.multiChannel', valueMultichannel);
      let imediate = !valueMultichannel;
      Ember.set(this, 'newRequest.printingOptions.templateLetter.disparoImediato', imediate);
      Ember.set(this, 'newRequest.printingOptions.templateSMS.disparoImediato', imediate);
      Ember.set(this, 'newRequest.printingOptions.templateEmail.disparoImediato', imediate);
      channels.forEach(element => {
        this.send('setQuantitySimulator', element.channelId);
      });
      this.send('salvarPendente'); // let nextWallet = get(this, 'newRequest.walletId');
      // if(get(this, 'newRequest.letterService')) {
      //   yield this.transitionToRoute('services.config')
      // }else if(get(this, 'newRequest.smsService')) {
      //   yield this.transitionToRoute('services.sms',  { queryParams: { wallet: nextWallet } })
      // }else if(get(this, 'newRequest.emailService')) { 
      //   yield this.transitionToRoute('services.email', { queryParams: { wallet: nextWallet } })
      // }

      this.send('serviceNextRouter');
    }).maxConcurrency(3).enqueue(),
    changeStorageRequestLetterFunc: function (chave, valor) {
      let _newReq = JSON.parse(localStorage.getItem('storage:request'));

      _newReq.printingOptions.templateLetter[chave] = valor;
      localStorage.setItem('storage:request', JSON.stringify(_newReq));
      Ember.set(this, 'newRequest.printingOptions.templateLetter.' + chave, valor);
    },
    actions: {
      simulatorItens: function () {
        //let dpc = get(this, 'dataPrices');
        let dpc = Ember.get(this, 'praticatePrices');
        let dpo = Ember.get(this, 'model.computedItens.dataPrintOptions');
        let channelsItens = ["carta", "email", "sms"];
        let f = dpo.format;
        let g = dpo.grammage;
        let p = dpo.papper; // calcular o valor total de todos os produtos e os totais individuais

        let t = 0;
        channelsItens.forEach(ci => {
          let item = dpc[ci];

          if (!item.channelId) {
            return;
          }

          if (Ember.get(this, 'newRequest.letterService')) {
            if (ci == "carta" && (!f || !g || !p || dpo.blackWhite == false && dpo.colored == false || !dpo.sendValue)) {
              this.toast.error('', 'É necessário informar a combinação do produto e o envio!.', {
                positionClass: 'toast-bottom-right'
              });
              return;
            }
          }

          let pt = this.baseapi.realStringConvertDecimal(item.priceSale);
          let discount_volume = this.baseapi.realStringConvertDecimal(item.priceVolumeGroup);
          let qt = Ember.$("#" + item.channelId).val();

          if (!qt) {
            return;
          }

          let v = pt * qt;

          if (discount_volume > 0) {
            v = v - v * (discount_volume / 100);
          }

          let v_str = this.baseapi.numberToReal(v);
          Ember.$("#vlrs" + item.channelId).html(`R$ ${v_str}`);
          t += v;
        });
        Ember.set(this, 'requestOptions.dataPrintOptions', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        Ember.set(this, 'newRequest.printingOptions.templateLetter.definition', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        Ember.set(this, 'requestOptions.dataPrices', Ember.get(this, 'dataPrices'));
        Ember.set(this, 'requestOptions.dataSendOption', this.variables.get('dataSendOption'));
        Ember.set(this, 'requestOptions.praticatePrices', Ember.get(this, 'praticatePrices'));
        let tstr = this.baseapi.numberToReal(t);
        Ember.set(this, 'dataTotalValue', tstr);
        this.send('saveRequestPrices');
      },
      setQuantitySimulator: function (channelId) {
        let dpc = Ember.get(this, 'dataPrices');
        let dpo = Ember.get(this, 'model.computedItens.dataPrintOptions');

        if (channelId) {
          // for get value price volume
          dpc.forEach(item => {
            if (item.channelId == channelId) {
              // let dp = dpc.find(x => x.id == id);
              let prs = item.options[0];

              if (Ember.get(this, 'newRequest.letterService') && item.channelValue == "carta") {
                let f = item.formatOptions.format.find(x => x.value == dpo.format);
                let g = item.formatOptions.grammage.find(x => x.value == dpo.grammage);
                let p = item.formatOptions.papper.find(x => x.value == dpo.papper);

                if (item.channelValue == "carta" && (!f || !g || !p || dpo.blackWhite == false && dpo.colored == false || !dpo.sendValue)) {
                  this.toast.error('', 'É necessário informar a combinação do produto e o envio!!.', {
                    positionClass: 'toast-bottom-right'
                  });
                  return;
                }

                if (item.channelValue == "carta") {
                  let strColor = dpo.colored ? "Colorido" : "Preto e Branco";
                  let sendName = Ember.get(this, 'newRequest.shippingMethod');
                  prs = item.options.find(x => x.format == dpo.formatValue && x.send.toLowerCase() == sendName.toLowerCase() && x.color.toLowerCase() == strColor.toLowerCase());
                }
              } else if (Ember.get(this, 'newRequest.smsService') && item.channelValue == "sms") {
                prs = item.options.find(x => x.send.toLowerCase() == Ember.get(this, 'newRequest.printingOptions.templateSMS.definition.sendValue').toLowerCase());
              } else if (Ember.get(this, 'newRequest.emailService') && item.channelValue == "email") {
                prs = item.options.find(x => x.send.toLowerCase() == Ember.get(this, 'newRequest.printingOptions.templateEmail.definition.sendValue').toLowerCase());
              }

              let q = Ember.$("#" + channelId).val();
              let strPraticaPrice = `praticatePrices.${item.channelValue}`;
              Ember.set(this, `${strPraticaPrice}.quantity`, q);
              Ember.set(this, `${strPraticaPrice}.channelId`, channelId);
              Ember.set(this, `${strPraticaPrice}.channelValue`, item.channelValue);

              if (prs) {
                Ember.set(this, `${strPraticaPrice}.priceSale`, prs.priceSale);
              } //volume discount


              if (prs && prs.volumeItens && prs.volumeItens.length > 0) {
                let v = prs.volumeItens.find(x => x.min <= q && q <= x.max);
                let priceVolumeGroup = Ember.get(this, `${strPraticaPrice}.priceVolumeGroup`);

                if (v && v.percentage != priceVolumeGroup) {
                  Ember.set(this, `${strPraticaPrice}.priceVolumeGroup`, v.percentage);
                } else if (v && v.percentage == priceVolumeGroup) {
                  Ember.set(this, `${strPraticaPrice}.priceVolumeGroup`, priceVolumeGroup);
                } else {
                  Ember.set(this, `${strPraticaPrice}.priceVolumeGroup`, "0,00");
                }
              } else {
                Ember.set(this, `${strPraticaPrice}.priceVolumeGroup`, "0,00");
              }
            }
          }); // set(this, 'dataPrices', newItens);
          // dpc = newItens;

          this.send('simulatorItens', null);
          this.send('saveRequestPrices');
        }
      },
      setFormatSimulator: function (id, type) {
        let stritem = 'model.computedItens.dataPrintOptions.' + type;
        Ember.set(this, stritem, id);
        let dpo = Ember.get(this, 'model.computedItens.dataPrintOptions');
        Ember.set(this, 'newRequest.letterService', true);

        if (dpo.format && dpo.grammage && dpo.papper) {
          let prices = Ember.get(this, 'dataPrices').find(x => x.channelValue == "carta");
          Ember.set(this, 'newRequest.shippingMethod', "");
          Ember.set(this, 'newRequest.colored', "");
          Ember.$("#" + prices.channelId).val(0);
          Ember.$("#vlrs" + prices.channelId).html("R$ 0,00");
          Ember.set(this, 'model.computedItens.dataPrintOptions.colored', false);
          Ember.set(this, 'model.computedItens.dataPrintOptions.blackWhite', false);
          Ember.set(this, 'dataPrintOptionsView.colored', false);
          Ember.set(this, 'dataPrintOptionsView.blackWhite', false);
          Ember.set(this, 'havingSendOption', false);

          if (prices) {
            let f = prices.formatOptions.format.find(x => x.value == dpo.format);
            let g = prices.formatOptions.grammage.find(x => x.value == dpo.grammage);
            let p = prices.formatOptions.papper.find(x => x.value == dpo.papper);

            if (!f || !g || !p) {
              return;
            }

            let formatValue = `${f.name} /${g.name} /${p.name}`;
            Ember.set(this, 'model.computedItens.dataPrintOptions.formatValue', formatValue);
            let itensPrices = prices.options.filter(x => x.format == formatValue);

            if (itensPrices) {
              if (itensPrices.find(x => x.color == "Colorido")) {
                Ember.set(this, 'havingOptionColor', true);
                Ember.set(this, 'dataPrintOptionsView.colored', true);
              }

              if (itensPrices.find(x => x.color == "Preto e Branco")) {
                Ember.set(this, 'havingOptionColor', true);
                Ember.set(this, 'dataPrintOptionsView.blackWhite', true);
              }
            }
          }
        }

        this.changeStorageRequestLetterFunc('definition', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        Ember.set(this, 'requestOptions.dataPrintOptions', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        Ember.set(this, 'newRequest.printingOptions.templateLetter.definition', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        Ember.set(this, 'requestOptions.dataPrices', Ember.get(this, 'dataPrices'));
        return;
      },
      setColorSimulator: function (hasColored) {
        var colorido = hasColored ? true : false;
        var blackWhite = !hasColored ? true : false;
        Ember.set(this, 'model.computedItens.dataPrintOptions.colored', colorido);
        Ember.set(this, 'model.computedItens.dataPrintOptions.blackWhite', blackWhite);
        this.changeStorageRequestLetterFunc('definition', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        let strColor = hasColored ? "Colorido" : "Preto e Branco";
        Ember.set(this, 'newRequest.colored', hasColored);
        let prices = Ember.get(this, 'dataPrices').find(x => x.channelValue == "carta");
        let dpo = Ember.get(this, 'model.computedItens.dataPrintOptions');
        let formatValue = dpo.formatValue;
        let itensPrices = prices.options.filter(x => x.format == formatValue && x.color.toLowerCase() == strColor.toLowerCase());
        Ember.$("#" + prices.channelId).val(0);
        Ember.$("#vlrs" + prices.channelId).html("R$ 0,00");
        let itens_send = [];
        itensPrices.map(item => {
          let obj = {
            name: (0, _globalFunctions.firstUpper)(item.send),
            value: (0, _globalFunctions.removeSpecialChar)(item.send.toLowerCase())
          };

          if (!itens_send.find(x => x.value == obj.value)) {
            itens_send.push(obj);
          }
        });
        Ember.set(this, 'havingSendOption', true); // set(this, 'dataSendOption', itens_send);
        // this.set('dataSendOption', itens_send);

        this.variables.set('dataSendOption', itens_send);
        Ember.set(this, 'requestOptions.dataPrintOptions', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        Ember.set(this, 'newRequest.printingOptions.templateLetter.definition', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        Ember.set(this, 'requestOptions.dataPrices', Ember.get(this, 'dataPrices'));
        Ember.set(this, 'requestOptions.dataSendOption', this.variables.get('dataSendOption'));
        return;
      },
      setService: function (name, val) {
        let s = name.toLowerCase();

        if (s == "carta") {
          Ember.set(this, 'newRequest.letterService', val);
        } else if (s == "sms") {
          Ember.set(this, 'newRequest.smsService', val);
        } else if (s == "email") {
          Ember.set(this, 'newRequest.emailService', val);
        }

        Ember.set(this, 'requestOptions.dataPrintOptions', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        Ember.set(this, 'newRequest.printingOptions.templateLetter.definition', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        Ember.set(this, 'requestOptions.dataPrices', Ember.get(this, 'dataPrices'));
        Ember.set(this, 'requestOptions.dataSendOption', this.variables.get('dataSendOption'));
        Ember.set(this, 'requestOptions.praticatePrices', Ember.get(this, 'praticatePrices'));
      },
      updateSimulatorCEP: function (itemValue) {
        Ember.set(this, 'dataSimulator.cep', itemValue);
      },
      calculateFrete: function () {
        let cep = Ember.get(this, 'dataSimulator.cep');
        let options_format = Ember.get(this, 'model.computedItens.dataPrintOptions');
        let papper_weight = Ember.get(this, 'dataPapperWeights');
        let quantity = Ember.$('.quantidade-carta').val();
        let w = papper_weight.find(x => x.format == options_format.format.toLowerCase() && x.grammage == options_format.grammage.toLowerCase());
        let peso = 1;

        if (w && cep.length > 3) {
          let wd = this.baseapi.realStringConvertDecimal(w.weight);
          peso = quantity * wd / 1000;
          peso = Math.ceil(peso);

          if (peso > 0 && quantity > 0) {
            let url = this.baseapi.apiLink("PricingTables/frete?peso=" + peso + "&cep=" + cep);
            this.baseapi.getHttp(url).then(resp => {
              let v = resp.data;
              Ember.set(this, 'dataSimulator.freight', v);
            });
          } else {
            this.toast.error('', 'Informe uma quantidade de cartas válidas para calcular o frete.', {
              positionClass: 'toast-bottom-right'
            });
          }
        } else {
          this.toast.error('', 'Informe um CEP para calcular o frete.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      setShippingMethod: function (value, channelValue) {
        let prices = Ember.get(this, 'dataPrices').find(x => x.channelValue == channelValue);
        Ember.$("#" + prices.channelId).val(0);
        Ember.$("#vlrs" + prices.channelId).html("R$ 0,00");

        if (channelValue == "carta") {
          Ember.set(this, 'newRequest.shippingMethod', value);
          Ember.set(this, 'model.computedItens.dataPrintOptions.sendValue', value);
          Ember.set(this, 'requestOptions.dataPrintOptions', Ember.get(this, 'model.computedItens.dataPrintOptions'));
          Ember.set(this, 'requestOptions.dataPrices', Ember.get(this, 'dataPrices'));
          Ember.set(this, 'requestOptions.dataSendOption', this.variables.get('dataSendOption'));
          Ember.set(this, 'newRequest.printingOptions.templateLetter.definition', Ember.get(this, 'model.computedItens.dataPrintOptions'));
        }

        let strSet = "newRequest.printingOptions.templateSMS.definition";
        let strCheck = "newRequest.smsService";

        if (channelValue == "sms") {
          strSet = "newRequest.printingOptions.templateSMS.definition";
          strCheck = "newRequest.smsService";
        } else if (channelValue == "email") {
          strSet = "newRequest.printingOptions.templateEmail.definition";
          strCheck = "newRequest.emailService";
        }

        if (channelValue != "carta") {
          Ember.set(this, strSet, {
            sendValue: value
          });
          Ember.set(this, strCheck, true);
        }

        let dpc = Ember.get(this, 'dataPrices');
        let dpo = Ember.get(this, 'model.computedItens.dataPrintOptions');
        dpc.forEach(item => {
          let prs = null;

          if (Ember.get(this, 'newRequest.letterService') && item.channelValue == "carta" && channelValue == "carta") {
            let f = item.formatOptions.format.find(x => x.value == dpo.format);
            let g = item.formatOptions.grammage.find(x => x.value == dpo.grammage);
            let p = item.formatOptions.papper.find(x => x.value == dpo.papper);

            if (item.channelValue == "carta" && (!f || !g || !p || dpo.blackWhite == false && dpo.colored == false || !dpo.sendValue)) {
              this.toast.error('', 'É necessário informar a combinação do produto e o envio!!!.', {
                positionClass: 'toast-bottom-right'
              });
              return;
            }

            if (item.channelValue == "carta") {
              let strColor = dpo.colored ? "Colorido" : "Preto e Branco";
              let sendName = Ember.get(this, 'newRequest.shippingMethod');
              prs = item.options.find(x => x.format == dpo.formatValue && x.send.toLowerCase() == sendName.toLowerCase() && x.color.toLowerCase() == strColor.toLowerCase());
            }
          } else if (Ember.get(this, 'newRequest.smsService') && item.channelValue == "sms" && channelValue == "sms") {
            prs = item.options.find(x => x.send.toLowerCase() == value.toLowerCase());
          } else if (Ember.get(this, 'newRequest.emailService') && item.channelValue == "email" && channelValue == "email") {
            prs = item.options.find(x => x.send.toLowerCase() == value.toLowerCase());
          }

          let strPraticaPrice = `praticatePrices.${item.channelValue}`;
          Ember.set(this, `${strPraticaPrice}.channelId`, item.channelId);
          Ember.set(this, `${strPraticaPrice}.channelValue`, item.channelValue);

          if (prs) {
            Ember.set(this, `${strPraticaPrice}.priceSale`, prs.priceSale);
          }
        });
        Ember.set(this, 'newRequest.praticatePrices', Ember.get(this, 'praticatePrices'));
        Ember.set(this, 'requestOptions.praticatePrices', Ember.get(this, 'praticatePrices'));
      },
      saveRequestPrices: function () {
        let dpc = Ember.get(this, 'praticatePrices');
        Ember.set(this, 'newRequest.praticatePrices', dpc);
      },
      setAccount: function (subAccount = false, a) {
        this.set('selectedFilter', a);
        this.set('userAdminSelected', a.id);
        this.set('newRequest.userId', a.id);

        if (subAccount) {
          this.set('userAdminSelected', a.subAccountId);
          this.set('newRequest.userId', a.subAccountId);
        }

        if (a.organizationId) {
          this.set('newRequest.organizationId', a.organizationId);
        }

        if (!a || !a.id) {
          this.toast.error('', 'Erro ao selecionar usuário, tente novamente.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        if (subAccount) {
          this.getLinkCustomerAccount(a.subAccountId);
        } else {
          this.getLinkCustomerAccount(a.id);
        }
      },

      deleteFilter(filter) {
        var removeItem = this.get('model.request_tags').findBy('name', filter.name);
        this.get('model.request_tags').removeObject(removeItem);
        let url = this.baseapi.apiLink(`RequestTags/delete/${filter.id}`);
        let call = this.baseapi.delete(url);
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.variables.set('empty_resp', resp);
          this.preloader.toggleClass('d-none');
        }, error => {
          this.variables.set('empty_resp', error);
          this.preloader.toggleClass('d-none');
        });
      },

      setRequestTag: function (rt) {
        this.set('selectedRequestTagFilter', rt);
        this.set('newRequest.title', rt.name);
        this.set('newRequest.requestTagId', rt.id);
        var v2 = this.get('requestOptions');
      },

      loadRequestTags() {
        this.loadRequestTags(this.get('userAdminSelected'));
      },

      saveRequestTag() {
        var account = this.get('accountUser');
        var idUser = account.id;
        var idUserSelected = this.get('userAdminSelected');

        if (idUserSelected) {
          idUser = idUserSelected;
        }

        if (!idUser) {
          this.toast.error('', 'É necessário selecionar o usuário.', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        var obj = {
          data: {
            type: "RequestTag",
            attributes: {
              name: this.get('dataCategory'),
              userId: idUser
            }
          }
        };
        let url = this.baseapi.apiLink('RequestTags');
        let call = this.baseapi.post(url, obj);
        this.preloader.toggleClass('d-none');
        call.then(resp => {
          this.preloader.toggleClass('d-none');
          this.variables.set('empty_resp', resp);
          this.toast.success('', 'Item cadastrado com sucesso', {
            positionClass: 'toast-bottom-right'
          });
        }, error => {
          this.preloader.toggleClass('d-none');
          this.variables.set('empty_resp', error);
          this.toast.error('', 'Erro ao cadastrar item.', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      nextRouter() {
        Ember.get(this, 'trasitionTask').perform();
      },

      setViewTutorial(link) {
        Ember.set(this, 'urlVideoTutorial', link);
      },

      showSimulator() {
        Ember.$("#boxSimulator").toggleClass("simulator-in");
        Ember.$("#boxSimulator").toggleClass("simulator-out");
        Ember.$("#simulator-chevron").toggleClass("fa-chevron-down");
        Ember.$("#simulator-chevron").toggleClass("fa-chevron-up");
      },

      pageReload() {
        // this.send('setShippingMethod', null, "sms");
        // var r = $("input[name='simulator_send_sms']");
        // console.log(r, r.length);
        // if(r.length  > 0) {
        //   for (let i = 0; i < r.length; i++) {
        //     const item = r[i];
        //     console.log('item', item);
        //     $(item).attr('checked', false);
        //   }
        // }
        window.location.reload(true);
      },

      setCostCenter(item) {
        Ember.set(this, 'newRequest.costCenterId', item.id);
        Ember.set(this, 'selectedCostCenter', item);
      }

    }
  });

  _exports.default = _default;
});