define("printpost/adapters/user", ["exports", "printpost/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      if (query.me) {
        delete query.me;
        return `${this._super(...arguments)}` + 'me';
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});