define("printpost/templates/cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uwVCeXba",
    "block": "{\"symbols\":[],\"statements\":[[4,\"leftside-nav\",null,null,{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"cloud.instances\"],[[\"tagName\"],[\"li\"]],{\"statements\":[[7,\"a\"],[11,\"href\",\"\"],[9],[0,\"Instâncias\"],[10]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"link-to\",[\"cloud.images\"],[[\"tagName\"],[\"li\"]],{\"statements\":[[7,\"a\"],[11,\"href\",\"\"],[9],[0,\"Imagens\"],[10]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/cloud.hbs"
    }
  });

  _exports.default = _default;
});