define("printpost/routes/unsubsuccess", ["exports", "printpost/mixins/open-route-mixin"], function (_exports, _openRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_openRouteMixin.default, {
    hasLayout: false,
    model: function () {
      setTimeout(() => {
        window.location.href = '/sign/in';
      }, 3000);
    }
  });

  _exports.default = _default;
});