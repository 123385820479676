define("printpost/components/wizard-js", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['wizard-js'],
    actions: {
      next() {
        function scroll_to_class(element_class, removed_height) {
          var scroll_to = Ember.$(element_class).offset().top - removed_height;

          if (Ember.$(window).scrollTop() != scroll_to) {
            Ember.$('html, body').stop().animate({
              scrollTop: scroll_to
            }, 0);
          }
        }

        function bar_progress(progress_line_object, direction) {
          var number_of_steps = progress_line_object.data('number-of-steps');
          var now_value = progress_line_object.data('now-value');
          var new_value = 0;

          if (direction == 'right') {
            new_value = now_value + 100 / number_of_steps;
          } else if (direction == 'left') {
            new_value = now_value - 100 / number_of_steps;
          }

          progress_line_object.attr('style', 'width: ' + new_value + '%;').data('now-value', new_value);
        }

        Ember.$('.f1 .btn-next').on('click', function () {
          var parent_fieldset = Ember.$(this).parents('fieldset');
          var next_step = true; // navigation steps / progress steps

          var current_active_step = Ember.$(this).parents('.f1').find('.f1-step.active');
          var progress_line = Ember.$(this).parents('.f1').find('.f1-progress-line'); // fields validation

          parent_fieldset.find('input[type="text"], input[type="password"], textarea').each(function () {
            if (Ember.$(this).val() == "") {
              Ember.$(this).addClass('input-error');
              next_step = false;
            } else {
              Ember.$(this).removeClass('input-error');
            }
          }); // fields validation

          if (next_step) {
            parent_fieldset.fadeOut(400, function () {
              // change icons
              current_active_step.removeClass('active').addClass('activated').next().addClass('active'); // progress bar

              bar_progress(progress_line, 'right'); // show next step

              Ember.$(this).next().fadeIn(); // scroll window to beginning of the form

              scroll_to_class(Ember.$('.f1'), 20);
            });
          }
        });
      },

      previous() {
        function scroll_to_class(element_class, removed_height) {
          var scroll_to = Ember.$(element_class).offset().top - removed_height;

          if (Ember.$(window).scrollTop() != scroll_to) {
            Ember.$('html, body').stop().animate({
              scrollTop: scroll_to
            }, 0);
          }
        }

        function bar_progress(progress_line_object, direction) {
          var number_of_steps = progress_line_object.data('number-of-steps');
          var now_value = progress_line_object.data('now-value');
          var new_value = 0;

          if (direction == 'right') {
            new_value = now_value + 100 / number_of_steps;
          } else if (direction == 'left') {
            new_value = now_value - 100 / number_of_steps;
          }

          progress_line_object.attr('style', 'width: ' + new_value + '%;').data('now-value', new_value);
        }

        Ember.$('.f1 .btn-previous').on('click', function () {
          // navigation steps / progress steps
          var current_active_step = Ember.$(this).parents('.f1').find('.f1-step.active');
          var progress_line = Ember.$(this).parents('.f1').find('.f1-progress-line');
          Ember.$(this).parents('fieldset').fadeOut(400, function () {
            // change icons
            current_active_step.removeClass('active').prev().removeClass('activated').addClass('active'); // progress bar

            bar_progress(progress_line, 'left'); // show previous step

            Ember.$(this).prev().fadeIn(); // scroll window to beginning of the form

            scroll_to_class(Ember.$('.f1'), 20);
          });
        });
      }

    }
  });

  _exports.default = _default;
});