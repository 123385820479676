define("printpost/controllers/operacao/enrich", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    xlsFile: Ember.computed(function () {
      return null;
    }),
    enrichDataTask: (0, _emberConcurrency.task)(function* () {
      let xls = Ember.get(this, 'xlsFile');
      Ember.set(this, 'isWaiting', true);

      try {
        let form = new FormData();
        form.append("arquivo_xls", xls);
        let url = this.baseapi.apiLink('Enrich/data');
        let data = yield this.baseapi.postFormData(url, form).then(resp => resp.data);
        Ember.set(this, 'isWaiting', false);

        if (data) {
          var blob = new Blob([data], {
            type: 'application/xlsx'
          });
          var linkElement = document.createElement('a');
          var urlDown = window.URL.createObjectURL(blob);
          linkElement.setAttribute('href', urlDown);
          linkElement.setAttribute("download", 'asdfasdf.xlsx');
          var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
          });
          linkElement.dispatchEvent(clickEvent);
        } else {
          this.toast.error('', 'Erro ao enriquecer dados.', {
            positionClass: 'toast-bottom-right'
          });
        }
      } catch (error) {
        this.toast.error('', 'Erro ao enriquecer dados.', {
          positionClass: 'toast-bottom-right'
        });
      }
    }),
    setFileExcelTask: (0, _emberConcurrency.task)(function (data) {
      Ember.set(this, 'xlsFile', data.target.files[0]);
    }).maxConcurrency(3).enqueue(),
    actions: {
      setFileExcel: function (file) {
        Ember.get(this, 'setFileExcelTask').perform(file).then();
      },
      enrichData: function () {
        Ember.get(this, 'enrichDataTask').perform();
      }
    }
  });

  _exports.default = _default;
});