define("printpost/components/whatsapp-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service('session'),
    whatsappMessage: '',
    messages: [],
    // message: '',
    actions: {
      sendMessage() {
        let msgs = this.get('messages');
        msgs.push(this.whatsappMessage);
        let newMsg = [];
        newMsg = newMsg.concat(msgs);
        this.set('messages', newMsg); // this.set('message', this.whatsappMessage)

        this.eventSend(this.whatsappMessage);
        this.set('whatsappMessage', '');
      }

    }
  });

  _exports.default = _default;
});