define("printpost/templates/components/sms-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2ubzach7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"filtered-container mx-auto\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"\"],[11,\"style\",\"z-index: 999;\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"id\",\"iframe-front\"],[11,\"class\",\"w-100 mt-5\"],[11,\"style\",\"\"],[9],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"view-phone-template box\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"screen\"],[9],[0,\"\\n                    \\n                    \"],[14,1],[0,\"\\n\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/sms-template.hbs"
    }
  });

  _exports.default = _default;
});