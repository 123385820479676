define("printpost/routes/services/sms/edit", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),

    model(params) {
      return {
        'templateId': params.id,
        'taskInstance': this.get('templateTask').perform(params.id)
      };
    },

    templateTask: (0, _emberConcurrency.task)(function* (id) {
      let request = yield this.baseapi.getTemplateById(id);
      return request;
    })
  });

  _exports.default = _default;
});