define("printpost/models/printing-price", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    colorPB: _emberData.default.attr('string'),
    colorPC: _emberData.default.attr('string'),
    colorColorful: _emberData.default.attr('string'),
    sizeoficio: _emberData.default.attr('string'),
    sizeA3: _emberData.default.attr('string'),
    sizeA4: _emberData.default.attr('string'),
    sizeA5: _emberData.default.attr('string'),
    apergaminhado: _emberData.default.attr('string'),
    coucheFosco: _emberData.default.attr('string'),
    coucheBrilho: _emberData.default.attr('string'),
    grammage: _emberData.default.attr('string')
  });

  _exports.default = _default;
});