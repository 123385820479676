define("printpost/templates/events/in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k8/4HFY7",
    "block": "{\"symbols\":[],\"statements\":[[15,\"events/all\",[]]],\"hasEval\":true}",
    "meta": {
      "moduleName": "printpost/templates/events/in.hbs"
    }
  });

  _exports.default = _default;
});