define("printpost/templates/components/navbar-tabbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h9ygTxOE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"nav\"],[11,\"class\",\"navbar navbar-tabbar\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"navbar-tabbar-container\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"nav navbar-nav\"],[9],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/navbar-tabbar.hbs"
    }
  });

  _exports.default = _default;
});