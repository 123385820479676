define("printpost/components/simple-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    size: 20,
    page: 1,
    actions: {
      listEvent(p, sum = true) {
        p = sum ? p + 1 : p;
        var paging = {
          limit: this.size,
          skip: (p - 1) * this.size
        };
        this.set('page', p);
        this.listEvent(paging);
      },

      listEventLt() {
        var p = this.page - 1 >= 1 ? this.page - 1 : 1;
        var paging = {
          limit: this.size,
          skip: (p - 1) * this.size
        };
        this.set('page', p);
        this.listEvent(paging);
      },

      listEventGt() {
        var p = this.page + 1 < this.attrs.totalPages.value ? this.page + 1 : this.attrs.totalPages.value;
        var paging = {
          limit: this.size,
          skip: (p - 1) * this.size
        };
        this.set('page', p);
        this.listEvent(paging);
      }

    }
  });

  _exports.default = _default;
});