define("printpost/models/account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    payment: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    cpf: _emberData.default.attr('string'),
    cnpj: _emberData.default.attr('string'),
    address: _emberData.default.attr(),
    companyId: _emberData.default.attr(),
    password: _emberData.default.attr()
  });

  _exports.default = _default;
});