define("printpost/routes/services/letter", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resourceName: 'Request',
    baseapi: Ember.inject.service(),
    model: async function () {
      var url_products = this.baseapi.apiLink('Products/list');
      const obj_products = await this.baseapi.getHttp(url_products).then(response => response.data); //canais

      var url_canais = this.baseapi.apiLink('Channels/list');
      const obj_canais = await this.baseapi.getHttp(url_canais).then(r => r.data);
      var canal = obj_canais.find(x => x.value == "carta"); //templates

      let newRequest = JSON.parse(localStorage.getItem('storage:request'));
      let query = {
        filter: {
          where: {
            channelId: canal.id,
            userId: newRequest.userId
          },
          fields: {
            internal: false,
            json: false,
            externalHtml: false,
            externalJson: false
          },
          include: ['channel']
        }
      }; // if(newRequest.organizationId) {
      //   delete query.filter.where.userId;
      //   query.filter.where.organizationId = newRequest.organizationId;
      // }

      let strquery = (0, _globalFunctions.apiLinkQuery)(query);
      var url_templates = this.baseapi.apiLink(`Templates/list?fu=true&${strquery}`);
      const obj_templates = await this.baseapi.getHttp(url_templates).then(r => r.data);
      return Ember.RSVP.hash({
        products: obj_products,
        templates: obj_templates,
        channel: canal,
        beginEdit: false
      });
    }
  });

  _exports.default = _default;
});