define("printpost/storages/print-options", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Storage = _object.default.extend();

  Storage.reopenClass({
    initialState() {
      return {
        type: 'carta',
        size: 'A4',
        grammage: 75,
        colors: 'PB',
        paper: 'coucheFosco',
        PC: {
          padrao: null
        },
        templateLetter: null,
        templateSMS: null,
        templateEmail: null,
        requestId: null,
        reload: false
      };
    }

  });
  var _default = Storage;
  _exports.default = _default;
});