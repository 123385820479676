define("printpost/services/session", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import {computed} from '@ember/object';
  //import PromiseObject from 'ember-data';
  var _default = _session.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    skipRedirectOnInvalidation: true
  });

  _exports.default = _default;
});