define("printpost/controllers/whitelabel/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service('session'),
    actions: {
      goToLink: function (item) {
        this.transitionToRoute(item);
      }
    }
  });

  _exports.default = _default;
});