define("printpost/routes/events/in", ["exports", "printpost/routes/events/all"], function (_exports, _all) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _all.default.extend({
    model(params) {
      return this.get('store').query('alert', {
        filter: {
          env_id: params.env_id
        }
      });
    }

  });

  _exports.default = _default;
});