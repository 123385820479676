define("printpost/components/counter-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["counter-block"],
    title: "Titulo",
    counter: 0,
    chart: function () {
      return {
        data: [],
        type: null
      };
    }
  });

  _exports.default = _default;
});