define("printpost/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kto4ogOV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"style\",\"align-self: center; display: inline-flex; flex-wrap: nowrap; max-width: 100%; width: 100%; justify-content: center;\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"style\",\"align-items: center; align-self: center; flex: 1 1 auto; margin: 0px 4px; max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"cvAvXt\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"size\",\"20\"],[11,\"class\",\"WzsNb\"],[9],[0,\"\\n                \"],[7,\"svg\"],[11,\"focusable\",\"false\"],[11,\"height\",\"20\"],[11,\"size\",\"20\"],[11,\"viewBox\",\"0 0 20 20\"],[11,\"width\",\"20\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"class\",\"spinner-stroke\"],[9],[0,\"\\n                    \"],[7,\"circle\"],[11,\"cx\",\"10\"],[11,\"cy\",\"10\"],[11,\"r\",\"9\"],[9],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "printpost/templates/components/loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});