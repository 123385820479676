define("printpost/controllers/operacao/profiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    groupsList: [],
    idGroupProcess: null,
    maquina: null,
    maquinaView: null,
    tpServico: null,
    reloadGroupProcess: function (id) {
      let url = this.baseapi.apiLink('ComputerProfiles/groups?id=' + id);
      let r = this.baseapi.getHttp(url);
      r.then(response => {
        let data = response.data;
        Ember.set(this, 'groupsList', data);
      });
    },
    reloadModel: function () {
      let url = this.baseapi.apiLink('ComputerProfiles/profiles');
      let r = this.baseapi.getHttp(url);
      r.then(response => {
        let objReturn = [];
        response.data.forEach(element => {
          let item = {
            "maquinaId": element.maquinaId,
            "maquina": element.maquina,
            "usuario": element.usuario,
            "active": element.active,
            "porta": element.porta,
            "letter": element.letter,
            "sms": element.sms,
            "email": element.email,
            "selectText": element.maquina,
            "selectId": element.maquinaId,
            "selectValue": element.usuario
          };
          objReturn.push(item);
        });
        Ember.set(this, 'model', objReturn);
      });
    },
    actions: {
      findGroupsProcess: function (id, name) {
        Ember.set(this, 'maquinaView', name);
        let url = this.baseapi.apiLink('ComputerProfiles/groups?id=' + id);
        let r = this.baseapi.getHttp(url);
        r.then(response => {
          let data = response.data;
          this.set('groupsList', data);
        });
        let countProfiles = this.baseapi.apiLink('ComputerProfiles/profiles');
        let r2 = this.baseapi.getHttp(countProfiles);
        r2.then(response => {
          let data = response.data;

          for (let j = 0; j < data.length; j++) {
            var element = data[j];
            element.selectText = element.maquina;
            element.selectId = element.maquinaId;
            element.selectValue = element.usuario;
          }

          this.set('model', data);
        });
      },
      setGroupProcess: function (item, nomeService) {
        Ember.set(this, 'idGroupProcess', item.id);
        Ember.set(this, 'maquina', item.maquina.usuario);
        Ember.set(this, 'tpServico', nomeService);
      },
      reprocessGroup: function () {
        let id = Ember.get(this, 'idGroupProcess');
        let mod = Ember.get(this, 'tpServico');
        let maq = this.baseapi.getFieldSelectValue("maquina_selection");
        let strUrl = 'GroupProcesses/reprocess?id=' + id + '&maquina=' + maq + '&servico=' + mod;
        let url = this.baseapi.apiLink(strUrl);
        let r = this.baseapi.patch(url);
        r.then(response => {
          let data = response.data;

          if (data.success) {
            this.toast.success('', 'Processamento alterado com sucesso.', {
              positionClass: 'toast-bottom-right'
            });
            this.reloadGroupProcess(maq);
            this.reloadModel();
          } else {
            this.toast.error('', data.message, {
              positionClass: 'toast-bottom-right'
            });
          }
        });
      },
      stopGroup: function () {
        let id = Ember.get(this, 'idGroupProcess');
        let maq = Ember.get(this, 'maquina');
        let mod = Ember.get(this, 'tpServico');
        let url = this.baseapi.apiLink('GroupProcesses/reprocess?id=' + id + '&maquina=' + maq + '&status=1' + '&servico=' + mod);
        let r = this.baseapi.patch(url);
        r.then(response => {
          let data = response.data;

          if (data.success) {
            this.toast.success('', 'Processamento alterado com sucesso.', {
              positionClass: 'toast-bottom-right'
            });
            this.reloadGroupProcess(maq);
            this.reloadModel();
          } else {
            this.toast.error('', data.message, {
              positionClass: 'toast-bottom-right'
            });
          }
        });
      },
      activeMaq: function (maq) {
        let id = "maq_id_" + maq.maquinaId;
        let check = document.getElementById(id).checked;
        let url = this.baseapi.apiLink('ComputerProfiles/active-computer?id=' + maq.usuario + '&active=' + maq);

        if (check) {
          console.log('active maq %s', maq.usuario);
          url = this.baseapi.apiLink('ComputerProfiles/active-computer?id=' + maq.usuario + '&active=1');
        } else {
          console.log('deactive maq %s', maq.usuario);
          url = this.baseapi.apiLink('ComputerProfiles/active-computer?id=' + maq.usuario + '&active=0');
        }

        let r = this.baseapi.getHttp(url);
        r.then(response => {
          let data = response.data;

          if (data.success) {
            if (check) {
              this.toast.success('', 'Computador ativado com sucesso.', {
                positionClass: 'toast-bottom-right'
              });
            } else {
              this.toast.success('', 'Computador desativado com sucesso.', {
                positionClass: 'toast-bottom-right'
              });
            }
          } else {
            this.toast.error('', data.message, {
              positionClass: 'toast-bottom-right'
            });
          }
        });
      },
      teste: function () {//console.log(get(this, 'groupsList'));
      }
    }
  });

  _exports.default = _default;
});