define("printpost/routes/requests", ["exports", "ember-concurrency", "lodash", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _lodash, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    requestService: Ember.inject.service(),
    model: async function () {
      // var params = queryParams;
      // const dataDashboard = (await this.dashboard.dashboardQuantity())[0];
      var objDate = new Date();
      const dateStart = `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T00:00:00-03:00`;
      const strDateBegin = `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T00:00:00-03:00`;
      const strDateEnd = `${objDate.getFullYear()}-${this.baseapi.padStr(objDate.getMonth() + 1)}-${this.baseapi.padStr(objDate.getDate())}T23:59:59-03:00`;
      let query = {
        filter: {
          where: {
            status: {
              nin: ['Pendente', 'Em Pendente']
            },
            createdAt: {
              gte: dateStart
            }
          },
          order: 'id DESC',
          limit: 20,
          include: ['wallet', 'account', 'files']
        }
      };
      let query_quantity = {
        filter: {
          where: {
            status: {
              nin: ['Pendente', 'Em Pendente']
            },
            createdAt: {
              gte: dateStart
            }
          },
          include: ['wallet']
        }
      };
      const indicators = await this.requestService.quantityData(query_quantity);
      const requestList = await this.requestService.list(query);
      const listStatus = [{
        description: 'Análise',
        value: 'Análise'
      }, {
        description: 'Execução',
        value: 'Execução'
      }, {
        description: 'Finalizado',
        value: 'Finalizado'
      }, {
        description: 'Cancelado',
        value: 'Cancelado'
      }];
      const listTipoService = [{
        field: 'letterService',
        description: 'Carta',
        value: true
      }, {
        field: 'emailService',
        description: 'E-mail',
        value: true
      }, {
        field: 'smsService',
        description: 'SMS',
        value: true
      }, {
        field: 'whatsappService',
        description: 'Whatsapp',
        value: true
      }, {
        field: 'registryOfficeService',
        description: 'Reg. em cartório',
        value: true
      }];
      const chart = {
        "datasets": [{
          label: ["Não entregues"],
          data: [parseFloat(indicators.chart.naoEntregue)],
          stack: "Não entregues",
          backgroundColor: ['#d80000']
        }, {
          label: ["Entregues"],
          data: [parseFloat(indicators.chart.entregue)],
          stack: "Entregues",
          backgroundColor: ['#40bbaf']
        }],
        "labels": ["Entregues"]
      };
      return Ember.RSVP.hash({
        'request': requestList.data,
        'pages': requestList.totalPages,
        // 'totals': dataDashboard,
        // qtdAndamento: dataDashboard.unfinished,
        // qtdFinalizado: dataDashboard.finalizadoRequests,
        listStatus: listStatus,
        listTipoService: listTipoService,
        dataRequestFiles: [],
        chart: chart,
        indicators: indicators,
        strDateBegin: strDateBegin,
        strDateEnd: strDateEnd
      });
    }
  });

  _exports.default = _default;
});