define("printpost/services/account-subaccount", ["exports", "printpost/utils/globalFunctions"], function (_exports, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    baseapi: Ember.inject.service(),
    listAccountSubaccount: async function () {
      var account = this.baseapi.getCurrentUser();

      if (account.type == this.baseapi.getAdmValue()) {
        return [];
      }

      var id = account.id;
      let query = {
        filter: {
          where: {
            principalAccountId: id
          },
          include: ['subAccount']
        }
      };
      let strquery = (0, _globalFunctions.apiLinkQuery)(query);
      let url = this.baseapi.apiLink('AccountSubaccounts/list');
      const data = await this.baseapi.getHttp(url + '?' + strquery).then(resp => resp.data);
      return data;
    }
  });

  _exports.default = _default;
});