define("printpost/controllers/reqdetail", ["exports", "ember-concurrency", "printpost/config/environment", "printpost/utils/globalFunctions"], function (_exports, _emberConcurrency, _environment, _globalFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const variables = Ember.Object.extend({
    printingOptions: null,
    emailContact: null,
    emailSubject: null,
    walletId: null,
    empty_resp: null,
    isLoadingResume: false
  });

  var _default = Ember.Controller.extend({
    baseapi: Ember.inject.service(),
    dashboard: Ember.inject.service(),
    session: Ember.inject.service('session'),
    variables: variables.create({
      empty_resp: 1
    }),

    init() {
      this._super(...arguments); //SEARCH


      this.filterTableComplement = this.filterTableComplement || ['attributes.nome', 'attributes.bairro', 'attributes.cpf', 'attributes.cidade', 'attributes.cep', 'attributes.estado'];
    },

    preloader: Ember.computed('preloader', function () {
      return Ember.$('#subpreloader');
    }).property().volatile(),
    queryParams: {
      id: null
    },
    paperOption: Ember.computed('paperOption', function () {
      return '';
    }),
    filesReturn: Ember.computed('filesReturn', function () {
      return null;
    }),
    isUploadReturn: Ember.computed('isUploadReturn', function () {
      return false;
    }),
    checklistChecked: Ember.computed('checklstchecked', function () {
      return false;
    }),
    randomScalingFactor: function (value = 100) {
      return Math.round(Math.random() * value);
    },
    dataGrafPieReturn: Ember.computed('dataGrafPieReturn', function () {
      let data = Ember.get(this, 'model.request');
      var dataTotalAddress = data.quantity.carta;
      var dataTotalDig = data.quantity.email + data.quantity.sms;
      var dataNotRet = Ember.get(this, 'model.requestReturn.1.emailNotReceive') + Ember.get(this, 'model.requestReturn.2.smsNotReceive') + Ember.get(this, 'model.requestReturn.0.cartaNotReceive');
      let d = {
        "datasets": [{
          "label": "Desempenho",
          "fill": false,
          "data": [dataTotalDig, dataTotalAddress, dataNotRet],
          "backgroundColor": ["#50e2d4", "#b9e2f1", "#ff8da0"],
          "borderColor": ["#50e2d4", "#b9e2f1", "#ff8da0"],
          "borderWidth": 0
        }],
        "color": ["#50e2d4", "#b9e2f1", "#ff8da0"],
        "labels": ["Digital", "Cartas", "Não Entregues"]
      };
      return d;
    }).property().volatile(),
    dataGrafPie1: Ember.computed('dataGrafPie1', function () {
      let model = Ember.get(this, 'dataGrafPie');
      return model;
    }).property().volatile(),
    dataGrafPie2: Ember.computed('dataGrafPie2', function () {
      let model = Ember.get(this, 'dataGrafPie');
      return model;
    }).property().volatile(),
    dataGrafPie3: Ember.computed('dataGrafPie3', function () {
      let model = Ember.get(this, 'dataGrafPie');
      return model;
    }).property().volatile(),
    dataGrafNotReturn: Ember.computed('dataGrafNotReturn', function () {
      return this.randomScalingFactor(200);
    }),
    dataGrafDigital: Ember.computed('dataGrafDigital', function () {
      var dataTotalDig = this.get('model.totalDigital');
      return dataTotalDig;
    }).property().volatile(),
    dataOptionsRandom: Ember.computed('dataOptionsRandom', function () {
      let m = {
        sms: this.randomScalingFactor(10000),
        carta: this.randomScalingFactor(10000),
        email: this.randomScalingFactor(10000),
        cartaRet: this.randomScalingFactor(100),
        emailRet: this.randomScalingFactor(100),
        smsRet: this.randomScalingFactor(100),
        cartaNot: 0,
        emailNot: 0,
        smsNot: 0,
        notAll: 0,
        correiosAll: 0
      };
      m.cartaNot = m.carta - m.cartaRet;
      m.emailNot = m.email - m.emailRet;
      m.smsNot = m.sms - m.smsRet;
      m.notAll = m.smsRet + m.cartaRet + m.emailRet;
      m.correiosAll = m.cartaNot;
      return m;
    }).property().volatile(),
    dataGrafPie: Ember.computed('model.analises.p1', function () {
      var data = this.get('model.request');
      var dataTotalAddress = data.wallet ? data.wallet.addressCount : this.randomScalingFactor(200);
      var dataTotalDig = Ember.get(this, 'dataGrafDigital');
      var dataNotRet = Ember.get(this, 'dataGrafNotReturn');
      let d = {
        "datasets": [{
          "label": "Desempenho",
          "fill": false,
          "data": [dataTotalDig, dataTotalAddress, dataNotRet],
          "backgroundColor": ["#50e2d4", "#b9e2f1", "#ff8da0"],
          "borderColor": ["#50e2d4", "#b9e2f1", "#ff8da0"],
          "borderWidth": 0
        }],
        "color": ["#50e2d4", "#b9e2f1", "#ff8da0"],
        "labels": ["Digital", "Cartas", "Não Entregues"]
      };
      return d;
    }),
    options: Ember.computed('model.analises.options', function () {
      return {
        "responsive": true,
        "legend": {
          "display": false,
          "labels": {
            "fontColor": "#000"
          }
        },
        "scales": {
          "yAxes": [{
            "display": false,
            "ticks": {
              "beginAtZero": false
            },
            "gridLines": {
              "display": false
            }
          }],
          "xAxes": [{
            "display": false,
            "gridLines": {
              "display": false
            }
          }]
        }
      };
    }),
    mesAtual: Ember.computed('mesAtual', function () {
      return ['Janeiro', 'Fevereiro'];
    }),
    pedidoAtual: Ember.computed('pedidoAtual', function () {
      return ['Campanha Carnaval #1001', 'Quarta de Cinzas #1002', 'Campanha Ano Novo #999', 'Campanha Natal #1000', 'Cobrança Mensalidade #1003'];
    }),
    request: Ember.computed('model.request', function () {
      // var data = this.get('model.request.firstObject');
      var data = this.get('model.request');

      if (data.letterService && data.printingOptions.templateLetter) {
        let papel = "";
        let cor = "";

        if (data.printingOptions.templateLetter.definition.papper == "couche_fosco") {
          papel = "Couché fosco";
        } else if (data.printingOptions.templateLetter.definition.papper == "couche_brilhoso") {
          papel = "Couché brilhoso";
        } else if (data.printingOptions.templateLetter.definition.papper == "apergaminhado") {
          papel = "Apergam.";
        }

        if (data.printingOptions.templateLetter.definition.colored) {
          cor = "Colorido";
        } else {
          cor = "Preto e branco";
        }

        let _p = data.printingOptions.templateLetter.definition.format + " - " + data.printingOptions.templateLetter.definition.grammage + " /" + papel + " /" + cor;

        Ember.set(this, 'paperOption', _p);
      }

      return data; //return this.get('model.request.firstObject');
    }),
    statusRequest: Ember.computed('statusRequest', function () {
      var req = this.get('model.request');
      var status = req.progress;
      var objReturn = [];
      status.forEach(st => {
        var data = new Date(st.createdAt);
        let strData = this.baseapi.getMonthStr(data.getMonth()).toUpperCase() + ' ' + this.baseapi.padStr(data.getDate()) + ', ' + data.getFullYear();
        let strHour = `${this.baseapi.padStr(data.getHours())}:${this.baseapi.padStr(data.getMinutes())}`;
        let marker = {
          class: 'timeline-vertical-marker-blue',
          info: strData,
          hour: strHour,
          content: st.descricao
        };
        objReturn.push(marker);
      });
      return objReturn;
    }).property().volatile(),
    totalCostRequest: Ember.computed('model.totalCost', function () {
      var valor = this.get('model.totalCost');
      return valor;
    }),
    accountUser: Ember.computed('accountUser', function () {
      let s = this.session.data.authenticated.account;
      return s;
    }),
    adminValue: Ember.computed('adminValue', function () {
      let s = this.baseapi.getAdmValue();
      return s;
    }),
    smsTest: Ember.computed('smsTest', function () {
      return {
        solicitarTestes: 1,
        smsContact: '',
        smsReturn: null
      };
    }),
    whatsappTest: Ember.computed('whatsappTest', function () {
      return {
        solicitarTestes: 1,
        phoneContact: '',
        smsReturn: null
      };
    }),
    emailTest: Ember.computed('emailTest', function () {
      return {
        solicitarTestes: 1,
        emailContact: '',
        emailReturn: null
      };
    }),
    totalItems: Ember.computed('totalItems', function () {
      return {
        execucao: 0,
        finalizados: 0,
        retornos: 0
      };
    }),
    downloadMakeTemplate: (0, _emberConcurrency.task)(function () {
      try {
        var data = this.get('model.request');
        let printOptions = data.printingOptions;
        let walletId = data.walletId;
        let form = new FormData();

        let _html = printOptions.templateLetter.frontHTML + '<footer></footer>' + printOptions.templateLetter.backHTML;

        if (printOptions.templateLetter.editorMode == "carta_pre_impresso") {
          _html = printOptions.templateLetter.frontHTML;
        }

        form.set("html", _html);
        form.set("walletId", walletId);
        let host = _environment.default.APP.API.host_py;
        let token = this.get('session.data.authenticated.id');
        let url = host + '/api/generatep/';
        let r = this.baseapi.postFormData(url + '?access_token=' + token, form, false);
        this.preloader.toggleClass('d-none');
        r.then(response => {
          this.preloader.toggleClass('d-none');
          let data = response.data;
          this.baseapi.getDownload(url + 'view?file=' + data, 'kkkk.pdf', false);
        }).catch(function () {
          this.preloader.toggleClass('d-none');
          this.toast.error('Erro!', 'Problemas ao tratar o template.', {
            positionClass: 'toast-bottom-right'
          });
        });
      } catch (e) {
        this.variables.set('empty_resp', e);
        this.toast.error('Erro!', 'Ocorreu algum problema ao baixar seu arquivo.', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    checkAprovacao: (0, _emberConcurrency.task)(function* () {
      let isChecked = false;
      let checkLetter = true;
      let checkSMS = true;
      let checkEmail = true;
      let checkRegistryOffice = true;
      let request = Ember.get(this, 'request');
      let checklist = this.get('model.checklistAprovacao');

      if (request.letterService && (!request.multiChannel || request.multiChannel && request.orderSend.first == "carta")) {
        let keys = Object.keys(checklist.letter);
        keys.forEach(function (item) {
          if (!checklist.letter[item]) {
            checkLetter = false;
          }
        });
      }

      if (request.smsService && (!request.multiChannel || request.multiChannel && request.orderSend.first == "sms")) {
        let keys = Object.keys(checklist.sms);
        keys.forEach(function (item) {
          if (!checklist.sms[item]) {
            checkSMS = false;
          }
        });
      }

      if (request.emailService && (!request.multiChannel || request.multiChannel && request.orderSend.first == "email")) {
        let keys = Object.keys(checklist.email);
        keys.forEach(function (item) {
          if (!checklist.email[item]) {
            checkEmail = false;
          }
        });
      }

      if (request.registryOfficeService && !request.multiChannel) {
        let keys = Object.keys(checklist.registry_office);
        keys.forEach(function (item) {
          if (!checklist.registry_office[item]) {
            checkRegistryOffice = false;
          }
        });
      }

      isChecked = checkLetter * checkSMS * checkEmail * checkRegistryOffice ? true : false;
      Ember.set(this, 'checklistChecked', isChecked);
    }).keepLatest(),
    setFileReturn: function (data) {
      //ler https://www.html5rocks.com/pt/tutorials/file/dndfiles/
      Ember.set(this, 'filesReturn', data.target.files);
      Ember.set(this, 'filesReturnLenght', data.target.files.length);
    },
    requestAttachs: Ember.computed('model.rawFiles', function () {
      let r = Ember.get(this, 'model.rawFiles');
      r = r == null ? [] : r;
      return r;
    }),
    downloadFileTask: (0, _emberConcurrency.task)(function (file) {
      let url = this.baseapi.apiLink('rawFiles');
      let token = this.get('session.data.authenticated.id');
      url += '/download-id/' + file.id + '/1?access_token=' + token;
      this.baseapi.getDownload(url, file.name, false);
    }).keepLatest(),
    dataUpdateQuantity: Ember.computed('dataUpdateQuantity', function () {
      let model = {
        carta: 1000,
        sms: 0,
        email: 0,
        whatsapp: 0,
        registry_office: 100
      };
      return model;
    }),
    dataObjPrices: Ember.computed('dataObjPrices', function () {
      let model = [];
      return model;
    }).property().volatile(),
    dataTotalValue: Ember.computed('dataTotalValue', function () {
      let m = 0;
      return m;
    }).property().volatile(),
    dataChangeSend: Ember.computed('dataChangeSend', function () {
      let m = {
        carta: '',
        email: '',
        sms: ''
      };
      return m;
    }),
    dataChangeSendValue: Ember.computed('dataChangeSendValue', function () {
      let m = '';
      return m;
    }),
    findPricesTask: (0, _emberConcurrency.task)(function* () {
      let requestData = this.get('model.request');
      let userId = requestData.userId;
      var obj_prices = [];

      if (userId) {
        var query = {
          filter: {
            where: {
              userId: userId,
              active: true
            }
          }
        };
        let url_prices = this.baseapi.apiLink('LinkCustomers/find');
        let strfilter = (0, _globalFunctions.apiLinkQuery)(query);
        const obj_prices_customer = yield this.baseapi.getHttp(`${url_prices}?${strfilter}`).then(resp => resp.data); // general

        let url_price_table = this.baseapi.apiLink('PricingTables/find');
        const obj_prices_general = yield this.baseapi.getHttp(url_price_table).then(resp => resp.data);

        if (obj_prices_customer.length > 0) {
          obj_prices = obj_prices_customer;
        } else {
          obj_prices = obj_prices_general;
        }
      }

      return obj_prices;
    }),
    isChangeRawFiles: Ember.computed(function () {
      return false;
    }),
    fileTrackingReturn: Ember.computed('fileTrackingReturn', function () {
      return '';
    }).property().volatile(),
    fileAttach: Ember.computed('fileAttach', function () {
      return '';
    }).property().volatile(),
    fileHomolog: Ember.computed('fileHomolog', function () {
      return '';
    }).property().volatile(),
    fileComplementary: Ember.computed('fileComplementary', function () {
      return '';
    }).property().volatile(),
    tableColumns: Ember.computed('tableColumns', function () {
      return '';
    }).property().volatile(),
    dataAproveComplement: Ember.computed('dataAproveComplement', function () {
      return '';
    }).property().volatile(),
    dataTypeReturn: Ember.computed('dataTypeReturn', function () {
      let m = [];
      return m;
    }),
    selectedReturn: Ember.computed('selectedReturn', function () {
      return null;
    }),
    filesReturnLenght: Ember.computed('filesReturnLenght', function () {
      return 0;
    }),
    sendRawFile: function (isFileHomolog = false) {
      let form = new FormData(); // let reqId = localStorage.getItem('newServiceRequestId');

      let isRegistryOffice = this.get('model.request').registryOfficeService;
      let hasRegistryOfficeFile = this.get('model.arquivoRegistro');
      let requestId = this.get('model.request').id;
      let userId = this.get('model.request').userId;
      form.set("requestId", requestId);
      form.set("userId", userId);
      var file = null;

      if (!isFileHomolog) {
        form.set("type", "receipt_attach");
        file = Ember.get(this, 'fileAttach');
      } else {
        form.set("type", "file_homolog");
        file = Ember.get(this, 'fileHomolog');
        let audit = Ember.get(this, 'model.listHomologation');

        if (audit.length > 0) {
          audit = audit[0];
          form.set("requestAuditId", audit.iid);
        }
      }

      if (!isFileHomolog && isRegistryOffice && !hasRegistryOfficeFile) {
        form.set("type", "registry_office_file");
      }

      if (!file) {
        this.toast.error('', 'Erros ao enviar o arquivo, tente novamente!!', {
          positionClass: 'toast-bottom-right'
        });
        return;
      }

      form.append("file", file); // let ext = this.baseapi.getFileExtension(file.name);

      let url = this.baseapi.apiLink("rawFiles");
      url += "/upload-file";
      this.preloader.toggleClass('d-none');
      Ember.set(this, 'isChangeRawFile', true);
      this.baseapi.postFormData(url, form).then(rawObj => {
        this.variables.set('empty_resp', rawObj);
        this.preloader.toggleClass('d-none');
        Ember.set(this, 'isChangeRawFiles', false);

        if (!isFileHomolog) {
          Ember.set(this, 'fileAttach', '');
          this.toast.success('', 'Arquivo enviado com sucesso.', {
            positionClass: 'toast-bottom-right'
          });
          this.send('finalizarPedido', requestId);
        } else {
          Ember.set(this, 'fileHomolog', '');
          this.toast.success('', 'Arquivo enviado com sucesso.', {
            positionClass: 'toast-bottom-right'
          });
          this.send('sessionChanged');
        }
      }, error => {
        this.variables.set('empty_resp', error);
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erros ao enviar o arquivo, tente novamente.', {
          positionClass: 'toast-bottom-right'
        });
      });
    },
    sendVinculateAr: function (requestId, organizationId, userId) {
      if (requestId && (organizationId || userId)) {
        const url = 'RequestReturns/return/vincule-ar';
        const form = new FormData();
        form.set("requestId", requestId);
        form.set("organizationId", organizationId);
        form.set("userId", userId);
        form.set("channel", "carta");
        form.set("id", "null");
        const trackfile = Ember.get(this, 'fileTrackingReturn');
        let name = 'upload_day.xslx';

        if (trackfile) {
          const file = trackfile;
          name = file.name;
          form.append("file", file);
        } else {
          this.toast.error('', 'Erro não foi possível encontrar o arquivo no pedido', {
            positionClass: 'toast-bottom-right'
          });
          return;
        }

        form.set("name", name);
        let urlP = this.baseapi.apiLink(url);
        let token = this.baseapi.getToken();
        this.preloader.toggleClass('d-none');
        this.baseapi.postFormData(`${urlP}?access_token=${token}`, form).then(resp => {
          this.preloader.toggleClass('d-none');
          Ember.set(this, 'fileTrackingReturn', '');
          Ember.$("#upload-track-return").val('');

          if (resp.data.success) {
            var msg = '';
            var ok = 'Arquivo vinculado com sucesso.';

            if (resp.data.success[0].source && resp.data.success[0].source.length > 0) {
              msg = `Sucesso:. ${resp.data.success[0].detail} (${resp.data.success[0].source.join(', ')})`;
            }

            this.toast.success(msg, ok, {
              positionClass: 'toast-bottom-right'
            });
          } else {
            this.toast.error('', resp.data.errors[0].detail, {
              positionClass: 'toast-bottom-right'
            });
          }
        }, error => {
          Ember.set(this, 'fileTrackingReturn', '');
          Ember.$("#upload-track-return").val('');
          this.preloader.toggleClass('d-none');
          this.toast.error('', `Erro:. ${error.response.data.errors[0].detail}`, {
            positionClass: 'toast-bottom-right'
          });
        });
      } else {
        // error
        this.toast.error('', 'Erro não foi possível associar o pedido', {
          positionClass: 'toast-bottom-right'
        });
        return;
      }
    },
    aproveDataComplement: function (isValidated = false) {
      //id_wallet = '5d9b8c14a4a29d29f0ddf538';
      let n = Ember.get(this, 'fileComplementary');
      let saida = false;
      Ember.set(this, 'isUploadFileComplement', true);

      try {
        let form = new FormData();
        form.set("name", n.name);
        form.set("requestId", this.get('request.id'));
        form.set("walletId", this.get('request.walletId'));
        form.set("userId", this.get('request.userId'));
        form.set("approve", 0);
        form.append("file", n);
        let token = this.get('session.data.authenticated.id');
        let url = this.baseapi.apiLink('Wallets/file-complementary' + '?access_token=' + token);

        if (isValidated) {
          form.set("approve", 1);
        }

        var call = this.baseapi.postFormData(url, form, false);
        call.then(resp => {
          if (isValidated) {
            Ember.$("#closeFileComplement").click();
            Ember.set(this, 'fileComplementary', null);
            this.toast.success('', 'Arquivo complementar, vinculado com sucesso', {
              positionClass: 'toast-bottom-right'
            });
          } else {
            Ember.set(this, 'isUploadFileComplement', false);
            saida = true;
            Ember.set(this, 'dataAproveComplement', resp.data.parser);
            let keys = Object.keys(resp.data.maxlength);

            if (keys.length > 1) {
              let array_keys = [];
              let array_filter = [];
              keys.forEach(k => {
                let obj_key = {
                  name: k,
                  valuePath: k
                };
                array_keys.push(obj_key);
                let obj_filter = 'attributes.' + k;
                array_filter.push(obj_filter);
              });
              Ember.set(this, 'tableColumns', array_keys);
            } else {
              Ember.set(this, 'fileComplementary', null);
              Ember.set(this, 'isUploadFileComplement', false);
              Ember.set(this, 'tableColumns', null);
              this.toast.error('', 'Erro ao tratar o arquivo complementar.', {
                positionClass: 'toast-bottom-right'
              });
            }
          }
        }, error => {
          console.log(error);
          Ember.set(this, 'fileComplementary', null);
          Ember.$("#closeFileComplement").click();
          Ember.set(this, 'isUploadFileComplement', false);
          Ember.set(this, 'fileComplementary', null);
          this.toast.error('', `Erro ao tratar o arquivo complementar!!`, {
            positionClass: 'toast-bottom-right'
          });
        });
      } catch (e) {
        Ember.set(this, 'fileComplementary', null);
        Ember.set(this, 'isUploadFileComplement', false);

        for (let err in e.body.errors) {
          this.toast.error('', 'Erro ao tratar o arquivo complementar!!!.', {
            positionClass: 'toast-bottom-right'
          });
        }
      }

      return saida;
    },
    taskFilterReturn: (0, _emberConcurrency.task)(function* (onlyItens = false) {
      let key = Ember.get(this, 'model.key');
      key = key ? key : '*';
      const request = Ember.get(this, 'model.request');
      const id = request.id;
      var query = {
        filter: {
          where: {
            id: id
          }
        }
      };
      const typePanel = this.get('model.typePanel');
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');
      if (!onlyItens) this.set('model.isLoadingResume', true);

      try {
        let url = this.baseapi.apiLink('RequestDatasets/panel-mail');
        let loadContactResponse = false;

        if (typePanel == 'sms') {
          loadContactResponse = true;
          url = this.baseapi.apiLink('RequestDatasets/panel-sms');
        } else if (typePanel == 'whatsapp') {
          loadContactResponse = true;
          key = key == '*' ? 's1' : key;
          url = this.baseapi.apiLink('RequestDatasets/panel-whatsapp');
        }

        let urlItens = this.baseapi.apiLink('Reports/panel-itens');
        if (key) strquery += `&key=${key}`;
        let filter = Ember.get(this, 'model.filter');
        if (filter.skip > 0) strquery += `&filter[skip]=${filter.skip}`;
        const strqueryitens = `${strquery}&type=${typePanel}`;
        var data = [];
        if (!onlyItens) data = yield this.baseapi.getHttp(`${url}?${strquery}`).then(x => x.data);
        const dataItens = yield this.baseapi.getHttp(`${urlItens}?${strqueryitens}`).then(x => x.data);

        if (loadContactResponse) {
          this.get('taskFilterContactResponse').perform(true);
        }

        this.preloader.toggleClass('d-none'); // construct pie graph

        var colors = ["#31B404", "#FE9A2E", "#FFBF00", "#D8CEF6", "#FE2EC8", "#8A084B", "#9AFE2E", "#61380B", "#0B615E", "#380B61", "#5FB404"];
        var objPieGraph = {
          datasets: [{
            label: "Status de sms",
            fill: false,
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 0
          }],
          color: [],
          labels: []
        };
        this.set('model.panelDataItens', dataItens);
        if (!onlyItens) this.set('model.isLoadingResume', false);
        console.log('enter her');
        if (onlyItens) return;
        data.qtdStatus.forEach((itemData, idx) => {
          objPieGraph.datasets[0].backgroundColor.push(colors[idx]);
          objPieGraph.datasets[0].borderColor.push(colors[idx]);
          objPieGraph.color.push(colors[idx]);
          objPieGraph.labels.push(itemData.message);
          objPieGraph.datasets[0].data.push(itemData.qtd);
        });
        this.set('model.panelData', data);
        this.set('model.objPieGraphPanelData', objPieGraph);
      } catch (error) {
        console.log(error);
        this.preloader.toggleClass('d-none');
        this.set('model.isLoadingResume', false);
        this.toast.error('', 'Erro ao consultar dados', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    taskFilterContactResponse: (0, _emberConcurrency.task)(function* () {
      let key = Ember.get(this, 'model.key');
      key = key ? key : '*';
      const request = Ember.get(this, 'model.request');
      const typePanel = Ember.get(this, 'model.typePanel');
      const id = request.id;
      var query = {
        filter: {
          where: {
            id: id
          }
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      this.preloader.toggleClass('d-none');

      try {
        let urlItens = this.baseapi.apiLink('Reports/contact-response');
        if (key) strquery += `&key=${key}`;
        let filter = Ember.get(this, 'model.filter');
        if (filter.skip > 0) strquery += `&filter[skip]=${filter.skip}`;
        const dataItens = yield this.baseapi.getHttp(`${urlItens}?${strquery}&type=${typePanel}`).then(x => x.data);
        this.preloader.toggleClass('d-none');
        this.set('model.panelDataItens', dataItens);
        this.set('model.qtdContactResponse', dataItens.total);
      } catch (error) {
        this.preloader.toggleClass('d-none');
        this.toast.error('', 'Erro ao consultar dados', {
          positionClass: 'toast-bottom-right'
        });
      }
    }).keepLatest(),
    taskListRequestReturnFiles: (0, _emberConcurrency.task)(function* () {
      var urlRawFiles = this.baseapi.apiLink('rawFiles/list');
      var query = {
        filter: {
          where: {
            type: 'request_return_report',
            name: {
              nin: ['', 'null']
            }
          },
          order: 'id DESC'
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      urlRawFiles += "?" + strquery;
      this.preloader.toggleClass('d-none');
      const dataRawFiles = yield this.baseapi.getHttp(urlRawFiles).then(rf => rf.data);
      this.preloader.toggleClass('d-none');
      this.set('model.filesRequestReturnReport', dataRawFiles);
    }).keepLatest(),
    taskBlacklistSave: (0, _emberConcurrency.task)(function* () {
      var urlQuery = this.baseapi.apiLink('Blacklists/save');
      let request = this.get('model.request');
      let contatos = this.get('model.blacklistData.cadastrar');
      let type = this.get('model.type');
      var query = {
        filter: {
          where: {
            userId: request.userId,
            organizationId: request.organizationId
          }
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      urlQuery += "?" + strquery;

      if (!contatos || contatos.length < 3) {
        this.toast.error('', 'É necessário informar os contatos.', {
          positionClass: 'toast-bottom-right'
        });
        return;
      }

      this.preloader.toggleClass('d-none');

      try {
        const dataResp = yield this.baseapi.post(urlQuery, {
          type: type,
          address: contatos
        }).then(rf => rf.data);
        this.toast.success('', 'Backlist cadastrado com sucesso.', {
          positionClass: 'toast-bottom-right'
        });
      } catch (error) {
        this.toast.error('', 'Backlist erro ao cadastradar dados.', {
          positionClass: 'toast-bottom-right'
        });
      }

      this.preloader.toggleClass('d-none');
    }).keepLatest(),
    taskBlacklistDelete: (0, _emberConcurrency.task)(function* () {
      var urlQuery = this.baseapi.apiLink('Blacklists/delete');
      let request = this.get('model.request');
      let contatos = this.get('model.blacklistData.deletar');
      let type = this.get('model.type');
      var query = {
        filter: {
          where: {
            userId: request.userId,
            organizationId: request.organizationId
          }
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      urlQuery += "?" + strquery;

      if (!contatos || contatos.length < 3) {
        this.toast.error('', 'É necessário informar os contatos.', {
          positionClass: 'toast-bottom-right'
        });
        return;
      }

      this.preloader.toggleClass('d-none');

      try {
        const dataResp = yield this.baseapi.post(urlQuery, {
          type: type,
          address: contatos
        }).then(rf => rf.data);
        this.toast.success('', 'Casos removidos do blacklist com sucesso.', {
          positionClass: 'toast-bottom-right'
        });
      } catch (error) {
        this.toast.error('', 'Erro para remover casos do blacklist.', {
          positionClass: 'toast-bottom-right'
        });
      }

      this.preloader.toggleClass('d-none');
    }).keepLatest(),
    taskBlacklistFind: (0, _emberConcurrency.task)(function* () {
      var urlQuery = this.baseapi.apiLink('Blacklists/find');
      let request = this.get('model.request');
      let contatos = this.get('model.blacklistData.consultar');
      let type = this.get('model.type');
      var query = {
        filter: {
          where: {
            userId: request.userId,
            organizationId: request.organizationId
          }
        }
      };
      var strquery = (0, _globalFunctions.apiLinkQuery)(query);
      urlQuery += "?" + strquery;

      if (!contatos || contatos.length < 3) {
        this.toast.error('', 'É necessário informar os contatos.', {
          positionClass: 'toast-bottom-right'
        });
        return;
      }

      this.preloader.toggleClass('d-none');

      try {
        const dataResp = yield this.baseapi.post(urlQuery, {
          type: type,
          address: contatos
        }).then(rf => rf.data);
        this.set('model.blacklistData.findResult', dataResp);
      } catch (error) {
        this.toast.error('', 'Backlist erro ao cadastradar dados.', {
          positionClass: 'toast-bottom-right'
        });
      }

      this.preloader.toggleClass('d-none');
    }).keepLatest(),
    actions: {
      teste: function () {
        // let r = this.get('session.data.authenticated.id');
        this.send("sessionChanged");
      },
      liberarPedido: function () {
        let request = Ember.get(this, 'request');
        let requestId = Ember.$('#txt_requestId').val();
        let str_validate = this.get('model.checklistAprovacao'); // str_validate = JSON.stringify(str_validate);
        // let url = this.baseapi.apiLink('Requests/liberar-pedido/' + requestId) + '&checklist=' + str_validate;

        let url = this.baseapi.apiLink('Requests/liberar-pedido');
        let confirmRequest = request.multiChannel || request.notarysOffice || request.fileDb == "layout" ? '?confirm=true' : '';
        let data = {
          data: {
            checklist: str_validate,
            id: requestId
          }
        };
        this.preloader.toggleClass('d-none');
        this.baseapi.post(`${url}${confirmRequest}`, data).then(resp => {
          this.preloader.toggleClass('d-none');

          if (resp.data.success) {
            this.toast.success('', 'Pedido liberado com sucesso!', {
              positionClass: 'toast-bottom-right'
            });
            this.send("sessionChanged");
          } else {
            this.toast.error('', 'Erro ao liberar pedido', {
              positionClass: 'toast-bottom-right'
            });
            this.send("sessionChanged");
          }
        }, error => {
          this.variables.set('empty_resp', error);
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro ao liberar pedido', {
            positionClass: 'toast-bottom-right'
          });
          this.send("sessionChanged");
        });
      },
      toggleValueCheckList: function (keyValue) {
        let t = 'model.checklistAprovacao.' + keyValue;
        let checklist = this.get(t);
        let v = checklist;
        this.set(t, !v);
        Ember.get(this, 'checkAprovacao').perform();
      },
      clearCheclist: function () {
        let r = {
          verificouCarteira: true,
          letter: {
            dataEnvioAceitavel: false,
            opcaoPapelAnotada: false,
            validacaoImpressa: false,
            dobra: false,
            enderecamento: false,
            usoDosCorreios: false,
            serrilhas: false,
            datasInternas: false,
            ortografia: false,
            chancelas: false
          },
          email: {
            dataEnvioAceitavel: false,
            templateValidado: false,
            assuntoRespondeValidos: false
          },
          sms: {
            dataEnvioAceitavel: false,
            templateValidado: false
          },
          registry_office: {
            arquivoValidado: false
          }
        };
        this.set('model.checklistAprovacao', r);
        Ember.set(this, 'checklistChecked', false);
      },
      cancelarPedido: function () {
        let requestId = Ember.$('#cancel_requestId').val();
        let url = this.baseapi.apiLink('Requests/cancelar-pedido/' + requestId);
        let r = this.baseapi.getHttp(url);
        r.then(function () {
          //let data = response.data.data;
          window.location.reload(true);
        });
      },
      downloadGenerateTemplate: function () {
        Ember.get(this, 'downloadMakeTemplate').perform();
      },
      letterChangeView: function () {
        let v = Ember.$("#pos_view").val();
        Ember.$("#iframe-front").toggleClass('display-none');
        Ember.$("#iframe-back").toggleClass('display-none');

        if (v == 0) {
          Ember.$("#btn_letterChangeView").html("Visualizar Frente");
          Ember.$("#pos_view").val(1);
        } else {
          Ember.$("#btn_letterChangeView").html("Visualizar Verso");
          Ember.$("#pos_view").val(0);
        }
      },
      transitionToWallet: function (id) {
        this.transitionToRoute('carteiras.details', id).then(() => document.location.reload());
      },
      letterDetailsShow: function () {
        Ember.$('#letter-details').toggleClass('in');
      },
      smsDetailsShow: function () {
        Ember.$('#sms-details').toggleClass('in');
      },
      emailDetailsShow: function () {
        Ember.$('#email-details').toggleClass('in');
      },
      sendTestsWhatsapp: function () {
        try {
          Ember.set(this, 'whatsappTest.solicitarTestes', 2);
          var requestData = this.get('model.request');
          let printOptions = requestData.printingOptions;
          let form = new FormData();

          if (printOptions.templateWhatsapp != null && printOptions.templateWhatsapp != undefined) {
            let _sms = printOptions.templateWhatsapp.frontHTML;
            form.set("whatsapp_html", _sms);
            form.set("whatsapp_send", Ember.get(this, 'whatsappTest.phoneContact'));
          }

          form.set("walletId", requestData.walletId);
          form.set("requestId", requestData.id);
          let host = _environment.default.APP.API.host_py;
          let token = this.get('session.data.authenticated.id');
          let payload = {
            send: Ember.get(this, 'whatsappTest.phoneContact'),
            requestId: requestData.id,
            walletId: requestData.walletId
          };
          let url = host + '/v1/Whatsapp/test';
          let r = this.baseapi.post(url + '?access_token=' + token, payload, false);
          r.then(response => {
            Ember.set(this, 'whatsappTest.solicitarTestes', 1);
            let data = response.data;

            if (data.sms.status == 200) {
              this.toast.success('Enviado!', 'Teste enviado com sucesso!', {
                positionClass: 'toast-bottom-right'
              });
            } else {
              this.toast.error('Erro!', 'Tivemos um problema no envio do seu teste, verifique os dados informados e tente realizar um novo teste.', {
                positionClass: 'toast-bottom-right'
              });
            }

            Ember.set(this, 'whatsappTest.smsReturn', data.sms);
          }).catch(function () {
            Ember.set(this, 'whatsappTest.solicitarTestes', 1);
            this.toast.error('Erro!', 'Problemas ao enviar os testes.', {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (error) {
          this.variables.set('empty_resp', error);
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      sendTestsSMS: function () {
        try {
          Ember.set(this, 'smsTest.solicitarTestes', 2);
          var requestData = this.get('model.request');
          let printOptions = requestData.printingOptions;
          let form = new FormData();

          if (printOptions.templateSMS != null && printOptions.templateSMS != undefined) {
            let _sms = printOptions.templateSMS.frontHTML;
            form.set("sms_html", _sms);
            form.set("sms_send", Ember.get(this, 'smsTest.smsContact'));
          }

          form.set("walletId", requestData.walletId);
          form.set("requestId", requestData.id);
          let payload = {
            send: Ember.get(this, 'smsTest.smsContact'),
            requestId: requestData.id,
            walletId: requestData.walletId
          };
          let host = _environment.default.APP.API.host_py;
          let token = this.get('session.data.authenticated.id');
          let url = host + '/v1/Sms/test';
          let r = this.baseapi.post(url + '?access_token=' + token, payload, false);
          r.then(response => {
            Ember.set(this, 'smsTest.solicitarTestes', 1);
            let data = response.data;

            if (data.sms.status == 200) {
              this.toast.success('Enviado!', 'Teste enviado com sucesso!', {
                positionClass: 'toast-bottom-right'
              });
            } else {
              this.toast.error('Erro!', 'Tivemos um problema no envio do seu teste, verifique os dados informados e tente realizar um novo teste.', {
                positionClass: 'toast-bottom-right'
              });
            }

            Ember.set(this, 'smsTest.smsReturn', data.sms);
          }).catch(function () {
            Ember.set(this, 'smsTest.solicitarTestes', 1);
            this.toast.error('Erro!', 'Problemas ao enviar os testes.', {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (error) {
          this.variables.set('empty_resp', error);
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      sendTestsEmail: function () {
        try {
          Ember.set(this, 'emailTest.solicitarTestes', 2);
          var requestData = this.get('model.request');
          let printOptions = requestData.printingOptions;
          let walletId = requestData.walletId;
          let form = new FormData();

          if (printOptions.templateEmail != null && printOptions.templateEmail != undefined) {
            let _html = printOptions.templateEmail.frontHTML;
            let reply_to = this.request.emailContact;
            let subject = this.request.emailSubject;
            form.set("email_html", _html);
            form.set("email_replyto", reply_to);
            form.set("email_subject", subject);
            form.set("email_send", Ember.get(this, 'emailTest.emailContact'));
          }

          form.set("walletId", walletId);
          let payload = {
            send: Ember.get(this, 'emailTest.emailContact'),
            requestId: requestData.id,
            walletId: requestData.walletId
          };
          let host = _environment.default.APP.API.host_py;
          let token = this.get('session.data.authenticated.id');
          let url = host + '/v1/Emails/test';
          let r = this.baseapi.post(url + '?access_token=' + token, payload, false);
          r.then(response => {
            let data = response.data;

            if (data.email.status == 200) {
              this.toast.success('Enviado!', 'Teste enviado com sucesso!', {
                positionClass: 'toast-bottom-right'
              });
            } else {
              this.toast.error('Erro!', 'Tivemos um problema no envio do seu teste, verifique os dados informados e tente realizar um novo teste.', {
                positionClass: 'toast-bottom-right'
              });
            }

            Ember.set(this, 'emailTest.emailReturn', data.sms);
            Ember.set(this, 'emailTest.solicitarTestes', 1);
          }).catch(function () {
            this.toast.error('Erro!', 'Problemas ao enviar os testes.', {
              positionClass: 'toast-bottom-right'
            });
            Ember.set(this, 'emailTest.solicitarTestes', 1);
          });
        } catch (error) {
          this.variables.set('empty_resp', error);
          this.toast.error('Erro!', 'Ocorreu algum problema para realizar o envio do seu teste.', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      setFileReturn: function (file) {
        this.setFileReturn(file);
      },
      showButonMenu: function (id) {
        Ember.$("#" + id).toggleClass('button-submenu-show');
      },

      download(file) {
        this.preloader.toggleClass('d-none');
        let url = this.baseapi.apiLink('rawFiles');
        let token = this.baseapi.getToken();
        url += '/download-id/' + file.id + '/1?access_token=' + token;
        this.baseapi.getDownload(url, file.name, false);
        this.preloader.toggleClass('d-none');
      },

      downloadWallet(file) {
        this.preloader.toggleClass('d-none');
        let url = this.baseapi.apiLink('Wallets');
        let token = this.baseapi.getToken();
        url += '/download-file/' + file.id + '?access_token=' + token;
        this.baseapi.getDownload(url, file.name, false);
        this.preloader.toggleClass('d-none');
      },

      updateQuantity: function (key, value) {
        let dom = Ember.$(value.target);
        let valor = dom.val();
        let str = 'dataUpdateQuantity.' + key;
        Ember.set(this, str, parseInt(valor));
        this.send('findPrices', 0);
      },
      findPrices: async function (submitePrices = 0) {
        let prices = await Ember.get(this, 'findPricesTask').perform();
        let requestData = this.get('model.request');
        let quantidades = Ember.get(this, 'dataUpdateQuantity');
        let carta = requestData.letterService;
        let email = requestData.emailService;
        let sms = requestData.smsService;
        let whatsapp = requestData.whatsappService;
        let registry_office = requestData.registryOfficeService;
        let unit = {
          email: '0,00',
          carta: '0,00',
          sms: '0,00',
          whatsapp: '0,00',
          registry_office: '0,00'
        };
        let total = 0;
        let dpo = null;
        let f = '-';
        let g = '-';
        let p = '-';
        prices.forEach(item => {
          let q = 0;
          if (item.channel.value == "carta" && !carta) return;
          if (item.channel.value == "sms" && !sms) return;
          if (item.channel.value == "email" && !email) return;
          if (item.channel.value == "whatsapp" && !whatsapp) return;
          if (item.channel.value == "registry_office" && !registry_office) return;

          if (item.channel.value == "carta") {
            q = quantidades.carta;
            f = dpo && dpo.format || '-';
            g = dpo && dpo.grammage || '-';
            p = dpo && dpo.papper || '-';
            dpo = requestData.printingOptions.templateLetter.definition;
          } else if (item.channel.value == "sms") {
            q = quantidades.sms;
            dpo = requestData.printingOptions.templateSMS.definition;
          } else if (item.channel.value == "email") {
            q = quantidades.email;
            dpo = requestData.printingOptions.templateEmail.definition;
          } else if (item.channel.value == "whatsapp") {
            q = quantidades.whatsapp;
            dpo = requestData.printingOptions.templateWhatsapp.definition;
          } else if (item.channel.value == "registry_office") {
            q = quantidades.registry_office;
            dpo = requestData.printingOptions.templateRegistryOffice.definition;
          } // ja tenho o valor


          if (item.channelValue.toLowerCase() == "carta" && (!f || !g || !p || dpo.blackWhite == false && dpo.colored == false || !dpo.sendId)) {
            this.toast.error('', 'Problemas para identificar a combinação do produto e o envio.', {
              positionClass: 'toast-bottom-right'
            });
            return;
          }

          let prs = item.options[0];
          let discount_volume = "0,00";
          prs = item.options.find(x => x.id == dpo.sendId); //volume discount

          if (prs && prs.volumeItens && prs.volumeItens.length > 0) {
            let v = prs.volumeItens.find(x => x.min <= q && q <= x.max);

            if (v && v.percentage) {
              discount_volume = v.percentage; // set(dp, 'praticatePrices.priceVolumeGroup', v.percentage);
            } else {
              discount_volume = "0,00"; // set(dp, 'praticatePrices.priceVolumeGroup', dp.praticatePrices.priceVolumeGroup);
              // set(dp, 'praticatePrices.priceVolumeGroup', "0,00");
            }
          } else {
            discount_volume = "0,00";
          }

          let pt = this.baseapi.realStringConvertDecimal(prs.priceSale);
          discount_volume = this.baseapi.realStringConvertDecimal(discount_volume);
          let v = pt * q;

          if (discount_volume > 0) {
            v = v - v * (discount_volume / 100);
          }

          if (item.channel.value == "carta") {
            unit.carta = this.baseapi.numberToReal(v); // set(dp, 'praticatePrices.priceTotal', unit.carta);

            if (carta) {
              total += v;
            }
          } else if (item.channel.value == "sms") {
            unit.sms = this.baseapi.numberToReal(v); // set(dp, 'praticatePrices.priceTotal', unit.sms);

            if (sms) {
              total += v;
            }
          } else if (item.channel.value == "email") {
            unit.email = this.baseapi.numberToReal(v); // set(dp, 'praticatePrices.priceTotal', unit.email);

            if (email) {
              total += v;
            }
          } else if (item.channel.value == "whatsapp") {
            unit.whatsapp = this.baseapi.numberToReal(v); // set(dp, 'praticatePrices.priceTotal', unit.email);

            if (whatsapp) {
              total += v;
            }
          } else if (item.channel.value == "registry_office") {
            unit.registry_office = this.baseapi.numberToReal(v); // set(dp, 'praticatePrices.priceTotal', unit.email);

            if (registry_office) {
              total += v;
            }
          }
        });
        let totalstr = this.baseapi.numberToReal(total);
        Ember.set(this, 'dataObjPrices', prices);
        Ember.set(this, 'dataTotalValue', totalstr);

        if (submitePrices == 1) {
          this.send('informQuantities');
        }
      },
      informQuantities: function () {
        let requestData = this.get('model.request');
        let quantidades = Ember.get(this, 'dataUpdateQuantity');
        let prices = Ember.get(this, 'dataObjPrices');
        let total = Ember.get(this, 'dataTotalValue');
        let objData = {
          requestId: requestData.id,
          totalStr: total,
          quantidade: quantidades,
          prices: prices
        };
        let url = this.baseapi.apiLink('Requests/inform-quantity');
        this.preloader.toggleClass('d-none');
        this.baseapi.post(url, objData).then(resp => {
          this.preloader.toggleClass('d-none');

          if (resp.data.success) {
            this.toast.success('', 'Quantidades e faturamento processados com sucesso', {
              positionClass: 'toast-bottom-right'
            });
            this.send("sessionChanged");
          } else {
            this.toast.error('', 'Quantidades e faturamento não foram processados', {
              positionClass: 'toast-bottom-right'
            });
          }
        }, error => {
          this.variables.set('empty_resp', error);
          this.preloader.toggleClass('d-none');
          this.toast.error('', 'Erro no processamento!', {
            positionClass: 'toast-bottom-right'
          });
        });
      },
      setSendOption: function (format, select) {
        let id = this.baseapi.getFieldSelectValue(select);
        let channelSend = this.get('model.obj_prices_sends').find(x => x.id == id);
        let str = `dataChangeSend.${format}`;
        Ember.set(this, str, channelSend);
      },
      setProviderSelect: function (format, select) {
        let id = this.baseapi.getFieldSelectValue(select);

        if (id && id.length > 3) {
          let str = `model.listEnvios.${format}`;
          let strData = `model.dataEnvios.${format}`;
          let showItem = `model.showItem.${format}`;
          let listEnvios = this.get(strData).filter(x => x.providerId == id);
          listEnvios = this.baseapi.populeObjSelect(listEnvios);
          Ember.set(this, str, listEnvios);
          Ember.set(this, showItem, true);
        }
      },
      setdataChangeSendValue: function (value) {
        Ember.set(this, 'dataChangeSendValue', value);
      },
      changeEnvio: function () {
        let format = Ember.get(this, 'dataChangeSendValue');
        let str = `dataChangeSend.${format}`;
        let data = Ember.get(this, str);
        let request = this.get('model.request');
        var objPost = {
          value: data.value,
          id: data.id,
          channelId: data.channelId,
          requestId: request.id
        };
        let url = this.baseapi.apiLink('Requests/change-send');
        this.preloader.toggleClass('d-none');
        this.baseapi.post(url, objPost).then(resp => {
          if (resp.data.success) {
            this.preloader.toggleClass('d-none');
            this.toast.success('', 'Dados de envio foram alterados com sucesso!');
          } else {
            this.toast.error('', resp.data.error.message, {
              positionClass: 'toast-bottom-right'
            });
            this.preloader.toggleClass('d-none');
          }
        }, error => {
          this.variables.set('empty_resp', error);
          this.toast.error('', 'A atualização não foi efetuada!', {
            positionClass: 'toast-bottom-right'
          });
          this.preloader.toggleClass('d-none');
        });
      },
      getListaPostagem: function (requestId) {
        try {
          let host = _environment.default.APP.API.host_py;
          let url = host + '/api/generatep/';
          let r = this.baseapi.getHttp(`${url}recibo-pedido?requestId=${requestId}`, false);
          r.then(response => {
            let data = response.data;

            if (!data) {
              this.toast.error('Erro!', 'Informações necessárias não foram encontradas.', {
                positionClass: 'toast-bottom-right'
              });
              return;
            }

            this.baseapi.getDownload(url + 'view?file=' + data, 'kkkk.pdf', false);
          }).catch(error => {
            this.variables.set('empty_resp', error);
            this.toast.error('Erro!', 'Problemas ao tratar o template.', {
              positionClass: 'toast-bottom-right'
            });
          });
        } catch (error) {
          this.variables.set('empty_resp', error);
          this.toast.error('Erro!', 'Problemas ao tratar o template!!!', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      downloadRequestOS: function (requestId) {
        try {
          this.preloader.toggleClass('d-none');
          let url = this.baseapi.apiLink(`Requests/${requestId}/os`);
          this.baseapi.getDownload(`${url}`);
        } catch (error) {
          this.toast.error('Erro!', 'Problemas no download do arquivo', {
            positionClass: 'toast-bottom-right'
          });
        } finally {
          this.preloader.toggleClass('d-none');
        }
      },
      downloadFileBind: function (requestId) {
        this.preloader.toggleClass('d-none');
        let url = this.baseapi.apiLink('Wallets');
        let token = this.baseapi.getToken();
        url += `/file-bind/${requestId}?access_token=${token}`;
        this.baseapi.getDownload(url, 'asd', false);
        this.preloader.toggleClass('d-none');
      },
      setFileTrackingReturn: function (data) {
        Ember.set(this, 'fileTrackingReturn', data.target.files[0]);
      },
      setFileAttach: function (data) {
        Ember.set(this, 'fileAttach', data.target.files[0]);
      },
      clearFileHomolog: function () {
        Ember.set(this, 'fileHomolog', '');
      },
      setFileHomolog: function (data) {
        Ember.set(this, 'fileHomolog', data.target.files[0]);
      },
      setFileComplementary: function (data) {
        Ember.set(this, 'fileComplementary', data.target.files[0]);
      },

      uploadFile() {
        this.sendRawFile();
      },

      uploadTrackinFile: function (requestId, organizationId, userId) {
        this.sendVinculateAr(requestId, organizationId, userId);
      },

      uploadFileHomolog() {
        this.sendRawFile(true);
      },

      finalizarPedido: function (requestId) {
        let url = this.baseapi.apiLink('Requests/finalizar-pedido/' + requestId);
        let r = this.baseapi.getHttp(url);
        r.then(function () {
          //let data = response.data.data;
          window.location.reload(true);
        }).catch(function () {});
      },
      loadTypeReturn: function () {
        let url = this.baseapi.apiLink('TypeReturns/list?filter[where][channelName]=carta');
        this.preloader.toggleClass('d-none');
        this.baseapi.getHttp(url).then(resp => {
          this.preloader.toggleClass('d-none');
          let itens = resp.data;
          let positivo = {
            channelId: "5c3f713ce1c9855370e3e02e",
            channelName: "carta",
            companyReturnId: null,
            description: "Positivo",
            id: "idxpositivo"
          };
          itens.push(positivo);
          Ember.set(this, 'dataTypeReturn', itens);
        }, error => {
          this.variables.set('empty_resp', error);
          this.toast.error('', 'Problemas ao listar dados', {
            positionClass: 'toast-bottom-right'
          });
          this.preloader.toggleClass('d-none');
        });
      },
      setTypeReturn: function (dataReturn) {
        Ember.set(this, 'selectedReturn', dataReturn);
      },
      sendFilesDataReturn: function () {
        var dataReturn = Ember.get(this, 'selectedReturn');
        var dataRequest = Ember.get(this, 'request');
        var filesSend = Ember.get(this, 'filesReturn');

        if (dataReturn && dataRequest && filesSend) {
          let form = new FormData();
          form.set("typeReturnId", dataReturn.id);
          form.set("typeReturnDescription", dataReturn.description);
          form.set("requestId", dataRequest.id);
          form.set("userId", dataRequest.userId);

          for (let i = 0; i < filesSend.length; i++) {
            const file = filesSend[i];
            form.append("file", file);
          }

          let url = this.baseapi.apiLink("RequestReturns/return/upload");
          this.preloader.toggleClass('d-none');
          this.baseapi.postFormData(url, form).then(resp => {
            this.preloader.toggleClass('d-none');

            if (resp.data.success) {
              Ember.set(this, 'filesReturn', '');
              Ember.set(this, 'filesReturnLenght', 0);
              var msg = 'Retornos processados com sucesso.';
              if (resp.data.success[0].source && resp.data.success[0].source.length > 0) msg = `Sucesso:. ${resp.data.success[0].detail} (${resp.data.success[0].source.join(', ')})`;
              this.toast.success('', msg, {
                positionClass: 'toast-bottom-right'
              });
            } else {
              this.toast.error('', resp.data.errors[0].detail, {
                positionClass: 'toast-bottom-right'
              });
            }
          }, error => {
            this.variables.set('empty_resp', error);
            this.preloader.toggleClass('d-none');
            this.toast.error('', `Erro:. ${error.response.data.errors[0].detail}`, {
              positionClass: 'toast-bottom-right'
            });
          });
        } else {
          this.toast.error('Erro!', 'Problemas ao enviar os arquivos', {
            positionClass: 'toast-bottom-right'
          });
        }
      },
      returnRequest: function () {
        let dataRequest = this.get('model.request');
        let url = this.baseapi.apiLink('Requests/retornar-pedido/' + dataRequest.id);
        let r = this.baseapi.getHttp(url);
        r.then(resp => {
          let data = resp.data.success[0];
          let dataMsg = data && data.detail ? data.detail : "Pedido retornado com sucesso!";
          this.toast.success('', dataMsg, {
            positionClass: 'toast-bottom-right'
          });
          this.transitionToRoute('requests');
        }).catch(error => {
          let data = error.response.data.errors[0];
          let dataMsg = data && data.detail ? data.detail : "Erro ao retornar pedido!";
          this.toast.error('', dataMsg, {
            positionClass: 'toast-bottom-right'
          });
        });
      },
      aproveRequest: function () {
        let dataRequest = this.get('model.request');
        let url = this.baseapi.apiLink('Requests/aprovar-pedido/' + dataRequest.id);
        let r = this.baseapi.getHttp(url);
        this.preloader.toggleClass('d-none');
        r.then(resp => {
          this.preloader.toggleClass('d-none');
          let data = resp.data.success[0];
          let dataMsg = data && data.detail ? data.detail : "Pedido aprovado com sucesso!";
          this.toast.success('', dataMsg, {
            positionClass: 'toast-bottom-right'
          });
          this.send('sessionChanged');
        }).catch(error => {
          this.preloader.toggleClass('d-none');
          let data = error.response.data.errors[0];
          let dataMsg = data && data.detail ? data.detail : "Erro ao retornar pedido!";
          this.toast.error('', dataMsg, {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      setReprove(val) {
        this.set('model.pdfHomologation.status', val);
      },

      saveHomologation(showNotification = true) {
        let dataRequest = this.get('model.request');
        let dataHomogation = this.get('model.pdfHomologation');
        var objHomolgation = {
          id: dataRequest.id,
          status: dataHomogation.status,
          observation: dataHomogation.observation
        };
        let url = this.baseapi.apiLink('Requests/homologar-pedido');

        if (showNotification) {
          this.preloader.toggleClass('d-none');
        }

        this.baseapi.post(url, {
          data: objHomolgation
        }).then(resp => {
          if (showNotification) {
            this.preloader.toggleClass('d-none');
            let data = resp.data.success[0];
            let dataMsg = data && data.detail ? data.detail : "Pedido homologado com sucesso!";
            this.toast.success('', dataMsg, {
              positionClass: 'toast-bottom-right'
            });
            this.send('sessionChanged');
          }
        }, error => {
          if (showNotification) {
            this.preloader.toggleClass('d-none');
            let data = error.response.data.errors[0];
            let dataMsg = data && data.detail ? data.detail : "Erro ao retornar pedido!";
            this.toast.error('', dataMsg, {
              positionClass: 'toast-bottom-right'
            });
          }
        });
      },

      aproveFileComplementary(isValidated = false) {
        this.aproveDataComplement(isValidated);
      },

      clearFileComplementary() {
        Ember.$("#upload-fcomplementary").val('');
        Ember.set(this, 'fileComplementary', false);
      },

      getMultichannelReport() {
        let request = this.get('model.request');
        let url = this.baseapi.apiLink('Reports/requests-analytical');
        let create = request.createdAt.split('T');
        let dtIni = `${create[0]}T00:00:00-03:00`;
        let dtEnd = `${create[0]}T23:59:59-03:00`;
        let strfilter = `filter[where][id]=${request.id}&filter[where][and][0][createdAt][gte]=${dtIni}&filter[where][and][0][createdAt][lte]=${dtEnd}`;
        this.preloader.toggleClass('d-none');
        this.baseapi.getHttp(`${url}?${strfilter}`).then(resp => {
          this.preloader.toggleClass('d-none');
          var data = resp.data;
          console.log('reportMultichannel', data['undefined']);
          var report = {
            carta: data['undefined'].find(x => x.channel == "carta"),
            email: data['undefined'].find(x => x.channel == "email"),
            sms: data['undefined'].find(x => x.channel == "sms"),
            resume: data['undefined'].find(x => x.channel == "RESUMO")
          };
          console.log('reportMultichannel', report);
          this.set('reportMultichannel', report);
        }, error => {
          this.variables.set('empty_resp', error);
          this.preloader.toggleClass('d-none');
          this.set('reportMultichannel', null);
          this.toast.error('', 'Erro ao buscar dados', {
            positionClass: 'toast-bottom-right'
          });
        });
      },

      panelWhatsapp() {
        this.set('model.typePanel', 'whatsapp');
        Ember.get(this, 'taskFilterReturn').perform();
      },

      panelSms() {
        this.set('model.typePanel', 'sms');
        Ember.get(this, 'taskFilterReturn').perform();
      },

      panelEmail() {
        this.set('model.typePanel', 'email');
        Ember.get(this, 'taskFilterReturn').perform();
      },

      loadContactResponse() {
        var dataItens = this.get('model.panelData.qtdStatus');
        dataItens.forEach((v, j) => Ember.$(`#status${j}`).removeClass('panel-active'));
        Ember.$(`#responseSms`).addClass('panel-active');
        this.set('model.key', '*');
        this.set('model.returnMessage', 'Respostas');
        this.set('model.filter.skip', 0); //   this.get('taskFilterContactResponse').perform(true);
      },

      paginationRequest(filterSkip) {
        this.set('model.filter', filterSkip);

        if (this.get('model.returnMessage') == 'Respostas') {
          this.get('taskFilterContactResponse').perform(true);
        } else {
          this.get('taskFilterReturn').perform(true);
        }
      },

      downloadAnalitycalXLS() {},

      loadItens(idx) {
        var dataItens = this.get('model.panelData.qtdStatus');
        var item = dataItens[idx];
        Ember.$(`#responseSms`).removeClass('panel-active');
        dataItens.forEach((d, j) => {
          if (j == idx) Ember.$(`#status${j}`).addClass('panel-active');else Ember.$(`#status${j}`).removeClass('panel-active');
        });
        this.set('model.key', item.key);
        this.set('model.returnMessage', item.message);
        this.set('model.filter.skip', 0);
        this.set('model.painelPagePagination', 1);
        this.get('taskFilterReturn').perform(true);
      },

      downloadMultichannelReport() {
        let request = this.get('model.request');
        let url = this.baseapi.apiLink('Reports/multichannel-analytical');
        let strfilter = `id=${request.id}&dispatch=1`;
        this.preloader.toggleClass('d-none');
        this.baseapi.getDownload(`${url}?${strfilter}`, 'file_download.xlsx', true);
        this.preloader.toggleClass('d-none');
      },

      downloadMultichannelReturn() {
        let request = this.get('model.request');
        let requestDispatchs = this.get('model.requestDispatchs');
        let url = this.baseapi.apiLink('Wallets/multichannel-return');
        this.preloader.toggleClass('d-none');
        this.baseapi.getDownload(`${url}/${request.id}?dispatch=${requestDispatchs[0].id}`, 'file_download.xlsx', true);
        this.preloader.toggleClass('d-none');
      },

      pauseSendRequest() {
        let request = this.get('model.request');
        let url = this.baseapi.apiLink('Requests');
        this.preloader.toggleClass('d-none');
        this.baseapi.patch(`${url}/${request.id}/pause-send`, {});
        this.preloader.toggleClass('d-none');
      },

      addTagFileReturn(tag) {
        this.get('model.walletColumns').pushObject(tag.toLowerCase());
      },

      removeTagFileReturn(index) {
        this.get('model.walletColumns').removeAt(index);
      },

      generateRequestReturn() {
        let request = this.get('model.request');
        let columns = this.get('model.walletColumns');
        let type = this.get('model.type');
        let url = this.baseapi.apiLink('Reports');
        const fields = columns.join(';');
        this.preloader.toggleClass('d-none');

        try {
          this.baseapi.getHttp(`${url}/request-file-return?type=${type}&fields=${fields}&filter[where][id]=${request.id}`);
          this.toast.success('', 'Arquivo solicitado com sucesso.', {
            positionClass: 'toast-bottom-right'
          });
        } catch (error) {
          this.toast.error('Erro!', 'Problemas ao gerar o arquivo.', {
            positionClass: 'toast-bottom-right'
          });
        }

        this.preloader.toggleClass('d-none');
      },

      listRequestReturnFiles() {
        this.get('taskListRequestReturnFiles').perform();
      },

      downloadRequestReturnFile(file) {
        this.baseapi.downloadRawFile(file);
      },

      setBlacklistAction(act) {
        this.set('model.blacklistData.action', act);
      },

      doActionBlacklist() {
        let act = this.get('model.blacklistData.action');

        switch (act) {
          case 'cadastrar':
            this.get('taskBlacklistSave').perform();
            break;

          case 'deletar':
            this.get('taskBlacklistDelete').perform();
            break;

          case 'consultar':
            this.get('taskBlacklistFind').perform();
            break;

          default:
            break;
        }
      },

      getBlacklistFind() {
        this.get('taskBlacklistFind').perform();
      }

    }
  });

  _exports.default = _default;
});