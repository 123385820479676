define("printpost/routes/menus/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: async function () {
      var url = this.baseapi.apiLink('Menus/list');
      var saida = [];
      const obj = await this.baseapi.getHttp(url).then(r => {
        let menus = r.data;
        let m = [];
        menus.forEach(item => {
          if (!item.submenuId) {
            m.push(item);
            menus.find(x => {
              if (item.id == x.submenuId) {
                m.push(x);
              }
            });
          }
        });
        return m;
      });
      saida = [{
        "objects": obj
      }];
      return Ember.RSVP.hash({
        data: saida
      });
    }
  });

  _exports.default = _default;
});