define("printpost/controllers/profile/settings", ["exports", "printpost/validations/user", "printpost/validations/password", "ember-concurrency"], function (_exports, _user, _password, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const updatedPassword = Ember.Object.extend({
    oldPassword: '',
    newPassword: '',
    confirmNew: ''
  });

  var _default = Ember.Controller.extend({
    updatedPassword: updatedPassword.create(),
    baseapi: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    UserValidations: _user.default,
    PasswordValidations: _password.default,

    init() {
      this._super(...arguments);
    },

    reloadUser() {
      this.get('currentUser').load();
    },

    modelEditor: Ember.computed('currentUser.session.data.authenticated.[]', function () {
      return this.get('currentUser.session.data.authenticated.account');
    }),
    submitTask: (0, _emberConcurrency.task)(function* (payload) {
      this.set('errorResults', []);
      this.set('isRunning', true);

      try {
        yield this.get('baseapi').updateUserDetails(payload);
        this.toast.success('Usuário alterado com sucesso', 'Sucesso!', {
          positionClass: 'toast-bottom-right'
        });
        Ember.getOwner(this).lookup('route:profile').refresh();
      } catch (error) {
        return error;
      }
    }),
    changePasswordTask: (0, _emberConcurrency.task)(function* (payload) {
      this.set('errorResults', []);
      this.set('isRunning', true);

      try {
        yield this.get('baseapi').updatePassword(payload);
        this.toast.success('Senha alterada com sucesso', 'Sucesso!', {
          positionClass: 'toast-bottom-right'
        });
        Ember.getOwner(this).lookup('route:profile').refresh();
      } catch (error) {
        this.toast.error('Senha antiga incorreta.', 'Erro!', {
          positionClass: 'toast-bottom-right'
        });
        return error;
      }
    }),
    actions: {
      submit(model) {
        let payload = {
          username: Ember.get(model, 'username'),
          email: Ember.get(model, 'email') // password: model.get('password')

        };

        if (payload.username) {
          Ember.set(this.modelEditor, 'username', payload.username);
        }

        if (payload.email) {
          Ember.set(this.modelEditor, 'email', payload.email);
        }

        this.submitTask.perform(payload).then(() => {
          this.reloadUser();
          Ember.getOwner(this).lookup('route:profile').refresh();
        });
      },

      submitPassword(model) {
        let payload = {
          password: Ember.get(model, 'oldPassword'),
          newPassword: Ember.get(model, 'newPassword'),
          confirmNew: Ember.get(model, 'confirmNew')
        };
        Ember.get(this, 'changePasswordTask').perform(payload);
      }

    }
  });

  _exports.default = _default;
});