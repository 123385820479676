define("printpost/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    /* auto gerado por ember-models.py em  2018-08-24 01:52:44 */
    title: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    user: _emberData.default.attr(),
    sender: _emberData.default.attr(),
    is_recent: _emberData.default.attr(),
    created_at: _emberData.default.attr('date')
  });

  _exports.default = _default;
});