define("printpost/routes/remetente/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    baseapi: Ember.inject.service(),
    session: Ember.inject.service(),
    model: async function () {
      var url = this.baseapi.apiLink('Emails/sender');
      const obj = await this.baseapi.getHttp(url).then(r => r.data);
      return Ember.RSVP.hash({
        data: obj,
        emailTrash: '',
        emailAdd: ''
      });
    },
    actions: {
      routerReload: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});